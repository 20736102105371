import CheckCircleOutlineIcon from '@scc/assets/icons/success.svg';
import SentSuccessLayout from '@scc/components/PartialSuccessLayout';

import Icon from '@ui/Icon';

import SuccessSentMessage from '@icons/outlined/successSentMessage.svg';

export const TYPES = {
    markDone: 'markDone',
    mailSent: 'mailSent',
};

export const COMPONENTS = {
    [TYPES.markDone]: {
        icon: CheckCircleOutlineIcon,
    },
    [TYPES.mailSent]: {
        icon: SuccessSentMessage,
        color: '#8990A9',
    },
};

function Success({ title = 'Success!', description, className, iconType, titleVariant }) {
    const component = COMPONENTS[iconType] || COMPONENTS[TYPES.markDone];

    return (
        <SentSuccessLayout
            className={className}
            description={<span>{description}</span>}
            icon={<Icon color={component?.color} component={component?.icon} fontSize='128px' />}
            title={<span>{title}</span>}
            titleVariant={titleVariant}
        />
    );
}

export default Success;
