import { v4 as uuid } from 'uuid';

import { PopoverWithCustom } from '@scc/components/popovers/PopoverWithCustom';
import { useAppDispatch, useAppSelector } from '@scc/hooks/useTypeScript';
import { useAddNewLoanProgramMutation } from '@scc/services/generated/userLoans';
import { selectLoan, updateLoanDataTermsLoans } from '@scc/store/ducks/loans/loans';

import { Trigger } from '../Trigger';

type LoanProgramSelectProps = {
    value: string;
    onChange: (value: string) => void;
    options: {
        displayName: string;
        uuid: string;
    }[];
    name: string;
    optionsKey: string;
};

export function LoanProgramSelect({ value, onChange, options, name, optionsKey }: LoanProgramSelectProps) {
    const dispatch = useAppDispatch();
    const { id } = useAppSelector(selectLoan);

    const transformedOptions = options.map((option) => ({
        label: option.displayName,
        value: option.uuid,
    }));

    const [addNewLoanProgram] = useAddNewLoanProgramMutation();

    const handleCustomAdd = (displayName: string) => {
        const newEntity = {
            listName: name,
            value: uuid(),
            displayName,
        };

        dispatch(
            updateLoanDataTermsLoans({
                [optionsKey]: [
                    ...options,
                    {
                        displayName,
                        uuid: newEntity.value,
                    },
                ],
            } as never)
        );

        addNewLoanProgram({
            loanDetailsId: Number(id),
            addListDefinitionEntryRequest: newEntity,
        });
    };

    return (
        <PopoverWithCustom
            customLabel='Add Program'
            options={transformedOptions}
            trigger={Trigger}
            value={value}
            onChange={onChange}
            onCustomAdd={handleCustomAdd}
        />
    );
}
