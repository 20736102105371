export const barPattern = {
    name: '',
    type: 'bar',
    data: [],
};

export const simpleLinePattern = {
    name: '',
    type: 'line',
    data: [],
};

export const smallBarPattern = {
    ...barPattern,
    barWidth: 16,
};

export const mediumBarPattern = {
    ...barPattern,
    barWidth: 40,
};

export const largeBarPattern = {
    ...barPattern,
    barWidth: 56,
};

export const baseEmphasis = {
    emphasis: {
        itemStyle: {
            color: '#3da3fb',
        },
    },
};
