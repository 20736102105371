import LockIcon from '@icons/outlined/lock-1.svg';
import { HTTP_STATUSES } from '@constants/httpStatuses';

const loginTitles = ({ errorStatus } = {}) => ({
    meta: {
        title: 'Login',
        description: 'Login',
    },
    welcomeSection: {
        title: errorStatus === HTTP_STATUSES.FORBIDDEN ? '' : 'Welcome\nBack',
        icon: errorStatus === HTTP_STATUSES.FORBIDDEN ? LockIcon : null,
        subTitle: '',
    },
    partialModal: {
        title: 'Login',
    },
});

export const getStepTitles = (step, options) => {
    switch (step) {
        case 'register':
        case 'registered':
            return {
                meta: {
                    title: 'Register',
                    description: 'Register',
                },
                welcomeSection: {
                    title: 'Smart\nWay',
                    subTitle: 'To invest in commercial real estate',
                },
                partialModal: {
                    title: 'Sign Up',
                },
            };
        case 'requestDemo':
        case 'requestDemoSuccess':
        case 'requestDemoAlertError':
            return {
                meta: {
                    title: 'Request Demo',
                    description: 'Request Demo',
                },
                welcomeSection: {
                    title: 'Smart\nWay',
                    subTitle: 'To invest in commercial real estate',
                },
                partialModal: {
                    title: 'Request Demo',
                },
            };
        case 'verification':
            return {
                meta: {
                    title: 'Register',
                    description: 'Register',
                },
                welcomeSection: {
                    title: 'Smart\nWay',
                    subTitle: 'To invest in commercial real estate',
                },
                partialModal: {
                    title: 'Sign Up',
                },
            };
        case 'login':
            return loginTitles(options);
        case 'forgotPassword':
            return {
                meta: {
                    title: 'Reset Your Password',
                    description: 'Reset Your Password',
                },
                welcomeSection: {
                    title: 'Welcome\nBack',
                    subTitle: '',
                },
                partialModal: {
                    title: 'Reset Your Password',
                },
            };
        case 'confirmPassword':
            return {
                meta: {
                    title: 'Set New Password',
                    description: 'Set New Password ',
                },
                welcomeSection: {
                    title: 'Welcome\nBack',
                    subTitle: '',
                },
                partialModal: {
                    title: 'Set New Password',
                },
            };
        case 'inviteUser':
            return {
                meta: {
                    title: 'Set Up Your Account',
                    description: 'Set Up Your Account',
                },
                welcomeSection: {
                    title: 'Welcome\nBack',
                    subTitle: '',
                },
                partialModal: {
                    title: 'Set Up Your Account',
                },
            };
        case 'forgotPasswordSuccess':
        case 'confirmPasswordSuccess':
            return {
                meta: {
                    title: 'Reset Your Password',
                    description: 'Reset Your Password',
                },
                welcomeSection: {
                    title: 'Welcome\nBack',
                    subTitle: '',
                },
                partialModal: {
                    title: 'Reset Your Password',
                },
            };
        default:
            return loginTitles();
    }
};
