import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { dashboardApi } from '@api';

const entity = '[dashboardNotifications]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const notificationsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadNotifications, setData: setNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectNotificationsState = (store) => store.dashboard.notifications;

function* loadNotificationsSaga({ type, payload }) {
    yield put(apiRequest(payload, dashboardApi.getNotifications, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setNotifications(action.payload.data));
}

export function* watchNotifications() {
    yield takeLatest(loadNotifications, loadNotificationsSaga);
}
