import colors from '@themes/palette/export.module.scss';

const chip = () => ({
    MuiChip: {
        defaultProps: {},
        styleOverrides: {
            root: {
                fontFamily: 'Open Sans',
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 700,
                height: 20,
                lineHeight: 1.25,
                padding: 2,
                borderRadius: 4,
                maxWidth: '100%',
            },
            filledPrimary: {
                color: colors['grey-600'],
                backgroundColor: colors['grey-A100'],
            },
            filledSecondary: {
                backgroundColor: colors.white,
                color: colors['grey-400'],
            },
            sizeMedium: {
                padding: '2px 6px',
            },
            sizeRound: {
                borderRadius: '50%',
            },
        },
    },
});

export default chip;
