import { Type as DocumentType } from '@scc/services/generated/document';

import colors from '@themes/palette/export.module.scss';

import UploadSvg from '@icons/outlined/arrow-up-3.svg';
import InProgress25Icon from '@icons/outlined/in-progress-25.svg';
import InProgress50Icon from '@icons/outlined/in-progress-50.svg';
import InProgress75Icon from '@icons/outlined/in-progress-75.svg';
import OtherSvg from '@icons/outlined/more-square.svg';
import RefreshSvg from '@icons/outlined/refresh-3.svg';
import SendSvg from '@icons/outlined/send-2.svg';
import StatusIcon from '@icons/outlined/status.svg';
import ReviewSvg from '@icons/outlined/task-review.svg';
import SignSvg from '@icons/outlined/task-sign.svg';
import ConfirmedIcon from '@icons/outlined/tick-circle.svg';

export const FORM_MODE = {
    EDIT: 'EDIT',
    VIEW: 'VIEW',
} as const;

export const FORM_CALL_CONTEXT = {
    USER_TODO_LIST_VIEW: 'USER_TODO_LIST_VIEW',
    TODO_LIST_VIEW: 'TODO_LIST_VIEW',
    TODO_LIST_CREATE: 'TODO_LIST_CREATE',
    CHECKLIST_VIEW: 'CHECKLIST_VIEW',
    CHECKLIST_CREATE: 'CHECKLIST_CREATE',
} as const;

export const FORM_CONTEXT_LIST_WITH_EDIT = [
    FORM_CALL_CONTEXT.TODO_LIST_CREATE,
    FORM_CALL_CONTEXT.CHECKLIST_VIEW,
    FORM_CALL_CONTEXT.CHECKLIST_CREATE,
] as const;

export const TASK_TYPE_ENUM = {
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
    REVIEW_DOCUMENT: 'REVIEW_DOCUMENT',
    SIGN_DOCUMENT: 'SIGN_DOCUMENT',
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
    SEND_DOCUMENT: 'SEND_DOCUMENT',
    OTHER: 'OTHER',
} as const;

export const TASK_TYPE = {
    [TASK_TYPE_ENUM.UPLOAD_DOCUMENT]: {
        label: 'Upload',
        value: TASK_TYPE_ENUM.UPLOAD_DOCUMENT,
        icon: UploadSvg,
    },
    [TASK_TYPE_ENUM.REVIEW_DOCUMENT]: {
        label: 'Review',
        value: TASK_TYPE_ENUM.REVIEW_DOCUMENT,
        icon: ReviewSvg,
    },
    [TASK_TYPE_ENUM.SIGN_DOCUMENT]: {
        label: 'Sign',
        value: TASK_TYPE_ENUM.SIGN_DOCUMENT,
        icon: SignSvg,
    },
    [TASK_TYPE_ENUM.UPDATE_DOCUMENT]: {
        label: 'Update',
        value: TASK_TYPE_ENUM.UPDATE_DOCUMENT,
        icon: RefreshSvg,
    },
    [TASK_TYPE_ENUM.SEND_DOCUMENT]: {
        label: 'Send',
        value: TASK_TYPE_ENUM.SEND_DOCUMENT,
        icon: SendSvg,
    },
    [TASK_TYPE_ENUM.OTHER]: {
        label: 'Other',
        value: TASK_TYPE_ENUM.OTHER,
        icon: OtherSvg,
    },
} as const;

export const DOCUMENT_TYPE_LIST_WITH_PROPERTY = [
    DocumentType.RentRoll,
    DocumentType.OperatingStatement,
    DocumentType.Budget,
] as const;

export const TODO_TYPE_LIST_WITH_DOCUMENTS = [TASK_TYPE_ENUM.SIGN_DOCUMENT, TASK_TYPE_ENUM.REVIEW_DOCUMENT] as const;

export const REQUIRED_FIELD_LIST = {
    [TASK_TYPE_ENUM.UPLOAD_DOCUMENT]: ['title', 'type', 'lead', 'documentType', 'documentPeriod'],
    [TASK_TYPE_ENUM.REVIEW_DOCUMENT]: ['title', 'type', 'lead', 'documents', 'documentSyncTask'],
    [TASK_TYPE_ENUM.SIGN_DOCUMENT]: ['title', 'type', 'lead', 'documents', 'documentSyncTask'],
    [TASK_TYPE_ENUM.UPDATE_DOCUMENT]: ['title', 'type', 'lead'],
    [TASK_TYPE_ENUM.SEND_DOCUMENT]: ['title', 'type', 'lead'],
    [TASK_TYPE_ENUM.OTHER]: ['title', 'type', 'lead'],
} as const;

export enum TASK_STATUS_ENUM {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    InProgress25 = 'IN_PROGRESS_25',
    InProgress50 = 'IN_PROGRESS_50',
    InProgress75 = 'IN_PROGRESS_75',
    Completed = 'COMPLETED',
}

export const TASK_STATUS = {
    [TASK_STATUS_ENUM.New]: {
        label: 'Not started',
        value: TASK_STATUS_ENUM.New,
        color: colors['grey-A200'],
        icon: StatusIcon,
    },
    [TASK_STATUS_ENUM.InProgress25]: {
        label: 'In progress',
        value: TASK_STATUS_ENUM.InProgress25,
        color: colors['yellow-500'],
        icon: InProgress25Icon,
        postfix: '25%',
    },
    [TASK_STATUS_ENUM.InProgress50]: {
        label: 'In progress',
        value: TASK_STATUS_ENUM.InProgress50,
        color: colors['yellow-500'],
        icon: InProgress50Icon,
        postfix: '50%',
    },
    [TASK_STATUS_ENUM.InProgress75]: {
        label: 'In progress',
        value: TASK_STATUS_ENUM.InProgress75,
        color: colors['yellow-500'],
        icon: InProgress75Icon,
        postfix: '75%',
    },
    [TASK_STATUS_ENUM.Completed]: {
        label: 'Completed',
        value: TASK_STATUS_ENUM.Completed,
        color: colors['green-500'],
        icon: ConfirmedIcon,
    },
} as const;

export const TASK_STATUS_LIST = Object.values(TASK_STATUS);
