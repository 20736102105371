import { baseApi as api } from '../configs/rentRollMetric';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        updateRentRollMetric: build.mutation<UpdateRentRollMetricApiResponse, UpdateRentRollMetricApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/rentroll-metric`,
                method: 'PUT',
                body: queryArg.saveUpdateRentRollMetricDto,
            }),
        }),
        createRentRollMetric: build.mutation<CreateRentRollMetricApiResponse, CreateRentRollMetricApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/rentroll-metric`,
                method: 'POST',
                body: queryArg.saveUpdateRentRollMetricDto,
            }),
        }),
        getRentRollMetricById: build.query<GetRentRollMetricByIdApiResponse, GetRentRollMetricByIdApiArg>({
            query: (queryArg) => ({ url: `/api/v2/rentroll-metric/${queryArg.propertyId}` }),
        }),
        deleteRentRollMetric: build.mutation<DeleteRentRollMetricApiResponse, DeleteRentRollMetricApiArg>({
            query: (queryArg) => ({ url: `/api/v2/rentroll-metric/${queryArg.propertyId}`, method: 'DELETE' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type UpdateRentRollMetricApiResponse = /** status 200 OK */ GenericJsonResponseRentRollMetricDto;
export type UpdateRentRollMetricApiArg = {
    saveUpdateRentRollMetricDto: SaveUpdateRentRollMetricDto;
};
export type CreateRentRollMetricApiResponse = /** status 200 OK */ GenericJsonResponseRentRollMetricDto;
export type CreateRentRollMetricApiArg = {
    saveUpdateRentRollMetricDto: SaveUpdateRentRollMetricDto;
};
export type GetRentRollMetricByIdApiResponse = /** status 200 OK */ GenericJsonResponseRentRollMetricDto;
export type GetRentRollMetricByIdApiArg = {
    propertyId: string;
};
export type DeleteRentRollMetricApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type DeleteRentRollMetricApiArg = {
    propertyId: string;
};
export type RentRollMetricDto = {
    id?: number;
    propertyId?: string;
    rentRollDate?: number;
    propertyType?: string;
    multiFamilyOccupancy?: number;
    multiFamilyRent?: number;
    multiFamilyGpr?: number;
    multiFamilyRentUnit?: string;
    multiFamilyGprUnit?: string;
    totalOccupancy?: number;
    totalRent?: number;
    totalGpr?: number;
    totalRentUnit?: string;
    totalGprUnit?: string;
};
export type GenericJsonResponseRentRollMetricDto = {
    status?: string;
    data?: RentRollMetricDto;
    message?: string;
    code?: string;
};
export type SaveUpdateRentRollMetricDto = {
    id?: number;
    propertyId?: string;
    rentRollDate?: number;
    multiFamilyOccupancy?: number;
    multiFamilyRent?: number;
    multiFamilyGpr?: number;
    multiFamilyRentUnit?: string;
    multiFamilyGprUnit?: string;
    totalOccupancy?: number;
    totalRent?: number;
    totalGpr?: number;
    totalRentUnit?: string;
    totalGprUnit?: string;
    anyMultiFamilyFieldNotNull?: boolean;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export const {
    useUpdateRentRollMetricMutation,
    useCreateRentRollMetricMutation,
    useGetRentRollMetricByIdQuery,
    useDeleteRentRollMetricMutation,
} = injectedRtkApi;
