export const heroData = {
    features: [
        {
            title: 'Ultra-fast deal underwriting',
        },
        {
            title: 'Optimized deal financing',
        },
        {
            title: 'Effortless portfolio monitoring',
        },
    ],
    actions: {
        signUp: 'Sign Up for Free',
    },
};
