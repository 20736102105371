import { MenuItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import { ElementType } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';
import { OtherOptionPopover } from '@scc/components/popovers/OtherOptionPopover';
import { useDropdown } from '@scc/hooks/useDropdown';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './PopoverWithCustom.module.scss';

type Options = {
    value: string;
    label: string;
};

type PopoverWithCustomProps = {
    options: Options[];
    value: string;
    onChange: (value: string) => void;
    onCustomAdd: (value: string) => void;
    trigger: ElementType;
    popoverProps?: Omit<PopoverProps, 'id' | 'onClose' | 'anchorEl'>;
    customLabel?: string;
    valuePlaceholder?: string;
    [key: string]: unknown;
};

function Trigger({ isOpen, onClick, value, ...rest }: { isOpen: boolean; onClick: () => void; value: string }) {
    return (
        <Button
            className={styles.button}
            endIcon={
                <Icon
                    className={clsx({
                        [styles.rotate]: isOpen,
                    })}
                    color={colors['darkNeutral-500']}
                    component={ChevronIcon}
                />
            }
            size='small'
            variant='outlined'
            onClick={onClick}
            {...rest}
        >
            {value}
        </Button>
    );
}

export function PopoverWithCustom({
    options,
    value,
    onChange,
    onCustomAdd,
    trigger: TriggerComponent = Trigger,
    customLabel = 'Other',
    valuePlaceholder = '',
    popoverProps,
}: PopoverWithCustomProps) {
    const [{ isOpen, anchorRef }, { handleAnchorClick, handleDropdownClose }] = useDropdown<HTMLButtonElement>();
    const id = isOpen ? 'popover-with-custom' : undefined;

    const renderValue = options.find((option) => option.value === value)?.label || '';

    return (
        <>
            <TriggerComponent
                aria-describedby={id}
                isOpen={isOpen}
                value={value ? renderValue : valuePlaceholder}
                onClick={handleAnchorClick}
            />
            <Popover anchorEl={anchorRef} open={isOpen} onClose={handleDropdownClose} {...popoverProps}>
                <Stack
                    alignItems='stretch'
                    direction='column'
                    sx={{
                        width: anchorRef?.clientWidth ?? 'auto',
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={value === option.value}
                            onClick={() => onChange(option.value)}
                        >
                            <Typography className={styles.label} variant='pr13'>
                                {option.label}
                            </Typography>
                        </MenuItem>
                    ))}
                    <Divider />
                    <OtherOptionPopover anchorWidth={anchorRef?.clientWidth} onSubmit={onCustomAdd}>
                        <MenuItem>{customLabel}</MenuItem>
                    </OtherOptionPopover>
                </Stack>
            </Popover>
        </>
    );
}
