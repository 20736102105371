import { formatValuationValue } from '@utils/valuation';

import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import IconSale from '@assets/icons/filled/sale.svg';

import styles from './styles.module.scss';

export default function ListingValue({ propertyListing }) {
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.value} component='span' variant='head2b'>
                {formatValuationValue(propertyListing.price)}
            </Typography>
            <Icon color={colors['green-500']} component={IconSale} fontSize={32} />
        </div>
    );
}
