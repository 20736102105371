import { generatedApi } from '@scc/services/generated/comments';

const enhanceOptions = {
    endpoints: {
        addComment: {
            async onQueryStarted({ addCommentRequest: { entityId, entityType } }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    dispatch(generatedApi.util.upsertQueryData('getComments', { entityId, entityType }, data));
                }
            },
        },
    },
} as const;

export const commentsApi = generatedApi.enhanceEndpoints(enhanceOptions);
