import { formatter } from '@utils/index';
import { isMFLike } from '@utils/properties';

import Typography from '@ui/Typography';

import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import styles from '@shared/templates/forms/components/OrderForm/AddPropertyAutocomplete/styles.module.scss';

function PropertyCard({ property: { address = '', units, size, propertyType } = {} }) {
    // todo remove propertyType by default
    const propertySize = isMFLike(propertyType || '') ? units : size;
    const propertyUnitType = isMFLike(propertyType || '') ? 'Units' : 'SF';
    const splitAddress = address.split(',');

    return (
        <div className={styles.item}>
            <PropertyTypeIcon type={propertyType} />
            <div>
                <Typography color={colors['darkNeutral-500']} component='h6'>
                    {splitAddress[1] || ''}
                </Typography>
                <Typography color={colors['darkNeutral-500']} variant='hr13'>
                    {splitAddress[0]},
                </Typography>
                <Typography color={colors['grey-400']} variant='hr13'>
                    {`${splitAddress[1]}, ${splitAddress[2]}`}
                </Typography>
                <div className={styles.endLine}>
                    <div className={styles.endLineItem}>
                        <Typography color={colors['grey-500']} variant='hr12'>
                            {formatter.number(propertySize)}
                        </Typography>
                        <Typography color={colors['grey-300']} variant='hr12'>
                            {propertyUnitType}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertyCard;
