import { BaseQueryFn } from '@reduxjs/toolkit/query/react';

import request from '@utils/request';

import { apiErrorUnauthorized } from '@store/ducks/api';

export const createBaseQuery =
    (): BaseQueryFn<
        {
            url: string;
            params?: Record<string, unknown>;
            method?: RequestInit['method'];
            body?: unknown;
            headers?: RequestInit['headers'];
        },
        unknown,
        unknown
    > =>
    async ({ url, body, ...restOptions }, { dispatch }) => {
        const data = await request(url, {
            ...restOptions,
            body: typeof body === 'string' ? body : JSON.stringify(body),
        });

        const { error, redirect } = data;

        if (error) {
            dispatch(apiErrorUnauthorized({ redirect }));

            return { error };
        }

        return { data };
    };
