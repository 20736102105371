import { combineReducers } from 'redux';

import financialsReducer, { watchFinancials } from './financials';
import financingReducer, { watchFinancing } from './financing';
import userRequestsReducer, { watchUserRequests } from './userRequests';
import varianceReportReducer, { watchVarianceReport } from './varianceReport';

export const financingWatchers = [watchFinancing, watchFinancials, watchVarianceReport, watchUserRequests];

export default combineReducers({
    financing: financingReducer,
    financials: financialsReducer,
    varianceReport: varianceReportReducer,
    userRequests: userRequestsReducer,
});
