import { createComponentsMap, reloadable } from '@utils/components';

import Icon from '@ui/Icon';

import colors from '@themes/palette/export.module.scss';

import LayersIcon from '@icons/outlined/3square.svg';
import BankIcon from '@icons/outlined/bank.svg';
import DollarIcon from '@icons/outlined/dollar-square.svg';
import MoreIcon from '@icons/outlined/more-square.svg';

export const ENTITY_TYPES = {
    COMPANY: 'COMPANY',
    INDIVIDUAL: 'INDIVIDUAL',
};

export const filterData = [
    {
        id: 44332,
        group: 'Role',
        filters: [
            {
                id: 68,
                label: 'All',
                param: 'memberType',
                value: 'INVESTOR, LENDER, OTHER',
                iconComponent: <Icon color={colors['grey-400']} component={LayersIcon} fontSize={15} />,
            },
            {
                id: 675,
                label: 'Investor',
                param: 'memberType',
                value: 'INVESTOR',
                iconComponent: <Icon color={colors['grey-400']} component={DollarIcon} fontSize={15} />,
            },
            {
                id: 4444,
                label: 'Lender',
                param: 'memberType',
                value: 'LENDER',
                iconComponent: <Icon color={colors['grey-400']} component={BankIcon} fontSize={16} />,
            },
            {
                id: 3345,
                label: 'Other',
                param: 'memberType',
                value: 'OTHER',
                iconComponent: <Icon color={colors['grey-400']} component={MoreIcon} fontSize={15} />,
            },
        ],
    },
];

export const TEMPLATES = {
    COMPANIES: 'Companies',
    INDIVIDUALS: 'Individuals',
};

export const PAGES_MAP = {
    [TEMPLATES.COMPANIES]: reloadable(() => import('@scc/pages/Members/components/Companies')),
    [TEMPLATES.INDIVIDUALS]: reloadable(() => import('@scc/pages/Members/components/Individuals')),
};

export const DEFAULT_TEMPLATE = TEMPLATES.COMPANIES;

export const getStepsContent = createComponentsMap(PAGES_MAP, DEFAULT_TEMPLATE);

export function getEntityType(activeTab) {
    return activeTab === TEMPLATES.COMPANIES ? ENTITY_TYPES.COMPANY : ENTITY_TYPES.INDIVIDUAL;
}
