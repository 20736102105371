import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { namesToInitials } from '@utils/index';

import ContentCard from '@scc/pages/Property/components/ContentCard';
import { selectBuildingData } from '@scc/store/ducks/building';

import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import Grid from '@ui/Grid';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import FlagIcon from '@icons/filled/flag.svg';

import styles from './styles.module.scss';

export default function Listing({ onContactBrokerClick, propertyListing, avatar }) {
    const { brokerInfo } = useSelector(selectBuildingData);

    const { firstname, lastname, department } = brokerInfo || {};

    const nameToInitials = useMemo(() => {
        return namesToInitials(firstname, lastname);
    }, [firstname, lastname]);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid className={styles.wrapper} container>
            <Grid md={6} xs={12} item>
                <ContentCard icon={FlagIcon} title='Listing Overview'>
                    {propertyListing?.comment || "There're no comments yet"}
                </ContentCard>
            </Grid>
            <Grid className={styles.contactBlock} md={6} xs={12} item>
                <Typography className={styles.contactBrokerTitle} color={colors['grey-400']}>
                    THIS PROPERTY IS LISTED BY
                </Typography>
                <Grid
                    alignItems='center'
                    className={styles.listed}
                    direction='row'
                    justifyContent='space-between'
                    spacing={16}
                    container
                >
                    <Grid item>
                        <Stack alignItems='center' direction='row' spacing={8}>
                            <Avatar alt='user' src={avatar}>
                                <Typography variant='hb32'>{nameToInitials}</Typography>
                            </Avatar>
                            <div>
                                <Typography className={styles.fullName} color={colors['darkNeutral-500']} variant='h4b'>
                                    {`${firstname} ${lastname}`}
                                </Typography>
                                <Typography color={colors['grey-400']} variant='h5r'>
                                    {department}
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Button fullWidth={isMobile} size='large' variant='outlined' onClick={onContactBrokerClick}>
                            Contact Broker
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
