import { baseApi as api } from '../configs/notes2';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        saveNotes: build.mutation<SaveNotesApiResponse, SaveNotesApiArg>({
            query: (queryArg) => ({ url: `/api/v2/notes2`, method: 'POST', body: queryArg.saveNotesRequest }),
        }),
        getNotes: build.query<GetNotesApiResponse, GetNotesApiArg>({
            query: (queryArg) => ({ url: `/api/v2/notes2/${queryArg.entityId}/${queryArg.entityType}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type SaveNotesApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type SaveNotesApiArg = {
    saveNotesRequest: SaveNotesRequest;
};
export type GetNotesApiResponse = /** status 200 OK */ GenericJsonResponseNoteDto;
export type GetNotesApiArg = {
    entityId: number;
    entityType: 'LOAN' | 'PROPERTY' | 'CONTACT' | 'DRAW';
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type SaveNotesRequest = {
    entityId: number;
    entityType: EntityType;
    notes: string;
};
export type NoteDto = {
    notes?: string;
};
export type GenericJsonResponseNoteDto = {
    status?: string;
    data?: NoteDto;
    message?: string;
    code?: string;
};
export enum EntityType {
    Loan = 'LOAN',
    Property = 'PROPERTY',
    Contact = 'CONTACT',
    Draw = 'DRAW',
}
export const { useSaveNotesMutation, useGetNotesQuery } = injectedRtkApi;
