import { formatter } from '@utils/index';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function Dropdown({ data, additionalData: { loanDetailsToProperties, properties } = {}, title }) {
    const loans = data.reduce((acc, { propertyId }) => {
        const filteredLoans = Object.values(loanDetailsToProperties).filter(({ propertiesIds }) =>
            propertiesIds.includes(propertyId)
        );
        return [...acc, ...filteredLoans];
    }, []);

    let summarySqft = 0;

    const loansWithProperties = loans.map(({ loanId, loanName, propertiesIds }) => {
        let summarySqftPerLoan = 0;
        const loanProperties = propertiesIds.reduce((acc, id) => {
            const propertiesData = properties.filter(({ propertyId, sqft }) => {
                // eslint-disable-next-line no-unused-expressions
                propertyId === id && (summarySqftPerLoan += sqft) && (summarySqft += sqft);
                return propertyId === id;
            });
            return acc.concat(propertiesData);
        }, []);
        return {
            loanId,
            loanName,
            loanProperties,
            summarySqftPerLoan,
        };
    });

    const handleToggleDropdown = (e) => {
        const parent = e.target.closest(`.${styles.item}`);
        parent.classList.toggle(styles.itemActive);
    };

    return (
        <div className={styles.tooltipTemplate}>
            <div className={styles.title}>
                <Typography className={styles.tenantName} color={colors['grey-200']} variant='smr'>
                    {title}
                </Typography>
                <div className={styles.summary}>
                    <Typography color={colors['grey-200']} variant='smsb'>
                        {formatter.number(summarySqft)}
                    </Typography>
                    <Typography color={colors['grey-200']} variant='smr'>
                        SF
                    </Typography>
                </div>
            </div>
            {loansWithProperties.map(({ loanName, loanId, summarySqftPerLoan, loanProperties }, idx) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div key={`${loanName}${loanId}${idx}`} className={styles.item} onClick={handleToggleDropdown}>
                        <div className={styles.row}>
                            <div className={styles.left}>
                                <Typography className={styles.loanName} color={colors.white} variant='smsb'>
                                    {loanName}
                                </Typography>
                                <Typography color={colors['grey-300']} variant='smr'>
                                    {loanId}
                                </Typography>
                            </div>
                            <div className={styles.right}>
                                <Typography color={colors.white} component='span' variant='smr'>
                                    {formatter.number(summarySqftPerLoan)}
                                </Typography>
                                <Typography color={colors['grey-300']} component='span' variant='smr'>
                                    SF
                                </Typography>
                                <Icon className={styles.chevron} component={ChevronIcon} fontSize={24} />
                            </div>
                        </div>
                        <div className={styles.dropdown}>
                            {loanProperties.map((property) => {
                                const { propertyId, propertyName, address, sqft } = property;
                                return (
                                    <div key={propertyId} className={styles.dropdownItem}>
                                        <div>
                                            <Typography color={colors.white} variant='smr'>
                                                {propertyName}
                                            </Typography>
                                            <Typography
                                                className={styles.propertyAddress}
                                                color={colors['grey-300']}
                                                variant='smr'
                                            >
                                                {address}
                                            </Typography>
                                        </div>
                                        <div className={styles.dropdownItemRight}>
                                            <Typography color={colors.white} component='span' variant='smr'>
                                                {formatter.number(sqft)}
                                            </Typography>
                                            <Typography color={colors['grey-300']} component='span' variant='smr'>
                                                SF
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Dropdown;
