import { createSlice } from '@reduxjs/toolkit';
import { put, select, take, takeLatest } from 'redux-saga/effects';

import { selectAuthState } from '@scc/store/ducks/auth';

import { DOCUMENT_TYPES } from '@components/DataTable/constants';

import { todoApi } from '@api';
import { TODO_FORM } from '@constants/modals';
import { todoItemTodoListAdapter } from '@modules/TodoForm/helpers/adapters';
import { FORM_CALL_CONTEXT } from '@modules/TodoForm/helpers/constants';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

const entity = '[loans/todos]';

const prepareUpdateTodoPayload = (todoItem) => {
    const { document, contact, propertyId, ...rest } = todoItem;

    const payload = {
        ...rest,
    };
    if (document) {
        payload.documentId = document.id;
    }
    if (contact) {
        payload.contact = contact.id;
    }
    if (propertyId) {
        payload.propertyReference = propertyId;
    }
    payload.connectedTasks = payload.connectedTasks?.map(({ id: taskId }) => taskId);
    return payload;
};

const initialArraysData = {
    LENDER: {
        todo: [],
        done: [],
        todoTable: [],
        doneTable: [],
    },
    BORROWER: {
        todo: [],
        done: [],
        todoTable: [],
        doneTable: [],
    },
};

const initialState = {
    userState: {},
    activeTab: '',
    isLender: false,
    data: initialArraysData,
    loaded: false,
    loading: false,
    sort: '',
};

const todoSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        initSwitch() {},
        switch(state, action) {
            const { userId, email, isLender, activeTab, loanId } = action.payload;
            state.userState = { userId, email };
            state.activeTab = activeTab;
            state.isLender = isLender;
            state.loanId = loanId;
        },
        load(state) {
            state.loaded = false;
            state.loading = true;
            state.data = initialArraysData;
        },
        setData(state, action) {
            state.data = { ...action.payload };
            state.loaded = true;
            state.loading = false;
        },
        create() {},
        update() {},
        changeStatus() {},
        remove() {},
        initCreate() {},
        checklists() {},
        [`load ${API_ERROR}`](state) {
            state.loading = false;
            state.loaded = true;
        },
        openTodo() {},
        changeSort(state, action) {
            state.sort = action.payload;
        },
    },
});

export const {
    load: loadTodoList,
    setData: setTodoData,
    openTodo: openTodoDrawer,
    initCreate: initCreateTodo,
    initSwitch: initSwitchTab,
    switch: switchTab,
    create: createTodo,
    changeStatus: changeTodoStatus,
    checklists: todoChecklists,
    remove: removeTodo,
    update: updateTodo,
    changeSort: changeSortTodos,
} = todoSlice.actions;

export default todoSlice.reducer;

export const selectTodoState = (store) => store.loans.todo;

function* loadTodoListSaga({ type }) {
    const { data: prevData, activeTab, loanId, isLender, sort } = yield select(selectTodoState);

    if (!activeTab || !loanId) {
        return;
    }

    yield put(apiRequest({ team: activeTab, loanId, sort }, todoApi.loadTodos, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const types = Object.keys(DOCUMENT_TYPES);

    const responseTodos = action.payload.data.map(({ documentType, propertyId, ...rest }) => ({
        ...rest,
        documentType: types.find((item) => DOCUMENT_TYPES[item].label === documentType),
        propertyId,
    }));

    const tableData = responseTodos.map((todo, index) => ({
        ...todo,
        userName: `${todo.assigneeFirstName} ${todo.assigneeLastName}`,
        propertyName: todo.propertyName || 'Property Name',
        taskType: todo.type,
        activeTab,
        isLender,
        index,
    }));

    const todoTable = [];
    const doneTable = [];
    for (let i = 0; i < tableData.length; i += 1) {
        if (tableData[i].status !== 'COMPLETED') {
            todoTable.push({ ...tableData[i], index: todoTable.length + 1 });
        } else {
            doneTable.push({ ...tableData[i], index: doneTable.length + 1 });
        }
    }

    const resData = { ...prevData };
    resData[activeTab] = {
        todoTable,
        doneTable,
        all: responseTodos,
    };
    yield put(setTodoData(resData));
}

function* initCreateTodoSaga({ payload }) {
    const { userState, activeTab, loanId } = yield select(selectTodoState);
    const initialUserState = payload?.userState ? { todo: payload.userState } : userState;

    yield put(
        showPrimaryAsideDrawer({
            content: TODO_FORM,
            header: null,
            data: {
                todoItem: {
                    ...initialUserState,
                    loanId,
                    assignerEmail: initialUserState?.todo?.email || userState?.email,
                    teamType: activeTab,
                },
                callContext: FORM_CALL_CONTEXT.TODO_LIST_CREATE,
            },
        })
    );
}

function* openTodoDrawerSaga({ payload }) {
    const { userState, activeTab, loanId, data } = yield select(selectTodoState);
    const todoItem = data[activeTab].all.find((item) => item.id === Number(payload));

    yield put(
        showPrimaryAsideDrawer({
            content: TODO_FORM,
            header: null,
            data: {
                todoItem: todoItemTodoListAdapter({
                    ...userState,
                    ...todoItem,
                    loanId,
                    teamType: activeTab.toUpperCase(),
                }),
                callContext: FORM_CALL_CONTEXT.TODO_LIST_VIEW,
            },
        })
    );
}

function* initSwitchTabSaga({ payload }) {
    const { isLender, activeTab, loanId } = payload;

    const {
        currentUser: { userId, name: email },
    } = yield select(selectAuthState);
    yield put(switchTab({ activeTab, isLender, userId, email, loanId }));
    yield put(loadTodoList());
}

function* createTodoSaga({ payload, type }) {
    yield put(apiRequest(payload, todoApi.createTodo, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadTodoList());
}

function* removeTodoSaga({ payload, type }) {
    yield put(apiRequest({ id: payload }, todoApi.deleteTodo, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadTodoList());
}

function* changeTodoStatusSaga({ payload, type }) {
    const { id, status } = payload;
    const { data, activeTab } = yield select(selectTodoState);
    const item = data[activeTab].all.find((todo) => todo.id === id);
    const preparedItem = prepareUpdateTodoPayload(item);
    const body = { ...preparedItem, status };
    yield put(apiRequest(body, todoApi.updateTodo, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadTodoList());
}

function* updateTodoSaga({ payload: todoItem, type }) {
    yield put(apiRequest(todoItem, todoApi.updateTodo, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadTodoList());
}

export function* watchToDo() {
    yield takeLatest(loadTodoList, loadTodoListSaga);
    yield takeLatest(initCreateTodo, initCreateTodoSaga);
    yield takeLatest(createTodo, createTodoSaga);
    yield takeLatest(changeTodoStatus, changeTodoStatusSaga);
    yield takeLatest(removeTodo, removeTodoSaga);
    yield takeLatest(initSwitchTab, initSwitchTabSaga);
    yield takeLatest(updateTodo, updateTodoSaga);
    yield takeLatest(openTodoDrawer, openTodoDrawerSaga);
    yield takeLatest(changeSortTodos, loadTodoListSaga);
}
