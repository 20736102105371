import { getPropertyTypeSize, isAgroProperty } from '@utils/properties';
import request from '@utils/request';

import { NOT_OPERATING_INCOME } from '@scc/pages/Portfolio/components/Underwriting/components/Valuation/helpers/constants';

export const maxAfterComma = (value, dp) => +parseFloat(value).toFixed(dp);

export const updateCapRates = async ({ parcelId, countyId }, data, { value, attribute, index }) => {
    const rates = data.map(({ scenario, capRate: { from, to } }) => ({ scenario, from: from / 100, to: to / 100 }));

    const res = rates.reduce((result, ratesItem, i) => {
        const updatedRatesItem = index === i ? { ...ratesItem, [attribute]: value / 100 } : ratesItem;
        return { ...result, [updatedRatesItem.scenario]: `${updatedRatesItem.from}-${updatedRatesItem.to}` };
    }, {});

    return request('/geoGekko/user/parcel/overrides/save', {
        method: 'POST',
        body: JSON.stringify({
            countyid: countyId,
            parcelid: parcelId,
            params: [{ paramname: 'CAP_RATE', paramvalue: res }],
        }),
    });
};

export const updateNoi = async (parcel, data, updatedData) => {
    const { parcelId, countyId } = parcel;
    const { value, index } = updatedData;

    const { scenario } = data[index];

    const body = {
        countyId,
        parcelId,
        name: NOT_OPERATING_INCOME,
        value: JSON.stringify({
            [scenario]: value,
        }),
    };

    return request('/api/v2/parcel-override', {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

export const prepareDataAfterUpdate = (arr) => {
    let resData = arr.map(({ noi, capRate, scenario, sqft, units }) => {
        const capRateFrom = capRate.from / 100;
        const capRateTo = capRate.to / 100;

        const value = {
            from: capRateTo ? maxAfterComma(noi / capRateTo, 0) : 0,
            to: capRateFrom ? maxAfterComma(noi / capRateFrom, 0) : 0,
        };

        const valuePerUnit = {
            from: capRateTo ? maxAfterComma(noi / capRateTo / units, 0) : 0,
            to: capRateFrom ? maxAfterComma(noi / capRateFrom / units, 0) : 0,
        };

        const valuePerSqft = {
            from: capRateTo ? maxAfterComma(noi / capRateTo / sqft, 0) : 0,
            to: capRateFrom ? maxAfterComma(noi / capRateFrom / sqft, 0) : 0,
        };
        return {
            noi,
            capRate,
            scenario,
            value,
            valuePerUnit,
            valuePerSqft,
            sqft,
            units,
            average: maxAfterComma((value.from + value.to) / 2, 0),
        };
    });
    let max = 0;
    resData.forEach(({ average }) => {
        max = max < average ? average : max;
    });
    resData = resData.map(({ average, ...rest }) => ({
        ...rest,
        average,
        percent: maxAfterComma((average / max) * 100, 0),
    }));
    return resData;
};

export const prepareTransactions = (items, isMf) =>
    (items || []).map(({ ...item }) => {
        const sizeProperty = getPropertyTypeSize(item.type) || 'size';
        const size = isAgroProperty(item.type) ? item.lotsize : item.size;
        const fullPrice = item.price * item[sizeProperty];
        const sfPrice = size && item.price && Math.round(fullPrice / size);
        const resItem = { ...item };
        if (isMf) {
            resItem.unitPrice = item.units && item.price && Math.round(fullPrice / item.units);
        }
        return {
            ...resItem,
            sfPrice,
        };
    });
