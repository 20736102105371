const formControl = () => ({
    MuiFormControl: {
        styleOverrides: {
            marginDense: {
                marginTop: 28,
                marginBottom: 4,
            },
        },
    },
});
export default formControl;
