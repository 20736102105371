const accordionSummary = () => ({
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                '&.Mui-expanded': {},
                minHeight: 30,
            },
            expandIconWrapper: {
                color: 'inherit',
            },
        },
    },
});

export default accordionSummary;
