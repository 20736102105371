import { put, take, delay, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { membersApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { hideDialog } from '@store/ducks/ui/dialog';
import { formatPropertyAddress } from '@utils/properties';

const entity = '[memberProperties]';

const initialState = {
    data: [],
    search: {},
    memberId: null,
    loaded: false,
    loading: false,
};

const memberPropertiesSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            const { memberId, search } = action.payload;
            if (search) {
                state.search = search;
            }

            if (memberId) {
                state.memberId = memberId;
            }
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            const { data } = action.payload;
            state.data = data;
            state.loaded = true;
            state.loading = false;
        },
        addProperty() {},
    },
});

export const {
    load: loadMemberProperties,
    setData: setMemberProperties,
    addProperty: addPropertyMember,
} = memberPropertiesSlice.actions;

export default memberPropertiesSlice.reducer;

export const selectMemberPropertiesState = (store) => store.members.properties;

export const selectMemberPropertiesCollaterals = (store) => {
    const initialCombinedState = {
        properties: store.members.properties.data.length || 0,
        size: 0,
        units: 0,
        amount: 0,
    };

    return (
        store.members.properties.data?.reduce((acc, cur) => {
            const nextAcc = { ...acc };

            if (cur.size) nextAcc.size += cur.size;
            if (cur.units) nextAcc.units += cur.units;
            if (cur.totalAmount) nextAcc.amount += cur.totalAmount;
            nextAcc.url = `/portfolio.html?memberId=${cur.memberId}`;

            return nextAcc;
        }, initialCombinedState) || {}
    );
};

function* loadMemberPropertiesSaga({ payload, type }) {
    yield delay(400);
    const { search, memberId } = payload;
    yield put(apiRequest({ ...search, memberId }, membersApi.getMemberProperties, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(
        setMemberProperties({
            data: action.payload.data.map(({ propertyName, address, ...item }) => ({
                ...item,
                address,
                propertyName: !propertyName ? formatPropertyAddress(address)[0] : propertyName,
            })),
            search,
        })
    );
}

function* addPropertyMemberSaga({ payload, type }) {
    const { memberId } = payload;
    yield put(apiRequest(payload, membersApi.createProperty, type));
    yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    yield put(hideDialog());
    yield put(loadMemberProperties({ memberId }));
}

export function* watchMemberProperties() {
    yield takeLatest(loadMemberProperties, loadMemberPropertiesSaga);
    yield takeLatest(addPropertyMember, addPropertyMemberSaga);
}
