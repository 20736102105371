import request from '@utils/request';

const BASE_URL = '/api/v2/orders';

export async function createOrderIntention(data) {
    return request(`${BASE_URL}/intention`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function createOrder(data) {
    return request(`${BASE_URL}/new`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function updateRequired({ id, documents = [] }) {
    return request(`${BASE_URL}/update-required-documents`, {
        method: 'POST',
        body: JSON.stringify({
            orderId: id,
            presentDocuments: documents,
        }),
    });
}

export async function moreInfoResponse({ id, comment }) {
    return request(`${BASE_URL}/more-info-response`, {
        method: 'POST',
        body: JSON.stringify({
            orderId: id,
            comment,
        }),
    });
}

export async function addDocuments({ id, rr = [], os = [] }) {
    return request(`${BASE_URL}/add-documents`, {
        method: 'POST',
        body: JSON.stringify({
            orderId: id,
            rentRolls: rr,
            statements: os,
        }),
    });
}

export async function getOrders({ size, page = 0 }) {
    return request(BASE_URL, {
        params: {
            size,
            page,
        },
    });
}
