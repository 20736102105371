import { baseApi as api } from '../configs/appraisalReport';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        updateTextField: build.mutation<UpdateTextFieldApiResponse, UpdateTextFieldApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/appraisal-report/${queryArg.id}/update-field`,
                method: 'POST',
                body: queryArg.updateAppraisalReportTextFieldRequest,
            }),
        }),
        getAppraisalReportChatMessages: build.query<
            GetAppraisalReportChatMessagesApiResponse,
            GetAppraisalReportChatMessagesApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/appraisal-report/${queryArg.id}/message` }),
        }),
        postAppraisalReportChatMessages: build.mutation<
            PostAppraisalReportChatMessagesApiResponse,
            PostAppraisalReportChatMessagesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/appraisal-report/${queryArg.id}/message`,
                method: 'POST',
                body: queryArg.postChatMessageRequest,
            }),
        }),
        updateCustomFields: build.mutation<UpdateCustomFieldsApiResponse, UpdateCustomFieldsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/appraisal-report/${queryArg.id}/update-custom-fields`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        updateImage: build.mutation<UpdateImageApiResponse, UpdateImageApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/appraisal-report/${queryArg.id}/update-image`,
                method: 'POST',
                body: queryArg.updateAppraisalReportImageRequest,
            }),
        }),
        deleteImage: build.mutation<DeleteImageApiResponse, DeleteImageApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/appraisal-report/${queryArg.id}/delete-image`,
                method: 'DELETE',
                body: queryArg.deleteAppraisalReportImageRequest,
            }),
        }),
        getAppraisalReportByDocumentId: build.query<
            GetAppraisalReportByDocumentIdApiResponse,
            GetAppraisalReportByDocumentIdApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/appraisal-report/get-by-document/${queryArg.documentId}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type UpdateTextFieldApiResponse = /** status 200 OK */ GenericJsonResponseAppraisalReportDto;
export type UpdateTextFieldApiArg = {
    /** Appraisal Report id */
    id: number;
    updateAppraisalReportTextFieldRequest: UpdateAppraisalReportTextFieldRequest;
};
export type GetAppraisalReportChatMessagesApiResponse = /** status 200 OK */ GenericJsonResponseListChatMessageDto;
export type GetAppraisalReportChatMessagesApiArg = {
    /** Id of the appraisal report */
    id: number;
};
export type PostAppraisalReportChatMessagesApiResponse = /** status 200 OK */ GenericJsonResponseListChatMessageDto;
export type PostAppraisalReportChatMessagesApiArg = {
    /** Id of the appraisal report */
    id: number;
    postChatMessageRequest: PostChatMessageRequest;
};
export type UpdateCustomFieldsApiResponse = /** status 200 OK */ GenericJsonResponseAppraisalReportDto;
export type UpdateCustomFieldsApiArg = {
    /** Appraisal Report id */
    id: number;
    body: AppraisalReportCustomField[];
};
export type UpdateImageApiResponse = /** status 200 OK */ GenericJsonResponseAppraisalReportDto;
export type UpdateImageApiArg = {
    /** Appraisal Report id */
    id: number;
    updateAppraisalReportImageRequest: UpdateAppraisalReportImageRequest;
};
export type DeleteImageApiResponse = /** status 200 OK */ GenericJsonResponseAppraisalReportDto;
export type DeleteImageApiArg = {
    /** Appraisal Report id */
    id: number;
    deleteAppraisalReportImageRequest: DeleteAppraisalReportImageRequest;
};
export type GetAppraisalReportByDocumentIdApiResponse = /** status 200 OK */ GenericJsonResponseAppraisalReportDto;
export type GetAppraisalReportByDocumentIdApiArg = {
    /** Id of the document related to the appraisal report */
    documentId: number;
};
export type AppraisalReportPromptValue = {
    description?: string;
    value?: string;
    hint?: string;
};
export type AppraisalReportImage = {
    url?: string;
    category?: string;
};
export type AppraisalReportCustomField = {
    name?: string;
    value?: string;
};
export type AppraisalReportDto = {
    id?: number;
    documentId?: number;
    assistantId?: string;
    promptValues?: {
        [key: string]: AppraisalReportPromptValue;
    };
    siteImages?: AppraisalReportImage[];
    salesComps?: string;
    rentComps?: string;
    unitMix?: string;
    tenantsTable?: string;
    salesCompsHint?: string;
    rentCompsHint?: string;
    unitMixHint?: string;
    tenantsTableHint?: string;
    customFields?: AppraisalReportCustomField[];
};
export type GenericJsonResponseAppraisalReportDto = {
    status?: string;
    data?: AppraisalReportDto;
    message?: string;
    code?: string;
};
export type UpdateAppraisalReportTextFieldRequest = {
    field: Field;
    value: string;
};
export type ChatMessageDto = {
    id?: string;
    message?: string;
    role?: string;
    createdAt?: number;
};
export type GenericJsonResponseListChatMessageDto = {
    status?: string;
    data?: ChatMessageDto[];
    message?: string;
    code?: string;
};
export type PostChatMessageRequest = {
    message: string;
};
export type UpdateAppraisalReportImageRequest = {
    url: string;
    category: string;
};
export type DeleteAppraisalReportImageRequest = {
    url: string;
};
export enum Field {
    PropertyDescription = 'PROPERTY_DESCRIPTION',
    PropertyName = 'PROPERTY_NAME',
    PropertyType = 'PROPERTY_TYPE',
    Address = 'ADDRESS',
    County = 'COUNTY',
    Parcel = 'PARCEL',
    TaxAssessmentValue = 'TAX_ASSESSMENT_VALUE',
    HighestBestAsVacant = 'HIGHEST_BEST_AS_VACANT',
    HighestBestAsImproved = 'HIGHEST_BEST_AS_IMPROVED',
    ExposureTime = 'EXPOSURE_TIME',
    MarketingTime = 'MARKETING_TIME',
    InspectionDate = 'INSPECTION_DATE',
    ValueDate = 'VALUE_DATE',
    ReportDate = 'REPORT_DATE',
    ProspectiveValueDate = 'PROSPECTIVE_VALUE_DATE',
    SiteImage = 'SITE_IMAGE',
    LandAreaUsable = 'LAND_AREA_USABLE',
    LandAreaUnusable = 'LAND_AREA_UNUSABLE',
    Topography = 'TOPOGRAPHY',
    Shape = 'SHAPE',
    Access = 'ACCESS',
    ExposureAppeal = 'EXPOSURE_APPEAL',
    Frontage = 'FRONTAGE',
    TrafficCount = 'TRAFFIC_COUNT',
    Zoning = 'ZONING',
    FloodZone = 'FLOOD_ZONE',
    SeismicZone = 'SEISMIC_ZONE',
    FireZone = 'FIRE_ZONE',
    NumberOfUnits = 'NUMBER_OF_UNITS',
    AvgUnitSize = 'AVG_UNIT_SIZE',
    GrossBuildingArea = 'GROSS_BUILDING_AREA',
    NetRentableArea = 'NET_RENTABLE_AREA',
    NumberOfBuildings = 'NUMBER_OF_BUILDINGS',
    NumberOfStories = 'NUMBER_OF_STORIES',
    NumberOfTenants = 'NUMBER_OF_TENANTS',
    TenantsTable = 'TENANTS_TABLE',
    BuiltYear = 'BUILT_YEAR',
    RenovatedYear = 'RENOVATED_YEAR',
    Class = 'CLASS',
    Condition = 'CONDITION',
    Marketability = 'MARKETABILITY',
    TypeOfConstruction = 'TYPE_OF_CONSTRUCTION',
    ParkingType = 'PARKING_TYPE',
    ParkingSpacesNumber = 'PARKING_SPACES_NUMBER',
    OpenParkingSpaces = 'OPEN_PARKING_SPACES',
    CoveredParkingSpaces = 'COVERED_PARKING_SPACES',
    ParkingSpacesUnits = 'PARKING_SPACES_UNITS',
    ParkingComment = 'PARKING_COMMENT',
    PropertyAmenities = 'PROPERTY_AMENITIES',
    SalePrice = 'SALE_PRICE',
    SaleUnitPrice = 'SALE_UNIT_PRICE',
    SaleSqftPrice = 'SALE_SQFT_PRICE',
    InvestmentHighlights = 'INVESTMENT_HIGHLIGHTS',
    ValueAddOpportunities = 'VALUE_ADD_OPPORTUNITIES',
    CurrentOccupancy = 'CURRENT_OCCUPANCY',
    StabilizedOccupancy = 'STABILIZED_OCCUPANCY',
    AvgUnitRent = 'AVG_UNIT_RENT',
    PotentialGrossIncome = 'POTENTIAL_GROSS_INCOME',
    AchievableRent = 'ACHIEVABLE_RENT',
    EffectiveGrossIncome = 'EFFECTIVE_GROSS_INCOME',
    TotalExpenses = 'TOTAL_EXPENSES',
    ExpenseRecovery = 'EXPENSE_RECOVERY',
    CapitalizationRate = 'CAPITALIZATION_RATE',
    Noi = 'NOI',
    MarketOverview = 'MARKET_OVERVIEW',
    MarketValue = 'MARKET_VALUE',
    ReplacementCost = 'REPLACEMENT_COST',
    IncomeApproach = 'INCOME_APPROACH',
    SalesCompsApproach = 'SALES_COMPS_APPROACH',
    LandValuation = 'LAND_VALUATION',
    SalesComps = 'SALES_COMPS',
    RentComps = 'RENT_COMPS',
    PropertyCondition = 'PROPERTY_CONDITION',
    MarketGrowth = 'MARKET_GROWTH',
    MarketRisks = 'MARKET_RISKS',
    MarketSwot = 'MARKET_SWOT',
    MarketAbsorptionRates = 'MARKET_ABSORPTION_RATES',
    MarketSupplyDemand = 'MARKET_SUPPLY_DEMAND',
    EnvironmentalRisks = 'ENVIRONMENTAL_RISKS',
    TenantRisk = 'TENANT_RISK',
    MarketOccupancy = 'MARKET_OCCUPANCY',
    MarketRents = 'MARKET_RENTS',
    ActualAge = 'ACTUAL_AGE',
    EffectiveAge = 'EFFECTIVE_AGE',
    EconomicLife = 'ECONOMIC_LIFE',
    RemainingLife = 'REMAINING_LIFE',
    UnitMix = 'UNIT_MIX',
    RentRoll = 'RENT_ROLL',
    Financials = 'FINANCIALS',
    BasicConstruction = 'BASIC_CONSTRUCTION',
    Foundation = 'FOUNDATION',
    Framing = 'FRAMING',
    ExteriorWalls = 'EXTERIOR_WALLS',
    RoofType = 'ROOF_TYPE',
    RoofCover = 'ROOF_COVER',
    Insulation = 'INSULATION',
    Plumbing = 'PLUMBING',
    Hvac = 'HVAC',
    AirConditioning = 'AIR_CONDITIONING',
    WaterSewer = 'WATER_SEWER',
    Gas = 'GAS',
    Heating = 'HEATING',
    HotWater = 'HOT_WATER',
    Lighting = 'LIGHTING',
    Electrical = 'ELECTRICAL',
    Electricity = 'ELECTRICITY',
    InteriorWalls = 'INTERIOR_WALLS',
    Windows = 'WINDOWS',
    Doors = 'DOORS',
    Flooring = 'FLOORING',
    Elevators = 'ELEVATORS',
    ProjectAmenities = 'PROJECT_AMENITIES',
    UnitAmenities = 'UNIT_AMENITIES',
    Appliances = 'APPLIANCES',
    Laundry = 'LAUNDRY',
    Countertops = 'COUNTERTOPS',
    Cabinets = 'CABINETS',
    CommonArea = 'COMMON_AREA',
    Security = 'SECURITY',
    FireProtection = 'FIRE_PROTECTION',
    Landscaping = 'LANDSCAPING',
    Signage = 'SIGNAGE',
    DeferredMaintenance = 'DEFERRED_MAINTENANCE',
    HazardousMaterials = 'HAZARDOUS_MATERIALS',
    AdaCompliance = 'ADA_COMPLIANCE',
    FuelSource = 'FUEL_SOURCE',
    UtilityMetering = 'UTILITY_METERING',
    SprinklerSystem = 'SPRINKLER_SYSTEM',
    LifeSafety = 'LIFE_SAFETY',
    Parking = 'PARKING',
    TenantName = 'TENANT_NAME',
    TenantAddress = 'TENANT_ADDRESS',
    TenantTelephone = 'TENANT_TELEPHONE',
    TenantEmail = 'TENANT_EMAIL',
    TenantContactName = 'TENANT_CONTACT_NAME',
    LandlordName = 'LANDLORD_NAME',
    LandlordAddress = 'LANDLORD_ADDRESS',
    LandlordTelephone = 'LANDLORD_TELEPHONE',
    LandlordEmail = 'LANDLORD_EMAIL',
    LandlordContactName = 'LANDLORD_CONTACT_NAME',
    PremisesAddress = 'PREMISES_ADDRESS',
    PremisesUnitId = 'PREMISES_UNIT_ID',
    PremisesSize = 'PREMISES_SIZE',
    PremisesType = 'PREMISES_TYPE',
    PremisesDescription = 'PREMISES_DESCRIPTION',
    PremisesLeaseStart = 'PREMISES_LEASE_START',
    PremisesLeaseEnd = 'PREMISES_LEASE_END',
    PremisesPermittedUse = 'PREMISES_PERMITTED_USE',
    PremisesParking = 'PREMISES_PARKING',
    PremisesSignage = 'PREMISES_SIGNAGE',
    BaseRentTable = 'BASE_RENT_TABLE',
    FreeRentTable = 'FREE_RENT_TABLE',
    RenewalOptionsTable = 'RENEWAL_OPTIONS_TABLE',
    BaseRentDescription = 'BASE_RENT_DESCRIPTION',
    FreeRentDescription = 'FREE_RENT_DESCRIPTION',
    RenewalOptionsDescription = 'RENEWAL_OPTIONS_DESCRIPTION',
    AdditionalRent = 'ADDITIONAL_RENT',
    PercentageRent = 'PERCENTAGE_RENT',
    ExpensesDefinition = 'EXPENSES_DEFINITION',
    ExpensesExclusions = 'EXPENSES_EXCLUSIONS',
    ExpensesTenantObligations = 'EXPENSES_TENANT_OBLIGATIONS',
    ExpensesLandlordObligations = 'EXPENSES_LANDLORD_OBLIGATIONS',
    ExpensesTenantProrataShares = 'EXPENSES_TENANT_PRORATA_SHARES',
    MaintenanceDefinition = 'MAINTENANCE_DEFINITION',
    MaintenanceTenantObligations = 'MAINTENANCE_TENANT_OBLIGATIONS',
    MaintenanceLandlordObligations = 'MAINTENANCE_LANDLORD_OBLIGATIONS',
    ImprovementsDefinition = 'IMPROVEMENTS_DEFINITION',
    ImprovementsTenantObligations = 'IMPROVEMENTS_TENANT_OBLIGATIONS',
    ImprovementsLandlordObligations = 'IMPROVEMENTS_LANDLORD_OBLIGATIONS',
    UtilitiesDefinition = 'UTILITIES_DEFINITION',
    UtilitiesTenantObligations = 'UTILITIES_TENANT_OBLIGATIONS',
    UtilitiesLandlordObligations = 'UTILITIES_LANDLORD_OBLIGATIONS',
    JanitorialDefinition = 'JANITORIAL_DEFINITION',
    JanitorialTenantObligations = 'JANITORIAL_TENANT_OBLIGATIONS',
    JanitorialLandlordObligations = 'JANITORIAL_LANDLORD_OBLIGATIONS',
    InsurancePaymentObligations = 'INSURANCE_PAYMENT_OBLIGATIONS',
    InsuranceTenant = 'INSURANCE_TENANT',
    InsuranceLandlord = 'INSURANCE_LANDLORD',
    TaxesDefinition = 'TAXES_DEFINITION',
    TaxesTenantObligations = 'TAXES_TENANT_OBLIGATIONS',
    TaxesLandlordObligations = 'TAXES_LANDLORD_OBLIGATIONS',
    CamDefinition = 'CAM_DEFINITION',
    CamTenantObligations = 'CAM_TENANT_OBLIGATIONS',
    CamLandlordObligations = 'CAM_LANDLORD_OBLIGATIONS',
    SecurityTenantObligations = 'SECURITY_TENANT_OBLIGATIONS',
    SecurityLandlordObligations = 'SECURITY_LANDLORD_OBLIGATIONS',
    OtherChargesTenantObligations = 'OTHER_CHARGES_TENANT_OBLIGATIONS',
    OtherChargesLandlordObligations = 'OTHER_CHARGES_LANDLORD_OBLIGATIONS',
    BaseRent = 'BASE_RENT',
    AbatmentConcessionFreerent = 'ABATMENT_CONCESSION_FREERENT',
    AbatmentConcessionFreerentIncreaseDate = 'ABATMENT_CONCESSION_FREERENT_INCREASE_DATE',
    AbatmentConcessionFreerentIncreaseAmount = 'ABATMENT_CONCESSION_FREERENT_INCREASE_AMOUNT',
}
export const {
    useUpdateTextFieldMutation,
    useGetAppraisalReportChatMessagesQuery,
    usePostAppraisalReportChatMessagesMutation,
    useUpdateCustomFieldsMutation,
    useUpdateImageMutation,
    useDeleteImageMutation,
    useGetAppraisalReportByDocumentIdQuery,
} = injectedRtkApi;
