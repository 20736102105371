import { CustomContentProps } from 'notistack';
import { forwardRef } from 'react';

import { Snackbar } from '@scc/components/snackbar/Snackbar';

import colors from '@themes/palette/export.module.scss';

import DangerSvg from '@icons/outlined/danger.svg';

export const Warning = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    return <Snackbar ref={ref} {...props} color={colors['error-main']} icon={DangerSvg} />;
});
