import { CustomContentProps } from 'notistack';
import { forwardRef } from 'react';

import { Snackbar } from '@scc/components/snackbar/Snackbar';

import colors from '@themes/palette/export.module.scss';

import TickSvg from '@icons/outlined/tick-circle.svg';

export const Success = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    return <Snackbar ref={ref} {...props} color={colors['success-main']} icon={TickSvg} />;
});
