import cx from 'classnames';
import { useState } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';
import QuotesIcon from '@scc/assets/icons/quotes.svg';

import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Paper from '@ui/Paper';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './comment.module.scss';

const defaultProps = {
    name: '',
    image: '',
    job: '',
    comment: '',
    noShowMore: false,
    column: true,
};

const AVATAR_SIZE = {
    md: {
        width: 40,
        height: 40,
    },
    lg: {
        width: 90,
        height: 90,
    },
};

function SliderComment({ hideShowMore, column, hideQuotes, name, image, job, comment, size = 'md' }) {
    const [showMore, setShowMore] = useState(hideShowMore);
    return (
        <div
            className={cx(styles.comment, {
                [styles.columnLayout]: column,
            })}
        >
            <div className={styles.profile} data-swiper-parallax='-300'>
                <Avatar alt={job} src={image} sx={AVATAR_SIZE[size]} />
                <div className={styles.profileInfo}>
                    <Typography variant='smr'>{name}</Typography>
                    <Typography color={colors['grey-400']} dangerouslySetInnerHTML={{ __html: job }} variant='smr' />
                </div>
            </div>
            <div className={styles.quotesWrapper}>
                {!hideQuotes && (
                    <span className={styles.quotes}>
                        <Icon
                            component={QuotesIcon}
                            data-swiper-parallax='-900'
                            fontSize='60px'
                            sx={{ stroke: 'black' }}
                            viewBox='0 0 60 43'
                        />
                        <span data-swiper-parallax='-600' />
                    </span>
                )}
                <div className={styles.messageWrapper} data-swiper-parallax='-300'>
                    <Typography
                        className={cx({
                            [styles.message]: !hideShowMore,
                            [styles.messageMore]: showMore,
                        })}
                        component='div'
                        variant='mdr'
                    >
                        {comment}
                        {!showMore && <div className={styles.gradientback} onClick={() => setShowMore(!showMore)} />}
                    </Typography>
                    {!hideShowMore && (
                        <Button.Link className={styles.buttonShowMore} onClick={() => setShowMore(!showMore)}>
                            {showMore ? 'Less' : 'More'}{' '}
                            <Icon className={showMore && styles.rotated} component={ChevronIcon} fontSize={13} />
                        </Button.Link>
                    )}
                </div>
            </div>
        </div>
    );
}

SliderComment.Square = function SliderCommentSquare({ name, image, job, comment }) {
    return (
        <Paper className={`${styles.paper} ${styles.square}`} variant='none'>
            <div className={styles.profile}>
                <Avatar alt={job} src={image} sx={{ height: '80px', width: '80px' }} />

                <Typography data-swiper-parallax='-300' variant='mdr'>
                    {comment}
                </Typography>

                <div className={styles.user} data-swiper-parallax='-300'>
                    <Typography.Uppercase variant='mdsb'>{name}</Typography.Uppercase>
                    <Typography color={colors['grey-400']} dangerouslySetInnerHTML={{ __html: job }} variant='mdr' />
                </div>
            </div>
        </Paper>
    );
};

SliderComment.Square.displayName = 'SliderCommentSquare';

SliderComment.defaultProps = defaultProps;

export default SliderComment;
