import PropTypes from 'prop-types';

const defaultProps = {
    label: '',
};

const Emoji = function ({ symbol, label }) {
    return (
        <span className='emoji' role='img' aria-label={label} aria-hidden={label ? 'false' : 'true'}>
            {symbol}
        </span>
    );
};

Emoji.propTypes = {
    symbol: PropTypes.node.isRequired,
    label: PropTypes.string,
};

Emoji.defaultProps = defaultProps;

export default Emoji;
