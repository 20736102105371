import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { dashboardApi } from '@api';
import { API_ERROR, API_REQUEST, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[dashboard/todos]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const todosSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load() {},
        setData(state, action) {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith(API_REQUEST),
                (state) => {
                    state.loading = true;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith(API_SUCCESS) || action.type.endsWith(API_ERROR),
                (state) => {
                    state.loading = false;
                    state.loaded = true;
                }
            );
    },
});

export const { load: loadTodos, setData: setTodos } = todosSlice.actions;

export default todosSlice.reducer;

export const selectTodosState = (store) => store.dashboard.todos;

function* loadTodosSaga({ type, payload }) {
    yield put(apiRequest(payload, dashboardApi.getTodos, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    if (action.payload?.data) {
        yield put(setTodos(action.payload.data));
    }
}

export function* watchTodos() {
    yield takeLatest(loadTodos, loadTodosSaga);
}
