import Icon from '@ui/Icon';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import CallSvg from '@icons/outlined/call.svg';
import SmsSvg from '@icons/outlined/sms.svg';

import styles from './styles.module.scss';

export default function ContactsInfo({ email, phone }) {
    return (
        <Stack justifyContent='center' spacing={3}>
            {email && (
                <Stack alignItems='center' direction='row'>
                    <Icon className={styles.asset} color={colors['grey-400']} component={SmsSvg} fontSize={14} />
                    <Typography color={colors['darkNeutral-500']} variant='hr13'>
                        {email}
                    </Typography>
                </Stack>
            )}
            {phone && (
                <Stack alignItems='center' direction='row'>
                    <Icon className={styles.asset} color={colors['grey-400']} component={CallSvg} fontSize={14} />
                    <Typography className={styles.nowrap} color={colors['grey-400']} variant='pr12'>
                        {phone}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
}
