import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function Label({ children }) {
    return (
        <Typography className={styles.label} color={colors.white} component='span' variant='h4b'>
            {children}
        </Typography>
    );
}

export default Label;
