import colors from '@themes/palette/export.module.scss';

const table = () => ({
    MuiTableCell: {
        styleOverrides: {
            sizeSmall: {
                padding: '10px 14px',
                '&:first-child': {
                    paddingLeft: 0,
                },
                '&:last-child': {
                    paddingRight: 0,
                },
            },
            sizeXs: {
                padding: '5px 8px',
            },
            root: {
                fontFamily: 'Open Sans',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&.Mui-selected': {
                    backgroundColor: colors['grey-A200'],
                },
            },
        },
    },
});

export default table;
