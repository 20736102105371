import { generatedApi } from '@scc/services/generated/referLoan';

const enhanceOptions = {
    endpoints: {
        findRefer: {
            async onQueryStarted(findReferRequest, { dispatch, queryFulfilled }) {
                const id = findReferRequest.id;
                const {
                    data: {
                        data: { data, files },
                        status,
                    },
                } = await queryFulfilled;

                if (status === 'success') {
                    dispatch(
                        generatedApi.util.updateQueryData('findRefer', { id }, (draft) => {
                            Object.assign(draft, { data: { data: { ...data, files: files } } });
                        })
                    );
                }
            },
        },
    },
} as const;

export const referLoanApi = generatedApi.enhanceEndpoints(enhanceOptions);
