import { generatedApi, GetUserLoansByStatusApiResponse } from '@scc/services/generated/denormalizedLoans';

export const DENORMALIZED_LOANS_TAGS = {
    GET_USER_LOANS_BY_STATUS: 'denormalizedLoans.getUserLoansByStatus',
} as const;

const enhanceOptions = {
    addTagTypes: [DENORMALIZED_LOANS_TAGS.GET_USER_LOANS_BY_STATUS],
    endpoints: {
        getUserLoansByStatus: {
            providesTags: [DENORMALIZED_LOANS_TAGS.GET_USER_LOANS_BY_STATUS],
            // eslint-disable-next-line consistent-return
            merge: (currentCache: GetUserLoansByStatusApiResponse, nextData: GetUserLoansByStatusApiResponse) => {
                if (currentCache?.data?.content && nextData?.data?.content) {
                    if (currentCache.data?.content[0]?.loanDetailsId === nextData.data?.content[0]?.loanDetailsId) {
                        return nextData;
                    }
                    Object.assign(currentCache.data, {
                        content: currentCache.data.content.concat(nextData.data.content),
                    });
                }
            },
            forceRefetch({ previousArg, currentArg }) {
                return previousArg?.page !== currentArg?.page || currentArg?.invalidateCache;
            },
            serializeQueryArgs: ({ queryArgs }) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { page, ...rest } = queryArgs;

                return rest;
            },
            keepUnusedDataFor: 0,
        },
    },
};

export const denormalizedLoansApi = generatedApi.enhanceEndpoints(enhanceOptions);
