import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import { rentRollComparisonLabel } from '@scc/modules/ExportExcelReport/helpers/formatters';

import { DOCUMENT_TYPES } from '@constants/documents';

import { TExcelExportSelectOptions, ExcelDocument, RentRollMetric } from '../ExportExcelReport.types';

import { SCENARIO_OPTIONS, USER_LOAN_DEFAULT_MODEL_DATE } from './constants';

export const generateScenariosOptions = (customScenarios: string[]) => {
    return customScenarios.map(
        (name) =>
            SCENARIO_OPTIONS[name] ?? {
                label: name,
                value: name,
            }
    );
};

export const generateLoanTableOptions = (asOfDates: string[]): TExcelExportSelectOptions[] => {
    return [
        USER_LOAN_DEFAULT_MODEL_DATE,
        ...(asOfDates ?? []).map((date) => ({ label: format(parseISO(date), 'dd MMM, yyyy'), value: date })),
    ];
};

export const generateRentRollComparisonOptions = (
    documents: ExcelDocument[],
    rentRollMetric: RentRollMetric
): TExcelExportSelectOptions[] => {
    const hasUnderwritingRentRoll = documents.some(({ rentRollILabel }) => rentRollILabel === 'UNDERWRITING');

    const options = documents
        .filter((document) => document.type === DOCUMENT_TYPES.RR && !document.combined)
        .map(({ fileName, documentDate, type, id, rentRollILabel }) => ({
            value: String(id),
            label: rentRollILabel === 'UNDERWRITING' ? 'Underwriting Rent Roll' : fileName,
            renderOption:
                rentRollILabel === 'UNDERWRITING'
                    ? rentRollComparisonLabel({
                          name: 'Underwriting Rent Roll',
                          date: documentDate,
                      })
                    : rentRollComparisonLabel({ name: fileName, date: documentDate }),
            type,
            rentRollILabel,
        }));

    if (!hasUnderwritingRentRoll && rentRollMetric.id) {
        options.unshift({
            value: String(rentRollMetric.propertyId),
            label: 'Underwriting Rent Roll Metric Input',
            renderOption: rentRollComparisonLabel({
                name: 'Underwriting Rent Roll Metric Input',
                date: rentRollMetric.rentRollDate,
            }),
            type: 'RENT_ROLL_METRIC',
            rentRollILabel: '',
        });
    }

    return options;
};
