import { useMemo, useState } from 'react';

import Popover from '@ui/Popover';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function ShowMoreEntities({ list, renderItem }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const popoverMinHeight = useMemo(() => (list.length ? (36 * list.length > 10 ? 10 : list.length) : 200), [list]);

    const onMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return list.length > 1 ? (
        <div>
            <div className={styles.countIndicator}>
                <Typography
                    aria-haspopup='true'
                    aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
                    color={colors.white}
                    variant='psb10'
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                >
                    +{list.length - 1}
                </Typography>
            </div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                id='mouse-over-popover'
                open={!!anchorEl}
                sx={{
                    pointerEvents: 'none',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
                onClose={() => setAnchorEl(null)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: `${popoverMinHeight}px`,
                    }}
                >
                    {list.map((item) => renderItem(item))}
                </div>
            </Popover>
        </div>
    ) : null;
}

export default ShowMoreEntities;
