import MuiStack, { StackProps } from '@mui/material/Stack';

import { createBaseProxy } from '@utils/components';

const Stack = createBaseProxy<StackProps>(MuiStack, 'Stack');

Stack.Menu = (props) => {
    return <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={10} {...props} />;
};

export default Stack;
