import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery, createConfig } from '@scc/services/helpers';

const ENTITY = 'userLoans';

export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});

export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: [
        'getDebtService',
        'updateHistoricalLoanData',
        'updateLoanData',
        'updateLoanCovenants',
        'deleteLoanCovenants',
        'saveLoanCovenants',
        'getLoanDraw',
        'createLoanDraw',
        'getPreDevelopmentDrawFunding',
        'updatePreDevelopmentDrawFunding',
        'getPreDevelopmentDrawDocuments',
        'updateDocumentDraw',
        'getPreDevelopmentDrawDocuments',
        'getPreDevelopmentDraw',
        'updatePreDevelopmentDraw',
        'deletePreDevelopmentDraw',
        'getDrawSummary',
        'getDrawSummaryDetails',
        'getLoanById',
        'addEntryToList',
        'addNewLoanProgram',
        'getLoanStatusEvents',
        'addLoanStatusEvent',
        'deleteLoanStatusEvent',
        'updateLoanStatusEvent',
        'getNewLoanProgramList',
    ],
});
