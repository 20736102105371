import colors from '@themes/palette/export.module.scss';

const inputLabel = () => ({
    MuiInputLabel: {
        styleOverrides: {
            root: {
                color: colors['grey-400'],
                '&.Mui-error': {
                    color: `${colors.error} !important`,
                },
            },
            shrink: {
                transform: 'translate(0, 0) scale(0.75)',
            },
            standard: {
                '&.Mui-focused': {
                    color: colors['grey-400'],
                },
            },
        },
        variants: [
            {
                props: {
                    variant: 'select',
                },
                style: {
                    transform: 'translate(12px, -8px) scale(0.75)',
                },
            },
        ],
    },
});

export default inputLabel;
