import { baseApi as api } from '../configs/referLoan';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        findRefer: build.query<FindReferApiResponse, FindReferApiArg>({
            query: (queryArg) => ({ url: `/api/v2/refer-loan/${queryArg.id}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type FindReferApiResponse = /** status 200 OK */ GenericJsonResponseReferDto;
export type FindReferApiArg = {
    id: string;
};
export type PropertySpec = {
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
};
export type PropertySpecWrite = {
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type BorrowerSpec = {
    borrowerType?: BorrowerType;
    borrowerName?: string;
    borrowerAddress?: string;
    borrowerNetWorth?: number;
    borrowerLiquidity?: number;
    borrowerFirstName?: string;
    borrowerLastName?: string;
    borrowerPhone?: string;
    borrowerCreditScore?: number;
    borrowerCitizenship?: string;
};
export type BorrowerSpecWrite = {
    borrowerType?: BorrowerType;
    borrowerName?: string;
    borrowerAddress?: string;
    borrowerNetWorth?: number;
    borrowerLiquidity?: number;
    borrowerFirstName?: string;
    borrowerLastName?: string;
    borrowerPhone?: string;
    borrowerCreditScore?: number;
    borrowerCitizenship?: string;
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type GuarantorSpec = {
    guarantorType?: GuarantorType;
    guarantorName?: string;
    guarantorAddress?: string;
    guarantorNetWorth?: number;
    guarantorLiquidity?: number;
    guarantorFirstName?: string;
    guarantorLastName?: string;
    guarantorPhone?: string;
    guarantorCreditScore?: number;
    guarantorCitizenship?: string;
};
export type GuarantorSpecWrite = {
    guarantorType?: GuarantorType;
    guarantorName?: string;
    guarantorAddress?: string;
    guarantorNetWorth?: number;
    guarantorLiquidity?: number;
    guarantorFirstName?: string;
    guarantorLastName?: string;
    guarantorPhone?: string;
    guarantorCreditScore?: number;
    guarantorCitizenship?: string;
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type LoanApplication = {
    isExternalLender?: boolean;
    budgetType?: BudgetType;
    budgetTotal?: number;
    budgetAmount?: number;
    projectAddress?: PropertySpec[];
    borrower?: BorrowerSpec[];
    guarantor?: GuarantorSpec[];
    summary?: string;
    loanTerm?: string;
    amortization?: string;
    recourse?: string;
    interestRate?: string;
    cashInterestRate?: string;
    financingExpectation?: string;
};
export type LoanApplicationWrite = {
    isExternalLender?: boolean;
    unmappedProperties?: {
        [key: string]: object;
    };
    budgetType?: BudgetType;
    budgetTotal?: number;
    budgetAmount?: number;
    projectAddress?: PropertySpecWrite[];
    borrower?: BorrowerSpecWrite[];
    guarantor?: GuarantorSpecWrite[];
    summary?: string;
    loanTerm?: string;
    amortization?: string;
    recourse?: string;
    interestRate?: string;
    cashInterestRate?: string;
    financingExpectation?: string;
};
export type ReferDto = {
    id?: string;
    createdBy?: number;
    data?: LoanApplication;
    files?: string[];
    createdAt?: string;
};
export type ReferDtoWrite = {
    id?: string;
    createdBy?: number;
    data?: LoanApplicationWrite;
    files?: string[];
    createdAt?: string;
};
export type GenericJsonResponseReferDto = {
    status?: string;
    data?: ReferDto;
    message?: string;
    code?: string;
};
export type GenericJsonResponseReferDtoWrite = {
    status?: string;
    data?: ReferDtoWrite;
    message?: string;
    code?: string;
};
export enum BudgetType {
    Acquisition = 'ACQUISITION',
    Construction = 'CONSTRUCTION',
    Refinance = 'REFINANCE',
    NewDevelopment = 'NEW_DEVELOPMENT',
    Redevelopment = 'REDEVELOPMENT',
}
export enum BorrowerType {
    Company = 'COMPANY',
    Corporation = 'CORPORATION',
    Individual = 'INDIVIDUAL',
}
export enum GuarantorType {
    Company = 'COMPANY',
    Corporation = 'CORPORATION',
    Individual = 'INDIVIDUAL',
}
export const { useFindReferQuery } = injectedRtkApi;
