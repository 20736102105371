export const INITIAL_CONNECTION_MC = 'initialConnectionMC';

export const ON_MESSAGE_MC = 'onMessageMC';

export const INIT_FILE_UPLOADS = 'initFileUploads';

export const CONFIRM_STANDARDIZE_FILE = 'confirmStandardizeFile';

export const AUTH_SERVICE_MC = 'authService';

export const VIEW_FILE_LOAD = 'viewFile-load';

export const VIEW_FILE_AVAILABLE = 'viewFile-available';

export const VIEW_FILE = 'view-file';

export const STANDARDIZED_TENANT_NAMES = 'standardized-tenant-names';

export const PREVIEW_FILE_INTERACTIONS = 'preview-file-interactions';

export const INITIALIZED_ANALYTICS_GRIDS_DATA = 'initializedAnalyticsGridsData';

export const DOWNLOAD_DETAILED_EXCEL = 'downloadDetailedExcelMC';

export const DETAILED_EXCEL_DONE = 'detailedExcelDone';

export const EXTRACT_SERIALIZED_TABLE_DATA = 'extractSerializedTableData';

export const GRID_EXTRACT_COLUMNS = 'grid-extract-columns';

export const GET_COLUMN_OPTIONS = 'get-column-options';

export const GET_ALL_COLUMN_OPTIONS = 'get-all-column-options';

export const GET_SELECTED_SCENARIOS_OPTIONS = 'get-selected-scenarios-options';

export const GET_ALL_SCENARIOS_OPTIONS = 'get-all-scenarios-options';

export const RECONCILATION_SUMMARY = 'reconcilation-summary';

export const RECONCILATION_UNIT_MIX = 'reconcilation-unit-mix';

export const RECONCILATION_UNIT_MIX_LIMITED = 'reconcilation-unit-mix-limited';

export const RECONCILATION_CHARGE_CODES = 'reconcilation-charge-codes';

export const CALCULATE_EXCEL_DATA = 'calculateExcelFileData';

export const CHANGE_EXCEL_TAB = 'changeExcelTab';

export const GET_CALCULATE_EXCEL_DATA = 'getCalculateExcelFileData';

export const CONFIRM_STANDARDIZED_FILE = 'confirmStandardizeFile';

export const UNSAVED_CHANGES = 'unsaved-changes';

export const SAVED_CHANGES = 'saved-changes';

export const GRID_LOADED = 'grid-loaded';

export const TABLE_CHANGED = 'table-changed';

export const STATEMENTS_TABLE = 'statements-table';

export const SERIALIZED_DATA = 'serialized-data';

export const GRID_SAVE_FAILED = 'grid-save-failed';

export const GRID_SAVE_ORIGINAL_COMPLETED = 'grid-save-original-completed';

export const GET_SCENARIO_OPTION = 'get-scenario-option';

export const SET_LOADING_STATUS = 'set-loading-status';

export const GRID_COMBINED_COLUMNS = 'grid-combined-columns';

export const BREAK_DOWN_TENANTS_LIST = 'break-down-tenants-list';
