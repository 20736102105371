import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@scc/index';
import { TFilter } from '@scc/modules/FilterToolbar';

type FilterToolbarState = {
    activeFilters: TFilter[];
};

const ENTITY = '[filterToolbar]';

const initialState: FilterToolbarState = {
    activeFilters: [],
};

const filterToolbarSlice = createSlice({
    name: ENTITY,
    initialState,
    reducers: {
        setFilters(state, { payload }) {
            state.activeFilters = payload;
        },
        updateFilters(state, { payload }) {
            const index = state.activeFilters.indexOf(payload);
            if (index === -1) {
                state.activeFilters.push(payload);
            } else {
                state.activeFilters.splice(index, 1);
            }
        },
        resetFilters() {
            return initialState;
        },
    },
});

export const { setFilters, updateFilters, resetFilters } = filterToolbarSlice.actions;

export default filterToolbarSlice.reducer;

export const selectFilterToolbarState = (state: RootState) => state.filterToolbar;

export const selectActiveFilters = (state: RootState) => state.filterToolbar.activeFilters;
