import { styled } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Stack from '@ui/Stack';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
}));

const QontoStepIcon = function (props) {
    const { active, className } = props;

    return <QontoStepIconRoot ownerState={{ active }} className={className} />;
};

const CustomizedSteppers = function ({ steps, className, active }) {
    return (
        <Stack sx={{ width: '100%' }} spacing={2} className={className}>
            <MobileStepper variant='progress' steps={steps.length + 1} position='static' activeStep={active + 1} />
            <Stepper activeStep={active} connector={<span style={{ flexGrow: 1 }}> </span>}>
                {steps.map(({ name, label, title = label } = {}, i) => (
                    <Step key={`${name} ${i}`}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{title}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
};

export default CustomizedSteppers;
