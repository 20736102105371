import React from 'react';

class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.hasError && this.state.hasError) {
            this.props.onError?.();
        }
    }

    componentDidCatch(error, errorInfo) {
        console.warn('error: ', error, errorInfo);
        this.setState({
            hasError: true,
        });
    }

    render() {
        const { indicator, children, ...restProps } = this.props;
        const { hasError } = this.state;

        if (hasError) return indicator || null;
        return React.cloneElement(children, restProps);
    }
}

ErrorBoundary.defaultProps = {
    indicator: null,
};

export default ErrorBoundary;
