import { getMonthYear } from '@utils/dates';
import { formatter } from '@utils/index';
import { simplifyPropertyTypeName } from '@utils/properties';

import EmptyStateSvg from '@scc/assets/images/no-search-results.svg';

import { CONTENT_OFFSET_TOP } from '@constants/layout';
import { CURRENCY_FORMAT_ROUND } from '@constants/numbers';
import withNoContentPlaceholder from '@shared/hoc/withNoContentPlaceholder';

const RoundNumberFormatter = (value) => formatter.number(value, CURRENCY_FORMAT_ROUND);

const DecimalNumberFormatter = (value) =>
    formatter.number(value, {
        ...CURRENCY_FORMAT_ROUND,
        maximumFractionDigits: 1,
    });
const RoundNumberToIntegerFormatter = (value) => formatter.number(value, { maximumFractionDigits: 0 });

export const TRANSACTIONS = {
    OUTLIER: 'OUTLIER_TRANSACTIONS',
    NOT_OUTLIER: 'NOT_OUTLIER_TRANSACTIONS',
};

export const HEADERS = {
    amout: {
        label: (list) => `${list?.length || 0} transactions found`,
        view: {
            align: 'left',
        },
    },
    build: {
        label: 'Build/Renov',
        name: 'buildRenovatedYear',
        view: {
            align: 'left',
            width: '5%',
        },
    },
    units: {
        name: 'units',
        label: 'Units',
    },
    sf: {
        name: 'size',
        label: 'SF',
        formatter: formatter.number,
    },
    rooms: {
        name: 'units',
        label: 'Rooms',
    },
    acres: {
        name: 'lotsize',
        label: 'SF',
        formatter: formatter.number,
    },
    $acre: {
        name: 'sfPrice',
        label: '$/acre',
        formatter: RoundNumberFormatter,
    },
    $totalValue: {
        name: 'totalPrice',
        label: 'Total value',
        formatter: RoundNumberFormatter,
    },
    $rooms: {
        name: 'unitPrice',
        label: '$/room',
        formatter: RoundNumberFormatter,
    },
    $unit: {
        name: 'unitPrice',
        label: '$/unit',
        formatter: RoundNumberFormatter,
    },
    $sf: {
        name: 'sqftPrice',
        label: '$/SF',
        formatter: RoundNumberFormatter,
    },
    $sfDecimal: {
        name: 'sqftPrice',
        label: '$/SF',
        formatter: DecimalNumberFormatter,
    },
    date: {
        name: 'soldDate',
        label: 'Date',
        formatter: getMonthYear,
        view: {
            hasFilter: true,
            align: 'right',
        },
    },
    similarity: {
        name: 'similarScore',
        label: 'SS',
        title: 'Similarity Score',
        formatter: RoundNumberToIntegerFormatter,
    },
};

const mfLike = [HEADERS.build, HEADERS.units, HEADERS.sf, HEADERS.$unit, HEADERS.$sf, HEADERS.date, HEADERS.similarity];
const notMfLike = [HEADERS.build, HEADERS.sf, HEADERS.$sf, HEADERS.date, HEADERS.similarity];

const vacantland = [
    HEADERS.build,
    HEADERS.$totalValue,
    HEADERS.acres,
    HEADERS.$sfDecimal,
    HEADERS.date,
    HEADERS.similarity,
];

const lodging = [
    HEADERS.build,
    HEADERS.rooms,
    HEADERS.sf,
    HEADERS.$rooms,
    HEADERS.$sf,
    HEADERS.date,
    HEADERS.similarity,
];

export const headersMap = {
    multifamily: mfLike,
    mobilehome: mfLike,
    seniorhousing: mfLike,
    studenthousing: mfLike,
    singlefamily: notMfLike,
    retail: notMfLike,
    industrial: notMfLike,
    office: notMfLike,
    apartment: notMfLike,
    mixeduse: notMfLike,
    healthcare: notMfLike,
    warehouse: notMfLike,
    agricultural: vacantland,
    lodging,
    vacantland,
    selfstorage: notMfLike,
    other: notMfLike,
};

export const prepareTableData = (property) => headersMap[simplifyPropertyTypeName(property) || 'other'];

export const hasUnitsAveragesPerUnitSF = (headers) => headers === mfLike || headers === lodging;

export const divideAddress = (address) => {
    const [first, second, third] = address?.split(',') || [];
    return [first, [second, third].join(',')];
};

const options = {
    preview: EmptyStateSvg,
    message: 'No transactions found. Please change your filters or add transactions.',
    showLoaderOnce: true,
};

export const SalesTransactionsContentWithPlaceholder = withNoContentPlaceholder(options, {
    placeholderProps: { absoluteSpinner: true, offsetTop: CONTENT_OFFSET_TOP },
});

export const createSaveOutlierTransactionPayload = ({ countyId, parcelId, userId, outlierTransactions }) => ({
    countyid: countyId,
    parcelid: parcelId,
    userid: userId,
    params: [
        {
            paramname: TRANSACTIONS.OUTLIER,
            paramvalue: outlierTransactions.length ? outlierTransactions.join(',') : '',
        },
    ],
});

export const createSaveNotOutlierTransactionPayload = ({ countyId, parcelId, userId, notOutlierTransactions }) => ({
    countyid: countyId,
    parcelid: parcelId,
    userid: userId,
    params: [
        {
            paramname: TRANSACTIONS.NOT_OUTLIER,
            paramvalue: notOutlierTransactions.length ? notOutlierTransactions.join(',') : '',
        },
    ],
});

export const getMergedOutliersIds = (outlierTransactions, notOutlierTransactions) => {
    const mergedOutliers = [...outlierTransactions, ...notOutlierTransactions];

    return mergedOutliers.reduce((prev, current) => {
        const prevCopy = { ...prev };

        if (outlierTransactions.includes(current)) {
            prevCopy[current] = true;
        }

        if (notOutlierTransactions.includes(current)) {
            prevCopy[current] = false;
        }

        return prevCopy;
    }, {});
};
