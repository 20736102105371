import blockedIcon from '@scc/assets/icons/lockedFolder.svg';

import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import lockIcon from '@icons/outlined/lock-1.svg';

import styles from './styles.module.scss';

export const text = {
    title: 'Content is not included into subscription',
    description: 'Contact support@smartcapital.center to request access',
};

function PageIcon() {
    return <Icon color='white' component={blockedIcon} fontSize={186} />;
}

function SectionIcon() {
    return <Icon color='#8990A9' component={lockIcon} fontSize={28} />;
}

export function BlockedBox({ icon: IconProp, rootStyles, title = text.title, description = text.description }) {
    return (
        <div className={rootStyles}>
            <IconProp />
            <Typography color={colors['darkNeutral-500']} variant='h6b'>
                {title}
            </Typography>
            <Typography color={colors['grey-400']} variant='smr'>
                {description}
            </Typography>
        </div>
    );
}

BlockedBox.Page = ({ title, description }) => {
    return <BlockedBox description={description} icon={PageIcon} rootStyles={styles.pageRoot} title={title} />;
};

BlockedBox.Section = ({ title, description }) => {
    return <BlockedBox description={description} icon={SectionIcon} rootStyles={styles.sectionRoot} title={title} />;
};
