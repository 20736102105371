import config from '@config';

const appPrefix = config.get('app');

export default class MessageChannelConnector {
    constructor({ type, name = 'channelConnector' } = {}) {
        this.mc = null;
        this.type = type;
        this.name = name;
    }

    init({ onInit, onMessage, onClose }) {
        console.info(`Starting: ${this.type} channel: ${this.name}`);
        this.onClose = onClose;
        this.onMessage = onMessage;
        this.createChannel(onInit);
    }

    close() {
        if (this.mc) {
            console.info(`Closing: ${this.type} channel: ${this.name}`);
            this.onClose?.();
        }
    }

    send(payload) {
        if (this.mc) {
            const data = MessageChannelConnector.safeStringify(payload);

            this.mc.port2.postMessage(data);
            console.info('>>> Sending:', payload);
        } else {
            console.warn(`Can't send messages`);
        }
    }

    sendToWindow(type, otherWindow, payload = {}, origin = '*') {
        if (this.mc && otherWindow) {
            const messageType = `${appPrefix}-${type}`;
            const data = MessageChannelConnector.safeStringify({ type: messageType, payload });

            otherWindow.postMessage(data, origin, [this.mc.port2]);
            console.info('>>> Sending:', { type: messageType, payload });
        } else {
            console.warn('Cannot send messages');
        }
    }

    createChannel(onInit) {
        const channel = new MessageChannel();

        channel.port1.onmessage = (event) => {
            let data;
            let parsed = false;

            try {
                data = JSON.parse(event.data.toString());
                parsed = true;
                console.info('<<< Receiving:', data);
            } catch (err) {
                console.warn(`Failed to parse ${event.data.toString()}`);
            }

            if (parsed) {
                this.onMessage?.(event, data);
            }
        };

        channel.port1.onmessageerror = (error) => {
            console.warn(`MC error: ${error}`);
        };

        this.mc = channel;
        onInit?.(channel);
    }

    static safeStringify(obj) {
        let result;
        try {
            result = JSON.stringify(obj);
        } catch (err) {
            console.warn(`Failed to parse supplied json: ${obj}`);
            result = `Parsing failed: ${err}`;
        }
        return result;
    }
}
