import { ChangeEvent } from 'react';
import { generatePath } from 'react-router-dom';

import links from '@scc/router/links';

export const decimalValueValidator = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const reg = /^\d{0,7}\.?\d{0,2}$/;

    return reg.test(value);
};

export const decimalWithFiveDigitsValueValidator = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const reg = /^\d{0,9}\.?\d{0,5}$/;

    return reg.test(value);
};

export const isTermsTab = (pathname: string, loanId) => {
    return pathname === generatePath(links.loan.routes!.details.routes!.terms.path, { loanId });
};

type TRow = {
    name: string;
    subRows?: TRow[];
};

export function cleanUpTableDataFromRowsWithEmptyValues({
    rowList,
    keyListToCheck,
}: {
    rowList: TRow[];
    keyListToCheck: string[];
}) {
    const result = rowList.filter((row) => row.name === 'divider' || keyListToCheck.some((key) => !!row[key]));

    result.forEach((row, index) => {
        if (result[index]?.subRows?.length) {
            result[index].subRows = cleanUpTableDataFromRowsWithEmptyValues({
                rowList: result[index].subRows!,
                keyListToCheck,
            });
        }
    });

    return result;
}
