import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Input from '@ui/Input';
import Button from '@ui/Button';
import useScript from '@shared/hooks/useScript';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { renderCaptcha } from '@utils/browser';
import { contactApi } from '@api';
import schema from './validationSchema';
import styles from './styles.module.scss';

const ContactUs = function ({ onSuccess }) {
    useScript({
        src: 'https://www.google.com/recaptcha/api.js?onload=onloadRecaptcha&render=explicit',
    });
    useEffect(() => {
        window.onloadRecaptcha = onloadRecaptcha;
    }, []);

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        if (data.captcha) {
            contactApi
                .sendMessage(data)
                .then(() => {
                    reset();
                    window.grecaptcha.reset();
                    setValue('captcha', null);
                    console.info('Your email has been sent');
                    onSuccess();
                })
                .catch(() => {
                    window.grecaptcha.reset();
                    setValue('captcha', null);
                    console.error('Your email has not been sent. Please contact us at hello@smartcapital.center');
                });
        } else {
            console.error('Pass the test captcha');
        }
    };

    function onloadRecaptcha() {
        renderCaptcha({
            container: 'contactFormCaptcha',
            verify(res) {
                if (res) {
                    setValue('captcha', res);
                }
            },
        });
    }

    return (
        <Box
            component='form'
            className={styles.contactUs}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={styles.formContent}>
                <Input
                    margin='dense'
                    label='Your Name'
                    name='name'
                    error={!!errors?.name}
                    fullWidth
                    {...register('name')}
                />
                <Input
                    margin='dense'
                    label='Email'
                    name='email'
                    error={!!errors?.email}
                    fullWidth
                    {...register('email')}
                />
                <Input
                    multiline
                    margin='dense'
                    label='Your Question'
                    name='message'
                    error={!!errors?.message}
                    fullWidth
                    maxRows={5}
                    {...register('message')}
                />
                <div id='contactFormCaptcha' className={styles.captchaContainer} />
            </div>
            <Button type='submit' size='extra-large' className={styles.contactUsBtn}>
                Send
            </Button>
        </Box>
    );
};
export default ContactUs;
