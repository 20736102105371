import request from '@utils/request';

const BASE_URL = '/geoGekko/building';
const BASE_URL_V2 = '/api/v2/parcel';

/**
 * Gets parcels associated with the address details
 *
 * @param   {Object}    data
 * @param   {string}    [data.userid]           Get parcels for specific user
 * @param   {number}    data.latitude           Address latitude
 * @param   {number}    data.longitude          Address longitude
 * @param   {string}    data.address            Address string
 * @param   {boolean}   data.parcelsData        Indicates if response should contain parcels detailes
 * @param   {boolean}   [data.oneParcelPerType] Indicates if response should contain only one parcel per property type
 * @return  {Object}                            Promise
 */
export async function getParcelsByAddress(data = {}) {
    return request(`${BASE_URL}/parcels`, {
        params: data,
    });
}

export async function createParcel(parcel = {}) {
    const data = { addressedValueType: 'building', ...parcel };
    return request(`${BASE_URL}/parcel/create`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function createNewParcel(parcel = {}) {
    return request(BASE_URL_V2, {
        method: 'POST',
        body: JSON.stringify(parcel),
    });
}

export async function updateUsingExistingParcel(payload = {}) {
    return request(`${BASE_URL_V2}/use-existing`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}
