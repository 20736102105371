import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import { maxAfterComma } from '@utils/financials';
import { CURRENCY_LABEL_MAP, currencyPrefixFormatter } from '@utils/index';

import { FINANCIALS } from '@components/DataTable/constants';

import colors from '@themes/palette/export.module.scss';

export const palette = (excludes = []) =>
    Object.entries(colors)
        .filter(([key]) => key.includes('chart-') && !excludes.includes(colors[key]))
        .map((color) => color[1]);

const getLabelRounder = (val = 0) => {
    if (val === 0) {
        return 0;
    }
    if (val < 0 && val >= -100) {
        return -100;
    }
    if (val > 0 && val <= 100) {
        return 100;
    }
    const parsedVal = currencyPrefixFormatter(val);
    const label = parsedVal.slice(-1);
    const roundOption = CURRENCY_LABEL_MAP.find((option) => option.label === label);
    const roundVal = roundOption ? roundOption.divider : 100;
    return Number.isNaN(roundVal) ? 10 : Math.ceil(val / roundVal + (val > 0 ? 1 : -1)) * roundVal;
};

export const arrayDataInjection = (config, data) => {
    if (!data) return null;
    const { data: chartData, xAxis = [], yAxis = [] } = data;
    let newConfig = { ...config };

    const updatedSeries = newConfig.series.map((item, i) => ({
        ...item,
        data: chartData[i],
    }));

    newConfig = {
        ...newConfig,
        series: updatedSeries,
    };

    if (xAxis.length) {
        newConfig.xAxis = {
            ...newConfig.xAxis,
            data: xAxis,
        };
    }

    if (yAxis.length) {
        newConfig.yAxis = {
            ...newConfig.yAxis,
            data: yAxis,
        };
    }

    let arrayData = [...updatedSeries[0].data] || [];
    arrayData = arrayData.sort((a, b) => a - b);
    let max = arrayData[arrayData.length - 1];
    let min = arrayData[0];

    if (isNumber(max) && isNumber(min)) {
        const axisKey = xAxis.length ? 'yAxis' : 'xAxis';

        max = getLabelRounder(max);
        min = getLabelRounder(min);
        newConfig[axisKey][0] = {
            ...newConfig[axisKey],
            min: max > 0 && min > 0 ? 0 : min,
            max: max < 0 && min < 0 ? 0 : max,
        };
    }

    return newConfig;
};

export const fullSeriesInjection = (config, data, axis = 'xAxis') => {
    if (!data) return null;

    const { data: chartData = [] } = data;
    let newConfig = { ...config };

    newConfig = {
        ...newConfig,
        series: chartData,
    };

    if (data[axis].length) {
        newConfig[axis] = {
            ...newConfig[axis],
            data: data[axis],
        };
    }

    return newConfig;
};

export const expensesDataInjection = (config, data) => {
    const { data: chartsData, xAxis } = data || {};

    if (!data || chartsData?.[0].length === 0) return null;

    let newConfig = { ...config };
    const inputData = chartsData[0];
    const resObject = {};

    if (!inputData[0] || !Object.keys(inputData[0]).length) {
        return newConfig;
    }

    inputData.forEach((item) => {
        Object.keys(item).forEach((key) => {
            if (!resObject[key]) {
                resObject[key] = {
                    name: FINANCIALS[key],
                    type: 'bar',
                    stack: 'one',
                    barWidth: 16,
                    data: [],
                };
            }
        });
    });

    const allKeys = Object.keys(resObject);

    inputData.forEach((item) => {
        allKeys.forEach((key) => {
            resObject[key].data.push(item[key] || 0);
        });
    });

    const columnsValues = [];
    const findArraySum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);

    const sortedResObject = Object.entries(resObject).sort(
        (prev, next) => findArraySum(next[1].data) - findArraySum(prev[1].data)
    );
    const labels = sortedResObject.map(([key]) => FINANCIALS[key]);
    const dataArray = sortedResObject.map(([, value]) => value.data);
    const sortedResObjectValues = sortedResObject.map(([, value]) => value);

    for (let i = 0; i < dataArray[0].length; i += 1) {
        let resCol = 0;
        for (let j = 0; j < dataArray.length; j += 1) {
            const val = dataArray[j][i];
            resCol = !Number.isNaN(val) ? resCol + Math.abs(val) : resCol;
        }
        columnsValues.push(resCol);
    }
    const zeroIndexes = [];
    for (let i = 0; i < dataArray.length; i += 1) {
        let resRow = 0;
        for (let j = 0; j < dataArray[i].length; j += 1) {
            const val = dataArray[i][j];
            resRow = !Number.isNaN(val) ? resRow + Math.abs(val) : resRow;
        }
        if (resRow === 0) {
            zeroIndexes.push(i);
        }
    }

    newConfig = {
        ...newConfig,
        series: sortedResObjectValues.filter((_, i) => zeroIndexes.indexOf(i) === -1),
        yAxis: {
            ...newConfig.yAxis,
        },
        legend: {
            ...newConfig.legend,
            data: labels.filter((_, i) => zeroIndexes.indexOf(i) === -1),
        },
    };

    if (xAxis.length) {
        newConfig.xAxis = {
            ...newConfig.xAxis,
            data: xAxis,
        };
    }

    return newConfig;
};

export const expensesDataInjectionPie = ({ ...config } = {}, expenses, label) => {
    if (!expenses) return null;

    const index = expenses.xAxis?.data.findIndex((item) => item === label);
    const resSeries = { ...config.series[0] };
    const total = expenses.series?.reduce((acc, curr) => (curr.data[index] ? acc + curr.data[index] : acc), 0) || 0;

    resSeries.data = expenses.series?.map((item) => {
        const { name, data } = item;
        return {
            name,
            value: total > 0 ? maxAfterComma((data[index] / total) * 100, 2) : 0,
            unit: '%',
        };
    });

    const isChartData = resSeries.data.filter((item) => item.value !== 0).length;

    if (!isChartData) return null;

    return {
        ...config,
        series: [resSeries],
    };
};

export const valuesDataInjections = (config, data) => {
    const { data: chartData, xAxis } = data;

    if (!data || !chartData) return null;

    let newConfig = { ...config };

    const updatedSeries = newConfig.series?.map((serie) => {
        const serieData = serie?.data?.map((item, j) => ({
            ...item,
            value: chartData[j],
        }));

        return {
            ...serie,
            data: serieData,
        };
    });

    newConfig = {
        ...newConfig,
        series: updatedSeries,
    };

    if (xAxis.length) {
        newConfig.xAxis = {
            ...newConfig.xAxis,
            data: xAxis,
        };
    }

    return newConfig;
};

export const convertToFlatData = (source = [], property) => {
    if (!source || source.length < 1) return null;
    return source.reduce(
        (acc, item) => {
            const type = typeof item[property];

            const structures = type === 'array' || type === 'object';

            if ((structures && !isEmpty(item[property])) || !structures) {
                acc.data[0].push(item[property]);
                const date = `${item.month} ${item.year}${item.dateLabel ? ` ${item.dateLabel}` : ''}`;
                acc.xAxis.push(date);
            }

            return acc;
        },
        {
            data: [[]],
            xAxis: [],
        }
    );
};
