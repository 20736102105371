import { createSlice } from '@reduxjs/toolkit';
import { select, put, take, takeLatest, delay } from 'redux-saga/effects';

import { VIEWS } from '@scc/modules/DocumentsWithGrid/helpers/constants';
import { selectAuthState } from '@scc/store/ducks/auth';
import { setHideTipsCommon, selectHideTipsCommon } from '@scc/store/ducks/common';
import { selectDetailedOverviewPropertyIdState } from '@scc/store/ducks/detailedProperty/overview';
import {
    selectGridState,
    setMessageGrid,
    setDocumentGrid,
    setDocumentsGrid,
    toggleViewGrid,
    selectGridActiveViewsState,
    setViewGrid,
    setMultiplierGrid,
} from '@scc/store/ducks/grid/common';

import { userApi, documentsApi } from '@api';
import { PROCESS_STATUSES } from '@constants/documents';
import { MESSAGES } from '@constants/isolatedGrid';
import { MERGE_MULTILINE_TITLES, REQUEST_HELP, SUCCESS } from '@constants/modals';
import { API_SUCCESS, API_ERROR, apiRequest } from '@store/ducks/api';
import { hideDialog, showDialog } from '@store/ducks/ui/dialog';
import { hideDrawer, showDrawer } from '@store/ducks/ui/drawer';
import { TYPES } from '@templates/forms/components/Success/Success';

const entity = '[financials]';

const initialState = {
    loaded: false,
    loading: false,
};

const financialsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        update() {},
        merge() {},
        startMerge() {},
        multiplyValues() {},
        cancel() {},
        startRequest() {},
        request() {},
        sendToAnalyst() {},
    },
});

export const {
    update: updateDocQualityFinancing,
    startMerge: startMergeMultilineTitlesFinancing,
    merge: mergeMultilineTitlesFinancing,
    cancel: cancelMultilineTitlesFinancing,
    startRequest: startRequestHelpFinancing,
    request: requestHelpFinancing,
    sendToAnalyst,
    multiplyValues: multiplyValuesFinancing,
} = financialsSlice.actions;

export default financialsSlice.reducer;

function* updateDocQualityFinancingSaga({ payload: { pdfParseType }, type }) {
    const { documents, document } = yield select(selectGridState);
    const propertyId = yield select(selectDetailedOverviewPropertyIdState);

    yield put(
        apiRequest(
            {
                documentId: document.id,
                propertyId,
                pdfParseType,
            },
            documentsApi.reprocessDocument,
            type
        )
    );

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        let resDocument;
        const resDocuments = documents.map((doc) => {
            if (doc.entityId === document.entityId) {
                resDocument = {
                    ...doc,
                    status: PROCESS_STATUSES.processing,
                    documentStatus: 'PROCESSING',
                    statusLabel: 'Processing',
                };
                return resDocument;
            }
            return doc;
        });
        yield put(setDocumentsGrid(resDocuments));
        yield put(setDocumentGrid(resDocument));
    }

    yield put(hideDrawer());
    yield put(toggleViewGrid(VIEWS.EDIT_MAPPING));
}

function* mergeMultilineTitlesFinancingSaga({ payload: { message, showTips } }) {
    if (showTips) {
        const {
            currentUser: { userId },
        } = yield select(selectAuthState);
        yield put(setHideTipsCommon(userId));
    }
    yield put(setMessageGrid({ message }));
    yield delay(1000);
    yield put(hideDrawer());
}

function* startMergeMultilineTitlesFinancingSaga({ payload }) {
    const { skipMergeTips } = yield select(selectHideTipsCommon);

    if (skipMergeTips) {
        yield put(mergeMultilineTitlesFinancing(payload));
    } else {
        yield put(
            showDrawer({
                content: MERGE_MULTILINE_TITLES,
                data: {
                    title: 'Merge Multiline Titles',
                },
            })
        );
    }
}

function* cancelMultilineTitlesFinancingSaga() {
    yield put(hideDrawer());
}

function* startRequestHelpFinancingSaga({ payload }) {
    yield put(
        showDrawer({
            content: REQUEST_HELP,
            data: {
                title: 'Request Help',
                payload,
            },
        })
    );
    yield take(`${requestHelpFinancing} ${API_SUCCESS}`);

    const gridActiveViewsState = yield select(selectGridActiveViewsState);

    if (gridActiveViewsState.editMapping) {
        const setViewGridPayload = {
            [VIEWS.EDIT_MAPPING]: false,
            [VIEWS.SIDE_LIST]: true,
            [VIEWS.PREVIEW_DOCUMENTS]: false,
        };

        yield put(setViewGrid(setViewGridPayload));
        yield put(
            setMessageGrid({
                message: MESSAGES.CONFIRM,
                data: {
                    openStandardized: true,
                },
            })
        );
    }
    yield put(hideDrawer());
}

function* requestHelpFinancingSaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.requestAnalystHelp, type));
}

function* sendToAnalystSaga({ payload }) {
    yield put(requestHelpFinancing(payload));
    yield take(`${requestHelpFinancing} ${API_SUCCESS}`);
    yield put(
        showDialog({
            content: SUCCESS,
            data: {
                iconType: TYPES.mailSent,
                title: 'Sent to Smart Capital Analyst',
                titleVariant: 'h3b',
            },
            fullScreen: true,
        })
    );
    yield delay(2000);
    yield put(hideDialog());
}

function* multiplyValuesFinancingSaga({ payload }) {
    const { message, value } = payload;

    yield put(setMultiplierGrid(value));
    yield put(setMessageGrid({ message }));
}

export function* watchFinancials() {
    yield takeLatest(updateDocQualityFinancing, updateDocQualityFinancingSaga);
    yield takeLatest(startMergeMultilineTitlesFinancing, startMergeMultilineTitlesFinancingSaga);
    yield takeLatest(mergeMultilineTitlesFinancing, mergeMultilineTitlesFinancingSaga);
    yield takeLatest(cancelMultilineTitlesFinancing, cancelMultilineTitlesFinancingSaga);
    yield takeLatest(startRequestHelpFinancing, startRequestHelpFinancingSaga);
    yield takeLatest(requestHelpFinancing, requestHelpFinancingSaga);
    yield takeLatest(sendToAnalyst, sendToAnalystSaga);
    yield takeLatest(multiplyValuesFinancing, multiplyValuesFinancingSaga);
}
