import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';

import { createBaseProxy } from '@utils/components';

type TypographyProps = Omit<MuiTypographyProps, 'variant'> & {
    variant?: string;
    htmlFor?: string;
    to?: string;
};

const Typography = createBaseProxy<TypographyProps>(MuiTypography, 'Typography');

Typography.Uppercase = createBaseProxy(MuiTypography, 'Typography', {
    sx: { textTransform: 'uppercase' },
});

export default Typography;
