import { useCallback, useEffect, useState } from 'react';

import request from '@utils/request';

const BASE_URL = '/api/v2';

type TQueryProps = {
    url: string;
    params?: {
        limit?: number;
        [key: string]: unknown;
    };
    dataAdapter?: (data: Record<string, unknown>) => unknown[];
    isMounted?: boolean;
};

const DEFAULT_SIZE = 10;

export const useSearchDataQuery = <T = Record<string, unknown>>({
    url,
    params,
    dataAdapter,
    isMounted = true,
}: TQueryProps) => {
    const [page, setPage] = useState(0);
    const [total, setTotalElements] = useState(0);
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');

    const fetchData = useCallback(
        async (index: number, searchQuery: string) => {
            if (!isMounted) {
                return;
            }

            setLoading(true);

            const response = await request(`${BASE_URL}/${url}`, {
                method: 'GET',
                params: {
                    ...params,
                    size: DEFAULT_SIZE,
                    page: index,
                    search: searchQuery,
                },
            });

            const {
                data: { content, totalElements },
            } = response;

            if (response.status === 'success') {
                const adaptedData = dataAdapter ? dataAdapter(content) : content;

                setData((prev) => [...prev, ...adaptedData]);
                setTotalElements(totalElements);
            } else {
                setError(response.message);
            }

            setLoading(false);
        },
        [dataAdapter, params, url, isMounted]
    );

    const handleSearchChange = useCallback((searchQuery: string) => {
        setData([]);
        setPage(0);
        setSearch(searchQuery);
        setTotalElements(0);
    }, []);

    const loadMoreHandler = async () => {
        await fetchData(page, search);
        setPage((prevState) => prevState + 1);
    };

    useEffect(() => {
        fetchData(0, search);
        setPage((prevState) => prevState + 1);
    }, [fetchData, search]);

    return [
        { data, loading, error, total },
        { loadMoreHandler, handleSearchChange },
    ] as const;
};
