import request from '@utils/request';

const BASE_URL = '/api/v2/sourcefile';

export async function uploadDocument(data) {
    return request(`${BASE_URL}/upload`, {
        method: 'POST',
        body: data,
        headers: {},
    });
}
