import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { Attention, Default, Success, Warning } from '@scc/components/snackbar';

import config from '@config';

const defaultProps = {
    context: {},
    theme: {},
};

function Root({ theme, store, emotionCache, children }) {
    const app = (
        <ReduxProvider store={store}>
            <CacheProvider value={emotionCache}>
                <MUIThemeProvider theme={theme}>
                    <SnackbarProvider
                        Components={{
                            default: Default,
                            success: Success,
                            attention: Attention,
                            warning: Warning,
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        preventDuplicate
                    >
                        <CssBaseline />
                        {children}
                    </SnackbarProvider>
                </MUIThemeProvider>
            </CacheProvider>
        </ReduxProvider>
    );

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{config.get('enableStrictMode') ? <React.StrictMode>{app}</React.StrictMode> : app}</>
    );
}

Root.defaultProps = defaultProps;

export default Root;
