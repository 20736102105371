import { put, take, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { stressTestApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[stressTest]';

const initialState = {
    data: {},
    loaded: false,
    loading: false,
};

const stressTestSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadStressTest, setData: setStressTest } = stressTestSlice.actions;

export default stressTestSlice.reducer;

export const selectStressTestState = (store) => store.stressTest;

function* loadStressTestSaga({ type, payload }) {
    yield put(apiRequest(payload, stressTestApi.getStressTest, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setStressTest(data));
}

export function* watchStressTest() {
    yield takeLatest(loadStressTest, loadStressTestSaga);
}
