import colors from './export.module.scss';

export const getDefaultPrimary = () => ({
    main: colors['blue-main'],
    contrastText: colors.white,
});

export const getDefaultSecondary = () => ({
    main: colors.white,
    light: colors['lightBlue-500'],
    contrastText: colors['darkNeutral-500'],
});

export const getDefaultDisabled = () => ({
    main: colors['grey-300'],
    light: colors['grey-400'],
    dark: colors['grey-500'],
});

export const getDefaultText = () => ({
    primary: colors.black,
    secondary: colors['darkNeutral-500'],
    disabled: colors['grey-600'],
    hint: colors['darkNeutral-500'],
});

export const getDefaultError = () => ({
    main: colors.error,
});

export const getDefaultWarning = () => ({
    main: colors.warning,
});

export const getDefaultInfo = () => ({
    main: colors.info,
});

export const getDefaultSuccess = () => ({
    main: colors.success,
});
