import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { put, take, takeLatest, select } from 'redux-saga/effects';

import { recalculateModelOnCopy } from '@utils/financials';

import { underwritingApi, propertiesApi } from '@api';
import { FINANCIAL_MODEL, SECONDARY_MODAL_HEADER } from '@constants/modals';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { showBottomPageDrawer } from '@store/ducks/ui/drawer';

const entity = '[underwritingFinancingScenarios]';

const initialState = {
    countyId: null,
    parcelId: null,
    userId: null,
    data: [],
    ratesData: [],
    model: null,
    loaded: false,
    loading: false,
    toggleLoaded: false,
    toggleLoading: false,
    toggleError: null,
};

const financingScenariosSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, { payload }) {
            state.loading = true;
            state.loaded = false;
            state.countyId = payload.countyId;
            state.parcelId = payload.parcelId;
            state.userId = payload.userId;
        },
        loadRates(state) {
            state.loading = true;
            state.loaded = false;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        setRatesData(state, action) {
            state.ratesData = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        toggleModel(state) {
            state.toggleError = null;
            state.toggleLoaded = false;
            state.toggleLoading = true;
        },
        setToggleError(state, action) {
            state.toggleError = action.payload;
            state.toggleLoaded = false;
            state.toggleLoading = false;
        },
        delete(state) {
            state.loading = true;
        },
        [`delete ${API_ERROR}`](state) {
            state.loading = false;
        },
        add(state) {
            state.loading = true;
        },
        [`add ${API_ERROR}`](state) {
            state.loading = false;
        },
        copy() {},
        clone() {},
        download() {},
        startEdit(state, action) {
            state.loaded = true;
            state.loading = false;
            state.model = action.payload?.modelData?.id || null;
        },
        edit(state, action) {
            const { withReload = false } = action.payload;
            if (withReload) {
                state.loading = true;
                state.loaded = false;
                state.data = [];
            }
        },
    },
});

export const {
    load: loadFinancingScenarios,
    loadRates: loadFinancingScenariosRates,
    setData: setFinancingScenarios,
    setRatesData: setFinancingScenariosRates,
    toggleModel: toggleModelFinancingScenarios,
    setToggleError: setToggleErrorFinancingScenarios,
    delete: deleteFinancingScenarios,
    startEdit: startEditFinancingScenarios,
    edit: editFinancingScenarios,
    add: addFinancingScenarios,
    copy: copyFinancingScenarios,
    clone: cloneFinancingScenarios,
    download: downloadTableFinancingScenarios,
} = financingScenariosSlice.actions;

export default financingScenariosSlice.reducer;

export const selectFinancingScenariosState = (store) => store.underwriting.financingScenarios;

function* loadFinancingScenariosSaga({ type, payload: { countyId, parcelId } }) {
    if (!countyId || !parcelId) return;

    yield put(apiRequest({ countyId, parcelId }, underwritingApi.getPropertyModels, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(setFinancingScenarios(content));
}

function* loadFinancingScenariosRatesSaga({ type }) {
    yield put(apiRequest({}, underwritingApi.getRates, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    const { sofr, treasury, prime } = data;

    const resRates = [
        { label: 'Prime', value: 'prime', typeValue: prime?.rate },
        { label: 'SOFR', value: 'sofr', typeValue: sofr?.rate },
        ...Object.entries(treasury?.rates || {})
            .sort((a, b) => {
                const [, ...numArrA] = a;
                const [, ...numArrB] = b;
                return +numArrA.join('') > +numArrB.join('') ? -1 : 1;
            })
            .map(([key, value]) => ({ label: key, value: key.toLowerCase(), typeValue: value })),
    ];

    yield put(setFinancingScenariosRates(resRates));
}

function* toggleModelFinancingScenariosSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.togglePropertyDefaultModel, type));

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        const { parcelId, countyId } = payload;
        yield put(loadFinancingScenarios({ parcelId, countyId }));
    }
    if (action.type.endsWith(API_ERROR)) {
        yield put(setToggleErrorFinancingScenarios(action.payload));
    }
}

function* deleteFinancingScenariosSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.deletePropertyModel, type));

    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadFinancingScenarios(payload));
}

function* startEditFinancingScenariosSaga({ payload }) {
    yield put(
        showBottomPageDrawer({
            content: FINANCIAL_MODEL,
            header: SECONDARY_MODAL_HEADER,
            data: payload,
        })
    );
}

function* editFinancingScenariosSaga({ type, payload: { parcelId, countyId, ...params } = {} }) {
    yield put(
        apiRequest(
            {
                county: countyId,
                parcel: parcelId,
                ...params,
            },
            underwritingApi.updatePropertyModel,
            type
        )
    );
    yield take(`${type} ${API_SUCCESS}`);

    yield put(loadFinancingScenarios({ parcelId, countyId }));
}

function* addFinancingScenariosSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.createPropertyModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadFinancingScenarios(payload));
}

function* copyFinancingScenariosSaga({ type, payload }) {
    const { id, rates } = payload;
    const { data } = yield select(selectFinancingScenariosState);
    const { modelDetails } = data.find((item) => item.id === id);

    if (isEmpty(modelDetails)) {
        yield put(apiRequest(payload, underwritingApi.cloneModel, type));
        yield take(`${type} ${API_SUCCESS}`);
        yield put(loadFinancingScenarios(payload));

        return;
    }

    const resModelDetails = recalculateModelOnCopy(modelDetails, null, rates);
    yield put(apiRequest({ id, ...resModelDetails }, underwritingApi.copyModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadFinancingScenarios(payload));
}

function* cloneFinancingScenariosSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.cloneModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    const { countyId, parcelId } = yield select(selectFinancingScenariosState);
    yield put(loadFinancingScenarios({ countyId, parcelId }));
}

function* downloadTableFinancingScenariosSaga({ type, payload }) {
    yield put(apiRequest(payload, propertiesApi.downloadExcelFile, type));
}

export function* watchFinancingScenarios() {
    yield takeLatest(loadFinancingScenarios, loadFinancingScenariosSaga);
    yield takeLatest(toggleModelFinancingScenarios, toggleModelFinancingScenariosSaga);
    yield takeLatest(deleteFinancingScenarios, deleteFinancingScenariosSaga);
    yield takeLatest(startEditFinancingScenarios, startEditFinancingScenariosSaga);
    yield takeLatest(editFinancingScenarios, editFinancingScenariosSaga);
    yield takeLatest(addFinancingScenarios, addFinancingScenariosSaga);
    yield takeLatest(copyFinancingScenarios, copyFinancingScenariosSaga);
    yield takeLatest(cloneFinancingScenarios, cloneFinancingScenariosSaga);
    yield takeLatest(downloadTableFinancingScenarios, downloadTableFinancingScenariosSaga);
    yield takeLatest(loadFinancingScenariosRates, loadFinancingScenariosRatesSaga);
}
