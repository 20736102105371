import { combineReducers } from 'redux';
import portfoliosReducer, { watchPortfolios } from './portfolios';
import portfolioReducer, { watchPortfolio } from './portfolio';

export const propertiesWatchers = [watchPortfolios, watchPortfolio];

export default combineReducers({
    portfolios: portfoliosReducer,
    portfolio: portfolioReducer,
});
