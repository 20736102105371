import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import Logo from '@scc/components/Logo';

import Box from '@ui/Box';
import Icon from '@ui/Icon';
import Paper from '@ui/Paper';

import colors from '@themes/palette/export.module.scss';

import CardSvg from '@assets/icons/outlined/card.svg';
import UserSvg from '@assets/icons/outlined/user.svg';

import styles from './styles.module.scss';

const cardStyle = {
    base: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '#ffffff',
        fontFamily: 'Roboto, system-ui, sans-serif',
        fontSize: '15px',
    },
    empty: {
        '::placeholder': {
            color: colors['blue-300'],
        },
    },
};

const defaultProps = {
    brand: '',
    id: 'payment-form',
    onCreateCardStatus: () => {},
};

function PaymentCard({
    brand,
    id,
    cardName,
    isEditable,
    last4,
    clear,
    onError,
    onCreateCardStatus,
    onChange,
    onSubmit,
}) {
    const stripe = useStripe();
    const elements = useElements();

    // eslint-disable-next-line no-use-before-define
    const cardNumberPlaceholder = createCardNumberString(last4, 'Card Number');
    const hasUserCard = !!last4;
    const [holder, setHolder] = useState(cardName);

    const cardRef = useRef({
        card: null,
        cvc: null,
        expiry: null,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        const card = {
            type: 'card',
            card: cardRef.current?.card,
        };
        if (hasUserCard && !isEditable) {
            onSubmit();
        } else {
            onCreateCardStatus('start');
            const { error } = await stripe.createPaymentMethod(card);

            if (!error) {
                const response = await stripe.createToken(elements.getElement(CardNumberElement), { name: holder });
                onSubmit({ token: response.token.id, holder });
            }

            if (error) {
                onError(error);
            }
        }
    };

    useEffect(() => {
        if (elements) {
            cardRef.current = {
                card: elements.getElement(CardNumberElement),
                cvc: elements.getElement(CardCvcElement),
                expiry: elements.getElement(CardExpiryElement),
            };
        }
    }, [elements]);

    useEffect(() => {
        if (clear) {
            Object.values(cardRef?.current).forEach((item) => item?.clear());
        }
    }, [clear]);

    return (
        <Paper
            className={cx(styles.card, styles.wrapper)}
            component='form'
            elevation={3}
            id={id}
            onSubmit={handleSubmit}
        >
            <Box className={styles.info}>
                <Logo className={styles.logo} color={colors.white} nameColor='#ffffff80' />
                <span className={styles.brand}>{brand}</span>

                <div className={styles.fieldset}>
                    <Icon color='primary.light' component={CardSvg} fontSize='large' />
                    <CardNumberElement
                        options={{ disabled: !isEditable, placeholder: cardNumberPlaceholder, style: cardStyle }}
                        onChange={onChange}
                    />
                </div>
                <CardExpiryElement
                    className={styles.input}
                    name='expiry'
                    options={{ disabled: !isEditable, placeholder: hasUserCard ? '***' : 'Expiry', style: cardStyle }}
                    onChange={onChange}
                />

                <span className={styles.fieldset}>
                    <Icon color='primary.light' component={UserSvg} fontSize='large' />
                    <input
                        className={styles.input}
                        disabled={!isEditable}
                        name='holder'
                        placeholder='Card Holder'
                        style={cardStyle.base}
                        type='text'
                        value={holder}
                        onChange={({ target }) => setHolder(target.value)}
                    />
                </span>
                <CardCvcElement
                    className={styles.input}
                    name='cvc'
                    options={{ disabled: !isEditable, placeholder: hasUserCard ? '***' : 'CVV', style: cardStyle }}
                    onChange={onChange}
                />
            </Box>
        </Paper>
    );
}

function createCardNumberString(value, text) {
    if (!value) return text;
    return String(value)
        .padStart(16, '*')
        .replace(/(.{4})/g, '$1 ')
        .trim();
}

PaymentCard.defaultProps = defaultProps;

export default PaymentCard;
