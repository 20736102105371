import { useSelector } from 'react-redux';

import { selectAuthPermissions, selectIsAdmin } from '@scc/store/ducks/auth';

import BlockedBox from '@components/BlockedBox';

export const withPermission = (Component, permissionName) => (props) => {
    const permissions = useSelector(selectAuthPermissions);
    const { isAdmin } = useSelector(selectIsAdmin);

    // TODO: Remove isAdmin when all permissions are added to the backend
    if (!permissions?.[permissionName] && !isAdmin) {
        return <BlockedBox.Page />;
    }

    return <Component {...props} />;
};
