import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import ArrowIcon from '@icons/outlined/share-arrow.svg';

import styles from './styles.module.scss';

const defaultProps = {
    label: 'Share',
    onClick: () => {},
};

function ShareButton({ label, onClick }) {
    return (
        <Button.Outlined className={styles.button} size='small' onClick={onClick}>
            <Typography className={styles.label} color={colors['darkNeutral-500']} component='span' variant='h6r'>
                {label}
            </Typography>
            <div className={styles.iconWrapper}>
                <Icon className={styles.arrow} color={colors['grey-A200']} component={ArrowIcon} fontSize='13px' />
            </div>
        </Button.Outlined>
    );
}

ShareButton.defaultProps = defaultProps;

export default ShareButton;
