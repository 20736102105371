import request from '@utils/request';

import { TGetTenantNamesAutocompleteRequest, TGetTenantNamesAutocompleteResponse } from './types';
/* eslint-disable no-return-await */

export async function getRentRollAll({ countyid = '', parcelid = '' }) {
    return await request('/geoGekko/rentroll/getAll', {
        params: {
            countyid,
            parcelid,
        },
    });
}

export async function getPortfolio({
    isAdmin,
    query = '',
    from = 0,
    size = 5,
    sort = [
        {
            name: 'latestuploaddate',
            order: 'desc',
        },
    ],
}) {
    return request(isAdmin ? 'api/v2/user-loans/search' : '/geoGekko/rentroll/loan/search', {
        method: 'POST',
        body: JSON.stringify({
            sort,
            size,
            from,
            shouldFilters: [
                {
                    fieldName: 'address',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'propertyname',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'propertyid',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'portfolio',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'loanid',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
            ],
        }),
    });
}

export async function getBenchmarkingPortfolio({
    query = '',
    from = 0,
    size = 5,
    sort = [
        {
            name: 'latestuploaddate',
            order: 'desc',
        },
    ],
}) {
    return request('/geoGekko/rentroll/loan/search/benchmarking', {
        method: 'POST',
        body: JSON.stringify({
            sort,
            size,
            from,
            shouldFilters: [
                {
                    fieldName: 'address',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'propertyname',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'propertyid',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'portfolio',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
                {
                    fieldName: 'loanid',
                    queryType: 'stringRelaxMatch',
                    keyword: query,
                },
            ],
        }),
    });
}

export async function getBenchmarking(body) {
    return request('/geoGekko/rentroll/loan/search-with-distance-and-score', {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function initRentRollUpload(data) {
    return await request('/geoGekko/uploadRentRoll', {
        method: 'POST',
        body: data,
        headers: {},
    });
}

export async function deleteRentRoll(data) {
    return await request('/geoGekko/rentRoll/delete', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function initRentRollStatementUpload(data) {
    return await request('/geoGekko/uploadRentRollStatement', {
        method: 'POST',
        body: data,
        headers: {},
    });
}

export async function generateRentRollStatement(data) {
    return await request('/geoGekko/rentRollStatement/combined/generate', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function deleteRentRollStatement(data) {
    return await request('/geoGekko/rentRollStatement/delete', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getCategorizeChargeCodes(data) {
    return await request('/geoGekko/rentRoll/categorizeChargeCodes', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export function recategorizeOriginalRows(template, countyid, parcelid, data) {
    return request('/geoGekko/rentRollStatement/categories', {
        method: 'POST',
        params: {
            template,
            countyid,
            parcelid,
        },
        body: JSON.stringify(data),
    });
}

export async function setExtractRentValues(data) {
    return await request('/geoGekko/extractRentValues', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getExtractEscalations(data) {
    return await request('/geoGekko/extractEscalations', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getExtractEscalationsPeriods(data) {
    return await request('/geoGekko/extractEscalationPeriods?limit=10&addColumns=true', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getTenantNamesAutocomplete({
    keyword,
    countyId,
    parcelId,
}: TGetTenantNamesAutocompleteRequest): Promise<TGetTenantNamesAutocompleteResponse> {
    return await request('/geoGekko/rentroll/tenantname/autocomplete', {
        params: {
            keyword,
            countyid: countyId,
            parcelid: parcelId,
        },
    });
}

export async function setTenantNamesStandardized({ data, countyId, parcelId, userId }) {
    return await request('/api/v2/tenant-name/savestandardized', {
        method: 'POST',
        body: JSON.stringify(data),
        params: {
            countyid: countyId,
            parcelid: parcelId,
            userid: userId,
        },
    });
}
