import { memo } from 'react';

import { formatter } from '@utils/index';

import InfoIcon from '@scc/assets/icons/info.svg';

import Grid from '@ui/Grid';
import LinearProgress from '@ui/LinearProgress';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

const defaultProps = {
    tooltip: null,
    value: 34,
    isNumber: false,
    number: null,
    numberVariant: 'mdsb',
    numberColor: colors['lightBlue-500'],
    labelVariant: 'mdr',
    labelColor: colors['darkNeutral-500'],
    barColor: colors['propertyType-office'],
    barBgColor: colors['grey-A200'],
    barHeight: 8,
    layout: { left: 3, right: 9 },
};

function LabeledProgress({
    label,
    labelStyle = {},
    labelVariant,
    labelColor,
    tooltip,
    value,
    isNumber,
    number,
    numberVariant,
    numberColor,
    barColor,
    barBgColor,
    barHeight,
    layout,
}) {
    return (
        <Grid columnSpacing={16} container>
            <Grid xs={layout.left} item>
                <div className={styles.labeledProgressTooltipWrapper}>
                    <Typography color={labelColor} sx={{ width: 'auto', ...labelStyle }} variant={labelVariant}>
                        {label || ' '}
                    </Typography>
                    {tooltip && (
                        <Tooltip title={tooltip} arrow>
                            <span className={styles.labeledProgressTooltip}>
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    )}
                </div>
            </Grid>
            <Grid xs={layout.right} item>
                <div className={styles.labeledProgressBar}>
                    <LinearProgress
                        sx={{
                            backgroundColor: barBgColor,
                            height: barHeight,
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: barColor,
                            },
                        }}
                        value={value}
                        variant='determinate'
                    />
                    {isNumber && (
                        <Typography color={numberColor} variant={numberVariant}>
                            {formatter.number(number)}
                        </Typography>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}

LabeledProgress.defaultProps = defaultProps;

export default memo(LabeledProgress);
