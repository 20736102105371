import { homeData } from '@scc/pages/Home/homeData';

import Container from '@ui/Container';
import Grid from '@ui/Grid';
import Link from '@ui/Link';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import Image from '@shared/components/Image';

import styles from './styles.module.scss';

const {
    caseStudies: { title, description, cases, link },
} = homeData;

function CaseStudies({ mobile }) {
    return (
        <div className={styles.caseStudies}>
            <Container maxWidth='xl'>
                <div className={styles.wrapper}>
                    <div className={styles.headerContainer}>
                        <div className={styles.titleWrapper}>
                            <Typography variant={mobile ? 'h1b' : 'head2b'}>{title}</Typography>
                            {description && (
                                <Typography color={colors['grey-400']} variant='h4r'>
                                    {description}
                                </Typography>
                            )}
                        </div>
                        <Link
                            aria-label={link.label}
                            className={styles.link}
                            href={link.path}
                            target='_blank'
                            variant='text'
                        >
                            <Typography color={colors.black} component='span' variant='h5r'>
                                {link.label}
                            </Typography>
                        </Link>
                    </div>

                    <Grid spacing={16} container>
                        {cases.map(({ id, image, link: _link }) => (
                            <Grid key={id} md={4} xs={12} item>
                                <div className={styles.case}>
                                    <Link
                                        aria-label={_link.label}
                                        className={styles.imgWrapper}
                                        href={_link.path}
                                        rel='nofollow'
                                        target='_blank'
                                    >
                                        <Image
                                            alt={_link.label}
                                            className={styles.img}
                                            loading='lazy'
                                            sizes={`${image.src?.width}px`}
                                            src={image.src.src}
                                            srcSet={image.src.srcSet}
                                            webp={image.webp.srcSet}
                                            width={image.src?.width}
                                        />
                                    </Link>

                                    <Link
                                        aria-label={_link.label}
                                        className={styles.link}
                                        href={_link.path}
                                        target='_blank'
                                    >
                                        <Typography color={colors.black} component='span' variant='lgsb'>
                                            {_link.label}
                                        </Typography>
                                    </Link>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default CaseStudies;
