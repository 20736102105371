import { reloadable } from '@utils/components';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import QuotesIcon from '@scc/assets/icons/quotes.svg';
import SliderComment from '@scc/components/SliderComment';
import { comments } from '@scc/statics/content';

import ServicesMenu from './ServicesMenu';
import styles from './services.module.scss';

const Slider = reloadable(() => import('@scc/components/Slider'), { ssr: false });

const ServicesTemplate = function ({ children }) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container spacing={isDesktop ? 46 : 0} justifyContent='center'>
            <Grid item xs={12} md={3}>
                <ServicesMenu />
            </Grid>
            <Grid item xs={12} md={9} className={styles.content}>
                {children}
                <br />
                <Icon className={styles.quotes} component={QuotesIcon} sx={{ stroke: '#C4C7D4' }} viewBox='0 0 60 43' />
                <Slider slides={comments}>
                    {({ position, name, message, image }) => (
                        <>
                            <SliderComment.Square image={image} name={name} job={position} comment={message} />
                            <span slot='wrapper-end'>&nbsp;</span>
                        </>
                    )}
                </Slider>
            </Grid>
        </Grid>
    );
};

export default ServicesTemplate;
