import { format } from 'date-fns';

import request from '@utils/request';

const BASE_URL = '/api/v2/rates';

export async function getRates({ loanId, rateDate }) {
    return request(`${BASE_URL}/loan/${loanId}`, {
        params: {
            rateDate: rateDate ? format(rateDate, 'yyyy-MM-dd') : null,
        },
    });
}
