import { useEffect } from 'react';

const useDebouncedEffect = (effect, deps, delay, callback) => {
    useEffect(() => {
        const handler = setTimeout(effect, delay);

        return () => {
            clearTimeout(handler);
            callback?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
