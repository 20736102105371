import userImage1 from '@scc/assets/images/users/customer1.jpg';
import userImage2 from '@scc/assets/images/users/customer2.jpg';
import userImage from '@scc/assets/images/users/male.jpg';


export const comments = [
    {
        name: 'Nitti Kaur',
        image: userImage1,
        position: 'CEO, Abundance360 Inc.,<br> Los Angeles',
        message: `Smart Capital Center provided a detailed analysis of a portfolio of properties I was interested in purchasing - in under 24 hours - faster than anyone else can! They provided a comprehensive and easy-to-follow market analysis and property valuation, which helped me evaluate the investment much faster. 
        Smart Capital also worked on lining up a financing package for the deal. I ended up not pursuing the acquisition due to the poor condition of the property, but I greatly appreciate SCC help throughout the process – they helped me save time and money.`,
    },
    {
        name: 'Roshan N.',
        image: userImage2,
        position: 'Partner, Lonebridge Ohio LLC,<br> San Francisco',
        message: 'Smart Capital Center enabled us to focus on looking for more investment opportunities instead of spending our time filling out forms and looking for lenders. They provided fast bank-quality analyses and did all the lender negotiations to get attractive mortgage rates for us. ' +
            'At the end, they got us a low-interest high-LTV mortgage – much better than what we were hoping for at the start. It was a challenging, time-sensitive deal and they successfully pushed the deal through to the finish line for us.',
    },
    {
        name: 'Naeem Gilani',
        image: userImage,
        position: 'Real Estate Investor at Luzon Apartment, Houston TX',
        message: 'I used Smart Capital Center to value my own properties in order to understand if an acquisition offer I received from a broker was a fair one. ' +
            'Smart Capital Center provided valuation reports for two of my properties in under 24 hours and they were extremely helpful. I realized I could get a lot more for the properties I own. In fact, I had an opportunity to do a cash-out refinance instead of the outright sale. A refinance is a more attractive option for me in this high-growing market. Smart Capital Center made it much easier to make the right decision.',
    },
];

export const mainPageSubtitles = [
    'Secure Low-Cost Financing',
    'Get Ultra-Fast Deal Underwriting',
    'Monitor Your Portfolio As It Grows',
]

export const searchProperties = [
    { label: '111 N Hill St, Los Angeles, CA 90012, USA', type: 'Office' },
    { label: '11263 Oleander Ave, Fontana, CA 92337, USA', type: 'Office' },
    { label: '450 Broadway, Redwood City, CA 94063, USA', type: 'Multi-Family' },
    { label: '3450 Farm to Market 1960 Rd W, Houston, TX 77068, USA', type: 'Multi-Family' },
    { label: '123 S Figueroa St, Los Angeles, CA 90012, USA', type: 'Multi-Family' },
    { label: '3450 Hill St, Los Angeles, CA 92337, USA', type: 'Office' },
    { label: 'United States', type: 'Multi-Family' },
];

export const frequentlyAskedQuestions = [
    {
        name: 'faq1',
        title: 'What property types does Smart Capital Center cover?',
        description: 'We cover all income generating real estate – income-generating single family, fix-n-flip, multifamily, office, retail, industrial, mobile homes, storage, and others.',
    },
    {
        name: 'faq2',
        title: 'How long does it take to get the detailed property valuation and loan quote?',
        description: 'Our bank-quality valuation report and loan quotes are delivered under 24 hours.  \n' +
            '\n' +
            'We use technology and our rich database to do the same work that usually takes others a week to complete – we can do it within minutes.',
    },
    {
        name: 'faq3',
        title: 'Do you specialize in a certain type of financing?',
        description:
            'We specialize in lending for all types of income-generating real estate (CRE) - including income-generating single family, fix-n-flip, multifamily, office, retail, industrial, hotel, senior housing, student housing, mobile homes, and storage.\n' +
            '\n' +
            'We provide both debt and equity financing, for acquisition or refinancing.',
    },
    {
        name: 'faq4',
        title: 'What states does your service cover?',
        description:
            'We provide valuations and loans nationwide.',
    },
    {
        name: 'faq5',
        title: 'What information is included in the valuation report?',
        description:
            'Our comprehensive report includes comprehensive analysis of property operating and financial performance, comparable sales transactions, valuation based on sales transactions and based on property income, detailed local market overview, and much more.',
    },
    {
        name: 'faq6',
        title: 'How can  I use the valuation report?',
        description:
            'The independent valuation report can be used to negotiate a fair price for new assets that you are considering to purchase.' +
            '\n' +
            'It can also be used to value your existing assets so you can evaluate your total wealth or estimate how much refinancing you can get.',
    },
    {
        name: 'faq7',
        title: 'What are your fees?',
        description:
            'Our fees are substantially lower than what you can get elsewhere as we leverage our industry-leading technology and our rich database to do all the work at a fraction of the time required previously.' +
            '\n' +
            'Our detailed property valuation is at $199 - and it is completely waived if you close a loan through us.' +
            '\n' +
            'We charge 1% for the loan service, and you will only be charged once you receive the loan.' +
            '\n' +
            'We relentlessly negotiate loan terms with lenders on your behalf which means that our fees are totally covered by the savings you get by engaging our experienced negotiators.' +
            '\n' +
            'In fact, the total costs of financing is lowered by an average by over 2% of the total loan amount as a result of working with Smart Capital Center’s experienced team.',
    }
];
