import request from '@utils/request';

const BASE_URL = '/api/v2/team';

export async function getTeam({ loanId, type } = {}) {
    return request(`${BASE_URL}/loan/${loanId}/type/${type}`);
}

export async function deleteMember(body) {
    return request(`${BASE_URL}/member/delete`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function setmemberAsLead(body) {
    return request(`${BASE_URL}/set-contact-lead`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function updateMemberRole(body) {
    return request(`${BASE_URL}/member/update-role`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}
