import { createSlice } from '@reduxjs/toolkit';
import { put, all, call, delay, takeLatest } from 'redux-saga/effects';

import { loadLenderDocuments } from '@scc/store/ducks/loans/lenderDocuments';

import { documentsApi } from '@api';
import { UPLOAD_LENDER_DOCUMENTS_STEPS } from '@constants/modals';
import { uploadDocumentsForm } from '@shared/templates/forms/UploadDocuments';
import { showDrawer, hideDrawer } from '@store/ducks/ui/drawer';

const entity = '[sh:flowLender]';

const { MAIN, LOADING, PREPARE, SUCCESS, ERROR } = uploadDocumentsForm.TEMPLATES;

const initialState = {
    loading: false,
    loaded: false,
    template: '',
};

const memberFLowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        upload(state) {
            state.template = MAIN;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
        add() {},
        uploadDocuments() {},
    },
});

export const {
    upload: uploadLenderDocuments,
    setTemplate: setTemplateLenderDocuments,
    add: addLenderDocumentsFlow,
    uploadDocuments: uploadLenderDocumentsFlow,
} = memberFLowSlice.actions;

export default memberFLowSlice.reducer;

export const selectLenderFlowState = (store) => store.flow.lender;

function* uploadLenderDocumentsSaga({ payload }) {
    yield put(
        showDrawer({
            content: UPLOAD_LENDER_DOCUMENTS_STEPS,
            data: payload,
        })
    );
}

function* addLenderDocumentsFlowSaga({ payload }) {
    const { selectDocs, lenderId } = payload;
    yield put(setTemplateLenderDocuments(LOADING));
    yield all(selectDocs.map((documentId) => call(documentsApi.addToLenderDocument, { documentId, lenderId })));
    yield put(setTemplateLenderDocuments(SUCCESS));
    yield delay(3000);
    yield put(hideDrawer());
}

function* uploadLenderDocumentsFlowSaga({ payload }) {
    const { prepareDocs, lenderId, editMode } = payload;
    yield put(setTemplateLenderDocuments(LOADING));

    const method = editMode ? documentsApi.updateDocument : documentsApi.uploadDocumentsToLender;

    const responses = yield all(
        Object.values(prepareDocs).map(({ id, name, start, end, type, date, file }) =>
            call(method, {
                id,
                lenderId,
                name,
                type,
                date,
                pages: `${start}-${end}`,
                file,
            })
        )
    );

    if (responses.some((res) => res.error)) {
        yield put(setTemplateLenderDocuments(ERROR));
        yield delay(2300);
        yield put(setTemplateLenderDocuments(PREPARE));
    } else {
        yield put(loadLenderDocuments(lenderId));
        yield put(setTemplateLenderDocuments(SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
    }
}

export function* watchLenderFLow() {
    yield takeLatest(uploadLenderDocuments, uploadLenderDocumentsSaga);
    yield takeLatest(addLenderDocumentsFlow, addLenderDocumentsFlowSaga);
    yield takeLatest(uploadLenderDocumentsFlow, uploadLenderDocumentsFlowSaga);
}
