/* eslint-disable import/no-extraneous-dependencies */
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useRef, useEffect } from 'react';

import { reloadable } from '@utils/components';

import Meta from '@components/Meta';

import config from '@config';
import useIsomorphicLayoutEffect from '@shared/hooks/useLayoutEffect';

import animationStyles from './animation.module.scss';
import AiSolution from './compnents/AiSolution';
import CaseStudies from './compnents/CaseStudies';
import Hero from './compnents/Hero';
import LowCostFinancing from './compnents/LowCostFinancing';
import Mission from './compnents/Mission';
import PropertyMonitoring from './compnents/PropertyMonitoring';
import Underwriting from './compnents/Underwriting';

const Feedback = reloadable(() => import('./compnents/Feedback'), { ssr: false });

const isServer = config.get('isServer');

if (!isServer) {
    gsap.registerPlugin(ScrollTrigger);
}

const animatedSections = [
    { selector: '.solution', className: animationStyles.solutionActive },
    { selector: '.mission', className: animationStyles.missionActive },
];

const addAnimation = ({ target, vars }) => target?.classList.add(vars.className);

function HomePage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const missionRef = useRef();
    const solutionRef = useRef();

    useEffect(() => {
        animatedSections.forEach(({ selector, className }) => {
            if (isServer) return;
            const target = document.querySelector(selector);
            ScrollTrigger.observe({
                target,
                type: 'touch',
                className,
                onUp: addAnimation,
                onDown: addAnimation,
            });
        });
    }, []);

    useIsomorphicLayoutEffect(() => {
        const selector = '.solution';
        const selectors = gsap.utils.toArray(selector);

        const ctx = gsap.context(() => {
            selectors.forEach((title) => {
                gsap.to(title, {
                    scrollTrigger: {
                        trigger: title,
                        start: 'center 65%',
                        end: 'center end',
                        onEnter: ({ trigger }) => {
                            trigger.classList.add(animationStyles.solutionActive);
                        },
                    },
                });
            });
        }, solutionRef);

        return () => ctx.revert();
    }, []);

    useIsomorphicLayoutEffect(() => {
        const selector = '.mission';
        const selectors = gsap.utils.toArray(selector);

        const ctx = gsap.context(() => {
            selectors.forEach((title) => {
                gsap.to(title, {
                    scrollTrigger: {
                        trigger: title,
                        start: 'center 65%',
                        end: 'center end',
                        onEnter: ({ trigger }) => {
                            trigger.classList.add(animationStyles.missionActive);
                        },
                    },
                });
            });
        }, missionRef);

        return () => ctx.revert();
    }, []);

    return (
        <>
            <Meta
                description={`Get real-time valuation, cap rates, real estate market data. 
                    Find best loans for any multifamily or commercial real estate property 
                    | Smart Capital Center`}
                title='Smart Capital Center: Real-time valuation, cap rates, loans'
            />
            <Hero mobile={isMobile} />
            <AiSolution mobile={isMobile} ref={solutionRef} />
            <Underwriting mobile={isMobile} />
            <LowCostFinancing mobile={isMobile} />
            <PropertyMonitoring mobile={isMobile} />
            <Mission mobile={isMobile} ref={missionRef} />
            <Feedback mobile={isMobile} />
            <CaseStudies mobile={isMobile} />
        </>
    );
}

export default HomePage;
