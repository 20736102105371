import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeEvery } from 'redux-saga/effects';

import LoanPortfolioCharts from '@utils/charts/classes/LoanPortfolioCharts';

import { propertyPortfolio, loansApi } from '@api';
import { API_SUCCESS, API_ERROR, apiRequest } from '@store/ducks/api';

const entity = '[loansPortfolio]';

export const CALCULATION_METHOD = {
    BALANCE: 'balance',
    PRINCIPAl: 'principal',
};

const initialState = {
    loading: false,
    loaded: false,
    loadingPortfolio: false,
    loadedPortfolio: false,
    loadingHeader: false,
    loadedHeader: false,
    loadingCharts: false,
    loadedCharts: false,
    loadingAnalytics: false,
    data: {},
    header: {},
    charts: {},
    analytics: {},
    calculationMethod: CALCULATION_METHOD.BALANCE,
};

const portfolioSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loadingPortfolio = true;
            state.loadedPortfolio = false;
        },
        loadHeader(state) {
            state.loadingHeader = true;
            state.loadedHeader = false;
        },
        loadCharts(state) {
            state.loadingCharts = true;
            state.loadedCharts = false;
        },
        loadMain(state) {
            state.loading = true;
            state.loaded = false;
        },
        loadedMain(state) {
            state.loading = false;
            state.loaded = true;
        },
        loadAnalytics(state) {
            state.loadingAnalytics = true;
        },
        setAnalytics(state, action) {
            state.loadingAnalytics = false;
            state.analytics = action.payload;
        },
        [`loadAnalytics ${API_ERROR}`](state) {
            state.loadingAnalytics = false;
        },
        set(state, action) {
            state.data = action.payload;
            state.loadedPortfolio = true;
            state.loadingPortfolio = false;
        },
        setHeader(state, action) {
            state.header = action.payload;
            state.loadedHeader = true;
            state.loadingHeader = false;
        },
        setCharts(state, action) {
            state.charts = action.payload;
            state.loadedCharts = true;
            state.loadingCharts = false;
        },
        setCalculationMethod(state, action) {
            state.calculationMethod = action.payload;
        },
    },
});

export const {
    load: loadLoansPortfolio,
    set: setLoansPortfolio,
    loadHeader: loadLoansPortfolioHeader,
    setHeader: setLoansPortfolioHeader,
    loadCharts: loadLoansPortfolioCharts,
    setCharts: setLoansPortfolioCharts,
    loadMain: loadMainData,
    loadedMain: loadedMainData,
    loadAnalytics: loadAnalyticsLoansPortfolio,
    setAnalytics: setAnalyticsLoansPortfolio,
    setCalculationMethod: setCalculationMethodLoansPortfolio,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;

export const selectLoansPortfolioState = (store) => store.loans.portfolio;

export const selectLoansPortfolioData = (store) => store.loans.portfolio.data;

function* loadLoansPortfolioSaga({ payload, type }) {
    yield put(apiRequest(payload, propertyPortfolio.loadLoansPortfolio, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setLoansPortfolio(action.payload.data));
}

function* loadLoansPortfolioHeaderSaga({ payload, type }) {
    yield put(apiRequest(payload, propertyPortfolio.loadLoansPortfolioHeader, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setLoansPortfolioHeader(action.payload.data));
}

function* loadLoansPortfolioChartsSaga({ payload }) {
    const charts = new LoanPortfolioCharts();
    charts.addData(payload);
    yield put(setLoansPortfolioCharts(charts));
}

function* loadMainDataSaga({ payload }) {
    yield put(loadLoansPortfolio(payload));
    yield put(loadLoansPortfolioHeader(payload));
    const portfolioHeader = yield take(setLoansPortfolioHeader);
    yield put(loadLoansPortfolioCharts({ loanStates: portfolioHeader.payload.loanStates }));
    const portfolio = yield take(setLoansPortfolio);
    yield put(
        loadLoansPortfolioCharts({
            portfolio: portfolio.payload,
            loanStates: portfolioHeader.payload.loanStates,
            osData: portfolio.payload.osData,
            tenants: portfolio.payload.tenants,
        })
    );
    yield put(loadedMainData());
}

function* loadAnalyticsLoansPortfolioSaga({ payload, type }) {
    yield put(apiRequest(payload, loansApi.getLoanPortfolioAnalyticsById, type));

    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(setAnalyticsLoansPortfolio(action.payload.data));
}

export function* watchPortfolio() {
    yield takeEvery(loadLoansPortfolio, loadLoansPortfolioSaga);
    yield takeEvery(loadLoansPortfolioHeader, loadLoansPortfolioHeaderSaga);
    yield takeEvery(loadLoansPortfolioCharts, loadLoansPortfolioChartsSaga);
    yield takeEvery(loadMainData, loadMainDataSaga);
    yield takeEvery(loadAnalyticsLoansPortfolio, loadAnalyticsLoansPortfolioSaga);
}
