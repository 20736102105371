import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import colors from '@themes/palette/export.module.scss';

import { baseChartConfig } from './index';

const rentsSplitted = merge(cloneDeep(baseChartConfig()), {
    series: [
        {
            data: [
                {
                    value: 0,
                    itemStyle: {
                        color: colors['chart-01'],
                    },
                },
                {
                    value: 0,
                    itemStyle: {
                        color: colors['chart-02'],
                    },
                },
            ],
            type: 'bar',
            barWidth: 64,
        },
    ],
});

export default rentsSplitted;
