import { fullNameToInitials } from '@utils/index';

import { MEMBER_TYPES } from '@components/DataTable/constants';

export const getMemberRequestDataAdapter = ({ user, userMember, companyMember } = {}) => {
    let resObj;
    if (user) {
        const { userInfo, member, userCompanyDTO } = userMember;
        const { firstname, lastname } = userInfo || {};
        const name = [firstname, lastname].join(' ');
        resObj = {
            userProfile: {
                name: fullNameToInitials(name),
                avatar: `/api/v2/user/${member.userId}/avatar`,
            },
            ...(userInfo || {}),
            ...member,
            ...userCompanyDTO,
            name,
            firstName: firstname,
            lastName: lastname,
        };
    } else {
        resObj = {
            userProfile: {
                name: companyMember.company.name,
                avatar: `/api/v2/company/logo/${companyMember.company.id}`,
            },
            ...companyMember.company,
            ...companyMember.member,
        };
    }

    const {
        id,
        name,
        firstName,
        lastName,
        address,
        contactAddress,
        email,
        phone,
        position,
        memberId,
        type: dataType,
        companyType,
        companyUrl,
        ssn,
        outstanding,
        ownedCompanies,
        notes,
        userProfile,
        userId,
        companyName,
        inviteConfirmed,
        profilePictureExists,
    } = resObj;

    const addressKey = address || contactAddress;

    const [streetAddress, ...restAddress] = addressKey?.split(',') || [];

    return {
        userProfile,
        id,
        userId,
        type: dataType || companyType,
        memberId,
        name,
        firstName,
        lastName,
        ownedCompanies,
        ssn,
        outstanding,
        post: position,
        notes,
        user,
        inviteConfirmed,
        profilePictureExists,
        companyName,
        fullAddress: addressKey,
        contacts: {
            phone: {
                title: phone,
            },
            [user ? 'email' : 'site']: {
                title: user ? email : companyUrl,
            },
            address: {
                title: streetAddress,
                subtitle: restAddress?.join(', ').trimStart(),
            },
        },
    };
};

export const parseMemberResponseObject = (item) => {
    const { user, isNew } = item;

    if (user) {
        const {
            email = '',
            firstname = '',
            lastname = '',
            phone = '',
            address = '',
            profilePictureExists,
        } = item?.userMember?.userInfo || {};

        const {
            id = '',
            userId,
            memberId: userMemberId = '',
            outstanding: userOutstanding = 0,
            type = '',
        } = item?.userMember?.member || {};
        const { companyId, companyName, position } = item?.userMember?.userCompanyDTO || {};

        return {
            isNew,
            user,
            id,
            entity: 'INDIVIDUAL',
            userId,
            avatar: profilePictureExists ? `/api/v2/user/${userId}/avatar` : '',
            memberId: userMemberId,
            companyId,
            companyName,
            position,
            name: `${firstname} ${lastname}`,
            firstName: firstname,
            lastName: lastname,
            type,
            address,
            email,
            outstanding: userOutstanding,
            phone,
            userMember: { ...item.userMember },
        };
    }
    const {
        id,
        memberId: companyMemberId = '',
        outstanding: companyOutstanding = '',
    } = item?.companyMember?.member || {};

    const {
        id: companyId = '',
        companyType = '',
        companyUrl = '',
        contactAddress = '',
        email: companyEmail = '',
        name = '',
        phone: companyPhone = '',
    } = item?.companyMember?.company || {};

    return {
        isNew,
        user,
        companyId,
        id,
        entity: 'COMPANY',
        memberId: companyMemberId,
        name,
        type: companyType,
        companyUrl,
        address: contactAddress,
        companyEmail,
        outstanding: companyOutstanding,
        companyPhone,
        companyMember: { ...item.companyMember },
    };
};

export const parseResponseContent = (content) =>
    Array.isArray(content)
        ? content.map((item) => parseMemberResponseObject(item))
        : parseMemberResponseObject(content);

export const parseDocuments = (content) => {
    return content.map(({ documentMembers, documentLoans, documentProperties, ...item }) => ({
        documentMembers: documentMembers.map((userItem) => {
            const {
                contacts: { email: { title: userEmail } = {}, phone: { title: userPhone } = {} } = {},
                id,
                firstName: firstname,
                lastName: lastname,
                name,
                type,
                email: companyEmail,
                phone: companyPhone,
            } = getMemberRequestDataAdapter(userItem);
            return {
                email: userEmail || companyEmail,
                firstname,
                lastname,
                type: MEMBER_TYPES[type],
                name,
                phone: userPhone || companyPhone,
                memberId: id,
            };
        }),
        documentLoans: documentLoans.map(({ id, jsonValues, ...rest }) => ({
            id,
            loanDetailsId: id,
            ...rest,
            jsonValues: jsonValues ? JSON.parse(jsonValues) : {},
        })),
        documentProperties: documentProperties.map(({ id, ...rest }) => ({ ...rest, propertyId: id })),
        ...item,
    }));
};
