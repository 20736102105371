import links from '@scc/router/links';

export const services = {
    title: 'Services',
    links: [
        {
            name: 'financing',
            label: 'Low-Cost Financing',
            value: links.services.routes.financing.path,
            toScroll: true,
        },
        {
            name: 'underwriting',
            label: 'Ultra-Fast Deal Underwriting',
            value: links.services.routes.valuation.path,
            toScroll: true,
        },
        {
            name: 'portfolio',
            label: 'Portfolio Management',
            value: links.services.routes.monitoring.path,
            toScroll: true,
        },
        {
            name: 'listings',
            label: 'Listings',
            value: links.listings.path,
            noFollow: true,
        },
        {
            name: 'enterprise',
            label: 'Enterprise',
            value: links.enterprise.path,
        },
    ],
};
