import { createSlice } from '@reduxjs/toolkit';
import { takeLatest, put, delay, take, select } from 'redux-saga/effects';

import {
    createSaveNotOutlierTransactionPayload,
    createSaveOutlierTransactionPayload,
    TRANSACTIONS,
    getMergedOutliersIds,
} from '@scc/components/SalesTransactionsTable/helpers';
import { selectCurrentPageCommon } from '@scc/store/ducks/common';

import { transactionsApi } from '@api';
import { ADD_SIMILAR_TRANSACTIONS } from '@constants/modals';
import { apiRequest, API_SUCCESS, API_ERROR } from '@store/ducks/api';
import { showPrimaryAsideDrawer, hideDrawer } from '@store/ducks/ui/drawer';

const entity = '[similarTransactions]';

const initialState = {
    data: {
        precision: 50,
        filterRequest: {
            radius: 40,
        },
    },
    loading: false,
    loaded: true,
    excludedTransactions: {},
    outlierTransactionsIds: {},
    error: null,
    toast: null,
};

const similarTransactionsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            state.loading = true;
            state.loaded = false;
            state.toast = action.payload?.toast;
        },

        set(state, action) {
            const { similarTransactionsResponse, outlierTransactions, notOutlierTransactions } = action.payload;

            state.outlierTransactionsIds = getMergedOutliersIds(outlierTransactions, notOutlierTransactions);
            state.data = similarTransactionsResponse;
            state.loading = false;
            state.loaded = true;
            state.error = null;

            state.excludedTransactions = similarTransactionsResponse?.zoomedValues.reduce((prev, current) => {
                const prevCopy = { ...prev };

                if (prevCopy[current.id]) {
                    return prevCopy;
                }

                const isTransactionOutlier = state.outlierTransactionsIds[current.id];

                if (current.excluded || isTransactionOutlier) {
                    prevCopy[current.id] = true;
                }

                if (isTransactionOutlier === false) {
                    prevCopy[current.id] = false;
                }

                return prevCopy;
            }, state.excludedTransactions);
        },
        toggle(state, action) {
            const isExcluded = state.excludedTransactions[action.payload];

            state.outlierTransactionsIds[action.payload] = !isExcluded;

            state.excludedTransactions[action.payload] = !isExcluded;
        },
        [`load ${API_ERROR}`](state, action) {
            state.loaded = true;
            state.loading = false;
            state.error = action.payload;
        },
        create() {},
    },
});

export const {
    load: loadSimilarTransactions,
    set: setSimilarTransactions,
    toggle: toggleSimilarTransactions,
    create: createTransactionsSimilarTransactions,
} = similarTransactionsSlice.actions;

export default similarTransactionsSlice.reducer;

export const selectSimilarTransactionsState = (state) => state.similarTransactions;

function* loadSimilarTransactionsSaga({ type, payload }) {
    yield delay(200);
    yield put(apiRequest(payload, transactionsApi.getSimilarTransactions, type));

    const action = yield take([`${loadSimilarTransactions.type} ${API_SUCCESS}`]);

    yield put(
        setSimilarTransactions({
            similarTransactionsResponse: action.payload,
            outlierTransactions: action.payload.outlierTransactions?.split(',').filter(Boolean) || [],
            notOutlierTransactions: action.payload.notOutlierTransactions?.split(',').filter(Boolean) || [],
        })
    );
}

function* createTransactionsSimilarTransactionsSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: ADD_SIMILAR_TRANSACTIONS,
        })
    );
    const action = yield take(hideDrawer);
    if (action?.payload) {
        // TODO: remove after adding global toast
        yield put(loadSimilarTransactions({ ...payload, toast: action?.payload }));
    }
}

function* toggleSimilarTransactionsSaga() {
    const { params } = yield select(selectCurrentPageCommon);

    const { outlierTransactionsIds } = yield select(selectSimilarTransactionsState);

    const outlierTransactions = [];
    const notOutlierTransactions = [];

    Object.entries(outlierTransactionsIds).forEach(([key, value]) => {
        if (value) {
            outlierTransactions.push(key);
        } else {
            notOutlierTransactions.push(key);
        }
    });

    const saveNotOutlierTransactionsPayload = createSaveNotOutlierTransactionPayload({
        ...params,
        notOutlierTransactions,
    });

    const saveOutlierTransactionsPayload = createSaveOutlierTransactionPayload({
        ...params,
        outlierTransactions,
    });

    yield put(
        apiRequest(
            saveNotOutlierTransactionsPayload,
            transactionsApi.overrideSimilarTransactions,
            TRANSACTIONS.NOT_OUTLIER
        )
    );
    yield put(
        apiRequest(saveOutlierTransactionsPayload, transactionsApi.overrideSimilarTransactions, TRANSACTIONS.OUTLIER)
    );
}

export function* watchSimilarTransactions() {
    yield takeLatest(loadSimilarTransactions, loadSimilarTransactionsSaga);
    yield takeLatest(createTransactionsSimilarTransactions, createTransactionsSimilarTransactionsSaga);
    yield takeLatest(toggleSimilarTransactions, toggleSimilarTransactionsSaga);
}
