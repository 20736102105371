import { put, take, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { thirdPartyReportApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { hideDrawer, showPrimaryAsideDrawer, showBottomPageDrawer } from '@store/ducks/ui/drawer';
import { SECONDARY_MODAL_HEADER, UPLOAD_REPORT, ORDER_REPORT } from '@constants/modals';
import { OrderReportForm } from '@templates/forms/OrderReport';
import config from '@config';

const isSQ = config.get('app') === 'sq';

const entity = '[loanTPReport]';
const CREATE_REPORT_ACTION = 'createReport';

const initialState = {
    data: [],
    error: null,
    formData: {},
    template: OrderReportForm.TEMPLATES.MAIN,
    loaded: false,
    loading: false,
    formLoaded: false,
    formLoading: false,
    reportUpdate: false,
};

const loanThirdPartyReportSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
            state.reportUpdate = false;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        setForm(state, action) {
            state.formData = action.payload;
            state.formLoaded = true;
            state.formLoading = false;
        },
        start(state) {
            state.formLoaded = false;
            state.formLoading = false;
            state.template = OrderReportForm.TEMPLATES.MAIN;
        },
        startOrder(state) {
            state.formLoaded = false;
            state.formLoading = false;
            state.template = OrderReportForm.TEMPLATES.MAIN;
        },
        setTemplate(state, action) {
            state.formLoaded = true;
            state.formLoading = false;
            state.template = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
            state.formLoaded = true;
            state.formLoading = false;
        },
        finishCreateReport(state) {
            state.loaded = true;
            state.loading = false;
            state.reportUpdate = true;
        },
        [CREATE_REPORT_ACTION](state) {
            state.formLoaded = false;
            state.formLoading = true;
            state.template = OrderReportForm.TEMPLATES.MAIN;
        },
        createOrderReport(state) {
            state.formLoaded = false;
            state.formLoading = true;
            state.template = OrderReportForm.TEMPLATES.MAIN;
        },
    },
});

export const {
    load: loadLoanThirdPartyReport,
    setData: setLoanThirdPartyReport,
    setForm: setFormLoanThirdPartyReport,
    setTemplate: setTemplateLoanThirdPartyReport,
    start: startLoanThirdPartyReport,
    startOrder: startLoanOrderThirdPartyReport,
    createReport: createThirdPartyReport,
    createOrderReport: createOrderThirdPartyReport,
    finishCreateReport: finishCreateThirdPartyReport,
    setError: setErrorFormLoanThirdPartyReport,
} = loanThirdPartyReportSlice.actions;

export default loanThirdPartyReportSlice.reducer;

export const selectLoanThirdPartyReportState = (store) => store.loans.thirdPartyReport;

function* loadLoanThirdPartyReportSaga({ type, payload }) {
    yield delay(200);
    yield put(apiRequest(payload, thirdPartyReportApi.getThirdPartyReports, type));
    const response = yield take(`${type} ${API_SUCCESS}`);
    const { content: data } = response.payload.data;
    yield put(setLoanThirdPartyReport(data));
}

function* createThirdPartyReportSaga({ type, payload }) {
    yield put(apiRequest(payload, thirdPartyReportApi.createThirdPartyReport, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setFormLoanThirdPartyReport(action.payload.data));
    }
    if (action.type.endsWith(API_ERROR)) {
        yield put(setErrorFormLoanThirdPartyReport(action.payload));
    }
}

function* createOrderThirdPartyReportSaga({ type, payload }) {
    yield put(apiRequest(payload, thirdPartyReportApi.createThirdPartyReport, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setFormLoanThirdPartyReport(action.payload.data));
        yield put(finishCreateThirdPartyReport());
    }
    if (action.type.endsWith(API_ERROR)) {
        yield put(setErrorFormLoanThirdPartyReport(action.payload));
    }
}

function* finishCreateThirdPartyReportSaga() {
    yield put(setTemplateLoanThirdPartyReport(OrderReportForm.TEMPLATES.SUCCESS));
    yield delay(3000);
    yield put(hideDrawer());
}

function* startLoanThirdPartyReportSaga({ payload }) {
    // todo
    const drawer = isSQ ? showPrimaryAsideDrawer : showBottomPageDrawer;
    yield put(
        drawer({
            content: UPLOAD_REPORT,
            header: SECONDARY_MODAL_HEADER,
            data: payload,
        })
    );
}

function* startLoanOrderThirdPartyReportSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: ORDER_REPORT,
            data: payload,
        })
    );
}

export function* watchLoanThirdPartyReport() {
    yield takeLatest(loadLoanThirdPartyReport, loadLoanThirdPartyReportSaga);
    yield takeLatest(startLoanThirdPartyReport, startLoanThirdPartyReportSaga);
    yield takeLatest(startLoanOrderThirdPartyReport, startLoanOrderThirdPartyReportSaga);
    yield takeEvery(createThirdPartyReport, createThirdPartyReportSaga);
    yield takeEvery(createOrderThirdPartyReport, createOrderThirdPartyReportSaga);
    yield takeEvery(finishCreateThirdPartyReport, finishCreateThirdPartyReportSaga);
}
