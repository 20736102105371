import { baseApi as api } from '../configs/underwriting';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        updateTermSheet: build.mutation<UpdateTermSheetApiResponse, UpdateTermSheetApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/underwriting/term-sheet`,
                method: 'PUT',
                body: queryArg.termSheetDto,
            }),
        }),
        getTermSheet: build.query<GetTermSheetApiResponse, GetTermSheetApiArg>({
            query: (queryArg) => ({ url: `/api/v2/underwriting/term-sheet/${queryArg.termSheetId}` }),
        }),
        deleteTermSheet: build.mutation<DeleteTermSheetApiResponse, DeleteTermSheetApiArg>({
            query: (queryArg) => ({ url: `/api/v2/underwriting/term-sheet/${queryArg.termSheetId}`, method: 'DELETE' }),
        }),
        getTermSheetsByLoanDetails: build.query<
            GetTermSheetsByLoanDetailsApiResponse,
            GetTermSheetsByLoanDetailsApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/underwriting/term-sheet/loan/${queryArg.loanDetailsId}` }),
        }),
        generateTermSheet: build.mutation<GenerateTermSheetApiResponse, GenerateTermSheetApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/underwriting/term-sheet/generate/${queryArg.loanModelId}`,
                method: 'POST',
            }),
        }),
        getTermSheets: build.query<GetTermSheetsApiResponse, GetTermSheetsApiArg>({
            query: (queryArg) => ({ url: `/api/v2/underwriting/term-sheet/model/${queryArg.loanModelId}` }),
        }),
        toggleDefaultTermSheet: build.mutation<ToggleDefaultTermSheetApiResponse, ToggleDefaultTermSheetApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/underwriting/term-sheet/toggle-default`,
                method: 'POST',
                body: queryArg.setDefaultTermSheet,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type UpdateTermSheetApiResponse = /** status 200 OK */ GenericJsonResponseTermSheetDto;
export type UpdateTermSheetApiArg = {
    termSheetDto: TermSheetDto;
};
export type GetTermSheetApiResponse = /** status 200 OK */ GenericJsonResponseTermSheetDto;
export type GetTermSheetApiArg = {
    termSheetId: number;
};
export type DeleteTermSheetApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type DeleteTermSheetApiArg = {
    termSheetId: number;
};
export type GetTermSheetsByLoanDetailsApiResponse = /** status 200 OK */ GenericJsonResponseListTermSheetDto;
export type GetTermSheetsByLoanDetailsApiArg = {
    loanDetailsId: number;
};
export type GenerateTermSheetApiResponse = /** status 200 OK */ GenericJsonResponseTermSheetDto;
export type GenerateTermSheetApiArg = {
    loanModelId: number;
};
export type GetTermSheetsApiResponse = /** status 200 OK */ GenericJsonResponseListTermSheetDto;
export type GetTermSheetsApiArg = {
    loanModelId: number;
};
export type ToggleDefaultTermSheetApiResponse = /** status 200 OK */ GenericJsonResponseTermSheetDto;
export type ToggleDefaultTermSheetApiArg = {
    setDefaultTermSheet: SetDefaultTermSheet;
};
export type TermSheetField = {
    name?: string;
    value?: string;
    displayName?: string;
    type?: string;
};
export type TermSheetDto = {
    id: number;
    loanModelId?: number;
    name?: string;
    preamble?: string;
    date?: string;
    fields?: {
        [key: string]: TermSheetField;
    };
    default?: boolean;
};
export type GenericJsonResponseTermSheetDto = {
    status?: string;
    data?: TermSheetDto;
    message?: string;
    code?: string;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type GenericJsonResponseListTermSheetDto = {
    status?: string;
    data?: TermSheetDto[];
    message?: string;
    code?: string;
};
export type SetDefaultTermSheet = {
    loanModelId: number;
    termSheetId: number;
};
export const {
    useUpdateTermSheetMutation,
    useGetTermSheetQuery,
    useDeleteTermSheetMutation,
    useGetTermSheetsByLoanDetailsQuery,
    useGenerateTermSheetMutation,
    useGetTermSheetsQuery,
    useToggleDefaultTermSheetMutation,
} = injectedRtkApi;
