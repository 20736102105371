import { combineReducers } from 'redux';

import allUsersReducer, { watchAllUsers } from './allUsers';
import teamReducer, { watchTeam } from './team';
import teamsReducer, { watchTeams } from './teams';

export const configurationWatchers = [watchAllUsers, watchTeams, watchTeam];

export default combineReducers({
    allUsers: allUsersReducer,
    teams: teamsReducer,
    team: teamReducer,
});
