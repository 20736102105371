import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';

import { selectAuthMngState, loadConfirmPasswordAuthMng } from '@scc/store/ducks/authManager';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Input from '@ui/Input';
import InputAdornment from '@ui/InputAdornment';
import Stack from '@ui/Stack';

import colors from '@themes/palette/export.module.scss';

import EyeSlashSvg from '@assets/icons/outlined/eye-slash.svg';
import EyeSvg from '@assets/icons/outlined/eye.svg';
import PasswordCheckSvg from '@assets/icons/outlined/password-check.svg';
import useQueryParams from '@shared/hooks/useQueryParams';

import styles from './styles.module.scss';
import schema from './validationSchema';

function ForgotPasswordForm() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [{ email: emailParam, token: tokenParam }] = useQueryParams();

    const dispatch = useDispatch();
    const { error: authError, loading } = useSelector(selectAuthMngState);

    useEffect(() => {
        if (authError) {
            setError('password', {
                type: 'manual',
                message: authError.message,
            });
        }
    }, [authError]);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = ({ password }) => {
        dispatch(
            loadConfirmPasswordAuthMng({
                email: emailParam,
                pwd: password,
                token: tokenParam,
            })
        );
    };

    return (
        <Stack className={styles.formWrapper} direction='column' justifyContent='space-between' sx={{ height: 1 }}>
            <Box className={styles.form} component='form' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    label='Password'
                    margin='normal'
                    variant='standard'
                    {...register('password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    edge='end'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <Icon color={colors['grey-600']} component={EyeSlashSvg} fontSize='17px' />
                                    ) : (
                                        <Icon color={colors['grey-600']} component={EyeSvg} fontSize='17px' />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                />
                <Input
                    label='Confirm Password'
                    margin='normal'
                    variant='standard'
                    {...register('confirmPassword')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Icon color={colors['grey-600']} component={PasswordCheckSvg} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    edge='end'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <Icon color={colors['grey-600']} component={EyeSlashSvg} fontSize='17px' />
                                    ) : (
                                        <Icon color={colors['grey-600']} component={EyeSvg} fontSize='17px' />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                />

                <Button
                    color='primary'
                    loading={loading}
                    size='extra-large'
                    sx={{ marginTop: '66px' }}
                    type='submit'
                    fullWidth
                >
                    Set New Password
                </Button>
            </Box>
        </Stack>
    );
}

export default ForgotPasswordForm;
