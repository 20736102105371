import { TKeyDate } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/KeyDates/KeyDates.types';
import { TLoanDataReserves } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Reserves/Reserves.types';
import { TMember } from '@scc/store/ducks/loans/types/members.types';

export const TermsKeys = {
    LOAN_PROGRAM: 'newLoanProgram',
    LOAN_PURPOSE: 'loanProgram',
    LOAN_NAME: 'loanName',
    LENDER_TYPE: 'lenderType',
    LOAN_ID: 'loanId',
    LOAN_ID_TRIMONT: 'trimontLoanId',
    LOAN_ID_MIDLANDS: 'midlandsLoanId',
    LOAN_POSITION: 'loanPosition',
    ORIGINATION_DATE: 'originationDate',
    MATURITY_DATE: 'maturityDate',
    COUPON: 'coupon',
    COUPON_365: 'coupon365',
    COUPON_TYPE: 'couponType',
    INDEX: 'index',
    INDEX_VALUE: 'indexValue',
    FORWARD_SOFR: 'forwardSofr',
    SPREAD: 'spread',
    IO_AMORTIZING: 'ioAmortizing',
    IO_PERIOD: 'ioPeriod',
    AMORTIZATION: 'amortization',
    AMORTIZATION_SCHEDULE: 'amortizationSchedule',
    FIXED_MONTHLY_PAYMENT: 'fixedMonthlyAmounts',
    TERM_SOFT_ADJUSTMENT: 'termSoftAdjustment',
    INDEX_FLOOR: 'indexFloor',
    FLOOR: 'floor',
    ORIGINATION_FEE: 'originationFee',
    EXTENSION_FEE: 'extensionFee',
    EXIT_FEE: 'exitFee',
    PREPAYMENT_PROTECTION: 'prepaymentProtection',
    INTEREST_RATE_CAP: 'interestRateCap',
    INTEREST_RATE_CAP_EXTENSION: 'interestRateCapExtension',
    INTEREST_RATE_CAP_EXPIRATION: 'interestRateCapExpiration',
    LOAN_RATING: 'loanRating',
    CASH_SWEEP: 'cashSweep',
    RECOURSE: 'recourse',
    COLLATERAL: 'collateral',
    STATUS: 'loanDetailsStatus',
    LENDER: 'lender',
    FIXED_FLOATING: 'fixedFloating',
    LOAN_IDS_METADATA: 'loanIdsMetadata',
    LOAN_ID_VALUES: 'loanIdValues',
    INDEX_DATE: 'indexDate',
    K_DEAL_DESIGNATION: 'kDealDesignation',
    ACCOUNTING_DESIGNATION: 'accountingDesignation',
    K_DEAL_DESTINATION_OPTIONS: 'kDealDesignationOptions',
    ACCOUNTING_DESTINATION_OPTIONS: 'accountingDesignationOptions',
    LOAN_PROGRAM_OPTIONS: 'newLoanProgramOptions',
} as const;

export type TCustomLoanIdMetadata = {
    id: string;
    value: string;
};

export type TCustomLoanIdValue = {
    label: string;
    value: string;
};

export type TCustomLoanIdsMetadata = {
    [key: number]: TCustomLoanIdMetadata;
};

export type TCustomLoanIdsValues = {
    [key: string]: TCustomLoanIdValue;
};

export type TLoanTermsKeys = (typeof TermsKeys)[keyof typeof TermsKeys];

type TLoanTermsBeforeOmit = {
    [key in TLoanTermsKeys]: key extends 'ioAmortizing' ? number[] : key extends 'lender' ? TMember : string | number;
};

export type TLoanTerms = Omit<TLoanTermsBeforeOmit, 'loanIdsMetadata' | 'loanIdValues'> & {
    [TermsKeys.LOAN_IDS_METADATA]: TCustomLoanIdsMetadata;
    [TermsKeys.LOAN_ID_VALUES]: TCustomLoanIdsValues;
};

export type TFundingTotalsDataItem = {
    balance: number;
    name: string;
    principal: number;
};

export type TFundingTotalsData = {
    [key: string]: TFundingTotalsDataItem;
};

export type secondarySyndicationValues = {
    index: string;
    floor?: number;
    forwardSofr: number;
    coupon: number;
    coupon365: number;
    spread: number;
    exitFee: number;
    ioAmortizing: number[];
    ioPeriod: number;
    indexValue: number;
    termSoftAdjustment: number;
    amortization: number;
    amortizationPeriod: number;
    fixedMonthlyPayment: number;
    fixedFloating: number;
    originationFee: number;
    extensionFee: number;
    prepaymentProtection: never[];
    indexDate: number;
};

export type syndicationTableRawDataItem = {
    syndication: string;
    balance: number;
    principal: number;
    _index: number;
} & secondarySyndicationValues;

export type syndicationTableRawData = {
    [key: string]: syndicationTableRawDataItem;
};

export type TLoanFunding = {
    entries?: TFundingTotalsData;
    blocks?: syndicationTableRawData;
};

export type TLoanDetails = {
    balance: number;
    loanAmount: number;
    unfundedBalance: number;
};

export type TLoanData = {
    terms: TLoanTerms;
    funding: TLoanFunding;
    keyDates: TKeyDate;
    reserves: TLoanDataReserves;
    loanDetails: TLoanDetails;
};
