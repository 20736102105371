import { createSlice, createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { put, take, takeLatest, select } from 'redux-saga/effects';

import { recalculateModelOnCopy } from '@utils/financials';

import { selectLoansState } from '@scc/store/ducks/loans/loans';

import { underwritingApi, loansApi } from '@api';
import { FINANCIAL_MODEL, SECONDARY_MODAL_HEADER } from '@constants/modals';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { showBottomPageDrawer } from '@store/ducks/ui/drawer';

const entity = '[underwriting]';

const initialState = {
    loaded: false,
    loading: false,
    toggleLoaded: false,
    toggleLoading: false,
    toggleError: null,
    data: [],
    model: null,
};

const underwritingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
            state.loaded = false;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        edit(state, action) {
            const { withReload = false } = action.payload;
            if (withReload) {
                state.loading = true;
                state.loaded = false;
                state.data = [];
            }
        },
        delete(state) {
            state.loading = true;
        },
        [`delete ${API_ERROR}`](state) {
            state.loading = false;
        },
        add(state) {
            state.loading = true;
        },
        [`add ${API_ERROR}`](state) {
            state.loading = false;
        },
        copy() {},
        clone() {},
        download() {},
        exportAsr() {},
        startEdit(state, action) {
            state.loaded = true;
            state.loading = false;
            state.model = action.payload?.modelData?.id || null;
        },
        toggleModel(state) {
            state.toggleError = null;
            state.toggleLoaded = false;
            state.toggleLoading = true;
        },
        setToggleError(state, action) {
            state.toggleError = action.payload;
            state.toggleLoaded = false;
            state.toggleLoading = false;
        },
    },
});

export const {
    load: loadUnderwriting,
    setData: setUnderwriting,
    delete: deleteUnderwriting,
    add: addUnderwriting,
    copy: copyUnderwriting,
    clone: cloneUnderwriting,
    startEdit: startEditUnderwriting,
    edit: editUnderwriting,
    toggleModel: toggleModelUnderwriting,
    setToggleError: setToggleErrorUnderwriting,
    download: downloadTableUnderwriting,
    exportAsr: exportAsrUnderwriting,
} = underwritingSlice.actions;

export default underwritingSlice.reducer;

export const selectUnderwritingState = (store) => store.loans.underwriting;

export const selectUnderwritingModelById = (id) =>
    createSelector(selectUnderwritingState, (state) => ({
        model: state.data.filter((item) => item.id === id)[0] || {},
        loaded: state.loaded,
    }));

function* loadUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.getModels, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(setUnderwriting(content));
}
function* deleteUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.deleteModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    const {
        loan: { id },
    } = yield select(selectLoansState);
    yield put(loadUnderwriting({ loanId: id }));
}

function* copyUnderwritingSaga({ type, payload }) {
    const { id, loanDetails, rates } = payload;
    const { data } = yield select(selectUnderwritingState);
    const { modelDetails } = data.find((item) => item.id === id);

    if (isEmpty(modelDetails)) {
        yield put(apiRequest(payload, underwritingApi.cloneModel, type));
        yield take(`${type} ${API_SUCCESS}`);
        yield put(loadUnderwriting(payload));

        return;
    }

    const resModelDetails = recalculateModelOnCopy(modelDetails, loanDetails, rates);
    yield put(apiRequest({ id, ...resModelDetails }, underwritingApi.copyModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadUnderwriting(payload));
}

function* cloneUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.cloneModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadUnderwriting(payload));
}

function* addUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.createModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    const {
        loan: { id },
    } = yield select(selectLoansState);
    yield put(loadUnderwriting({ loanId: id }));
}

function* startEditUnderwritingSaga({ payload }) {
    yield put(
        showBottomPageDrawer({
            content: FINANCIAL_MODEL,
            header: SECONDARY_MODAL_HEADER,
            data: { ...payload, hide: 'summary' },
        })
    );
}

function* editUnderwritingSaga({ type, payload: { ...params } = {} }) {
    yield put(apiRequest(params, underwritingApi.updateModel, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadUnderwriting({ loanId: params.loanDetailsId }));
}

function* toggleModelUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.toggleDefaultModel, type));

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        yield put(loadUnderwriting({ loanId: payload.loanDetailsId }));
    }
    if (action.type.endsWith(API_ERROR)) {
        yield put(setToggleErrorUnderwriting(action.payload));
    }
}

function* downloadTableUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.downloadExcelFile, type));
}

function* exportAsrUnderwritingSaga({ type, payload }) {
    yield put(apiRequest(payload, underwritingApi.exportAsr, type));
}

export function* watchUnderwriting() {
    yield takeLatest(loadUnderwriting, loadUnderwritingSaga);
    yield takeLatest(deleteUnderwriting, deleteUnderwritingSaga);
    yield takeLatest(addUnderwriting, addUnderwritingSaga);
    yield takeLatest(copyUnderwriting, copyUnderwritingSaga);
    yield takeLatest(cloneUnderwriting, cloneUnderwritingSaga);
    yield takeLatest(downloadTableUnderwriting, downloadTableUnderwritingSaga);
    yield takeLatest(exportAsrUnderwriting, exportAsrUnderwritingSaga);
    yield takeLatest(startEditUnderwriting, startEditUnderwritingSaga);
    yield takeLatest(editUnderwriting, editUnderwritingSaga);
    yield takeLatest(toggleModelUnderwriting, toggleModelUnderwritingSaga);
}
