import { createSlice } from '@reduxjs/toolkit';
import { put, all, call, delay, take, takeLatest } from 'redux-saga/effects';

import { sequentialRequest } from '@utils/request';
import { publicURLParse } from '@utils/url';

import { loadPortfolioDocuments } from '@scc/store/ducks/documents';
import { addMemory } from '@scc/store/ducks/memory';
import { addPropertyPortfolio } from '@scc/store/ducks/portfolios';

import { documentsApi } from '@api';
import { PORTFOLIO_SEARCH_PROPERTY, UPLOAD_PORTFOLIO_DOCUMENTS_STEPS } from '@constants/modals';
import { uploadDocumentsForm } from '@shared/templates/forms/UploadDocuments';
import { API_SUCCESS } from '@store/ducks/api';
import { showDialog } from '@store/ducks/ui/dialog';
import { showPrimaryAsideDrawer, hideDrawer } from '@store/ducks/ui/drawer';
import { showAlert } from '@store/ducks/ui/mainAlert';

const { MAIN, LOADING, PREPARE, SUCCESS, ERROR } = uploadDocumentsForm.TEMPLATES;

const entity = '[flowPortfolio]';

const initialState = {
    data: {},
    loading: false,
    loaded: false,
    isDocumentLoading: false,
    isDocumentLoaded: false,
    template: '',
};

const portfolioFlowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        upload(state) {
            state.template = MAIN;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
        add() {},
        uploadDocuments(state) {
            state.isDocumentLoading = true;
            state.isDocumentLoaded = false;
        },
        uploadedDocuments(state) {
            state.isDocumentLoading = false;
            state.isDocumentLoaded = true;
        },
        dropUploadDocumentsFlags(state) {
            state.isDocumentLoading = false;
            state.isDocumentLoaded = false;
        },
        addPropertyToPortfolio() {},
    },
});

export const {
    addPropertyToPortfolio: addPropertyToPortfolioFlow,
    upload: uploadPortfolioDocuments,
    setTemplate: setTemplatePortfolioDocuments,
    add: addPortfolioDocumentsFlow,
    uploadDocuments: uploadPortfolioDocumentsFlow,
    uploadedDocuments: uploadedPortfolioDocumentsFlow,
    dropUploadDocumentsFlags: dropUploadDocumentsFlagsFlow,
    generatePdf: generatePdfFlow,
    generatedPdf: generatedPdfFlow,
} = portfolioFlowSlice.actions;

export default portfolioFlowSlice.reducer;

export const selectPortfolioFlowState = (store) => store.flow.portfolio;

function* addPropertyToPortfolioFlowSaga({ payload }) {
    yield put(showDialog({ content: PORTFOLIO_SEARCH_PROPERTY, ...payload }));
    const action = yield take(`${addPropertyPortfolio}`);

    yield take(`${addPropertyPortfolio} ${API_SUCCESS}`);

    yield put(
        addMemory({
            data: {
                message: 'You successfully added this property to your portfolio',
                link: {
                    path: '/portfolio',
                    label: 'Back to Portfolio',
                },
            },
            entity: showAlert.type,
        })
    );

    yield delay(1000);
    window.location.href = publicURLParse(action.payload.publicurl);
}

function* uploadPortfolioDocumentsSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: UPLOAD_PORTFOLIO_DOCUMENTS_STEPS,
            data: payload,
        })
    );
}

function* addPortfolioDocumentsFlowSaga({ payload }) {
    const { selectDocs, parcelId, countyId } = payload;
    yield put(setTemplatePortfolioDocuments(LOADING));
    yield all(
        selectDocs.map((documentId) => call(documentsApi.addToPortfolioDocument, { documentId, parcelId, countyId }))
    );
    yield put(setTemplatePortfolioDocuments(SUCCESS));
    yield delay(3000);
    yield put(hideDrawer());
}

function* uploadPortfolioDocumentsFlowSaga({ payload }) {
    const { prepareDocs, parcelId, countyId, editMode } = payload;
    yield put(setTemplatePortfolioDocuments(LOADING));

    const method = editMode ? documentsApi.updateDocument : documentsApi.uploadDocumentsToProperty;

    const responses = yield call(
        sequentialRequest,
        Object.values(prepareDocs),
        ({ id, name, start, end, type, date, file }) =>
            method({
                id,
                parcelId,
                countyId,
                name,
                type,
                date,
                pages: `${start}-${end}`,
                file,
            })
    );

    if (responses.some((res) => res.error)) {
        yield put(setTemplatePortfolioDocuments(ERROR));
        yield delay(2300);
        yield put(setTemplatePortfolioDocuments(PREPARE));
    } else {
        yield put(loadPortfolioDocuments({ parcel: { parcelId, countyId } }));
        yield put(setTemplatePortfolioDocuments(SUCCESS));
        yield put(uploadedPortfolioDocumentsFlow());
        yield put(hideDrawer());
    }
}

export function* watchPortfolioFLow() {
    yield takeLatest(addPropertyToPortfolioFlow, addPropertyToPortfolioFlowSaga);
    yield takeLatest(uploadPortfolioDocuments, uploadPortfolioDocumentsSaga);
    yield takeLatest(addPortfolioDocumentsFlow, addPortfolioDocumentsFlowSaga);
    yield takeLatest(uploadPortfolioDocumentsFlow, uploadPortfolioDocumentsFlowSaga);
}
