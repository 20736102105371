import * as yup from 'yup';
import { validation } from '@shared/utils/forms';

const schema = yup
    .object({
        password: validation.newPassword,
        confirmPassword: validation.confirmPassword('password'),
    })
    .required();

export default schema;
