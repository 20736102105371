import { baseApi as api } from '../configs/todo';
export const addTagTypes = ['TODO List'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAllTodosForCurrentUser: build.query<
                GetAllTodosForCurrentUserApiResponse,
                GetAllTodosForCurrentUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v2/todo`,
                    params: {
                        search: queryArg.search,
                        taskStatus: queryArg.taskStatus,
                        taskType: queryArg.taskType,
                        page: queryArg.page,
                        size: queryArg.size,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['TODO List'],
            }),
            getAllByTeam: build.query<GetAllByTeamApiResponse, GetAllByTeamApiArg>({
                query: (queryArg) => ({
                    url: `/api/v2/todo/${queryArg.team}/${queryArg.loanId}`,
                    params: { page: queryArg.page, size: queryArg.size, sort: queryArg.sort },
                }),
                providesTags: ['TODO List'],
            }),
            updateTaskStatus: build.mutation<UpdateTaskStatusApiResponse, UpdateTaskStatusApiArg>({
                query: (queryArg) => ({
                    url: `/api/v2/todo/update-status`,
                    method: 'POST',
                    body: queryArg.updateTaskStatus,
                }),
                invalidatesTags: ['TODO List'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as generatedApi };
export type GetAllTodosForCurrentUserApiResponse = /** status 200 OK */ GenericJsonResponseListTodoTaskDto;
export type GetAllTodosForCurrentUserApiArg = {
    search?: string;
    taskStatus?: 'NEW' | 'IN_PROGRESS' | 'IN_PROGRESS_25' | 'IN_PROGRESS_50' | 'IN_PROGRESS_75' | 'COMPLETED';
    taskType?: 'Upload document' | 'Review document' | 'Sign document' | 'Other';
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type GetAllByTeamApiResponse = /** status 200 OK */ GenericJsonResponseListTodoTaskDto;
export type GetAllByTeamApiArg = {
    team: string;
    loanId: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type UpdateTaskStatusApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type UpdateTaskStatusApiArg = {
    updateTaskStatus: UpdateTaskStatus;
};
export type TimeLineEvents = {
    repeater?: Repeater;
    reminder?: ('MONTH_BEFORE' | 'WEEK_BEFORE' | 'TWO_DAYS_BEFORE' | 'DAY_BEFORE')[];
};
export type CompanyOwnerMemberDto = {
    name?: string;
    companyType?: CompanyType;
    logoPath?: string;
    ownership?: number;
};
export type MemberResponseDto = {
    id?: string;
    userId?: number;
    companyId?: string;
    type?: string;
    notes?: string;
    ssn?: string;
    outstanding?: number;
    liquidity?: number;
    netWorth?: number;
    asOf?: string;
    ownedCompanies?: CompanyOwnerMemberDto[];
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type UserCompanyDto = {
    userId?: number;
    companyId?: string;
    position?: string;
    companyName?: string;
    updatedAt?: string;
};
export type TeamMemberDto = {
    id?: number;
    loanRole?: LoanRole;
    position?: string;
    lead?: boolean;
};
export type UserMemberResponseDto = {
    member?: MemberResponseDto;
    userInfo?: UserInfoDto;
    userCompanyDTO?: UserCompanyDto;
    teamMemberDTO?: TeamMemberDto;
};
export type CompanyDto = {
    id?: string;
    name?: string;
    companyType?: CompanyType;
    companyUrl?: string;
    email?: string;
    taxId?: string;
    contactAddress?: string;
    phone?: string;
    logoPath?: string;
    createdAt?: string;
    createdBy?: number;
};
export type CompanyMemberResponseDto = {
    member?: MemberResponseDto;
    company?: CompanyDto;
};
export type MemberDto = {
    userMember?: UserMemberResponseDto;
    companyMember?: CompanyMemberResponseDto;
    isNew?: boolean;
    createdAt?: string;
    id?: string;
    user?: boolean;
};
export type UnderwritingMetricsDto = {
    lesser?: number;
    contract?: number;
    market?: number;
    rollover?: number;
    downMarket?: number;
    customScenarios?: {
        [key: string]: number;
    };
};
export type LoanPropertyDto = {
    id?: number;
    propertyId?: string;
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
    userId?: number;
    groupId?: number;
    maturityDate?: string;
    address?: string;
    state?: string;
    propertyType?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    isDetailAnalyze?: boolean;
    size?: number;
    units?: number;
    acres?: number;
    underwritingRevenue?: UnderwritingMetricsDto;
    underwritingNoi?: UnderwritingMetricsDto;
    underwritingValuation?: UnderwritingMetricsDto;
    principal?: number;
    balance?: number;
};
export type LoanDetailsDto = {
    id?: number;
    loanId?: string;
    loanName?: string;
    jsonValues?: string;
    status?: Status3;
    balance?: number;
    unfundedBalance?: number;
    principal?: number;
    loanProgram?: LoanProgram;
    loanType?: LoanType;
    propertyScore?: number;
    termEnd?: string;
    funded?: string;
    permission?: Permission;
    loanData?: object;
    loanPropertyList?: LoanPropertyDto[];
    members?: {
        [key: string]: MemberDto;
    };
};
export type PropertyBuildingDto = {
    id?: number;
    propertyId?: number;
    name?: string;
    documents?: DocumentDto[];
};
export type DocumentDto = {
    id?: number;
    isNew?: boolean;
    userId?: number;
    groupId?: number;
    type?: Type2;
    name?: string;
    fileName?: string;
    pages?: string;
    sheetNumber?: number;
    generated?: boolean;
    uploadedTimestamp?: string;
    uploadedDate?: string;
    documentTimestamp?: string;
    documentDate?: string;
    documentStatus?: DocumentStatus;
    documentStatusByCurrentUser?: DocumentStatusByCurrentUser;
    status?: Status2;
    statusLabel?: string;
    documentUsers?: UserInfoDto[];
    documentLoans?: LoanDetailsDto[];
    documentMembers?: MemberDto[];
    documentProperties?: LoanPropertyDto[];
    documentPropertyBuildings?: PropertyBuildingDto[];
    initialPdfParseType?: InitialPdfParseType;
    rrId?: number;
    osId?: number;
    template?: string;
    statusNote?: string;
    updatedUser?: UserInfoDto;
    isProcessMySelf?: boolean;
};
export type TodoTaskCommentDto = {
    id?: number;
    comment?: string;
    userId?: number;
    firstName?: string;
    lastName?: string;
    createdAt?: string;
};
export type TodoReview = {
    status?: Status4;
    note?: string;
};
export type CompletedByDto = {
    userName?: string;
    email?: string;
    completedAt?: string;
};
export type TodoTaskDto = {
    id?: number;
    loanId?: number;
    loanName?: string;
    loanIdString?: string;
    assignerEmail?: string;
    assigneeEmail?: string;
    assigneeFirstName?: string;
    assigneeLastName?: string;
    title?: string;
    dueDates?: string[];
    dueBy?: string;
    description?: string;
    type?: Type;
    status?: Status;
    propertyId?: number;
    propertyName?: string;
    propertyType?: string;
    documentType?: string;
    documentPeriod?: string;
    timelineEvents?: TimeLineEvents;
    contactId?: string;
    contact?: MemberDto;
    document?: DocumentDto;
    isNew?: boolean;
    assignerAvatarLink?: string;
    teamType?: TeamType;
    comments?: TodoTaskCommentDto[];
    labels?: string[];
    reviews?: TodoReview[];
    connectedTasks?: TodoTaskDto[];
    blockedTasks?: TodoTaskDto[];
    docSyncTask?: TodoTaskDto;
    completedBy?: CompletedByDto;
    properties?: LoanPropertyDto[];
    duration?: number;
    completed?: boolean;
};
export type GenericJsonResponseListTodoTaskDto = {
    status?: string;
    data?: TodoTaskDto[];
    message?: string;
    code?: string;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type UpdateTaskStatus = {
    id: number;
    status: Status5;
};
export enum Type {
    UploadDocument = 'Upload document',
    ReviewDocument = 'Review document',
    SignDocument = 'Sign document',
    Other = 'Other',
}
export enum Status {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    InProgress25 = 'IN_PROGRESS_25',
    InProgress50 = 'IN_PROGRESS_50',
    InProgress75 = 'IN_PROGRESS_75',
    Completed = 'COMPLETED',
}
export enum Repeater {
    Month = 'MONTH',
    Quarter = 'QUARTER',
    Year = 'YEAR',
}
export enum CompanyType {
    Llc = 'LLC',
    Corporation = 'CORPORATION',
}
export enum LoanRole {
    Borrower = 'BORROWER',
    Lender = 'LENDER',
    Guarantor = 'GUARANTOR',
    Broker = 'BROKER',
    Other = 'OTHER',
}
export enum Type2 {
    RentRoll = 'RENT_ROLL',
    OperatingStatement = 'OPERATING_STATEMENT',
    Budget = 'BUDGET',
    TaxReturn = 'TAX_RETURN',
    BankStatement = 'BANK_STATEMENT',
    DevelopmentBudget = 'DEVELOPMENT_BUDGET',
    Invoice = 'INVOICE',
    PropertyPurchaseAgreement = 'PROPERTY_PURCHASE_AGREEMENT',
    BalanceSheet = 'BALANCE_SHEET',
    GuarantorFinancialStatement = 'GUARANTOR_FINANCIAL_STATEMENT',
    MortgageStatement = 'MORTGAGE_STATEMENT',
    InsurancePolicy = 'INSURANCE_POLICY',
    InspectionReport = 'INSPECTION_REPORT',
    AppraisalReport = 'APPRAISAL_REPORT',
    InfoMemo = 'INFO_MEMO',
    LoanInfoMemo = 'LOAN_INFO_MEMO',
    LeaseAgreement = 'LEASE_AGREEMENT',
    LoanAgreement = 'LOAN_AGREEMENT',
    InsuranceForm = 'INSURANCE_FORM',
    InsuranceAgreement = 'INSURANCE_AGREEMENT',
    Other = 'OTHER',
}
export enum DocumentStatus {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum DocumentStatusByCurrentUser {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum Status2 {
    Error = 'ERROR',
    InProcessAuto = 'IN_PROCESS_AUTO',
    InProcessManual = 'IN_PROCESS_MANUAL',
    Processed = 'PROCESSED',
    Confirmed = 'CONFIRMED',
}
export enum Status3 {
    SccUnderwriting = 'SCC_UNDERWRITING',
    Evaluation = 'EVALUATION',
    InitialQuoteSent = 'INITIAL_QUOTE_SENT',
    Underwriting = 'UNDERWRITING',
    AppraisalNTitleWork = 'APPRAISAL_N_TITLE_WORK',
    InClosing = 'IN_CLOSING',
    Funded = 'FUNDED',
    PaidOff = 'PAID_OFF',
    Passed = 'PASSED',
    TransferredOut = 'TRANSFERRED_OUT',
    DueDiligence = 'DUE_DILIGENCE',
    ApplicationIssued = 'APPLICATION_ISSUED',
    Loi = 'LOI',
    Dead = 'DEAD',
    UnderContract = 'UNDER_CONTRACT',
}
export enum LoanProgram {
    Acquisition = 'ACQUISITION',
    Construction = 'CONSTRUCTION',
    Refinance = 'REFINANCE',
    NewDevelopment = 'NEW_DEVELOPMENT',
    Redevelopment = 'REDEVELOPMENT',
}
export enum LoanType {
    Loan = 'LOAN',
    LoanRequest = 'LOAN_REQUEST',
    Investment = 'INVESTMENT',
}
export enum Permission {
    Admin = 'ADMIN',
    Creator = 'CREATOR',
    Owner = 'OWNER',
    Collaborator = 'COLLABORATOR',
    Viewer = 'VIEWER',
    None = 'NONE',
}
export enum InitialPdfParseType {
    Pdfbox = 'PDFBOX',
    Ocr = 'OCR',
    Ocr2 = 'OCR2',
}
export enum TeamType {
    Borrower = 'BORROWER',
    Lender = 'LENDER',
}
export enum Status4 {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum Status5 {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    InProgress25 = 'IN_PROGRESS_25',
    InProgress50 = 'IN_PROGRESS_50',
    InProgress75 = 'IN_PROGRESS_75',
    Completed = 'COMPLETED',
}
export const { useGetAllTodosForCurrentUserQuery, useGetAllByTeamQuery, useUpdateTaskStatusMutation } = injectedRtkApi;
