import { put, take, actionChannel } from 'redux-saga/effects';

export const SEND_EVENT = 'SEND_EVENT';
export const GET_EVENT = 'GET_EVENT';

export const apiEvent = (data, entity) => ({
    type: `${SEND_EVENT}`,
    payload: {
        data,
        meta: { entity },
    },
});

export const apiSuccess = ({ data, meta = {} } = {}) => ({
    type: `${meta?.entity} ${GET_EVENT}`,
    payload: {
        data,
        meta,
    },
});

export function* watchEventRequest() {
    const requestChan = yield actionChannel(SEND_EVENT);
    while (true) {
        const { payload } = yield take(requestChan);
        yield put(apiSuccess(payload));
    }
}
