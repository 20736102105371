import { useSelector } from 'react-redux';

import Icon from '@ui/Icon';

import SentSuccessLayout from '@scc/components/PartialSuccessLayout';
import CheckCircleOutlineIcon from '@scc/assets/icons/success.svg';
import { selectAuthMngState } from '@scc/store/ducks/authManager';
import styles from './styles.module.scss';

const ForgotPasswordFormSuccess = function () {
    const { recoveryEmail } = useSelector(selectAuthMngState);

    return (
        <SentSuccessLayout
            className={styles.success}
            icon={<Icon component={CheckCircleOutlineIcon} fontSize='128px' />}
            title={<span>Password reset sent!</span>}
            description={
                <>
                    <span>We have sent an email with a password reset link to </span>
                    <span className={styles.email}>{recoveryEmail}</span>
                </>
            }
        />
    );
};
export default ForgotPasswordFormSuccess;
