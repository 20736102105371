import { useState } from 'react';

import useDebouncedEffect from '@shared/hooks/useDebouncedEffect';

export function getDimensionObject(node) {
    const rect = node?.getBoundingClientRect?.() || {};
    return {
        top: Number.isFinite(Number(rect.top)) && Math.round(rect.top),
        left: Number.isFinite(Number(rect.left)) && Math.round(rect.left),
        height: Number.isFinite(Number(rect.height)) && Math.round(rect.height),
        width: Number.isFinite(Number(rect.width)) && Math.round(rect.width),
    };
}

const useDimensions = (ref, initial = false, trigger) => {
    const [dimensions, setDimensions] = useState();

    useDebouncedEffect(
        () => {
            const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(ref)));
            if (initial || typeof trigger !== 'undefined') {
                measure();
            }
            if (ref) {
                measure();
                window.addEventListener('resize', measure);
            }
        },
        [ref, trigger, initial],
        300,
        () => {
            const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(ref)));
            window.removeEventListener('resize', measure);
        }
    );

    return dimensions || {};
};

export default useDimensions;
