import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@ui/RadioGroup';
import Radio from '@ui/Radio';

const RadioBaseForm = function ({ radioProps: { name, values, ...radioProps} = {}, ...props }) {
    return (
        <RadioGroup
            {...radioProps}
            {...props}
            name={name}
        >
            {values?.map(({ value, label, control: Control = Radio }) => (
                <FormControlLabel key={value} value={value} control={<Control />} label={label} />
            ))}
        </RadioGroup>
    );
};

export default RadioBaseForm;
