import request from '@utils/request';

import { PushPropertyInfoApiArg, PushPropertyInfoApiResponse } from '@scc/services/generated/argus';

const BASE_URL = '/api/v2/argus/';

export async function pushPropertyInfo(queryArg: PushPropertyInfoApiArg): Promise<PushPropertyInfoApiResponse> {
    return request(`${BASE_URL}/property/push`, {
        method: 'POST',
        params: { propertyId: queryArg.propertyId },
    });
}
