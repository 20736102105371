import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery, createConfig } from '@scc/services/helpers';

const ENTITY = 'dealCloud';

export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});

export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: [
        'getDealsSortedByYearAndMonth',
        'getDealListByActiveStatus',
        'getDealsByQuotesIssuedSortedByYearAndMonth',
        'getDealsByTermSheetsIssuedSortedByYearAndMonth',
        'getDealsByApplicationIssuedSortedByYearAndMonth',
        'getDealsByClosedStatusSortedByYearAndMonth',
        'getDealListByUnderReview',
        'getDealListByActiveStatusLastTwelveMonths',
        'getDealListByUnderReviewLastTwelveMonths',
        'getDealListByPassedDead',
        'getDealListByPassedDeadLastTwelveMonths',
        'getSortedDealsByYearMonthExcludingEmptyProperty',
        'getDealListByLastTwelveMonths',
    ],
});
