/* eslint-disable max-len */

import { reloadable } from '@utils/components';
import { createRoutes } from '@utils/router';

import { withPermission } from '@scc/hocs/withPermission';
import AboutUsPage from '@scc/pages/AboutUs/AboutUs';
import AuthPage from '@scc/pages/Auth';
import EnterprisePage from '@scc/pages/Enterprise';
import HomePage from '@scc/pages/Home';
import LoginAsUser from '@scc/pages/LoginAsUser';
import PricingPage from '@scc/pages/Pricing';
import PrivacyTermsPages from '@scc/pages/PrivacyTerms';
import PropertyPage from '@scc/pages/Property';
import ServicesPage from '@scc/pages/Services';
import ServicePage from '@scc/pages/Services/Service';
import SiteMapPage from '@scc/pages/SiteMap';
import SsoTimeoutPage from '@scc/pages/SsoTimeout';
import ContentDialogTemplate from '@scc/templates/ContentDialog';
import ContentDialogTemplate2 from '@scc/templates/ContentDialog2';
import DialogTemplate from '@scc/templates/Dialog';
import PricingTemplate from '@scc/templates/Pricing';
import PropertyTemplate from '@scc/templates/Property';
import ServicesTemplate from '@scc/templates/Services';

import * as PERMISSIONS from '@constants/permissions';

import pages from './links';

const DashboardPage = reloadable(() => import('@scc/pages/Dashboard'));
const DashboardBorrowerPage = reloadable(() => import('@scc/pages/DashboardBorrower'));

const Noop = reloadable(() => import('@components/Noop'));
const MyPropertiesPage = reloadable(() => import('@scc/pages/MyProperties'));
const PropertiesPage = reloadable(() => import('@scc/pages/Properties'));
const PropertiesPropertiesPage = reloadable(() => import('@scc/pages/Properties/components/Properties'));
const PropertiesPortfolioPage = reloadable(() => import('@scc/pages/Properties/components/Portfolio'));
const PropertiesPortfoliosPage = reloadable(() => import('@scc/pages/Properties/components/Portfolios2'));
const PropertiesTenantsPage = reloadable(() => import('@scc/pages/Properties/components/Tenants'));
const BookmarksPage = reloadable(() => import('@scc/pages/Bookmarks'));
const HistoryPage = reloadable(() => import('@scc/pages/History'));
const History2Page = reloadable(() => import('@scc/pages/History2'));
const HistoryPropertiesPage = reloadable(() => import('@scc/pages/History2/components/Properties'));
const HistoryLoansPage = reloadable(() => import('@scc/pages/History2/components/Loans'));

const DocumentsPage = reloadable(() => import('@scc/pages/Documents'));

const ProfileTemplate = reloadable(() => import('@scc/templates/Profile'));
const AccountPage = reloadable(() => import('@scc/pages/Profile/Account'));
const PasswordPage = reloadable(() => import('@scc/pages/Profile/Password'));
const PaymentMethodPage = reloadable(() => import('@scc/pages/Profile/PaymentMethod'));
const FeatureInProgressPage = reloadable(() => import('@scc/pages/FeatureInProgress'));

const FinancingsPage = reloadable(() => import('@scc/pages/Financings'));
const FinancingPage = reloadable(() => import('@scc/pages/Financing'));
const FinancingDocumentsPage = reloadable(() => import('@scc/pages/Financing/components/Documents'));
const FinancingDetailsPage = reloadable(() => import('@scc/pages/Financing/components/Details'));
const FinancingLendersPage = reloadable(() => import('@scc/pages/Loan/components/Lenders'));
const FinancingBorrowerPage = reloadable(() => import('@scc/pages/Financing/components/Borrower'));
const FinancingStatementsPage = reloadable(() => import('@scc/pages/Financing/components/Statements'));
const FinancingGuarantorPage = reloadable(() => import('@scc/pages/Financing/components/Guarantor'));
const FinancingBudgetPage = reloadable(() => import('@scc/pages/Financing/components/Budget'));
const FinancingCollateralPage = reloadable(() => import('@scc/pages/Financing/components/Collateral'));
const FinancingDrawsPage = reloadable(() => import('@scc/pages/Financing/components/Draws'));
const FinancingTeamPage = reloadable(() => import('@scc/pages/Financing/components/Team'));
const ThirdPartyReportsPage = reloadable(() => import('@scc/pages/Financing/components/ThirdPartyReports'));

const Todo = reloadable(() => import('@scc/pages/Financing/components/Todo'));
const ErrorPage = reloadable(() => import('@scc/pages/404'));
const ListingsPage = reloadable(() => import('@scc/pages/Listings'));
const ReferPage = reloadable(() => import('@scc/pages/ReferPage'));

const BaseDialogTemplate = reloadable(() => import('@components/BaseDialog'));
const RequestLoanPage = reloadable(() => import('@scc/modules/RequestLoan'));

// pages from Lenders router
const MembersPage = reloadable(() => import('@scc/pages/Members'));
const MemberPage = reloadable(() => import('@scc/pages/Member'));
const MemberDetailsPage = reloadable(() => import('@scc/pages/Member/components/Details'));
const MemberLoansPage = reloadable(() => import('@scc/pages/Member/components/Loans'));
const MemberPropertiesPage = reloadable(() => import('@scc/pages/Member/components/ProperiesOwned'));
const MemberTeamPage = reloadable(() => import('@scc/pages/Member/components/Team'));
const MemberOwnersPage = reloadable(() => import('@scc/pages/Member/components/Owners'));
const DocumentsMemberPage = reloadable(() => import('@scc/pages/Member/components/Documents'));
const MemberNotesPage = reloadable(() => import('@scc/pages/Member/components/Notes'));
const MemberFinancialsPage = reloadable(() => import('@scc/pages/Member/components/Financials'));
const BenchmarkingPage = reloadable(() => import('@scc/pages/Benchmarking'));
const BenchmarkPage = reloadable(() => import('@scc/pages/Benchmarking/components/Benchmarking'));

const LoansPage = reloadable(() => import('@scc/pages/Loans'));
const LoansPortfolioPage = reloadable(() => import('@scc/pages/Loans/components/Portfolio'));
const LoanPage = reloadable(() => import('@scc/pages/Loan'));
const LoanDetailsPage = reloadable(() => import('@scc/pages/Loan/components/Details'));
const LoanInsightPage = reloadable(() => import('@scc/pages/Loan/components/Insight'));
const LoanLendersPage = reloadable(() => import('@scc/pages/Loan/components/Lenders'));
const LoanBudgetPage = reloadable(() => import('@scc/pages/Financing/components/Budget'));
const LoanDocumentsPage = reloadable(() => import('@scc/pages/Loan/components/Documents'));
const LoanStatementsPage = reloadable(() => import('@scc/pages/Loan/components/Statements'));
const LoanTodo = reloadable(() => import('@scc/pages/Loan/components/Todo'));

const LoanUnderwriting = reloadable(() => import('@scc/pages/Loan/components/Underwriting'));

const LoanProforma = reloadable(() => import('@scc/pages/Loan/components/Proforma'));

const LoanUnderwritingFinancialScenario = reloadable(() =>
    import('@scc/pages/Loan/components/Underwriting/components/FinancingScenario')
);

const LoanUnderwritingTermsSheets = reloadable(() =>
    import('@scc/pages/Loan/components/Underwriting/components/TermSheets')
);

const LoanDrawsPage = reloadable(() => import('@scc/pages/Financing/components/Draws'));
const LoanCovenantsPage = reloadable(() => import('@scc/pages/Loan/components/Covenants'));
const LoanTeamPage = reloadable(() => import('@scc/pages/Loan/components/Team'));
const LoanCollateralPage = reloadable(() => import('@scc/pages/Loan/components/Collateral'));
const LoanThirdPartyReportsPage = reloadable(() => import('@scc/pages/Loan/components/ThirdPartyReports'));
const LoanNotesPage = reloadable(() => import('@scc/pages/Loan/components/Notes'));

const LoanDetailsTerms = reloadable(() =>
    import('@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Terms')
);

const LoanDetailsKeyDates = reloadable(() =>
    import('@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/KeyDates')
);

const LoanDetailsReserves = reloadable(() =>
    import('@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Reserves')
);

const LoanDetailsFunding = reloadable(() =>
    import('@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Funding')
);

const LoanStatementsFunding = reloadable(() => import('@scc/pages/Loan/components/Statements/components/Funding'));

const LoanStatementsDebtService = reloadable(() =>
    import('@scc/pages/Loan/components/Statements/components/DebtService')
);

const LoanStatementsStatements = reloadable(() =>
    import('@scc/pages/Loan/components/Statements/components/Statements')
);

const PortfoliosPage = reloadable(() => import('@scc/pages/Portfolios'));
const PortfolioPage = reloadable(() => import('@scc/pages/Portfolio'));
const PortfolioFinancingPage = reloadable(() => import('@scc/pages/Portfolio/components/Financing'));
const PortfolioOverviewPage = reloadable(() => import('@scc/pages/Portfolio/components/Overview'));
const PortfolioAnalyticsPage = reloadable(() => import('@scc/pages/Portfolio/components/Analytics'));
const PortfolioInsightPage = reloadable(() => import('@scc/pages/Portfolio/components/Insight'));
const PortfolioRentRollPage = reloadable(() => import('@scc/pages/Portfolio/components/RentRoll'));
const PortfolioMarketPage = reloadable(() => import('@scc/pages/Portfolio/components/Market'));
const PortfolioFinancialsPage = reloadable(() => import('@scc/pages/Portfolio/components/Financials'));
const PortfolioDocumentsPage = reloadable(() => import('@scc/pages/Portfolio/components/Documents'));
const PortfolioUnderwritingPage = reloadable(() => import('@scc/pages/Portfolio/components/Underwriting'));
const PortfolioPropertyHistoryPage = reloadable(() => import('@scc/pages/Portfolio/components/PropertyHistoty'));
const PortfolioProformaPage = reloadable(() => import('@scc/pages/Portfolio/components/Proforma'));
const PortfolioProformaGridPage = reloadable(() => import('@scc/pages/Portfolio/components/ProformaGrid'));
const PortfolioNotesPage = reloadable(() => import('@scc/pages/Portfolio/components/Notes'));
const PortfolioAdminPage = reloadable(() => import('@scc/pages/Portfolio/components/Admin'));
const MapsPage = reloadable(() => import('@scc/pages/Maps'));
const TodoPage = reloadable(() => import('@scc/pages/Todos'));

const ChatAiPage = reloadable(() => import('@scc/pages/ChatAi'));
const ChatDocumentAiPage = reloadable(() => import('@scc/pages/ChatAi/components/DocumentAi'));
const ChatDataAiPage = reloadable(() => import('@scc/pages/ChatAi/components/DataAi'));

const Configuration = reloadable(() => import('@scc/pages/Configuration'));
const AllUsers = reloadable(() => import('@scc/pages/Configuration/components/AllUsers'));
const Teams = reloadable(() => import('@scc/pages/Configuration/components/Teams'));
const Team = reloadable(() => import('@scc/pages/Configuration/components/Team'));

const PortfolioExperimentalRentRollPage = reloadable(() =>
    import('@scc/pages/Portfolio/components/ExperimentalRentRoll')
);

const PortfolioExperimentalFinancialsPage = reloadable(() =>
    import('@scc/pages/Portfolio/components/ExperimentalFinancials')
);

const DevelopmentBudget = reloadable(() => import('@scc/pages/Portfolio/components/DevelopmentBudget'));

const PortfolioExperimentalProformaPage = reloadable(() =>
    import('@scc/pages/Portfolio/components/ExperimentalProforma')
);

const DrawPage = reloadable(() => import('@scc/pages/Draw'));
const DrawSummaryPage = reloadable(() => import('@scc/pages/Draw/components/Summary'));
const DrawNotesPage = reloadable(() => import('@scc/pages/Draw/components/Notes'));
const DrawDocumentsPage = reloadable(() => import('@scc/pages/Draw/components/Documents'));
const DrawFundingSourcePage = reloadable(() => import('@scc/pages/Draw/components/FundingSources'));

const PAGES = {
    home: {
        main: HomePage,
        status: 'public',
        alter: DashboardBorrowerPage,
    },
    dashboard: {
        status: 'private',
        main: DashboardPage,
    },
    enterprise: {
        status: 'public',
        main: EnterprisePage,
        template: ContentDialogTemplate2,
    },
    requestLoan: {
        main: RequestLoanPage,
        template: BaseDialogTemplate,
    },
    bookmarks: {
        status: 'private',
        main: withPermission(BookmarksPage, PERMISSIONS.WISHLIST.ENTRY),
    },
    myProperties: {
        status: 'private',
        main: MyPropertiesPage,
    },
    history: {
        status: 'private',
        main: HistoryPage,
    },
    history2: {
        status: 'private',
        main: History2Page,
        routes: {
            properties: {
                main: HistoryPropertiesPage,
            },
            loans: {
                main: HistoryLoansPage,
            },
        },
    },
    property: {
        main: PropertyPage,
        template: PropertyTemplate,
    },
    portfolios: {
        status: 'private',
        main: withPermission(PortfoliosPage, PERMISSIONS.PROPERTY_BORROWER.ENTRY),
    },
    portfolio: {
        status: 'private',
        main: PortfolioPage,
        routes: {
            overview: {
                main: PortfolioOverviewPage,
            },
            analytics: {
                main: withPermission(PortfolioAnalyticsPage, PERMISSIONS.PROPERTY.ANALYTICS),
            },
            insight: {
                main: withPermission(PortfolioInsightPage, PERMISSIONS.PROPERTY.INSIGHTS),
            },
            rentRoll: {
                main: withPermission(PortfolioRentRollPage, PERMISSIONS.PROPERTY.RENTROLL),
            },
            financials: {
                main: withPermission(PortfolioFinancialsPage, PERMISSIONS.PROPERTY.FINANCIALS),
            },
            proforma: {
                main: withPermission(PortfolioProformaPage, PERMISSIONS.PROPERTY.PRO_FORMA),
            },
            proformaGrid: {
                main: withPermission(PortfolioProformaGridPage, PERMISSIONS.PROPERTY.PRO_FORMA),
            },
            underwriting: {
                main: withPermission(PortfolioUnderwritingPage, PERMISSIONS.PROPERTY.UNDERWRITING),
            },
            market: {
                main: withPermission(PortfolioMarketPage, PERMISSIONS.PROPERTY.MARKET),
            },
            documents: {
                main: PortfolioDocumentsPage,
            },
            financing: {
                main: withPermission(PortfolioFinancingPage, PERMISSIONS.PROPERTY.FINANCING),
            },
            propertyHistory: {
                main: withPermission(PortfolioPropertyHistoryPage, PERMISSIONS.PROPERTY.HISTORY),
            },
            notes: {
                main: withPermission(PortfolioNotesPage, PERMISSIONS.PROPERTY.NOTES),
            },
            admin: {
                main: PortfolioAdminPage,
            },
            experimentalRentRoll: {
                main: PortfolioExperimentalRentRollPage,
            },
            experimentalFinancials: {
                main: withPermission(PortfolioExperimentalFinancialsPage, PERMISSIONS.PROPERTY.EXP_FINANCIALS),
            },
            developmentBudget: {
                main: DevelopmentBudget,
            },
            experimentalProforma: {
                main: withPermission(PortfolioExperimentalProformaPage, PERMISSIONS.PROPERTY.EXP_PRO_FORMA),
            },
        },
    },
    listings: {
        status: 'private',
        main: withPermission(ListingsPage, PERMISSIONS.LISTING.ENTRY),
    },
    financings: {
        status: 'private',
        main: withPermission(FinancingsPage, PERMISSIONS.FINANCING.ENTRY),
    },
    draw: {
        status: 'private',
        main: DrawPage,
        routes: {
            summary: {
                main: DrawSummaryPage,
            },
            documents: {
                main: DrawDocumentsPage,
            },
            fundingSource: {
                main: DrawFundingSourcePage,
            },
            notes: {
                main: DrawNotesPage,
            },
        },
    },
    financing: {
        status: 'private',
        main: FinancingPage,
        routes: {
            details: {
                main: FinancingDetailsPage,
                routes: {
                    terms: {
                        main: LoanDetailsTerms,
                    },
                    keyDates: {
                        main: LoanDetailsKeyDates,
                    },
                    reserves: {
                        main: LoanDetailsReserves,
                    },
                    funding: {
                        main: LoanDetailsFunding,
                    },
                },
            },
            lenders: {
                main: FinancingLendersPage,
            },
            borrower: {
                main: FinancingBorrowerPage,
            },
            guarantor: {
                main: FinancingGuarantorPage,
            },
            budget: {
                main: FinancingBudgetPage,
            },
            loanApplication: {
                main: FeatureInProgressPage,
            },
            documents: {
                main: FinancingDocumentsPage,
            },
            statements: {
                main: withPermission(FinancingStatementsPage, PERMISSIONS.FINANCING.STATEMENTS),
            },
            toDos: {
                main: withPermission(Todo, PERMISSIONS.FINANCING.TODO),
            },
            underwriting: {
                main: withPermission(LoanUnderwriting, PERMISSIONS.FINANCING.FINANCING_UNDERWRITING),
                routes: {
                    financialScenario: {
                        main: LoanUnderwritingFinancialScenario,
                    },
                    termsSheets: {
                        main: LoanUnderwritingTermsSheets,
                    },
                },
            },
            covenants: {
                main: withPermission(FeatureInProgressPage, PERMISSIONS.FINANCING.COVENANTS),
            },
            messages: {
                main: withPermission(Noop, PERMISSIONS.FINANCING.MESSAGES),
            },
            collateral: {
                main: withPermission(FinancingCollateralPage, PERMISSIONS.FINANCING.COLLATERAL),
            },
            draws: {
                main: FinancingDrawsPage,
            },
            team: {
                main: withPermission(FinancingTeamPage, PERMISSIONS.FINANCING.TEAM),
            },
            thirdPartyReports: {
                main: withPermission(ThirdPartyReportsPage, PERMISSIONS.FINANCING.THIRD_PARTY_REPORT),
            },
            notes: {
                main: withPermission(FeatureInProgressPage, PERMISSIONS.FINANCING.NOTES),
            },
        },
    },
    loans: {
        main: withPermission(LoansPage, PERMISSIONS.LOAN.ENTRY),
        status: 'private',
    },
    loansPortfolio: {
        main: LoansPortfolioPage,
        status: 'private',
    },
    loan: {
        main: LoanPage,
        status: 'private',
        routes: {
            details: {
                main: LoanDetailsPage,
                routes: {
                    terms: {
                        main: LoanDetailsTerms,
                    },
                    keyDates: {
                        main: LoanDetailsKeyDates,
                    },
                    reserves: {
                        main: LoanDetailsReserves,
                    },
                    funding: {
                        main: LoanDetailsFunding,
                    },
                },
            },
            insight: {
                main: withPermission(LoanInsightPage, PERMISSIONS.LOAN.INSIGHTS),
            },
            lenders: {
                main: LoanLendersPage,
            },
            budget: {
                main: LoanBudgetPage,
            },
            documents: {
                main: LoanDocumentsPage,
            },
            statements: {
                main: withPermission(LoanStatementsPage, PERMISSIONS.LOAN.STATEMENTS),
                routes: {
                    funding: {
                        main: LoanStatementsFunding,
                    },
                    debtService: {
                        main: LoanStatementsDebtService,
                    },
                    statements: {
                        main: LoanStatementsStatements,
                    },
                },
            },
            guarantors: {
                main: FinancingGuarantorPage,
            },
            borrower: {
                main: FinancingBorrowerPage,
            },
            todo: {
                main: withPermission(LoanTodo, PERMISSIONS.LOAN.TODO),
            },
            underwriting: {
                main: withPermission(LoanUnderwriting, PERMISSIONS.LOAN.UNDERWRITING),
                routes: {
                    financialScenario: {
                        main: LoanUnderwritingFinancialScenario,
                    },
                    termsSheets: {
                        main: LoanUnderwritingTermsSheets,
                    },
                },
            },
            proforma: {
                main: LoanProforma,
            },
            covenants: {
                main: withPermission(LoanCovenantsPage, PERMISSIONS.LOAN.COVENANTS),
            },
            messages: {
                main: withPermission(Noop, PERMISSIONS.LOAN.MESSAGES),
            },
            draws: {
                main: withPermission(LoanDrawsPage, PERMISSIONS.LOAN.DRAW),
            },
            team: {
                main: withPermission(LoanTeamPage, PERMISSIONS.LOAN.TEAM),
            },
            collateral: {
                main: LoanCollateralPage,
            },
            thirdPartyReports: {
                main: withPermission(LoanThirdPartyReportsPage, PERMISSIONS.LOAN.THIRD_PARTY_REPORT),
            },
            notes: {
                main: withPermission(LoanNotesPage, PERMISSIONS.LOAN.NOTES),
            },
        },
    },
    refer: {
        status: 'private',
        main: withPermission(ReferPage, PERMISSIONS.REFER.ENTRY),
    },
    propertySSR: {
        main: PropertyPage,
        template: PropertyTemplate,
    },
    properties: {
        main: withPermission(PropertiesPage, PERMISSIONS.PROPERTY.ENTRY),
        status: 'private',
        routes: {
            properties: {
                main: PropertiesPropertiesPage,
            },
            portfolios: {
                main: withPermission(PropertiesPortfoliosPage, PERMISSIONS.PORTFOLIO.PROPERTY_PORTFOLIOS),
                routes: {
                    combinedPortfolios: {
                        main: PropertiesPortfolioPage,
                    },
                    portfolio: {
                        main: PropertiesPortfolioPage,
                    },
                },
            },
            tenants: {
                main: withPermission(PropertiesTenantsPage, PERMISSIONS.PORTFOLIO.TENANTS),
            },
        },
    },
    pricing: {
        main: PricingPage,
        template: PricingTemplate,
    },
    services: {
        main: ServicesPage,
        template: ServicesTemplate,
        routes: {
            valuation: {
                noFade: true,
                main: ServicePage,
            },
            financing: {
                noFade: true,
                main: ServicePage,
            },
            monitoring: {
                noFade: true,
                main: ServicePage,
            },
        },
    },
    documents: {
        status: 'private',
        main: withPermission(DocumentsPage, PERMISSIONS.DOCUMENTS.ENTRY),
    },
    aboutUs: {
        main: AboutUsPage,
        template: ContentDialogTemplate2,
    },
    policy: {
        main: PrivacyTermsPages,
        template: ContentDialogTemplate,
    },
    terms: {
        main: PrivacyTermsPages,
        template: ContentDialogTemplate,
    },
    siteMap: {
        main: SiteMapPage,
    },
    login: {
        status: 'public',
        main: AuthPage,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    ssoTimeout: {
        status: 'public',
        main: SsoTimeoutPage,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    loginAsUser: {
        status: 'prevAdmin',
        main: LoginAsUser,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    register: {
        status: 'public',
        main: AuthPage,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    requestDemo: {
        status: 'public',
        main: AuthPage,
        template: DialogTemplate,
        templateProps: {
            withMobileLayout: true,
        },
        state: {
            empty: true,
        },
    },
    forgotPassword: {
        status: 'public',
        main: AuthPage,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    confirmPassword: {
        status: 'public',
        main: AuthPage,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    inviteUser: {
        status: 'public',
        main: AuthPage,
        template: DialogTemplate,
        state: {
            empty: true,
        },
    },
    account: {
        status: 'private',
        main: AccountPage,
        template: ProfileTemplate,
    },
    password: {
        status: 'private',
        main: PasswordPage,
        template: ProfileTemplate,
    },
    payment: {
        status: 'private',
        main: PaymentMethodPage,
        template: ProfileTemplate,
    },
    member: {
        main: MemberPage,
        status: 'private',
        routes: {
            details: {
                main: MemberDetailsPage,
            },
            loans: {
                main: MemberLoansPage,
            },
            properties: {
                main: MemberPropertiesPage,
            },
            team: {
                main: MemberTeamPage,
            },
            owners: {
                main: MemberOwnersPage,
            },
            financials: {
                main: MemberFinancialsPage,
            },
            documents: {
                main: DocumentsMemberPage,
            },
            notes: {
                main: MemberNotesPage,
            },
        },
    },
    members: {
        main: withPermission(MembersPage, PERMISSIONS.CONTACTS.ENTRY),
        status: 'private',
    },
    benchmarking: {
        main: withPermission(BenchmarkingPage, PERMISSIONS.BENCHMARKING.ENTRY),
        status: 'private',
        routes: {
            map: {
                main: MapsPage,
            },
            benchmarking: {
                main: BenchmarkPage,
            },
        },
    },
    chatAi: {
        main: withPermission(ChatAiPage, PERMISSIONS.GPT_AI.ENTRY),
        status: 'private',
        routes: {
            documents: {
                main: ChatDocumentAiPage,
            },
            data: {
                main: ChatDataAiPage,
            },
        },
    },
    todos: {
        main: withPermission(TodoPage, PERMISSIONS.TODOS.ENTRY),
        status: 'private',
    },
    configuration: {
        main: Configuration,
        status: 'private',
        routes: {
            allUsers: {
                main: AllUsers,
            },
            teams: {
                main: Teams,
            },
            team: {
                main: Team,
            },
        },
    },
    error: {
        main: ErrorPage,
    },
};

const routes = createRoutes(PAGES, pages);

export default routes;
