import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import ConfirmedSvg from '@assets/icons/filled/tick-circle.svg';
import ProcessingSvg from '@assets/icons/outlined/arrow-up.svg';
import AwaitingSvg from '@assets/icons/outlined/clock-1.svg';

import styles from './styles.module.scss';

const STATUSES = {
    confirmed: {
        name: 'Confirmed',
        color: colors['grey-400'],
        icon: ConfirmedSvg,
        iconColor: colors['green-500'],
        iconSize: '14px',
    },
    processed: {
        name: 'Processing',
        color: colors['darkNeutral-500'],
        icon: ProcessingSvg,
        iconColor: colors['chart-13'],
        iconSize: '14px',
    },
    awaiting: {
        name: 'Awaiting Confirmation',
        color: colors['darkNeutral-500'],
        icon: AwaitingSvg,
        iconColor: colors.info,
        iconSize: '14px',
    },
};

const defaultProps = {
    status: 'processed',
    options: STATUSES,
};

function DocumentStatus({ status, options }) {
    const { name, color, icon, iconColor, iconSize } = options[status.toLowerCase()] || {};
    return (
        <div className={styles.status}>
            <Icon color={iconColor} component={icon} fontSize={iconSize} />
            <Typography color={color} component='span' variant='pr12'>
                {name}
            </Typography>
        </div>
    );
}

DocumentStatus.defaultProps = defaultProps;

export default DocumentStatus;
