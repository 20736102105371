const tabScrollButton = () => ({
    MuiTabScrollButton: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                '&.Mui-disabled': {
                    opacity: '0.3',
                },
            },
        },
    },
});
export default tabScrollButton;
