import { createSlice } from '@reduxjs/toolkit';

const entity = '[agGrid]';

const initialState = {
    hoveredHeaderCell: null,
};

const agGridSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setHoveredHeaderCell(state, action) {
            state.hoveredHeaderCell = action.payload;
        },
    },
});

export const { setHoveredHeaderCell: setHoveredHeaderCellAGGrid } = agGridSlice.actions;

export default agGridSlice.reducer;

export const selectAgGridState = (store) => store.agGrid;
