import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery, createConfig } from '@scc/services/helpers';

const ENTITY = 'rentRollLabel';

export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});

export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: ['createOrUpdateRentRollLabel', 'deleteRentRollLabelByRentRollId'],
});
