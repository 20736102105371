import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '@ui/Button';
import Popover from '@ui/Popover';
import RecentlyViewed from '@scc/pages/Property/components/RecentlyViewed';
import { selectIsUserAuth } from '@scc/store/ducks/auth';

const RecentlyViewedButton = function () {
    const actionRef = useRef();
    const [open, setOpen] = useState(false);
    const isLoggedIn = useSelector(selectIsUserAuth);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        setOpen(!!anchorEl);
    }, [anchorEl]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        isLoggedIn && (
            <div>
                <Button variant='outlined' id='recently-viewed' size='small' onClick={handleClick}>
                    Recently Viewed
                </Button>
                <Popover
                    action={actionRef}
                    id='recent-viewed'
                    open={open}
                    PaperProps={{
                        sx: {
                            padding: '1rem',
                            width: '100%',
                            maxWidth: 'min-content',
                        },
                    }}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transitionDuration={300}
                >
                    <RecentlyViewed
                        handleClose={handleClose}
                        closeIcon
                        updatePosition={() => actionRef.current.updatePosition()}
                    />
                </Popover>
            </div>
        )
    );
};

export default RecentlyViewedButton;
