import { AnyAction } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore.d';
import { LicenseManager } from 'ag-grid-enterprise';

import createApp from '@utils/app';

import App from '@scc/app';
import withGlobalBrowserFunctionality from '@scc/hocs/withGlobalBrowserFunctionality';
import routes from '@scc/router';
import links from '@scc/router/links';
import { sagas, reducers, TReducers } from '@scc/store';

import { theme } from '@themes/index';

import createStore from '@shared/store';

import '@scc/assets/styles/main';

if (process.env.AG_GRID_KEY) {
    LicenseManager.setLicenseKey(process.env.AG_GRID_KEY);
}

const { store, renderApp } = createApp(createStore, {
    theme,
    reducers,
    sagas,
    persist: ['auth', 'common', 'benchmarking', 'memory'], // WARNING: Don't add unnecessary data to persist store!
    noMount: false,
});

renderApp(
    withGlobalBrowserFunctionality(App, {
        routes,
        redirects: {
            allow: links.home.path,
            forbidden: links.login.path,
        },
    })
);

const typedStore: ToolkitStore<{ [K in keyof TReducers]: ReturnType<TReducers[K]> }, AnyAction, []> = store;

export type RootState = ReturnType<typeof typedStore.getState>;

export type AppDispatch = typeof typedStore.dispatch;
