import request from '@utils/request';

const BASE_URL = '/api/v2/property-details';
const UNDERWRITING_URL = '/api/v2/underwriting/property/financials';

export async function getOverview({ countyId, parcelId, userId } = {}) {
    return request(`${BASE_URL}/overview`, {
        params: {
            countyId,
            parcelId,
            userId,
        },
    });
}

export async function getMarket({ countyId, parcelId, userId } = {}) {
    return request(`${BASE_URL}/market`, {
        params: {
            countyId,
            parcelId,
            userId,
        },
    });
}

export async function getMarketTopEmployers({ pointLat, pointLon, radius, hqOnly, page = 0, size = 20, sort } = {}) {
    return request(`/api/v2/statistics/companies/top`, {
        params: {
            pointLat,
            pointLon,
            radius,
            hqOnly,
            page,
            size,
            sort,
        },
    });
}

export async function getDocuments({ countyId, parcelId, userId } = {}) {
    return request(`${BASE_URL}/processed-documents`, {
        params: {
            countyId,
            parcelId,
            userId,
        },
    });
}

export async function getDocumentsStatement({ propertyId, propertyBuildingId } = {}) {
    return request(`${BASE_URL}/processed-documents/statement`, {
        params: {
            propertyId,
            propertyBuildingId,
        },
    });
}

export async function getDocumentsRentRoll({ propertyId, propertyBuildingId } = {}) {
    return request(`${BASE_URL}/processed-documents/rent-roll`, {
        params: {
            propertyId,
            propertyBuildingId,
        },
    });
}

export async function getDocumentsDevelopmentBudget({ propertyId, propertyBuildingId } = {}) {
    return request(`${BASE_URL}/processed-documents/dev-budget`, {
        params: {
            propertyId,
            propertyBuildingId,
        },
    });
}

export async function getCombinedDocumentStatement({ propertyId } = {}) {
    return request(`${BASE_URL}/processed-documents/combined-statement`, {
        params: {
            propertyId,
        },
    });
}

export async function getCombinedDocumentRentRoll({ propertyId } = {}) {
    return request(`${BASE_URL}/processed-documents/combined-rent-roll`, {
        params: {
            propertyId,
        },
    });
}

export async function getDataLabels({ propertyId } = {}) {
    return request(`${BASE_URL}/processed-documents/combined-statement/date-labels`, {
        params: {
            propertyId,
        },
    });
}

export async function compareRR({ rrid, prevRrid } = {}) {
    return request(`${BASE_URL}/rr-compare`, {
        params: {
            rrid,
            prevRrid,
        },
    });
}

export async function getHistory({ countyId, parcelId, userId, loanDetailId } = {}) {
    return request(`${BASE_URL}/history`, {
        params: {
            countyId,
            parcelId,
            userId,
            loanDetailId,
        },
    });
}

export async function getAnalytics({ rrid, combinedOsid, dateLabels, useNormalized, uploadRentroll } = {}) {
    if (!rrid && !combinedOsid) return Promise.reject(new Error('No rrid or combinedOsid provided'));

    return request(`${BASE_URL}/analytics`, {
        params: {
            rrid,
            combinedOsid,
            dateLabels,
            useNormalized,
            uploadRentroll,
        },
    });
}

export async function getValuation({ parcelId, countyId, userId } = {}) {
    return request(`${UNDERWRITING_URL}/${countyId}/${parcelId?.replace('/', '_SLASH_')}?userId=${userId}`);
}

export async function getUnderwritingTable({ parcelId, countyId, userId } = {}) {
    return request(`${UNDERWRITING_URL}/${countyId}/${parcelId?.replace('/', '_SLASH_')}?userId=${userId}`);
}

export async function overwriteMainFields(data) {
    return request('/api/v2/parcel-override', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function overwriteBaseFields({ countyId, parcelId, userId, data }) {
    return request('/api/v2/property/update', {
        params: { countyId, parcelId, userId },
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getAnalysis(params) {
    return request(`${BASE_URL}/analysis`, params);
}

export async function sendEmailRequests({ id, email, link, fullAddress, emailBody } = {}) {
    return request(`${BASE_URL}/user-request/send-email`, {
        method: 'POST',
        body: JSON.stringify({
            id,
            email,
            link,
            address: fullAddress,
            comment: emailBody,
        }),
    });
}
