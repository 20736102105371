const appBar = () => ({
    MuiAppBar: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                transition: 'all 0.3s ease-in-out, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
        },
    },
});
export default appBar;
