import { Switch } from 'react-router-dom';

import ErrorBoundary from '@components/ErrorBoundary';

import RouteItem from './RouteItem';

const defaultProps = {
    type: 'main',
    routes: [],
    isLimited: false,
    limitedRoutes: [],
};

function RouteList({ routes, type, auth, location, redirects, templateProps, isLimited, limitedRoutes, props }) {
    return (
        <ErrorBoundary>
            <Switch location={location}>
                {routes.map((route) => (
                    <RouteItem
                        key={route.name}
                        auth={auth}
                        component={route[type]}
                        isLimited={isLimited}
                        limitedRoutes={limitedRoutes}
                        redirects={redirects}
                        templateProps={templateProps}
                        {...props}
                        {...route}
                    />
                ))}
            </Switch>
        </ErrorBoundary>
    );
}

RouteList.defaultProps = defaultProps;

export default RouteList;
