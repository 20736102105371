import { MAIN_TEMPLATE_WIDGETS } from './helpers';

export const getTemplatesTabs = ({
    customControls,
    template,
    tabs,
}: {
    customControls?: {
        items: Record<string, unknown>;
    };
    template: {
        id: string;
        group: string;
    };
    tabs: Record<string, unknown>[];
}) => {
    if (tabs) {
        return tabs;
    }

    if (customControls?.items) {
        return customControls?.items;
    }

    return MAIN_TEMPLATE_WIDGETS[template.group]?.templates?.[template.id];
};
