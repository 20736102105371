import colors from '@themes/palette/export.module.scss';

export const containedPrimary = {
    backgroundColor: colors['primary-main'],
    '&:hover': {
        backgroundColor: colors['primary-light'],
    },
    '&:active': {
        backgroundColor: colors['primary-dark'],
    },
    '&:focus': {
        backgroundColor: colors['primary-main'],
    },
    '&:focus-within': {
        backgroundColor: colors['primary-main'],
    },
    '&.Mui-disabled': {
        backgroundColor: colors['primary-disabled'],
    },
};

export const containedSecondary = {
    backgroundColor: colors['secondary-main'],
    '&:hover': {
        backgroundColor: colors['secondary-light'],
    },
    '&:active': {
        backgroundColor: colors['secondary-dark'],
    },
    '&:focus': {
        backgroundColor: colors['secondary-main'],
    },
    '&:focus-within': {
        backgroundColor: colors['secondary-focus'],
    },
    '&.Mui-disabled': {
        backgroundColor: colors['secondary-disabled'],
    },
};

export const containedInherit = {
    color: 'inherit',
    backgroundColor: colors['lightGrey-300'],
    '&:hover': {
        backgroundColor: colors['lightGrey-200'],
    },
    '&:active': {
        backgroundColor: colors['lightGrey-500'],
    },
    '&:focus': {
        backgroundColor: colors['lightGrey-300'],
    },
    '&:focus-within': {
        backgroundColor: colors['lightGrey-400'],
    },
    '&.Mui-disabled': {
        backgroundColor: '#D2D6E4',
    },
};

export const containedSuccess = {
    backgroundColor: colors['success-main'],

    '&:hover': {
        backgroundColor: colors['success-light'],
    },

    '&:active': {
        backgroundColor: colors['success-dark'],
    },

    '&:focus': {
        backgroundColor: colors['success-main'],
    },

    '&:focus-within': {
        backgroundColor: colors['success-focus'],
    },

    '&.Mui-disabled': {
        backgroundColor: colors['success-disabled'],
    },
};

export const containedWarning = {
    backgroundColor: colors['warning-main'],

    '&:hover': {
        backgroundColor: colors['warning-light'],
    },

    '&:active': {
        backgroundColor: colors['warning-dark'],
    },

    '&:focus': {
        backgroundColor: colors['warning-main'],
    },

    '&:focus-within': {
        backgroundColor: colors['warning-focus'],
    },

    '&.Mui-disabled': {
        backgroundColor: colors['warning-disabled'],
    },
};

export const containedError = {
    backgroundColor: colors['error-main'],

    '&:hover': {
        backgroundColor: colors['error-light'],
    },

    '&:active': {
        backgroundColor: colors['error-dark'],
    },

    '&:focus': {
        backgroundColor: colors['error-main'],
    },

    '&:focus-within': {
        backgroundColor: colors['error-focus'],
    },

    '&.Mui-disabled': {
        backgroundColor: colors['error-disabled'],
    },
};
