import useDebouncedEffect from '@shared/hooks/useDebouncedEffect';

const addBodyClass = (className) => document.body.classList.add(className);
const removeBodyClass = (className) => document.body.classList.remove(className);

const useAddBodyClass = (className, flag = true, delay = 0) => {
    useDebouncedEffect(
        () => {
            if (flag) addBodyClass(className);
        },
        [flag, className],
        delay,
        () => {
            removeBodyClass(className);
        }
    );
};

export default useAddBodyClass;
