import { createSlice } from '@reduxjs/toolkit';

const entity = '[grid/editMapping]';

const initialState = {
    step: 0,
};

const editMappingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setStep(state, action) {
            state.step = action.payload;
        },
        resetState() {
            return initialState;
        },
    },
});

export const { setStep: setStepEditMapping, resetState: resetStateEditMapping } = editMappingSlice.actions;

export const selectEditMappingState = (store) => store.grid.editMapping;

export default editMappingSlice.reducer;
