import { createSlice } from '@reduxjs/toolkit';
import { put, takeEvery, takeLeading, takeLatest, select, delay, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { ordersApi, propertiesApi, listingApi } from '@api';
import { SHARE_PROPERTY, LISTING_PROPERTY } from '@constants/modals';
import { TEMPLATES } from '@shared/templates/forms/ListingProperty/helpers';
import { SharePropertyForm } from '@shared/templates/forms/ShareProperty';
import { apiRequest, API_SUCCESS, API_ERROR } from '@store/ducks/api';
import { showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

import { getCurrentUserAuth, selectIsUserAuth, selectUserInfo, setStatusAuth, updateUserAuth } from './auth';
import { setTemplateAuthMng } from './authManager';
import { selectBuildingState } from './building';
import { loadAllRentRoll, selectRRFiles, selectOSFiles, selectHasFiles } from './rentRoll';
import { showDrawer, hideDrawer, selectIsOpenDrawerState } from './ui/_drawer';

const initialState = {
    stepper: 0,
    step: '',
    type: '',
    status: null,
    template: SharePropertyForm.TEMPLATES.MAIN,
    listingTemplate: TEMPLATES.MAIN,
    loading: false,
    loaded: false,
};

const entity = '[property]';
const SHARE_LINK_ACTION = 'shareLink';
const CONTACT_BROKER_ACTION = 'contactBroker';

const propertySlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        start(state, action) {
            state.step = action.payload.step;
            state.type = action.payload.type;
        },
        setStepper(state, action) {
            state.stepper = action.payload;
        },
        setStep(state, action) {
            state.step = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        addUserCard() {},
        cancel() {},
        create() {},
        [SHARE_LINK_ACTION](state) {
            state.loaded = false;
            state.loading = true;
            state.template = SharePropertyForm.TEMPLATES.MAIN;
        },
        setShareTemplate(state, action) {
            state.template = action.payload;
        },
        setListingTemplate(state, action) {
            state.listingTemplate = action.payload;
        },
        startShareLink(state) {
            state.template = SharePropertyForm.TEMPLATES.MAIN;
        },
        startContactBroken(state) {
            state.listingTemplate = TEMPLATES.MAIN;
        },
        [`${SHARE_LINK_ACTION} ${API_SUCCESS}`](state) {
            state.loaded = true;
            state.loading = false;
        },
        [`${SHARE_LINK_ACTION} ${API_ERROR}`](state) {
            state.loaded = false;
            state.loading = false;
        },
        [CONTACT_BROKER_ACTION](state) {
            state.loaded = false;
            state.loading = true;
            state.template = TEMPLATES.MAIN;
        },
        [`${CONTACT_BROKER_ACTION} ${API_SUCCESS}`](state) {
            state.loaded = true;
            state.loading = false;
        },
        [`${CONTACT_BROKER_ACTION} ${API_ERROR}`](state) {
            state.loaded = false;
            state.loading = false;
        },
    },
});

export const {
    start: startProperty,
    create: createProperty,
    setStepper: setStepperProperty,
    setStep: setStepProperty,
    setStatus: setStatusProperty,
    cancel: cancelProperty,
    addUserCard: addUserCardProperty,
    setShareTemplate: setShareTemplateProperty,
    startShareLink: startShareLinkProperty,
    shareLink: shareLinkProperty,

    setListingTemplate: setListingTemplateProperty,
    startContactBroken: startContactBrokenProperty,
    contactBroker: contactBrokenProperty,
} = propertySlice.actions;

export default propertySlice.reducer;

export const selectPropertyState = (state) => state.property;

export const selectHasOrderCompleteState = createSelector(
    [selectPropertyState, selectUserInfo, selectHasFiles],
    ({ step, status }, { card }, hasFiles) =>
        // eslint-disable-next-line no-nested-ternary
        step === 'valuation' ? (status === 'completed' ? false : !card) : !hasFiles
);

function* createPropertySaga() {
    const RRFiles = yield select(selectRRFiles) || [];
    const OSFiles = yield select(selectOSFiles) || [];
    const { parcel } = yield select(selectBuildingState);

    const params = {
        orderType: 'FULL',
        countyId: parcel.countyid,
        parcelId: parcel.parcelid,
        rentRolls: RRFiles.map((file) => file.rrid),
        statements: OSFiles.map((file) => file.osid),
    };

    yield put(apiRequest(params, ordersApi.createOrder, createProperty));
    const action = yield take([`${createProperty} ${API_SUCCESS}`, `${createProperty} ${API_ERROR}`]);

    if (action.type.endsWith('SUCCESS')) {
        yield put(setStepProperty('sent'));
    }
}

function* startPropertySaga(action) {
    const isLoggedIn = yield yield select(selectIsUserAuth);
    if (isLoggedIn) {
        yield put(setStepperProperty(1));
    } else {
        yield put(setTemplateAuthMng({ name: 'register' }));
    }
    yield put(getCurrentUserAuth());
    yield put(showDrawer(action.payload.type));
}

function* setupPropertySaga(action) {
    const isLoggedIn = action.payload === 'private';
    yield put(setStepperProperty(isLoggedIn ? 1 : 0));
    const isDrawerOpen = yield select(selectIsOpenDrawerState);
    const { status } = yield select(selectPropertyState);

    if (isLoggedIn && isDrawerOpen) {
        const { parcel } = yield select(selectBuildingState);
        yield put(loadAllRentRoll(parcel));
    } else if (!isLoggedIn && status) {
        yield put(setStatusProperty(null));
    }
}

function* cancelPropertySaga() {
    yield put(hideDrawer());
    yield put(setStepperProperty(1));
}

function* setStepPropertySaga(action) {
    // eslint-disable-next-line default-case
    switch (action.payload) {
        case 'registered':
            yield delay(4000);
            yield put(setStepProperty('upload'));
            break;
        case 'verification':
            yield delay(4000);
            yield put(setStepProperty('sent'));
            break;
        case 'sent':
            yield delay(4000);
            yield put(setStepperProperty(1));
            yield put(hideDrawer());
            break;
        default:
            console.warn(`property step changed: ${action.payload}`);
    }
}

function* addUserCardPropertySaga(action) {
    if (action.payload) {
        yield put(
            updateUserAuth({
                cardToken: action.payload.token,
                cardName: action.payload.holder,
            })
        );
    }
    yield put(createProperty());
}

function* startShareLinkPropertySaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: SHARE_PROPERTY,
            data: payload,
        })
    );
    const action = yield take(`${shareLinkProperty} ${API_SUCCESS}`);
    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setShareTemplateProperty(SharePropertyForm.TEMPLATES.SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
    }
}

function* shareLinkPropertySaga({ type, payload }) {
    yield put(apiRequest(payload, propertiesApi.shareLink, type));
}

function* startContactBrokenPropertySaga({ payload: { className, ...payload } }) {
    yield put(
        showPrimaryAsideDrawer({
            content: LISTING_PROPERTY,
            data: { ...payload },
            className,
        })
    );
    const action = yield take(`${contactBrokenProperty} ${API_SUCCESS}`);
    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setListingTemplateProperty(TEMPLATES.SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
    }
}

function* contactBrokenPropertySaga({ type, payload }) {
    yield put(apiRequest(payload, listingApi.sendMessage, type));
}

export function* watchPropertyProcess() {
    yield takeLeading(startProperty, startPropertySaga);
    yield takeLeading(createProperty, createPropertySaga);
    yield takeLeading(addUserCardProperty, addUserCardPropertySaga);
    yield takeEvery(cancelProperty, cancelPropertySaga);
    yield takeEvery(setStatusAuth, setupPropertySaga);
    yield takeEvery(setStepProperty, setStepPropertySaga);
    yield takeLatest(startShareLinkProperty, startShareLinkPropertySaga);
    yield takeEvery(shareLinkProperty, shareLinkPropertySaga);

    yield takeLatest(startContactBrokenProperty, startContactBrokenPropertySaga);
    yield takeEvery(contactBrokenProperty, contactBrokenPropertySaga);
}
