import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { HeaderMenu } from '@scc/components/HeaderMenu';
import Logo from '@scc/components/Logo';
import links, { publicHeaderLinks, publicHeaderMobileLinks } from '@scc/router/links';
import { selectIsUserAuth } from '@scc/store/ducks/auth';
import { selectCommonState } from '@scc/store/ducks/common';

import AppBar from '@ui/AppBar';
import Button from '@ui/Button';
import Container from '@ui/Container';
import Drawer from '@ui/Drawer';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Toolbar from '@ui/Toolbar';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import BulletSvg from '@assets/icons/outlined/bullet.svg';
import CloseSvg from '@assets/icons/outlined/close.svg';
import MenuSvg from '@assets/icons/outlined/menu.svg';
import SearchIcon from '@assets/icons/outlined/search-normal-1.svg';
import { PROPERTY_SEARCH } from '@constants/modals';
import Meta from '@shared/components/Meta';
import { selectUiDialogState } from '@store/ducks/ui/dialog';
import { showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

import styles from './base.module.scss';
import BaseBreadcrumbs from './BaseBreadcrumbs';

const defaultProps = {
    barClassName: '',
};

function BaseHeader({ barClassName, onLogout, children, containerProps, appBarProps, containerRef }) {
    const dispatch = useDispatch();

    const location = useLocation();
    const [transparentHeader, setTransparentHeader] = useState(true);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const isLoggedIn = useSelector(selectIsUserAuth);
    const { currentPage, menuOpen } = useSelector(selectCommonState);
    const { isOpen: isOpenSearch } = useSelector(selectUiDialogState);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const mobileOrDesktopLinks = isMobile ? publicHeaderMobileLinks : publicHeaderLinks;

    const linksToShow = !isLoggedIn ? mobileOrDesktopLinks || [] : [];

    useEffect(() => {
        const listenerTarget = containerRef || document.body || window;

        const handleScroll = () => {
            const isTransparent = listenerTarget ? listenerTarget.scrollTop > 0 : window.pageYOffset > 0;
            setTransparentHeader(!isTransparent);
        };
        listenerTarget.addEventListener('scroll', handleScroll);
        return () => listenerTarget.removeEventListener('scroll', handleScroll);
    }, [containerRef]);

    const closeMenu = () => {
        setIsOpenMenu(false);
    };

    const handleLogout = () => {
        onLogout();
        closeMenu();
    };

    const handleSearch = () => {
        dispatch(
            showPrimaryAsideDrawer({
                content: PROPERTY_SEARCH,
                data: {
                    title: <Typography variant='h2b'>Search Property</Typography>,
                },
            })
        );
    };

    const mobileLayout = () => {
        if (isLoggedIn) return null;
        return (
            <div className={styles.burgerMenu}>
                <IconButton.Medium
                    aria-label='Property search'
                    disabled={isOpenSearch}
                    variant='outlined'
                    onClick={handleSearch}
                >
                    <Icon
                        color={isOpenSearch ? colors['grey-300'] : colors['darkNeutral-500']}
                        component={SearchIcon}
                        fontSize={20}
                    />
                </IconButton.Medium>
                <Button
                    aria-label='Request Demo'
                    component={Link}
                    rel='nofollow'
                    size='small'
                    to={{
                        state: { from: location },
                        pathname: links.requestDemo.path,
                    }}
                >
                    Request Demo
                </Button>
                {isOpenMenu || isOpenSearch ? (
                    <IconButton.Large
                        onClick={() => {
                            setIsOpenMenu(false);
                        }}
                    >
                        <Icon color={colors['darkNeutral-500']} component={CloseSvg} />
                    </IconButton.Large>
                ) : (
                    <IconButton.Large
                        onClick={() => {
                            setIsOpenMenu(true);
                        }}
                    >
                        <Icon color={colors['darkNeutral-500']} component={MenuSvg} />
                    </IconButton.Large>
                )}
            </div>
        );
    };

    const desktopLayout = () => {
        if (!linksToShow?.length) return null;
        return (
            <div className={styles.menuWrapper}>
                <HeaderMenu active={location.pathname} links={linksToShow} />
            </div>
        );
    };

    const headerLayout = () => (
        <AppBar
            className={cx({ [styles.borderBottom]: isLoggedIn })}
            color={transparentHeader ? 'transparent' : 'inherit'}
            position='sticky'
        >
            <Meta title={currentPage?.title} />
            <Container {...containerProps}>
                <Toolbar className={`${barClassName} ${styles.headerToolbar}`} variant='dense' disableGutters>
                    {isMobile ? mobileLayout() : desktopLayout()}
                    <div className={styles.logo}>
                        <Logo type='link' />
                        {isLoggedIn && (
                            <div
                                className={cx(styles.breadcrumbsWrapper, {
                                    [styles.breadcrumbsWrapperMenuClosed]: !menuOpen,
                                })}
                            >
                                <BaseBreadcrumbs
                                    based={currentPage?.based}
                                    template={currentPage?.path}
                                    title={currentPage?.title}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.menu}>{children}</div>
                </Toolbar>
            </Container>
        </AppBar>
    );

    return (
        <>
            {headerLayout()}
            {isMobile && (
                <Drawer
                    PaperProps={{
                        className: styles.drawerPaper,
                    }}
                    anchor='right'
                    classes={{
                        root: styles.drawerContainer,
                    }}
                    open={isOpenMenu}
                    onClose={closeMenu}
                >
                    <AppBar color='inherit' position='static' {...appBarProps}>
                        <Container {...containerProps}>
                            <Toolbar
                                className={`${barClassName} ${styles.headerToolbar}`}
                                variant='dense'
                                disableGutters
                            >
                                {mobileLayout()}
                                <div className={styles.logo}>
                                    <Logo type='link' />
                                </div>
                                <div className={styles.menu}>{children}</div>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <Container
                        classes={{
                            root: isMobile ? styles.mobileContainerWrapper : null,
                        }}
                        {...containerProps}
                    >
                        <Grid className={styles.menuWrapper} container>
                            <ItemList items={linksToShow}>
                                {({ path, name, label, external, target, routes }) => {
                                    const props = external ? { href: path, target } : { component: NavLink, to: path };

                                    return (
                                        <>
                                            <Button.Link
                                                key={name}
                                                aria-label={label}
                                                component={Link}
                                                {...props}
                                                {...(label === 'Listings' ? { rel: 'nofollow' } : {})}
                                                className={cx(styles.navLink, styles.link)}
                                                onClick={closeMenu}
                                            >
                                                <Typography color={colors['darkNeutral-500']} variant='h5sb'>
                                                    {label}
                                                </Typography>
                                            </Button.Link>
                                            {routes && (
                                                <div className={styles.subMenu}>
                                                    {Object.entries(routes).map(([, rout]) => (
                                                        <Button.Link
                                                            key={rout.name}
                                                            aria-label={rout.label}
                                                            className={cx(styles.navLink, styles.subLink)}
                                                            component={NavLink}
                                                            to={rout.path}
                                                            onClick={closeMenu}
                                                        >
                                                            <Icon
                                                                color={colors['blue-main']}
                                                                component={BulletSvg}
                                                                fontSize={13}
                                                            />
                                                            <Typography
                                                                color={colors['darkNeutral-500']}
                                                                variant='pr16'
                                                            >
                                                                {rout.label}
                                                            </Typography>
                                                        </Button.Link>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            </ItemList>
                        </Grid>
                        <Grid className={styles.actionsWrapper} container>
                            {isLoggedIn && (
                                <Button
                                    aria-label='Log Out'
                                    size='extra-large'
                                    variant='outlined'
                                    fullWidth
                                    onClick={handleLogout}
                                >
                                    Log Out
                                </Button>
                            )}
                            {!isLoggedIn && (
                                <>
                                    <Button
                                        aria-label='Create Account for Free'
                                        color='primary'
                                        component={Link}
                                        rel='nofollow'
                                        size='extra-large'
                                        to={{
                                            state: { from: location },
                                            pathname: links.register.path,
                                        }}
                                        fullWidth
                                        onClick={closeMenu}
                                    >
                                        Create Account for Free
                                    </Button>
                                    <Button
                                        aria-label='Login'
                                        component={Link}
                                        rel='nofollow'
                                        size='extra-large'
                                        to={{
                                            state: { from: location },
                                            pathname: links.login.path,
                                        }}
                                        variant='outlined'
                                        fullWidth
                                        onClick={closeMenu}
                                    >
                                        Login
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Container>
                </Drawer>
            )}
        </>
    );
}

BaseHeader.defaultProps = defaultProps;

export default BaseHeader;
