import { Box, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import FlagIcon from '@scc/assets/images/flag.svg';
import VerificationFeatures from '@scc/components/RegisterForm/components/VerificationFeatures';

import Button from '@ui/Button';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import ArrowLeftIcon from '@icons/outlined/arrow-left-3.svg';

import styles from './styles.module.scss';
import { verificationSchema } from './validationSchema';

function EmailForm({ onSuccess, onStepChange, loading }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(verificationSchema),
    });

    const handleBackButtonClick = () => {
        onStepChange('register');
    };

    const onSubmit = (data) => onSuccess(data);

    return (
        <div className={styles.formWrapper}>
            <Box className={styles.form} component='form' onSubmit={handleSubmit(onSubmit)}>
                <Grid columnSpacing={12} container>
                    <Grid md={6} xs={12} item>
                        <Input
                            label='First Name'
                            margin='normal'
                            variant='standard'
                            {...register('firstName')}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid md={6} xs={12} item>
                        <Input
                            label='Last Name'
                            margin='normal'
                            variant='standard'
                            {...register('lastName')}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Input
                    label='phone'
                    margin='normal'
                    variant='standard'
                    {...register('phone')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Icon component={FlagIcon} fontSize={24} />
                            </InputAdornment>
                        ),
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    fullWidth
                />

                <VerificationFeatures />

                <Button
                    className={styles.submit}
                    loading={loading}
                    size='extra-large'
                    type='submit'
                    variant='contained'
                    fullWidth
                >
                    Create Free Account
                </Button>
                <Button.Link
                    className={styles.back}
                    color='secondary'
                    size='extra-large'
                    fullWidth
                    onClick={handleBackButtonClick}
                >
                    <Icon
                        color={colors['grey-400']}
                        component={ArrowLeftIcon}
                        fontSize='16px'
                        sx={{ fontWeight: 'bold' }}
                    />
                    <Typography color={colors['grey-400']} component='span' variant='mdr'>
                        Back
                    </Typography>
                </Button.Link>
            </Box>
            <div />
        </div>
    );
}

export default EmailForm;
