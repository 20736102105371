import { Route, Redirect } from 'react-router-dom';

import Noop from '@components/Noop';

import { useSessionKeepAlive } from '@shared/hooks/useSessionKeepAlive';

const defaultProps = {
    auth: {},
};

function ProtectedRoute({
    component: Component,
    auth: { isAdmin, isPreviousAdmin, isLoggedIn },
    alter: Alter,
    template: Template,
    forbiddenRedirect,
    templateProps = {},
    status,
    redirect,
    ...rest
}) {
    const isForbidden =
        (!isLoggedIn && status === 'private') ||
        (isLoggedIn && status === 'public' && !Alter) ||
        // label SKIP IF NOT ADMIN OR NOT PREVADMIN
        (!isAdmin && !isPreviousAdmin && status === 'prevAdmin');
    const Content = isLoggedIn && status === 'public' ? Alter || Noop : Component;
    const forbiddenRedirectPath = isLoggedIn ? forbiddenRedirect?.allow : forbiddenRedirect?.forbidden;

    useSessionKeepAlive();

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <Route
            {...rest}
            render={(props) =>
                isForbidden ? (
                    <Redirect
                        to={{
                            pathname: redirect || props.location?.state?.from?.pathname || forbiddenRedirectPath,
                            search: props.location?.state?.from?.search,
                            hash: props.location?.state?.from?.hash,
                            state: { from: isLoggedIn && status === 'public' ? null : props.location },
                        }}
                    />
                ) : (
                    <Template label={rest.label} name={rest.name} state={rest.state} {...templateProps}>
                        <Content {...rest} {...props} />
                    </Template>
                )
            }
        />
    );
}

ProtectedRoute.defaultProps = defaultProps;

export default ProtectedRoute;
