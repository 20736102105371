import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';

import AcceptTerms from '@scc/components/AcceptTerms';
import links from '@scc/router/links';
import { cancelProperty, selectHasOrderCompleteState } from '@scc/store/ducks/property';

import Button from '@ui/Button';
import Divider from '@ui/Divider';
import Stack from '@ui/Stack';

import styles from './styles.module.scss';

function Controls({ orderProps, step, onStepperChange }) {
    const showNextBtn = useSelector(selectHasOrderCompleteState);
    const dispatch = useDispatch();
    const props = step === 'valuation' ? orderProps : {};

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {!isMobile ? <Divider /> : null}
            <Stack className={styles.container} direction='row' justifyContent='space-between'>
                <Button variant='outlined' onClick={onStepperChange(step === 'valuation' ? -1 : 1)}>
                    {step === 'valuation' ? 'Back' : `I'll upload ${isMobile ? '' : 'documents'} later`}
                </Button>
                <span className={styles.controls}>
                    {!isMobile ? (
                        <Button variant='outlined' onClick={() => dispatch(cancelProperty())}>
                            Cancel
                        </Button>
                    ) : null}
                    <Button
                        {...props}
                        disabled={showNextBtn}
                        onClick={step === 'valuation' ? () => null : onStepperChange(1)}
                    >
                        {step === 'valuation' ? 'Complete Order' : 'Next'}
                    </Button>
                </span>
            </Stack>
            {!isMobile ? (
                <div className={styles.footerText} style={{ marginTop: '35px' }}>
                    <AcceptTerms policyLink={links.policy.path} termsLink={links.terms.path} />
                </div>
            ) : null}
        </>
    );
}

export default Controls;
