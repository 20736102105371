import { createSlice } from '@reduxjs/toolkit';
import { put, takeLatest, select, take } from 'redux-saga/effects';

import { loadLoans } from '@scc/store/ducks/loans/loans';

import { referApi } from '@api';
import { LOAN_REQUEST, SEARCH_PROPERTY } from '@constants/modals';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { addParcel, cleanParcel } from '@store/ducks/parcel';
import { showEmptyDialog, hideDialog } from '@store/ducks/ui/dialog';
import { showPageDrawer } from '@store/ducks/ui/drawer';

const entity = '[flowLoan]';

const initialState = {
    data: {},
    currentLoanId: null,
};

const loanFLowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        request() {},
        send(state, action) {
            state.data = action.payload.data;
        },
        setCurrentLoanId(state, action) {
            state.currentLoanId = action.payload;
        },
        clearStore() {
            return initialState;
        },
        showProperty() {},
        addProperty() {},
    },
});

export const {
    request: requestLoanFlow,
    showProperty: showPropertyLoanFlow,
    addProperty: addPropertyLoanFlow,
    send: sendLoanFLow,
    setCurrentLoanId: setCurrentLoanIdLoanFlow,
    clearStore: clearStoreLoanFlow,
} = loanFLowSlice.actions;

export default loanFLowSlice.reducer;

export const selectLoansState = (store) => store.flow.requestLoan;

function* requestLoanFlowSaga({ payload }) {
    yield put(cleanParcel());
    yield put(setCurrentLoanIdLoanFlow(payload));
    yield put(
        showPageDrawer({
            content: LOAN_REQUEST,
            data: { label: '' },
        })
    );
}

function* sendLoanFLowSaga({ payload, type }) {
    const { currentLoanId } = yield select(selectLoansState);
    yield put(apiRequest({ data: payload, id: currentLoanId }, referApi.postRefer, type));

    const {
        payload: { data: responseData },
    } = yield take(`${type} ${API_SUCCESS}`);

    yield put(setCurrentLoanIdLoanFlow(responseData?.id));

    yield put(loadLoans());
}

function* showPropertyLoanFlowSaga({ payload }) {
    yield put(showEmptyDialog({ content: SEARCH_PROPERTY, className: payload.className }));
}

function* addPropertyLoanFlowSaga({ payload }) {
    yield put(addParcel(payload));
    yield put(hideDialog());
}

export function* watchLoanRequestFLow() {
    yield takeLatest(requestLoanFlow, requestLoanFlowSaga);
    yield takeLatest(sendLoanFLow, sendLoanFLowSaga);
    yield takeLatest(showPropertyLoanFlow, showPropertyLoanFlowSaga);
    yield takeLatest(addPropertyLoanFlow, addPropertyLoanFlowSaga);
}
