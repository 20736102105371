import Icon from '@ui/Icon';
import SentSuccessLayout from '@scc/components/PartialSuccessLayout';
import CheckCircleOutlineIcon from '@scc/assets/icons/success.svg';

import styles from './styles.module.scss';

function ConfirmInviteFormSuccess() {
    return (
        <SentSuccessLayout
            className={styles.success}
            icon={<Icon component={CheckCircleOutlineIcon} fontSize='128px' />}
            title={<span>Success!</span>}
            description={<span>You’ve successfully updated your password</span>}
        />
    );
}
export default ConfirmInviteFormSuccess;
