import download from 'downloadjs';

import request, { downloadDocument } from '@utils/request';

const BASE_URL = '/api/v2/document';
const BASE_URL_SLAT_XLS_EXPORT = '/api/v2/slat-xls-export';

export const DOWNLOAD_URL = `${BASE_URL}/download`;

export async function getLoanDocuments({ loanId = '', search = {} } = {}) {
    return request(`${BASE_URL}/find-by-loan/${loanId}`, {
        params: {
            ...search,
        },
    });
}

export async function getMemberDocuments({ memberId = '', search = {} } = {}) {
    return request(`${BASE_URL}/find-by-member/${memberId}`, {
        params: {
            ...search,
        },
    });
}

export async function getLenderDocuments({ lenderId = '', search = {} } = {}) {
    return request(`${BASE_URL}/find-by-lender/${lenderId}`, {
        params: {
            ...search,
        },
    });
}

export async function getPortfolioDocuments({
    parcel: { parcelId = '', countyId = '', types = '', search = '', userId } = {},
} = {}) {
    return request(`${BASE_URL}/${countyId}/${parcelId?.replace('/', '_SLASH_')}`, {
        params: {
            types,
            ...search,
            userId,
        },
    });
}

export async function getAllDocuments({ page = 0, size = 20, access = 'ALL', ...search }) {
    return request(`${BASE_URL}/find`, {
        params: {
            page,
            size,
            access: access.split(',').length === 2 ? 'ALL' : access,
            ...search,
        },
    });
}

export async function addToLoan(data) {
    return request(`${BASE_URL}/add-to-loan`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function removeFromLoan(data) {
    return request(`${BASE_URL}/remove-from-loan`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function addToMember(data) {
    return request(`${BASE_URL}/add-to-member`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function removeFromMember(data) {
    return request(`${BASE_URL}/remove-from-member`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function addToProperty(data) {
    return request(`${BASE_URL}/add-to-property`, {
        method: 'POST',

        body: JSON.stringify(data),
    });
}

export async function removeFromProperty(data) {
    return request(`${BASE_URL}/remove-from-property`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function addToUser(data) {
    return request(`${BASE_URL}/add-to-user`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function reprocessDocument(data) {
    return request(`${BASE_URL}/reprocess`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function removeFromUser(data) {
    return request(`${BASE_URL}/remove-from-user`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function downloadFile(id) {
    return fetch(`${BASE_URL}/download/${id}`);
}

export async function getDocument({ id, isFixed = false } = {}) {
    return request(`${BASE_URL}/download/${id}`, {
        responseType: 'arrayBuffer',
        headers: {},
        params: { fixed: isFixed },
    });
}

export async function getDocumentByPath(fileName) {
    return request(fileName, {
        responseType: 'arrayBuffer',
        headers: {},
    });
}

export async function downloadSingleFile({ id }) {
    const res = await downloadFile(id);
    const [, fileName] = res.headers.get('content-disposition')?.match(/filename="(.*)"/) || [];
    const blob = await res.blob();
    download(blob, fileName);
}

export async function deleteSingleFile({ id }) {
    return request(`${BASE_URL}/${id}`, {
        method: 'DELETE',
    });
}

export async function addToLoanDocument({ documentId, loanDetailsId }) {
    return request(`${BASE_URL}/add-to-loan`, {
        method: 'POST',
        body: JSON.stringify({
            documentId,
            loanDetailsId,
        }),
    });
}

export async function addToLoanDocumentRequest({ documentId, loanRequestId }) {
    return request(`${BASE_URL}/add-to-request`, {
        method: 'POST',
        body: JSON.stringify({
            documentId,
            loanRequestId,
        }),
    });
}

export async function addToMemberDocument({ documentId, memberId }) {
    return request(`${BASE_URL}/add-to-member`, {
        method: 'POST',
        body: JSON.stringify({
            documentId,
            memberId,
        }),
    });
}

export async function addToLenderDocument({ documentId, lenderId }) {
    return request(`${BASE_URL}/add-to-lender`, {
        method: 'POST',
        body: JSON.stringify({
            documentId,
            lenderId,
        }),
    });
}

export async function addToPortfolioDocument({ documentId, parcelId, countyId }) {
    return request(`${BASE_URL}/add-to-portfolio`, {
        method: 'POST',
        body: JSON.stringify({
            documentId,
            parcelId,
            countyId,
        }),
    });
}

export async function uploadToLoanDocument({ name, type, date, pages, loanId, sheetNumber, file }) {
    return request(`${BASE_URL}/upload-to-loan`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            loanId,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function uploadDocumentsToMember({ name, type, date, pages, memberId, sheetNumber, file }) {
    return request(`${BASE_URL}/upload-to-member`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            memberId,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function uploadDocumentsToLender({ name, type, date, pages, lenderId, sheetNumber, file }) {
    return request(`${BASE_URL}/upload-to-lender`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            lenderId,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function uploadDocumentsToRequest({ name, type, date, pages, loanRequestId, sheetNumber, file }) {
    return request(`${BASE_URL}/upload-to-request`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            loanRequestId,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function uploadDocumentsToProperty({
    countyId,
    parcelId,
    name,
    type,
    date,
    pages,
    sheetNumber,
    file,
    propertyId,
    propertyBuildingId,
}) {
    return request(`${BASE_URL}/upload-to-property/${countyId}/${parcelId?.replace('/', '_SLASH_')}`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            sheetNumber,
            propertyId,
            propertyBuildingId,
        },
        body: file,
        headers: {},
    });
}

export async function uploadDocumentsToTodo({ name, type, date, pages, toDoId, sheetNumber, file }) {
    return request(`${BASE_URL}/upload-to-todo`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            toDoId,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function uploadDocumentsToDraw({ name, type, date, pages, drawId, sheetNumber, file }) {
    return request(`${BASE_URL}/upload-to-draw`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            drawId,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function uploadGeneratedDocuments({ propertyBuildingId, propertyId, name, type, date }) {
    return request(`${BASE_URL}/generate`, {
        method: 'POST',
        body: JSON.stringify({ propertyBuildingId, propertyId, name, type, date }),
    });
}

export async function uploadDocument({ name, type, date, pages, sheetNumber, file }) {
    return request(`${BASE_URL}/upload`, {
        method: 'POST',
        params: {
            name,
            type,
            date,
            pages,
            sheetNumber,
        },
        body: file,
        headers: {},
    });
}

export async function loadDocument(id) {
    return request(`${BASE_URL}/${id}`);
}

export async function updateDocument({ id, name, type, date, pages, county, parcel, pdfParseType }) {
    return request(`${BASE_URL}/${id}`, {
        method: 'PUT',
        body: JSON.stringify({
            name,
            type,
            date,
            pages,
            county,
            parcel,
            pdfParseType,
        }),
    });
}

export async function changeDocumentStatus({ body, documentId }) {
    return request(`${BASE_URL}/${documentId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function getOsTemplates({ propertyType }) {
    return request(`${BASE_URL}/os-template/${propertyType}`);
}

export async function getSlatExcelExport({ id = '', column = 0 }) {
    return request(`${BASE_URL_SLAT_XLS_EXPORT}/${id}/${column}`, { responseType: 'response' });
}

export async function updateDocumentPropertyStatus(payload) {
    return request(`${BASE_URL}/property/status`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}

export async function processMyself({ documentId, propertyId }) {
    return request(`${BASE_URL}/process-myself/${propertyId}/${documentId}`, {
        method: 'POST',
    });
}

export async function reviewMyself({ documentId, propertyId }) {
    return request(`${BASE_URL}/review-myself/${propertyId}/${documentId}`, {
        method: 'POST',
    });
}

export async function unlockDocument({ id, message }) {
    return request(`${BASE_URL}/unlock/${id}`, {
        method: 'POST',
        body: JSON.stringify({ message }),
    });
}

export async function duplicateDocument({ id }) {
    return request(`${BASE_URL}/duplicate/${id}`, {
        method: 'POST',
    });
}

export async function deleteBulkDocuments(body) {
    return request(`${BASE_URL}/remove-all`, {
        method: 'DELETE',
        body: JSON.stringify(body),
    });
}

export async function downloadBulkDocuments(body) {
    const response = await request(`${BASE_URL}/download-all`, {
        method: 'POST',
        body: JSON.stringify(body),
        responseType: 'blob',
    });

    downloadDocument(response.response, response.file);
}

export async function shareBulkDocuments(body) {
    return request(`${BASE_URL}/add-all-to-user`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function regenerateLimitedRentRoll({rrId, denominator}) {
    return request(`/geoGekko/regenerateLimitedRentRoll`, {
        params: {
            rrId,
            denominator,
        },
    });
}