import request from '@utils/request';

const BASE_URL = '/api/v2/benchmark';

export async function getBenchmark({ parcelId, countyId, sourceParcelId, sourceCountyId, userid }) {
    return request(`${BASE_URL}/county/${countyId}/parcel/${parcelId?.replace('/', '_SLASH_')}`, {
        params: {
            sourceCounty: sourceCountyId,
            sourceParcel: sourceParcelId,
            userId: userid,
        },
    });
}
