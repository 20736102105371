import { useEffect, useState } from 'react';

import Input from '@ui/Input';

const InputBaseForm = function ({ unregister, onChange, InputProps, format, ...props }) {
    const [state, setState] = useState(format?.(props.defaultValue) || props.defaultValue);

    const localOnChange = (event) => {
        setState(format?.(event.target.value) || event.target.value);
        onChange(event);
    };

    useEffect(() => {
        onChange({ target: { value: props.defaultValue } });
        return () => {
            unregister(props.name);
        }
    }, []);
    return (
        <Input {...props} value={state} InputProps={InputProps} onChange={localOnChange} />
    );
};

export default InputBaseForm;
