import colors from '@themes/palette/export.module.scss';

import { SIZES, SHORT_SIZES, LOGOS } from './consts';

export const getLogoProps = (short: boolean, color: string, size: keyof typeof SIZES) => {
    const props = short ? SHORT_SIZES[size] : SIZES[size];

    if (short) {
        return { ...LOGOS.default, color: color || colors.white, ...props };
    }

    return { ...LOGOS.default, color, ...props };
};
