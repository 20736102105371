/* eslint-disable max-len */
import { hideNoDataSeries, unitsSetter } from '@utils/charts/formatters';
import { currencyPrefixFormatter } from '@utils/index';

import colors from '@themes/palette/export.module.scss';

import { palette } from '../helpers';
import { DROPDOWN } from '../tooltip/templates';

export const baseYAxisConfig = {
    type: 'value',
    name: '',
    nameTextStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        lineHeight: 14,
        fontSize: 11,
        color: colors['darkNeutral-500'],
        align: 'left',
    },
    axisTick: {
        show: false,
    },
    axisLine: {
        show: true,
        lineStyle: {
            color: colors['grey-200'],
        },
    },
    splitLine: {
        lineStyle: {
            width: 0.5,
        },
    },
    axisLabel: {
        color: colors['grey-300'],
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 15,
        formatter: (value) => currencyPrefixFormatter(value),
    },
};

export const baseXAxisConfig = {
    type: 'category',
    data: [],
    axisTick: {
        show: false,
    },
    axisLine: {
        lineStyle: {
            color: colors['grey-200'],
        },
    },
    axisLabel: {
        color: colors['darkNeutral-500'],
        fontFamily: 'Open Sans',
        fontSize: 11,
        lineHeight: 14,
        overflow: 'truncate',
    },
};

export const xAxisDateFormatter = {
    formatter: (value) => {
        const label = value.split(' ');
        return `{a|${label[0]}}\n  {b|${label[1]}}\n{a|${label[2] || ''}}`;
    },
    rich: {
        a: {
            color: colors['darkNeutral-500'],
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: 11,
            lineHeight: 14,
            padding: [0, 0, 0, 5],
        },
        b: {
            fontFamily: 'Open Sans',
            color: colors['grey-400'],
            fontWeight: 300,
            fontSize: 11,
            lineHeight: 14,
        },
    },
};

export const baseChartConfig = ({ isHorizontal = false, tooltipSidePosition = 'default' } = {}) => ({
    grid: {
        top: 40,
        left: 40,
        right: 30,
    },
    tooltip: {
        trigger: 'axis',
        appendToBody: true,
        extraCssText: `font-family: 'Open Sans'; pointer-events: all; background: rgba(71, 77, 102, 0.9); border-radius: 4px; color: #fff; padding: 4px; min-width: 275px; max-height: 350px; overflow: auto; box-shadow: none; border: 0`,
        position(cursorPosition, params, dom, rect, size) {
            const obj = {};
            const modifier = isHorizontal ? -60 : 30;

            const chartOrientationMap = {
                left: 0,
                right: 1,
                default: +(cursorPosition[isHorizontal ? 1 : 0] < size.viewSize[isHorizontal ? 1 : 0] / 2),
            };

            const tooltipSide = [isHorizontal ? 'bottom' : 'right', isHorizontal ? 'top' : 'left'][
                chartOrientationMap[tooltipSidePosition]
            ];

            obj[tooltipSide] =
                (chartOrientationMap[tooltipSidePosition]
                    ? cursorPosition[isHorizontal ? 1 : 0]
                    : size.viewSize[isHorizontal ? 1 : 0] - cursorPosition[isHorizontal ? 1 : 0]) + modifier;
            obj[isHorizontal ? 'left' : 'top'] = cursorPosition[isHorizontal ? 0 : 1] - 30;
            return obj;
        },
        formatter: (series) =>
            unitsSetter({
                series,
            }),
    },
    xAxis: isHorizontal ? baseYAxisConfig : baseXAxisConfig,
    yAxis: isHorizontal ? baseXAxisConfig : baseYAxisConfig,
    series: [],
    color: palette(),
});

export const basePieChartConfig = {
    grid: {
        left: 0,
        right: 0,
    },
    tooltip: {
        trigger: 'item',
        appendToBody: true,
        extraCssText: `font-family: 'Open Sans'; pointer-events: all; background: rgba(71, 77, 102, 0.9); border-radius: 4px ; color: #fff; padding: 4px; min-width: 275px; max-height: 350px; overflow: auto; box-shadow: none; border: 0`,
        formatter: (series) =>
            unitsSetter({
                series,
            }),
    },
    series: [
        {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            label: {
                position: 'outside',
                offset: [0, -15],
                show: true,
                color: colors['darkNeutral-500'],
                fontFamily: 'Open Sans',
                fontSize: 11,
                fontWeight: 600,
            },
            labelLine: {
                show: false,
            },
            data: [],
        },
    ],
    color: palette(),
};

export const dropdownTooltipConfig = (units = '', map = {}) => ({
    enterable: true,
    triggerOn: 'click',
    appendToBody: true,
    className: 'dropdownTemplate',
    formatter: (series) => {
        const filteredNoDataSeries = hideNoDataSeries(series);
        return unitsSetter({
            series: filteredNoDataSeries,
            showMarker: false,
            sorting: 'DESC',
            sName: false,
            units,
            map,
            template: DROPDOWN,
        });
    },
});

export const baseStackedTooltipConfig = (units = '') => ({
    appendToBody: true,
    formatter: (series) => {
        const filteredNoDataSeries = hideNoDataSeries(series);
        return unitsSetter({
            series: filteredNoDataSeries,
            showMarker: false,
            sorting: 'DESC',
            sName: false,
            units,
        });
    },
});
