import request from '@utils/request';

const BASE_URL = '/api/v2/pdf-export';

const TEMPLATE_MANAGE_URL = `${BASE_URL}/template`;

export async function createTemplate(body) {
    return request(TEMPLATE_MANAGE_URL, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function setTemplateAsSystem(templateId) {
    return request(`${BASE_URL}/${templateId}/set-system`, {
        method: 'GET',
    });
}

export async function getTemplates() {
    return request(TEMPLATE_MANAGE_URL);
}

export async function updateTemplate(body) {
    return request(TEMPLATE_MANAGE_URL, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function deleteTemplate(id) {
    return request(`${TEMPLATE_MANAGE_URL}/${id}`, {
        method: 'DELETE',
    });
}
