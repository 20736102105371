import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBookmark, selectBookmarkState, toggleBookmark, selectIsBookmarkChecked } from '@scc/store/ducks/bookmark';
import { selectPortfolioFromState } from '@scc/store/ducks/portfolios';

import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Tooltip from '@ui/Tooltip';

import colors from '@themes/palette/export.module.scss';

import BookmarkIcon from '@icons/outlined/bookmark-new.svg';

const defaultProps = {
    bookmarkId: null,
};

function PropertyBookmark({ parcel: { parcelid: parcelId, countyid: countyId } }) {
    const dispatch = useDispatch();
    const { bookmark } = useSelector(selectBookmarkState);
    const isWishListChecked = useSelector(selectIsBookmarkChecked);
    const { loading } = useSelector(selectPortfolioFromState);

    useEffect(() => {
        if (countyId && !bookmark?.id) {
            dispatch(fetchBookmark({ countyId, parcelId }));
        }
    }, [dispatch, bookmark?.id, countyId, parcelId]);

    const handleToggleBookmark = useCallback(() => {
        dispatch(toggleBookmark({ parcelId, countyId, id: bookmark?.id }));
    }, [dispatch, parcelId, countyId, bookmark]);

    return (
        <Tooltip title={isWishListChecked ? 'Remove from bookmarks' : 'Bookmark property'}>
            <IconButton disabled={loading} onClick={handleToggleBookmark}>
                <Icon color={colors[isWishListChecked ? 'lightBlue-500' : 'grey-400']} component={BookmarkIcon} />
            </IconButton>
        </Tooltip>
    );
}

PropertyBookmark.defaultProps = defaultProps;

export default PropertyBookmark;
