const validDomains = [/^https?:\/\/([^.]+\.)*spacequant\.com/, /^https?:\/\/([^.]+\.)*smartcapital\.center/];

export const publicURLParse = (publicURL) => {
    try {
        const url = new URL(publicURL);
        return url.pathname + url.search;
    } catch (e) {
        return publicURL;
    }
};

export const isValidDomain = (domain) => validDomains.some((validDomain) => validDomain.test(domain));