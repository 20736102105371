import { validation } from '@utils/forms';
import * as yup from 'yup';

export const registrationSchema = yup.object({
    email: validation.email,
    password: validation.newPassword,
    confirmPassword: validation.confirmPassword('password'),
});

export const verificationSchema = yup.object({
    firstName: validation.firstName,
    lastName: validation.lastName,
    phone: validation.phone,
});

export default registrationSchema;
