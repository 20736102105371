import cx from 'classnames';
import { memo } from 'react';

import styles from './styles.module.scss';

const defaultProps = {
    src: '',
    alt: '',
    className: null,
    type: 'image/jpg',
};

function Image({ src, srcSet, webp, alt, className, sizes, type, width, ...rest }) {
    return (
        <picture>
            {webp && <source sizes={sizes} srcSet={webp} type='image/webp' width={width} />}
            <source sizes={sizes} srcSet={srcSet} type={type} width={width} />
            <img
                alt={alt}
                className={cx(styles.image, {
                    [className]: className,
                })}
                sizes={sizes}
                src={src}
                srcSet={srcSet}
                width={width}
                {...rest}
            />
        </picture>
    );
}

Image.defaultProps = defaultProps;

export default memo(Image);
