const accordion = () => ({
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                display: 'flex',
                gap: 8,
                margin: 0,
            },
        },
    },
});
export default accordion;
