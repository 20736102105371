import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMapValue } from '@utils/components';

import BaseDrawer from '@scc/components/BaseDrawer';
import LoginBase from '@scc/components/LoginBase';
import RegisterFormVerification from '@scc/components/RegisterForm/RegisterFormVerification';
import { selectAuthMngState } from '@scc/store/ducks/authManager';
import { selectBuildingData, copyParcelBuilding } from '@scc/store/ducks/building';
import { selectPropertyState, setStepProperty, setStepperProperty } from '@scc/store/ducks/property';

import Typography from '@ui/Typography';

import Controls from './Controls';
import GetValuationStep from './GetValuationStep';
import OrderCreateSuccess from './OrderCreateSuccess';
import Stepper from './Stepper';
import styles from './styles.module.scss';
import UploadDocumentStep from './UploadDocumentStep';

const stepperMap = new Map([
    ['register', LoginBase],
    ['upload', UploadDocumentStep],
    ['valuation', GetValuationStep],
    ['verification', RegisterFormVerification],
    ['sent', OrderCreateSuccess],
]);

function ValuationProcess() {
    const dispatch = useDispatch();
    const { address } = useSelector(selectBuildingData) || {};
    const { stepper, step, type } = useSelector(selectPropertyState);
    const { template } = useSelector(selectAuthMngState);
    const formId = 'payment-order';

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const steps = useMemo(
        () => [
            {
                step: 'register',
                title: template === 'register' ? 'Create Account' : 'Login',
            },
            {
                step: 'upload',
                title: 'Upload Documents',
            },
            {
                step: 'valuation',
                label: type === 'loan' ? 'Get Best Loan Quotes' : 'Get Valuation',
            },
        ],
        [type, template]
    );

    /* eslint-disable no-shadow */
    const handleStepChange = useCallback((step) => dispatch(setStepProperty(step)), [dispatch]);

    const handleStepperChange = useCallback(
        (num = 0) =>
            () =>
                dispatch(setStepperProperty(stepper + num)),
        [dispatch, stepper]
    );

    const successAuth = () => {
        dispatch(copyParcelBuilding());
    };

    useEffect(() => {
        dispatch(setStepProperty(steps[stepper]?.step));
    }, [dispatch, stepper, steps]);

    const Content = getMapValue(stepperMap, step, 'register');

    const contentProps = {
        steps,
        step,
        stepper,
        onStepChange: handleStepChange,
        onStepperChange: handleStepperChange,
    };

    return (
        <BaseDrawer
            anchor='right'
            header={type === 'loan' ? 'Get Loan Quotes' : 'Get Detailed Valuation'}
            headerClassName={styles.header}
            paperProps={{
                className: isMobile ? styles.mobileDrawer : null,
            }}
            subHeader={address}
        >
            <Stepper active={stepper} className={styles.stepper} steps={steps} />
            {steps[stepper]?.title && !isMobile && (
                <Typography textAlign='center' variant='h1b' gutterBottom>
                    {steps[stepper]?.title}
                </Typography>
            )}
            <Content
                controls={<Controls orderProps={{ type: 'submit', form: formId }} {...contentProps} />}
                formId={formId}
                onSuccess={successAuth}
                {...contentProps}
            />
        </BaseDrawer>
    );
}

export default ValuationProcess;
