import { useSelector } from 'react-redux';

import { isServer } from '@utils/browser';

import RecentlyViewedButton from '@scc/components/RecentlyViewedButton';
import { selectBuildingState } from '@scc/store/ducks/building';

import Breadcrumbs from '@ui/Breadcrumbs';
import Link from '@ui/Link';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import SimilarBuildings from '../SimilarBuildings';

import styles from './footer.module.scss';

const serverBreadcrumbs = Array(3)
    .fill()
    .map((item, i) => ({
        label: `<c:out value="\${breadcrumbList[${i}].label}"/>`,
        link: i < 2 ? `<c:out value="\${breadcrumbList[${i}].link}"/>` : undefined,
    }));

function Footer() {
    let { breadcrumbs } = useSelector(selectBuildingState);

    if (isServer) {
        breadcrumbs = serverBreadcrumbs;
    }

    return (
        <div className={styles.footerContainer}>
            <Breadcrumbs separator='|'>
                {[{ link: '/cap-rates-and-loans', label: 'USA' }, ...breadcrumbs].map((item) =>
                    item.link ? (
                        <Link key={item.label} color='inherit' href={item.link}>
                            <Typography color={colors['grey-400']} variant='smr'>
                                {item.label}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography key={item.label} color={colors['grey-400']} variant='smr'>
                            {item.label}
                        </Typography>
                    )
                )}
            </Breadcrumbs>

            <div className={styles.interestedContainer}>
                <div className={styles.countInterested} />
                <RecentlyViewedButton />
            </div>
            <SimilarBuildings />
        </div>
    );
}

export default Footer;
