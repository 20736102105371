import { Box, Skeleton } from '@mui/material';
import { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import ItemList from '@components/ItemList';

import { EmptyList } from './components/EmptyList';
import styles from './styles.module.scss';

export type InfinityDropdownProps<T> = {
    onChange: (value: T) => void;
    data: T[];
    loadMoreHandler: () => void;
    total: number;
    isLoading: boolean;
    selected: T | T[];
    renderOption: (item: T, isSelected?: boolean) => ReactNode;
    findKey?: string;
    height?: string;
};

export function InfinityDropdown<T extends Record<string, unknown> = Record<string, unknown>>({
    onChange,
    loadMoreHandler,
    data,
    total,
    renderOption,
    isLoading,
    findKey = 'id',
    selected,
    height = '400px',
}: InfinityDropdownProps<T>) {
    const hasMore = data.length < total;

    return (
        <Box className={styles.wrapper}>
            <InfiniteScroll
                dataLength={data.length}
                hasMore={hasMore}
                height={height}
                loader={
                    isLoading ? (
                        <Box
                            sx={{
                                paddingBlock: '10px',
                            }}
                        >
                            <Skeleton height={30} variant='rounded' />
                        </Box>
                    ) : null
                }
                next={loadMoreHandler}
            >
                <Box className={styles.list}>
                    {data.length === 0 && <EmptyList isLoading={isLoading} />}
                    <ItemList<T> items={data}>
                        {(item) => {
                            const isSelect = Array.isArray(selected)
                                ? Boolean(selected.find((el) => el[findKey] === item[findKey]))
                                : selected[findKey] === item[findKey];

                            return (
                                <Box
                                    key={item[findKey] as string}
                                    data-qa={`find-item-${item[findKey]}`}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onChange(item);
                                    }}
                                >
                                    {renderOption(item, isSelect)}
                                </Box>
                            );
                        }}
                    </ItemList>
                </Box>
            </InfiniteScroll>
        </Box>
    );
}
