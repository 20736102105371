import Icon from '@ui/Icon';

import ArrowDownSvg from '@icons/outlined/arrow-down.svg';

const select = () => ({
    MuiSelect: {
        defaultProps: {
            IconComponent: (props) => <Icon component={ArrowDownSvg} {...props} color='#000' fontSize={12} />,
        },
        styleOverrides: {
            standard: {
                '&.MuiSelect-select-icon': {
                    color: '#000',
                },
            },
            outlined: {
                paddingRight: '30px !important',
            },
        },
    },
});

export default select;
