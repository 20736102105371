import { InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import cx from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import ChevronIcon from '@scc/assets/icons/chevron.svg';
import { decimalValueValidator } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/helpers';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import OutlinedInput from '@ui/OutlinedInput';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import PlusIcon from '@icons/filled/plus.svg';
import DeleteIconOutlined from '@icons/outlined/delete.svg';

import styles from './styles.module.scss';

export type PrepaymentProtection = {
    amount: number;
    years: number;
    id: string;
};

type PrepaymentProtectionInputProps = {
    value: PrepaymentProtection[];
    onSuccess: (value: PrepaymentProtection[]) => void;
    className: never;
};

const convertValues = (values: PrepaymentProtection[]) => {
    return values.map((item) => {
        return {
            ...item,
            amount: Number(item.amount),
        };
    });
};

function PrepaymentProtectionInput({ value: originalValue, onSuccess, ...inputProps }: PrepaymentProtectionInputProps) {
    const [isOpened, setIsOpened] = useState(false);
    const [values, setValue] = useState(originalValue);

    const [anchorRef, setAnchor] = useState<HTMLDivElement | null>(null);

    const handleClose = () => {
        setIsOpened(false);
        setValue(originalValue);
    };

    const onAddNew = () => {
        setValue((prev) => [...prev, { amount: 0, years: 0, id: uuid() }]);
    };

    const onDelete = (id: string) => {
        setValue((prev) => prev.filter((item) => item.id !== id));
    };

    const onChange = (id: string, key: keyof PrepaymentProtection, value: number) => {
        setValue((prev) => prev.map((item) => (item.id === id ? { ...item, [key]: value } : item)));
    };

    const onSave = () => {
        onSuccess(convertValues(values));
        handleClose();
    };

    const renderValue = (valueToRender: PrepaymentProtection[]) => {
        return valueToRender
            .map((value) => {
                return `${value.amount}% ${value.years}y`;
            })
            .join('; ');
    };

    useEffect(() => {
        setValue(originalValue);
    }, [originalValue]);

    return (
        <>
            <OutlinedInput
                className={cx(inputProps.className, styles.input, { [styles.inputOpen]: isOpened })}
                endAdornment={
                    <InputAdornment position='end'>
                        <Icon
                            className={cx({
                                [styles.iconOpen]: isOpened,
                            })}
                            color={colors['grey-400']}
                            component={ChevronIcon}
                            fontSize={20}
                        />
                    </InputAdornment>
                }
                size='small'
                value={renderValue(originalValue)}
                variant='noOutlined'
                fullWidth
                readOnly
                onClick={(event) => {
                    setIsOpened(true);
                    setAnchor(event.currentTarget);
                }}
            />
            <Popover
                anchorEl={anchorRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isOpened}
                sx={{
                    '&& .MuiPopover-paper': {
                        minWidth: anchorRef?.clientWidth,
                    },
                }}
                onClose={handleClose}
            >
                <Box>
                    <Box className={styles.menuList}>
                        {values.map((value) => (
                            <Box key={value.id} className={styles.menuItem}>
                                <OutlinedInput
                                    className={styles.outlinedInput}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <Typography color={colors['grey-400']} variant='smr'>
                                                %
                                            </Typography>
                                        </InputAdornment>
                                    }
                                    value={value.amount}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        if (decimalValueValidator(event)) {
                                            onChange(value.id, 'amount', event.target.value);
                                        }
                                    }}
                                />
                                <OutlinedInput
                                    className={styles.outlinedInput}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <Typography color={colors['grey-400']} variant='smr'>
                                                years
                                            </Typography>
                                        </InputAdornment>
                                    }
                                    value={value.years}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        if (decimalValueValidator(event)) {
                                            onChange(value.id, 'years', Number(event.target.value));
                                        }
                                    }}
                                />
                                <IconButton className={styles.button} onClick={() => onDelete(value.id)}>
                                    <Icon color={colors['grey-500']} component={DeleteIconOutlined} fontSize={16} />
                                </IconButton>
                            </Box>
                        ))}
                        <Button variant='outlined' fullWidth onClick={onAddNew}>
                            <Icon color={colors['grey-400']} component={PlusIcon} fontSize={20} />
                        </Button>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 5,
                        }}
                    >
                        <Button variant='outlined' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button color='primary' variant='contained' onClick={onSave}>
                            Add
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
}

export default PrepaymentProtectionInput;
