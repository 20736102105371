import { useState } from 'react';

import Typography from '@ui/Typography';

import EditablePercent from '@components/DataTable/Cells/CapRateGapsCell/components/EditablePercent';
import {
    EDITABLE_SCORE_PARAMETER_LABELS,
    LETTER_SCORE_PARAMETER_LABELS,
    PERCENT_SCORE_PARAMETER_LABELS,
} from '@components/DataTable/constants';
import Percent from '@components/Percent';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

const letterMap = {
    100: 'A',
    60: 'B',
    40: 'C',
    20: 'D',
};

function ScoringParameterValue({
    value,
    row: { original },
    onUpdateData,
    cell: { column: { id: accessorName } = {} },
}) {
    const [isVisibleArrows, setIsVisibleArrows] = useState(false);
    let resComponent;

    const onChange = ({ name, value: changedValue }) => {
        onUpdateData({ cell: { name, value: changedValue }, original });
    };

    if (LETTER_SCORE_PARAMETER_LABELS[original.lineName]) {
        resComponent = (
            <Typography color={colors['darkNeutral-400']} variant='h6r'>
                {letterMap[value]}
            </Typography>
        );
    } else if (EDITABLE_SCORE_PARAMETER_LABELS[original.lineName]) {
        resComponent = (
            <div onMouseLeave={() => setIsVisibleArrows(false)} onMouseOver={() => setIsVisibleArrows(true)}>
                <EditablePercent
                    amountFontVariant='hr12'
                    labelFontVariant='hr12'
                    name={accessorName}
                    value={value}
                    visibleArrows={isVisibleArrows}
                    withLabel={false}
                    withoutDecimals
                    onSuccess={onChange}
                />
            </div>
        );
    } else if (PERCENT_SCORE_PARAMETER_LABELS[original.lineName]) {
        resComponent = (
            <Percent
                amountColor={colors['darkNeutral-400']}
                amountFontVariant='hr12'
                labelColor={colors['grey-400']}
                labelFontVariant='hr12'
            >
                {value === 'NaN'
                    ? 'n/a'
                    : Number(value)
                          .toFixed(
                              ['debtYield', 'ltv'].includes(original.lineName)
                                  ? 0
                                  : original.lineName === 'coupon'
                                  ? 4
                                  : 1
                          )
                          .toString()}
            </Percent>
        );
    } else {
        resComponent = (
            <Typography color={colors['darkNeutral-400']} variant='h6r'>
                {value}
            </Typography>
        );
    }

    return <div className={styles.flexWrapper}>{resComponent}</div>;
}

export default ScoringParameterValue;
