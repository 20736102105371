import { put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { showAlert } from '@store/ducks/ui/mainAlert';

const entity = '[memory]';

const initialState = {};

const memorySlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        add(state, action) {
            state[action.payload.entity] = action.payload.data;
        },
        resetOne(state, action) {
            delete state[action.payload];
        },
        resetAll: () => initialState,
    },
});

export const { add: addMemory, resetAll: resetMemory, resetOne: resetOneMemory } = memorySlice.actions;

export default memorySlice.reducer;

export const selectMemoryState = (store) => store.memory;

function* mainListener({ payload: { data, entity } }) {
    if (entity) {
        yield put(addMemory({ data, entity }));
    }
}

export function* watchMemory() {
    yield takeEvery([showAlert], mainListener);
}
