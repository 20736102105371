import { createSlice } from '@reduxjs/toolkit';
import { put, select, delay, take, takeLatest } from 'redux-saga/effects';

import { propertiesApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

export const VR_STATUSES = {
    NEW: 'NEW',
    CREATED: 'CREATED',
    SAVED: 'SAVED',
};

const prepareVarianceReportData = (statementsTableData, varianceReportName, varianceReportOptions) => {
    const { headers, rows, ...rest } = statementsTableData;

    const [
        { count: count1, month: month1, year: year1, dateLabel: dateLabel1 = undefined } = {},
        { count: count2, month: month2, year: year2, dateLabel: dateLabel2 = undefined } = {},
    ] = varianceReportOptions.map((item) => item.option);

    const [firstHeader, ...restHeaders] = headers;
    const headersIndexes = [];

    const filteredHeaders = restHeaders.filter((header, index) => {
        const { count: headerCount, month: headerMonth, year: headerYear, dateLabel: headerDateLabel } = header;
        if (
            (headerDateLabel === dateLabel1 &&
                headerCount === count1 &&
                headerMonth === month1 &&
                headerYear === year1) ||
            (headerDateLabel === dateLabel2 && headerCount === count2 && headerMonth === month2 && headerYear === year2)
        ) {
            headersIndexes.push(index);
            return true;
        }
        return false;
    });
    const resHeaders = [firstHeader, ...filteredHeaders];

    const resRows = rows.map(({ values, ...row }) => {
        const resValues = headersIndexes.map((index) => values[index]);
        return { ...row, values: resValues };
    });

    return { headers: resHeaders, rows: resRows, ...rest };
};

const entity = '[varianceReport]';

const initialState = {
    message: null,
    documentId: null,
    isCombined: false,
    report: null,
    loaded: false,
    loading: false,
    vrStatus: VR_STATUSES.NEW,
    reports: [],
    varianceReportOptions: [],
    statementsTable: {},
    statementsOriginalTable: {},
    rentRollOriginalTable: {},
    rentRollLimitedTable: {}
};

const varianceReportSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        prepare(state, action) {
            const { options, activeDocument } = action.payload;
            state.loaded = false;
            state.isNewReport = true;
            state.isCombined = activeDocument?.combined;
            state.documentId = activeDocument?.combined ? activeDocument.id : activeDocument?.entityId;
            state.varianceReportOptions = options;
        },
        updateReport() {},
        createReport() {},
        removeReport() {},
        setReport(state, action) {
            if (action.payload?.report) {
                state.report = action.payload.report;
                state.vrStatus = VR_STATUSES.SAVED;
                state.varianceReportOptions = action.payload.report.documentJson?.varianceReportOptions || [];
            } else {
                state.vrStatus = VR_STATUSES.CREATED;
                state.report = { ...action.payload };
                if (action.payload?.id) {
                    state.reports = state.reports.map((report) => {
                        if (report.id === action.payload.id) {
                            return action.payload;
                        }
                        return report;
                    });
                }
                state.varianceReportOptions = action.payload?.documentJson?.varianceReportOptions || [];
            }
        },
        loadReports(state) {
            state.loading = true;
        },
        setReports(state, action) {
            state.reports = action.payload;
            state.loaded = true;
        },
        setVRStatus(state, action) {
            state.vrStatus = action.payload;
        },
        setStatementsTable(state, action) {
            state.statementsTable = action.payload.statementsTable ?? {};
            state.statementsOriginalTable = action.payload.statementsOriginalTable ?? {};
            state.rentRollOriginalTable = action.payload.rentRollOriginalTable ?? {};
            state.rentRollLimitedTable = action.payload.rentRollLimitedTable ?? {};
        },
        resetState() {
            return initialState;
        },
        resetReport(state) {
            state.varianceReportOptions = [];
            state.vrStatus = VR_STATUSES.NEW;
            state.report = null;
        },
    },
});

export const {
    prepare: prepareVarianceReport,
    updateReport: updateVarianceReport,
    createReport: createVarianceReport,
    setReport: setVarianceReport,
    setVRStatus: setVarianceReportStatus,
    removeReport: removeVarianceReport,
    loadReports: loadVarianceReports,
    setReports: setVarianceReports,
    setStatementsTable: setStatementsTableGrid,
    resetState: resetVarianceReportState,
    resetReport: resetVarianceReport,
} = varianceReportSlice.actions;

export default varianceReportSlice.reducer;

export const selectVarianceReportState = (store) => store.portfolio.varianceReport;

export const selectVariancesReports = (store) => store.portfolio.varianceReport.reports;

function* createVarianceReportSaga({ type, payload }) {
    const { parcelId, countyId, ...statementsTable } = payload;

    const {
        varianceReportOptions,
        varianceReportName = '',
        documentId,
        isCombined,
    } = yield select(selectVarianceReportState);
    const documentJson = prepareVarianceReportData(statementsTable, varianceReportName, varianceReportOptions);
    documentJson.varianceReportOptions = varianceReportOptions;
    const resVarianceReport = {
        name: varianceReportName,
        parcelId,
        countyId,
        documentJson,
    };
    if (isCombined) {
        resVarianceReport.combinedId = documentId;
    } else {
        resVarianceReport.osId = documentId;
    }

    yield put(apiRequest(resVarianceReport, propertiesApi.createVarianceReports, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setVarianceReport(data));
    yield put(loadVarianceReports({ parcelId, countyId }));
    yield delay(3000);
    yield put(setVarianceReportStatus(VR_STATUSES.SAVED));
}

function* removeVarianceReportSaga({ type, payload }) {
    const { reports } = yield select(selectVarianceReportState);
    yield put(apiRequest(payload, propertiesApi.deleteVarianceReport, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(setVarianceReports(reports.filter((report) => report.id !== payload)));
}

function* updateVarianceReportSaga({ type, payload }) {
    const {
        report: {
            parcelId,
            countyId,
            id,
            osId,
            combinedId,
            documentJson: { varianceReportOptions },
        },
    } = yield select(selectVarianceReportState);

    const documentJson = payload;
    documentJson.varianceReportOptions = varianceReportOptions;
    const resVarianceReport = {
        id,
        parcelId,
        countyId,
        documentJson,
    };
    if (combinedId) {
        resVarianceReport.combinedId = combinedId;
    } else {
        resVarianceReport.osId = osId;
    }

    yield put(apiRequest(resVarianceReport, propertiesApi.updateVarianceReports, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setVarianceReport(data));
    yield delay(3000);
    yield put(setVarianceReportStatus(VR_STATUSES.SAVED));
}

function* loadVarianceReportsSaga({ type, payload }) {
    yield put(apiRequest(payload, propertiesApi.getVarianceReports, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setVarianceReports(data));
}

export function* watchVarianceReport() {
    yield takeLatest(updateVarianceReport, updateVarianceReportSaga);
    yield takeLatest(removeVarianceReport, removeVarianceReportSaga);
    yield takeLatest(createVarianceReport, createVarianceReportSaga);
    yield takeLatest(loadVarianceReports, loadVarianceReportsSaga);
}
