import colors from '@themes/palette/export.module.scss';

const linearProgress = () => ({
    MuiLinearProgress: {
        styleOverrides: {
            colorPrimary: {
                backgroundColor: colors['grey-A200'],
            },
            bar1Determinate: {
                backgroundColor: colors['chart-01'],
            },
        },
    },
});

export default linearProgress;
