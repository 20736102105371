import { useState, useEffect } from 'react';
import Typography from '@ui/Typography';
import ItemList from '@components/ItemList';
import SearchPropertyItem from '@shared/components/SearchPropertyItem';
import Close from '@scc/assets/icons/close.svg';
import { historyApi } from '@api';
import dayjs from 'dayjs';
import IconButton from '@ui/IconButton';
import { createEmptyData } from '@utils/';

import styles from './styles.module.scss';

const RecentlyViewed = function ({ handleClose, updatePosition }) {
    const [historyArray, setHistoryArray] = useState(createEmptyData({ quantity: 3, prefix: 'skeleton' }));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        historyApi.getHistory().then((res) => {
            setIsLoading(false);
            if (res?.data?.content) {
                const historyData = res.data.content.map((i) => {
                    const date = dayjs(i.updatedAt).format('DD MMM, YYYY');
                    return { ...i, date };
                });
                setHistoryArray(historyData);
            } else {
                setHistoryArray([]);
            }
            if (updatePosition) {
                updatePosition()
            }
        });
    }, []);

    const notFoundMessage = <span>Recently viewed not found</span>;

    return (
        <>
            <div className={styles.headerWrapper}>
                <Typography variant='h2b'>Recently Viewed</Typography>
                <IconButton component={Close} onClick={handleClose} />
            </div>
            <ItemList items={historyArray} alt={notFoundMessage}>
                {({ id, publicUrl, address, propertyType }) => {
                    const [mainText, ...secondaryTextArray] = address?.split(', ') || [];
                    return (
                        <SearchPropertyItem
                            mainText={mainText}
                            propertyType={propertyType}
                            publicUrl={publicUrl}
                            secondaryText={secondaryTextArray.join(', ')}
                            id={id}
                            isLoading={isLoading}
                        />
                    );
                }}
            </ItemList>
        </>
    );
};

export default RecentlyViewed;
