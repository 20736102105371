import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { detailedProperty, userApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { hideDrawer } from '@store/ducks/ui/drawer';

const entity = '[portfolio/userRequests]';

const initialState = {
    userRequests: [],
    loading: false,
};

const userRequestsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
        },
        set(state, action) {
            state.userRequests = action.payload;
            state.loading = false;
        },
        setItem(state, action) {
            const { id } = action.payload;
            const index = state.userRequests.findIndex((item) => item.id === id);
            state.userRequests[index] = {
                ...state.userRequests[index],
                ...action.payload,
            };
        },
        send() {},
    },
});

export const {
    load: loadUserRequests,
    set: setUserRequests,
    setItem: setItemUserRequests,
    send: sendUserRequests,
} = userRequestsSlice.actions;

export default userRequestsSlice.reducer;

export const selectUserRequestsState = (state) => state.portfolio.userRequests;

function* loadUserRequestsSaga({ type, payload }) {
    const { propertyId } = payload;
    yield put(apiRequest(propertyId, userApi.getUserRequestsByPropertyId, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setUserRequests(action.payload.data));
}

function* sendUserRequestsSaga({ type, payload }) {
    const { propertyId } = payload;
    yield put(apiRequest(payload, detailedProperty.sendEmailRequests, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadUserRequests({ propertyId }));
    yield put(hideDrawer());
}

export function* watchUserRequests() {
    yield takeLatest(loadUserRequests, loadUserRequestsSaga);
    yield takeLatest(sendUserRequests, sendUserRequestsSaga);
}
