import { Link, useLocation } from 'react-router-dom';

import links from '@scc/router/links';

import Button from '@ui/Button';
import Container from '@ui/Container';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { DEMO_DATA } from './helpers';
import styles from './styles.module.scss';

function EnterpriseTop({ mobile }) {
    const location = useLocation();

    const { title, buttonLabel } = DEMO_DATA;

    return (
        <div className={styles.wrapper}>
            <Container maxWidth='xl'>
                <div className={styles.container}>
                    <Typography className={styles.title} color={colors.white} component='span' variant='h1b'>
                        {title}
                    </Typography>

                    <Button
                        aria-label={buttonLabel}
                        className={styles.button}
                        component={Link}
                        fullWidth={mobile}
                        rel='nofollow'
                        size='extra-large'
                        to={{
                            state: { from: location },
                            pathname: links.requestDemo.path,
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </Container>
        </div>
    );
}

export default EnterpriseTop;
