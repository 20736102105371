import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { isActiveRoute } from '@utils/router';

import pages from '@scc/router/links';
import { selectIsUserAdmin, selectIsUserDemo, selectIsUserLender } from '@scc/store/ducks/auth';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import List from '@ui/List';
import ListItemIcon from '@ui/ListItemIcon';
import ListItemText from '@ui/ListItemText';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import { ADD_ITEMS_FOR_DEMO_PURPOSES, ADMIN_ONLY, ENTERPRISE_TABS_LIST, NON_ENTERPRISE_TABS_LIST } from './helpers';
import styles from './styles.module.scss';

export function MenuItemsList({ items = [], iconsMap = {}, hideLabel, iconSize, className, navRef }) {
    const { pathname } = useLocation();
    const isUserLender = useSelector(selectIsUserLender);
    const isUserAdmin = useSelector(selectIsUserAdmin);
    const isUserDemo = useSelector(selectIsUserDemo);
    const ROLE_BASED_LIST = isUserLender ? ENTERPRISE_TABS_LIST : NON_ENTERPRISE_TABS_LIST;
    const nonAdminFilteredItems = isUserAdmin ? items : items.filter((page) => !ADMIN_ONLY.includes(page.name));

    const filteredItems = isUserAdmin
        ? nonAdminFilteredItems
        : nonAdminFilteredItems.filter((page) => ROLE_BASED_LIST.includes(page.name));

    // if this is demo account we may extend our role based list of features
    if (isUserDemo) {
        ADD_ITEMS_FOR_DEMO_PURPOSES.forEach((demoPage) => {
            if (!filteredItems.find((page) => page.name === demoPage.name)) {
                filteredItems.push(demoPage);
            }
        });
    }

    return (
        <List
            className={cx(styles.listWrapper, className, {
                [styles.collapsed]: hideLabel,
            })}
            component='nav'
            ref={navRef}
        >
            <ItemList items={filteredItems}>
                {({ name, path, label, displayLabel, exact, ...props }) => {
                    const isActive = isActiveRoute(pathname, path, exact);

                    return (
                        <Button
                            key={name}
                            className={cx(styles.lineItemButton, {
                                [styles.lineItemButtonSpacing]: [pages.search.name, pages.history2.name].includes(name),
                                [styles.active]: isActive,
                            })}
                            color={isActive ? 'primary' : 'text'}
                            component={Link}
                            size='small'
                            to={path}
                            {...props}
                        >
                            {iconsMap[name] && (
                                <ListItemIcon
                                    color='inherit'
                                    sx={{
                                        minWidth: `${iconSize}px`,
                                        marginRight: hideLabel ? '0' : '8px',
                                        padding: '5px 0',
                                    }}
                                >
                                    <Icon
                                        color={isActive ? 'white' : colors['grey-400']}
                                        component={iconsMap[name]}
                                        fontSize={iconSize}
                                    />
                                </ListItemIcon>
                            )}
                            {!hideLabel && (
                                <ListItemText>
                                    <Typography
                                        color={isActive ? 'white' : colors['darkNeutral-500']}
                                        variant={isActive ? 'smb' : 'smr'}
                                    >
                                        {displayLabel ?? label}
                                    </Typography>
                                </ListItemText>
                            )}
                        </Button>
                    );
                }}
            </ItemList>
        </List>
    );
}
