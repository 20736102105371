import { baseApi as api } from '../configs/grid';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        createVarianceReport: build.mutation<CreateVarianceReportApiResponse, CreateVarianceReportApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property/variance-report`,
                method: 'POST',
                body: queryArg.createVarianceReportRequest,
            }),
        }),
        checkProformaData: build.query<CheckProformaDataApiResponse, CheckProformaDataApiArg>({
            query: (queryArg) => ({ url: `/api/v2/grid/statement/preprocess/proforma/status/${queryArg.propertyId}` }),
        }),
        saveOriginalStatement: build.mutation<SaveOriginalStatementApiResponse, SaveOriginalStatementApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/save-original`,
                method: 'POST',
                body: queryArg.originalStatement,
            }),
        }),
        normalizeNoi: build.mutation<NormalizeNoiApiResponse, NormalizeNoiApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/normalize-noi`,
                method: 'POST',
                body: queryArg.standardizedStatement,
            }),
        }),
        regenerateOriginalStatement: build.mutation<
            RegenerateOriginalStatementApiResponse,
            RegenerateOriginalStatementApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/regenerate-original`,
                method: 'POST',
                body: queryArg.originalStatement,
                params: { regenerateAll: queryArg.regenerateAll, breakdown: queryArg.breakdown },
            }),
        }),
        preprocessLoanProforma: build.query<PreprocessLoanProformaApiResponse, PreprocessLoanProformaApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/loan-proforma/${queryArg.loanId}`,
                params: { proformaAsOfDate: queryArg.proformaAsOfDate, clearDate: queryArg.clearDate },
            }),
        }),
        saveLoanProforma: build.mutation<SaveLoanProformaApiResponse, SaveLoanProformaApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/loan-proforma/${queryArg.loanId}`,
                method: 'POST',
                body: queryArg.combinedStatement,
            }),
        }),
        preprocessStatement: build.query<PreprocessStatementApiResponse, PreprocessStatementApiArg>({
            query: (queryArg) => ({ url: `/api/v2/grid/statement/preprocess/${queryArg.statementId}` }),
        }),
        preprocessOriginalStatement: build.query<
            PreprocessOriginalStatementApiResponse,
            PreprocessOriginalStatementApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/grid/statement/preprocess/original/${queryArg.statementId}` }),
        }),
        preprocessCurentDevBudget: build.query<PreprocessCurentDevBudgetApiResponse, PreprocessCurentDevBudgetApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/devbudget/current/${queryArg.propertyId}`,
            }),
        }),
        preprocessCombinedStatement: build.query<
            PreprocessCombinedStatementApiResponse,
            PreprocessCombinedStatementApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/grid/statement/preprocess/combined/${queryArg.combinedOsid}` }),
        }),
        saveCombined: build.mutation<SaveCombinedApiResponse, SaveCombinedApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/combined/${queryArg.combinedOsid}`,
                method: 'POST',
                body: queryArg.combinedStatement,
            }),
        }),
        preprocessProforma: build.query<PreprocessProformaApiResponse, PreprocessProformaApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/proforma/${queryArg.propertyId}`,
                params: {
                    combinedColumnId: queryArg.combinedColumnId,
                    rrId: queryArg.rrId,
                    proformaAsOfDate: queryArg.proformaAsOfDate,
                    clearDate: queryArg.clearDate,
                    regenerateRefIDs: queryArg.regenerateRefIDs,
                },
            }),
        }),
        saveProforma: build.mutation<SaveProformaApiResponse, SaveProformaApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/proforma/${queryArg.propertyId}`,
                method: 'POST',
                body: queryArg.combinedStatement,
            }),
        }),
        downloadAdjustments: build.mutation<DownloadAdjustmentsApiResponse, DownloadAdjustmentsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/export/preprocess/combined/${queryArg.combinedOsid}/adjustments`,
                method: 'POST',
                body: queryArg.financialColumn,
            }),
        }),
        saveStatement: build.mutation<SaveStatementApiResponse, SaveStatementApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/save`,
                method: 'POST',
                body: queryArg.body,
                params: { autoConfirm: queryArg.autoConfirm },
            }),
        }),
        addCombinedYearColumn: build.mutation<AddCombinedYearColumnApiResponse, AddCombinedYearColumnApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/grid/statement/preprocess/combined-column`,
                method: 'PUT',
                body: queryArg.addStatementColumnRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type CreateVarianceReportApiResponse = /** status 200 OK */ GenericJsonResponseVarianceReportDto;
export type CreateVarianceReportApiArg = {
    createVarianceReportRequest: CreateVarianceReportRequest;
};
export type CheckProformaDataApiResponse = /** status 200 OK */ GenericJsonResponseProformaDataStatus;
export type CheckProformaDataApiArg = {
    propertyId: number;
};
export type SaveOriginalStatementApiResponse =
    /** status 200 OK */ GenericJsonResponsePreprocessedStandardizedStatement;
export type SaveOriginalStatementApiArg = {
    originalStatement: OriginalStatementWrite;
};
export type NormalizeNoiApiResponse = /** status 200 OK */ GenericJsonResponsePreprocessedStandardizedStatement;
export type NormalizeNoiApiArg = {
    standardizedStatement: StandardizedStatementWrite;
};
export type RegenerateOriginalStatementApiResponse =
    /** status 200 OK */ GenericJsonResponsePreprocessedOriginalStatement;
export type RegenerateOriginalStatementApiArg = {
    regenerateAll?: boolean;
    breakdown?: boolean;
    originalStatement: OriginalStatementWrite;
};
export type PreprocessLoanProformaApiResponse = /** status 200 OK */ GenericJsonResponsePreprocessedProforma;
export type PreprocessLoanProformaApiArg = {
    loanId: number;
    proformaAsOfDate?: number;
    clearDate?: boolean;
};
export type SaveLoanProformaApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type SaveLoanProformaApiArg = {
    loanId: number;
    combinedStatement: CombinedStatementWrite;
};
export type PreprocessStatementApiResponse = /** status 200 OK */ GenericJsonResponsePreprocessedStandardizedStatement;
export type PreprocessStatementApiArg = {
    statementId: number;
};
export type PreprocessOriginalStatementApiResponse =
    /** status 200 OK */ GenericJsonResponsePreprocessedOriginalStatement;
export type PreprocessOriginalStatementApiArg = {
    statementId: number;
};
export type PreprocessCurentDevBudgetApiResponse =
    /** status 200 OK */ GenericJsonResponsePreprocessedStandardizedStatement;
export type PreprocessCurentDevBudgetApiArg = {
    propertyId: number;
};
export type PreprocessCombinedStatementApiResponse =
    /** status 200 OK */ GenericJsonResponsePreprocessedCombinedStatement;
export type PreprocessCombinedStatementApiArg = {
    /** Combined Statement id */
    combinedOsid: number;
};
export type SaveCombinedApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type SaveCombinedApiArg = {
    combinedOsid: number;
    combinedStatement: CombinedStatementWrite;
};
export type PreprocessProformaApiResponse = /** status 200 OK */ GenericJsonResponsePreprocessedProforma;
export type PreprocessProformaApiArg = {
    propertyId: number;
    /** Combined statement header index */
    combinedColumnId?: number;
    /** Rent Roll internal id */
    rrId?: number;
    proformaAsOfDate?: number;
    clearDate?: boolean;
    regenerateRefIDs?: boolean;
};
export type SaveProformaApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type SaveProformaApiArg = {
    propertyId: number;
    combinedStatement: CombinedStatementWrite;
};
export type DownloadAdjustmentsApiResponse = unknown;
export type DownloadAdjustmentsApiArg = {
    combinedOsid: number;
    financialColumn: FinancialColumn;
};
export type SaveStatementApiResponse = /** status 200 OK */ GenericJsonResponseString;
export type SaveStatementApiArg = {
    autoConfirm?: boolean;
    body: string;
};
export type AddCombinedYearColumnApiResponse = /** status 200 OK */ GenericJsonResponseCombinedStatement;
export type AddCombinedYearColumnApiArg = {
    addStatementColumnRequest: AddStatementColumnRequest;
};
export type Header = {
    name?: string;
    exportToJSON?: boolean;
    id?: string;
    osid?: number;
    added?: boolean;
    dateLabel?: string;
    c?: string;
    p?: number;
    annualizedFrom?: string;
    commentsCol?: boolean;
    scenario?: string;
    adjustment?: boolean;
    adjustmentCol?: boolean;
    normalizedCol?: boolean;
    metadata?: {
        [key: string]: string;
    };
    variance?: boolean;
    default?: boolean;
    base?: boolean;
    month?: Month;
    year?: number;
    count?: number;
    sqBaseSelected?: boolean;
};
export type HeaderWrite = {
    name?: string;
    exportToJSON?: boolean;
    id?: string;
    osid?: number;
    added?: boolean;
    dateLabel?: string;
    c?: string;
    p?: number;
    annualizedFrom?: string;
    commentsCol?: boolean;
    scenario?: string;
    adjustment?: boolean;
    adjustmentCol?: boolean;
    normalizedCol?: boolean;
    metadata?: {
        [key: string]: string;
    };
    variance?: boolean;
    unmappedProperties?: {
        [key: string]: object;
    };
    default?: boolean;
    base?: boolean;
    month?: Month;
    year?: number;
    count?: number;
    sqBaseSelected?: boolean;
};
export type Market = {
    value?: number;
    refId?: string;
};
export type RowCell = {
    value?: string;
    refId?: string;
    unmappedProperties?: RowCell;
    a?: string;
    aC?: string;
    vC?: string;
};
export type Row = {
    name?: string;
    type?: string;
    group?: string;
    copiedType?: string;
    fromOther?: boolean;
    originalType?: string;
    initialType?: string;
    autoAdded?: boolean;
    market?: Market;
    varianceComment?: string;
    values?: RowCell[];
    unmappedProperties?: Row;
    subgroup?: string;
};
export type VarianceReportPeriod = {
    year?: string;
    count?: string;
    month?: string;
};
export type VarianceReportOptions = {
    value?: string;
    option?: VarianceReportPeriod;
};
export type ProformaConfig = {
    hidden?: boolean;
};
export type ExcelConfig = {
    formula?: string;
    labelOnly?: boolean;
    skip?: boolean;
    descendantsToTotals?: boolean;
};
export type GroupDefinition = {
    name?: string;
    label?: string;
    formula?: string;
    proformaConfig?: ProformaConfig;
    excelConfig?: ExcelConfig;
};
export type GroupDefinitionWrite = {
    name?: string;
    label?: string;
    formula?: string;
    proformaConfig?: ProformaConfig;
    excelConfig?: ExcelConfig;
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type RowMapping = {
    rowType?: string;
    path?: string[];
};
export type RowMappingWrite = {
    rowType?: string;
    path?: string[];
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type OriginalDropdownValue = {
    name?: string;
    label?: string;
};
export type OriginalDropdownValues = {
    revenue?: OriginalDropdownValue[];
    expenses?: OriginalDropdownValue[];
    other?: OriginalDropdownValue[];
};
export type StatementTemplateDefinition = {
    name?: string;
    groupsOrder?: GroupDefinition[];
    rowGrouping?: RowMapping[];
    originalDropdownValues?: OriginalDropdownValues;
};
export type StatementTemplateDefinitionWrite = {
    name?: string;
    groupsOrder?: GroupDefinitionWrite[];
    rowGrouping?: RowMappingWrite[];
    originalDropdownValues?: OriginalDropdownValues;
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type VarianceReportDto = {
    id?: number;
    name?: string;
    countyId?: string;
    parcelId?: string;
    documentJson?: {
        [key: string]: object;
    };
    osId?: number;
    headers?: Header[];
    rows?: Row[];
    options?: VarianceReportOptions[];
    combinedId?: number;
    createdAt?: string;
    updatedAt?: string;
    template?: StatementTemplateDefinition;
    vrTotalComments?: {
        [key: string]: string;
    };
};
export type VarianceReportDtoWrite = {
    id?: number;
    name?: string;
    countyId?: string;
    parcelId?: string;
    documentJson?: {
        [key: string]: object;
    };
    osId?: number;
    headers?: HeaderWrite[];
    rows?: Row[];
    options?: VarianceReportOptions[];
    combinedId?: number;
    createdAt?: string;
    updatedAt?: string;
    template?: StatementTemplateDefinitionWrite;
    vrTotalComments?: {
        [key: string]: string;
    };
};
export type GenericJsonResponseVarianceReportDto = {
    status?: string;
    data?: VarianceReportDto;
    message?: string;
    code?: string;
};
export type GenericJsonResponseVarianceReportDtoWrite = {
    status?: string;
    data?: VarianceReportDtoWrite;
    message?: string;
    code?: string;
};
export type CreateVarianceReportRequest = {
    name: string;
    countyId: string;
    parcelId: string;
    documentJson: {
        [key: string]: object;
    };
    osId?: number;
    combinedId?: number;
};
export type GenericJsonResponseProformaDataStatus = {
    status?: string;
    data?: Data;
    message?: string;
    code?: string;
};
export type StandardizedStatement = {
    osid?: number;
    template?: string;
    headers?: Header[];
    rows?: Row[];
    maxRefs?: number;
    unmappedProperties?: StandardizedStatement;
};
export type StandardizedStatementWrite = {
    osid?: number;
    template?: string;
    headers?: HeaderWrite[];
    rows?: Row[];
    maxRefs?: number;
    unmappedProperties?: StandardizedStatementWrite;
};
export type Column = {
    id?: string;
    field?: string;
    name?: string;
    originalName?: string;
    width?: number;
    minWidth?: number;
    metaColumn?: boolean;
    behavior?: string;
    selectable?: boolean;
    resizable?: boolean;
    p?: number;
    c?: string;
    count?: number;
    year?: number;
    month?: Month;
    dateLabel?: string;
    annualizedFrom?: string;
    commentsCol?: boolean;
    added?: boolean;
    scenario?: string;
    hidden?: boolean;
    formatter?: string;
    groupTotalsFormatter?: object;
    editor?: string;
    metadata?: {
        [key: string]: string;
    };
    osid?: number;
    exportToJSON?: boolean;
    index?: number;
    unmappedProperties?: Column;
    default?: boolean;
    base?: boolean;
    sqBaseSelected?: boolean;
};
export type Name = {
    name?: string;
    refId?: string;
    value?: string;
};
export type ProcessedRowCell = {
    value?: number;
    refId?: string;
    unmappedProperties?: ProcessedRowCell;
    a?: string;
    aC?: string;
};
export type ProcessedRow = {
    id?: string;
    name?: Name;
    market?: Market;
    values?: ProcessedRowCell[];
    type?: string;
    originalGroup?: string;
    fromOther?: boolean;
    subType?: string;
    originalType?: string;
    initialType?: string;
    autoAdded?: boolean;
    mappedLabel?: string;
    varianceComment?: string;
    adjustmentComment?: string;
    group?: string;
    groupType?: string;
    type2?: string;
    noiMargin?: string;
    expenseRatio?: string;
    unmappedProperties?: ProcessedRow;
    _grouping?: string[];
    subgroup?: string;
};
export type PreprocessedStandardizedStatement = {
    statement?: StandardizedStatement;
    columns?: Column[];
    rows?: ProcessedRow[];
    template?: StatementTemplateDefinition;
};
export type PreprocessedStandardizedStatementWrite = {
    statement?: StandardizedStatementWrite;
    columns?: Column[];
    rows?: ProcessedRow[];
    template?: StatementTemplateDefinitionWrite;
};
export type GenericJsonResponsePreprocessedStandardizedStatement = {
    status?: string;
    data?: PreprocessedStandardizedStatement;
    message?: string;
    code?: string;
};
export type GenericJsonResponsePreprocessedStandardizedStatementWrite = {
    status?: string;
    data?: PreprocessedStandardizedStatementWrite;
    message?: string;
    code?: string;
};
export type OriginalRowCell = {
    refId?: string;
    value?: string;
    c?: string;
    p?: number;
    r?: number;
};
export type OriginalRowCellWrite = {
    refId?: string;
    value?: string;
    unmappedProperties?: {
        [key: string]: object;
    };
    c?: string;
    p?: number;
    r?: number;
};
export type OriginalRow = {
    group?: Group;
    initialType?: string;
    merge?: number;
    name?: string;
    type?: string;
    subGroup?: string;
    subType?: string;
    indent?: boolean;
    values?: OriginalRowCell[];
    c?: string;
    h?: boolean;
    p?: string;
    r?: string;
};
export type OriginalRowWrite = {
    group?: Group;
    initialType?: string;
    merge?: number;
    name?: string;
    type?: string;
    subGroup?: string;
    subType?: string;
    indent?: boolean;
    values?: OriginalRowCellWrite[];
    unmappedProperties?: {
        [key: string]: object;
    };
    c?: string;
    h?: boolean;
    p?: string;
    r?: string;
};
export type OriginalStatement = {
    template?: string;
    maxRefs?: number;
    isSearchablePdf?: boolean;
    osid?: string;
    pdfParseType?: string;
    resolutions?: number[];
    rows?: OriginalRow[];
    userId?: number;
    autoConfirmed?: boolean;
    excelMergedCellsCount?: number;
    formulas?: {
        [key: string]: object;
    };
    headers?: Header[];
};
export type OriginalStatementWrite = {
    template?: string;
    maxRefs?: number;
    isSearchablePdf?: boolean;
    osid?: string;
    pdfParseType?: string;
    resolutions?: number[];
    rows?: OriginalRowWrite[];
    userId?: number;
    autoConfirmed?: boolean;
    excelMergedCellsCount?: number;
    formulas?: {
        [key: string]: object;
    };
    headers?: HeaderWrite[];
    unmappedProperties?: {
        [key: string]: object;
    };
};
export type ColumnExcelRow = {
    rowNum?: number;
    cells?: string[];
    varianceComment?: string;
    adjustmentComment?: string;
    issuedCells?: number[];
    label?: string;
    level?: number;
    name?: string;
};
export type PreprocessedOriginalStatement = {
    statement?: OriginalStatement;
    template?: StatementTemplateDefinition;
    headerRows?: ColumnExcelRow[];
    rows?: ProcessedRow[];
};
export type PreprocessedOriginalStatementWrite = {
    statement?: OriginalStatementWrite;
    template?: StatementTemplateDefinitionWrite;
    headerRows?: ColumnExcelRow[];
    rows?: ProcessedRow[];
};
export type GenericJsonResponsePreprocessedOriginalStatement = {
    status?: string;
    data?: PreprocessedOriginalStatement;
    message?: string;
    code?: string;
};
export type GenericJsonResponsePreprocessedOriginalStatementWrite = {
    status?: string;
    data?: PreprocessedOriginalStatementWrite;
    message?: string;
    code?: string;
};
export type AdjustmentsCommentsCell = {
    date?: string;
    name?: string;
    userId?: string;
    value?: string;
};
export type AdjustmentsCommentsRow = {
    type?: string;
    values?: AdjustmentsCommentsCell[];
};
export type AdjustmentsCommentsData = {
    month?: Month;
    year?: number;
    count?: number;
    dateLabel?: string;
    rows?: AdjustmentsCommentsRow[];
};
export type AdjustmentsDataCell = {
    value?: string;
};
export type AdjustmentsRow = {
    type?: string;
    values?: AdjustmentsDataCell[];
};
export type AdjustmentsData = {
    month?: Month;
    year?: number;
    count?: number;
    dateLabel?: string;
    rows?: AdjustmentsRow[];
    adjustmentValues?: {
        [key: string]: number;
    };
};
export type UnderwritingRow = {
    type?: string;
    values?: RowCell[];
};
export type Underwriting = {
    metadata?: {
        [key: string]: string;
    };
    label?: string;
    concession?: number;
    vacancy?: number;
    rows?: UnderwritingRow[];
    dateLabel?: string;
    selected?: boolean;
    month?: Month;
    year?: number;
    count?: number;
};
export type HistoricalProforma = {
    dateLabel?: string;
    scenarios?: {
        [key: string]: Underwriting;
    };
    month?: Month;
    year?: number;
    count?: number;
    selected?: boolean;
};
export type CombinedStatement = {
    template?: string;
    headers?: Header[];
    rows?: Row[];
    maxRefs?: number;
    adjustmentsCommentsData?: AdjustmentsCommentsData[];
    adjustmentsData?: AdjustmentsData[];
    proforma?: {
        [key: string]: Underwriting;
    };
    historicalProforma?: HistoricalProforma[];
    proformaAsOfDate?: number;
    proformaUpdateDate?: number;
    selectedColumns?: Header[];
    selectedScenarios?: string[];
    formulas?: {
        [key: string]: object;
    };
    cellsComments?: {
        [key: string]: object;
    };
    baseHeader?: Header;
};
export type CombinedStatementWrite = {
    template?: string;
    headers?: HeaderWrite[];
    rows?: Row[];
    maxRefs?: number;
    adjustmentsCommentsData?: AdjustmentsCommentsData[];
    adjustmentsData?: AdjustmentsData[];
    proforma?: {
        [key: string]: Underwriting;
    };
    historicalProforma?: HistoricalProforma[];
    proformaAsOfDate?: number;
    proformaUpdateDate?: number;
    selectedColumns?: HeaderWrite[];
    selectedScenarios?: string[];
    formulas?: {
        [key: string]: object;
    };
    cellsComments?: {
        [key: string]: object;
    };
    baseHeader?: HeaderWrite;
};
export type PreprocessedProforma = {
    statement?: CombinedStatement;
    columns?: Column[];
    rows?: ProcessedRow[];
    proformaBottomRows?: ProcessedRow[];
    template?: StatementTemplateDefinition;
    proformaTemplate?: string;
    proforma?: {
        [key: string]: Underwriting;
    };
    historicalProforma?: HistoricalProforma[];
    selectedColumns?: Header[];
    selectedScenarios?: string[];
    benchmarks?: {
        [key: string]: number;
    };
    proformaAsOfDate?: number;
};
export type PreprocessedProformaWrite = {
    statement?: CombinedStatementWrite;
    columns?: Column[];
    rows?: ProcessedRow[];
    proformaBottomRows?: ProcessedRow[];
    template?: StatementTemplateDefinitionWrite;
    proformaTemplate?: string;
    proforma?: {
        [key: string]: Underwriting;
    };
    historicalProforma?: HistoricalProforma[];
    selectedColumns?: HeaderWrite[];
    selectedScenarios?: string[];
    benchmarks?: {
        [key: string]: number;
    };
    proformaAsOfDate?: number;
};
export type GenericJsonResponsePreprocessedProforma = {
    status?: string;
    data?: PreprocessedProforma;
    message?: string;
    code?: string;
};
export type GenericJsonResponsePreprocessedProformaWrite = {
    status?: string;
    data?: PreprocessedProformaWrite;
    message?: string;
    code?: string;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type PreprocessedCombinedStatement = {
    statement?: CombinedStatement;
    columns?: Column[];
    rows?: ProcessedRow[];
    template?: StatementTemplateDefinition;
};
export type PreprocessedCombinedStatementWrite = {
    statement?: CombinedStatementWrite;
    columns?: Column[];
    rows?: ProcessedRow[];
    template?: StatementTemplateDefinitionWrite;
};
export type GenericJsonResponsePreprocessedCombinedStatement = {
    status?: string;
    data?: PreprocessedCombinedStatement;
    message?: string;
    code?: string;
};
export type GenericJsonResponsePreprocessedCombinedStatementWrite = {
    status?: string;
    data?: PreprocessedCombinedStatementWrite;
    message?: string;
    code?: string;
};
export type FinancialColumn = {
    count?: number;
    year?: string;
    month?: string;
    dateLabel?: string;
    column?: number;
};
export type GenericJsonResponseString = {
    status?: string;
    data?: string;
    message?: string;
    code?: string;
};
export type GenericJsonResponseCombinedStatement = {
    status?: string;
    data?: CombinedStatement;
    message?: string;
    code?: string;
};
export type GenericJsonResponseCombinedStatementWrite = {
    status?: string;
    data?: CombinedStatementWrite;
    message?: string;
    code?: string;
};
export type AddStatementColumnRequest = {
    combinedOsId: number;
    month: string;
    year: number;
};
export enum Month {
    January = 'JANUARY',
    February = 'FEBRUARY',
    March = 'MARCH',
    April = 'APRIL',
    May = 'MAY',
    June = 'JUNE',
    July = 'JULY',
    August = 'AUGUST',
    September = 'SEPTEMBER',
    October = 'OCTOBER',
    November = 'NOVEMBER',
    December = 'DECEMBER',
}
export enum Data {
    Empty = 'EMPTY',
    Processing = 'PROCESSING',
    Nodata = 'NODATA',
    Ready = 'READY',
}
export enum Group {
    Revenue = 'REVENUE',
    Expenses = 'EXPENSES',
    Other = 'OTHER',
    Totals = 'TOTALS',
}
export const {
    useCreateVarianceReportMutation,
    useCheckProformaDataQuery,
    useSaveOriginalStatementMutation,
    useNormalizeNoiMutation,
    useRegenerateOriginalStatementMutation,
    usePreprocessLoanProformaQuery,
    useSaveLoanProformaMutation,
    usePreprocessStatementQuery,
    usePreprocessOriginalStatementQuery,
    usePreprocessCurentDevBudgetQuery,
    usePreprocessCombinedStatementQuery,
    useSaveCombinedMutation,
    usePreprocessProformaQuery,
    useSaveProformaMutation,
    useDownloadAdjustmentsMutation,
    useSaveStatementMutation,
    useAddCombinedYearColumnMutation,
} = injectedRtkApi;
