import { put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice, createSelector } from '@reduxjs/toolkit';

import { rentRollApi } from '@api';
import { apiRequest, API_SUCCESS } from '@store/ducks/api';
import { selectAuthState } from './auth';

const entity = '[rent-roll]';

const initialState = {
    error: null,
    data: {},
    loading: false,
    loaded: false,
    status: '',
};

const rentRollSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadAll(state) {
            state.loading = true;
            state.loaded = false;
        },
        clean: () => initialState,
        deleteFileRequest(state) {
            state.status = 'deleted';
        },
        removeFile(state, action) {
            const { id, collection, idKey } = action.payload;
            const files = state.data[collection];
            if (files && files.length) {
                state.data[collection] = files.map((rr) =>
                    rr[idKey] === id ? { ...rr, dynamic: false, deleted: true } : rr
                );
            }
        },
        uploadFiles() {},
        addsFile(state, action) {
            state.status = 'added';
            const { collection, file } = action.payload;
            state.data[collection].push(file);
        },
        update(state, action) {
            state.data = action.payload;
            state.loading = false;
            state.loaded = true;
        },
    },
});

export const {
    loadAll: loadAllRentRoll,
    deleteFileRequest: deleteFileRequestRentRoll,
    update: updateRentRoll,
    removeFile,
    uploadFiles: uploadFilesRentRoll,
    addsFile: addsFileRentRoll,
    clean: cleanRentRoll,
} = rentRollSlice.actions;

export default rentRollSlice.reducer;

export const selectRentRollAllState = (state) => state.rentRoll;
export const selectRRFiles = createSelector(selectRentRollAllState, ({ data: { rentRollList = [] } }) =>
    rentRollList
        .filter(({ deleted }) => !deleted)
        .map(({ rrId, rentRollFileName, ...rest }) => ({ id: rrId, name: rentRollFileName, ...rest }))
);
export const selectOSFiles = createSelector(selectRentRollAllState, ({ data: { rentRollStatementList = [] } }) =>
    rentRollStatementList
        .filter(({ deleted }) => !deleted)
        .map(({ osId, statementsFileName, ...rest }) => ({ id: osId, name: statementsFileName, ...rest }))
);
export const selectDynamicRRFiles = createSelector(selectRRFiles, (files = []) =>
    files.filter(({ dynamic }) => !!dynamic)
);
export const selectDynamicOSFiles = createSelector(selectOSFiles, (files = []) =>
    files.filter(({ dynamic }) => !!dynamic)
);

export const selectHasFiles = (state) => !!selectOSFiles(state).length || !!selectRRFiles(state).length;

function* loadRentRollAllSaga(action) {
    yield put(apiRequest(action.payload, rentRollApi.getRentRollAll, loadAllRentRoll.type));
    const { payload } = yield take([`${loadAllRentRoll.type} ${API_SUCCESS}`]);
    yield put(updateRentRoll(payload));
}

function* deleteFileRequestRentRollSaga({ payload }) {
    let idKey = 'rrid';
    let collection = 'rentRollList';
    let method = rentRollApi.deleteRentRoll;

    if (payload.type === 'os') {
        idKey = 'osid';
        collection = 'rentRollStatementList';
        method = rentRollApi.deleteRentRollStatement;
    }

    yield put(apiRequest({ [idKey]: payload.id }, method, deleteFileRequestRentRoll.type));
    yield take([`${deleteFileRequestRentRoll.type} ${API_SUCCESS}`]);
    yield put(removeFile({ id: payload.id, idKey, collection }));
}

function* uploadFilesRentRollSaga({ payload }) {
    const { currentUser: { userId } = {} } = yield select(selectAuthState);
    let id = payload.res.rentRollOriginalTable?.rrid;
    let name = 'rentRollFileName';
    let idKey = 'rrid';
    let timeStamp = 'rentRollFileTimestamp';
    let collection = 'rentRollList';

    if (payload.res.osid) {
        id = payload.res.osid;
        name = 'statementsFileName';
        idKey = 'osid';
        timeStamp = 'statementsFileTimestamp';
        collection = 'rentRollStatementList';
    }

    yield put(
        addsFileRentRoll({
            collection,
            file: {
                [name]: payload.name,
                [timeStamp]: Date.now(),
                [idKey]: id,
                userid: userId,
                deleted: false,
                confirmed: false,
                dynamic: true,
            },
        })
    );
}

export function* watchRentRoll() {
    yield takeLatest(loadAllRentRoll, loadRentRollAllSaga);
    yield takeLatest(deleteFileRequestRentRoll, deleteFileRequestRentRollSaga);
    yield takeLatest(uploadFilesRentRoll, uploadFilesRentRollSaga);
}
