import request from '@utils/request';

const BASE_URL = '/api/v2/listing';

export async function getListing({ propertyTypes, address }) {
    return request(`${BASE_URL}/find`, {
        params: {
            propertyTypes,
            address,
        },
    });
}

export async function createListing(body) {
    return request(`${BASE_URL}/create`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function sendMessage({ id, message } = {}) {
    return request(`${BASE_URL}/email`, {
        method: 'POST',
        body: JSON.stringify({
            id,
            message,
        }),
    });
}

export async function deleteListing(body) {
    return request(BASE_URL, {
        method: 'DELETE',
        body: JSON.stringify(body),
    });
}

export async function editListing(body) {
    return request(BASE_URL, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}
