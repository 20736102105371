import cx from 'classnames';
import { useState } from 'react';

import Spinner from '@ui/Spinner';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import PrepareDocument from '@shared/templates/forms/components/PrepareDocument';

import styles from './styles.module.scss';

function PrepareDocumentsForm({ className, data, previewIndex, totalPages, actions, canDelete }) {
    const [isLoading, setIsLoading] = useState(false);
    const isSingleDocument = Object.keys(data).length === 1;

    return (
        <div className={cx(styles.wrapper, className)}>
            <Typography.Uppercase color={colors['grey-300']} variant='smsb'>
                Prepare your Documents
            </Typography.Uppercase>

            {Object.values(data).map((doc, index) => (
                <PrepareDocument
                    key={doc.id}
                    canDelete={canDelete}
                    document={doc}
                    index={index}
                    isActive={previewIndex === index || isSingleDocument}
                    setIsLoading={isSingleDocument ? () => null : setIsLoading}
                    totalPages={totalPages}
                    {...actions}
                />
            ))}
            {isLoading && (
                <div className={styles.loadingBox}>
                    <Spinner className={styles.spinner} />
                </div>
            )}
        </div>
    );
}

export default PrepareDocumentsForm;
