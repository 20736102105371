import Payment from './Payment';
import styles from './styles.module.scss';

const GetValuationStep = function ({ formId, controls }) {
    return (
        <>
            <div className={styles.valuation}>
                <Payment formId={formId} />
            </div>
            {controls}
        </>
    );
};

export default GetValuationStep;
