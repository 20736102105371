import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';
import Copyright from '@components/Copyright';

import { loginAsUserAuth } from '@scc/store/ducks/auth';

import Logo from '@scc/components/Logo';
import PartialModal from '@scc/components/PartialModal';
import Input from '@ui/Input';
import Button from '@ui/Button';
import styles from './styles.module.scss';
import schema from './validationSchema';

const LoginAsUser = function ({ dialogProps }) {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async ({ email }) => {
        dispatch(loginAsUserAuth({ username: email }));
    };

    const Form = memo(() => (
        <Stack direction='column' justifyContent='space-between' sx={{ height: 1 }}>
            <Box component='form' className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Input
                    margin='normal'
                    variant='standard'
                    label='Email'
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <Button color='primary' size='extra-large' fullWidth type='submit' sx={{ marginTop: '30px' }}>
                    Login
                </Button>
            </Box>
        </Stack>
    ));

    const MobileLayout = function () {
        return <Form />;
    };

    const DesktopLayout = memo(() => (
        <Box className={styles.modal}>
            <Grid container direction='row'>
                <Grid item xs className={styles.welcomeSection}>
                    <Stack direction='column' justifyContent='space-between' sx={{ height: 1, width: '100%' }}>
                        <div>
                            <Logo type='link' />
                        </div>
                        <Typography variant='h1b' component='span' className={styles.message}>
                            Welcome Back
                        </Typography>
                        <Copyright />
                    </Stack>
                </Grid>
                <Grid item xs='auto'>
                    <PartialModal
                        className={styles.partialModal}
                        titleClassName={styles.title}
                        contentClassName={styles.content}
                        onClose={dialogProps?.onClose}
                        title='Login as User'
                    >
                        <Form />
                    </PartialModal>
                </Grid>
            </Grid>
        </Box>
    ));

    return isMobile ? <MobileLayout /> : <DesktopLayout />;
};

export default LoginAsUser;
