import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, take, takeLatest, select, all, call } from 'redux-saga/effects';

import { RootState } from '@scc/index';
import { updateLoanDataFundingLoans, updateLoanLoans } from '@scc/store/ducks/loans/loans';

import { loansApi, userApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { hideDrawer } from '@store/ducks/ui/drawer';

import { TLoanState } from './types';

const entity = '[loan]';

const initialState: TLoanState = {
    loaded: false,
    loading: false,
    requestLoading: false,
    requestLoaded: false,
    detailsLoading: false,
    detailsLoaded: false,
    detailsEdited: false,
    details: {
        loanId: '',
        loanName: '',
        jsonValues: {},
        loanType: '',
        status: '',
        loanProgram: '',
        members: {},
        balance: 0,
        principal: 0,
        unfundedBalance: 0,
        readOnly: false,
        loanRating: '',
    },
    requests: [],
    activity: [],
    id: null,
    rocketChatToken: '',
    params: {},
};

const loanSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load() {},
        loadActivity(state, action: PayloadAction<number>) {
            state.loaded = false;
            state.loading = true;
            state.id = action.payload;
        },
        setActivity(
            state,
            action: PayloadAction<{ events: Record<string, unknown>[]; params: Record<string, unknown> }>
        ) {
            state.activity = action.payload.events;
            state.params = action.payload.params;
            state.loaded = true;
            state.loading = false;
        },
        loadRequests(state, action) {
            state.requestLoading = true;
            state.requestLoaded = false;
            state.id = action.payload;
        },
        loadDetails(state) {
            state.detailsLoading = true;
            state.detailsLoaded = false;
            state.details = initialState.details;
        },
        saveDetails(state, action) {
            state.detailsEdited = false;
            state.detailsLoading = true;
            state.details.jsonValues = action.payload.values;
        },
        setDetails(state, { payload }) {
            state.detailsLoading = false;
            state.detailsLoaded = true;
            state.detailsEdited = true;
            const values = payload.jsonValues ? JSON.parse(payload.jsonValues) : {};

            // recording date in timestamp format
            const data = { ...state.details.jsonValues, ...values };
            data.approvedDate = data?.approvedDate ? Date.parse(data.approvedDate) : null;
            data.maturityDate = data?.maturityDate ? Date.parse(data.maturityDate) : null;

            state.details.jsonValues = data;
            state.details.loanId = payload.loanId || state.id;
            state.details.loanName = payload.loanName || '';
            state.details.loanType = payload.loanType || '';
            state.details.status = payload.status || '';
            state.details.loanProgram = payload.loanProgram || '';
            state.details.members = payload.members || {};
            state.details.balance = payload.balance || 0;
            state.details.principal = payload.principal || 0;
            state.details.unfundedBalance = payload.unfundedBalance || 0;
            state.details.loanRating = data?.loanRating || '';
            state.details.readOnly = payload.readOnly || false;
        },
        updateDetails(state, action) {
            state.details = {
                ...state.details,
                ...action.payload,
            };
        },
        setRequests(state, action) {
            state.requestLoading = false;
            state.requestLoaded = true;
            state.requests = action.payload || [];
        },
        saveRequest(state) {
            state.requestLoading = true;
            state.requestLoaded = false;
        },
        updateToken(state, { payload }: PayloadAction<string>) {
            state.rocketChatToken = payload;
        },
        updateRequests(state, action) {
            state.requests = state.requests.concat(action.payload);
            state.requestLoading = false;
            state.requestLoaded = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateLoanDataFundingLoans, (state, action) => {
            if (!action.payload.entries) return;
            const fundingValues = Object.values(action.payload.entries).reduce(
                (accum, row) => {
                    // eslint-disable-next-line no-return-assign
                    return {
                        // eslint-disable-next-line no-param-reassign
                        principal: (accum.principal += row.principal),
                        // eslint-disable-next-line no-param-reassign
                        balance: (accum.balance += row.balance),
                    };
                },
                {
                    principal: 0,
                    balance: 0,
                }
            );
            state.details = {
                ...state.details,
                principal: fundingValues.principal,
                balance: fundingValues.balance,
                unfundedBalance: fundingValues.principal - fundingValues.balance,
            };
        });
    },
});

export const {
    load: loadLoan,
    updateToken: updateRocketChatToken,
    loadActivity: loadActivityLoan,
    loadRequests: loadRequestsLoan,
    saveRequest: saveRequestLoan,
    updateRequests: updateRequestsLoan,
    setRequests: setRequestsLoan,
    setActivity: setActivityLoan,
    loadDetails: loadDetailsLoan,
    saveDetails: saveDetailsLoan,
    setDetails: setDetailsLoan,
    updateDetails: updateDetailsLoan,
} = loanSlice.actions;

export default loanSlice.reducer;

export const selectLoanState = (store: RootState) => store.loans.loan;

function* loadLoanSaga({ type, payload }) {
    yield put(apiRequest({ loanId: payload }, loansApi.loginAndCreateUserAndRoom, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    if (action.payload?.scc_url) {
        yield put(apiRequest(action.payload, loansApi.loginIntoSCCviaToken, type));
    }

    yield put(updateRocketChatToken(action.payload?.chat_token));
}

function* loadActivityLoanSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.getActivity, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, sort, totalElements: total } = action.payload.data;
    const users = yield all(content?.map((event) => call(userApi.getUserById, event?.userId)));

    const events = users?.map((user, idx) => {
        if (user && !user.error) {
            return { ...content[idx], user };
        }
        return { ...content[idx] };
    });
    yield put(setActivityLoan({ events, params: { sort, total } }));
}

function* loadRequestsLoanSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.getLoanRequest, type));
    const response = yield take(`${type} ${API_SUCCESS}`);
    yield put(setRequestsLoan(response.payload.data?.content));
}

function* saveRequestLoanSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.saveLoanRequest, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(updateRequestsLoan(action.payload.data));
    yield put(hideDrawer());
}

function* loadDetailsLoanSaga({ type, payload }) {
    const detailsId = payload.id;
    yield put(apiRequest({ id: payload.id, detailsId }, loansApi.getLoanDetails, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setDetailsLoan(action.payload.data));
}

function* saveDetailsLoanSaga({ type, payload }) {
    const loanId = payload.id;

    const {
        details: { jsonValues },
    } = yield select(selectLoanState);
    yield put(
        apiRequest(
            {
                id: loanId,
                loanName: payload.name || '',
                loanId,
                values: { ...jsonValues, ...payload.values },
            },
            loansApi.saveLoanDetails,
            type
        )
    );
    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(setDetailsLoan(action.payload.data));
    yield put(updateLoanLoans(action.payload.data));
    yield put(hideDrawer());
}

export function* watchLoan() {
    yield takeLatest(loadLoan, loadLoanSaga);
    yield takeLatest(loadActivityLoan, loadActivityLoanSaga);
    yield takeLatest(loadRequestsLoan, loadRequestsLoanSaga);
    yield takeLatest(saveRequestLoan, saveRequestLoanSaga);
    yield takeLatest(loadDetailsLoan, loadDetailsLoanSaga);
    yield takeLatest(saveDetailsLoan, saveDetailsLoanSaga);
}
