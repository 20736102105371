/* eslint-disable no-template-curly-in-string */
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isServer } from '@utils/browser';
import { isMFLike, propertyTypes } from '@utils/properties';
import { calculateValuationsRange, formatValuationValue } from '@utils/valuation';

import { scrollToElement } from '@scc/pages/Property/helper';
import styles from '@scc/pages/Property/property.module.scss';
import { highlightsMap, mapTabs, tooltip } from '@scc/pages/Property/staticData';
import links from '@scc/router/links';
import { selectIsUserAuth } from '@scc/store/ducks/auth';
import { loadBuilding, loadBuildingSimilar, selectBuildingState } from '@scc/store/ducks/building';
import {
    selectPropertyState,
    startContactBrokenProperty,
    startProperty,
    startShareLinkProperty,
} from '@scc/store/ducks/property';

import Icon from '@ui/Icon';
import Stack from '@ui/Stack';

import { ordersApi } from '@api';
import LocationSvg from '@assets/icons/outlined/location.svg';
import { LISTING_SEARCH_PROPERTY } from '@constants/modals';
import useAddBodyClass from '@shared/hooks/useAddBodyClass';
import useScrollPosition from '@shared/hooks/useScrollPosition';
import { showDialog } from '@store/ducks/ui/dialog';

export default function useProperty() {
    const dispatch = useDispatch();
    const { data: propertyData, loaded, loadingSimilar, parcel } = useSelector(selectBuildingState);
    const isLoggedIn = useSelector(selectIsUserAuth);
    const { step } = useSelector(selectPropertyState);
    const { type, address: addressParam = '' } = useParams();
    let propertyType =
        type?.toLowerCase() === 'multi-family'
            ? propertyTypes.MULTIFAMILY
            : propertyTypes[type?.toUpperCase()?.replace('-', '_')];
    const buildingId = addressParam.split('-').slice(-1)[0];
    const propertyValueRef = useRef();
    const [valuation, setValuation] = useState([]);
    const [isOpenParcelEditor, setIsOpenParcelEditor] = useState(false);
    const [activeMapTab, setActiveMapTab] = useState(mapTabs[0].value);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { current: overviewTab } = useRef(mapTabs[0].value);

    const position = useScrollPosition(0, 500);

    const isShowValuations = useMemo(
        () => !loaded || !valuation.filter((item) => item === '0').length,
        [loaded, valuation]
    );
    const isValuationLoaded = useMemo(() => loaded && !loadingSimilar, [loaded, loadingSimilar]);

    const onTransactionsUpdate = useCallback(() => {
        if (!propertyData) {
            setValuation([0, 0]);
            return;
        }
        const { classdesc, units, size, lotSize, estimationFull, estimationError } = propertyData;
        // todo MFlike
        const unitsOrSize = isMFLike(classdesc) ? units : size;
        const propertySize = classdesc === propertyTypes.VACANT_LAND ? lotSize : unitsOrSize;
        const valuationsRange = calculateValuationsRange(estimationFull, estimationError);
        setValuation([
            formatValuationValue(valuationsRange.minPrice, propertySize),
            formatValuationValue(valuationsRange.maxPrice, propertySize),
        ]);
    }, [propertyData]);

    useAddBodyClass(styles.propertyBody);
    useAddBodyClass(styles.propertyPage);

    useEffect(() => {
        // normally propertyData will be already available due to SSR
        // we should run building call with similar context if:
        // - there's no similarTransactionsResponse (for runtime buildings)
        // - user is logged in (so we show valuation based on user overrides instead of default valuation)
        if (isEmpty(propertyData)) {
            // keeping this for local development
            dispatch(loadBuilding({ id: buildingId, type: propertyType }));
        } else if (!propertyData?.similarTransactionsResponse || isLoggedIn) {
            dispatch(loadBuildingSimilar(parcel));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onTransactionsUpdate(propertyData?.similarTransactionsResponse?.zoomedValues || []);
    }, [propertyData, onTransactionsUpdate]);

    let { address = '', locationScore, riskScore, growthScore } = propertyData;
    const { propertyListing } = propertyData;
    const street = address.split(',')[0];
    const city = address.split(',').splice(1, address.length).join(', ');

    if (isServer) {
        address = '${address}';
        propertyType = '${propertyType}';
        locationScore = '${locationScore}';
        riskScore = '${riskScore}';
        growthScore = '${growthScore}';
    }

    const handleContactBroker = () => {
        const { id } = propertyListing;

        dispatch(
            startContactBrokenProperty({
                title: isMobile ? 'Contact Broker' : 'Property Listing',
                label: isMobile ? null : (
                    <Stack alignItems='center' direction='row' spacing={4}>
                        <Icon component={LocationSvg} />
                        {address}
                    </Stack>
                ),
                id,
                propertyListing,
                className: styles.modal,
            })
        );
    };

    const handleAddToListingClick = () => {
        if (!isLoggedIn) {
            window.router?.history.push(links.login.path, {
                from: { pathname: window.location.pathname },
            });
        }

        dispatch(
            showDialog({
                content: LISTING_SEARCH_PROPERTY,
                data: propertyData,
                className: styles.modal,
            })
        );
    };

    const getProperty = async (propType, propStep) => {
        if (propType === 'valuation') {
            const { countyid: countyId, parcelid: parcelId } = parcel;

            const data = {
                orderType: 'FULL',
                parcelId,
                countyId,
            };
            try {
                await ordersApi.createOrderIntention(data);
            } catch (e) {
                console.warn(e);
            }
        }
        dispatch(startProperty({ type: propType, step: propStep }));
    };

    const handleShareProperty = () => {
        dispatch(
            startShareLinkProperty({
                title: 'Share Property',
                address: isServer ? address : street,
                city,
            })
        );
    };

    const handleChangeTab = (newValue) => {
        if (isMobile) {
            setActiveMapTab(newValue);
        } else {
            const isOverview = newValue === overviewTab;
            const mapFound = highlightsMap.find(({ map }) => map === newValue);
            const element = document.getElementById(mapFound?.name);
            if (!element && !isOverview) {
                setActiveMapTab(newValue);
            } else {
                const delay = position === 0 ? 700 : 0;

                if (!isOverview && position === 0) window.scrollTo({ top: 1 });

                setTimeout(scrollToElement, delay, {
                    newValue,
                    element,
                    overviewTab,
                    position,
                    onChangeActiveMapTab: setActiveMapTab,
                });
            }
        }
    };

    return [
        {
            step,
            propertyValueRef,
            isOpenParcelEditor,
            tooltip,
            activeMapTab,
            isShowValuations,
            isValuationLoaded,
            propertyData: {
                ...propertyData,
                address,
                street,
                city,
                propertyType,
                locationScore,
                riskScore,
                growthScore,
                valuation,
                parcel,
            },
            loaded,
            isServer,
            parcel,
            isMobile,
            isLoggedIn,
        },
        {
            handleChangeTab,
            handleShareProperty,
            getProperty,
            handleContactBroker,
            setIsOpenParcelEditor,
            onTransactionsUpdate,
            handleAddToListingClick,
        },
    ];
}
