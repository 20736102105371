import links from '@scc/router/links';

export default {
    policy: {
        label: 'Privacy Policy',
        subTitle: 'Last revised on: January 20, 2024',
        rules: [
            {
                title: 'Acceptance Through Use',
                description: 'This privacy policy sets out how Smart Capital Center uses and protects any information that you provide to Smart Capital Center. Smart Capital Center is devoted to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when accessing your account information, you can be assured that such information will only be used in accordance with this privacy statement. Smart Capital Center has a firm policy of protecting the confidentiality and security of information that we collect from our clients. We do not share your non-public personal information. Smart Capital Center reserves the right to change this policy at any time.'
            },
            {
                title: 'The Types of Information We Collect',
                description: 'We limit the collection and use of non-public personal information to the minimum we believe is necessary to deliver superior service to you. The types of personal information we may obtain or you may choose to provide include:',
                list: [
                    'Contact information (such as name, postal or e-mail address, and phone number)',
                    'Your professional information (such as job title, name of organization)',
                    'Username and password',
                    'Payment account information'
                ]
            },
            {
                title: 'Accessing, correcting and deleting your information',
                description: 'You may request access to any personal information we hold about you by contacting us (see “Contact Us”). You may also contact us to request that we delete information that we hold about you or that we delete your account. We will respond to your request within a reasonable period. We will give access in the manner you have requested if it is reasonable to do so. If you make an access request, we will ask you to verify your identity.',
            },
            {
                title: 'How We Use Your Information',
                description: 'We do not disclose any kind of non-public personal information about our clients or former clients to anyone, except where disclosure is required by law. Except in those specific, limited situations, without your consent, we will not make any disclosures of non-public personal information to other companies who may want to sell their products or services to you. We require this information for the following reasons:',
                list: [
                    'Internal record keeping',
                    'We send email about new investment properties, investor statements, valuation statements, loan offers or other information which we think may be valuable using the email address which you have provided',
                    'From time to time, we may also use your information to contact you for general account information. We may contact you by email, phone, fax or mail',
                    'We may use the information you submit to Smart Capital Center to customize our website and improve analytics to provide better service to you.'
                ]
            },
            {
                title: 'Security',
                description: 'We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect.'
            }
        ],
        paging: links.terms,
    },
    terms: {
        label: 'Terms of Use',
        subTitle: 'Last revised on: 3 January, 2020',
        version: 'Version 3.0',
        description: [
            'The website located at www.smart-capital.center and smartcapital.center (the "Site", "Smart Capital Center Service") is a copyrighted work belonging to Smart Capital Center ("Company", "us", "our", and "we"). Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into these Terms.',
            'These Terms of Use (these "Terms") set forth the legally binding terms and conditions that govern your use of the Site. By accessing or using the Site, you are accepting these Terms (on behalf of yourself or the entity that you represent), and you represent and warrant that you have the right, authority, and capacity to enter into these Terms (on behalf of yourself or the entity that you represent). You may not access or use the Site or accept the Terms if you are not at least 18 years old. If you do not agree with all of the provisions of these Terms, do not access and/or use the Site.',
            'These terms require the use of arbitration (Section 11.2) on an individual basis to resolve disputes, rather than jury trials or class actions, and also limit the remedies available to you in the event of a dispute.'
        ],
        rules: [
            {
                title: 'Accounts',
                description: '1.1 Account Creation. In order to use certain features of the Site, you must register for an account ("Account") and provide certain information about yourself as prompted by the account registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Site or by emailing to hello@spacequant.com. Company may suspend or terminate your Account in accordance with Section 8.\n 1.2 Account Responsibilities. You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.'
            },
            {
                title: 'Access to the Site',
                description: `2.1 License. Subject to these Terms, Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site.
                    2.2 Certain Restrictions. The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise use the Site, whether in whole or in part, or any content displayed on the Site, other than as expressly authorized in these Terms of Use; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site, or use any data or materials received from Company, in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.
                    2.3 Modification. Company reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you. You agree that Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.
                    2.4 Support and Maintenance. Subject to the terms hereof, Company will provide you with reasonable technical support services for the Site.
                    2.5 Ownership. Excluding any User Content that you may provide (defined below), you acknowledge that all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets, in the Site and its content are owned by Company or Company?s suppliers. Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1. Company and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.`
            },
            {
                title: 'User Content',
                description: `3.1 User Content. "User Content" means any and all information and content that a user submits to, or uses with, the Site. You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness. You hereby represent and warrant that your User Content does not violate our Acceptable Use Policy (defined in Section 3.3). You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by Company. Because you alone are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy.
                3.2 License. You hereby grant (and you represent and warrant that you have the right to grant) to Company an irrevocable, nonexclusive, royalty-free and fully paid, worldwide license to collect, use, copy, store, transmit, modify, and create derivative works of User Content solely to the extent necessary to provide the Spacequant Service and related services, and for other development, enhancement, diagnostic and corrective purposes in connection with the Site. You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.
                3.3 Acceptable Use Policy. The following terms constitute our "Acceptable Use Policy":
                (a) You agree not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another?s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise objectionable; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.
                (b) In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) use the Site to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Site (or to other computer systems or networks connected to or used together with the Site), whether through password mining or any other means; (vi) harass or interfere with any other user?s use and enjoyment of the Site; or (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that we conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file).`
            }
        ],
        paging: links.policy,
    }
};