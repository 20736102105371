import request from '@utils/request';

const BASE_URL = '/api/v2/user-permission';

export async function getGroups({ size = 20, page = 0, sort } = {}) {
    return request(`${BASE_URL}/group`, {
        params: {
            size,
            page,
            sort,
        },
    });
}

export async function getGroup(id) {
    return request(`${BASE_URL}/group/${id}`);
}

export async function saveGroup(group) {
    const method = group.id ? 'PUT' : 'POST';
    return request(`${BASE_URL}/group`, {
        method,
        body: JSON.stringify(group),
    });
}

export async function addGroupUsers(body) {
    return request(`${BASE_URL}/group/user-add`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function removeGroupUsers(group) {
    return request(`${BASE_URL}/group/user-remove`, {
        method: 'PUT',
        body: JSON.stringify(group),
    });
}

export async function deleteGroup(id) {
    return request(`${BASE_URL}/group/${id}`, {
        method: 'DELETE',
    });
}

export async function getPresets() {
    return request(`${BASE_URL}/preset`);
}
