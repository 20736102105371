import {
    DeletePropertyBuildingApiArg,
    generatedApi,
    UpdateBuildingDocumentsApiArg,
} from '@scc/services/generated/property';

export const PROPERTY_TAGS = {
    BUILDING_LIST: 'buildingList',
} as const;

const enhanceOptions = {
    addTagTypes: [PROPERTY_TAGS.BUILDING_LIST],
    endpoints: {
        getPropertyBuildings: {
            providesTags: [PROPERTY_TAGS.BUILDING_LIST],
        },
        addPropertyBuilding: {
            invalidatesTags: [PROPERTY_TAGS.BUILDING_LIST],
        },
        deletePropertyBuilding: {
            onQueryStarted(arg: DeletePropertyBuildingApiArg, { dispatch, queryFulfilled }) {
                const { propertyId, buildingId } = arg.deletePropertyBuildingRequest;

                const patchResult = dispatch(
                    generatedApi.util.updateQueryData('getPropertyBuildings', { id: propertyId }, (draft) => {
                        const patch = draft?.data?.filter((building) => building.id !== buildingId);

                        Object.assign(draft, { data: patch });
                    })
                );

                queryFulfilled.catch(patchResult.undo);
            },
        },
        updateBuildingDocuments: {
            onQueryStarted(arg: UpdateBuildingDocumentsApiArg, { dispatch, queryFulfilled }) {
                const { propertyId } = arg.body[0];
                const patch = arg.body;

                const patchResult = dispatch(
                    generatedApi.util.updateQueryData('getPropertyBuildings', { id: propertyId }, (draft) => {
                        Object.assign(draft, { data: patch });
                    })
                );

                queryFulfilled.catch(patchResult.undo);
            },
        },
    },
} as const;

export const propertyApi = generatedApi.enhanceEndpoints(enhanceOptions);
