import { useDispatch, useSelector } from 'react-redux';

import { getMapValue } from '@utils/components';
import LoginForm from '@scc/components/LoginForm';
import RequestDemoForm from '@scc/components/RequestDemoForm';
import RegisterForm from '@scc/components/RegisterForm';
import RegisterFormSuccess from '@scc/components/RegisterForm/RegisterFormSuccess';
import RegisterFormComplete from '@scc/components/RegisterForm/RegisterFormComplete';
import ForgotPasswordForm from '@scc/components/ForgotPasswordForm';
import ConfirmPasswordForm from '@scc/components/ConfirmPasswordForm';
import InviteUserForm from '@scc/components/InviteUserForm';
import ForgotPasswordFormSuccess from '@scc/components/ForgotPasswordFormSuccess';
import ConfirmPasswordFormSuccess from '@scc/components/ConfirmPasswordFormSuccess';
import ConfirmInviteFormSuccess from '@scc/components/ConfirmInviteFormSuccess';
import RequestDemoFormSuccess from '@scc/components/RequestDemoForm/RequestDemoFormSuccess';

import { selectStatusAuthMngState, setTemplateAuthMng } from '@scc/store/ducks/authManager';
import { useMemo } from 'react';
import Alert from '@ui/Alert';
import Icon from '@ui/Icon';
import InfoCircleSvg from '@icons/outlined/info-circle.svg';
import Typography from '@ui/Typography';

const DEFAULT_TEMPLATE = 'register';
const cred = {};

const authFlowMap = new Map([
    ['register', RegisterForm],
    ['login', LoginForm],
    ['requestDemo', RequestDemoForm],
    ['forgotPassword', ForgotPasswordForm],
    ['confirmPassword', ConfirmPasswordForm],
    ['inviteUser', InviteUserForm],
    ['registered', RegisterFormSuccess],
    ['complete', RegisterFormComplete],
    ['forgotPasswordSuccess', ForgotPasswordFormSuccess],
    ['confirmPasswordSuccess', ConfirmPasswordFormSuccess],
    ['confirmInviteSuccess', ConfirmInviteFormSuccess],
    ['requestDemoSuccess', RequestDemoFormSuccess],
    [
        'requestDemoAlertError',
        ({ data: { status, message = 'Ooops' } }) => (
            <Alert variant='outlined' severity='warning' icon={<Icon component={InfoCircleSvg} fontSize='lg' />}>
                <Typography variant='pr16'>{status}</Typography>
                <Typography variant='pr16'>{message}</Typography>
            </Alert>
        ),
    ],
]);

const LoginBase = function ({ onSuccess, onClose, loading }) {
    const dispatch = useDispatch();
    const { template, data } = useSelector(selectStatusAuthMngState);

    const onStepChange = (step) => {
        dispatch(setTemplateAuthMng({ name: step }));
    };
    const Form = useMemo(() => getMapValue(authFlowMap, template, DEFAULT_TEMPLATE), [template]);

    return (
        <Form
            name={template}
            user={cred}
            data={data}
            onStepChange={onStepChange}
            onSuccess={onSuccess}
            onClose={onClose}
            loading={loading}
        />
    );
};

export default LoginBase;
