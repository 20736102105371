import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PaymentCard from '@scc/components/PaymentCard';
import { selectUserInfo } from '@scc/store/ducks/auth';
import { addUserCardProperty, selectPropertyState, setStatusProperty } from '@scc/store/ducks/property';

import Icon from '@ui/Icon';
import List from '@ui/List';
import ListItem from '@ui/ListItem';
import ListItemIcon from '@ui/ListItemIcon';
import ListItemText from '@ui/ListItemText';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import StripePayment from '@components/StripePayment';

import colors from '@themes/palette/export.module.scss';

import TickCircleIcon from '@assets/icons/outlined/tick-circle.svg';
import InfoIcon from '@shared/assets/icons/outlined/info-circle.svg';

import styles from './styles.module.scss';

const TYPES = {
    loan: {
        price: (0).toFixed(2),
        benefits: [
            'Independent property valuation',
            'Comprehensive analysis of property financial and operating performance, local market analysis',
            'Most attractive loan quotes negotiated with best lenders for this deal',
        ],
    },
    valuation: {
        price: (199).toFixed(2),
        benefits: [
            'Independent property valuation delivered within hours, not days',
            'Comprehensive analysis of property financial and operating performance, local market analysis',
            'Valuation report cost is refunded if you close a loan with us',
        ],
    },
};

function PaymentTooltip() {
    return (
        <div className={styles.tooltip}>
            <Typography variant='pr14'>
                Smart Capital Center requires a credit card as part of the verification process before getting loan
                quotes.
            </Typography>
            <Typography variant='pr14'>Your card will not be charged.</Typography>
        </div>
    );
}

function Payment({ formId }) {
    const dispatch = useDispatch();
    const { type } = useSelector(selectPropertyState);

    const { card = {} } = useSelector(selectUserInfo);
    const handleCardSubmit = (data) => dispatch(addUserCardProperty(data));
    const [fields, setFields] = useState({});

    const isFieldsComplete = useMemo(() => {
        const allFields = Object.values(fields);
        return allFields.length > 2 && !allFields.some(({ complete } = {}) => !complete);
    }, [fields]);

    useEffect(() => {
        dispatch(setStatusProperty(isFieldsComplete || card.last4 ? 'completed' : null));
    }, [card, isFieldsComplete]);

    const handleCardChange = (data) => {
        setFields((state) => ({ ...state, [data.elementType]: data }));
    };

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <div className={styles.cardWrapper}>
                <div className={styles.priceWrapper}>
                    <Typography variant='h1b'>${TYPES[type]?.price}</Typography>
                    <Typography color='text.secondary' variant='pr12'>
                        100% money back guarantee
                    </Typography>
                </div>

                <StripePayment>
                    <PaymentCard
                        brand={card.brand}
                        cardName={card.cardName}
                        id={formId}
                        isEditable={!card.last4}
                        last4={card.last4}
                        onChange={handleCardChange}
                        onSubmit={handleCardSubmit}
                    />
                </StripePayment>
                {type === 'loan' && !isMobile && (
                    <div className={styles.noteWrapper}>
                        <Typography color={colors['grey-400']} variant='pr12'>
                            Why should I give card details
                        </Typography>
                        <Tooltip title={<PaymentTooltip />}>
                            <span>
                                <Icon color={colors['grey-200']} component={InfoIcon} fontSize='14px' />
                            </span>
                        </Tooltip>
                    </div>
                )}
            </div>

            <div className={styles.notes}>
                <Typography
                    style={!isMobile ? { textTransform: 'upperCase' } : null}
                    variant={!isMobile ? 'hsb13' : 'h5sb'}
                >
                    What do you get
                </Typography>

                <List dense>
                    {TYPES[type]?.benefits?.map((text) => (
                        <ListItem key={text}>
                            <ListItemIcon sx={{ minWidth: '24px' }}>
                                <Icon color='success' component={TickCircleIcon} />
                            </ListItemIcon>
                            <ListItemText
                                secondary={
                                    <Typography component='span' variant={!isMobile ? 'hsb14' : 'pr'}>
                                        {text}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    );
}

export default Payment;
