import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { memoStore } from '@utils/store';

import { apiMiddlewares } from '@scc/services';

export const createRootReducer = (reducers) => combineReducers(reducers);

function createStore(initialState, reducers) {
    const sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
        reducer: reducers,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }).concat([...apiMiddlewares, sagaMiddleware]),
        devTools: true,
    });

    setupListeners(store.dispatch);

    return { store, sagaMiddleware };
}

export default memoStore(createStore);
