export const defaultGroupingConfig = [
    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: 'Quarterly',
        value: 'quarterly',
    },
    {
        label: 'Annually',
        value: 'annually',
    },
];

export const groupingConfigFilter = (data, config = defaultGroupingConfig) =>
    config.filter(({ value }) => value in data && data[value]?.length > 0);
