import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { formatDate, isValidDate } from '@utils/dates';

import Typography from '@ui/Typography';

const dateFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

export function formatDetailsDateValue(value: number | string): ReactNode {
    if (!value || value === '-' || !isValidDate(value)) {
        return null;
    }

    const [date, month, year] = formatDate(value, dateFormatOptions).split(' ');

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '4px',
            }}
        >
            <Typography variant='h6sb'>{`${date} ${month}`}</Typography>
            <Typography variant='hr13'>{year}</Typography>
        </Box>
    );
}
