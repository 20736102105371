import { createSlice, createSelector } from '@reduxjs/toolkit';
import { put, select, take, delay, takeLatest } from 'redux-saga/effects';

import { getMemberRequestDataAdapter } from '@utils/store/middleware';

import { membersApi, loansApi } from '@api';
import { MEMBER_LOAN } from '@constants/loans';
import { EDIT_LOAN_FORM } from '@constants/modals';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { GET_EVENT } from '@store/ducks/event';
import { showPrimaryAsideDrawer, hideDrawer } from '@store/ducks/ui/drawer';

const entity = '[member]';
const size = 20;

const initialState = {
    totalCount: 0,
    data: [],
    member: {
        userProfile: {},
        contacts: {},
    },
    requestMember: {
        userProfile: {},
        contacts: {},
    },
    loans: {
        totalCount: 0,
        data: [],
        search: {},
    },
    owners: {
        totalCount: 0,
        data: [],
    },
    search: {},
    loaded: false,
    loading: false,
};

const memberSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadOwners(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadMoreOwners() {},
        addMoreOwners(state, action) {
            state.data = action.payload;
        },
        setOwners(state, action) {
            const { data, totalCount } = action.payload;
            state.owners.data = data;
            state.owners.totalCount = totalCount;
            state.loaded = true;
            state.loading = false;
        },
        loadMember(state) {
            state.loaded = false;
            state.loading = true;
        },
        setMember(state, { payload }) {
            state.member = payload;
            state.loaded = true;
            state.loading = false;
        },
        setRequestMember(state, { payload }) {
            state.requestMember = payload;
            state.loaded = true;
            state.loading = false;
        },
        updateMember(state) {
            state.loaded = true;
            state.loading = false;
        },
        setLoans(state, { payload }) {
            state.loans = payload;
            state.loaded = true;
            state.loading = false;
        },
        setLoan(state, action) {
            state.current = action.payload || null;
        },
        loadLoans(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadLoan(state, action) {
            state.loan = action.payload;
            state.loaded = false;
            state.loading = true;
        },
        loadMoreLoans() {},
        addMoreLoans(state, action) {
            const normalized = action.payload.map(({ jsonValues, ...item }) => ({
                ...item,
                jsonValues: jsonValues ? JSON.parse(jsonValues) : {},
            }));
            state.loans.data.push(...normalized);
        },
        filterLoans(state, action) {
            state.loans.search = action.payload;
            state.data = [];
            state.loaded = false;
            state.loading = true;
        },
        editLoan(state, action) {
            state.current = action.payload;
        },
        deleteLoan(state) {
            state.loaded = false;
            state.loading = true;
        },
        addExistingLoan(state) {
            state.loaded = false;
            state.loading = true;
        },
        updateUserMember(state, action) {
            const { memberType, values } = action.payload;

            state.member[memberType].member = {
                ...state.member[memberType].member,
                ...values,
            };
        },
    },
});

export const {
    setOwners,
    loadOwners,
    loadMoreOwners,
    addMoreOwners,
    loadMember,
    setMember,
    setRequestMember,
    updateMember,
    updateUserMember,
    setLoans: setMemberLoans,
    setLoan: setMemberLoan,
    loadLoans: loadMemberLoans,
    loadLoan: loadMemberLoan,
    loadMoreLoans: loadMoreMemberLoans,
    addMoreLoans: addMoreMemberLoans,
    filterLoans: filterMemberLoans,
    editLoan: editMemberLoan,
    deleteLoan: deleteMemberLoan,
    addExistingLoan: addExistingMemberLoan,
} = memberSlice.actions;

export default memberSlice.reducer;

export const selectMemberState = (store) => store.members.member;

export const selectMemberLoansState = (store) => store.members.member.loans;

export const selectMemberOwnersState = (store) => store.members.member.owners;

export const selectCurrentMemberLoan = createSelector(
    selectMemberLoansState,
    ({ data, current }) => data.find(({ id } = {}) => id === current) || {}
);

function* loadOwnersSaga({ payload, type }) {
    yield delay(400);
    const { data: prevData } = yield select(selectMemberOwnersState);
    const { memberId } = payload;
    const data = { size, page: Math.floor(prevData.length / size), memberId };
    yield put(apiRequest(data, membersApi.getOwners, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setOwners({ data: content, totalCount: totalElements }));
}

function* loadMoreOwnersSaga({ type }) {
    const { data: prevData } = yield select(selectMemberState);
    yield put(apiRequest({ page: prevData.length / size }, membersApi.getMembers, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;

    const resContent = content.map(({ ...item }) => ({
        ...item,
    }));
    yield put(addMoreOwners([...prevData, ...resContent]));
}

function* loadMemberSaga({ payload, type }) {
    yield put(apiRequest(payload, membersApi.getMember, type));
    const {
        payload: { data },
    } = yield take(`${type} ${API_SUCCESS}`);

    const member = getMemberRequestDataAdapter(data);
    yield put(setMember(data));
    yield put(setRequestMember(member));
}

function* updateMemberSaga({ type, payload }) {
    yield delay(400);
    yield put(apiRequest(payload, membersApi.updateMember, type));
    const {
        payload: { data },
    } = yield take(`${type} ${API_SUCCESS}`);
    const member = getMemberRequestDataAdapter(data);
    yield put(setMember(data));
    yield put(setRequestMember(member));
}

function* loadMemberLoansSaga({ payload, type }) {
    yield delay(400);
    yield put(apiRequest(payload, membersApi.getMemberLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(
        setMemberLoans({
            data: content.map(({ jsonValues, ...item }) => ({
                ...item,
                jsonValues: jsonValues ? JSON.parse(jsonValues) : {},
            })),
            totalCount: totalElements,
        })
    );
}

function* loadMoreMemberLoansSaga({ payload, type }) {
    const { data: prevData, search = {} } = yield select(selectMemberLoansState);
    yield put(apiRequest({ ...search, page: prevData.length / size, ...payload }, membersApi.getMemberLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(addMoreMemberLoans(action.payload.data.content));
}

function* filterLoansSaga({ type, payload }) {
    yield delay(400);
    yield put(apiRequest(payload, membersApi.filterMemberLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setMemberLoans({ data: content, totalCount: totalElements }));
}

function* editMemberLoanSaga({ payload }) {
    yield put(setMemberLoan(payload));
    yield put(
        showPrimaryAsideDrawer({
            content: EDIT_LOAN_FORM,
            data: { label: 'Edit Loan', title: payload?.propertyName || '', loan: payload, loanType: 'member' },
        })
    );
}

function* deleteMemberLoanSaga({ payload, type }) {
    yield put(apiRequest(payload, membersApi.deleteMemberLoan, type));
    yield take(`${type} ${API_SUCCESS}`);

    const { memberId } = payload;
    yield put(loadMemberLoans({ memberId }));
}

function* addExistingMemberLoanSaga({ type, payload }) {
    const { memberId, data } = payload;

    const body = data.map(({ loanDetailsId }) => ({
        loanDetailsId,
        memberId,
    }));

    yield put(apiRequest({ requestData: body }, membersApi.addExistingLoan, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadMemberLoans({ memberId }));
}

function* addMemberLoanSaga({ payload: { data = {} }, type }) {
    const { memberId, ...restData } = data;
    yield put(apiRequest(restData, loansApi.addLoan, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadMemberLoans({ memberId }));
    yield put(hideDrawer());
}

export function* watchMember() {
    yield takeLatest(loadOwners, loadOwnersSaga);
    yield takeLatest(loadMoreOwners, loadMoreOwnersSaga);
    yield takeLatest(loadMember, loadMemberSaga);
    yield takeLatest(loadMemberLoans, loadMemberLoansSaga);
    yield takeLatest(updateMember, updateMemberSaga);
    yield takeLatest(loadMoreMemberLoans, loadMoreMemberLoansSaga);
    yield takeLatest(filterMemberLoans, filterLoansSaga);
    yield takeLatest(editMemberLoan, editMemberLoanSaga);
    yield takeLatest(deleteMemberLoan, deleteMemberLoanSaga);
    yield takeLatest(addExistingMemberLoan, addExistingMemberLoanSaga);

    yield takeLatest(`${MEMBER_LOAN} ${GET_EVENT}`, addMemberLoanSaga);
}
