import { DateTimeFormatOptions } from '@utils/dates';

export const CURRENCY_FORMAT = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
};

export const CURRENCY_FORMAT_ROUND = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};

export const PERCENT_FORMAT_ROUND = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};

export const NUMBER_FORMAT_ROUND = {
    style: 'decimal',
    maximumFractionDigits: 2,
};

export const NUMBER_FORMAT_MIN_ONE_ROUND = {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
};

export const NUMBER_FORMAT_MIN_TWO_ROUND = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
};

export const NUMBER_FORMAT_MIN_FOUR_ROUND = {
    style: 'decimal',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
};

export const NUMBER_FORMAT_MIN_FIVE_ROUND = {
    style: 'decimal',
    minimumFractionDigits: 5,
    maximumFractionDigits: 5,
};

export const DATE_FORMAT = {
    default: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    } as DateTimeFormatOptions,
    noYear: {
        year: 'numeric',
        month: 'short',
        day: undefined,
    } as DateTimeFormatOptions,
};
