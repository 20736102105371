import cx from 'classnames';

import { publicURLParse } from '@utils/url';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';
import Typography from '@ui/Typography';

import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import LocationIcon from '@icons/outlined/location.svg';

import styles from './styles.module.scss';

function SearchPropertyItem({ mainText, secondaryText, propertyType, publicUrl, isLoading, withoutPadding, ...props }) {
    // todo we do not use this event as it rewrites out
    const handleItemClick = (event, url) => {
        if (isLoading) return;
        event.preventDefault();
        if (url) {
            if (typeof window !== 'undefined') {
                window.location.href = publicURLParse(url);
            }
        } else {
            props?.onClick(event);
        }
    };

    return (
        <Box
            className={styles.lineItemWrapper}
            style={{ cursor: isLoading ? 'wait' : 'pointer' }}
            onClick={(event) => !isLoading && handleItemClick(event, publicUrl)}
            {...props}
        >
            <Box className={cx(styles.lineItemContainer, { [styles.noPadding]: withoutPadding })}>
                {isLoading ? (
                    <Grid alignItems='center' container>
                        <Skeleton height={24} variant='circular' width={24} />
                        <Skeleton height='22px' sx={{ marginLeft: '8px' }} width='80px' />
                        <Skeleton height='16px' sx={{ margin: '3px 0 0 8px' }} width='120px' />
                    </Grid>
                ) : (
                    <>
                        {propertyType ? (
                            <PropertyTypeIcon size='medium' type={propertyType || 'Office'} />
                        ) : (
                            <Icon color={colors['grey-400']} component={LocationIcon} fontSize={18} size='small' />
                        )}
                        <Box className={cx(styles.textWrapper, styles.center)}>
                            <Box>
                                {Array.isArray(mainText) ? (
                                    mainText.map((part) => (
                                        <Typography
                                            key={part.text}
                                            component='span'
                                            sx={{ background: part.highlight ? colors['yellow-300'] : 'transparent' }}
                                            variant='pr14'
                                        >
                                            {part.text}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography variant='pr14'>{mainText}</Typography>
                                )}
                            </Box>
                            <Typography color={colors['grey-400']} variant='pr12'>
                                {`, ${secondaryText}`}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}

export default SearchPropertyItem;
