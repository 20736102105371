import Charts from '../index';

export default class AnalyticsCharts extends Charts {
    addData(analytics) {
        this.analytics = analytics || null;
        this.tenants = this.analytics?.data || null;
        this.osData = this.analytics?.osData || null;
        this.underwritingRevenue = this.analytics?.underwritingRevenue || null;
        this.underwritingNoi = this.analytics?.underwritingNoi || null;
        this.underwritingValuation = this.analytics?.underwritingValuation || null;
    }

    getUbUnitMix(customConfig) {
        return super.ubUnitMix(customConfig, this.tenants);
    }

    getSbUnitMix(customConfig) {
        return super.sbUnitMix(customConfig, this.tenants);
    }

    getUbRents(customConfig) {
        return super.ubRents(customConfig, this.tenants);
    }

    getSbRents(customConfig) {
        return super.sbRents(customConfig, this.tenants);
    }

    getOccupancy(customConfig) {
        return super.occupancy(customConfig, this.analytics);
    }

    getRevenue(customConfig, grouping) {
        return super.revenue(customConfig, grouping, this.osData);
    }

    getNoi(customConfig, grouping) {
        return super.noi(customConfig, grouping, this.osData);
    }

    getNoiMargin(customConfig, grouping) {
        return super.noiMargin(customConfig, grouping, this.osData);
    }

    getNewLeases(customConfig) {
        return super.newLeases(customConfig, this.tenants);
    }

    getLeaseExpirations(customConfig) {
        return super.leaseExpirations(customConfig, this.tenants);
    }

    getUbRentsForNewLeasesSF(customConfig) {
        return super.ubRentsForNewLeasesSF(customConfig, this.tenants);
    }

    getUbRentsForNewLeasesUnit(customConfig) {
        return super.ubRentsForNewLeasesUnit(customConfig, this.tenants);
    }

    getUnderwritingRevenue(customConfig) {
        return super.underwritingRevenue(customConfig, this.underwritingRevenue);
    }

    getUnderwritingNOI(customConfig) {
        return super.underwritingNOI(customConfig, this.underwritingNoi);
    }

    getValuation(customConfig) {
        return super.valuation(customConfig, this.underwritingValuation);
    }

    getLeaseExpirationsSF(customConfig) {
        return super.singleLeaseExpirationsSF(customConfig, this.tenants);
    }

    getLeaseExpirationsRent(customConfig) {
        return super.singleLeaseExpirationsRent(customConfig, this.tenants);
    }

    getSbRentsForNewLeasesSF(customConfig) {
        return super.sbRentsForNewLeasesSF(customConfig, this.tenants);
    }

    getSbRentsForNewLeasesUnit(customConfig) {
        return super.sbRentsForNewLeasesUnit(customConfig, this.tenants);
    }
}
