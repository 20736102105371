import Header from '@scc/templates/Base/BaseHeader';
import BaseHeaderMenu from '@scc/templates/Base/BaseHeaderMenu';

import Container from '@ui/Container';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function Hero({ mobile, containerRef }) {
    return (
        <div className={styles.hero}>
            <Header
                appBarProps={{ position: 'static' }}
                containerProps={{
                    maxWidth: 'xl',
                }}
                containerRef={containerRef?.current}
            >
                <BaseHeaderMenu />
            </Header>
            <Container className={styles.container} maxWidth='xl'>
                <div className={styles.wrapper}>
                    <Typography color={colors['deepBlue-500']} component='span' variant={mobile ? 'h1b' : 'head1b'}>
                        A New Kind of Financial Partner
                    </Typography>
                    <Typography color={colors['darkNeutral-500']} component='span' variant={mobile ? 'pr16' : 'h3sb'}>
                        Our dedicated team of experts, powered by technology and AI, offer deep industry knowledge,
                        super-fast execution, and the lowest costs in the industry.
                    </Typography>
                </div>
            </Container>
        </div>
    );
}

export default Hero;
