import BaseIframe from '@components/BaseIframe';

import withMessageChannelIframe from '@shared/hoc/withMessageChannelIframe';

export const VIEWS = {
    SIDE_LIST: 'sideList',
    UPLOAD_DOCUMENTS: 'uploadDocuments',
    PREVIEW_DOCUMENTS: 'previewDocuments',
    VARIANCE_ASSISTANCE: 'varianceAssistance',
    EDIT_MAPPING: 'editMapping',
    EDIT_MAPPING_CONFIRM: 'editMappingConfirm',
    EDIT_BUILDINGS: 'editBuildings',
    EDIT_COMBINED_RR: 'editCombinedRR',
};

export const VIEWS_WITH_GRID_RESIZING = [VIEWS.SIDE_LIST];

export const PERIOD_OPTIONS = [
    {
        label: 'Actual',
        value: 'actual',
    },
    {
        label: 'Normalized',
        value: 'normalized',
    },
    {
        label: 'Adjustments',
        value: 'adjustments',
    },
];

export const TAB_TYPES = {
    FINANCIALS: 'financials-tab',
    RENT_ROLL: 'rentroll-tab',
    DEVELOPMENT_BUDGET: 'development-budget-tab',
} as const;

const mcOptions = {
    type: 'scc-iframe',
    name: 'FileViewerPostMessage',
};

export const COMBINED_COLUMNS_SPECIFIC_FIELDS = ['count', 'month', 'year'];

export const IframeWithMC = withMessageChannelIframe(BaseIframe, mcOptions);
