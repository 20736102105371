import { baseApi as api } from '../configs/dealCloud';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDealListByActiveStatus: build.query<GetDealListByActiveStatusApiResponse, GetDealListByActiveStatusApiArg>({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/active` }),
        }),
        getDealsSortedByYearAndMonth: build.query<
            GetDealsSortedByYearAndMonthApiResponse,
            GetDealsSortedByYearAndMonthApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/all` }),
        }),
        getSortedDealsByYearMonthExcludingEmptyProperty: build.query<
            GetSortedDealsByYearMonthExcludingEmptyPropertyApiResponse,
            GetSortedDealsByYearMonthExcludingEmptyPropertyApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/all/exclude-empty-property-type` }),
        }),
        getDealsByQuotesIssuedSortedByYearAndMonth: build.query<
            GetDealsByQuotesIssuedSortedByYearAndMonthApiResponse,
            GetDealsByQuotesIssuedSortedByYearAndMonthApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/quotes-issued` }),
        }),
        getDealsByTermSheetsIssuedSortedByYearAndMonth: build.query<
            GetDealsByTermSheetsIssuedSortedByYearAndMonthApiResponse,
            GetDealsByTermSheetsIssuedSortedByYearAndMonthApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/term-sheets-issued` }),
        }),
        getDealsByApplicationIssuedSortedByYearAndMonth: build.query<
            GetDealsByApplicationIssuedSortedByYearAndMonthApiResponse,
            GetDealsByApplicationIssuedSortedByYearAndMonthApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/applications-issued` }),
        }),
        getDealListByLastTwelveMonths: build.query<
            GetDealListByLastTwelveMonthsApiResponse,
            GetDealListByLastTwelveMonthsApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/active/ltm` }),
        }),
        getDealsByClosedStatusSortedByYearAndMonth: build.query<
            GetDealsByClosedStatusSortedByYearAndMonthApiResponse,
            GetDealsByClosedStatusSortedByYearAndMonthApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/closed` }),
        }),
        getDealListByUnderReview: build.query<GetDealListByUnderReviewApiResponse, GetDealListByUnderReviewApiArg>({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/under-review` }),
        }),
        getDealListByUnderReviewLastTwelveMonths: build.query<
            GetDealListByUnderReviewLastTwelveMonthsApiResponse,
            GetDealListByUnderReviewLastTwelveMonthsApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/under-review/ltm` }),
        }),
        getDealListByPassedDead: build.query<GetDealListByPassedDeadApiResponse, GetDealListByPassedDeadApiArg>({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/passed-dead` }),
        }),
        getDealListByPassedDeadLastTwelveMonths: build.query<
            GetDealListByPassedDeadLastTwelveMonthsApiResponse,
            GetDealListByPassedDeadLastTwelveMonthsApiArg
        >({
            query: () => ({ url: `/api/v2/dealcloud/data/deals/passed-dead/ltm` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetDealListByActiveStatusApiResponse = /** status 200 Successful operation */ DealDto;
export type GetDealListByActiveStatusApiArg = void;
export type GetDealsSortedByYearAndMonthApiResponse = /** status 200 OK */ GenericJsonResponseMapStringListDealDto;
export type GetDealsSortedByYearAndMonthApiArg = void;
export type GetSortedDealsByYearMonthExcludingEmptyPropertyApiResponse =
    /** status 200 OK */ GenericJsonResponseMapStringListDealDto;
export type GetSortedDealsByYearMonthExcludingEmptyPropertyApiArg = void;
export type GetDealsByQuotesIssuedSortedByYearAndMonthApiResponse =
    /** status 200 OK */ GenericJsonResponseMapStringListDealDto;
export type GetDealsByQuotesIssuedSortedByYearAndMonthApiArg = void;
export type GetDealsByTermSheetsIssuedSortedByYearAndMonthApiResponse =
    /** status 200 OK */ GenericJsonResponseMapStringListDealDto;
export type GetDealsByTermSheetsIssuedSortedByYearAndMonthApiArg = void;
export type GetDealsByApplicationIssuedSortedByYearAndMonthApiResponse =
    /** status 200 OK */ GenericJsonResponseMapStringListDealDto;
export type GetDealsByApplicationIssuedSortedByYearAndMonthApiArg = void;
export type GetDealListByLastTwelveMonthsApiResponse = /** status 200 OK */ GenericJsonResponseListDealDto;
export type GetDealListByLastTwelveMonthsApiArg = void;
export type GetDealsByClosedStatusSortedByYearAndMonthApiResponse =
    /** status 200 OK */ GenericJsonResponseMapStringListDealDto;
export type GetDealsByClosedStatusSortedByYearAndMonthApiArg = void;
export type GetDealListByUnderReviewApiResponse = /** status 200 Successful operation */ DealDto;
export type GetDealListByUnderReviewApiArg = void;
export type GetDealListByUnderReviewLastTwelveMonthsApiResponse = /** status 200 Successful operation */ DealDto;
export type GetDealListByUnderReviewLastTwelveMonthsApiArg = void;
export type GetDealListByPassedDeadApiResponse = /** status 200 Successful operation */ DealDto;
export type GetDealListByPassedDeadApiArg = void;
export type GetDealListByPassedDeadLastTwelveMonthsApiResponse = /** status 200 Successful operation */ DealDto;
export type GetDealListByPassedDeadLastTwelveMonthsApiArg = void;
export type DealDto = {
    entryId?: number;
    dealName?: string;
    loanAmount?: string;
    tremontLoanAmount?: string;
    newDealDate?: number;
    estimatedClosingDate?: number;
    quotedDate?: number;
    termSheetIssuedDate?: number;
    applicationIssuedDate?: number;
    closedDealDate?: number;
    passedDeadDate?: number;
    propertyType?: string;
    propertyAddress?: string;
    program?: string;
    stage?: string;
    furthestStage?: string;
    status?: string;
    fsstacked1?: string;
    fsstacked2?: string;
    fsstacked3?: string;
    fsstacked4?: string;
    fsstacked5?: string;
    fsstackedClosed?: string;
    sponsor?: string;
    sponsorGlobalGeography?: string;
    originator1?: string;
    underwriter?: string;
    broker?: string;
    analyst?: string;
    lostDealReasons?: string;
    passedDeadCommentary?: string;
    dealType?: string;
};
export type GenericJsonResponseMapStringListDealDto = {
    status?: string;
    data?: {
        [key: string]: DealDto[];
    };
    message?: string;
    code?: string;
};
export type GenericJsonResponseListDealDto = {
    status?: string;
    data?: DealDto[];
    message?: string;
    code?: string;
};
export const {
    useGetDealListByActiveStatusQuery,
    useGetDealsSortedByYearAndMonthQuery,
    useGetSortedDealsByYearMonthExcludingEmptyPropertyQuery,
    useGetDealsByQuotesIssuedSortedByYearAndMonthQuery,
    useGetDealsByTermSheetsIssuedSortedByYearAndMonthQuery,
    useGetDealsByApplicationIssuedSortedByYearAndMonthQuery,
    useGetDealListByLastTwelveMonthsQuery,
    useGetDealsByClosedStatusSortedByYearAndMonthQuery,
    useGetDealListByUnderReviewQuery,
    useGetDealListByUnderReviewLastTwelveMonthsQuery,
    useGetDealListByPassedDeadQuery,
    useGetDealListByPassedDeadLastTwelveMonthsQuery,
} = injectedRtkApi;
