import { clamp, round, floor, filter, meanBy, isFinite } from 'lodash';

const k = 1000;

const labelMap = [
    { label: '', divider: 1 },
    { label: 'K', divider: k },
    { label: 'M', divider: k * k },
    { label: 'B', divider: k * k * k },
];

function log(n, base) {
    return Math.log(n) / (base ? Math.log(base) : 1);
}

const median = (array) => {
    array.sort((a, b) => b - a);
    // eslint-disable-next-line prefer-destructuring
    const length = array.length;
    if (length % 2 === 0) {
        return (array[length / 2] + array[length / 2 - 1]) / 2;
    }
    return array[Math.floor(length / 2)];
};

export const calculateTransactionsAverages = function (transactions = []) {
    let averagePrice = 0;
    let pricePerUnitLow = 0;
    let pricePerSqftLow = 0;
    let pricePerUnitHigh = 0;
    let pricePerSqftHigh = 0;
    if (transactions.length) {
        const filteredTransactions = filter(transactions, (item) => item.price);

        if (filteredTransactions.length) {
            averagePrice = median(filteredTransactions.map(({ price }) => price));
        }
        pricePerUnitLow = meanBy(
            filter(transactions, ({ pricePerUnitLow: tPricePerUnitLow }) => !!tPricePerUnitLow),
            ({ pricePerUnitLow: tPricePerUnitLow }) => tPricePerUnitLow
        );
        pricePerSqftLow = meanBy(
            filter(transactions, ({ pricePerSqftLow: tPricePerSqftLow }) => !!tPricePerSqftLow),
            ({ pricePerSqftLow: tPricePerSqftLow }) => tPricePerSqftLow
        );
        pricePerUnitHigh = meanBy(
            filter(transactions, ({ pricePerUnitHigh: tPricePerUnitHigh }) => !!tPricePerUnitHigh),
            ({ pricePerUnitHigh: tPricePerUnitHigh }) => tPricePerUnitHigh
        );
        pricePerSqftHigh = meanBy(
            filter(transactions, ({ pricePerUnitHigh: tPricePerUnitHigh }) => !!tPricePerUnitHigh),
            ({ pricePerSqftHigh: tPricePerUnitHigh }) => tPricePerUnitHigh
        );
    }

    return {
        minPrice: averagePrice * 0.9,
        maxPrice: averagePrice * 1.1,
        averagePrice,
        pricePerUnitLow,
        pricePerSqftLow,
        pricePerUnitHigh,
        pricePerSqftHigh,
    };
};

export const calcTransactionsAverages = function (transactions = []) {
    let unitPrice = 0;
    let sfPrice = 0;
    if (transactions.length) {
        const positiveUnitPrices = filter(transactions, ({ unitPrice: tUnitPrice }) => !!tUnitPrice);
        const positiveSqFtPrices = filter(transactions, ({ sqftPrice: tSqftPrice }) => !!tSqftPrice);
        unitPrice = meanBy(positiveUnitPrices, ({ unitPrice: pUnitPrice }) => pUnitPrice);
        sfPrice = meanBy(positiveSqFtPrices, ({ sqftPrice: pSqftPrice }) => pSqftPrice);
    }
    return {
        unitPrice,
        sfPrice,
    };
};

export const calculateValuationsRange = (estimationFull, estimationError) => {
    // todo
    // 10% of the number
    // % must take a seat in the estimationError
    const percentageOfEstimate = estimationFull * 0.1;
    return {
        minPrice: estimationFull - percentageOfEstimate,
        maxPrice: estimationFull + percentageOfEstimate,
        estimationFull,
        estimationError,
    };
};

export const calculateValuationValue = function (value, size = 1) {
    return floor(parseFloat(value) * parseFloat(size));
};

export const formatValuationValue = function (initialValue, size = 1) {
    const isMinus = initialValue < 0;
    const value = Math.abs(initialValue);
    let formattedValue;

    if (isFinite(parseFloat(value)) && isFinite(parseFloat(size))) {
        formattedValue = parseFloat(value) * parseFloat(size);
        const mapIndex = clamp(floor(log(formattedValue, k)), 0, labelMap.length - 1);
        const { divider = 1, label } = labelMap[mapIndex] || {};
        const resValue = formattedValue / divider;
        formattedValue = round(resValue, 1) + label;
    }

    return (isMinus ? '-' : '') + formattedValue;
};
