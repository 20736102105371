import download from 'downloadjs';

import request, { downloadDocument } from '@utils/request';

import { FinancialColumn } from '@scc/services/generated/grid';
import { DocumentLightDto } from '@scc/services/generated/propertyDetails';

const PROPERTY_BASE_URL = '/api/v2/property';
const STATEMENT_BASE_URL = '/api/v2/grid/statement';
const RENT_ROLL_BASE_URL = '/api/v2/property-details/processed-documents/rent-roll';

export async function getRentRollAnalytics({ rrid, userid }: { rrid: number; userid: number }) {
    return request(`/geoGekko/getRentRollAnalytics?rrid=${rrid}&userid=${userid}`);
}

export async function saveLimitedRentRoll({ data, signal }: { data: unknown; signal: AbortController['signal'] }) {
    return request(`/geoGekko/saveLimitedRentRoll`, {
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
}

export async function getOsStandardizedGrid(entityId: number) {
    return request(`${STATEMENT_BASE_URL}/preprocess/${entityId}`);
}

export async function getOsStandardizedCombinedGrid(combinedId: number) {
    return request(`${STATEMENT_BASE_URL}/preprocess/combined/${combinedId}`);
}

export async function saveOsStandardizedGrid({ data, signal }: { data: unknown; signal: AbortController['signal'] }) {
    return request(`${STATEMENT_BASE_URL}/save?autoConfirm=true`, {
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
}

export async function saveOsStandardizedCombinedGrid({
    combinedId,
    data,
    signal,
}: {
    combinedId: string;
    data: unknown;
    signal: AbortController['signal'];
}) {
    return request(`${STATEMENT_BASE_URL}/preprocess/combined/${combinedId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
}

export async function getProformaGrid({
    rentRollLoanId,
    proformaAsOfDate,
    clearDate,
    regenerateRefIDs,
    rrId,
    combinedColumnId,
}: {
    rentRollLoanId: number;
    proformaAsOfDate?: number;
    clearDate?: boolean;
    regenerateRefIDs?: boolean;
    rrId?: string;
    combinedColumnId?: string;
}) {
    return request(`${STATEMENT_BASE_URL}/preprocess/proforma/${rentRollLoanId}`, {
        params: {
            proformaAsOfDate,
            clearDate,
            regenerateRefIDs,
            rrId,
            combinedColumnId,
        },
    });
}

export async function saveProformaGrid({
    data,
    signal,
    rentRollLoanId,
}: {
    data: unknown;
    signal: AbortController['signal'];
    rentRollLoanId: number;
}) {
    return request(`${STATEMENT_BASE_URL}/preprocess/proforma/${rentRollLoanId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
}

type TExportExcelPayload = {
    rentRollLoanId: number;
    asOfDate?: number;
    transformName?: (name: string) => string;
};

export async function downloadProformaExcel({ rentRollLoanId, asOfDate, transformName }: TExportExcelPayload) {
    const exportProformaUrl = `${STATEMENT_BASE_URL}/export/preprocess/proforma`;

    const response = await fetch(`${exportProformaUrl}/${rentRollLoanId}${asOfDate ? `?asOfDate=${asOfDate}` : ''}`);
    const [, fileName] = response.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await response.blob();
    download(blob, transformName?.(fileName) ?? fileName);
}

export async function downloadOSExcel({
    osId,
    transformName,
}: {
    osId: number;
    transformName?: (name: string) => string;
}) {
    const response = await fetch(`${STATEMENT_BASE_URL}/export/preprocess/${osId}`);

    const [, fileName] = response.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await response.blob();
    download(blob, transformName?.(fileName) ?? fileName);
}

export async function downloadOSCombinedExcel({
    combinedId,
    transformName,
}: {
    combinedId: number | null | undefined;
    transformName?: (name: string) => string;
}) {
    const response = await fetch(`${STATEMENT_BASE_URL}/export/preprocess/combined/${combinedId}`);

    const [, fileName] = response.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await response.blob();
    download(blob, transformName?.(fileName) ?? fileName);
}

export async function downloadAdjustments({
    combinedId,
    payload,
}: {
    combinedId: number | null | undefined;
    transformName?: (name: string) => string;
    payload?: FinancialColumn;
}) {
    const response = await request(`${STATEMENT_BASE_URL}/export/preprocess/combined/${combinedId}/adjustments`, {
        method: 'POST',
        responseType: 'blob',
        body: JSON.stringify(payload),
    });

    downloadDocument(response.response, response.file);
}

export async function downloadOSVarianceExcel({
    varianceId,
    transformName,
}: {
    varianceId: number | null | undefined;
    transformName?: (name: string) => string;
}) {
    const response = await fetch(`${STATEMENT_BASE_URL}/export/preprocess/variance/${varianceId}`);

    const [, fileName] = response.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await response.blob();
    download(blob, transformName?.(fileName) ?? fileName);
}

export async function getProformaRentRollsDocuments(
    propertyId: number,
    confirmed?: boolean
): Promise<{
    data: DocumentLightDto[];
}> {
    return request(RENT_ROLL_BASE_URL, {
        params: {
            propertyId,
            confirmed,
        },
    });
}

export async function saveOsVarianceReport({ data, signal }: { data: unknown; signal: AbortController['signal'] }) {
    return request(`${PROPERTY_BASE_URL}/variance-report`, {
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
}

export async function updateOsVarianceReport({ data, signal }: { data: unknown; signal: AbortController['signal'] }) {
    return request(`${PROPERTY_BASE_URL}/variance-report`, {
        method: 'PUT',
        body: JSON.stringify(data),
        signal,
    });
}

export function getPlStatementJson(payload) {
    return request(`/pl/statement/json`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}

export function savePlStatementJson(payload) {
    return request(`/pl/statement/push`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}

export function getJllStatementJson(payload) {
    return request(`/jll/statement/json`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}

export function saveJllStatementJson(payload) {
    return request(`/jll/statement/push`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}
