import { put, take, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { rrid, osid } from '@utils/store';
import { detailedProperty } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import Charts from '@utils/charts';

const entity = '[detailedPropertyFinancials]';

const initialState = {
    loaded: false,
    loading: false,
    documentsLoading: false,
    documentsLoaded: false,
    analyticsLoading: false,
    analyticsLoaded: false,
    chartsLoaded: false,
    data: {
        documents: {},
        analytics: {},
        charts: {},
    },
    unitMix: {
        sort: 'unitType',
        filter: 'all',
    },
};

const financialsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
            state.loaded = false;
        },
        loaded(state) {
            state.loading = false;
            state.loaded = true;
        },
        loadDocuments(state) {
            state.documentsLoading = true;
            state.documentsLoaded = false;
        },
        loadAnalytics(state) {
            state.analyticsLoading = true;
            state.analyticsLoaded = false;
        },
        setDocuments(state, { payload }) {
            state.data.documents = payload;
            state.documentsLoading = false;
            state.documentsLoaded = true;
        },
        setAnalytics(state, { payload }) {
            state.data.analytics = payload;
            state.analyticsLoading = false;
            state.analyticsLoaded = true;
        },
        setCharts(state, { payload }) {
            state.data.charts = payload;
            state.chartsLoaded = true;
        },
        setUnitMix(state, action) {
            state.unitMix = { ...state.unitMix, ...action?.payload };
        },
    },
});

export const {
    load: loadMainData,
    loaded: loadedMainData,
    loadDocuments: loadDocumentsData,
    loadAnalytics: loadAnalyticsData,
    setDocuments: setDocumentsData,
    setAnalytics: setAnalyticsData,
    setCharts: setChartsData,
    setUnitMix: setUnitMixAnalytics,
} = financialsSlice.actions;

export default financialsSlice.reducer;

export const selectDetailedFinancialsState = (store) => store.detailedProperty.financials;

function* loadDocumentsDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getDocuments, type));
    const documentsAction = yield take(`${type} ${API_SUCCESS}`);
    const {
        payload: {
            data: { processedDocuments: documentsData },
        },
    } = documentsAction;
    yield put(setDocumentsData(documentsData));
}
function* loadAnalyticsDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getAnalytics, type));
    const analyticsAction = yield take(`${type} ${API_SUCCESS}`);
    const {
        payload: {
            data: { analytics: analyticsData },
        },
    } = analyticsAction;
    const charts = new Charts(analyticsData);
    yield put(setChartsData(charts));
    yield put(setAnalyticsData(analyticsData));
}

function* loadMainDataSaga({ payload }) {
    yield put(loadDocumentsData(payload));
    const documents = yield take(setDocumentsData);
    const {
        payload: { rentRollList, rentRollStatementCombined: { combinedOsid } = '', rentRollStatementList },
    } = documents;

    const analyticRequestData = {
        ...rrid(rentRollList),
        ...osid(rentRollStatementList),
        combinedOsid: combinedOsid || '',
    };

    yield put(loadAnalyticsData(analyticRequestData));
    yield put(loadedMainData());
}

export function* watchFinancials() {
    yield takeLatest(loadMainData, loadMainDataSaga);
    yield takeLatest(loadDocumentsData, loadDocumentsDataSaga);
    yield takeLatest(loadAnalyticsData, loadAnalyticsDataSaga);
}
