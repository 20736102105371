import { useSelector } from 'react-redux';

import { selectIsAdmin } from '@scc/store/ducks/auth';

import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import { text as blockedBoxText } from '@components/BlockedBox/BlockedBox';

import styles from './styles.module.scss';

function BlockedControl({ isLimited, children, placement, tooltipText, allUsers = false }) {
    const { isAdmin } = useSelector(selectIsAdmin);

    const tooltipTitle = (
        <div className={styles.tooltipContent}>
            <Typography variant='hsb10'>{blockedBoxText.title}</Typography>
            <Typography variant='hr10'>{blockedBoxText.description}</Typography>
        </div>
    );

    // TODO: Remove isAdmin when all permissions are added to the backend
    if (isLimited && (!isAdmin || allUsers)) {
        return (
            <Tooltip placement={placement || 'top'} title={tooltipText ?? tooltipTitle} arrow>
                <span>{children}</span>
            </Tooltip>
        );
    }

    return children;
}

export default BlockedControl;
