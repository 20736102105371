import { memo } from 'react';

import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import styles from './styles.module.scss';

function ContentCard({ icon, title, children, style }) {
    return (
        <div className={styles.contentCard} style={style}>
            <div className={styles.contentCardHeader}>
                <Typography variant='h1b'>
                    <Icon className={styles.contentCardHeaderIcon} component={icon} fontSize='23px' />
                    {title}
                </Typography>
            </div>
            <div>{children}</div>
        </div>
    );
}

export default memo(ContentCard);
