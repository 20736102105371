import config from '@config';

const RE_CAPTCHA_KEY = typeof process !== 'undefined' && process.env.RE_CAPTHA_KEY;

export const isBrowser = typeof window !== 'undefined' || !config.get('isServer');

export const isServer = !isBrowser;

export const loadData = (key) => {
    if (isServer) return;
    try {
        const serializedState = window.localStorage.getItem(key);
        // eslint-disable-next-line consistent-return
        return JSON.parse(serializedState);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const saveData = (key, state) => {
    if (isServer) return;
    try {
        window.localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export const createExternalLink = (pathname, search, host) => {
    try {
        const origin = isBrowser ? window.location.hostname : config.get('domain');
        const url = new URL(`https://${host || origin}${pathname}`);
        url.search = typeof search === 'string' ? search : new URLSearchParams(search).toString();
        return url.toString();
    } catch (e) {
        console.warn(e);
        return undefined;
    }
};

export const getElementHtmlById = (id) => isBrowser && document.getElementById(id)?.innerHTML;

export const makeResponsiveFontSize = ({ node, minSize = 10, maxSize = 30, step = 1, unit = 'px' }) => {
    if (node) {
        let size = minSize;
        let overflow = false;
        const parent = node.parentNode;

        while (!overflow && size < maxSize) {
            // eslint-disable-next-line no-param-reassign
            node.style.fontSize = `${size}${unit}`;
            overflow = node.clientWidth > parent.clientWidth;

            if (!overflow) size += step;
        }
        // eslint-disable-next-line no-param-reassign
        node.style.fontSize = `${size - step}${unit}`;
    }
};

export const openExternalWindow = ({ url, target = '_blank', options = 'noopener,noreferrer' }) =>
    isBrowser && window.open(url, target, options);

export const errorCaptcha = () => {
    // eslint-disable-next-line no-console
    console.error('Captcha error');
};

export const renderCaptcha = ({ container = '', verify = () => {}, error = errorCaptcha }) => {
    if (isBrowser) {
        window.grecaptcha?.render(container, {
            sitekey: RE_CAPTCHA_KEY,
            callback: verify,
            'error-callback': error,
        });
    }
};

export const addScript = ({ src, id, async, defer, onLoad, node = 'body' }) => {
    const existing = document.getElementById(id);
    if (isServer) {
        return null;
    }

    if (existing) {
        return existing;
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = async;
    script.defer = defer;
    script.onload = onLoad;
    document[node]?.appendChild(script);
    return script;
};

export const removeScript = (id) => {
    const script = document.getElementById(id);
    if (script) {
        document.body.removeChild(script);
    }
};

export const getURLParameter = (sParam) => {
    if (isServer) return;
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');
    for (let i = 0; i < sURLVariables.length; i += 1) {
        const sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            // eslint-disable-next-line consistent-return
            return sParameterName[1];
        }
    }
};

export const createUrl = ({ origin, path, search = '', stringSearch, hash } = {}) => {
    if (typeof window === 'undefined') return '';

    try {
        const originUrl = origin || window.location.origin;
        const params = new window.URLSearchParams(search);
        const src = new window.URL(originUrl);
        if (path) src.pathname = path;
        if (params || stringSearch) src.search = `?${[params, stringSearch].filter(Boolean).join('&')}`;
        if (hash) src.hash = hash;
        return src.href;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return '';
    }
};

export const isInsideIframe = () => {
    if (typeof window === 'undefined') return false;
    return window.location !== window.parent.location;
};
