import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import links from '@scc/router/links';

import Button from '@ui/Button';
import Container from '@ui/Container';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { TOP_FOOTER_DATA } from './helpers';
import styles from './styles.module.scss';

function HomeTop({ mobile }) {
    const location = useLocation();

    const { title, slogan, buttonLabel } = TOP_FOOTER_DATA;
    return (
        <div className={styles.wrapper}>
            <Container maxWidth='xl'>
                <div className={styles.container}>
                    <div className={styles.sloganWrapper}>
                        <Typography className={styles.title} color={colors.white} component='span'>
                            {title}
                        </Typography>

                        <div className={styles.aWrapper}>
                            <div className={cx(styles.aWords, styles.aWords1)}>
                                <Typography classes={{ root: styles.sliderText }} color={colors.white} component='span'>
                                    Invest
                                </Typography>
                                <Typography classes={{ root: styles.sliderText }} color={colors.white} component='span'>
                                    Finance
                                </Typography>
                            </div>
                            <Typography className={styles.title} color={colors.white} component='span'>
                                {slogan}
                            </Typography>
                        </div>
                    </div>

                    <Button
                        aria-label='Request Demo'
                        className={styles.button}
                        component={Link}
                        fullWidth={mobile}
                        rel='nofollow'
                        size={mobile ? 'extra-large' : 'large'}
                        to={{
                            state: { from: location },
                            pathname: links.register.path,
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </Container>
        </div>
    );
}

export default HomeTop;
