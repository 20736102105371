import pages from '@scc/router/links';

export const SCC_LIMITED_TABS_LIST = [
    pages.login.name,
    pages.dashboard.name,
    pages.todos.name,
    pages.history2.name,
    pages.properties.name,
    pages.portfolio.name,
    pages.loans.name,
    pages.loan.name,
    pages.documents.name,
];

export const ENTERPRISE_TABS_LIST = [
    pages.dashboard.name,
    pages.todos.name,
    pages.history2.name,
    pages.properties.name,
    pages.loans.name,
    pages.members.name,
    pages.documents.name,
    pages.benchmarking.routes.map.name,
];

export const NON_ENTERPRISE_TABS_LIST = [
    pages.search.name,
    pages.todos.name,
    pages.myProperties.name,
    pages.bookmarks.name,
    pages.history.name,
    pages.portfolios.name,
    pages.listings.name,
    pages.financings.name,
    pages.members.name,
    pages.refer.name,
    pages.documents.name,
];

export const ADMIN_ONLY = [pages.chatAi.name];

export const ADD_ITEMS_FOR_DEMO_PURPOSES = [pages.chatAi];
