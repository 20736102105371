/*
 * Size Based properties: Office, Retail, Industrial
 * Unit Based Proprties: The rest properties
 *
 * prefix Sb – Charts for Size Based Properties.
 * prefix Ub – Charts for Unit Based Properties.
 */

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';

import { getPropertyLink } from '@utils/properties';
import { sortObjectByDateKeysCb, sortObjectByKeys } from '@utils/sort';

import colors from '@themes/palette/export.module.scss';

import LeaseExpirations from './configs/leaseExpirations';
import NewLeases from './configs/newLeases';
import Noi from './configs/noi';
import NoiMargin from './configs/noiMargin';
import Occupancy from './configs/occupancy';
import OperatingExpenses from './configs/operatingExpenses';
import OperatingExpensesPie from './configs/operatingExpensesPie';
import RentsDualed from './configs/rentsDualed';
import RentsForNewLeases from './configs/rentsForNewLeases';
import RentsSplitted from './configs/rentsSplitted';
import Revenue from './configs/revenue';
import UnderwritingNOI from './configs/underwritingNOI';
import UnderwritingRevenue from './configs/underwritingRevenue';
import UnderwritingValuation from './configs/underwritingValuation';
import UnitMix from './configs/unitMix';
import { groupingConfigFilter, defaultGroupingConfig } from './groupingConfigs';
import {
    arrayDataInjection,
    convertToFlatData,
    expensesDataInjection,
    expensesDataInjectionPie,
    fullSeriesInjection,
    valuesDataInjections,
} from './helpers';
import { barPattern, simpleLinePattern } from './patterns';
import {
    singleGroup,
    multipleGroup,
    groupingByPeriods,
    groupingByYearsByAddresses,
} from './pieces/leaseExpirationsChart';

export default class Charts {
    operatingExpensesData = null;

    ubUnitMix(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(UnitMix), customConfig);

        const units = chartPassedData?.reduce((acc, { bedroomsNumber: number }) => {
            const chartData = { ...acc };
            let name;
            switch (number) {
                case '0':
                    name = 'Studio';
                    break;
                default:
                    name = `${number} Bed`;
            }

            if (chartData[number]) {
                chartData[number].value += 1;
            } else {
                chartData[number] = {
                    value: 1,
                    name,
                };
            }
            return chartData;
        }, {});

        const data = {
            data: [Object.values(units)],
        };

        return arrayDataInjection(config, data);
    }

    sbUnitMix(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(UnitMix), customConfig);

        const groups = chartPassedData.reduce((acc, { propType, sqft }) => {
            const summary = { ...acc };
            if (propType in summary) {
                summary[propType].value += sqft;
            } else {
                summary[propType] = {
                    value: sqft,
                    name: propType,
                };
            }

            return summary;
        }, {});

        const data = {
            data: [Object.values(groups)],
        };

        return arrayDataInjection(config, data);
    }

    ubRents(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(RentsDualed), customConfig);

        const occupiedTenants = chartPassedData.filter((tenant) => !['Vacant', 'Non-revenue', 'Model', 'Employee'].includes(tenant.status));

        const rentsChartRawData = occupiedTenants?.reduce(
            (acc, { bedroomsNumber: number, status, rent, marketPrice, propType }) => {

                const summary = { ...acc };
                if (!number) {
                    if (summary[propType]) {
                        summary[propType].numbers += 1;
                        summary[propType].rent += rent;
                        summary[propType].marketPrice += marketPrice;
                    } else {
                        summary[propType] = {
                            numbers: 1,
                            rent: rent,
                            marketPrice: marketPrice,
                            name: propType,
                        };
                    }
                } else if (summary[number]) {
                    summary[number].numbers += 1;
                    summary[number].rent += rent;
                    summary[number].marketPrice += marketPrice;
                } else {
                    summary[number] = {
                        numbers: 1,
                        rent: rent,
                        marketPrice: marketPrice,
                        name: `${number} Bed`,
                    };
                }

                return summary;
            },
            {}
        );

        const rentInPlaceData = Object.values(rentsChartRawData).reduce(
            (acc, { numbers, rent }) => [...acc, (rent / numbers).toFixed(0)],
            []
        );

        const rentMarketData = Object.values(rentsChartRawData).reduce(
            (acc, { numbers, marketPrice }) => [...acc, (marketPrice / numbers).toFixed(0)],
            []
        );

        const rentMarketAxis = Object.values(rentsChartRawData).reduce((acc, { name }) => [...acc, name], []);

        const data = {
            data: [rentInPlaceData, rentMarketData],
            xAxis: rentMarketAxis,
        };

        return arrayDataInjection(config, data);
    }

    sbRents(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(RentsSplitted), customConfig);

        const tenants = chartPassedData?.length;

        const nonVacantTenants = chartPassedData.filter((tenant) => !tenant.vacant);

        const rentsChartInPlaceRawData = nonVacantTenants?.reduce(
            (acc, { yearPrice, sqft }) => {
                const summary = { ...acc };

                summary.yearPrice += yearPrice;
                summary.sqft += sqft;

                return summary;
            },
            { yearPrice: 0, sqft: 0 }
        );

        const rentsChartMarketRawData = chartPassedData?.reduce(
            (acc, { marketPrice }) => {
                const summary = { ...acc };

                summary.marketPrice += marketPrice;

                return summary;
            },
            { marketPrice: 0 }
        );

        const rentInPlaceData = rentsChartInPlaceRawData.yearPrice / rentsChartInPlaceRawData.sqft;

        const rentMarketData = rentsChartMarketRawData.marketPrice / tenants;

        const data = {
            data: [rentInPlaceData, rentMarketData],
            xAxis: ['In-place', 'Market'],
        };

        return valuesDataInjections(config, data);
    }

    occupancy(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(Occupancy), customConfig);

        const { occupancy, marketOccupancy } = chartPassedData;

        const data = {
            data: [occupancy, marketOccupancy],
            xAxis: ['In-place', 'Market'],
        };

        return valuesDataInjections(config, data);
    }

    revenue(customConfig = {}, defaultGroupingOption, chartPassedData = null) {
        if (!chartPassedData) return null;

        const filteredConfig = groupingConfigFilter(chartPassedData);
        const groupingOption = defaultGroupingOption || filteredConfig?.[0]?.value;

        if (!chartPassedData[groupingOption]) return null;

        const config = merge(cloneDeep(Revenue), customConfig);
        const data = chartPassedData ? convertToFlatData(chartPassedData[groupingOption], 'totalRevenue') : null;

        return {
            config: arrayDataInjection(config, data),
            groupingConfig: filteredConfig,
            defaultGroupingOption: groupingOption,
        };
    }

    noi(customConfig = {}, defaultGroupingOption, chartPassedData = null) {
        if (!chartPassedData) return null;

        const filteredConfig = groupingConfigFilter(chartPassedData);
        const groupingOption = defaultGroupingOption || filteredConfig?.[0]?.value;

        if (!chartPassedData[groupingOption]) return null;

        const config = merge(cloneDeep(Noi), customConfig);

        const data = chartPassedData ? convertToFlatData(chartPassedData[groupingOption], 'noi') : null;

        return {
            config: arrayDataInjection(config, data),
            groupingConfig: filteredConfig,
            defaultGroupingOption: filteredConfig?.[0].value,
        };
    }

    noiMargin(customConfig = {}, defaultGroupingOption, chartPassedData = null) {
        if (!chartPassedData) return null;

        const filteredConfig = groupingConfigFilter(chartPassedData);
        const groupingOption = defaultGroupingOption || filteredConfig?.[0]?.value;

        if (!chartPassedData[groupingOption]) return null;

        const config = merge(cloneDeep(NoiMargin), customConfig);

        const data = chartPassedData ? convertToFlatData(chartPassedData[groupingOption], 'noiMargin') : null;

        return {
            config: arrayDataInjection(config, data),
            groupingConfig: filteredConfig,
            defaultGroupingOption: filteredConfig?.[0].value,
        };
    }

    newLeases(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(NewLeases), customConfig);

        const groupingByStartDate = chartPassedData.reduce((acc, { startDate, bedroomsNumber }) => {
            if (startDate === '') return acc;
            const obj = { ...acc };
            const startOfMonth = dayjs(startDate).startOf('month').format('MM/DD/YYYY');
            if (startOfMonth) {
                const key = `${bedroomsNumber} Bed`;
                if (startOfMonth in obj) {
                    if (key in obj[startOfMonth]) {
                        obj[startOfMonth][key] += 1;
                    } else {
                        obj[startOfMonth][key] = 1;
                    }
                } else {
                    obj[startOfMonth] = {};
                    obj[startOfMonth][key] = 1;
                }
            }
            return obj;
        }, {});

        const sortedGroupedByStartDate = sortObjectByKeys(groupingByStartDate, sortObjectByDateKeysCb);

        const dates = Object.keys(sortedGroupedByStartDate);

        const beds = chartPassedData.reduce((acc, { bedroomsNumber }) => {
            const bedsObj = { ...acc };
            const key = `${bedroomsNumber} Bed`;
            if (!(key in bedsObj)) {
                bedsObj[key] = {
                    ...barPattern,
                    barWidth: 16,
                    stack: 'total',
                    name: key,
                    data: Array(dates.length).fill(null),
                };
            }
            return bedsObj;
        }, {});

        const groupingByUnits = Object.values(sortedGroupedByStartDate).reduce((group, curr, idx) => {
            const bedrooms = cloneDeep(group);
            Object.entries(curr).forEach(([key, value]) => {
                if (key && key in bedrooms) {
                    bedrooms[key].data[idx] = value;
                }
            });
            return bedrooms;
        }, sortObjectByKeys(beds));

        const data = {
            data: Object.values(groupingByUnits),
            xAxis: dates,
        };

        return fullSeriesInjection(config, data);
    }

    leaseExpirations(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(NewLeases), customConfig);

        const groupingByExpDate = chartPassedData
            .filter(({ expDate }) => expDate !== '')
            .reduce((acc, { expDate, bedroomsNumber }) => {
                let obj = { ...acc };
                const key = `${bedroomsNumber} Bed`;
                const startOfMonth = dayjs(expDate).startOf('month').format('MM/DD/YYYY');
                if (startOfMonth) {
                    if (startOfMonth in obj) {
                        if (key in obj[startOfMonth]) {
                            obj[startOfMonth][key] += 1;
                        } else {
                            obj[startOfMonth][key] = 1;
                        }
                    } else {
                        obj[startOfMonth] = {};
                        obj[startOfMonth][key] = 1;
                    }
                } else if ('No date' in obj) {
                    if (key in obj['No date']) {
                        obj['No date'][key] += 1;
                    } else {
                        obj['No date'][key] = 1;
                    }
                } else {
                    obj = { 'No date': {}, ...obj };
                }
                return obj;
            }, {});

        const sortedGroupedByExpDate = sortObjectByKeys(groupingByExpDate, sortObjectByDateKeysCb);

        const dates = Object.keys(sortedGroupedByExpDate);

        const beds = chartPassedData.reduce((acc, { bedroomsNumber }) => {
            const bedsObj = { ...acc };
            const key = `${bedroomsNumber} Bed`;
            if (!(key in bedsObj)) {
                bedsObj[key] = {
                    ...barPattern,
                    barWidth: 16,
                    stack: 'total',
                    name: key,
                    data: Array(dates.length).fill(null),
                };
            }
            return bedsObj;
        }, {});

        const groupingByUnits = Object.values(sortedGroupedByExpDate).reduce((group, curr, idx) => {
            const bedrooms = cloneDeep(group);
            Object.entries(curr).forEach(([key, value]) => {
                if (key && key in bedrooms) {
                    bedrooms[key].data[idx] = value;
                }
            });
            return bedrooms;
        }, sortObjectByKeys(beds));

        const data = {
            data: Object.values(groupingByUnits),
            xAxis: dates,
        };

        return fullSeriesInjection(config, data);
    }

    singleLeaseExpirationsSF(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(LeaseExpirations()), customConfig);

        const sortedTenants = sortBy(chartPassedData, (tenant) => new Date(1 + tenant.expYear));

        const groupingByYears = sortedTenants.reduce((acc, { tenantName, expYear, sqft }) => {
            const summary = { ...acc };
            if (expYear) {
                if (expYear in summary) {
                    summary[expYear].push({ tenantName, value: sqft });
                } else {
                    summary[expYear] = [{ tenantName, value: sqft }];
                }
            }

            return summary;
        }, {});

        const currentYears = Object.keys(groupingByPeriods(groupingByYears).current);
        const xAxisLength = currentYears.length + 2;

        const currentGroup = singleGroup({
            years: Object.keys(groupingByPeriods(groupingByYears).current),
            groupingByYears,
            xAxisLength,
        });

        const oldGroup = singleGroup({
            years: Object.keys(groupingByPeriods(groupingByYears).old),
            groupingByYears,
            xAxisLength,
            position: 0,
            barColor: colors['chart-02'],
        });

        const futureGroup = singleGroup({
            years: Object.keys(groupingByPeriods(groupingByYears).future),
            groupingByYears,
            xAxisLength,
            position: currentYears.length + 1,
        });

        const data = {
            data: [...oldGroup, ...currentGroup, ...futureGroup],
            xAxis: ['Old', ...currentYears, 'Future'],
        };

        return fullSeriesInjection(config, data);
    }

    singleLeaseExpirationsRent(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(LeaseExpirations()), customConfig);

        const sortedTenants = sortBy(chartPassedData, (tenant) => new Date(1 + tenant.expYear));

        const groupingByYears = sortedTenants.reduce((acc, { tenantName, expYear, rent, sqft }) => {
            const summary = { ...acc };
            if (expYear) {
                if (expYear in summary) {
                    summary[expYear].push({ tenantName, value: rent * sqft });
                } else {
                    summary[expYear] = [{ tenantName, value: rent * sqft }];
                }
            }

            return summary;
        }, {});

        const currentYears = Object.keys(groupingByPeriods(groupingByYears).current);
        const xAxisLength = currentYears.length + 2;

        const currentGroup = singleGroup({
            years: Object.keys(groupingByPeriods(groupingByYears).current),
            groupingByYears,
            xAxisLength,
        });

        const oldGroup = singleGroup({
            years: Object.keys(groupingByPeriods(groupingByYears).old),
            groupingByYears,
            xAxisLength,
            position: 0,
            barColor: colors['chart-02'],
        });

        const futureGroup = singleGroup({
            years: Object.keys(groupingByPeriods(groupingByYears).future),
            groupingByYears,
            xAxisLength,
            position: currentYears.length + 1,
        });

        const data = {
            data: [...oldGroup, ...currentGroup, ...futureGroup],
            xAxis: ['Old', ...currentYears, 'Future'],
        };

        return fullSeriesInjection(config, data);
    }

    multipleLeaseExpirationsSF(customConfig = {}, chartPassedData) {
        if (!chartPassedData) return null;
        const config = merge(cloneDeep(LeaseExpirations('right')), customConfig);

        const sortedTenants = sortBy(chartPassedData.tenants, (tenant) => new Date(1 + tenant.expYear));

        const groupingByYears = sortedTenants.reduce(
            (acc, { propertyAddress, propertyName, tenantName, expYear, sqft }) => {
                const summaryByYears = { ...acc };
                const property = (chartPassedData.properties || []).find((item) => item.address === propertyAddress);
                if (expYear) {
                    if (expYear in summaryByYears) {
                        summaryByYears[expYear].push({
                            propertyAddress,
                            propertyName,
                            tenantName,
                            link: getPropertyLink(property),
                            sqft,
                        });
                    } else {
                        summaryByYears[expYear] = [
                            {
                                propertyAddress,
                                propertyName,
                                tenantName,
                                link: getPropertyLink(property),
                                sqft,
                            },
                        ];
                    }
                } else {
                    summaryByYears.noDate = [
                        ...(summaryByYears?.noDate || []),
                        {
                            propertyAddress,
                            propertyName,
                            tenantName,
                            link: getPropertyLink(property),
                            sqft,
                        },
                    ];
                }

                return summaryByYears;
            },
            {}
        );

        const currentYears = Object.keys(groupingByPeriods(groupingByYears).current);
        const xAxisLength = currentYears.length + 2;

        const groups = (period, position, barColor) => {
            const years = Object.keys(period);

            return multipleGroup(
                years,
                xAxisLength,
                groupingByYearsByAddresses(chartPassedData, period, 'sqft'),
                position,
                barColor
            );
        };

        const currentGroup = groups(groupingByPeriods(groupingByYears).current);
        const oldGroup = groups(groupingByPeriods(groupingByYears).old, 0);
        const futureGroup = groups(groupingByPeriods(groupingByYears).future, currentYears.length + 1);

        const data = {
            data: [...oldGroup, ...currentGroup, ...futureGroup],
            xAxis: ['Old', ...currentYears, 'Future'],
        };

        return fullSeriesInjection(config, data);
    }

    multipleLeaseExpirationsRent(customConfig = {}, chartPassedData) {
        if (!chartPassedData) return null;
        const config = merge(cloneDeep(LeaseExpirations('left')), customConfig);

        const sortedTenants = sortBy(chartPassedData.tenants, (tenant) => new Date(1 + tenant.expYear));

        const groupingByYears = sortedTenants.reduce(
            (acc, { propertyAddress, propertyName, tenantName, expYear, yearPrice }) => {
                const summaryByYears = { ...acc };
                const property = (chartPassedData.properties || []).find((item) => item.address === propertyAddress);
                if (expYear) {
                    if (expYear in summaryByYears) {
                        summaryByYears[expYear].push({
                            propertyAddress,
                            propertyName,
                            link: getPropertyLink(property),
                            tenantName,
                            yearPrice,
                        });
                    } else {
                        summaryByYears[expYear] = [
                            {
                                propertyAddress,
                                propertyName,
                                link: getPropertyLink(property),
                                tenantName,
                                yearPrice,
                            },
                        ];
                    }
                } else {
                    summaryByYears.noDate = [
                        ...(summaryByYears?.noDate || []),
                        {
                            propertyAddress,
                            propertyName,
                            tenantName,
                            link: getPropertyLink(property),
                            yearPrice,
                        },
                    ];
                }

                return summaryByYears;
            },
            {}
        );

        const currentYears = Object.keys(groupingByPeriods(groupingByYears).current);
        const xAxisLength = currentYears.length + 2;

        const groups = (period, position, barColor) => {
            const years = Object.keys(period);

            return multipleGroup(
                years,
                xAxisLength,
                groupingByYearsByAddresses(chartPassedData, period, 'yearPrice'),
                position,
                barColor
            );
        };

        const currentGroup = groups(groupingByPeriods(groupingByYears).current);
        const oldGroup = groups(groupingByPeriods(groupingByYears).old, 0);
        const futureGroup = groups(groupingByPeriods(groupingByYears).future, currentYears.length + 1);

        const data = {
            data: [...oldGroup, ...currentGroup, ...futureGroup],
            xAxis: ['Old', ...currentYears, 'Future'],
        };

        return fullSeriesInjection(config, data);
    }

    ubRentsForNewLeasesSF(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(RentsForNewLeases), customConfig);

        const groupingByDate = chartPassedData.reduce((acc, { startDate, sqft, rent, bedroomsNumber }) => {
            if (startDate === '') return acc;
            const props = cloneDeep(acc);
            const startOfMonth = dayjs(startDate).startOf('month').format('MM/DD/YYYY');
            if (startOfMonth) {
                if (startOfMonth in props) {
                    props[startOfMonth].push({ sqft, rent, bedroomsNumber });
                } else {
                    props[startOfMonth] = [{ sqft, rent, bedroomsNumber }];
                }
            }
            return props;
        }, {});

        const sortedGroupedByDate = sortObjectByKeys(groupingByDate, sortObjectByDateKeysCb);

        const dates = Object.keys(sortedGroupedByDate);

        const summaryTenantsSqftPerDate = dates.map((date) =>
            groupingByDate[date].reduce((accSqft, { sqft }) => {
                let summaryTenantSqftPerDate = accSqft;
                summaryTenantSqftPerDate += sqft;
                return summaryTenantSqftPerDate;
            }, 0)
        );

        const allUnitsEmptyConfig = chartPassedData.reduce((acc, { bedroomsNumber }) => {
            const units = cloneDeep(acc);
            const unitProp = `${bedroomsNumber} Bed`;
            if (!(unitProp in units)) {
                const initArray = Array(dates.length).fill(null);
                units[unitProp] = {
                    ...simpleLinePattern,
                    yAxisIndex: 1,
                    name: unitProp,
                    data: initArray,
                };
            }
            return units;
        }, {});

        const groupingByUnits = dates.reduce((unitGroups, date) => {
            const units = cloneDeep(unitGroups);

            const group = groupingByDate[date].reduce((unit, { rent, sqft, bedroomsNumber }) => {
                const unitFull = { ...unit };
                const unitProp = `${bedroomsNumber} Bed`;
                if (unitProp in unitFull) {
                    unitFull[unitProp].rent += rent;
                    unitFull[unitProp].sqft += sqft;
                } else {
                    unitFull[unitProp] = { rent, sqft, unitProp };
                }
                return unitFull;
            }, {});
            units.push(group);
            return units;
        }, []);

        const unitsLinesCharts = groupingByUnits.reduce((acc, units, idx) => {
            const unitConfig = { ...acc };
            Object.keys(units).forEach((key) => {
                const summaryRent = (units[key]?.rent || 0) / (units[key]?.sqft || 0) || 0;
                unitConfig[key].data[idx] = summaryRent.toFixed(2);
            });

            return acc;
        }, sortObjectByKeys(allUnitsEmptyConfig));

        const unitsAllLineChart = groupingByUnits.reduce(
            (acc, units, idx) => {
                const allUnitsConfig = cloneDeep(acc);
                let notNullUnits = 0;
                const sum = Object.keys(units).reduce((unitRent, key) => {
                    if (units[key].rent && units[key]?.sqft) {
                        // eslint-disable-next-line no-param-reassign
                        unitRent += units[key]?.rent || 0;
                        notNullUnits += units[key]?.sqft || 0;
                    }
                    return unitRent;
                }, 0);
                allUnitsConfig.data[idx] = (sum / notNullUnits).toFixed(2);
                return allUnitsConfig;
            },
            {
                ...simpleLinePattern,
                yAxisIndex: 1,
                name: 'All',
                data: Array(dates.length).fill(null),
            }
        );

        const data = {
            data: [
                unitsAllLineChart,
                ...Object.values(unitsLinesCharts),
                {
                    ...barPattern,
                    itemStyle: {
                        color: colors['chart-02'],
                    },
                    name: 'Size',
                    barWidth: 16,
                    barGap: '20%',
                    data: summaryTenantsSqftPerDate,
                },
            ],
            xAxis: dates,
        };

        return fullSeriesInjection(config, data);
    }

    ubRentsForNewLeasesUnit(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(RentsForNewLeases), customConfig);

        const groupingByDate = chartPassedData.reduce((acc, { startDate, rent, bedroomsNumber }) => {
            if (startDate === '') return acc;
            const props = cloneDeep(acc);
            const startOfMonth = dayjs(startDate).startOf('month').format('MM/DD/YYYY');
            if (startOfMonth) {
                if (startOfMonth in props) {
                    props[startOfMonth].push({ rent, bedroomsNumber });
                } else {
                    props[startOfMonth] = [{ rent, bedroomsNumber }];
                }
            }
            return props;
        }, {});

        const sortedGroupedByDate = sortObjectByKeys(groupingByDate, sortObjectByDateKeysCb);

        const dates = Object.keys(sortedGroupedByDate);

        const summaryTenantsPerDate = dates.map((date) =>
            groupingByDate[date].reduce((accTenants) => {
                let tenantsPerDate = accTenants;
                tenantsPerDate += 1;
                return tenantsPerDate;
            }, 0)
        );

        const allUnitsEmptyConfig = chartPassedData.reduce((acc, { bedroomsNumber }) => {
            const units = cloneDeep(acc);
            const unitProp = `${bedroomsNumber} Bed`;
            if (!(unitProp in units)) {
                const initArray = Array(dates.length).fill(null);
                units[unitProp] = {
                    ...simpleLinePattern,
                    yAxisIndex: 1,
                    name: unitProp,
                    data: initArray,
                };
            }
            return units;
        }, {});

        const groupingByUnits = dates.reduce((unitGroups, date) => {
            const units = cloneDeep(unitGroups);

            const group = groupingByDate[date].reduce((unit, { rent, bedroomsNumber }) => {
                const unitFull = { ...unit };
                const unitProp = `${bedroomsNumber} Bed`;
                if (unitProp in unitFull) {
                    unitFull[unitProp].rent += rent;
                    unitFull[unitProp].tenants += 1;
                } else {
                    unitFull[unitProp] = { rent, tenants: 1, unitProp };
                }
                return unitFull;
            }, {});
            units.push(group);
            return units;
        }, []);

        const unitsLinesCharts = groupingByUnits.reduce((acc, units, idx) => {
            const unitConfig = { ...acc };
            Object.keys(units).forEach((key) => {
                const summaryRent = (units[key]?.rent || 0) / (units[key]?.tenants || 0) || 0;
                unitConfig[key].data[idx] = summaryRent.toFixed(2);
            });

            return acc;
        }, sortObjectByKeys(allUnitsEmptyConfig));

        const unitsAllLineChart = groupingByUnits.reduce(
            (acc, units, idx) => {
                const allUnitsConfig = cloneDeep(acc);
                let notNullUnits = 0;
                const sum = Object.keys(units).reduce((unitRent, key) => {
                    if (units[key].rent && units[key]?.tenants) {
                        // eslint-disable-next-line no-param-reassign
                        unitRent += units[key]?.rent || 0;
                        notNullUnits += units[key]?.tenants || 0;
                    }
                    return unitRent;
                }, 0);
                allUnitsConfig.data[idx] = (sum / notNullUnits).toFixed(2);
                return allUnitsConfig;
            },
            {
                ...simpleLinePattern,
                yAxisIndex: 1,
                name: 'All',
                data: Array(dates.length).fill(null),
            }
        );

        const data = {
            data: [
                unitsAllLineChart,
                ...Object.values(unitsLinesCharts),
                {
                    ...barPattern,
                    itemStyle: {
                        color: colors['chart-02'],
                    },
                    name: 'Units',
                    barWidth: 16,
                    barGap: '20%',
                    data: summaryTenantsPerDate,
                },
            ],
            xAxis: dates,
        };

        return data ? fullSeriesInjection(config, data) : config;
    }

    underwritingRevenue(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const { lesser, contract, market, rollover, downMarket, customScenarios } = chartPassedData;

        const isData = lesser || contract || market || rollover || downMarket;

        const customScenariosLabels = Object.keys(customScenarios || {});

        const customScenariosData = customScenariosLabels.map((scenarioLabel) => customScenarios[scenarioLabel]);

        const seriesData = isData ? [lesser, contract, market, rollover, downMarket, ...customScenariosData] : [];

        if (!seriesData.length) return null;

        const yAxisData = seriesData.map((serieData) => ({
            value: serieData,
        }));

        const xAxisLabels = [
            'Lesser Of\n in-place and\n market',
            'In-place\n using\n contract',
            'Mark-to-\n market',
            'Rollover\n to market\n 5 year',
            'Down Market',
            ...customScenariosLabels,
        ];

        return merge(
            cloneDeep(UnderwritingRevenue),
            {
                xAxis: {
                    data: xAxisLabels,
                },
                series: [
                    {
                        data: yAxisData,
                    },
                ],
            },
            customConfig
        );
    }

    underwritingNOI(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const { contract, downMarket, lesser, market, rollover, customScenarios } = chartPassedData;

        const isData = lesser || contract || market || rollover || downMarket;

        const customScenariosLabels = Object.keys(customScenarios || {});

        const customScenariosData = customScenariosLabels.map((scenarioLabel) => customScenarios[scenarioLabel]);

        const seriesData = isData ? [lesser, contract, market, rollover, downMarket, ...customScenariosData] : [];

        if (!seriesData.length) return null;

        const yAxisData = seriesData.map((serieData) => ({
            value: serieData,
        }));

        const xAxisLabels = [
            'Lesser Of\n in-place and\n market',
            'In-place\n using\n contract',
            'Mark-to-\n market',
            'Rollover\n to market\n 5 year',
            'Down Market',
            ...customScenariosLabels,
        ];

        return merge(
            cloneDeep(UnderwritingNOI),
            {
                xAxis: {
                    data: xAxisLabels,
                },
                series: [
                    {
                        data: yAxisData,
                    },
                ],
            },
            customConfig
        );
    }

    operatingExpenses(
        {
            config: customConfig = {},
            type: defaultGroupingOption = defaultGroupingConfig[0].value,
            defaultOptions: options = [],
        },
        chartPassedData = null
    ) {
        if (!chartPassedData || !chartPassedData[defaultGroupingOption].length) {
            return [null, options];
        }

        const config = merge(cloneDeep(OperatingExpenses), customConfig);

        const data = convertToFlatData(chartPassedData[defaultGroupingOption], 'expenses');
        this.operatingExpensesData = expensesDataInjection(config, data);
        const resOptions = options.filter(
            (option) => chartPassedData[option.value] && chartPassedData[option.value].length
        );
        return [expensesDataInjection(config, data), resOptions];
    }

    operatingExpensesPie(customConfig = {}, grouping = 'monthly', chartPassedData = null) {
        if (!chartPassedData || !grouping) return null;

        const config = merge(cloneDeep(OperatingExpensesPie), customConfig);

        return expensesDataInjectionPie(config, this.operatingExpensesData, grouping);
    }

    valuation(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const { contract, downMarket, lesser, market, rollover, salesComps, customScenarios } = chartPassedData;

        const isData = lesser || contract || market || rollover || downMarket;

        const customScenariosLabels = Object.keys(customScenarios || {});

        const customScenariosData = customScenariosLabels.map((scenarioLabel) => customScenarios[scenarioLabel]);

        const seriesData = isData
            ? [lesser, contract, market, rollover, downMarket, ...customScenariosData, salesComps]
            : [];

        if (!seriesData.length) return null;

        const yAxisData = seriesData.map((serieData) => ({
            value: serieData,
        }));

        const xAxisLabels = [
            'Lesser Of\n in-place and\n market',
            'In-place\n using\n contract',
            'Mark-to-\n market',
            'Rollover\n to market\n 5 year',
            'Down Market',
            ...customScenariosLabels,
            'Sales Comps',
        ];

        return merge(
            cloneDeep(UnderwritingValuation),
            {
                xAxis: {
                    data: xAxisLabels,
                },
                series: [
                    {
                        data: yAxisData,
                    },
                ],
            },
            customConfig
        );
    }

    sbRentsForNewLeasesSF(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(RentsForNewLeases), customConfig);

        const sortedByStartDateData = [...chartPassedData].sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );

        const groupingByDate = sortedByStartDateData.reduce((acc, { startDate, sqft, rent, propType }) => {
            const props = cloneDeep(acc);
            if (startDate) {
                if (startDate in props) {
                    props[startDate].push({ sqft, rent, propType });
                } else {
                    props[startDate] = [{ sqft, rent, propType }];
                }
            }
            return props;
        }, {});

        const dates = Object.keys(groupingByDate);

        const summaryTenantsSF = dates.map((date) =>
            groupingByDate[date].reduce((acc, { sqft }) => {
                // eslint-disable-next-line no-param-reassign
                acc += sqft;
                return acc;
            }, 0)
        );

        const allPropertiesEmptyConfig = sortedByStartDateData.reduce((acc, { propType }) => {
            const props = cloneDeep(acc);
            if (!(propType in props)) {
                const initArray = Array(dates.length).fill(null);
                props[propType] = {
                    ...simpleLinePattern,
                    yAxisIndex: 0,
                    name: propType,
                    data: initArray,
                };
            }
            return props;
        }, {});

        const groupingByProperties = dates.reduce((propertyGroups, date) => {
            const props = cloneDeep(propertyGroups);

            const group = groupingByDate[date].reduce((property, { rent, propType }) => {
                const prop = { ...property };
                if (propType in prop) {
                    prop[propType].rent += rent;
                    prop[propType].tenants += 1;
                } else {
                    prop[propType] = { rent, tenants: 1, propType };
                }
                return prop;
            }, {});
            props.push(group);
            return props;
        }, []);

        const propertyLinesCharts = groupingByProperties.reduce((acc, props, idx) => {
            const propertyConfig = { ...acc };
            Object.keys(props).forEach((key) => {
                propertyConfig[key].data[idx] = (props[key]?.rent || 0) / (props[key]?.tenants || 0);
            });

            return acc;
        }, allPropertiesEmptyConfig);

        const propertyAllLineChart = groupingByProperties.reduce(
            (acc, props, idx) => {
                const propertyConfig = cloneDeep(acc);
                let notNullProps = 0;
                const sum = Object.keys(props).reduce((propertiesSum, key) => {
                    if (props[key]?.rent && props[key]?.tenants) {
                        // eslint-disable-next-line no-param-reassign
                        propertiesSum += (props[key]?.rent || 0) / (props[key]?.tenants || 0) || 0;
                        notNullProps += 1;
                    }
                    return propertiesSum;
                }, 0);
                propertyConfig.data[idx] = Math.round(sum / notNullProps) || 0;
                return propertyConfig;
            },
            {
                ...simpleLinePattern,
                yAxisIndex: 0,
                name: 'All',
                data: Array(dates.length).fill(null),
            }
        );

        const data = {
            data: [
                propertyAllLineChart,
                ...Object.values(propertyLinesCharts),
                {
                    ...barPattern,
                    yAxisIndex: 1,
                    itemStyle: {
                        color: colors['chart-02'],
                    },
                    name: 'Size',
                    data: summaryTenantsSF,
                },
            ],
            xAxis: dates,
        };

        config.yAxis[1] = {
            ...config.yAxis[1],
            min: Math.min(...summaryTenantsSF),
            max: Math.max(...summaryTenantsSF),
        };
        config.yAxis[0] = {
            ...config.yAxis[0],
            min: Math.min(...propertyAllLineChart.data),
            max: Math.max(...propertyAllLineChart.data),
        };

        return fullSeriesInjection(config, data);
    }

    sbRentsForNewLeasesUnit(customConfig = {}, chartPassedData = null) {
        if (!chartPassedData) return null;

        const config = merge(cloneDeep(RentsForNewLeases), customConfig);

        const sortedByStartDateData = [...chartPassedData].sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );

        const groupingByDate = sortedByStartDateData.reduce((acc, { startDate, tenantName, rent, sqft, propType }) => {
            const props = cloneDeep(acc);
            if (startDate) {
                if (startDate in props) {
                    props[startDate].push({ tenantName, rent, sqft, propType });
                } else {
                    props[startDate] = [{ tenantName, rent, sqft, propType }];
                }
            }
            return props;
        }, {});

        const dates = Object.keys(groupingByDate);

        const summaryTenantsPerDate = dates.map((date) =>
            groupingByDate[date].reduce((accTenants) => {
                let tenantsPerDate = accTenants;
                tenantsPerDate += 1;
                return tenantsPerDate;
            }, 0)
        );

        const allPropertiesEmptyConfig = sortedByStartDateData.reduce((acc, { propType }) => {
            const props = cloneDeep(acc);
            if (!(propType in props)) {
                const initArray = Array(dates.length).fill(null);
                props[propType] = {
                    ...simpleLinePattern,
                    yAxisIndex: 0,
                    name: propType,
                    data: initArray,
                };
            }
            return props;
        }, {});

        const groupingByProperties = dates.reduce((propertyGroups, date) => {
            const props = cloneDeep(propertyGroups);

            const group = groupingByDate[date].reduce((property, { rent, sqft, propType }) => {
                const prop = { ...property };
                if (propType in prop) {
                    prop[propType].rent += rent * sqft;
                    prop[propType].tenants += 1;
                } else {
                    prop[propType] = { rent: rent * sqft, tenants: 1, propType };
                }
                return prop;
            }, {});
            props.push(group);
            return props;
        }, []);

        const propertyLinesCharts = groupingByProperties.reduce((acc, props, idx) => {
            const propertyConfig = { ...acc };
            Object.keys(props).forEach((key) => {
                propertyConfig[key].data[idx] = (props[key]?.rent || 0) / (props[key]?.tenants || 0);
            });

            return acc;
        }, allPropertiesEmptyConfig);

        const propertyAllLineChart = groupingByProperties.reduce(
            (acc, props, idx) => {
                const propertyConfig = cloneDeep(acc);
                let notNullProps = 0;
                const sum = Object.keys(props).reduce((propertiesSum, key) => {
                    if (props[key]?.rent && props[key]?.tenants) {
                        // eslint-disable-next-line no-param-reassign
                        propertiesSum += (props[key]?.rent || 0) / (props[key]?.tenants || 0);
                        notNullProps += 1;
                    }
                    return propertiesSum;
                }, 0);
                propertyConfig.data[idx] = Math.round(sum / notNullProps);
                return propertyConfig;
            },
            {
                ...simpleLinePattern,
                yAxisIndex: 0,
                name: 'All',
                data: Array(dates.length).fill(null),
            }
        );

        const data = {
            data: [
                propertyAllLineChart,
                ...Object.values(propertyLinesCharts),
                {
                    ...barPattern,
                    yAxisIndex: 1,
                    itemStyle: {
                        color: colors['chart-02'],
                    },
                    name: 'Units',
                    data: summaryTenantsPerDate,
                },
            ],
            xAxis: dates,
        };

        return fullSeriesInjection(config, data);
    }
}
