import request from '@utils/request';

const BASE_URL = '/api/v2/portfolio-analytics';

export async function getPropertyPortfolio({ id, allPeriods }) {
    return request(`${BASE_URL}/${id}`, {
        params: {
            allPeriods,
        },
    });
}

export async function getPropertyPortfolioCombined({ id, allPeriods }) {
    return request(`${BASE_URL}/loan/${id}`, {
        params: {
            allPeriods,
        },
    });
}

export async function getPropertyPortfolioTenants({ id, size = 10, page = 0 }) {
    return request(`${BASE_URL}/tenants`, {
        params: {
            id,
            size,
            page,
        },
    });
}

export async function getPropertyPortfolioCombinedTenants({ id, size = 10, page = 0 }) {
    return request(`${BASE_URL}/loan/${id}/tenants`, {
        params: {
            size,
            page,
        },
    });
}

export async function loadLoansPortfolio(id) {
    return request(`${BASE_URL}/loan-portfolio/${id}`);
}

export async function loadLoansPortfolioHeader(id) {
    return request(`${BASE_URL}/loan-portfolio/${id}/header`);
}
