import colors from '@themes/palette/export.module.scss';

const tab = () => ({
    MuiTab: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
                minHeight: 36,
                padding: '8px 12px 10px',
                color: colors['grey-500'],
                fontSize: 14,
                lineHeight: '20px',
                fontWeight: 600,
                '.MuiTab-iconWrapper': {
                    marginBottom: '0px !important',
                },
                '&.Mui-selected': {
                    color: colors.black,
                },
            },
        },
        variants: [
            {
                props: { variant: 'icon' },
                style: {
                    width: 40,
                    height: 40,
                    padding: 0,
                },
            },
            {
                props: { variant: 'filled' },
                style: {
                    minHeight: 'auto',
                    height: 32,
                    borderRadius: 4,
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '16px',
                    textAlign: 'center',
                    padding: '0 16px',
                    textTransform: 'capitalize',
                    minWidth: 'auto',
                    '&.MuiTab-textColorPrimary': {
                        '&.Mui-selected': {
                            backgroundColor: colors['darkNeutral-500'],
                            color: colors['grey-50'],
                        },
                    },
                },
            },
        ],
    },
});

export default tab;
