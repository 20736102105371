import cloneDeep from 'lodash/cloneDeep';

import { formatter } from '@utils/index';

import templatesMap, { BASE } from './tooltip/templates';

export const hideNoDataSeries = (series) => series.filter((item) => item.data);

const messageRender = ({
    series,
    showValue,
    showMarker,
    format,
    sName,
    formatName,
    sorting = 'DESC',
    template,
    ...rest
}) => {
    const data = cloneDeep(series);

    let source;
    const Template = templatesMap[template];
    switch (sorting) {
        case 'ASC':
            source = data.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
            break;
        case 'DESC':
            source = data.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
            break;
        default:
            source = data;
    }

    return source.reduce((acc, serie) => {
        const { seriesName, name } = serie;
        const tooltipName = sName ? formatName(name) : seriesName;
        let message = acc;
        message += Template({ header: seriesName, showValue, showMarker, format, tooltipName, ...serie, ...rest });
        return message;
    }, '');
};

export const unitsSetter = ({
    series,
    units = '',
    map,
    showValue = true,
    showMarker = true,
    format = formatter.number,
    formatName = (name) => name,
    sName = true,
    sorting,
    template = BASE,
}) =>
    messageRender({
        series: Array.isArray(series) ? series : [series],
        units,
        map,
        showValue,
        showMarker,
        format,
        sName,
        sorting,
        template,
        formatName,
    });

export const tooltipTemplateDefault = (series, content) => {
    const axisValue = series.length ? series[0].axisValue : '';

    const tooltipContent = content.reduce((acc, curr, idx, arr) => {
        let part = acc;
        part += curr;
        if (idx !== arr.length - 1) {
            part += '<br/>';
        }
        return part;
    }, '');
    return `${axisValue}<br/>${tooltipContent}`;
};
