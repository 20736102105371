import { put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { documentsApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { parseDocuments } from '@utils/store/middleware';

const entity = '[memberDocs]';
const size = 20;

const initialState = {
    data: [],
    search: {},
    toBeUpdated: { toAdd: [], toRemove: [] },
    totalCount: null,
    loaded: false,
    loading: false,
};

const membersSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadAll(state, action) {
            state.loaded = false;
            state.loading = true;
            state.data = [];
            state.search = action.payload.search;
        },
        loadMore() {},
        setData(state, action) {
            const { data, content, totalElements } = action.payload;
            const _data = data ?? content;
            state.data = _data.map((item) => ({ ...item, shared: [] }));
            if (totalElements) {
                state.totalCount = totalElements;
            }
            state.loaded = true;
            state.loading = false;
        },
        add(state) {
            state.loaded = false;
            state.loading = true;
        },
        updateOne() {},
        updateLinking(state, action) {
            const { task, item, neccessaryKey } = action.payload;
            const { toAdd, toRemove } = { ...state.toBeUpdated };
            if (task === 'add') {
                const resAdd = toAdd.filter((listItem) => listItem[neccessaryKey] !== item[neccessaryKey]);
                state.toBeUpdated = {
                    toRemove,
                    toAdd: resAdd,
                };
            } else {
                const resRemove = toRemove.filter((listItem) => listItem[neccessaryKey] !== item[neccessaryKey]);
                state.toBeUpdated = {
                    toAdd,
                    toRemove: resRemove,
                };
            }
        },
        addMore(state, action) {
            state.data = [
                ...state.data,
                ...action.payload.map((item) => ({
                    ...item,
                    shared: [],
                })),
            ];
        },
        updateItems(state, action) {
            state.toBeUpdated = action.payload;
        },
        reset(state) {
            state.data = [];
            state.totalCount = 0;
        },
    },
});

export const {
    load: loadMemberDocuments,
    loadAll: loadAllDocuments,
    loadMore: loadMoreDocuments,
    add: addMemberDocuments,
    addMore: addMoreDocuments,
    setData: setDocuments,
    updateOne: updateLinkedItem,
    updateLinking: updateStateOfLinking,
    updateItems: updateLinkedItems,
    reset: resetDocumentsState,
} = membersSlice.actions;

export default membersSlice.reducer;

export const selectMemberDocumentsState = (store) => store.members.documents;

function* loadMemberDocumentsSaga({ type, payload }) {
    yield put(apiRequest({ memberId: payload }, documentsApi.getMemberDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content), totalCount: data.totalElements }));
}

function* loadAllDocumentsSaga({ type, payload }) {
    const { search } = payload;
    yield put(apiRequest({ ...search }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setDocuments({ data: parseDocuments(content), totalCount: totalElements }));
}

function* loadMoreDocumentsSaga({ type }) {
    const { search, data } = yield select(selectMemberDocumentsState);
    yield put(apiRequest({ ...search, page: data.length / size, size }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreDocuments(parseDocuments(content)));
}

function* updateLinkedItemSaga({ payload, type }) {
    const { item, task } = payload;
    const neccessaryKey = Object.keys(item).filter((key) => key !== 'documentId')[0];
    if (neccessaryKey === 'memberId') {
        task === 'add'
            ? yield put(apiRequest(item, documentsApi.addToMember, type))
            : yield put(apiRequest(item, documentsApi.removeFromMember, type));
    } else if (neccessaryKey === 'propertyId') {
        task === 'add'
            ? yield put(apiRequest(item, documentsApi.addToProperty, type))
            : yield put(apiRequest(item, documentsApi.removeFromProperty, type));
    } else if (neccessaryKey === 'email') {
        task === 'add'
            ? yield put(apiRequest(item, documentsApi.addToUser, type))
            : yield put(apiRequest(item, documentsApi.removeFromUser, type));
    } else {
        task === 'add'
            ? yield put(apiRequest(item, documentsApi.addToLoan, type))
            : yield put(apiRequest(item, documentsApi.removeFromLoan, type));
    }
    yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    yield put(updateStateOfLinking({ ...payload, neccessaryKey }));
}

function* addMemberDocumentsSaga({ type, payload }) {
    yield put(apiRequest(payload, documentsApi.addToMemberDocument, type));
}

export function* watchMemberDocuments() {
    yield takeLatest(loadMemberDocuments, loadMemberDocumentsSaga);
    yield takeLatest(loadAllDocuments, loadAllDocumentsSaga);
    yield takeLatest(loadMoreDocuments, loadMoreDocumentsSaga);
    yield takeLatest(updateLinkedItem, updateLinkedItemSaga);
    yield takeLatest(addMemberDocuments, addMemberDocumentsSaga);
}
