import { put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { notificationsApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { selectFullUserState } from '@scc/store/ducks/auth';

const entity = '[notifications]';

const initialState = {
    data: [],
    total: 0,
    loading: false,
};

const notificationsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loading = false;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setAsReadById(state, action) {
            const notificationId = action.payload;
            const updateIndex = state.data.findIndex((notification) => notification.id === notificationId);

            state.data[updateIndex] = {
                ...state.data[updateIndex],
                isRead: true,
            };
        },
        saveAsReadById() {},
        saveAllAsRead(state) {
            state.data = state.data.map((notification) => ({
                ...notification,
                isRead: true,
            }));
        },
        onLoadMore() {},
    },
});

export const {
    load: loadNotifications,
    setData: setNotifications,
    setTotal: setTotalNotifications,
    setAsReadById: setAsReadByIdNotifications,
    saveAsReadById: saveAsReadByIdNotifications,
    saveAllAsRead: saveAllAsReadNotifications,
    onLoadMore: onLoadMoreNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectNotificationsState = (store) => store.notifications;

function* loadNotificationsSaga({ type }) {
    const { userId } = yield select(selectFullUserState);

    yield put(apiRequest({ userId, size: notificationsApi.PAGE_SIZE }, notificationsApi.getAll, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setNotifications(action.payload.data?.content));
    yield put(setTotalNotifications(action.payload.data?.totalElements));
}

function* saveAsReadByIdNotificationsSaga({ payload: notificationId, type }) {
    const { data: notifications } = yield select(selectNotificationsState);
    const { userId } = yield select(selectFullUserState);

    if (notifications.find((notification) => notification.id === notificationId).isRead) {
        return;
    }

    yield put(setAsReadByIdNotifications(notificationId));
    yield put(apiRequest({ userId, notificationId }, notificationsApi.markAsRead, type));
}

function* saveAllAsReadNotificationsSaga({ type }) {
    const { userId } = yield select(selectFullUserState);
    yield put(apiRequest({ userId }, notificationsApi.markAllAsRead, type));
}

function* onLoadMoreNotificationsSaga({ type }) {
    const { userId } = yield select(selectFullUserState);
    const { data: notifications } = yield select(selectNotificationsState);
    const payload = {
        userId,
        page: notifications.length / notificationsApi.PAGE_SIZE,
        size: notificationsApi.PAGE_SIZE,
    };

    yield put(apiRequest(payload, notificationsApi.getAll, type));

    const action = yield take(`${type} ${API_SUCCESS}`);
    const moreItems = action.payload.data?.content;

    yield put(setNotifications([...notifications, ...moreItems]));
}

export function* watchNotifications() {
    yield takeLatest(loadNotifications, loadNotificationsSaga);
    yield takeLatest(saveAsReadByIdNotifications, saveAsReadByIdNotificationsSaga);
    yield takeLatest(saveAllAsReadNotifications, saveAllAsReadNotificationsSaga);
    yield takeLatest(onLoadMoreNotifications, onLoadMoreNotificationsSaga);
}
