import { MouseEvent, useState } from 'react';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Popover from '@ui/Popover';

import TickSvg from '@icons/outlined/tick.svg';

import styles from './OtherOptionPopover.module.scss';

type TConfirmPopoverProps = {
    onSubmit: (inputValue: string) => void;
    children: React.ReactNode;
    anchorWidth?: number;
};

export function OtherOptionPopover({ onSubmit, children, anchorWidth }: TConfirmPopoverProps) {
    const [inputValue, setInputValue] = useState('');
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const isOpen = Boolean(anchorEl);

    const onWrapperClick = (event: MouseEvent<HTMLDivElement>) => {
        event?.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onClose = (event) => {
        event?.stopPropagation();
        setAnchorEl(null);
    };

    const onSubmitClick = (event: MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation();
        onSubmit(inputValue);
        onClose(event);
    };

    return (
        <Box onClick={onWrapperClick}>
            {children}
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={isOpen}
                sx={{
                    '& .MuiPopover-paper': {
                        width: anchorWidth ?? '280px',
                    },
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={onClose}
            >
                <Box className={styles.popoverContent}>
                    <Input
                        className={styles.popoverInput}
                        value={inputValue}
                        variant='outlined'
                        fullWidth
                        onChange={(event) => setInputValue(event.target.value)}
                    />
                    <Button
                        className={styles.popoverBtn}
                        disabled={inputValue.length < 2}
                        variant='icon'
                        onClick={onSubmitClick}
                    >
                        <Icon component={TickSvg} />
                    </Button>
                </Box>
            </Popover>
        </Box>
    );
}
