import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentPageCommon } from '@scc/store/ducks/common';

const useQueryParams = <T extends Record<string, unknown>>(defaultParams = {}) => {
    const { params = defaultParams, search = '' } = useSelector(selectCurrentPageCommon) as {
        params: Record<string, string>;
        search: string;
    };
    const [state, setState] = useState<T>(params as T);

    useEffect(() => {
        const queries = new URLSearchParams(search);
        if (queries.toString()) {
            const paramsObj = Object.fromEntries(queries);
            setState(paramsObj as T);
        } else {
            setState({} as T);
        }
    }, [search]);

    return [state, search] as const;
};

export default useQueryParams;
