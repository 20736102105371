import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import LinkIcon from '@icons/outlined/link-2.svg';
import LocationIcon from '@icons/outlined/location.svg';
import useCopyToClipboard from '@shared/hooks/useCopyToClipboard';

import styles from './styles.module.scss';
import schema from './validationSchema';

function SharePropertyForm({ address: addressProp, link, loading, onSuccess }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [clipboardValue, copyToClipboard] = useCopyToClipboard();

    const { address, city } = addressProp;

    const handleCopyLink = () => {
        copyToClipboard(link);
    };

    return (
        <Box className={styles.form} component='form' onSubmit={handleSubmit(onSuccess)}>
            <div>
                <div className={styles.addressWrapper}>
                    <Icon color={colors['grey-400']} component={LocationIcon} fontSize='38px' />
                    <div className={styles.addressContainer}>
                        <Typography color={colors['darkNeutral-500']} component='span' variant='h5sb'>
                            {address}
                        </Typography>
                        <Typography color={colors['grey-400']} component='span' variant='hr13'>
                            {city}
                        </Typography>
                    </div>
                </div>
                <Input
                    label='Email'
                    margin='normal'
                    variant='standard'
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <Input
                    label='Message (optional)'
                    margin='normal'
                    variant='standard'
                    fullWidth
                    {...register('message')}
                    error={!!errors.message}
                    helperText={errors.message?.message}
                />
            </div>

            <div className={styles.actionsWrapper}>
                <Button color='primary' loading={loading} size='extra-large' type='submit' fullWidth>
                    <Typography color={colors.white} component='span' variant='lgr'>
                        Send
                    </Typography>
                </Button>
                <Button
                    className={styles.linkButton}
                    size='extra-large'
                    variant='outlined'
                    fullWidth
                    onClick={handleCopyLink}
                >
                    <Icon color={colors['grey-300']} component={LinkIcon} fontSize='24px' />
                    <Typography color={colors['darkNeutral-500']} component='span' variant='h4sb'>
                        Copy Link
                    </Typography>
                </Button>

                {clipboardValue && (
                    <Typography
                        className={styles.clipboardDescription}
                        color={colors['grey-400']}
                        component='span'
                        variant='h6r'
                    >
                        Link has been copied
                    </Typography>
                )}
            </div>
        </Box>
    );
}

export default SharePropertyForm;
