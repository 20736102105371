const inputAdornment = () => ({
    MuiInputAdornment: {
        styleOverrides: {
            positionStart: {
                marginLeft: 0,
            },
            positionEnd: {
                marginRight: 0,
            },
        },
    },
});

export default inputAdornment;
