import EnterpriseTop from '@scc/templates/Base/BaseFooter/components/EnterpriseTop';
import HomeTop from '@scc/templates/Base/BaseFooter/components/HomeTop';

import SocIcon from './images/SOC 2.png';
import SocSmIcon from './images/SOC-sm.png';

export const FOOTER_DATA = {
    map: {
        SCC: {
            title: 'Why Smart Capital',
            links: [
                {
                    label: 'Head of Mortgage',
                    url: '',
                },
                {
                    label: 'Head of Mortgage Operations',
                    url: '',
                },
                {
                    label: 'One platform for a lifetime ',
                    url: '',
                },
            ],
        },
        Products: {
            title: 'Products',
            links: [
                {
                    label: 'Borrower Portal',
                    url: '',
                },
                {
                    label: 'Mortgage',
                    url: '',
                },
                {
                    label: 'Integrations',
                    url: '',
                },
            ],
        },
        Resources: {
            title: 'Resources',
            links: [
                {
                    label: 'Blog',
                    url: '',
                },
                {
                    label: 'Library',
                    url: '',
                },
                {
                    label: 'For Devolopers',
                    url: '',
                },
            ],
        },
    },
    partners: {
        soc: {
            logo: SocIcon,
            shortLogo: SocSmIcon,
        },
    },
};

export const TOP_FOOTER_COMPONENT_MAP = {
    home: HomeTop,
    enterprise: EnterpriseTop,
};
