export const PROPERTY_YEAR = 'PROPERTY_YEAR';

export const PROPERTY_RENOVATED_YEAR = 'PROPERTY_RENOVATED_YEAR';

export const PROPERTY_SIZE = 'PROPERTY_SIZE';

export const PROPERTY_UNITS = 'PROPERTY_UNITS';

export const PROPERTY_OCCUPANCY = 'PROPERTY_OCCUPANCY';

export const PROPERTY_LAND = 'PROPERTY_LAND';

export const PROPERTY_BUILDING_CLASS = 'PROPERTY_BUILDING_CLASS';

export const PROPERTY_ZONING = 'PROPERTY_ZONING';

export const PROPERTY_LAND_USE_CODE = 'PROPERTY_LAND_USE_CODE';

export const PROPERTY_COUNTY_DESCRIPTION = 'PROPERTY_COUNTY_DESCRIPTION';

export const MARKET_VALUE = 'MARKET_VALUE';

export const PROPERTY_TYPE = 'PROPERTY_TYPE';
