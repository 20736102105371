import cx from 'classnames';

import { getMemberRequestDataAdapter } from '@utils/store/middleware';

import Typography from '@ui/Typography';

import ShowMoreEntities from '@components/DataTable/Cells/EntityCell/ShowMoreEntities';
import { MEMBER_TYPES } from '@components/DataTable/constants';

import colors from '@themes/palette/export.module.scss';

import { loansApi, rentRollApi } from '@api';
import { getMembers } from '@shared/services/api/members';

import styles from './styles.module.scss';

const fetchLoans = async ({ value, searchProp = '' }) => {
    const response = await loansApi.filter({ search: searchProp });
    if (response?.status === 'success') {
        const filteredLoans = response.data.content.filter((responseLoan) => {
            const { id: loanId } = responseLoan;
            return !value.map(({ id }) => id).includes(loanId);
        });
        return filteredLoans.map(({ jsonValues, id, ...rest }) => ({
            ...rest,
            jsonValues: jsonValues ? JSON.parse(jsonValues) : {},
            loanDetailsId: id,
        }));
    }
};

const fetchProperties = async ({ searchProp = '' }) => {
    const response = await rentRollApi.getPortfolio({ query: searchProp, size: 20 });
    if (response?.items && response.items.length) {
        const filteredProperties = response.items.map((item) => ({
            propertyId: item.systemid,
            address: item.address,
        }));

        if (filteredProperties.length > 10) {
            filteredProperties.length = 10;
        }
        return filteredProperties;
    }
    return [];
};

const fetchMembers = async ({ value, searchProp }) => {
    try {
        const response = await getMembers({ search: searchProp });
        if (response.status === 'success') {
            const users = response.data.content
                .map((userItem) => {
                    const {
                        contacts: { email: { title: userEmail } = {}, phone: { title: userPhone } = {} } = {},
                        id,
                        firstName: firstname,
                        lastName: lastname,
                        name,
                        type,
                        email: companyEmail,
                        phone: companyPhone,
                    } = getMemberRequestDataAdapter(userItem);
                    return {
                        email: userEmail || companyEmail,
                        firstname,
                        lastname,
                        type: MEMBER_TYPES[type],
                        name,
                        phone: userPhone || companyPhone,
                        memberId: id,
                    };
                })
                .filter(({ email, name }) => email && name && name.trim().length);
            return users;
        }
        return [];
    } catch (e) {
        console.warn(e);
        return [];
    }
};

const renderLoan = (
    { item = {}, value, interactive = false, showMore = false, showLoanId = false, interactiveClass },
    onClick,
    className
) => {
    const { id = null, jsonValues, loanName } = item;

    const {
        loanName: jsonValueLoanName = null,
        propertyName = null,
        LoanId = null,
    } = jsonValues && jsonValues !== 'null'
        ? jsonValues
        : { jsonValueLoanName: null, propertyName: null, bankName: null, LoanId: null };
    const selected = interactive && value?.map((itemValue) => itemValue.loanDetailsId).includes(item.loanDetailsId);

    return (
        <div
            key={id}
            className={cx(styles.listItem, className, {
                [styles.selected]: selected,
                [styles.pointer]: interactive,
                [styles.padding]: interactive,
                [interactiveClass]: interactiveClass,
            })}
            onClick={(e) => onClick(e, item)}
        >
            <div className={styles.listItemMain}>
                <Typography color={colors['darkNeutral-500']} variant='hsb13'>
                    {loanName || jsonValueLoanName || propertyName || '-'}
                </Typography>
                <div className={styles.itemInfoInline}>
                    <Typography color={colors['grey-400']} variant='hsb12'>
                        Bank:
                    </Typography>
                    <Typography color={colors['grey-400']} variant='hr12'>
                        {jsonValues?.bankName || '-'}
                    </Typography>
                </div>
                {showLoanId && (
                    <div className={styles.itemInfoInline}>
                        <Typography color={colors['grey-400']} variant='hsb12'>
                            Loan ID:
                        </Typography>
                        <Typography color={colors['grey-400']} variant='hr12'>
                            {jsonValues?.LoanId || '-'}
                        </Typography>
                    </div>
                )}
            </div>
            {showMore ? (
                <ShowMoreEntities
                    list={value}
                    renderItem={(item) => renderLoan({ item, interactive: false }, onClick, styles.loanItemWrapper)}
                />
            ) : null}
        </div>
    );
};

const renderProperty = ({ item, value, interactive = false, showMore = false }, onClick, className) => {
    const { address, propertyId } = item;
    const [main, ...secondaryText] = address.split(', ');
    const secondary = secondaryText.join(', ');
    const selected = interactive && value?.map(({ propertyId: id }) => id).includes(propertyId);

    return (
        <div
            key={propertyId}
            className={cx(styles.listItem, className, {
                [styles.selected]: selected,
                [styles.pointer]: interactive,
                [styles.padding]: interactive,
            })}
            onClick={(e) => onClick(e, item)}
        >
            <div className={styles.listItemMain}>
                <Typography color={colors['darkNeutral-500']} variant='hsb13'>
                    {main}
                </Typography>
                <Typography color={colors['grey-400']} variant='hr12'>
                    {secondary}
                </Typography>
            </div>
            {showMore ? (
                <ShowMoreEntities
                    list={value}
                    renderItem={(item) =>
                        renderProperty({ item, interactive: false }, onClick, styles.propertyItemWrapper)
                    }
                />
            ) : null}
        </div>
    );
};

const renderMember = ({ item, value, interactive = false, showMore = false }, onClick, className) => {
    const { name, type, id } = item;
    const selected = interactive && value?.map((itemValue) => itemValue.email).includes(item.email);

    return (
        <div
            key={id}
            className={cx(styles.listItem, className, {
                [styles.selected]: selected,
                [styles.pointer]: interactive,
                [styles.padding]: interactive,
            })}
            onClick={(e) => onClick(e, item)}
        >
            <div className={styles.listItemMain}>
                <Typography color={colors['darkNeutral-500']} variant='hsb13'>
                    {name}
                </Typography>
                <Typography color={colors['grey-400']} variant='hr12'>
                    {type}
                </Typography>
            </div>
            {showMore && (
                <ShowMoreEntities
                    list={value}
                    renderItem={(item) => renderMember({ item, withCheckbox: false }, onClick, styles.itemWrapper)}
                />
            )}
        </div>
    );
};

export default {
    loan: {
        singleLabel: 'Loan',
        key: 'loanDetailsId',
        multipleLabel: 'Loans',
        fetchItems: fetchLoans,
        renderItem: renderLoan,
    },
    property: {
        singleLabel: 'Property',
        multipleLabel: 'Properties',
        key: 'propertyId',
        fetchItems: fetchProperties,
        renderItem: renderProperty,
    },
    member: {
        singleLabel: 'Entity',
        multipleLabel: 'Entities',
        key: 'memberId',
        fetchItems: fetchMembers,
        renderItem: renderMember,
    },
};
