import Typography from '@ui/Typography';
import Avatar from '@ui/Avatar';
import Chip from '@ui/Chip';
import { getUserInitials } from '@utils/index';

import styles from './styles.module.scss';

const MemberCell = function (props) {
    const {
        row: {
            values: {
                owner: { name, role, avatar },
            },
        },
    } = props;
    return (
        <span className={styles.memberCell}>
            <Avatar src={avatar} alt={name} component='span'>
                <Typography variant='hb14'>{getUserInitials(name)}</Typography>
            </Avatar>
            <span className={styles.memberCellAvatar}>
                <Typography component='span' variant='mdr'>
                    {name}
                </Typography>
            </span>
            {role ? <Chip label={role} component='span' /> : null}
        </span>
    );
};

export default MemberCell;
