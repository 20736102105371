import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';

import Box from '@ui/Box';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import CloseCircleSvg from '@assets/icons/outlined/close-circle.svg';
import DocumentTextSvg from '@assets/icons/outlined/document-text.svg';
import DocumentUploadSvg from '@assets/icons/outlined/document-upload.svg';
import TickCircleSvg from '@assets/icons/outlined/tick-circle.svg';

import useUploadRentRoll from './hooks/useUploadRentRoll';
import styles from './styles.module.scss';

function DropZone({ initialFiles = [], both, docs = [], note, type, params = {} }) {
    const [hovered, setHovered] = useState(false);
    const toggleHover = (id) => () => setHovered(id);

    const [{ loading }, { getRootProps, getInputProps, removeFile }] = useUploadRentRoll({
        docs,
        type,
        params,
    });

    return (
        <section className={styles.wrapper}>
            <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />
                <Typography color='text.secondary' variant='pr16'>
                    Drop your property financial documents here, or <strong>search on this device</strong>
                </Typography>
            </div>
            <aside className={styles.thumbs}>
                {note && (
                    <Typography color='disabled.light' variant='pr14' gutterBottom>
                        {note}
                    </Typography>
                )}
                <ItemList items={(both || !docs.length) && initialFiles}>
                    {(file) => (
                        <Typography key={file.id} className={styles.item} variant='pr14'>
                            <Icon component={DocumentUploadSvg} />
                            {file.name}
                        </Typography>
                    )}
                </ItemList>
                <ItemList items={docs}>
                    {({ osid, rrid, name, initial }) => {
                        const fileId = osid || rrid;
                        const isHovered = hovered === fileId;

                        return (
                            <Typography key={fileId} className={styles.item} variant='pr14'>
                                <Icon
                                    color={initial ? 'inherit' : 'primary.main'}
                                    component={initial ? DocumentUploadSvg : DocumentTextSvg}
                                />
                                {name}
                                <IconButton
                                    onClick={removeFile(fileId)}
                                    onMouseEnter={toggleHover(fileId)}
                                    onMouseLeave={toggleHover(false)}
                                >
                                    <Icon
                                        color={isHovered ? 'error' : 'success'}
                                        component={isHovered ? CloseCircleSvg : TickCircleSvg}
                                        fontSize='14px'
                                    />
                                </IconButton>
                            </Typography>
                        );
                    }}
                </ItemList>
                {loading && (
                    <Box sx={{ textAlign: 'center' }}>
                        <CircularProgress size={24} />
                    </Box>
                )}
            </aside>
        </section>
    );
}

export default DropZone;
