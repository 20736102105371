import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { RootState } from '@scc/index';
import { userLoans } from '@scc/services/apis';

import { loansApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

import { COVENANT_TYPE_VALUES, TCovenant, TCovenantLatestValue, TCovenantState, LATEST_TYPE_VALUES } from './types';

const initialState: TCovenantState = {
    loading: false,
    loaded: false,
    data: [],
    latestValues: {},
};

const entity = '[loans/covenants]';

const covenantSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load: (state) => {
            state.loading = true;
            state.loaded = false;
        },
        setData: (state, action: PayloadAction<{ data: TCovenant[]; latestValues: TCovenantLatestValue }>) => {
            state.loading = false;
            state.loaded = true;
            state.data = action.payload.data;
            state.latestValues = action.payload.latestValues;
        },
        update: (state, action: PayloadAction<TCovenant>) => {
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
        },
        delete: (state, action: PayloadAction<TCovenant>) => {
            state.data = state.data.filter((item) => item.id !== action.payload.id);
        },
        add: (state, action: PayloadAction<TCovenant>) => {
            state.data.push(action.payload);
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(userLoans.endpoints.saveLoanCovenants.matchFulfilled, (state, action) => {
            if (action.payload.data) {
                state.data.push(action.payload.data as TCovenant);
            }
        });
    },
});

export const {
    load: loadCovenants,
    setData: setCovenants,
    update: updateCovenant,
    add: addCovenant,
    delete: deleteCovenant,
    reset: resetCovenants,
} = covenantSlice.actions;

export default covenantSlice.reducer;

export const selectCovenants = (state: RootState) => {
    return {
        ...state.loans.covenants,
        covenants: state.loans.covenants.data.map((item) => {
            return {
                ...item,
                latestValue:
                    item.type === COVENANT_TYPE_VALUES.MIN_DEBT_YIELD
                        ? (state.loans.covenants.latestValues[LATEST_TYPE_VALUES.MIN_DEBT_YIELD] || 0) * 100
                        : state.loans.covenants.latestValues[LATEST_TYPE_VALUES[item.type]] || '',
            };
        }),
    };
};

function* loadCovenantsSaga({ type, payload }: PayloadAction<number>) {
    const covenantsArrayType = `${type}-covenants`;
    const covenantLatestValueType = `${type}-covenant-latest-value`;

    yield put(apiRequest({ loanDetailsId: payload }, loansApi.getLoanCovenants, covenantsArrayType));

    const action: {
        type: string;
        payload: { data: Omit<TCovenant[], 'latestValue'> };
    } = yield take(`${covenantsArrayType} ${API_SUCCESS}`);

    if (!action.payload.data) return;

    yield put(apiRequest({ loanDetailsId: payload }, loansApi.getLoanCovenantsLatestValues, covenantLatestValueType));

    const latestValuesAction: {
        type: string;
        payload: { data: TCovenantLatestValue };
    } = yield take(`${covenantLatestValueType} ${API_SUCCESS}`);

    yield put(setCovenants({ data: action.payload.data, latestValues: latestValuesAction.payload.data }));
}

export function* watchCovenants() {
    yield takeLatest(loadCovenants.type, loadCovenantsSaga);
}
