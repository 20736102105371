import { createSlice } from '@reduxjs/toolkit';
import { all, fork, put, select, take } from 'redux-saga/effects';
import { selectMemoryState, resetOneMemory } from '@scc/store/ducks/memory';

const initialState = {
    open: false,
    data: {
        message: '',
    },
};

const mainAlertSlice = createSlice({
    name: '[ui] mainAlert',
    initialState,
    reducers: {
        show(state, action) {
            state.open = true;
            state.data = action.payload?.data || initialState.data;
        },
        hide() {
            return initialState;
        },
    },
});

export const { show: showAlert, hide: hideAlert } = mainAlertSlice.actions;
export const selectUiMainAlertState = (state) => state.ui.notifications.mainAlert;
export default mainAlertSlice.reducer;

function* getCurrentUser() {
    const state = yield select(selectMemoryState);
    const data = state[showAlert.type];
    if (data) {
        yield put(showAlert({ data }));
        yield take(hideAlert);
        yield put(resetOneMemory(showAlert.type))
    }
}

export function* watchMainAlert() {
    yield all([fork(getCurrentUser)]);
}
