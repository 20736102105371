import colors from '@themes/palette/export.module.scss';

const tabs = () => ({
    MuiTabs: {
        defaultProps: {
            TabIndicatorProps: {
                selected: {
                    backgroundColor: '#474D66',
                    color: '#FFFFFF',
                },
            },
        },
        styleOverrides: {
            root: {
                position: 'relative',
                minHeight: 'auto',
            },
            indicator: {
                backgroundColor: colors['blue-main'],
                height: 3,
            },
            scroller: {
                paddingLeft: 32,
                paddingRight: 32,
            },
        },
        variants: [
            {
                props: { variant: 'vertical-icon' },
                style: {
                    width: 48,
                },
            },
            {
                props: {
                    noPadding: true,
                },
                style: {
                    '.MuiTabs-flexContainer': {
                        columnGap: '1.5rem',
                    },
                    '.MuiTab-root': {
                        minWidth: 'unset',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
            {
                props: { variant: 'filled' },
                style: {
                    padding: 8,

                    '.MuiTabs-indicator': {
                        display: 'none',
                    },
                },
            },
            {
                props: { variant: 'scrollable' },
                style: {
                    padding: 4,

                    '.MuiTabs-indicator': {
                        display: 'none',
                    },
                },
            },
        ],
    },
});

export default tabs;
