import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { baseChartConfig, xAxisDateFormatter } from './index';

const noiMargin = merge(cloneDeep(baseChartConfig()), {
    series: [
        {
            data: [],
            type: 'line',
        },
    ],
    xAxis: {
        axisLabel: xAxisDateFormatter,
    },
});

export default noiMargin;
