import { useEffect } from 'react';

let targetElement;

const getElement = () => {
    if (!targetElement) {
        targetElement = document.createElement('div');
    }

    return targetElement;
};

export const useCustomEventListener = (eventName, eventHandler, deps, initialElement = null) => {
    let element = initialElement;

    useEffect(() => {
        element = element || getElement();
        const handleEvent = (event) => {
            // skip events without details to prevent runtime error on processing third party custom events
            if (!event.detail) return;
            eventHandler(event, event.detail);
        };

        element.addEventListener(eventName, handleEvent, false);

        return () => {
            element?.removeEventListener(eventName, handleEvent, false);
        };
    }, deps);

    return (el) => {
        element = el;
    };
};

export const emitCustomEvent = (eventName, data) => {
    const element = getElement();
    const event = new CustomEvent(eventName, { detail: data });
    element.dispatchEvent(event);
};
