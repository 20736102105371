import request from '@utils/request';

const BASE_URL = '/api/v2/stress-test';

export async function getStressTest({
    rentsCoefficient,
    occupancyCoefficient,
    capRateCoefficient,
    interestsRateCoefficient,
}) {
    return request(BASE_URL, {
        params: {
            rentsCoefficient,
            occupancyCoefficient,
            capRateCoefficient,
            interestsRateCoefficient,
        },
    });
}
