import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import PortfolioCharts from '@utils/charts/classes/PortfolioCharts';

import { borrowerPortfolio, propertyPortfolio } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[propertiesPortfolio]';

const initialState = {
    loading: false,
    loaded: false,
    loadingPortfolio: false,
    loadedPortfolio: false,
    loadingCharts: false,
    loadedCharts: false,
    data: {
        portfolio: {},
        charts: {},
    },
};

const portfolioSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
            state.loaded = false;
        },
        loaded(state) {
            state.loading = false;
            state.loaded = true;
        },
        loadPortfolio(state) {
            state.loadedPortfolio = false;
            state.loadingPortfolio = true;
        },
        loadBorrowerPortfolio(state) {
            state.loadedPortfolio = false;
            state.loadingPortfolio = true;
        },
        setPortfolio(state, action) {
            state.data.portfolio = action.payload;
            state.loadedPortfolio = true;
            state.loadingPortfolio = false;
        },
        loadCharts(state) {
            state.loadedCharts = false;
            state.loadingCharts = true;
        },
        setCharts(state, action) {
            state.data.charts = action.payload;
            state.loadedCharts = true;
            state.loadingCharts = false;
        },
    },
});

export const {
    load: loadMainData,
    loaded: loadedMainData,
    loadPortfolio: loadPortfolioData,
    loadBorrowerPortfolio: loadBorrowerPortfolioData,
    setPortfolio: setPortfolioData,
    loadCharts: loadChartsData,
    setCharts: setChartsData,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;

export const selectPropertyPortfolioState = (store) => store.properties.portfolio;

function* loadPortfolioDataSaga({ type, payload }) {
    const { id, isCombined, allPeriods } = payload;
    const method = isCombined ? propertyPortfolio.getPropertyPortfolioCombined : propertyPortfolio.getPropertyPortfolio;
    yield put(apiRequest({ id, allPeriods }, method, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setPortfolioData(action.payload.data));
}

function* loadChartsDataSaga({ payload }) {
    const charts = new PortfolioCharts();
    if (payload) charts.addData(payload);
    yield put(setChartsData(charts));
}

function* loadMainDataSaga({ payload }) {
    yield put(loadPortfolioData(payload));
    yield put(loadChartsData());
    const portfolio = yield take(setPortfolioData);
    yield put(loadChartsData(portfolio.payload));
    yield put(loadedMainData());
}

function* loadBorrowerPortfolioSaga({ type }) {
    yield put(apiRequest(null, borrowerPortfolio.getBorrowerPortfolioAnalytics, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setPortfolioData(action.payload.data));
}

export function* watchPortfolio() {
    yield takeLatest(loadPortfolioData, loadPortfolioDataSaga);
    yield takeLatest(loadChartsData, loadChartsDataSaga);
    yield takeLatest(loadMainData, loadMainDataSaga);
    yield takeLatest(loadBorrowerPortfolioData, loadBorrowerPortfolioSaga);
}
