import Button from '@ui/Button';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

export default function Actions({ getProperty, step, propertyListing, handleContactBroker }) {
    return (
        <div className={styles.wrapper}>
            <Button.Primary fullWidth onClick={() => getProperty('loan', step)}>
                View Available Financing
            </Button.Primary>
            <Button.Outlined fullWidth onClick={() => getProperty('valuation', step)}>
                Request Income-Based Valuation
            </Button.Outlined>
            {propertyListing ? (
                <Button.Outlined fullWidth onClick={handleContactBroker}>
                    Contact Broker
                </Button.Outlined>
            ) : null}
            {!propertyListing ? (
                <Typography color={colors['grey-400']} variant='smr'>
                    Delivery within 24 hours
                </Typography>
            ) : null}
        </div>
    );
}
