import { combineReducers } from 'redux';

import analyticsReducer, { watchAnalytics } from './analytics';
import financingScenariosReducer, { watchFinancingScenarios } from './financingScenarios';
import financingReducer, { watchFinancials } from './financials';

export const analyticsWatchers = [watchAnalytics, watchFinancingScenarios, watchFinancials];

export default combineReducers({
    analytics: analyticsReducer,
    financingScenarios: financingScenariosReducer,
    financing: financingReducer,
});
