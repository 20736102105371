export const recalculateCoupon = ({
    index = 0,
    spread = 0,
    indexFloor = 0,
    sofrAdjustment = 0,
}: {
    index: number;
    spread: number;
    indexFloor: number;
    sofrAdjustment: number;
}) => {
    return Math.max(index, indexFloor) + spread + sofrAdjustment;
};

const removeNonNumeric = (value: string) => value.replace(/[^0-9.]/g, '');

export const transformRates = (data: {
    sofr: { rate: number; date: string[] };
    treasury: { rates: Record<string, number> };
    prime: { rate: number };
    monthTermSofr: { rate: number };
}) => {
    const { sofr, treasury, prime, monthTermSofr } = data;

    return {
        date: sofr?.date?.join('') ?? '',
        rates: [
            { label: 'Prime', value: 'prime', typeValue: prime?.rate },
            { label: 'SOFR', value: 'sofr', typeValue: sofr?.rate },
            { label: '1M Term SOFR', value: 'monthTermSofr', typeValue: monthTermSofr?.rate },
            ...Object.entries(treasury?.rates || {})
                .map(([key, value]) => ({
                    label: key,
                    value: key.toLowerCase(),
                    typeValue: value,
                }))
                .sort((a, b) => {
                    const sortA = Number(removeNonNumeric(a.label));
                    const sortB = Number(removeNonNumeric(b.label));

                    return sortA - sortB;
                }),
        ],
    };
};
