import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isInsideIframe } from '@utils/browser';
import { isValidDomain } from '@utils/url';

import { logoutAuth, selectIsUserAuth } from '@scc/store/ducks/auth';

import config from '@config';
import { ON_MESSAGE_MC, AUTH_SERVICE_MC } from '@constants/connectorMC';
import { useCustomEventListener, emitCustomEvent } from '@shared/hooks/useCustomEvents';

const APP_PREFIX = config.get('app');

function useCustomEventListeners() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsUserAuth);

    const handleMessages = (event, { type = '', payload = {} } = {}) => {
        if (!type.startsWith(APP_PREFIX)) return;
        switch (type) {
            case `${APP_PREFIX}-${AUTH_SERVICE_MC}`: {
                const { status } = payload;
                if (status === 401 || status === 406) {
                    dispatch(logoutAuth());
                }
                break;
            }
            default:
                emitCustomEvent(type, payload);
        }
    };

    const handleRootMessages = (event) => {
        if (!isValidDomain(event.origin)) return;

        const { data } = event;
        try {
            const { type, payload } = data && typeof data === 'string' ? JSON.parse(data) : data || {};
            if (!type?.startsWith(APP_PREFIX)) return;
            handleMessages(event, { type, payload });
        } catch (e) {
            console.warn(e);
        }
    };

    useEffect(() => {
        if (typeof window === 'undefined') return;
        if (isInsideIframe() && !isLoggedIn) {
            const data = {
                type: `${APP_PREFIX}-${AUTH_SERVICE_MC}`,
                payload: {
                    status: 401,
                },
            };
            window.parent?.postMessage?.(JSON.stringify(data), '*');
        }
    }, [isLoggedIn]);

    useCustomEventListener('message', handleRootMessages, [handleRootMessages], window);
    useCustomEventListener(ON_MESSAGE_MC, handleMessages, [handleMessages]);
}

export default useCustomEventListeners;
