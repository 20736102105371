import { Route } from 'react-router-dom';

import BlockedBox from '@components/BlockedBox';
import Noop from '@components/Noop';

import ProtectedRoute from './ProtectedRoute';

const defaultProps = {
    component: Noop,
    template: Noop,
    templateProps: {},
    isLimited: false,
    limitedRoutes: [],
};

function RouteItem({
    status,
    component: Component,
    template: Template,
    redirects,
    templateProps,
    auth,
    alter,
    isLimited,
    limitedRoutes,
    ...rest
}) {
    const { name = '' } = rest;
    const isRouteLimited = isLimited && !limitedRoutes?.includes(name);

    if (isRouteLimited) {
        return (
            <Route
                exact={rest.exact}
                path={rest.path}
                render={() => (
                    <Template label={rest.label} name={rest.name} state={rest.state} {...templateProps}>
                        <BlockedBox.Page />
                    </Template>
                )}
            />
        );
    }

    return status ? (
        <ProtectedRoute
            alter={alter}
            auth={auth}
            component={Component}
            forbiddenRedirect={redirects}
            status={status}
            template={Template}
            templateProps={templateProps}
            {...rest}
        />
    ) : (
        <Route
            exact={rest.exact}
            path={rest.path}
            render={(props) => (
                <Template label={rest.label} name={rest.name} state={rest.state} {...templateProps}>
                    <Component {...props} {...rest} />
                </Template>
            )}
        />
    );
}

RouteItem.defaultProps = defaultProps;

export default RouteItem;
