import { useState, MouseEvent } from 'react';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Popover from '@ui/Popover';
import Typography from '@ui/Typography';

import styles from './styles.module.scss';

type TConfirmPopoverProps = {
    confirmText: string;
    onSubmit: () => void;
    children: React.ReactNode;
};

export function ConfirmPopover({ confirmText, onSubmit, children }: TConfirmPopoverProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const isOpen = Boolean(anchorEl);

    const onWrapperClick = (event: MouseEvent<HTMLDivElement>) => {
        event?.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onClose = (event) => {
        event?.stopPropagation();
        setAnchorEl(null);
    };

    const onSubmitClick = (event: MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation();
        onClose(event);
        onSubmit();
    };

    return (
        <Box onClick={onWrapperClick}>
            {children}
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={isOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={onClose}
            >
                <div className={styles.popoverContent}>
                    <Typography className={styles.popoverText} variant='hsb13'>
                        {confirmText}
                    </Typography>
                    <div className={styles.popoverControls}>
                        <Button size='small' variant='outlined' onClick={onClose}>
                            No
                        </Button>
                        <Button size='small' variant='outlined' onClick={onSubmitClick}>
                            Yes
                        </Button>
                    </div>
                </div>
            </Popover>
        </Box>
    );
}
