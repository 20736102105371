import request from '@utils/request';

const BASE_URL = '/api/v2/bookmarks';

export async function getAllBookmarks() {
    return request(BASE_URL);
}

export async function getBookmark({ parcelId, countyId }) {
    return request(`${BASE_URL}/parcel/${parcelId?.replace('/', '_SLASH_')}/county/${countyId}`);
}

export async function createBookmark({ parcelId, countyId }) {
    return request(BASE_URL, {
        method: 'POST',
        body: JSON.stringify({ parcelId, countyId }),
    });
}

export async function deleteBookmark(id) {
    return request(`${BASE_URL}/${id}`, {
        method: 'DELETE',
    });
}
