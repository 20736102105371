import request from '@utils/request';

const BASE_URL = '/api/v2/members';
const BASE_URL_USER = '/api/v2/user';
const BASE_PORTFOLIO_URL = `${BASE_URL}/portfolio`;

export async function getMembers({ size = 20, page = 0, entityType, memberType, search } = {}) {
    return request(BASE_URL, {
        params: { page, size, entityType, memberType, search },
    });
}

export async function getMember({ memberId }) {
    return request(`${BASE_URL}/${memberId}`);
}

export async function filterMembers({ page = 0, size = 20, status = '', search = '' } = {}) {
    return request(`${BASE_URL}`, {
        params: {
            detailsStatus: status,
            search,
            page,
            size,
        },
    });
}

export async function createUserMember({ body }) {
    return request(`${BASE_URL_USER}/create-member-user`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updateUserMember({ memberId, body }) {
    return request(`${BASE_URL}/${memberId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function getOwners({ size, page, memberId }) {
    return request(`${BASE_URL}/${memberId}/owners`, {
        params: {
            page,
            size,
        },
    });
}

export async function getTeamByMember({ memberId }) {
    return request(`${BASE_URL}/team/${memberId}`);
}

export async function addNewTeamMember({ memberId, memberData }) {
    return request(`${BASE_URL}/team/${memberId}/new`, {
        method: 'POST',
        body: JSON.stringify(memberData),
    });
}

export async function addExistedTeamMembers({ memberId, emails }) {
    return request(`${BASE_URL}/team/${memberId}/existed`, {
        method: 'POST',
        body: JSON.stringify({ emails }),
    });
}

export async function deleteMemberFromTeam({ teamMemberId, memberIdToRemove }) {
    return request(`${BASE_URL}/team/remove`, {
        method: 'DELETE',
        body: JSON.stringify({ teamMemberId, memberIdToRemove }),
    });
}

export async function getMemberProperties({ page = 0, size = 20, memberId }) {
    return request(`${BASE_PORTFOLIO_URL}/${memberId}`, {
        params: { page, size },
    });
}

export async function getTotal({ memberId }) {
    return request(`${BASE_PORTFOLIO_URL}/${memberId}/totals`);
}

export async function createProperty({ memberId, ...body }) {
    return request(`${BASE_PORTFOLIO_URL}/${memberId}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function deleteProperty(id) {
    return request(`${BASE_PORTFOLIO_URL}/${id}`, {
        method: 'DELETE',
    });
}

export async function updateMember({ id, type, ssn, notes }) {
    const reqData = {
        type,
        ssn,
        notes,
    };
    return request(`${BASE_URL}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(reqData),
    });
}

export async function deleteMember(id) {
    return request(`${BASE_URL}/${id}`, {
        method: 'DELETE',
    });
}

export async function getMemberLoans({ memberId, size = 20, page = 0 } = {}) {
    return request(`${BASE_URL}/loans/${memberId}`, {
        params: { page, size },
    });
}

export async function filterMemberLoans({
    page = 0,
    size = 20,
    status = '',
    program = '',
    search = '',
    memberId,
} = {}) {
    return request(`${BASE_URL}/loans/${memberId}`, {
        params: {
            detailsStatus: status,
            search,
            program,
            page,
            size,
        },
    });
}

export async function addMemberLoan(body) {
    return request(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function deleteMemberLoan({ loanDetailsId, memberId }) {
    return request(`${BASE_URL}/${memberId}/loan/${loanDetailsId}`, {
        method: 'DELETE',
    });
}

export async function addExistingLoan(body) {
    return request(`${BASE_URL}/add-loan`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}
