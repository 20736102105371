import { baseApi as api } from '../configs/rentRollLabel';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        deleteRentRollLabelByRentRollId: build.mutation<
            DeleteRentRollLabelByRentRollIdApiResponse,
            DeleteRentRollLabelByRentRollIdApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/rentroll-label/${queryArg.rentRollId}`, method: 'DELETE' }),
        }),
        createOrUpdateRentRollLabel: build.mutation<
            CreateOrUpdateRentRollLabelApiResponse,
            CreateOrUpdateRentRollLabelApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/rentroll-label`, method: 'POST', body: queryArg.rentRollLabelDto }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type DeleteRentRollLabelByRentRollIdApiResponse = /** status 200 OK */ GenericJsonResponseString;
export type DeleteRentRollLabelByRentRollIdApiArg = {
    rentRollId: number;
};
export type CreateOrUpdateRentRollLabelApiResponse = /** status 200 OK */ GenericJsonResponseRentRollLabelDto;
export type CreateOrUpdateRentRollLabelApiArg = {
    rentRollLabelDto: RentRollLabelDto;
};
export type GenericJsonResponseString = {
    status?: string;
    data?: string;
    message?: string;
    code?: string;
};
export type RentRollLabelDto = {
    id?: number;
    rentRollId?: number;
    rentRollLabel?: RentRollLabel;
};
export type GenericJsonResponseRentRollLabelDto = {
    status?: string;
    data?: RentRollLabelDto;
    message?: string;
    code?: string;
};
export enum RentRollLabel {
    Underwriting = 'UNDERWRITING',
}
export const { useDeleteRentRollLabelByRentRollIdMutation, useCreateOrUpdateRentRollLabelMutation } = injectedRtkApi;
