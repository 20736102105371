import { getDimensionObject } from '@shared/hooks/useDimensions';
import { scrollToTop } from '@shared/hooks/useScrollPosition';

export const scrollToElement = ({ newValue, element, overviewTab, position, onChangeActiveMapTab }) => {
    const infoContainer = document.getElementById('infoContainer');

    const { height } = getDimensionObject({ current: infoContainer });
    const { top } = getDimensionObject({ current: element });
    const offset = Math.abs(position + top);

    const offsetTop = newValue === overviewTab ? 0 : offset - (height + 100);
    scrollToTop({ top: offsetTop });
    onChangeActiveMapTab(newValue);
};

export const getLocationParamsData = ({
    transportation,
    hwyscore,
    footTraffic,
    popularity,
    populationDensity,
    workDensity,
}) => {
    return [
        {
            label: 'Transit',
            tooltip:
                'This score measures the quantity and accessibility of public transit within a 0.3-mile' +
                ' radius of the property (100 – very accessible, 0 – not accessible)',
            value: transportation?.val,
        },
        {
            label: 'Proximity to Highways',
            tooltip: 'This score measures proximity of the property to main highways (100 – very close, 0 – very far)',
            value: hwyscore?.val,
        },
        {
            label: 'Foot Traffic',
            tooltip:
                'This score combines three metrics to measure foot traffic around the property. It looks' +
                ' at the number of people living or working in a 0.3-mile radius of the property, as well' +
                ' as tracks the number of GPS points from mobile devices. (100 – heavy traffic, 0 – no traffic)',
            value: footTraffic?.val,
        },
        {
            label: 'Amenities',
            tooltip:
                'This score measures the quantity and diversity of amenities, such as grocery stores,' +
                ' restaurants, fitness centers, etc., within a 0.3-miles radius of the property (100 – many' +
                ' amenities, 0 – no amenities)',
            value: popularity?.val,
        },
        {
            label: 'Demographics',
            tooltip:
                'This score measures the population density and income of people living in a 0.3-mile radius' +
                ' of the property (100 – high density, high income; 0 – low density, low income)',
            value: populationDensity?.rank,
        },
        {
            label: 'Business Environment',
            tooltip:
                'This score measures the density of businesses in the area, and suitability of the area for' +
                ' businesses within a 0.3-mile radius of the property. (100 – high business density and suitability,' +
                ' 0 – low business density and suitability)',
            value: workDensity?.rank,
        },
    ];
};
