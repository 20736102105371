import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export function scrollToTop(options) {
    window.scrollTo({ ...options, behavior: 'smooth' });
}

const useScrollPosition = (initialPosition = 0, throtl = 300) => {
    const [position, setPosition] = useState(initialPosition);

    const handleScroll = throttle(() => {
        if (typeof window !== 'undefined') {
            setPosition(window.scrollY);
        }
    }, throtl);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return position;
};

export default useScrollPosition;
