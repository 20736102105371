import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { deleteFileRequestRentRoll, uploadFilesRentRoll } from '@scc/store/ducks/rentRoll';

import { rentRollApi } from '@api';

export default function useUploadRentRoll({ type, params }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const uploadFileMethod = type === 'os' ? rentRollApi.initRentRollStatementUpload : rentRollApi.initRentRollUpload;

    const onDrop = useCallback(
        async (acceptedFiles) => {
            if (!acceptedFiles.length) {
                return;
            }

            const uploadFileRequests = acceptedFiles.map((file) => {
                const formData = new FormData();

                Object.keys(params).forEach((key) => {
                    formData.append(key, params[key]);
                });

                formData.append('files[]', file, file.name);

                return uploadFileMethod(formData);
            });
            setLoading(true);
            const responseList = await Promise.all(uploadFileRequests);
            setLoading(false);
            responseList.forEach((response, index) => {
                if (!response?.error) {
                    dispatch(uploadFilesRentRoll({ res: response, name: acceptedFiles[index].name }));
                }
            });
        },
        [dispatch, params, uploadFileMethod]
    );

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 10,
        accept: 'application/*, image/*',
        onDrop,
    });

    const removeFile = (id) => () => {
        dispatch(deleteFileRequestRentRoll({ id, type }));
    };

    return [
        {
            loading,
        },
        {
            getRootProps,
            getInputProps,
            removeFile,
        },
    ];
}
