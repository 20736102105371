import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_API_KEY = typeof process !== 'undefined' && process.env.STRIPE_API_KEY;
const stripePromise = loadStripe(STRIPE_API_KEY);

const StripePayment = function ({ children }) {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripePayment;
