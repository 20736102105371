import PropertyAddress from '@scc/pages/Property/components/PropertyAddress';
import PropertyButtons from '@scc/pages/Property/components/PropertyButtons';

import Stack from '@ui/Stack';

import styles from './styles.module.scss';

export default function PropertyTitleSection({
    address,
    city,
    isLoaded,
    parcel,
    handleShareProperty,
    handleAddToListingClick,
}) {
    return (
        <Stack className={styles.container} direction='row' justifyContent='space-between'>
            <PropertyAddress {...{ address, city, isLoaded }} />
            <PropertyButtons {...{ parcel, handleShareProperty, handleAddToListingClick }} />
        </Stack>
    );
}
