import colors from '@themes/palette/export.module.scss';

const card = () => ({
    MuiCard: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                border: `1px solid ${colors['grey-A200']}`,
            },
        },
        variants: [
            {
                props: {
                    border: 'small',
                },
                style: {
                    borderRadius: 8,
                },
            },
            {
                props: {
                    border: 'medium',
                },
                style: {
                    borderRadius: 16,
                },
            },
            {
                props: {
                    variant: 'tertiary',
                },
                style: {
                    borderStyle: 'dashed',
                    backgroundColor: colors.white,
                },
            },
            {
                props: {
                    variant: 'primary',
                },
                style: {
                    backgroundColor: colors.white,
                },
            },
            {
                props: {
                    variant: 'secondary',
                },
                style: {
                    backgroundColor: colors['grey-A100'],
                    borderColor: 'transparent',
                },
            },
        ],
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                padding: '1.2rem 1.2rem .5rem',
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: '1.2rem',
            },
        },
    },
});

export default card;
