const Popover = () => ({
    MuiPopover: {
        styleOverrides: {
            paper: {
                borderRadius: '8px',
                boxShadow: '0 1px 3px 2px rgba(9, 7, 20, 0.1)',
            },
        },
    },
});
export default Popover;
