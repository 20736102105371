import cx from 'classnames';
import { memo } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Icon from '@ui/Icon';

import colors from '@themes/palette/export.module.scss';

import styles from '../../styles.module.scss';

const sizes = {
    small: styles.editableFieldWrapperSmall,
    medium: styles.editableFieldWrapperMedium,
    big: styles.editableFieldWrapperBig,
    fullWidth: styles.editableFieldWrapperFullWidth,
};

function EditableFieldWrapperComponent({ component, size, type, value, isEditMode }) {
    const selectIcon = type === 'select' && value !== '-' && !isEditMode && (
        <Icon color={colors['darkNeutral-500']} component={ChevronIcon} fontSize={13} />
    );

    return (
        <div
            className={cx(styles.editableFieldWrapper, sizes[size])}
            style={{ width: type === 'select' ? '100%' : 'auto' }}
        >
            {component}
            {selectIcon}
        </div>
    );
}

export const EditableFieldWrapper = memo(EditableFieldWrapperComponent);
