import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';

import ContactUsModal from '@scc/components/ContactUsModal';
import Logo from '@scc/components/Logo';
import { socialLinks, enterprisePolicyLinks, enterpriseFooterLinks } from '@scc/router/links';

import Button from '@ui/Button';
import Container from '@ui/Container';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Link from '@ui/Link';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import CallIcon from '@icons/outlined/call.svg';
import LocationIcon from '@icons/outlined/location.svg';
import MessageIcon from '@icons/outlined/message-text-1.svg';
import SmsIcon from '@icons/outlined/sms.svg';

import { FOOTER_DATA, TOP_FOOTER_COMPONENT_MAP } from './helpers';
import styles from './styles.module.scss';

const TELEPHONE = typeof process !== 'undefined' && process.env.TELEPHONE;
const EMAIL = typeof process !== 'undefined' && process.env.EMAIL;

function Footer({ onClick, scrollRef, mobile, links = {}, location }) {
    const [isOpenContactUs, setIsOpenContactUs] = useState(false);

    const handleOpenContactUs = () => setIsOpenContactUs(true);
    const handleCloseContactUs = () => setIsOpenContactUs(false);

    const executeScroll = () => scrollRef.current.scrollIntoView({ block: 'start' });

    const theme = useTheme();
    const isNarrowMobile = useMediaQuery(theme.breakpoints.down('narrowMobile'));

    const {
        partners: { soc },
    } = FOOTER_DATA;

    const TopFooterComponent = TOP_FOOTER_COMPONENT_MAP[location || 'home'];

    return (
        <footer className={styles.footer}>
            <TopFooterComponent mobile={isNarrowMobile} />
            <Container className={styles.top} maxWidth='xl'>
                <Grid
                    className={styles.logoContainer}
                    direction='column'
                    justifyContent='space-between'
                    md={4}
                    container
                >
                    <Grid item>
                        <Logo color='#284AB0' rel='nofollow' size='large' type='link' short />
                    </Grid>
                    {!mobile && (
                        <Grid className={styles.socContainer} xs={5} item>
                            <Image alt='Smart Capital Logo' src={soc.logo} />
                        </Grid>
                    )}
                </Grid>

                <Grid direction={mobile ? 'column' : 'row'} justifyContent='space-between' md={8} spacing={3} container>
                    {!isEmpty(links) && (
                        <Grid direction='column' item>
                            <div className={styles.linksContainer}>
                                {!mobile && (
                                    <Typography color={colors.white} variant='hsb18'>
                                        {links?.title}
                                    </Typography>
                                )}
                                <div className={styles.linkItemsWrapper}>
                                    {links?.links.map(({ name, label, value, toScroll = false, noFollow }) => (
                                        <Button.Link
                                            key={name}
                                            aria-label={label}
                                            color='secondary'
                                            component='button'
                                            size='small'
                                            {...(noFollow ? { rel: 'nofollow' } : {})}
                                            onClick={() => {
                                                onClick(value);
                                                if (toScroll) {
                                                    executeScroll();
                                                }
                                            }}
                                        >
                                            <Typography color='#FFFFFFCC' variant='hsb13'>
                                                {label}
                                            </Typography>
                                        </Button.Link>
                                    ))}
                                </div>
                            </div>
                        </Grid>
                    )}
                    <Grid direction='column' item>
                        <div className={styles.linksContainer}>
                            {!mobile && (
                                <Typography color={colors.white} variant='hsb18'>
                                    Company
                                </Typography>
                            )}
                            <div className={styles.linkItemsWrapper}>
                                {enterpriseFooterLinks.map(({ path, name, label, external, target, icon }) => {
                                    const props = external ? { href: path, target } : { component: NavLink, to: path };
                                    return (
                                        <Button.Link
                                            key={name}
                                            aria-label={label}
                                            className={styles.buttonLink}
                                            color='secondary'
                                            size='small'
                                            {...(label === 'News' ? { rel: 'nofollow' } : {})}
                                            {...props}
                                        >
                                            <Typography color='#FFFFFFCC' variant='hsb13'>
                                                {label}
                                            </Typography>
                                            {icon && (
                                                <Icon
                                                    color={icon.color}
                                                    component={icon.component}
                                                    fontSize={icon.size}
                                                />
                                            )}
                                        </Button.Link>
                                    );
                                })}
                            </div>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className={styles.contactsWrapper}>
                            <div className={styles.questionsWrapper}>
                                <Typography color={colors.white} variant={mobile ? 'hr14' : 'hsb18'}>
                                    Have a question?
                                </Typography>
                                <Button
                                    aria-label='Contact Us'
                                    className={styles.button}
                                    fullWidth
                                    onClick={handleOpenContactUs}
                                >
                                    <Icon color={colors.white} component={MessageIcon} />
                                    Contact Us
                                </Button>
                            </div>
                            <div className={styles.address}>
                                <div className={styles.addressWrapper}>
                                    <Icon color={colors.white} component={LocationIcon} fontSize='14px' />
                                    <div>
                                        <Typography color={colors.white} variant='mdr'>
                                            535 Mission Street, suite 1760
                                        </Typography>
                                        <Typography color={colors['grey-500']} variant='mdr'>
                                            San Francisco, CA, 94105
                                        </Typography>
                                    </div>
                                </div>
                                <div className={styles.addressWrapper}>
                                    <Icon color={colors.white} component={CallIcon} fontSize='14px' />
                                    <Link.Secondary href={`tel:${TELEPHONE}`.replace(/ /g, '')}>
                                        <Typography
                                            className={styles.addressWrapper}
                                            color={colors.white}
                                            variant='mdr'
                                        >
                                            {TELEPHONE}
                                        </Typography>
                                    </Link.Secondary>
                                </div>
                                <div className={styles.addressWrapper}>
                                    <Icon color={colors.white} component={SmsIcon} fontSize='14px' />
                                    <Link.Secondary href={`mailto:${EMAIL}`}>
                                        <Typography
                                            className={styles.addressWrapper}
                                            color={colors.white}
                                            variant='mdr'
                                        >
                                            {EMAIL}
                                        </Typography>
                                    </Link.Secondary>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className={styles.bottom}>
                <Container className={styles.container} maxWidth='xl' Container>
                    {mobile && (
                        <Typography className={styles.mobileCopyright} color='#ffffff80' variant='smr'>
                            © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                        </Typography>
                    )}
                    <div className={styles.copyright}>
                        {mobile ? (
                            <div className={styles.mobileSocLogoWrapper}>
                                <Image alt='Smart Capital Logo' src={soc.shortLogo} />
                            </div>
                        ) : (
                            <Typography color='#ffffff80' variant='smr'>
                                © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                            </Typography>
                        )}

                        <div>
                            {enterprisePolicyLinks.map(({ path, name, label, external, target }) => {
                                const props = external ? { href: path, target } : { component: NavLink, to: path };
                                return (
                                    <Button.Link
                                        key={name}
                                        aria-label={label}
                                        color='secondary'
                                        size='small'
                                        {...props}
                                    >
                                        <Typography color='#ffffff80' variant='smr'>
                                            {label}
                                        </Typography>
                                    </Button.Link>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.socialLinks}>
                        <ItemList items={socialLinks}>
                            {({ name, path, component, viewBox }) => (
                                <Button.Link key={name} aria-label={name} href={path}>
                                    <Icon
                                        color={colors['grey-A200']}
                                        component={component}
                                        fontSize='15px'
                                        viewBox={viewBox}
                                    />
                                </Button.Link>
                            )}
                        </ItemList>
                    </div>
                    <div className={styles.menu} />
                </Container>
            </div>
            <ContactUsModal open={isOpenContactUs} onClose={handleCloseContactUs} onSuccess={handleCloseContactUs} />
        </footer>
    );
}

export default Footer;
