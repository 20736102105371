import { commaSeparateNumber, isNumber, parseLocaleNumber, formatter } from '@utils/index';

import styles from '@modules/FinancialModel/styles.module.scss';

export const analysisAndSizing = {
    interestRate: 5,
    amortization: 30,
    dscr: 1.25,
    ltv: 70,
};

export const loansIndexTypes = [
    {
        value: 'prime',
        label: 'Prime',
        typeValue: 7.75,
    },
    {
        value: 'sofr',
        label: 'SOFR',
        typeValue: 4.55,
    },
    {
        value: 'monthTermSofr',
        label: '1 Month Term SOFR',
        typeValue: 4.55,
    },
    {
        value: 't1',
        label: 'T1',
        typeValue: 5.25,
    },
    {
        value: 't2',
        label: 'T2',
        typeValue: 5.05,
    },
    {
        value: 't3',
        label: 'T3',
        typeValue: 4.71,
    },
    {
        value: 't5',
        label: 'T5',
        typeValue: 4.34,
    },
    {
        value: 't7',
        label: 'T7',
        typeValue: 4.19,
    },
    {
        value: 't10',
        label: 'T10',
        typeValue: 4.19,
    },
    {
        value: 't20',
        label: 'T20',
        typeValue: 4.11,
    },
    {
        value: 't30',
        label: 'T30',
        typeValue: 3.88,
    },
    {
        value: 'none',
        label: ' ',
        typeValue: 0,
    },
];

export const maxAfterComma = (value, dp) => {
    const val = +parseFloat(value).toFixed(dp);
    return !isNumber(val) || val === Infinity ? 0 : val;
};

export const scenarioOptions = [
    {
        label: 'At Contract',
        value: 'contract',
    },
    {
        label: 'Lesser of Contract and Market',
        value: 'lesser',
    },
    {
        label: 'Market Rate',
        value: 'market',
    },
    {
        label: 'Rollover to Market at Year 5',
        value: 'rollover',
    },
    {
        label: 'Down Market Rate',
        value: 'downMarket',
    },
];

const loanRowData = [
    {
        label: 'Loan name',
        name: 'name',
        notEditable: true,
        cols: [
            {
                name: 'name',
            },
        ],
    },
    {
        label: 'Amount',
        name: 'amount',
        notEditable: true,
        cols: [
            {
                name: 'amount',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Term (yrs)',
        name: 'terms',
        notEditable: true,
        cols: [
            {
                name: 'terms',
                postfix: 'Y',
            },
        ],
    },
    {
        label: 'Interest only (yrs)',
        notEditable: true,
        cols: [
            {
                name: 'interest',
                postfix: 'Y',
            },
        ],
    },
    {
        label: 'Amortization, after IO',
        name: 'amortization',
        notEditable: true,
        cols: [
            {
                name: 'amortization',
                postfix: 'Y',
            },
        ],
    },
    {
        label: 'Index',
        name: 'index',
        labelType: 'none',
        labelTypes: loansIndexTypes,
        notEditable: true,
        cols: [
            {
                name: 'index',
                postfix: '%',
            },
        ],
    },
    {
        label: 'SOFR Adjustment',
        name: 'sofrAdjustment',
        notEditable: true,
        cols: [
            {
                name: 'sofrAdjustment',
                postfix: '%',
            },
        ],
    },
    {
        label: 'Spread',
        name: 'spread',
        notEditable: true,
        cols: [
            {
                name: 'spread',
                postfix: '%',
            },
        ],
    },
    {
        label: 'Index Floor',
        name: 'indexFloor',
        notEditable: true,
        cols: [
            {
                name: 'indexFloor',
                postfix: '%',
            },
        ],
    },
    {
        label: 'Coupon',
        name: 'cashInterestRate',
        notEditable: true,
        cols: [
            {
                name: 'cashInterestRate',
                postfix: '%',
            },
        ],
    },
    {
        label: 'PIK interest',
        name: 'pikInterest',
        notEditable: true,
        cols: [
            {
                name: 'pikInterest',
                postfix: '%',
            },
        ],
    },
    {
        label: 'Equity participation',
        name: 'equityParticipation',
        notEditable: true,
        cols: [
            {
                name: 'equityParticipation',
                postfix: '%',
            },
        ],
    },
];

export const fiveDecimalNames = {
    equityParticipation: true,
    pikInterest: true,
    spread: true,
    interestRateCap: true,
    cashInterestRate: true,
    indexFloor: true,
    index: true,
    sofrAdjustment: true,
};

export const feesRowData = [
    {
        label: 'Lender Processing & Points',
        name: 'lenderPoints',
        notEditable: true,
        cols: [
            {
                name: 'lenderPointsPercent',
                postfix: '%',
            },
            {
                name: 'lenderPoints',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Mortgage Broker Fee',
        name: 'mortgageFee',
        notEditable: true,
        cols: [
            {
                name: 'mortgageFeePercent',
                postfix: '%',
            },
            {
                name: 'mortgageFee',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Title/Escrow',
        name: 'titleEscrow',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'titleEscrow',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Misc/Contingency',
        name: 'misc',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'misc',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Other',
        name: 'other',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'other',
                prefix: '$',
            },
        ],
    },
    {
        rowClass: styles.totalsRow,
        name: 'total',
        label: 'Total',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'total',
                prefix: '$',
                notEditable: true,
            },
        ],
    },
];

export const reportsRowData = [
    {
        label: 'Appraisal',
        name: 'appraisal',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'appraisal',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Environmental',
        name: 'environmental',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'environmental',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Property Condition',
        name: 'condition',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'condition',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Seismic',
        name: 'seismic',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'seismic',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Survey',
        name: 'survey',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'survey',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Lender Legal',
        name: 'lenderLegal',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'lenderLegal',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Borrower Legal',
        name: 'borrowerLegal',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'borrowerLegal',
                prefix: '$',
            },
        ],
    },
    {
        label: 'Other',
        name: 'other',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'other',
                prefix: '$',
            },
        ],
    },
    {
        name: 'total',
        label: 'Total',
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: 'total',
                prefix: '$',
                notEditable: true,
            },
        ],
        rowClass: styles.totalsRow,
    },
];

export const addEmptyRow = (type = 'other', props) => {
    const resObject = {
        amount: 0,
        type,
        name: ' ',
    };
    switch (type) {
        case 'loan':
            resObject.rows = loanRowData.map((item) => {
                if (item.name === 'index' && props?.rates.length) {
                    const ratesObject = {};

                    props.rates.forEach((rate) => {
                        ratesObject[rate.value] = rate.typeValue;
                    });

                    const labelTypes = loansIndexTypes.map((loansIndexType) => {
                        if (loansIndexType.value in ratesObject) {
                            return {
                                ...loansIndexType,
                                typeValue: ratesObject[loansIndexType.value],
                            };
                        }

                        return loansIndexType;
                    });

                    return {
                        ...item,
                        labelType: props.index || 'Other',
                        labelTypes,
                    };
                }

                return item;
            });
            loanRowData
                .map((item) => item.cols[0].name)
                .forEach((key) => {
                    let val = 0;
                    switch (key) {
                        case 'index':
                            val = props?.index ? props.index : 0;
                            break;
                        case 'name':
                            val = ' ';
                            break;
                        default:
                            break;
                    }
                    resObject[key] = val;
                });
            break;
        case 'reports':
            resObject.rows = reportsRowData;
            reportsRowData
                .map((item) => item.cols[1].name)
                .forEach((key) => {
                    resObject[key] = 0;
                });
            break;
        case 'fees':
            resObject.rows = feesRowData;
            feesRowData
                .map((item) => item.cols[1].name)
                .forEach((key) => {
                    resObject[key] = 0;
                });

            resObject.lenderPointsPercent = 0;
            resObject.mortgageFeePercent = 0;
            break;
        default:
            return resObject;
    }
    return resObject;
};

export const formatValueOnLoansRow = ({ value, prefix, postfix }) => {
    if (!prefix && !postfix) {
        return value && value.toString().length && value !== ' ' ? value.toString() : '-';
    }

    if (prefix === '$' || postfix === '$') {
        return formatter.number(value, { maximumFractionDigits: 0 });
    }

    if (prefix === '%' || postfix === '%') {
        return commaSeparateNumber(value === 0 || !value ? '0.00000' : value.toFixed(5));
    }

    if (prefix === 'Y' || postfix === 'Y') {
        return commaSeparateNumber(value === 0 || !value ? '0.00' : value.toFixed(2));
    }

    return value || '-';
};

export const recalculateRows = ({ data, rows, isLoan = false }) => {
    let categorizedValue = 0;
    for (let i = 0; i < rows.length - 1; i += 1) {
        const name = rows[i].cols[1]?.name || rows[i].cols[0]?.name;
        if (name !== 'name') {
            categorizedValue += isNumber(Number(data[name])) ? Number(data[name]) : 0;
        }
    }
    const copy = { ...data };
    if (!isLoan) {
        copy.amount = categorizedValue;
        copy.total = categorizedValue;
    }
    return copy;
};

export const sources = [addEmptyRow('equity'), addEmptyRow('loan'), addEmptyRow()];

export const uses = [addEmptyRow('purchase'), addEmptyRow('fees'), addEmptyRow('reports'), addEmptyRow()];

export const getDefaultSources = (loanDetails, rates) => {
    const data = [addEmptyRow('equity'), addEmptyRow('loan', { index: loanDetails.indexLabel, rates }), addEmptyRow()];
    const { assetValue, ...restLoanDetails } = loanDetails;

    data[0].amount = assetValue;
    data[1] = {
        ...data[1],
        ...restLoanDetails,
    };

    return data;
};

export const getDefaultUses = ({ assetValue, originationFee, exitFee }, sourcesArray) => {
    const loanValue = sourcesArray.find((item) => item.type === 'loan')?.amount || 0;
    const data = [...uses];
    const lenderPointsPercent = originationFee + exitFee || 0;
    data[0].amount = assetValue;
    data[1].lenderPointsPercent = lenderPointsPercent;
    const lenderPoints = lenderPointsPercent && loanValue ? (loanValue * (lenderPointsPercent / 100)).toFixed(0) : 0;
    data[1].lenderPoints = +lenderPoints;
    const { rows, ...restData } = data[1];
    data[1] = { rows, ...recalculateRows({ rows, data: restData }) };
    return data;
};

export const calculateFinancialsAttributes = (financials, financialsData, scenario, fullData) => {
    let totalSize = 0;
    let totalUnits = 0;
    let value = 0;

    financials?.forEach((item) => {
        totalSize += item.size ?? 0;
        totalUnits += item.units ?? 0;
        value += item.underwritingValuation?.[scenario] ?? 0;
    });

    const noiArr = (financialsData.proforma?.[scenario]?.rows || []).filter((row) => row.type === 'TOTAL_NOI');
    const noi = noiArr.length ? noiArr[0].values[0].value : 0;

    const assetValue =
        value ||
        fullData?.underwritingValuation?.[scenario] ||
        fullData?.underwritingValuation?.customScenarios?.[scenario] ||
        0;
    const valuation = assetValue;

    return { totalSize, totalUnits, assetValue, valuation, noi };
};

const calcResFraction = (amount, total) => {
    if (!amount || !total) return 0;
    return ((amount / total) * 100).toFixed(2);
};

export const recalculateFraction = (array) => {
    const total = Math.abs(array.reduce((acc, item) => (item?.amount ? acc + Number(item.amount) : acc), 0));

    return array.map((item) => ({
        ...item,
        fraction: calcResFraction(item?.amount, total),
    }));
};

export const getLtv = (sourcesArray, assetValue) => {
    if (!assetValue) return 0;
    const debtBalance = sourcesArray
        .filter((item) => item.type === 'loan')
        .reduce((acc, item) => acc + Number(item.amount), 0);
    return Math.round((debtBalance / assetValue) * 100);
};

export const getDscr = (sourcesArray, noi) => {
    if (!noi) return 0;

    const dscr = sourcesArray.reduce((acc, cur = {}) => {
        if (cur.type !== 'loan') {
            return acc;
        }

        if (!cur?.amortization && !cur?.interest) {
            return acc;
        }

        let debtService;
        const termMonthly = cur.amortization * 12;
        const interestRateMonthly = cur.cashInterestRate / 12 / 100;
        // step 2
        const t = (interestRateMonthly + 1) ** termMonthly;
        // step 3
        const c15 = t * interestRateMonthly;
        // step 5
        const coef1 = (interestRateMonthly + 1) ** termMonthly - 1;
        const loanAmount = cur.amount;
        const io = (cur.interest ? cur.interest : 0) * 12;
        // step 6
        const step6 = (loanAmount * c15) / coef1;
        // step 7
        const step7 = loanAmount * interestRateMonthly;

        if (io > 12) {
            debtService = step7 * 12;
        } else if (!termMonthly) {
            debtService = step7 * io;
        } else {
            debtService = step6 * (12 - io) + step7 * io;
        }

        if (Number(debtService)) {
            return acc + debtService;
        }

        return acc;
    }, 0);

    if (!dscr) {
        return 0;
    }

    return (parseLocaleNumber(String(noi)) / dscr).toFixed(2);
};

export const getDebtYield = (sourcesArray, noi) => {
    if (!noi) {
        return 0;
    }
    const totalLoan = sourcesArray.reduce((acc, item) => (item.type === 'loan' ? acc + item.amount : acc), 0);
    return totalLoan === 0 ? 0 : Math.round((noi / totalLoan) * 100);
};

export const updateContextLabel = ({ data, rows, key, newLabel, newKey }) => {
    const objCopy = { ...data };
    objCopy[newKey] = data[key];
    const rowsCopy = [...rows];
    const index = rows.findIndex((item) => item.cols[1].name === key);
    const columns = rowsCopy[index].cols;
    rowsCopy[index] = { label: newLabel, name: newKey, cols: [columns[0], { ...columns[1], name: newKey }] };
    return { ...objCopy, rows: rowsCopy };
};

export const handleAddWidgetRow = ({ data, rowId }) => {
    const { rows, ...rest } = { ...data[rowId] };
    let counter = 1;
    while (rest[`title${counter}`]) {
        counter += 1;
    }
    const newData = { ...rest, [`title${counter}`]: 0 };
    const last = { ...rows[rows.length - 1] };

    const newRows = [...rows];
    newRows[rows.length - 1] = {
        label: `Title ${counter}`,
        name: `title${counter}`,
        cols: [
            {
                name: 'empty',
                notEditable: true,
            },
            {
                name: `title${counter}`,
                prefix: '$',
            },
        ],
    };
    newRows.push(last);
    return { rows: newRows, ...newData };
};

export const evaluateMathExpression = (expression) => {
    try {
        // eslint-disable-next-line no-eval
        const result = eval(expression);
        if (typeof result === 'number') {
            return result;
        }
    } catch (error) {
        // Ignore errors from invalid expressions
    }
    return null;
};

export const validateInput = (e) => {
    const inputStr = e.target?.value.trim().replaceAll(',', '.') || '';
    if (inputStr === '' || inputStr === '=') return true;
    let input = inputStr;
    let mathExpression;

    const lastSymbol = input[input.length - 1];
    if ('+-*/.'.includes(lastSymbol)) {
        input = input.slice(0, -1);
    }

    if (input.startsWith('=')) {
        mathExpression = input.slice(1);
        // eslint-disable-next-line no-useless-escape
        const regex = /^-?\d+(\.\d+)?([\+\-\*/]-?\d+(\.\d+)?)*$/;
        return regex.test(mathExpression);
    }
    const regex = /^\d*\.?\d*$/;
    return regex.test(input);
};

export const updateSources = (sourcesArr, loanDetails, rates) => {
    const {
        loanAmount = null,
        term = null,
        amortization = null,
        interestRateCap = null,
        indexFloor = null,
        index = null,
        spread = null,
        cashInterestRate = null,
        interestAmortization = null,
    } = loanDetails || {};

    const data = [...sourcesArr];
    const loanItemIndex = data.findIndex((item) => item.type === 'loan');
    const { rows, ...loanItem } = { ...data[loanItemIndex] };
    let resIndex;
    let resRate;
    loanItem.rows = rows.map((item) => {
        if (item.name === 'index' && rates.length && loanItem.index) {
            resIndex = rates.find((rate) => rate.value === item.itemType)?.typeValue || loanItem.index;
            return {
                ...item,
                labelType: rates.find((rate) => rate.typeValue === index)?.value || 'Other',
                labelTypes: rates,
            };
        }
        return item;
    });

    loanItem.index = resIndex;

    if (loanDetails) {
        loanItem.amount = loanAmount;
        loanItem.terms = term;
        loanItem.amortization = amortization;
        loanItem.interestRateCap = interestRateCap;
        loanItem.indexFloor = indexFloor;
        loanItem.spread = spread;
        loanItem.interest = interestAmortization;
        resRate = cashInterestRate || resIndex + spread;
        resRate = resRate > interestRateCap + spread ? interestRateCap + spread : resRate;
        resRate = index < indexFloor ? indexFloor + spread : resRate;
        loanItem.cashInterestRate = resRate;
    } else {
        loanItem.cashInterestRate = resIndex + spread;
    }

    data[loanItemIndex] = loanItem;
    return data;
};

export const updateUses = (loanDetails, usesArray, sourcesArray) => {
    const { originationFee = 0, exitFee = 0 } = loanDetails || {};
    if (!originationFee && !exitFee) return usesArray;

    const loanValue = sourcesArray.find((item) => item.type === 'loan')?.amount || 0;
    const data = [...usesArray];

    const feesItemIndex = data.findIndex((item) => item.type === 'fees');
    let feesItem = { ...data[feesItemIndex] };
    const lenderPointsPercent = originationFee + exitFee || 0;
    feesItem.lenderPointsPercent = lenderPointsPercent;
    const lenderPoints = lenderPointsPercent && loanValue ? (loanValue * (lenderPointsPercent / 100)).toFixed(0) : 0;
    feesItem.lenderPoints = +lenderPoints;
    const { rows, ...restData } = feesItem;
    feesItem = { rows, ...recalculateRows({ rows, data: restData }) };
    data[feesItemIndex] = feesItem;
    return data;
};

export const recalculateModelOnCopy = (modelDetails, loanDetails, rates) => {
    const { sources: sourcesArr, uses: usesArr, analysisAndSizing: modelAnalysisAndSizing, ...rest } = modelDetails;
    const resModel = { ...rest };
    const resSources = recalculateFraction(updateSources(sourcesArr, loanDetails, rates));
    const resUses = recalculateFraction(updateUses(loanDetails, usesArr, resSources));
    const ltv = getLtv(resSources, modelAnalysisAndSizing.assetValue);
    const dscr = getDscr(resSources, modelAnalysisAndSizing.noi);
    const debtYield = getDebtYield(resSources, modelAnalysisAndSizing.noi);

    return {
        ...resModel,
        sources: resSources,
        uses: resUses,
        ltv,
        dscr,
        debtYield,
        analysisAndSizing: modelAnalysisAndSizing,
    };
};

export const recalculateModelOnClone = (modelDetails, loanDetails, rates) => {
    const { sources: sourcesArr, uses: usesArr, analysisAndSizing: modelAnalysisAndSizing, ...rest } = modelDetails;
    const resModel = { ...rest };
    const resSources = recalculateFraction(updateSources(sourcesArr, loanDetails, rates));
    const resUses = recalculateFraction(updateUses(loanDetails, usesArr, resSources));
    const ltv = getLtv(resSources, modelAnalysisAndSizing.assetValue);
    const dscr = getDscr(resSources, modelAnalysisAndSizing.noi);
    const debtYield = getDebtYield(resSources, modelAnalysisAndSizing.noi);
    return {
        ...resModel,
        sources: resSources,
        uses: resUses,
        ltv,
        dscr,
        debtYield,
        analysisAndSizing,
    };
};

export const getBalanceFraction = (sourcesAmountTotal, totalUsesAmount) => {
    if (!sourcesAmountTotal) return 100;
    const res = Math.abs(totalUsesAmount - sourcesAmountTotal) / sourcesAmountTotal;
    if (!res) return '0';
    return (res * 100).toFixed(2);
};

export const prepareUsesHeaders = (isRefinance, headers, isLimited) => {
    let nextHeaders = headers.map((header) => {
        const nextHeader = { ...header };
        if (isLimited && header.editable) {
            nextHeader.headerProps.cellData.isLimited = true;
        }
        return nextHeader;
    });
    if (isRefinance) {
        nextHeaders = nextHeaders.map((header) => {
            const nextHeader = { ...header };
            if (nextHeader.accessor === 'type') {
                const statusesCopy = nextHeader?.headerProps?.cellData?.statuses || [];
                nextHeader.headerProps.cellData.statuses = statusesCopy.map((item) => {
                    if (item.value === 'purchase') {
                        return {
                            ...item,
                            label: 'Loan Prepayment',
                        };
                    }
                    return item;
                });
            }
            return nextHeader;
        });
    }
    return nextHeaders;
};

export const prepareSourcesHeaders = (headers, isLimited) => {
    return headers.map((header) => {
        const nextHeader = { ...header };
        if (isLimited && header.editable) {
            nextHeader.headerProps.cellData.isLimited = true;
        }
        return nextHeader;
    });
};

export const prepareSourcesData = (sourcesData = [], totalUsesAmount, sourcesAmountTotal) => [
    ...sourcesData,
    {
        type: 'Balance',
        isEditable: false,
        name: ' ',
        amount: totalUsesAmount - sourcesAmountTotal,
        fraction: getBalanceFraction(sourcesAmountTotal, totalUsesAmount),
    },
];
