/* eslint-disable no-template-curly-in-string */
import Helmet from 'react-helmet';

const Meta = function ({ title, description, children }) {
    return (
        <Helmet defaultTitle='SCC' titleTemplate='%s | Smart Capital Center'>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta
                name='keywords'
                content={`valuation, commercial real estate, multifamily, cap rates, sales 
                    transactions, property data, rents, loans, commercial real estate loans, multifamily loans, 
                    commercial mortgage, rates`}
            />
            {children}
        </Helmet>
    );
};

export default Meta;
