import { baseApi as api } from '../configs/portfolioAlerts';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRecentPortfolioAlerts: build.query<GetRecentPortfolioAlertsApiResponse, GetRecentPortfolioAlertsApiArg>({
            query: () => ({ url: `/api/v2/home/portfolio-alert/recent` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetRecentPortfolioAlertsApiResponse =
    /** status 200 OK */ GenericJsonResponseListDashboardPortfolioAlertDto;
export type GetRecentPortfolioAlertsApiArg = void;
export type DashboardPortfolioAlertDto = {
    id?: string;
    userId?: number;
    message?: string;
    createdAt?: string;
    read?: boolean;
};
export type GenericJsonResponseListDashboardPortfolioAlertDto = {
    status?: string;
    data?: DashboardPortfolioAlertDto[];
    message?: string;
    code?: string;
};
export const { useGetRecentPortfolioAlertsQuery } = injectedRtkApi;
