import { InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import { InputProps } from '@mui/material/Input';
import Popover from '@mui/material/Popover';
import cx from 'classnames';
import { useState } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import MenuItem from '@ui/MenuItem';
import OutlinedInput from '@ui/OutlinedInput';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

type SelectOption = {
    label: string;
    value: string;
};

type MultiSelectWithApplyProps = {
    value: string[];
    onSuccess: (value: string[]) => void;
    options: SelectOption[];
    inputProps?: InputProps;
};

function MultiSelectWithApply({ value, onSuccess, options, inputProps = {} }: MultiSelectWithApplyProps) {
    const [selected, setSelected] = useState(value ?? []);
    const [open, setOpen] = useState(false);
    const [anchorRef, setAnchor] = useState<HTMLDivElement | null>(null);

    const handleClose = () => {
        setOpen(false);
        setSelected(value);
    };

    const handleApply = () => {
        onSuccess(selected);
        setOpen(false);
    };

    const renderValue = (value: string[]) => {
        return value?.length
            ? value
                  ?.map((item) => {
                      const option = options.find((option) => String(option.value) === String(item));

                      return option?.label || item;
                  })
                  .join(', ')
            : '';
    };

    return (
        <>
            <OutlinedInput
                {...inputProps}
                className={cx(inputProps.className, styles.input, { [styles.inputOpen]: open })}
                endAdornment={
                    <InputAdornment position='end'>
                        <Icon
                            className={cx({
                                [styles.iconOpen]: open,
                            })}
                            color={colors['grey-400']}
                            component={ChevronIcon}
                            fontSize={20}
                        />
                    </InputAdornment>
                }
                size='small'
                value={renderValue(selected)}
                variant='noOutlined'
                readOnly
                onClick={(event) => {
                    setOpen(true);
                    setAnchor(event.currentTarget);
                }}
            />
            <Popover
                anchorEl={anchorRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                sx={{
                    '&& .MuiPopover-paper': {
                        width: anchorRef?.clientWidth,
                    },
                }}
                onClose={handleClose}
            >
                <Box>
                    <Box className={styles.menuList}>
                        {options.map((option) => (
                            <MenuItem
                                key={option.value}
                                className={cx(styles.menuItem, {
                                    [styles.menuItemActive]: selected.includes(option.value),
                                })}
                                onClick={() => {
                                    if (selected.includes(option.value)) {
                                        setSelected(selected.filter((item) => item !== option.value));
                                    } else {
                                        setSelected([...selected, option.value]);
                                    }
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 5,
                        }}
                    >
                        <Button variant='outlined' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button color='primary' variant='contained' onClick={handleApply}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
}

export default MultiSelectWithApply;
