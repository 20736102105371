import Icon from '@ui/Icon';
import SmsSvg from '@assets/icons/outlined/sms.svg';

import PartialSuccessLayout from '@scc/components/PartialSuccessLayout';

const RegisterFormVerification = function({ userEmail }) {
    return (
        <PartialSuccessLayout
            icon={<Icon component={SmsSvg} color='text.disabled' fontSize={50} />}
            description={(
                <>
                    <p>
                        Click the verification button in the email we sent to <b>{userEmail}</b>.
                    </p>
                    <p>This helps keep your account secure.</p>
                    <p>No email in your inbox or spam folder?</p>
                    <p>Let’s resend it</p>
                </>
            )}
        />
    );
};

export default RegisterFormVerification;