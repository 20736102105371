import Link from '@ui/Link';
import Typography from '@ui/Typography';

import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import styles from './similarBuildings.module.scss';

function SimilarBuildingsLink({ item, propertype }) {
    return (
        <Link key={item.address} color='inherit' href={item.publicUrl || item.publicLink}>
            <div className={styles.addressContainer}>
                <PropertyTypeIcon size='small' type={propertype} />
                <Typography color={colors['darkNeutral-500']} variant='mdr'>
                    {item.address}
                </Typography>
            </div>
        </Link>
    );
}

export default SimilarBuildingsLink;
