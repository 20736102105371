import { getBreakpoints } from '@themes/breakpoints';
import colors from '@themes/palette/export.module.scss';

import * as components from './components';
import * as palette from './palette';
import { typography } from './typography';

export const lightTheme = {
    typography,
    spacing: (factor) => `${2 * factor}px`,
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
        values: getBreakpoints(),
        paddings: { xs: 17, sm: 16, md: 24, lg: 40, xl: 40, xxl: 40 },
        unit: 'px',
    },
    shape: {
        borderRadius: 8,
    },
    palette: {
        action: {
            active: colors['blue-700'],
            hover: colors['blue-400'],
            disabled: colors.white,
            disabledBackground: colors['grey-A200'],
        },
        background: {
            default: '#fff',
            paper: '#fff',
        },
        common: {
            black: colors.black,
            white: colors.white,
        },
        grey: {
            natural: colors['grey-500'],
            700: colors['grey-700'],
        },
        text: palette.getDefaultText(),
        primary: palette.getDefaultPrimary(),
        secondary: palette.getDefaultSecondary(),
        info: palette.getDefaultInfo(),
        disabled: palette.getDefaultDisabled(),
        success: palette.getDefaultSuccess(),
        warning: palette.getDefaultWarning(),
        error: palette.getDefaultError(),
    },
    shadows: [
        'none',
        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        'none',
        '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        'none',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
        '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
        'none',
        '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
        '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    zIndex: { drawer: 1200, modal: 1300, tooltip: 1500 },
    components: Object.values(components).reduce((acc, component) => ({ ...acc, ...component() }), {}),
};

export default lightTheme;
