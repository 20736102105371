import MuiIcon, { IconProps } from '@mui/material/Icon';

type TIconProps = Omit<IconProps, 'color' | 'fontSize'> & {
    width?: string | number;
    height?: string | number;
    color?: string;
    stroke?: string;
    fontSize?: string | number;
};

function Icon({
    width,
    height,
    color = 'inherit',
    stroke,
    className,
    component,
    fontSize = 'inherit',
    sx,
}: TIconProps) {
    return (
        <MuiIcon
            className={className}
            component={component as React.ElementType}
            sx={{
                stroke,
                width,
                height,
                color,
                fontSize,
                ...sx,
            }}
        />
    );
}

Icon.AutoSize = (props) => {
    return <Icon {...props} height='auto' width='auto' />;
};

export default Icon;
