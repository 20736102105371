import { customLoanIdFormatter } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Terms/helpers/formatters';
import {
    TCustomLoanIdsMetadata,
    TCustomLoanIdsValues,
    TermsKeys,
    TLoanTerms,
    TLoanTermsKeys,
} from '@scc/store/ducks/loans/types';

import { TLoanTermsDetailsWithDivider, TLoanTermsNode } from '../Terms.types';

import {
    TERMS_BASE_PATH,
    filedToRecalculateIndexValue,
    fieldToRecalculateCoupon,
    fieldToRecalculateCoupon365,
    CouponType,
    RATE_TYPES,
} from './constants';

export const generateTermsPath = (...keys: string[]) => {
    const path = [`/${TERMS_BASE_PATH}`, ...keys];

    return path.join('/');
};

export const getValueByType = (value: string | number, type: string) => {
    if (type === 'number') {
        return Number(value);
    }

    return value;
};

export const getNodeType = (node: TLoanTermsDetailsWithDivider) => {
    return node.kind;
};

export const recalculateCoupon = ({
    fieldName,
    value,
    terms,
}: {
    fieldName: TLoanTermsKeys;
    value: string | number;
    terms: TLoanTerms;
}) => {
    const termsCopy = { ...terms };

    termsCopy[fieldName as string] = value;

    const indexValue = parseFloat(termsCopy.indexValue as string) || 0;
    const spread = parseFloat(termsCopy.spread as string) || 0;
    const termSoftAdjustment = parseFloat(termsCopy.termSoftAdjustment as string) || 0;
    const indexFloor = parseFloat(termsCopy.indexFloor as string) || 0;

    return spread + Math.max(indexValue + termSoftAdjustment, indexFloor);
};

export const recalculateCoupon365 = (coupon: number) => {
    return coupon * (365 / 360);
};

type TGetValuesToUpdate = {
    fieldName: TLoanTermsKeys;
    value: string | number;
    rates: Record<string, unknown>[];
    terms: TLoanTerms;
};

type TGetValuesToUpdateReturn = {
    [key in TLoanTermsKeys]: {
        name: TLoanTermsKeys;
        value: string | number;
        path: string;
        type: string;
    };
};

export const getValueToUpdate = ({ fieldName, value, terms, rates }: TGetValuesToUpdate): TGetValuesToUpdateReturn => {
    const fieldsToUpdate = {} as TGetValuesToUpdateReturn;

    if (filedToRecalculateIndexValue[fieldName]) {
        const { typeValue } = rates.find((rate) => rate.value === value) || {};

        fieldsToUpdate[TermsKeys.INDEX_VALUE] = {
            name: TermsKeys.INDEX_VALUE,
            value: typeValue as number,
            path: generateTermsPath(TermsKeys.INDEX_VALUE),
            type: 'number',
        };
    }

    if (fieldToRecalculateCoupon[fieldName]) {
        if (fieldName === TermsKeys.COUPON_TYPE && value === CouponType.FIXED) {
            return fieldsToUpdate;
        }

        const isIndexValueChanged = fieldsToUpdate[TermsKeys.INDEX_VALUE];

        const coupon = recalculateCoupon({
            fieldName,
            value,
            terms: isIndexValueChanged ? { ...terms, indexValue: isIndexValueChanged.value } : terms,
        });

        fieldsToUpdate[TermsKeys.COUPON] = {
            name: TermsKeys.COUPON,
            value: coupon,
            path: generateTermsPath(TermsKeys.COUPON, 'value'),
            type: 'number',
        };

        const coupon365 = recalculateCoupon365(coupon);

        fieldsToUpdate[TermsKeys.COUPON_365] = {
            name: TermsKeys.COUPON_365,
            value: coupon365,
            path: generateTermsPath(TermsKeys.COUPON, TermsKeys.COUPON_365),
            type: 'number',
        };
    }

    if (fieldToRecalculateCoupon365[fieldName]) {
        const coupon365 = recalculateCoupon365(parseFloat(value as string));

        fieldsToUpdate[TermsKeys.COUPON_365] = {
            name: TermsKeys.COUPON_365,
            value: coupon365,
            path: generateTermsPath(TermsKeys.COUPON, TermsKeys.COUPON_365),
            type: 'number',
        };

        fieldsToUpdate[TermsKeys.COUPON_TYPE] = {
            name: TermsKeys.COUPON_TYPE,
            value: CouponType.FIXED,
            path: generateTermsPath(TermsKeys.COUPON_TYPE),
            type: 'number',
        };
    }

    return fieldsToUpdate;
};

export const getIoAmortization = (ioAmortization: string) => {
    if (Array.isArray(ioAmortization)) {
        return ioAmortization;
    }

    return ioAmortization ? [ioAmortization] : [];
};

export const getNodesFromLoanCustomIds = ({
    loanIdsMetadata,
    loanIdValues,
}: {
    loanIdsMetadata: TCustomLoanIdsMetadata;
    loanIdValues: TCustomLoanIdsValues;
}): TLoanTermsNode[] => {
    return Object.entries(loanIdsMetadata).map(
        ([, { value, id }]) =>
            ({
                id,
                kind: 'node' as const,
                title: customLoanIdFormatter(value),
                value: loanIdValues?.[id]?.value ?? '',
                path: generateTermsPath(TermsKeys.LOAN_ID_VALUES),
                name: TermsKeys.LOAN_ID_VALUES,
                customLoanId: id,
            } satisfies TLoanTermsNode)
    );
};

export const generateUpdateCustomLoanInPayload = ({
    terms,
    customLoanId,
    value,
}: {
    terms: TLoanTerms;
    customLoanId: string;
    value: string;
}): { [TermsKeys.LOAN_ID_VALUES]: TCustomLoanIdsValues } => {
    return {
        [TermsKeys.LOAN_ID_VALUES]: {
            ...(terms[TermsKeys.LOAN_ID_VALUES] ?? {}),
            [customLoanId]: {
                ...(terms[TermsKeys.LOAN_ID_VALUES]?.[customLoanId] ?? {}),
                value,
            },
        },
    };
};

export const genereateUpdateStorePayload = ({
    name,
    value,
    terms,
    type,
    customLoanId,
}: {
    name: string;
    value: string;
    terms: TLoanTerms;
    type: string;
    customLoanId?: string;
}) => {
    if (name === TermsKeys.LOAN_ID_VALUES) {
        return generateUpdateCustomLoanInPayload({
            terms,
            customLoanId: customLoanId as string,
            value,
        });
    }

    return {
        [name]: getValueByType(value, type),
    };
};

export const getNewRateByType = ({ value, newRate }: { value: string; newRate: Record<string, unknown> }) => {
    const type = RATE_TYPES[value] || RATE_TYPES.default;

    if (type === RATE_TYPES.default) {
        return newRate[value.toUpperCase()];
    }

    return newRate;
};
