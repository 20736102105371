import request from '@utils/request';

export const BASE_URL = '/api/v2/notes';

export const PROPERTY = 'PROPERTY';
export const INSIGHTS = 'INSIGHTS';
export const PROPERTY_INSIGHTS = 'PROPERTY_INSIGHTS';

export async function getNotes({ countyId, parcelId, type }) {
    return request(`${BASE_URL}`, {
        params: {
            countyId,
            parcelId,
            type,
        },
    });
}

export async function saveNote(body) {
    return request(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function removeNote() {
    return request(`${BASE_URL}/`, {
        method: 'DELETE',
    });
}
