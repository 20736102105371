import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { loansApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[loansPortfolios]';

const initialState = {
    data: {},
    loaded: false,
    loading: false,
};

const portfoliosSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        update() {},
    },
});

export const { load: loadPortfolios, setData: setPortfolios, update: updatePortfolio } = portfoliosSlice.actions;

export default portfoliosSlice.reducer;

export const selectPortfoliosState = (store) => store.loans.portfolios;

function* loadPortfoliosSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.getPortfolios, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(setPortfolios(action.payload.data));
}

function* updatePortfolioSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.updateLoanPortfolio, type));
    yield take(`${type} ${API_SUCCESS}`);

    yield put(loadPortfolios());
}

export function* watchPortfolios() {
    yield takeLatest(loadPortfolios, loadPortfoliosSaga);
    yield takeLatest(updatePortfolio, updatePortfolioSaga);
}
