import colors from '@themes/palette/export.module.scss';

export const text = {
    color: colors['darkNeutral-500'],
    '&:hover': {
        backgroundColor: colors['primary-hover'],
    },
    '&:active': {
        color: '#090B1B',
        backgroundColor: colors['primary-selected'],
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const textSecondary = {
    color: colors['darkNeutral-500'],
    '&:hover': {
        backgroundColor: colors['secondary-hover'],
    },
    '&:active': {
        color: '#090B1B',
        backgroundColor: colors['secondary-selected'],
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const textInherit = {
    color: 'inherit',
    '&:hover': {
        backgroundColor: colors['lightGrey-200'],
    },
    '&:active': {
        color: '#090B1B',
        backgroundColor: colors['lightGrey-500'],
    },

    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const textSuccess = {
    color: colors['darkNeutral-500'],

    '&:hover': {
        backgroundColor: colors['success-hover'],
    },
    '&:active': {
        color: '#090B1B',
        backgroundColor: colors['success-dark'],
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const textWarning = {
    color: colors['darkNeutral-500'],

    '&:hover': {
        backgroundColor: colors['warning-hover'],
    },
    '&:active': {
        color: '#090B1B',
        backgroundColor: colors['warning-selected'],
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const textError = {
    color: colors['darkNeutral-500'],

    '&:hover': {
        backgroundColor: colors['error-hover'],
    },
    '&:active': {
        color: '#090B1B',
        backgroundColor: colors['error-selected'],
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};
