import { combineReducers } from 'redux';

import membersReducer, { watchMembers } from './members';
import memberReducer, { watchMember } from './member';
import memberPropertiesReducer, { watchMemberProperties } from './memberProperties';
import memberDocumentsReducer, { watchMemberDocuments } from './memberDocuments';

export const membersWatchers = [
    watchMembers,
    watchMember,
    watchMemberProperties,
    watchMemberDocuments,
];

export default combineReducers({
    index: membersReducer,
    member: memberReducer,
    properties: memberPropertiesReducer,
    documents: memberDocumentsReducer,
});
