import { Box, InputAdornment, Divider, Stack, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';

import { loginAuth, selectAuthState, selectIsUserAuth, verificationPhoneAuth } from '@scc/store/ducks/auth';

import Button from '@ui/Button';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Link from '@ui/Link';
import Typography from '@ui/Typography';

import LoginSocialNetworkButton from '@components/SocialNetworkButton';

import colors from '@themes/palette/export.module.scss';

import EyeSlashSvg from '@assets/icons/outlined/eye-slash.svg';
import EyeSvg from '@assets/icons/outlined/eye.svg';
import InfoIcon from '@assets/icons/outlined/info-circle.svg';
import { HTTP_STATUSES } from '@constants/httpStatuses';

import styles from './styles.module.scss';
import schema from './validationSchema';

const SUPPORT_EMAIL = typeof process !== 'undefined' && process.env.SUPPORT_EMAIL;

function LoginForm({ onStepChange, onSuccess }) {
    const {
        register,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const { error: authError, loading } = useSelector(selectAuthState);
    const isLoggedIn = useSelector(selectIsUserAuth);

    const [showPassword, setShowPassword] = useState(false);

    const isBlocked = authError?.status === HTTP_STATUSES.FORBIDDEN;

    useEffect(() => {
        if (authError) {
            setError('email', {
                type: 'manual',
                message: authError.message,
            });
        } else if (isLoggedIn) {
            reset();
            if (onSuccess) {
                onSuccess();
            }
        } else {
            clearErrors();
        }
    }, [authError, isLoggedIn]);

    const onSubmit = async (data) =>
        dispatch(
            loginAuth({
                meta: { callback: () => dispatch(verificationPhoneAuth()) },
                ...data,
            })
        );

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Stack className={styles.formWrapper} direction='column' justifyContent='space-between' sx={{ height: 1 }}>
            <Box className={styles.form} component='form' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    label='Email'
                    margin='normal'
                    variant='standard'
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <Input
                    label='Password'
                    margin='normal'
                    variant='standard'
                    fullWidth
                    {...register('password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    edge='end'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <Icon color={colors['grey-600']} component={EyeSlashSvg} fontSize='17px' />
                                    ) : (
                                        <Icon color={colors['grey-600']} component={EyeSvg} fontSize='17px' />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    type={showPassword ? 'text' : 'password'}
                />

                {!isBlocked && (
                    <>
                        <div className={styles.forgotPasswordWrapper}>
                            <Link.Secondary
                                size='small'
                                onClick={() => {
                                    onStepChange?.('forgotPassword');
                                }}
                            >
                                <Typography className={styles.forgotPassword} component='span' variant='smr'>
                                    Forgot password?
                                </Typography>
                            </Link.Secondary>
                        </div>

                        <Button
                            color='primary'
                            loading={loading}
                            size='extra-large'
                            sx={{ marginTop: '66px' }}
                            type='submit'
                            fullWidth
                        >
                            Login
                        </Button>
                        <div>
                            <Divider sx={{ margin: '84px 0 34px 0' }}>or</Divider>
                            <Grid
                                alignItems='center'
                                direction='row'
                                justifyContent='center'
                                spacing={3}
                                sx={{ marginBottom: '65px' }}
                                container
                            >
                                <Grid xs='auto' item>
                                    <LoginSocialNetworkButton
                                        size='extra-large'
                                        type='google'
                                        variant='outlined'
                                        fullWidth
                                        logoOnly
                                    />
                                </Grid>
                                <Grid xs='auto' item>
                                    <LoginSocialNetworkButton
                                        size='extra-large'
                                        type='facebook'
                                        variant='outlined'
                                        logoOnly
                                    />
                                </Grid>
                                <Grid xs='auto' item>
                                    <LoginSocialNetworkButton
                                        size='extra-large'
                                        type='linkedin'
                                        variant='outlined'
                                        logoOnly
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Typography color={colors['grey-400']} sx={{ textAlign: 'center' }} variant='smr'>
                            Dont have account?{' '}
                            <Link.Secondary
                                size='small'
                                onClick={() => {
                                    onStepChange?.('register');
                                }}
                            >
                                <Typography color={colors['grey-400']} component='span' variant='smr'>
                                    Sign up
                                </Typography>
                            </Link.Secondary>
                        </Typography>
                    </>
                )}

                {isBlocked && (
                    <div className={styles.blockedInfoWrapper}>
                        <div className={styles.blockedHeader}>
                            <Icon color={colors.error} component={InfoIcon} fontSize='22px' />
                            <Typography color={colors.error} variant='h6sb'>
                                Account has been locked (for 5 minutes)
                            </Typography>
                        </div>
                        <div className={styles.blockedContent}>
                            <Typography color={colors['darkNeutral-500']} variant='h6r'>
                                We locked your account due to multiple attempts to login with an incorrect password.
                            </Typography>
                            <Typography color={colors['darkNeutral-500']} variant='h6r'>
                                Please try to login or re-set your password in 5 minutes. If you experience any issues,
                                please feel free to email us at
                                <Link className={styles.email} href={`mailto:${SUPPORT_EMAIL}`}>
                                    {SUPPORT_EMAIL}
                                </Link>
                            </Typography>
                        </div>
                    </div>
                )}

                <Typography className={styles.copyright} color='#dbdbdb' variant='smr'>
                    © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                </Typography>
            </Box>
        </Stack>
    );
}

export default LoginForm;
