import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Accordion from '@ui/Accordion';
import AccordionSummary from '@ui/AccordionSummary';
import Typography from '@ui/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ItemList from '@components/ItemList';

const PricingFaq = function ({ questions }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [expanded, setExpanded] = useState('faq1');

    const handleChange = (panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

    const getFaqIcon = (name) => (
        <Typography variant='h4' component='span'>
            {expanded === name ? '-' : '+'}
        </Typography>
    );

    return (
        <>
            <Typography variant={isMobile ? 'h1b' : 'head2b'} component='p' gutterBottom>
                Frequently Asked Questions
            </Typography>
            <ItemList items={questions}>
                {({ name, title, description }) => (
                    <Accordion
                        key={name}
                        disableGutters
                        expanded={expanded === name}
                        onChange={handleChange(name)}
                        variant='none'
                    >
                        <AccordionSummary
                            id={`${name}-header`}
                            aria-controls={`${name}-content`}
                            expandIcon={getFaqIcon(name)}
                        >
                            <Typography variant={isMobile ? 'h4b' : 'h2b'} component='h2'>
                                {title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='lgr' gutterBottom>{description}</Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </ItemList>
        </>
    );
};

export default PricingFaq;
