import ItemList from '@components/ItemList';
import HighlightItem from './../HighlightItem';

const HighlightList = ({ list }) => (
  <ItemList items={list}>
    {(item) => {
      const {
        messageInfo,
        marker,
        options,
        outOf
      } = item;
      return (
        <HighlightItem
          key={messageInfo}
          text={messageInfo}
          type={marker}
          chips={options}
          outOf={outOf}
        />
      );
    }}
  </ItemList>
);

export default HighlightList;
