import { createSlice } from '@reduxjs/toolkit';
import { put, all, call, delay, takeLatest } from 'redux-saga/effects';

import { loadMemberDocuments } from '@scc/store/ducks/members/memberDocuments';

import { documentsApi } from '@api';
import { UPLOAD_MEMBER_DOCUMENTS_STEPS } from '@constants/modals';
import { uploadDocumentsForm } from '@shared/templates/forms/UploadDocuments';
import { showPrimaryAsideDrawer, hideDrawer } from '@store/ducks/ui/drawer';

const entity = '[sh:flowMember]';

const { MAIN, LOADING, PREPARE, SUCCESS, ERROR } = uploadDocumentsForm.TEMPLATES;

const initialState = {
    loading: false,
    loaded: false,
    template: '',
};

const memberFLowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        upload(state) {
            state.template = MAIN;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
        add() {},
        uploadDocuments() {},
    },
});

export const {
    upload: uploadMemberDocuments,
    setTemplate: setTemplateMemberDocuments,
    add: addMemberDocumentsFlow,
    uploadDocuments: uploadMemberDocumentsFlow,
} = memberFLowSlice.actions;

export default memberFLowSlice.reducer;

export const selectMemberFlowState = (store) => store.flow.member;

function* uploadMemberDocumentsSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: UPLOAD_MEMBER_DOCUMENTS_STEPS,
            data: payload,
        })
    );
}

function* addMemberDocumentsFlowSaga({ payload }) {
    const { selectDocs, memberId } = payload;
    yield put(setTemplateMemberDocuments(LOADING));
    yield all(selectDocs.map((documentId) => call(documentsApi.addToMemberDocument, { documentId, memberId })));
    yield put(setTemplateMemberDocuments(SUCCESS));
    yield delay(3000);
    yield put(hideDrawer());
}

function* uploadMemberDocumentsFlowSaga({ payload }) {
    const { prepareDocs, memberId, editMode } = payload;
    yield put(setTemplateMemberDocuments(LOADING));

    const method = editMode ? documentsApi.updateDocument : documentsApi.uploadDocumentsToMember;

    const responses = yield all(
        Object.values(prepareDocs).map(({ id, name, start, end, type, date, file }) =>
            call(method, {
                id,
                memberId,
                name,
                type,
                date,
                pages: `${start}-${end}`,
                file,
            })
        )
    );

    if (responses.some((res) => res.error)) {
        yield put(setTemplateMemberDocuments(ERROR));
        yield delay(2300);
        yield put(setTemplateMemberDocuments(PREPARE));
        yield put(loadMemberDocuments(memberId));
    } else {
        yield put(setTemplateMemberDocuments(SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
        yield put(loadMemberDocuments(memberId));
    }
}

export function* watchMemberFLow() {
    yield takeLatest(uploadMemberDocuments, uploadMemberDocumentsSaga);
    yield takeLatest(addMemberDocumentsFlow, addMemberDocumentsFlowSaga);
    yield takeLatest(uploadMemberDocumentsFlow, uploadMemberDocumentsFlowSaga);
}
