import { dark, grey } from '@themes/colors';
import colors from '@themes/palette/export.module.scss';

const list = () => ({
    MuiListItemButton: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                borderRadius: '4px',
                color: grey[400],
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&.Mui-selected': {
                    color: dark[700],
                    backgroundColor: colors['grey-A100'],
                },
            },
        },
        variants: [
            {
                props: { variant: 'outlined' },
                style: {
                    '&.Mui-selected': {
                        outlineColor: colors['blue-500'],
                        outlineWidth: 1,
                        outlineStyle: 'solid',
                        outlineOffset: -1,
                    },
                },
            },
        ],
    },
    MuiListItemAvatar: {
        styleOverrides: {
            root: {
                minWidth: '32px',
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: '1.7rem',
            },
        },
    },
    MuiListItemText: {
        styleOverrides: {
            root: {
                borderRadius: '4px',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&.Mui-selected': {
                    color: dark[700],
                },
            },
        },
    },
});

export default list;
