import AiChatIcon from '@icons/outlined/ai-chat.svg';
import DocumentIcon from '@icons/outlined/document-1.svg';

export const TAB_ENUM = {
    DOCUMENT: 'documentTab',
    CHAT: 'chatTab',
} as const;

export const TABS = [
    {
        name: TAB_ENUM.DOCUMENT,
        label: 'Document',
        icon: DocumentIcon,
    },
    {
        name: TAB_ENUM.CHAT,
        label: 'Chat',
        icon: AiChatIcon,
    },
] as const;
