import colors from '@themes/palette/export.module.scss';

const Stepper = () => ({
    MuiMobileStepper: {
        styleOverrides: {
            progress: {
                width: '100%',
            },
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                color: colors['grey-100'],
                '&.Mui-active': {
                    color: colors['blue-100'],
                    '.MuiStepIcon-text': {
                        fill: colors['blue-500'],
                    },
                },
                text: {
                    fill: colors['grey-400'],
                },
            },
        },
    },
    MuiStepLabel: {
        styleOverrides: {
            label: {
                color: colors['grey-400'],
                '&.Mui-active': {
                    color: colors['darkNeutral-500'],
                },
            },
        },
    },
});

export default Stepper;
