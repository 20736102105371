export const GOOGLE_MAPS_API_SCRIPT_NAME = 'googleMapsApi';

export const GOOGLE_MAPS_API_SCRIPT_ID = 'googleMapsApiScript';

export const GOOGLE_API_ORIGIN = 'https://maps.googleapis.com';

export const GOOGLE_API_JS_PATH = '/maps/api/js';

export const GOOGLE_API_STREETVIEW_PATH = '/maps/api/streetview';

export const GOOGLE_API_STATICMAP_PATH = '/maps/api/staticmap';

export const MAPBOX_API_ORIGIN = 'https://api.mapbox.com';

export const MAPBOX_API_STATIC_IMAGE_PATH = '/styles/v1/mapbox';
