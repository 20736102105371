import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { portfolioApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[propertiesPortfolios]';

const initialState = {
    data: {},
    loaded: false,
    loading: false,
};

const portfoliosSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        updateName(state, action) {
            const { id, name } = action.payload;
            state.data = state.data.map((item) => {
                return item.id === id ? { ...item, name } : item;
            });
        },
    },
});

export const {
    load: loadPortfolios,
    setData: setPortfolios,
    updateName: updatePortfolioItemName,
} = portfoliosSlice.actions;

export default portfoliosSlice.reducer;

export const selectPortfoliosState = (store) => store.properties.portfolios;

function* loadPortfoliosSaga({ type, payload }) {
    yield put(apiRequest(payload, portfolioApi.getPortfolios, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(setPortfolios(action.payload.data));
}

export function* watchPortfolios() {
    yield takeLatest(loadPortfolios, loadPortfoliosSaga);
}
