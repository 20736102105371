import { createSlice, createSelector } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { formatter } from '@utils/index';

import { selectUnderwritingState, editUnderwriting } from '@scc/store/ducks/loans/underwriting';
import {
    selectFinancingScenariosState,
    editFinancingScenarios,
} from '@scc/store/ducks/underwriting/financingScenarios';

import { loanRatesApi, underwritingApi } from '@api';
import { transformRates } from '@modules/FinancialModel/helpers/helpers';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[financials]';

const initialState = {
    data: {},
    fullData: {},
    params: {},
    properties: [],
    loaded: false,
    loading: false,
    scenario: '',
    ratesByDate: {
        date: '',
        rates: [],
    },
    isRatesLoaded: false,
    isRatesLoading: false,
};

const financialsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            state.loaded = false;
            state.loading = true;
            state.scenario = action.payload.scenario;
        },
        setData(state, action) {
            state.data = action.payload.financials;
            state.scenario = action.payload.scenario;
            state.fullData = action.payload;
            state.properties = action.payload.loanPropertyList || action.payload.propertyList || [];
            state.loaded = true;
            state.loading = false;
        },
        resetLoader(state) {
            state.loaded = false;
            state.loading = true;
        },
        setScenario(state, action) {
            state.scenario = action.payload;
        },
        edit() {},
        setRatesByDate(state, action) {
            state.isRatesLoaded = true;
            state.isRatesLoading = false;
            state.ratesByDate = action.payload;
        },
        loadRatesByDate(state) {
            state.isRatesLoaded = false;
            state.isRatesLoading = true;
        },
        reset: () => initialState,
    },
});

export const {
    load: loadFinancials,
    setData: setFinancials,
    setScenario: setScenarioFinancials,
    edit: editFinancials,
    resetLoader: resetLoaderFinancials,
    setRatesByDate: setRatesByDateFinancials,
    loadRatesByDate: loadRatesByDateFinancials,
    reset: resetFinancials,
} = financialsSlice.actions;

export default financialsSlice.reducer;

export const selectFinancialsState = (store) => store.underwriting.financing;

export const selectCustomScenariosFinancialsState = createSelector(
    selectFinancialsState,
    ({ data: { proforma } = {} }) => (proforma ? Object.keys(proforma) : [])
);

export const selectFinancialsRowsState = createSelector(
    selectFinancialsState,
    ({ data: { rows = [], ...data } = {}, scenario }) => {
        const allScenarios = data?.proforma?.[scenario]?.rows || [];

        return rows?.reduce(
            (acc, { ...row }) => {
                const currentScenarioIndex = allScenarios.findIndex((sc) => sc.type === row.type);
                const nextRow = { ...row };

                if (currentScenarioIndex >= 0) {
                    [nextRow.proforma, nextRow.proformaSf] = allScenarios[currentScenarioIndex]?.values || [
                        { value: '', unit: '' },
                        { value: '', unit: '' },
                    ];
                }

                acc.push(nextRow);

                if (row.type === 'TOTAL_REVENUE') {
                    acc.push({ values: [], type: 'EXPENSES' });
                }

                return acc;
            },
            [{ values: [], type: 'REVENUE' }]
        );
    }
);

export const selectFinancialsTotalsState = createSelector(selectFinancialsRowsState, (rows) =>
    rows.reduce(
        (acc, curr) => {
            // eslint-disable-next-line default-case
            switch (curr.type) {
                case 'TOTAL_REVENUE':
                    acc.revenue = formatter.currency(curr?.proforma?.value || 0, {
                        maximumFractionDigits: 0,
                    });
                    break;
                case 'TOTAL_NOI':
                    acc.noi = formatter.currency(curr?.proforma?.value || 0, {
                        maximumFractionDigits: 0,
                    });
                    break;
            }

            return acc;
        },
        {
            revenue: 0,
            noi: 0,
        }
    )
);

// eslint-disable-next-line consistent-return
export const selectFinancialsModelById = (id, { countyId, parcelId, loanId }) => {
    if (countyId && parcelId) {
        return createSelector(selectFinancingScenariosState, (state) => ({
            model: state.data.filter((item) => item.id === id)[0] || {},
            loaded: state.loaded,
        }));
    }
    if (loanId) {
        return createSelector(selectUnderwritingState, (state) => ({
            model: state.data.filter((item) => item.id === id)[0] || {},
            loaded: state.loaded,
        }));
    }
};

function* loadFinancialsSaga({ type, payload: { countyId, parcelId, loanId } }) {
    if (countyId && parcelId) {
        yield put(apiRequest({ countyId, parcelId }, underwritingApi.getPropertyFinancials, type));
    }
    if (loanId) {
        yield put(apiRequest({ loanId }, underwritingApi.getFinancials, type));
    }
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setFinancials(action.payload.data));
}

function* editFinancialsSaga({
    payload: {
        payload: { countyId, parcelId, loanDetailsId },
        ...payload
    },
}) {
    if (countyId && parcelId) {
        yield put(
            editFinancingScenarios({
                countyId,
                parcelId,
                ...payload,
            })
        );
    }
    if (loanDetailsId) {
        yield put(
            editUnderwriting({
                loanDetailsId,
                ...payload,
            })
        );
    }
}

function* loadRatesByDateSaga({ type, payload: { rateDate, countyId, parcelId, loanId } }) {
    const isUnderwritingProperty = countyId && parcelId;

    const service = isUnderwritingProperty ? underwritingApi.getRates : loanRatesApi.getRates;
    const payload = isUnderwritingProperty ? { rateDate } : { loanId, rateDate };

    yield put(apiRequest(payload, service, type));

    const action = yield take(`${type} ${API_SUCCESS}`);

    const rates = transformRates(action.payload.data);

    yield put(setRatesByDateFinancials(rates));
}

export function* watchFinancials() {
    yield takeLatest(loadFinancials, loadFinancialsSaga);
    yield takeLatest(editFinancials, editFinancialsSaga);
    yield takeLatest(loadRatesByDateFinancials, loadRatesByDateSaga);
}
