import FacebookIcon from '@scc/assets/icons/facebook.svg';
import LinkedInIcon from '@scc/assets/icons/linked-in.svg';
import TwitterIcon from '@scc/assets/icons/twitter.svg';

import MessagesIcon from '@icons/outlined/messages-2.svg';

export type TRoute = {
    name: string;
    path: string;
    label?: string;
    based?: string;
    displayLabel?: string;
    exact?: boolean;
    routes?: Record<string, TRoute>;
    external?: boolean;
    target?: string;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    title?: string;
};

const pages = {
    home: {
        name: 'home',
        label: 'Home',
        path: '/',
        exact: true,
    },
    dashboard: {
        name: 'dashboard',
        label: 'Home',
        path: '/dashboard',
    },
    enterprise: {
        name: 'enterprise',
        label: 'Enterprise',
        path: '/enterprise',
    },
    requestLoan: {
        name: 'requestLoan',
        label: 'Request Loan',
        path: '/request-loan',
    },
    search: {
        name: 'search',
        label: 'Home',
        path: '/',
        exact: true,
    },
    myProperties: {
        name: 'myProperties',
        label: 'My Orders',
        path: '/orders',
    },
    bookmarks: {
        name: 'bookmarks',
        label: 'Wishlist',
        path: '/bookmarks',
    },
    history: {
        name: 'history',
        label: 'History',
        path: '/history',
    },
    history2: {
        name: 'history2',
        label: 'History',
        path: '/history2',
        routes: {
            properties: {
                name: 'properties',
                label: 'Properties',
                path: '/history2',
            },
            loans: {
                name: 'loans',
                label: 'Loans',
                path: '/history2/loans',
            },
        },
    },
    propertySSR: {
        name: 'Property',
        path: '/property',
    },
    property: {
        name: 'Property',
        path: '/property/:type/:address',
    },
    properties: {
        name: 'properties',
        label: 'Properties',
        path: '/properties',
        routes: {
            properties: {
                name: 'properties',
                label: 'Properties',
                path: '/properties',
            },
            portfolios: {
                name: 'portfolios',
                label: 'Portfolios',
                displayLabel: 'Portfolios',
                path: '/properties/portfolios',
                routes: {
                    combinedPortfolios: {
                        name: 'portfolios',
                        label: 'Portfolios Old',
                        displayLabel: 'Portfolios Old',
                        path: '/properties/portfolios/combined/:portfolioId',
                    },
                    portfolio: {
                        name: 'portfolio',
                        label: 'Portfolio',
                        displayLabel: 'Portfolio',
                        path: '/properties/portfolios/:portfolioId',
                    },
                },
            },
            tenants: {
                name: 'tenants',
                label: 'Tenants',
                displayLabel: 'Properties',
                path: '/properties/tenants',
            },
        },
    },
    services: {
        name: 'services',
        label: 'Services',
        path: '/services',
        routes: {
            services: {
                name: 'services',
                label: 'Services',
                path: '/services',
            },
            financing: {
                name: 'financing',
                label: 'Low-Cost Financing',
                path: '/services/fast-financing',
            },
            valuation: {
                name: 'valuation',
                label: 'Ultra-Fast Deal Underwriting',
                path: '/services/independent-property-valuation',
            },
            monitoring: {
                name: 'monitoring',
                label: 'Portfolio Management',
                path: '/services/free-portfolio-monitoring',
            },
        },
    },
    pricing: {
        name: 'pricing',
        label: 'Pricing',
        path: '/pricing',
    },
    aboutUs: {
        name: 'aboutUs',
        label: 'About Us',
        path: '/team',
    },
    news: {
        name: 'news',
        label: 'News',
        path: 'https://www.smartcapital.center/news',
        external: true,
        target: '_blank',
    },
    terms: {
        name: 'terms',
        label: 'Terms of Use',
        path: '/tou',
    },
    policy: {
        name: 'policy',
        label: 'Privacy Policy',
        path: '/privacy',
    },
    siteMap: {
        name: 'sitemap',
        label: 'Sitemap',
        path: '/cap-rates-and-loans',
        external: true, // need to reload page
    },
    login: {
        name: 'login',
        label: 'Log In',
        path: '/login',
    },
    ssoTimeout: {
        name: 'ssoTimeout',
        label: 'SSO Timeout',
        path: '/sso-timeout',
    },
    loginAsUser: {
        name: 'loginAsUser',
        label: 'Log In as user',
        path: '/login-as-user',
    },
    register: {
        name: 'register',
        label: 'Sign Up',
        path: '/register',
    },
    requestDemo: {
        name: 'requestDemo',
        label: 'Request Demo',
        path: '/requestDemo',
    },
    forgotPassword: {
        name: 'forgotPassword',
        label: 'Reset Your Password',
        path: '/forgot-password',
    },
    confirmPassword: {
        name: 'confirmPassword',
        label: 'Reset Your Password',
        path: '/confirm-password',
    },
    inviteUser: {
        name: 'inviteUser',
        label: 'Set Up Your Account',
        path: '/invite-user',
    },
    account: {
        name: 'account',
        label: 'Account',
        path: '/profile/account',
    },
    password: {
        name: 'password',
        label: 'Password',
        path: '/profile/password',
    },
    payment: {
        name: 'payment',
        label: 'Payment Method',
        path: '/profile/payment-method',
    },
    portfolios: {
        name: 'portfolios',
        label: 'Properties',
        path: '/portfolio',
        exact: true,
    },
    portfolio: {
        name: 'portfolio',
        label: 'Portfolio',
        path: '/portfolio/detailed',
        based: '/portfolio/',
        routes: {
            overview: {
                name: 'overview',
                label: 'Overview',
                path: '/portfolio/detailed',
            },
            analytics: {
                name: 'analytics',
                label: 'Analytics',
                path: '/portfolio/detailed/analytics',
            },
            insight: {
                name: 'insight',
                label: 'Insight',
                path: '/portfolio/detailed/insight',
            },
            rentRoll: {
                name: 'rentRoll',
                label: 'Rent roll',
                path: '/portfolio/detailed/rentRoll',
            },
            financials: {
                name: 'financials',
                label: 'Financials',
                path: '/portfolio/detailed/financials',
            },
            experimentalRentRoll: {
                name: 'experimentalRentRoll',
                label: 'Exp Rent roll',
                path: '/portfolio/detailed/experimentalRentRoll',
            },
            experimentalFinancials: {
                name: 'experimentalFinancials',
                label: 'Financials(Beta)',
                path: '/portfolio/detailed/experimentalFinancials',
            },
            developmentBudget: {
                name: 'developmentBudget',
                label: 'Development Budget',
                path: '/portfolio/detailed/developmentBudget',
            },
            experimentalProforma: {
                name: 'experimentalProforma',
                label: 'Proforma',
                path: '/portfolio/detailed/experimentalProforma',
            },
            proforma: {
                name: 'proforma',
                label: 'Proforma',
                path: '/portfolio/detailed/proforma',
            },
            proformaGrid: {
                name: 'proformaGrid',
                label: 'Old Proforma',
                path: '/portfolio/detailed/proformaGrid',
            },
            underwriting: {
                name: 'underwriting',
                label: 'Underwriting',
                path: '/portfolio/detailed/underwriting',
            },
            market: {
                name: 'market',
                label: 'Market',
                path: '/portfolio/detailed/market',
            },
            documents: {
                name: 'documents',
                label: 'Documents',
                path: '/portfolio/detailed/documents',
            },
            financing: {
                name: 'financing',
                label: 'Financing',
                path: '/portfolio/detailed/financing',
            },
            propertyHistory: {
                name: 'propertyHistory',
                label: 'Property History',
                path: '/portfolio/detailed/propertyHistory',
            },
            notes: {
                name: 'notes',
                label: 'Notes',
                path: '/portfolio/detailed/notes',
            },
            admin: {
                name: 'admin',
                label: 'admin',
                path: '/portfolio/detailed/admin',
            },
        },
    },
    listings: {
        name: 'listings',
        label: 'Listings',
        path: '/listings',
    },
    draw: {
        name: 'draw',
        label: 'Draw',
        path: '/loans/:loanId/draws/:drawId',
        routes: {
            summary: {
                name: 'summary',
                label: 'Summary',
                path: '/loans/:loanId/draws/:drawId',
                exact: true,
            },
            documents: {
                name: 'documents',
                label: 'Documents',
                path: '/loans/:loanId/draws/:drawId/documents',
            },
            fundingSource: {
                name: 'fundingsSources',
                label: 'Funding Sources',
                path: '/loans/:loanId/draws/:drawId/funding-sources',
            },
            notes: {
                name: 'notes',
                label: 'Notes',
                path: '/loans/:loanId/draws/:drawId/notes',
            },
        },
    },
    financings: {
        name: 'financings',
        label: 'Financing',
        path: '/financings',
        exact: true,
    },
    financing: {
        name: 'financing',
        label: 'Financing',
        path: '/financings/:loanId',
        based: '/financings/',
        routes: {
            details: {
                name: 'financingDetails',
                label: 'Financing Details',
                path: '/financings/:loanId',
                routes: {
                    terms: {
                        name: 'terms',
                        label: 'Terms',
                        path: '/financings/:loanId',
                        exact: true,
                    },
                    keyDates: {
                        name: 'keyDates',
                        label: 'Key Dates',
                        path: '/financings/:loanId/details/key-dates',
                    },
                    reserves: {
                        name: 'reserves',
                        label: 'Reserves',
                        path: '/financings/:loanId/details/reserves',
                    },
                    funding: {
                        name: 'funding',
                        label: 'Funding',
                        path: '/financings/:loanId/details/funding',
                    },
                },
            },
            lenders: {
                name: 'lenders',
                label: 'Lenders',
                path: '/financings/:loanId/lenders',
            },
            borrower: {
                name: 'borrower',
                label: 'Borrower',
                path: '/financings/:loanId/borrower',
            },
            guarantor: {
                name: 'guarantor',
                label: 'Guarantor',
                path: '/financings/:loanId/guarantor',
            },
            budget: {
                name: 'budget',
                label: 'Budget',
                path: '/financings/:loanId/budget',
            },
            loanApplication: {
                name: 'loanApplication',
                label: 'Loan Application',
                path: '/financings/:loanId/application',
            },
            documents: {
                name: 'documents',
                label: 'Documents',
                path: '/financings/:loanId/documents',
            },
            statements: {
                name: 'statements',
                label: 'Statements',
                path: '/financings/:loanId/statements',
            },
            toDos: {
                name: 'todos',
                label: 'ToDos',
                path: '/financings/:loanId/todos',
            },
            underwriting: {
                name: 'underwriting',
                label: 'Underwriting',
                path: '/financings/:loanId/underwriting',
                routes: {
                    financialScenario: {
                        name: 'financialScenario',
                        label: 'Financial Scenario',
                        path: '/financings/:loanId/underwriting',
                        exact: true,
                    },
                    termsSheets: {
                        name: 'termsSheets',
                        label: 'Terms Sheets',
                        path: '/financings/:loanId/underwriting/terms-sheets',
                    },
                },
            },
            covenants: {
                name: 'covenants',
                label: 'Covenants',
                path: '/financings/:loanId/covenants',
            },
            messages: {
                name: 'messages',
                label: 'Messages',
                path: '/financings/:loanId/messages',
                icon: MessagesIcon,
            },
            collateral: {
                name: 'collateral',
                label: 'Collateral',
                path: '/financings/:loanId/collateral',
            },
            draws: {
                name: 'draws',
                label: 'Draws',
                path: '/financings/:loanId/draws',
                exact: true,
            },
            team: {
                name: 'team',
                label: 'Team',
                path: '/financings/:loanId/team',
            },
            thirdPartyReports: {
                name: 'thirdPartyReports',
                label: 'Third-Party Reports',
                path: `/financings/:loanId/third-party-reports`,
            },
            notes: {
                name: 'notes',
                label: 'Notes',
                path: '/financings/:loanId/notes',
            },
        },
    },
    loans: {
        name: 'loans',
        label: 'Loans',
        path: '/loans',
        exact: true,
    },
    loansPortfolio: {
        name: 'portfolio',
        label: 'Portfolio',
        path: '/loans/portfolios/:portfolioId',
    },
    loan: {
        name: 'loan',
        label: 'Loans',
        path: '/loans/:loanId',
        based: '/loans/',
        routes: {
            details: {
                name: 'details',
                label: 'Loan Details',
                path: '/loans/:loanId',
                routes: {
                    terms: {
                        name: 'terms',
                        label: 'Terms',
                        path: '/loans/:loanId',
                        exact: true,
                    },
                    keyDates: {
                        name: 'keyDates',
                        label: 'Key Dates',
                        path: '/loans/:loanId/details/key-dates',
                    },
                    reserves: {
                        name: 'reserves',
                        label: 'Reserves',
                        path: '/loans/:loanId/details/reserves',
                    },
                    funding: {
                        name: 'funding',
                        label: 'Funding',
                        path: '/loans/:loanId/details/funding',
                    },
                },
            },
            insight: {
                name: 'insight',
                label: 'Insight',
                path: '/loans/:loanId/insight',
            },
            lenders: {
                name: 'lenders',
                label: 'Lenders',
                path: `/loans/:loanId/lenders`,
            },
            budget: {
                name: 'budget',
                label: 'Budget',
                path: '/loans/:loanId/budget',
            },
            borrower: {
                name: 'borrower',
                label: 'Borrower',
                path: '/loans/:loanId/borrower',
            },
            guarantors: {
                name: 'guarantors',
                label: 'Guarantors',
                path: '/loans/:loanId/guarantors',
            },
            documents: {
                name: 'documents',
                label: 'Documents',
                path: '/loans/:loanId/documents',
            },
            statements: {
                name: 'statements',
                label: 'Statements',
                path: '/loans/:loanId/statements',
                routes: {
                    funding: {
                        name: 'funding',
                        label: 'Funding',
                        path: '/loans/:loanId/statements',
                        exact: true,
                    },
                    debtService: {
                        name: 'debtService',
                        label: 'Debt Service',
                        path: '/loans/:loanId/statements/debt-service',
                    },
                    statements: {
                        name: 'statements',
                        label: 'Statements',
                        path: '/loans/:loanId/statements/statements',
                    },
                },
            },
            todo: {
                name: 'todos',
                label: 'ToDos',
                path: '/loans/:loanId/todos',
            },
            underwriting: {
                name: 'underwriting',
                label: 'Underwriting',
                path: '/loans/:loanId/underwriting',
                routes: {
                    financialScenario: {
                        name: 'financialScenario',
                        label: 'Financial Scenario',
                        path: '/loans/:loanId/underwriting',
                        exact: true,
                    },
                    termsSheets: {
                        name: 'termsSheets',
                        label: 'Terms Sheets',
                        path: '/loans/:loanId/underwriting/terms-sheets',
                    },
                },
            },
            proforma: {
                name: 'proforma',
                label: 'Proforma',
                path: '/loans/:loanId/proforma',
            },
            covenants: {
                name: 'covenants',
                label: 'Covenants',
                path: '/loans/:loanId/covenants',
            },
            messages: {
                name: 'messages',
                label: 'Messages',
                path: '/loans/:loanId/messages',
                icon: MessagesIcon,
            },
            draws: {
                name: 'draws',
                label: 'Draws',
                path: '/loans/:loanId/draws',
            },
            team: {
                name: 'team',
                title: 'Team',
                label: 'Borrower Team',
                path: '/loans/:loanId/team',
                routes: {
                    lender: {
                        name: 'lender',
                        label: 'Lender Team',
                        path: '/loans/:loanId/team/lender',
                    },
                },
            },
            collateral: {
                name: 'collateral',
                label: 'Collateral',
                path: '/loans/:loanId/collateral',
            },
            thirdPartyReports: {
                name: 'thirdPartyReports',
                label: 'Third-Party Reports',
                path: '/loans/:loanId/third-party-reports',
            },
            notes: {
                name: 'notes',
                label: 'Notes',
                path: '/loans/:loanId/notes',
            },
        },
    },
    refer: {
        name: 'refer',
        label: 'Refer',
        path: '/refer',
    },
    documents: {
        name: 'documents',
        label: 'Documents',
        path: '/documents',
    },
    member: {
        name: 'member',
        label: 'Contacts',
        path: '/members/:memberId',
        based: '/members/',
        routes: {
            details: {
                name: 'details',
                label: 'Contact Details',
                path: '/members/:memberId',
            },
            loans: {
                name: 'loans',
                label: 'Loans',
                path: '/members/:memberId/loans',
            },
            properties: {
                name: 'properties',
                label: 'Properties',
                path: '/members/:memberId/properties',
            },
            team: {
                name: 'team',
                label: 'Team',
                path: '/members/:memberId/team',
            },
            owners: {
                name: 'owners',
                label: 'Owners',
                path: '/members/:memberId/owners',
            },
            documents: {
                name: 'documents',
                label: 'Documents',
                path: '/members/:memberId/documents',
            },
            financials: {
                name: 'financials',
                label: 'Financials',
                path: '/members/:memberId/financials',
            },
            notes: {
                name: 'notes',
                label: 'Notes',
                path: '/members/:memberId/notes',
            },
        },
    },
    members: {
        name: 'members',
        label: 'Contacts',
        path: '/members',
        exact: true,
    },
    benchmarking: {
        name: 'benchmarking',
        label: 'Benchmarking',
        path: '/benchmarking',
        routes: {
            map: {
                name: 'map',
                label: 'Map',
                displayLabel: 'Benchmarking',
                path: '/benchmarking/map',
            },
            benchmarking: {
                name: 'benchmarking',
                label: 'Benchmarking',
                path: '/benchmarking',
            },
        },
    },
    adminPage: {
        name: 'adminPage',
        label: 'Admin page',
        path: '/adminPage.html',
    },
    chatAi: {
        name: 'chatAi',
        label: 'Chat AI',
        path: '/chatAi',
        routes: {
            documents: {
                name: 'documents',
                label: 'Document AI ',
                path: '/chatAi',
            },
            data: {
                name: 'data',
                label: 'Data AI',
                path: '/chatAi/data',
            },
        },
    },
    todos: {
        name: 'To-Dos',
        label: 'To-Dos',
        path: '/todos',
    },
    configuration: {
        name: 'configuration',
        label: 'Configuration',
        path: '/configuration',
        routes: {
            allUsers: {
                displayLabel: 'Configuration',
                name: 'allUsers',
                label: 'All Users',
                path: '/configuration/all-users',
            },
            teams: {
                name: 'teams',
                label: 'Teams',
                path: '/configuration/teams',
                exact: true,
            },
            team: {
                displayLabel: 'Configuration',
                name: 'teams',
                label: 'Teams',
                based: '/configuration/teams',
                path: '/configuration/teams/:teamId',
            },
        },
    },
    error: {
        name: 'error',
        path: '*',
    },
} as const;

export default pages;

const copyServicesRoutes = { ...pages.services, routes: { ...pages.services.routes } };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
delete copyServicesRoutes.routes.services;

export const publicHeaderLinks = [copyServicesRoutes, pages.listings, pages.enterprise, pages.news];

export const publicHeaderMobileLinks = [
    ...Object.values(pages.services.routes!),
    pages.listings,
    pages.enterprise,
    pages.pricing,
    pages.aboutUs,
    pages.news,
];

export const authedDrawerLinks = [
    pages.dashboard,
    pages.search,
    pages.todos,
    pages.myProperties,
    pages.bookmarks,
    pages.history,
    pages.history2,
    pages.properties,
    pages.portfolios,
    pages.listings,
    pages.financings,
    pages.loans,
    pages.members,
    pages.refer,
    pages.documents,
    pages?.benchmarking?.routes?.map,
    pages.chatAi,
];

export const footerLinks = [pages.services, pages.pricing, pages.aboutUs, pages.news];

export const policyLinks = [pages.terms, pages.policy, pages.siteMap];

export const servicesLinks = Object.values(pages.services.routes!);

export const profileLinks = Object.values([pages.account, pages.password, pages.payment]);

export const enterprisePolicyLinks = [pages.terms, pages.policy, pages.siteMap];

// export const enterpriseFooterLinks = [pages.services, pages.pricing, pages.aboutUs, pages.news, pages.siteMap];
export const enterpriseFooterLinks = [pages.aboutUs, pages.pricing, pages.news];

export const socialLinks = [
    {
        name: 'facebook',
        path: 'https://www.facebook.com/smartcapital.center',
        viewBox: '0 0 8 16',
        component: FacebookIcon,
    },
    {
        name: 'linkedIn',
        path: 'https://www.linkedin.com/company/smart-capital-center',
        viewBox: '0 0 16 15',
        component: LinkedInIcon,
    },
    {
        name: 'twitter',
        path: 'https://twitter.com/SmartCapCenter',
        viewBox: '0 0 15 12',
        component: TwitterIcon,
    },
];
