import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { basePieChartConfig } from '@utils/charts/configs/index';
import { unitsSetter } from '@utils/charts/formatters';

const operatingExpansesPie = merge(cloneDeep(basePieChartConfig), {
    grid: {
        top: '60px',
    },
    tooltip: {
        formatter: (series) =>
            unitsSetter({
                series,
                units: '%',
            }),
    },
    series: [
        {
            label: {
                show: false,
            },
        },
    ],
});

export default operatingExpansesPie;
