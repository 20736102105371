import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { parcelsApi } from '@api';
import { apiRequest, API_SUCCESS, API_ERROR } from '@store/ducks/api';

const entity = '[parcel]';

const initialState = {
    error: null,
    data: null,
    params: null,
    parcels: [],
    loaded: false,
    loading: false,
};

const parcelSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadByAddress(state, action) {
            state.params = action.payload;
            state.loaded = false;
            state.loading = true;
        },
        add(state, action) {
            state.parcels = state.parcels?.concat(action.payload);
        },
        set(state, action) {
            state.loaded = true;
            state.loading = false;
            state.data = action.payload;
        },
        [`loadByAddress${API_ERROR}`]: {},
        setError(state, action) {
            state.loaded = true;
            state.loading = false;
            state.error = action.payload;
        },
        removeOne(state, action) {
            const index = state.parcels?.findIndex((parcel) => parcel.parcel === action.payload?.parcel);
            if (~index) {
                state.parcels.splice(index, 1);
            }
        },
        clean: () => initialState,
    },
});

export const {
    loadByAddress: loadByAddressParcel,
    add: addParcel,
    set: setParcel,
    setError: setParcelError,
    removeOne: removeOneParcel,
    clean: cleanParcel,
} = parcelSlice.actions;

export default parcelSlice.reducer;

export const selectParcelState = (state) => state.parcel;

function* loadByAddressParcelSaga({ payload, type }) {
    yield put(apiRequest(payload, parcelsApi.getParcelsByAddress, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    yield put(setParcel(action.payload));
    if (action.type.endsWith(API_ERROR)) {
        yield put(setParcelError(true));
    } else {
        yield put(setParcelError(false));
    }
}

export function* watchParcel() {
    yield takeLatest(loadByAddressParcel, loadByAddressParcelSaga);
}
