import request from '@utils/request';

const BASE_URL = '/api/v2/company';

export async function getUserCompanies() {
    return request(`${BASE_URL}/user-companies`);
}

export async function createUserCompany({ body }) {
    return request(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updateUserCompany(data) {
    return request(`${BASE_URL}`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function addCompanyLogo({ id, file }) {
    return request(`${BASE_URL}/${id}/logo`, {
        method: 'POST',
        body: file,
        headers: {},
    });
}
