import colors from '@themes/palette/export.module.scss';

const iconButton = () => ({
    MuiIconButton: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: 'inherit',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'inherit',
                    color: colors['grey-200'],
                },
            },
            sizeSmall: {
                width: 28,
                height: 28,
            },
            sizeMedium: {
                width: 32,
                height: 32,
            },
            sizeLarge: {
                width: 40,
                height: 40,
            },
        },
        variants: [
            {
                props: { size: 'extra-small' },
                style: {
                    width: 24,
                    height: 24,
                },
            },
            {
                props: { size: 'extra-large' },
                style: {
                    width: 48,
                    height: 48,
                },
            },
            {
                props: { color: 'primary' },
                style: {
                    backgroundColor: colors['blue-main'],
                    borderRadius: 4,
                    color: colors.white,
                    '&:hover': {
                        backgroundColor: colors['blue-400'],
                    },
                    '&:active': {
                        backgroundColor: colors['blue-700'],
                    },
                    '&:focus': {
                        backgroundColor: colors['blue-600'],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: colors['blue-200'],
                    },
                },
            },
            {
                props: { color: 'secondary' },
                style: {
                    border: `1px solid ${colors['grey-100']}`,
                    backgroundColor: colors.white,
                    borderRadius: 6,
                    color: colors['darkNeutral-500'],
                    '&:hover': {
                        color: colors['blue-400'],
                        borderColor: colors['blue-400'],
                    },
                    '&:active': {
                        color: colors['blue-700'],
                        borderColor: colors['blue-700'],
                        backgroundColor: colors['blue-50'],
                    },
                    '&:focus': {
                        color: colors['darkNeutral-500'],
                        borderColor: colors['blue-900'],
                        outline: `1px solid ${colors['darkNeutral-500']}`,
                    },
                    '&.Mui-disabled': {
                        color: colors['grey-200'],
                    },
                },
            },
        ],
    },
});

export default iconButton;
