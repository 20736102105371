const buttonBase = () => ({
    MuiButtonBase: {
        disableRipple: true,
        styleOverrides: {
            root: {
                '.Mui-active &.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.8) !important',
                },
            },
        },
    },
});
export default buttonBase;
