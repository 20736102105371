import { isNaN } from 'lodash';

import { isIsoDate, isValidDate } from '@utils/dates';

const removeTimeZone = (data = new Date()) =>
    data.getTimezoneOffset ? data.getTime() - data.getTimezoneOffset() * 60 * 1000 : data;

const restISOTime = (date) => {
    return isIsoDate(date) ? removeTimeZone(new Date(date)) : date;
};

export const applyDateValue = (date) => {
    if (!date || date === '-') {
        return null;
    }

    if (!isValidDate(date)) {
        return null;
    }

    return new Date(restISOTime(isNaN(Number(date)) ? date : Number(date))).getTime();
};
