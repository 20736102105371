import TableContainer from '@mui/material/TableContainer';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useExpanded, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';

import { stringOrFunctionChecker } from '@utils/index';

import Table from '@ui/Table';
import TableBody from '@ui/TableBody';
import TableCell from '@ui/TableCell';
import TableHead from '@ui/TableHead';
import TableRow from '@ui/TableRow';
import Typography from '@ui/Typography';

import BlockedControl from '@components/BlockedBox/components/BlockedControl/BlockedControl';
import EditableBlock from '@components/EditableBlock';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';
import BaseFooter from './ui/BaseFooter';

const defaultPropsBaseCell = {
    onUpdateData: () => {},
};

export function BaseCell({
    searchWords,
    value: initialValue,
    row,
    rows,
    column: {
        headerProps: {
            size = 'hr13',
            interactive = false,
            cellData: { validate = () => true, justifyContent = 'flex-start', isLimited = false } = {},
        } = {},
        editable: editableProp,
        placeholder,
        formatting = ({ value }) => value,
        showHighlight = false,
    },
    cell: {
        column: { id: cellId },
    },
    onUpdateData,
    tableKey,
    childrenClassName,
    dataQa,
}) {
    // console.log();
    const getEditableProp = typeof editableProp === 'function' ? editableProp(row?.original) : editableProp;

    const editable =
        // eslint-disable-next-line no-prototype-builtins
        row && row.original && row.original.hasOwnProperty('isEditable') ? row.original.isEditable : getEditableProp;

    const jsonValues = row.original?.jsonValues || {};
    const value = initialValue ?? jsonValues[cellId];

    const formattedValue = value
        ? formatting({ value, size, original: row?.original || {}, rows, showHighlight, searchWords })
        : '-';
    const color = placeholder && !value ? colors['grey-300'] : colors['darkNeutral-500'];
    return (
        <BlockedControl isLimited={isLimited} placement='left'>
            <EditableBlock
                dataQa={dataQa}
                initialValue={initialValue}
                inputClassName={styles.input}
                inputProps={{ size: 'small', classes: styles.input }}
                name={cellId}
                notEditable={!editable || isLimited}
                tabIndex={row.index}
                validate={validate}
                onSuccess={(val) => onUpdateData({ cell: val, row, rows, tableKey })}
            >
                <Typography
                    className={cx(styles.cell, childrenClassName, {
                        [styles.notInteractive]: !interactive && !editable,
                        [styles.interactiveCell]: editable,
                    })}
                    color={color}
                    component='div'
                    sx={{ justifyContent }}
                    variant={size}
                >
                    {placeholder && !value ? placeholder : formattedValue}
                </Typography>
            </EditableBlock>
        </BlockedControl>
    );
}

BaseCell.defaultProps = defaultPropsBaseCell;

const defaultColumn = {
    Cell: BaseCell,
};

const tableBodyId = 'tableBody';

const defaultProps = {
    data: [],
    showEmpty: false,
    rowProps: {},
    tableProps: {},
    headerProps: {},
    cellProps: {},
    infiniteScrollProps: {},
    fullHeight: false,
    mainProps: {},
};

function DataTable({
    inverseSideSpacing,
    data,
    className,
    showEmpty,
    headers,
    rowProps,
    tableProps,
    headerProps,
    bordered,
    unBordered,
    cellProps,
    footerProps,
    update,
    onUpdateData,
    totalCount,
    skipPageReset,
    skipExpandReset,
    infiniteScrollProps,
    expandedRows,
    children,
    fullHeight,
    footerClass,
    actionsBar: ActionsBarComponent,
    id: tableId = 'table',
    mainProps,
    maxHeight,
    rootClassName,
}) {
    const cellPropsSafeForComponent = { ...cellProps };
    delete cellPropsSafeForComponent.menuItems;

    const { toggleAllRowsExpanded, getTableProps, getTableBodyProps, headerGroups, footerGroups, prepareRow, rows } =
        useTable(
            {
                columns: headers,
                data,
                defaultColumn,
                autoResetPage: !skipPageReset,
                autoResetExpanded: !skipExpandReset,
                onUpdateData,
                manualSortBy: true,
                manualFilters: true,
            },
            useGlobalFilter,
            useFilters,
            useSortBy,
            useExpanded,
            useRowSelect
        );

    useEffect(() => {
        if (expandedRows) toggleAllRowsExpanded(true);
    }, [data, expandedRows, toggleAllRowsExpanded]);

    const rootElRef = useRef(null);
    const [isLimitedByMaxHeight, setIsLimitedByMaxHeight] = useState(false);

    useEffect(() => {
        if (maxHeight) {
            setIsLimitedByMaxHeight(rootElRef.current?.clientHeight > maxHeight);
        }
    }, [maxHeight]);

    if (!data?.length && !showEmpty) {
        return null;
    }

    const { noHeaders } = headerProps;
    const { size } = tableProps;
    const { activeRowClass, className: rowClassName, selected, highlightClassName, ...restRowProps } = rowProps;

    return (
        <div
            className={cx(styles.root, {
                [styles.infiniteScrollContainer]: fullHeight,
                [styles.inverseSideSpacing]: inverseSideSpacing,
                [rootClassName]: rootClassName,
            })}
            ref={rootElRef}
            style={isLimitedByMaxHeight ? { height: maxHeight } : {}}
        >
            <InfiniteScroll
                dataLength={rows.length}
                hasMore={totalCount > rows.length}
                loader={<div />}
                next={update}
                scrollableTarget='table'
                {...infiniteScrollProps}
            >
                <TableContainer
                    className={cx(styles.tableFullHeight, className, {
                        [styles.bordered]: bordered,
                        [styles.unBordered]: unBordered,
                    })}
                    id={tableId}
                >
                    <Table
                        {...getTableProps()}
                        aria-label='sticky table'
                        className={styles.tableFullHeight}
                        size={size}
                        stickyHeader
                    >
                        {!noHeaders && (
                            <TableHead className={headerProps?.className}>
                                {headerGroups.map((headerGroup) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <TableCell
                                                {...column.getHeaderProps([
                                                    {
                                                        className: cx(column.headerProps?.className, {
                                                            [styles.alignRight]: column?.headerProps?.isRight,
                                                            [styles.alignCenter]: column?.headerProps?.isCenter,
                                                        }),
                                                        style: column.headerProps?.sx,
                                                    },
                                                ])}
                                                key={column.id}
                                            >
                                                <Typography
                                                    className={cx(styles.heading, column.headerProps?.className)}
                                                    color={colors['grey-500']}
                                                    component='div'
                                                    variant='psb12'
                                                >
                                                    {column.render('Header', { headerProps, mainProps })}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHead>
                        )}
                        <TableBody
                            {...getTableBodyProps()}
                            classes={{
                                root: styles.tableBody,
                            }}
                            id={tableBodyId}
                        >
                            <>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    const rowId = row?.original?.id || row?.values?.id || row?.id;
                                    return (
                                        <TableRow
                                            key={rowId}
                                            {...row.getRowProps()}
                                            {...restRowProps}
                                            className={cx(
                                                rowClassName,
                                                activeRowClass?.row,
                                                stringOrFunctionChecker(activeRowClass, row),
                                                {
                                                    [highlightClassName]: row?.isHighlighted || row?.original?.isNew,
                                                },
                                                restRowProps.classNameFn?.(row) ?? ''
                                            )}
                                            data-qa={`${tableId}-record-${row?.id}`}
                                            data-toggle={row?.id}
                                            id={rowId}
                                            selected={typeof selected === 'function' ? selected(row) : selected}
                                            onClick={(e) => {
                                                restRowProps?.onClick?.(e, row);
                                            }}
                                        >
                                            {row.cells.map(({ getCellProps, column, render }, index) => (
                                                <TableCell
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={index}
                                                    {...getCellProps()}
                                                    className={cx(cellProps?.className, column.cellProps?.className, {
                                                        [styles.alignRight]: column?.headerProps?.isRight,
                                                        [styles.alignCenter]: column?.headerProps?.isCenter,
                                                    })}
                                                    id={`${rowId}-${index}`}
                                                    style={column?.cellProps?.sx}
                                                    {...cellPropsSafeForComponent}
                                                >
                                                    {render('Cell', { ...cellProps, mainProps })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {children && (
                                    <TableRow>
                                        <TableCell colSpan={headers.length}>{children}</TableCell>
                                    </TableRow>
                                )}
                                {data.length <= 12 && fullHeight && (
                                    <TableRow>
                                        <TableCell className={styles.emptyRow} colSpan={headers.length} />
                                    </TableRow>
                                )}
                            </>
                            {!!ActionsBarComponent && <ActionsBarComponent data={{ rows }} headers={headers} />}
                        </TableBody>
                        {footerProps && (
                            <BaseFooter
                                className={cx({
                                    [footerClass]: footerClass,
                                    [styles.footerSticky]: footerProps.isSticky,
                                    [styles.footerTotal]: footerProps.isTotal,
                                })}
                                cols={footerProps.columns}
                                groups={footerGroups}
                            />
                        )}
                    </Table>
                </TableContainer>
            </InfiniteScroll>
        </div>
    );
}

DataTable.defaultProps = defaultProps;

export default DataTable;
