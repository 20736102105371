import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { v1 as uuid } from 'uuid';

import { DOCUMENT_TYPES } from '@constants/documents';

import { TTemplate } from '../ExportExcelReport.types';

import { COLUMN_TYPES_VALUES, OPTIONS_TYPES, TEMPLATES_ID, TYPES, DOCUMENTS_TYPES } from './constants';

export const TEMPLATES_WITHOUT_INFO = {
    [TEMPLATES_ID.proforma]: true,
    [TEMPLATES_ID.underwriting]: true,
    [TEMPLATES_ID.fmRentRollComparison]: true,
    [TEMPLATES_ID.loanTable]: true,
    [TEMPLATES_ID.fmFinancials]: true,
    [TEMPLATES_ID.fmDMS]: true,
};

type TemplatesMap = {
    [key: string]: () => TTemplate;
};

export const FM_TEMPLATES = {
    [TEMPLATES_ID.fmFinancials]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.fmFinancials,
        name: 'FM Financials',
        options: [
            { name: OPTIONS_TYPES.LOAN_ID },
            { name: OPTIONS_TYPES.COUNT, value: '0', hidden: true },
            { name: OPTIONS_TYPES.COLUMNS_TYPE, value: 'all', hidden: true },
            {
                name: OPTIONS_TYPES.COLUMN,
                filter: [
                    ['count', 'count'],
                    ['columnsType', 'type'],
                ],
            },
        ],
    }),
    [TEMPLATES_ID.fmDMS]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.fmDMS,
        name: 'Freddie Mac DMS export',
        options: [
            { name: OPTIONS_TYPES.LOAN_ID },
            { name: OPTIONS_TYPES.COUNT, value: '0', hidden: true },
            { name: OPTIONS_TYPES.COLUMNS_TYPE, value: 'all', hidden: true },
            { name: OPTIONS_TYPES.RENT_ROLL_COMPARISON, displayHidden: true },
            { name: OPTIONS_TYPES.RENT_ROLL_SECOND_COMPARISON },
            {
                name: OPTIONS_TYPES.COLUMN,
                filter: [
                    ['count', 'count'],
                    ['columnsType', 'type'],
                ],
            },
        ],
    }),
};

export const RGA_TEMPLATES: {
    [key: string]: () => TTemplate;
} = {
    [TEMPLATES_ID.rgaRentRoll]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.rgaRentRoll,
        name: 'RGA Rent Roll',
        filter: DOCUMENT_TYPES.RR,
        options: [{ name: OPTIONS_TYPES.DOCUMENTS }, { name: 'type', value: 'standardized' }],
    }),
    [TEMPLATES_ID.rgaStatement]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.rgaStatement,
        name: 'RGA Financials',
        filter: DOCUMENT_TYPES.COMBINED,
        options: [
            { name: OPTIONS_TYPES.DOCUMENTS, hidden: true },
            { name: OPTIONS_TYPES.COUNT, value: '12' },
            { name: OPTIONS_TYPES.COLUMNS_TYPE, value: 'all' },
            {
                name: OPTIONS_TYPES.COLUMN,
                filter: [
                    ['count', 'count'],
                    ['columnsType', 'type'],
                ],
            },
        ],
    }),
};

export const TEMPLATES_MAP: TemplatesMap = {
    [TEMPLATES_ID.financials]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.financials,
        name: 'Financials',
        filter: DOCUMENT_TYPES.OS,
        options: [{ name: OPTIONS_TYPES.DOCUMENTS }, { name: OPTIONS_TYPES.TYPE, value: 'standardized' }],
    }),
    [TEMPLATES_ID.rentRoll]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.rentRoll,
        name: 'Rent Roll',
        filter: DOCUMENT_TYPES.RR,
        options: [{ name: OPTIONS_TYPES.DOCUMENTS }, { name: OPTIONS_TYPES.TYPE, value: 'standardized' }],
    }),
    [TEMPLATES_ID.proforma]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.proforma,
        name: 'Proforma',
        options: [{ name: OPTIONS_TYPES.SCENARIOS, value: 'contract' }],
    }),
    [TEMPLATES_ID.underwriting]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.underwriting,
        name: 'Underwriting',
        options: [{ name: OPTIONS_TYPES.MODEL_ID }],
    }),
    [TEMPLATES_ID.propertyOverview]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.propertyOverview,
        name: 'Overview Charts',
    }),
    [TEMPLATES_ID.asr]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.asr,
        name: 'ASR report',
        options: [{ name: OPTIONS_TYPES.LOAN_ID }],
    }),
    [TEMPLATES_ID.osarCREFC]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.osarCREFC,
        name: 'OSAR',
        filter: DOCUMENT_TYPES.RR,
        options: [
            { name: OPTIONS_TYPES.DOCUMENTS, hidden: true, isRequired: false },
            { name: OPTIONS_TYPES.COMBINED_ID, hidden: true },
            { name: OPTIONS_TYPES.LOAN_ID },
            { name: OPTIONS_TYPES.RR_ID },
            { name: OPTIONS_TYPES.COUNT, value: '1', filteredOptions: ['0'] },
            { name: OPTIONS_TYPES.COLUMNS_TYPE, value: 'all', hidden: true },
            {
                name: OPTIONS_TYPES.COLUMN,
                filter: [
                    ['count', 'count'],
                    ['columnsType', 'type'],
                ],
            },
        ],
    }),
    [TEMPLATES_ID.symetraFinancials]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.symetraFinancials,
        name: 'Symetra Financials',
        filter: DOCUMENT_TYPES.COMBINED,
        options: [
            { name: OPTIONS_TYPES.DOCUMENTS, hidden: true },
            { name: OPTIONS_TYPES.YEAR },
            { name: OPTIONS_TYPES.PERIODS, multiple: false },
        ],
    }),
    [TEMPLATES_ID.unitMix]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.unitMix,
        name: 'Unit Mix',
        filter: DOCUMENT_TYPES.RR,
        options: [{ name: OPTIONS_TYPES.RR_ID }, { name: OPTIONS_TYPES.UNIT_MIX_TYPE, value: 'byUnits' }],
    }),
    [TEMPLATES_ID.varianceReport]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.varianceReport,
        name: 'Variance Report',
        options: [{ name: OPTIONS_TYPES.VARIANCE_REPORT_ID }],
    }),
    [TEMPLATES_ID.adjustments]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.adjustments,
        name: 'Adjustments',
        filter: DOCUMENT_TYPES.COMBINED,
        options: [
            { name: OPTIONS_TYPES.DOCUMENTS, hidden: true },
            { name: OPTIONS_TYPES.COUNT, value: '12' },
            { name: OPTIONS_TYPES.COLUMNS_TYPE, value: 'all' },
            {
                name: OPTIONS_TYPES.COLUMN,
                filter: [
                    ['count', 'count'],
                    ['columnsType', 'type'],
                ],
            },
        ],
    }),
    [TEMPLATES_ID.fmRentRollComparison]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.fmRentRollComparison,
        name: 'Rent Roll Comparison',
        filter: DOCUMENT_TYPES.RR,
        options: [
            {
                name: OPTIONS_TYPES.LOAN_ID,
            },
            { name: OPTIONS_TYPES.RENT_ROLL_COMPARISON },
            { name: OPTIONS_TYPES.RENT_ROLL_SECOND_COMPARISON },
        ],
    }),
    [TEMPLATES_ID.loanTable]: () => ({
        key: uuid(),
        id: TEMPLATES_ID.loanTable,
        name: 'Loan Table',
        options: [{ name: OPTIONS_TYPES.LOAN_TABLE_MODEL_DATE }],
    }),
} as const;

const SINGLE_INSTANCE_TEMPLATES = [TEMPLATES_MAP[TEMPLATES_ID.propertyOverview]()];

const MULTI_INSTANCE_TEMPLATES = [
    TEMPLATES_MAP[TEMPLATES_ID.financials](),
    TEMPLATES_MAP[TEMPLATES_ID.rentRoll](),
    TEMPLATES_MAP[TEMPLATES_ID.proforma](),
    TEMPLATES_MAP[TEMPLATES_ID.underwriting](),
    TEMPLATES_MAP[TEMPLATES_ID.symetraFinancials](),
    TEMPLATES_MAP[TEMPLATES_ID.osarCREFC](),
    TEMPLATES_MAP[TEMPLATES_ID.asr](),
    TEMPLATES_MAP[TEMPLATES_ID.unitMix](),
    TEMPLATES_MAP[TEMPLATES_ID.varianceReport](),
    TEMPLATES_MAP[TEMPLATES_ID.adjustments](),
    TEMPLATES_MAP[TEMPLATES_ID.fmRentRollComparison](),
    TEMPLATES_MAP[TEMPLATES_ID.loanTable](),
];

const TEMPLATES_MAP_BY_ROLES = {
    rga: [RGA_TEMPLATES['rga-rent-roll'](), RGA_TEMPLATES['rga-statement']()],
    fm: [FM_TEMPLATES[TEMPLATES_ID.fmFinancials](), FM_TEMPLATES[TEMPLATES_ID.fmDMS]()],
};

export const createTemplatesList = ({ isRga, currentTabs, isFMAC }) => {
    const currentTabIds = new Set(currentTabs?.map(({ id }) => id));

    const filteredSingleInstanceTabs = SINGLE_INSTANCE_TEMPLATES.filter(({ id }) => !currentTabIds.has(id));

    return [
        ...MULTI_INSTANCE_TEMPLATES,
        ...filteredSingleInstanceTabs,
        ...(isRga ? TEMPLATES_MAP_BY_ROLES.rga : []),
        ...(isFMAC ? TEMPLATES_MAP_BY_ROLES.fm : []),
    ];
};

const COMMON_TEMPLATES = {
    templates: {
        financial: [TEMPLATES_MAP.financials()],
        rentroll: [TEMPLATES_MAP[TEMPLATES_ID.rentRoll]()],
        propertyOverview: [TEMPLATES_MAP['property-overview']()],
    },
    controls: {
        value: TYPES.common,
        name: 'Templates',
        items: [
            {
                id: 'financial',
                name: 'Financials',
            },
            {
                id: 'rentroll',
                name: 'Rent Roll',
            },
            {
                id: 'propertyOverview',
                name: 'Property Overview',
            },
        ],
    },
} as const;

export const CUSTOM_TEMPLATES = {
    controls: {
        value: TYPES.custom,
        name: 'My Templates',
    },
} as const;

export const MAIN_TEMPLATE_WIDGETS = {
    [TYPES.common]: COMMON_TEMPLATES,
    [TYPES.custom]: CUSTOM_TEMPLATES,
} as const;

export const reorderItems = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const isRequiredName = (name = '') => /documents|combinedId/gim.test(name);

export const transformDocumentForSelect = (document) => {
    const label = document?.documentName || document?.fileName || 'All Financials';

    return {
        label,
        value: document?.id,
        type: document?.type,
        combined: document?.combined ?? false,
    };
};

export const isQuarterPeriod = (count) => {
    if (!isNumber(count) || Number.isNaN(count)) {
        return false;
    }

    return count % 3 === 0 && count < 12;
};

export const transformVariantsReportForSelect = (report) => {
    const {
        documentJson: { varianceReportOptions },
    } = report;

    const variantReportsNames = varianceReportOptions?.map(({ value }) => value) || [];

    const label = varianceReportOptions.length ? variantReportsNames.join(' vs ') : null;

    return {
        label,
        value: report?.id,
    };
};

export const getFilteredHiddenOptions = (options, filteredOptions, filterOptionFn) => {
    if (filterOptionFn) {
        return options?.filter(filterOptionFn);
    }

    if (!filteredOptions?.length) {
        return options;
    }

    return options?.filter(({ value }) => !filteredOptions.includes(value));
};

export const getTabItems = ({ options, tab, headerSelects }) => {
    return options?.reduce((acc, cur, index, arr) => {
        let currentHeaders = headerSelects[cur.name];
        const templateOption = options?.[index];

        if (cur.name === OPTIONS_TYPES.PERIODS) {
            const idx = acc.findIndex(({ name }) => name === 'year');
            currentHeaders = currentHeaders?.[acc[idx >= 0 ? idx : 0]?.value];
        }

        const filterHeaderOptionsByValue = arr.filter((option) =>
            templateOption?.filter?.some(([name]) => name.toLowerCase() === option.name.toLowerCase())
        );

        if (cur.name === OPTIONS_TYPES.COMBINED_ID) {
            const combinedId = currentHeaders?.find(({ type }) => type === DOCUMENT_TYPES.COMBINED)?.value;

            return acc.concat({
                ...cur,
                value: combinedId,
                options: [{ label: 'Combined', value: combinedId }],
            });
        }

        if (tab.id === TEMPLATES_ID.fmDMS && cur.name === OPTIONS_TYPES.RENT_ROLL_COMPARISON) {
            const headerValue = currentHeaders?.find(
                (option) => option.rentRollILabel || option.type === 'RENT_ROLL_METRIC'
            );

            return acc.concat({
                ...cur,
                ...headerValue,
                value: headerValue?.value ?? 'nodata',
                options: currentHeaders,
            });
        }

        if (cur.name === OPTIONS_TYPES.RENT_ROLL_COMPARISON) {
            const item = arr?.find(
                ({ type, value }, idx) => cur.index === idx || (type === cur.value && !cur.index) || value === cur.value
            );

            const headerValue = currentHeaders?.find((option) => option.value === item?.value) || currentHeaders?.[0];

            return acc.concat({
                ...cur,
                ...headerValue,
                value: headerValue?.value ?? 'nodata',
                options: currentHeaders,
            });
        }

        if (cur.name === OPTIONS_TYPES.RENT_ROLL_SECOND_COMPARISON) {
            const prevRentRoll = acc.find(({ name }) => name === OPTIONS_TYPES.RENT_ROLL_COMPARISON)?.value;

            const item = arr?.find(
                ({ type, value }, idx) => cur.index === idx || (type === cur.value && !cur.index) || value === cur.value
            );

            const filteredRentRolls = currentHeaders?.filter(({ value }) => value !== prevRentRoll);

            const headerValue =
                filteredRentRolls?.find((option) => option.value === item?.value) || filteredRentRolls?.[0];

            return acc.concat({
                ...cur,
                ...headerValue,
                value: headerValue?.value ?? 'nodata',
                options: filteredRentRolls?.length ? filteredRentRolls : [{ label: 'No Data', value: 'nodata' }],
            });
        }

        const headerOptions = currentHeaders?.filter((header) => {
            return (
                !('type' in header) ||
                (header.type === DOCUMENT_TYPES.COMBINED && tab.filter !== DOCUMENT_TYPES.RR) ||
                header.type === cur.value ||
                header.type.startsWith(tab.filter) ||
                templateOption?.filter?.every(([, key], idx) => {
                    const filterHeaderValue = isNumber(header[key])
                        ? Number(filterHeaderOptionsByValue[idx]?.value)
                        : filterHeaderOptionsByValue[idx]?.value;

                    if (filterHeaderValue === 'other' && header.type) {
                        return Object.values(COLUMN_TYPES_VALUES)
                            .filter((value) => value !== COLUMN_TYPES_VALUES.OTHER)
                            .every((value) => header.type !== value);
                    }

                    if (templateOption.name === 'column' && filterHeaderValue === 3) {
                        return isQuarterPeriod(header[key] ?? 0);
                    }

                    const isAllValue = /(0|all)/gi.test(filterHeaderOptionsByValue[idx]?.value);

                    return isAllValue || header[key] === filterHeaderValue;
                })
            );
        });

        const filteredHeaderOptions = getFilteredHiddenOptions(
            headerOptions,
            templateOption?.filteredOptions,
            templateOption.filterOptionFn
        );

        const item = arr?.find(
            ({ type, value }, idx) => cur.index === idx || (type === cur.value && !cur.index) || value === cur.value
        );

        const headerValue = templateOption?.multiple
            ? String(item.value)
                  .split(',')
                  .reduce(
                      (_acc, _cur, idx) => {
                          const { label = '', value } =
                              headerOptions?.find((option) => String(option.value) === _cur || option.type === _cur) ||
                              headerOptions?.[0] ||
                              {};
                          return {
                              value:
                                  // eslint-disable-next-line no-nested-ternary
                                  idx === 0 ? value : _acc.value === value ? value : `${_acc.value},${value}`,
                              label:
                                  // eslint-disable-next-line no-nested-ternary
                                  idx === 0 ? label : _acc.label === label ? label : `${_acc.label},${label}`,
                          };
                      },
                      { label: '', value: '' }
                  )
            : filteredHeaderOptions?.find((option) => option.value === item?.value || option.type === item?.value) ||
              filteredHeaderOptions?.[0];

        return acc.concat({
            ...cur,
            ...headerValue,
            value: headerValue?.value ?? 'nodata',
            options: !isEmpty(filteredHeaderOptions) ? filteredHeaderOptions : [{ label: 'No Data', value: 'nodata' }],
        });
    }, []);
};

export const getTemplateInfoMessage = (type) => {
    const documentType = DOCUMENTS_TYPES[type] ?? DOCUMENTS_TYPES.default;

    return `Property does not have ${documentType} documents`;
};

export const getInfoMessage = ({ tabId, type }) => {
    if (TEMPLATES_WITHOUT_INFO[tabId]) {
        return null;
    }

    return getTemplateInfoMessage(type);
};
