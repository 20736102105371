import QuotesIcon from '@scc/assets/icons/quotes.svg';

import Container from '@ui/Container';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import LauraImageWebP from '@/sc/images/team/Laura2.jpg?format=webp&useResponsiveLoader=true';
import LauraImage from '@/sc/images/team/Laura2.jpg?useResponsiveLoader=true';

import Image from '../Image';
import Label from '../Label';

import styles from './styles.module.scss';

function Story({ mobile }) {
    return (
        <div className={styles.story}>
            <Container maxWidth='xl'>
                <div className={styles.wrapper}>
                    <Label>Our Story</Label>
                    <Typography
                        className={styles.title}
                        color={colors['darkNeutral-500']}
                        component='span'
                        variant={mobile ? 'h1b' : 'head2b'}
                    >
                        Smart Capital can help you scale your real estate business 10x – with fewer resources
                        <Icon className={styles.quote} component={QuotesIcon} />
                    </Typography>

                    <div className={styles.userInfo}>
                        <div className={styles.imgContainer}>
                            <Image
                                alt='Laura Krashakova'
                                loading='lazy'
                                sizes={`${LauraImage?.width}px`}
                                src={LauraImage.src}
                                srcSet={LauraImage.srcSet}
                                webp={LauraImageWebP.srcSet}
                                width={LauraImage?.width}
                            />
                        </div>
                        <div className={styles.rightBlock}>
                            <Typography color={colors.black} component='span' variant='h4sb'>
                                Laura Krashakova
                            </Typography>
                            <Typography color={colors['darkNeutral-500']} component='span' variant='h4sb'>
                                CEO
                            </Typography>
                            <Typography color={colors['darkNeutral-500']} variant='pr16'>
                                Powered by its state-of-the-art technology and data platform, Smart Capital provides
                                access to real-time unbiased underwriting and cost-effective financing options – all in
                                one platform.
                            </Typography>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Story;
