import request from '@utils/request';

const BASE_URL = `/api/v2/chat-ai`;

export async function askChat({ chatId, message }) {
    return request(`${BASE_URL}/ask`, {
        method: 'POST',
        body: JSON.stringify({
            chatId,
            question: message,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

export async function getChatsByUserPropertyResponse(userId: number, propertyId: number) {
    return request(`${BASE_URL}/${userId}/${propertyId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

export async function getChatById(chatId: number) {
    return request(`${BASE_URL}/chat/${chatId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

export async function createChatForUser(name: string, propertyId: number) {
    return request(`${BASE_URL}/new`, {
        method: 'POST',
        body: JSON.stringify({
            name,
            propertyId,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

export async function deleteChat(chatId: number) {
    return request(`${BASE_URL}/${chatId}`, {
        method: 'DELETE',
        body: JSON.stringify({
            chatId,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

export async function renameChat(id: number, name: string) {
    return request(`${BASE_URL}/rename`, {
        method: 'PUT',
        body: JSON.stringify({
            id,
            name,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}