import { memo } from 'react';

import { TEMPLATES_MAP, BASE } from './constants';

function LargestTenants({ title, data, additionalData, template = BASE }) {
    if (!data) {
        return null;
    }

    const Component = TEMPLATES_MAP[template];

    return <Component additionalData={additionalData} data={data} title={title} />;
}

export default memo(LargestTenants);
