import colors from '@themes/palette/export.module.scss';

const menu = () => ({
    MuiMenu: {
        styleOverrides: {
            list: {
                display: 'flex',
                flexDirection: 'column',
                rowGap: 4,
            },
        },
    },
    MuiMenuItem: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                transition: 'background-color 300ms ease-in-out',
                padding: '8px 16px',
                fontSize: '12px',
                borderRadius: 0,
                color: colors['text-accent'],
                fontFamily: 'Open Sans',
                '&:hover': {
                    backgroundColor: '#4870FF1A',
                },
                '&.Mui-selected': {
                    backgroundColor: '#4870FF33',
                    color: colors.black,
                },
            },
        },
        variants: [
            {
                props: { variant: 'large' },
                style: {
                    fontSize: '16px',
                    minHeight: '48px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px',
                    },
                },
            },
        ],
    },
});

export default menu;
