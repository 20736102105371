import request from '@utils/request';
/* eslint-disable no-return-await */

const BASE_URL = '/geoGekko/building';

export async function getPropertyById({ id, type, countyId, parcelId, userId }) {
    return await request(`${BASE_URL}/data`, {
        params: {
            id,
            propertytype: type,
            countyid: countyId,
            parcelid: parcelId,
            userid: userId,
        },
    });
}

export async function getParcelsByAddress({ userid, address, latitude, longitude, oneParcelPerType, parcelsData }) {
    return await request(`${BASE_URL}/parcels`, {
        params: {
            userid,
            address,
            latitude,
            longitude,
            oneParcelPerType,
            parcelsData,
        },
    });
}

export async function getBuildingData(data = {}) {
    return await request('/geoGekko/get/building', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function copyPublicParcel(data = {}) {
    return await request(`${BASE_URL}/parcel/public/copy`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getParcelsByParcelId({ countyid, parcelid, oneParcelPerType }) {
    return request(`${BASE_URL}/parcel/getByParcel`, {
        params: {
            countyid,
            parcelid,
            oneParcelPerType,
        },
    });
}
