import request from '@utils/request';

const BASE_URL = '/api/v2/portfolio';

export async function getPortfolio({ userId, page = 0, size = 20, status = null }) {
    const searchParam = status ? `/${status}` : '';
    return request(`${BASE_URL}/all/${userId}${searchParam}`, {
        params: {
            page,
            size,
        },
    });
}

export async function getPortfolios() {
    return request(`${BASE_URL}`);
}

export async function updatePortfolio(body) {
    return request(`${BASE_URL}/update`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updatePortfolioName({ portfolioId, body }) {
    return request(`${BASE_URL}/update/${portfolioId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function getPortfolioBookmark({ parcelId, countyId }) {
    return request(`${BASE_URL}/parcel/${parcelId?.replace('/', '_SLASH_')}/county/${countyId}`);
}

export async function createPortfolio({ parcelId, countyId, userId }) {
    return request(`${BASE_URL}/create`, {
        method: 'POST',
        body: JSON.stringify({ parcelId, countyId, userId }),
    });
}

export async function deletePortfolio({ id }) {
    return request(`${BASE_URL}/delete`, {
        method: 'POST',
        body: JSON.stringify({ id }),
    });
}

export async function getTotal() {
    return request(`${BASE_URL}/totals`);
}

export async function getOnePortfolio({ portfolioId }) {
    return request(`${BASE_URL}/${portfolioId}`);
}

export async function getPortfoliosLender() {
    return request(`${BASE_URL}/lender`);
}

export async function getPortfolioDetails({ portfolioId, userId }) {
    return request(`${BASE_URL}/details/${portfolioId}?userId=${userId}`);
}

export async function addPortfoliosParcel(items) {
    return request(`${BASE_URL}/lender/parcel`, {
        method: 'PUT',
        body: JSON.stringify(items),
    });
}

export async function deletePortfoliosParcel(items) {
    return request(`${BASE_URL}/lender/parcel`, {
        method: 'DELETE',
        body: JSON.stringify(items),
    });
}
