import { apiReducers } from '@scc/services';

import createRootSaga from '@shared/store/sagas';
import { watchApiRequest } from '@store/ducks/api';
import { watchEventRequest } from '@store/ducks/event';
import listingsReducer, { watchListings } from '@store/ducks/listings';
import parcelReducer, { watchParcel } from '@store/ducks/parcel';
import stressTestReducer, { watchStressTest } from '@store/ducks/stressTest';

import { watchApiError } from './ducks/apiError';
import authReducer, { watchAuth } from './ducks/auth';
import authMngReducer, { watchAuthMng } from './ducks/authManager';
import benchmarkingReducer, { watchBenchmarking } from './ducks/benchmarking';
import bookmarkReducer, { watchBookmark } from './ducks/bookmark';
import buildingReducer, { watchBuilding } from './ducks/building';
import commonReducer, { watchCommon } from './ducks/common';
import configurationReducers, { configurationWatchers } from './ducks/configuration';
import dashboardReducers, { dashboardWatchers } from './ducks/dashboard';
import demoReducer, { watchDemo } from './ducks/demo';
import detailedPropertyReducers, { detailedPropertyWatchers } from './ducks/detailedProperty';
import documentsReducer, { watchDocuments } from './ducks/documents';
import filterToolbarReducer from './ducks/filterToolbar';
import flowReducer, { flowWatchers } from './ducks/flow';
import gridReducers, { gridWatchers } from './ducks/grid';
import historyReducers, { historyWatchers } from './ducks/history';
import listingReducer, { watchListing } from './ducks/listing';
import loansReducers, { loansWatchers } from './ducks/loans';
import membersReducers, { membersWatchers } from './ducks/members';
import memoryReducers, { watchMemory } from './ducks/memory';
import myPropertyReducer, { watchMyProperties } from './ducks/myProperties';
import notificationsReducer, { watchNotifications } from './ducks/notifications';
import watchReducer, { watchOrder } from './ducks/order';
import portfolioReducers, { financingWatchers } from './ducks/portfolio';
import portfoliosReducer, { watchPortfolio } from './ducks/portfolios';
import propertiesReducers, { propertiesWatchers } from './ducks/properties';
import propertyReducer, { watchPropertyProcess } from './ducks/property';
import referReducer, { watchRefer } from './ducks/refer';
import rentRollReducer, { watchRentRoll } from './ducks/rentRoll';
import similarTransactionsReducer, { watchSimilarTransactions } from './ducks/similarTransactions';
import todosReducer, { watchTodos } from './ducks/todos';
import uiReducer, { uiWatchers } from './ducks/ui';
import underwritingReducers, { analyticsWatchers } from './ducks/underwriting';
import agGridReducer from './ducks/agGrid';

const mainWatchers = [
    watchApiRequest,
    watchApiError,
    watchCommon,
    watchAuth,
    watchAuthMng,
    watchBuilding,
    watchOrder,
    watchPropertyProcess,
    watchMyProperties,
    watchRentRoll,
    watchParcel,
    watchBookmark,
    watchBenchmarking,
    watchDocuments,
    watchPortfolio,
    watchSimilarTransactions,
    watchRefer,
    watchListing,
    watchListings,
    watchStressTest,
    watchMemory,
    watchNotifications,
    watchDemo,
    watchEventRequest,
    watchTodos,
    // watchLocationManager,
];

export const sagas = createRootSaga(
    mainWatchers.concat(
        loansWatchers,
        membersWatchers,
        flowWatchers,
        financingWatchers,
        gridWatchers,
        detailedPropertyWatchers,
        analyticsWatchers,
        dashboardWatchers,
        historyWatchers,
        propertiesWatchers,
        uiWatchers,
        configurationWatchers
    )
);

const sourceReducers = {
    auth: authReducer,
    authMng: authMngReducer,
    building: buildingReducer,
    myProperties: myPropertyReducer,
    property: propertyReducer,
    rentRoll: rentRollReducer,
    parcel: parcelReducer,
    order: watchReducer,
    benchmarking: benchmarkingReducer,
    bookmark: bookmarkReducer,
    common: commonReducer,
    documents: documentsReducer,
    portfolios: portfoliosReducer,
    similarTransactions: similarTransactionsReducer,
    listing: listingReducer,
    listings: listingsReducer,
    refer: referReducer,
    stressTest: stressTestReducer,
    loans: loansReducers,
    ui: uiReducer,
    members: membersReducers,
    portfolio: portfolioReducers,
    underwriting: underwritingReducers,
    flow: flowReducer,
    detailedProperty: detailedPropertyReducers,
    dashboard: dashboardReducers,
    memory: memoryReducers,
    notifications: notificationsReducer,
    demo: demoReducer,
    history: historyReducers,
    properties: propertiesReducers,
    todos: todosReducer,
    configuration: configurationReducers,
    agGrid: agGridReducer,
    grid: gridReducers,
    filterToolbar: filterToolbarReducer,
    ...apiReducers,
};

export type TReducers = typeof sourceReducers;

export const reducers = Object.keys(sourceReducers)
    .sort()
    .reduce((obj, key) => {
        const result = { ...obj };

        result[key] = sourceReducers[key];

        return result;
    }, {});
