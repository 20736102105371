import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { v1 as uuid } from 'uuid';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@scc/index';
import {
    COLUMN_TYPE_OPTIONS,
    COUNT_OPTIONS,
    DOCUMENT_TYPE_OPTIONS,
    generateLoanTableOptions,
    generateRentRollComparisonOptions,
    generateScenariosOptions,
    getInfoMessage,
    getTemplatesTabs,
    isRequiredName,
    OPTIONS_TYPES,
    TEMPLATES_ID,
    transformDocumentForSelect,
    transformVariantsReportForSelect,
    TYPES,
    UNIT_MIX_OPTIONS,
    VIEW,
} from '@scc/modules/ExportExcelReport/helpers';
import { getTabItems } from '@scc/modules/ExportExcelReport/helpers/helpers';
import { selectDetailedCombinedOsID } from '@scc/store/ducks/detailedProperty/overview';
import { loadVarianceReports, selectVariancesReports } from '@scc/store/ducks/portfolio/varianceReport';
import {
    loadFinancingScenarios,
    selectFinancingScenariosState,
} from '@scc/store/ducks/underwriting/financingScenarios';

import { exportReportApi, loansApi } from '@api';
import { DOCUMENT_TYPES } from '@constants/documents';
import { EXPORT_EXCEL_REPORT } from '@constants/modals';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { showPageDrawer } from '@store/ducks/ui/drawer';

import {
    Column,
    ExcelReportStore,
    HeaderSelects,
    MappedColumn,
    SelectedTabs,
    Tab,
    TabOptions,
    Template,
    TProperty,
} from './types';

const entity = '[excelReport]';

const isReadyDocuments = (document) =>
    /(CONFIRMED|PROCESSED)/gim.test(document.status) || document.type === 'STATEMENT_COMB';

const initialState: ExcelReportStore = {
    group: '',
    document: null,
    template: {} as Template,
    headers: {},
    view: VIEW.main,
    activeProperty: null,
    parcels: [],
    tabs: [],
    documents: [],
    loading: false,
    loaded: true,
    loadingTemplate: false,
    loadedTemplate: false,
    customTemplates: [],
    selectedTabs: {} as SelectedTabs,
    modelId: null,
    customScenarios: [],
    loanId: null,
    loans: [],
    isTemplateChanged: false,
    userId: null,
    unitMix: '',
    property: {} as TProperty,
    rentRollMetrics: {} as never,
    userLoanModelDates: [],
};

const excelReportFLowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        clean: () => initialState,
        init(state, action: PayloadAction<{ docId: string; property: TProperty; userId: string; unitMix: string }>) {
            state.unitMix = action.payload.unitMix;
            state.template = initialState.template;
            const {
                property: { parcels, ...property },
            } = action.payload;

            const activeProperty = parcels?.findIndex(
                (parcel) => property?.countyId === parcel.countyid && property?.parcelId === parcel.parcelid
            );

            state.activeProperty = Math.max(activeProperty, 0);
            state.parcels = parcels;
            state.property = property as TProperty;
            state.document = action.payload.docId;
            state.userId = action.payload.userId;
        },
        loadDocuments(state) {
            state.loading = true;
            state.loaded = false;
        },
        setDocuments(state, action) {
            state.documents = action.payload.filter(isReadyDocuments);
            state.loading = false;
            state.loaded = true;
        },
        setActiveProperty(state, action) {
            state.activeProperty = action.payload;
        },
        loadHeaders(state) {
            state.loading = true;
            state.loaded = false;
        },
        setHeaders(state, action) {
            state.headers = action.payload.reduce((acc, { year, ...cur } = {} as Column) => {
                if (String(year) in acc) {
                    acc[String(year)].push({
                        year,
                        ...cur,
                    });
                } else {
                    acc[String(year)] = [{ year, ...cur }];
                }
                return acc;
            }, {});
            state.loading = false;
            state.loaded = true;
        },
        setTemplate(state, action) {
            const { group, tabs, ...payload } = action.payload;
            if (group !== undefined) {
                state.group = group;
            }

            const customTemplate = state.customTemplates?.find(({ id }) => id === payload.id);
            state.tabs = getTemplatesTabs({
                customControls: customTemplate,
                template: action.payload,
                tabs,
            });

            state.template = payload;
            state.loadingTemplate = false;
            state.loadedTemplate = true;
            state.isTemplateChanged = false;
        },
        updateTemplate(state, action) {
            state.isTemplateChanged = true;
            state.template = { ...state.template, ...action.payload };
        },
        setView(state, action) {
            state.view = action.payload || VIEW.main;
        },
        selectNewTabs(state, action) {
            const { key } = action.payload || {};
            if (Array.isArray(state.selectedTabs)) state.selectedTabs = {};
            const isTabSelected = key in state.selectedTabs;

            if (isTabSelected) {
                delete state.selectedTabs[key];
            } else {
                state.selectedTabs[key] = action.payload;
            }
        },
        unSelectNewTabs(state) {
            state.selectedTabs = initialState.selectedTabs;
        },
        createNewTabs(state) {
            state.isTemplateChanged = true;
        },
        setTabs(state, action) {
            state.tabs = action.payload || [];
        },
        deleteTab(state, action) {
            state.tabs.splice(action.payload.index, 1);
            state.isTemplateChanged = true;
        },
        updateTabs(state, action) {
            const tab = state.tabs[action.payload.row] || {};
            const items = tab.options || tab.items;
            const selectOptions = items?.[action.payload.col] || {};

            state.isTemplateChanged = true;

            items?.splice(action.payload.col, 1, { ...selectOptions, value: action.payload.value } as TabOptions);

            if (action.payload.name === 'documents') {
                const [typeOption] = items || [];
                if (state.document === action.payload.value) {
                    tab.options = [
                        typeOption,
                        { name: 'count', value: '0' },
                        { name: 'columnsType', value: 'all' },
                        {
                            name: 'column',
                            multiple: true,
                            filter: [
                                ['count', 'count'],
                                ['columnsType', 'type'],
                            ],
                        },
                    ];
                } else {
                    const document = action.payload.options.find(({ value }) => value === action.payload.value);
                    const isCombinedRR = document?.type === DOCUMENT_TYPES.RR && document?.combined === true;

                    tab.options = [
                        typeOption,
                        { name: 'type', value: 'standardized', filteredOptions: isCombinedRR ? ['original'] : [] },
                    ];
                }
            }
        },
        loadCustom() {},
        saveCustom(state) {
            state.loadingTemplate = true;
            state.loadedTemplate = false;
        },
        setCustom(state, action) {
            state.customTemplates = action.payload?.map(({ id, name, templateJson, ...rest }) => ({
                id,
                name,
                items: JSON.parse(templateJson),
                ...rest,
            }));
            state.loadingTemplate = false;
            state.loadedTemplate = true;
        },
        deleteCustom(state, action) {
            state.customTemplates = state.customTemplates.filter((template) => template.id !== action.payload);
        },
        loadCustomScenarios() {},
        loadLoans() {},
        setLoans(state, action) {
            state.loans = action.payload;
        },
        setCustomScenarios(state, action) {
            if (Array.isArray(action.payload)) {
                state.customScenarios = action.payload;
            }
        },
        loadRentRollMetrics() {},
        setRentRollMetrics(state, action) {
            state.rentRollMetrics = action.payload;
        },
        loadUsersLoanModelDates() {},
        setUsersLoanModelDates(state, action) {
            state.userLoanModelDates = action.payload;
        },
        resetTemplate(state) {
            state.template = initialState.template;
        },
    },
});

export const {
    init: initExcelReportFlow,
    loadHeaders: loadHeadersExcelReportFlow,
    loadDocuments: loadDocumentsExcelReportFlow,
    setActiveProperty: setActivePropertyExcelReportFlow,
    setHeaders: setHeadersExcelReportFlow,
    setDocuments: setDocumentsExcelReportFlow,
    setTemplate: setTemplateExcelReportFlow,
    updateTemplate: updateTemplateExcelReportFlow,
    setView: setViewExcelReportFlow,
    selectNewTabs: selectNewTabExcelReportFlow,
    unSelectNewTabs: unSelectNewTabsExcelReportFlow,
    createNewTabs: createNewTabsExcelReportFlow,
    setTabs: setTabsExcelReportFlow,
    updateTabs: updateTabsExcelReportFlow,
    deleteTab: deleteTabExcelReportFlow,
    clean: cleanExcelReportFlow,
    loadCustom: loadCustomExcelReportFlow,
    deleteCustom: deleteCustomExcelReportFlow,
    setCustom: setCustomExcelReportFlow,
    saveCustom: saveCustomExcelReportFlow,
    loadCustomScenarios: loadCustomScenariosExcelReportFlow,
    setCustomScenarios: setCustomScenariosExcelReportFlow,
    loadLoans: loadLoansExcelReportFlow,
    setLoans: setLoansExcelReportFlow,
    resetTemplate: resetTemplateExcelReportFlow,
    loadRentRollMetrics: loadRentRollMetricsExcelReportFlow,
    setRentRollMetrics: setRentRollMetricsExcelReportFlow,
    loadUsersLoanModelDates: loadUsersLoanModelDatesExcelReportFlow,
    setUsersLoanModelDates: setUsersLoanModelDatesExcelReportFlow,
} = excelReportFLowSlice.actions;

export default excelReportFLowSlice.reducer;

export const selectExcelReportState = ({ flow }: RootState): ExcelReportStore => flow.excelReport;

export const selectExcelReportCombinedOsId = createSelector(selectExcelReportState, ({ documents }) => {
    return documents.find(({ type }) => type === DOCUMENT_TYPES.COMBINED)?.id;
});

export const selectPropertyOverviewPayload = createSelector(selectExcelReportState, ({ documents, document }) => {
    const [rr, prevRR] = documents.filter(({ type }) => type === DOCUMENT_TYPES.RR);
    const [os] = documents.filter(({ type }) => type === DOCUMENT_TYPES.OS);
    return {
        rrId: rr?.id,
        prevRrid: prevRR?.id,
        osid: os?.id,
        combinedOsid: document,
    };
});

export const selectCustomControlsToExport = ({ flow: { excelReport } }) =>
    excelReport.customTemplates?.find(({ id }) => id === excelReport.template?.id);

export const selectCurrentTabs = ({ flow: { excelReport } }) => excelReport.tabs;

export const selectPropertiesExcelReportState = createSelector(
    selectExcelReportState,
    ({ property, parcels, activeProperty }) => {
        if (Number.isInteger(activeProperty) && parcels?.[activeProperty!]) {
            const { propertytype: propertyType, ...rest } = parcels[activeProperty!];
            return { ...property, ...rest, propertyType };
        }
        return null;
    }
);

export const selectHeadersExcelReport = createSelector(
    [selectExcelReportState, selectFinancingScenariosState, selectVariancesReports],
    (
        { documents, headers, customScenarios, loans, rentRollMetrics, userLoanModelDates }: ExcelReportStore,
        { data: models }: { data: Record<string, unknown>[] },
        reports: Record<string, unknown>[]
    ) => ({
        [OPTIONS_TYPES.MODEL_ID]: models.map(({ name, id }) => ({ label: name, value: id })),
        [OPTIONS_TYPES.LOAN_ID]: loans?.map(({ loanName, id }) => ({ label: loanName, value: id })),
        [OPTIONS_TYPES.YEAR]: sortBy(
            Object.keys(headers)?.map((year) => ({ label: year, value: String(year) })),
            (header) => -header.value
        ),
        [OPTIONS_TYPES.TYPE]: DOCUMENT_TYPE_OPTIONS,
        [OPTIONS_TYPES.DOCUMENTS]: documents.map(transformDocumentForSelect),
        [OPTIONS_TYPES.VARIANCE_REPORT_ID]: reports.map(transformVariantsReportForSelect),
        [OPTIONS_TYPES.COMBINED_ID]: documents
            .filter(({ type }) => type === DOCUMENT_TYPES.COMBINED)
            .map(transformDocumentForSelect),
        [OPTIONS_TYPES.RR_ID]: documents
            .filter(({ type }) => type === DOCUMENT_TYPES.RR)
            .map(transformDocumentForSelect),
        [OPTIONS_TYPES.SCENARIOS]: generateScenariosOptions(customScenarios),
        [OPTIONS_TYPES.COUNT]: COUNT_OPTIONS,
        [OPTIONS_TYPES.COLUMNS_TYPE]: COLUMN_TYPE_OPTIONS,
        [OPTIONS_TYPES.PERIODS]: Object.entries(headers).reduce((acc, [key, value]) => {
            acc[key] = sortBy(
                (value as unknown as Column[])?.map((item) => ({
                    label: `${item?.count} month ending ${item?.month} ${item?.year ?? ''} ${item?.dateLabel}`,
                    value: String(item?.column),
                })) || [],
                (period) => -period.value
            );
            return acc;
        }, {}),
        [OPTIONS_TYPES.COLUMN]: Object.entries(headers)
            .reduce<MappedColumn[]>((acc, [key, value]) => {
                const mappedColumns: MappedColumn[] = Array.isArray(value)
                    ? value
                          .map((item: Column) => ({
                              label: `${item?.count} month ending ${item?.month} ${item?.year ?? ''} ${
                                  item?.annualizedFrom ? 'Annualized' : ''
                              } ${item?.dateLabel}`,
                              value: item?.column,
                              type: item?.dateLabel?.toLowerCase(),
                              columnDate: dayjs(`${key}-${item.month}-15`, 'YYYY-MMM-D').endOf(
                                  'month'
                              ) as unknown as Date,
                              count: item?.count,
                              item,
                          }))
                          .sort((a, b) => a.count - b.count)
                    : [];

                return [...acc, ...mappedColumns];
            }, [])
            .sort((a, b) => b.count! - a.count!),
        [OPTIONS_TYPES.UNIT_MIX_TYPE]: UNIT_MIX_OPTIONS,
        [OPTIONS_TYPES.RENT_ROLL_COMPARISON]: generateRentRollComparisonOptions(documents, rentRollMetrics),
        [OPTIONS_TYPES.RENT_ROLL_SECOND_COMPARISON]: generateRentRollComparisonOptions(documents, rentRollMetrics),
        [OPTIONS_TYPES.LOAN_TABLE_MODEL_DATE]: generateLoanTableOptions(userLoanModelDates),
    })
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const selectTemplateTabsExcelReport = createSelector(
    [selectExcelReportState, selectHeadersExcelReport],
    ({ tabs = [], documents = [] }: ExcelReportStore, headerSelects: HeaderSelects) =>
        tabs?.map(({ options, ...tab }) => {
            if (!options) return tab;

            const items = getTabItems({
                options,
                tab,
                headerSelects,
            });

            const document = items.find(({ name }) => name === 'documents' || name === 'rrId');

            const infoMessage = documents?.filter((el) => el?.type === document?.type).length
                ? null
                : getInfoMessage({ type: tab.filter, tabId: tab.id });

            return {
                ...tab,
                items,
                infoMessage,
            };
        })
);

export const selectToSaveTemplateTabsExcelReport = createSelector(selectTemplateTabsExcelReport, (tabs) =>
    tabs?.map(({ items, ...tab }) => ({
        ...tab,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        options: items?.map(({ options, value, ...item }) => (value === 'nodata' ? item : { value, ...item })),
    }))
);

export const selectTabByTemplateExcelReport = createSelector(
    selectToSaveTemplateTabsExcelReport,
    (tabs) =>
        tabs
            ?.find(
                ({ filter, options }) =>
                    /(STATEMENT)/i.test(filter!) && options?.find(({ name }) => /periods|count/i.test(name))
            )
            ?.options?.find(({ name }) => /periods|count/i.test(name)) || {}
);

export const selectTemplateForExportDocumentPreview = createSelector(
    selectExcelReportState,
    selectToSaveTemplateTabsExcelReport,
    selectTabByTemplateExcelReport,
    selectPropertyOverviewPayload,
    (
        { document, template, activeProperty, parcels, property: { countyid, parcelId } = {} as TProperty },
        tabs,
        templateTab,
        overview
    ) => {
        const templateTabs = tabs
            ?.filter(
                ({ options }) =>
                    !options?.some(({ name, value, isRequired = true }) => {
                        if (!isRequired) {
                            return false;
                        }

                        return isRequiredName(name) && (value === 'nodata' || !value);
                    })
            )
            ?.map(({ id, options }) => ({
                id,
                options: options?.filter(({ name, hidden }) => !hidden || isRequiredName(name)),
            }));

        const overviewPayload = templateTabs.some(({ id }) => id === TEMPLATES_ID.propertyOverview) ? overview : {};
        return {
            value: templateTab?.value,
            payload: {
                ...overviewPayload,
                parcelId: parcels?.[activeProperty!]?.parcelid || parcelId,
                countyId: parcels?.[activeProperty!]?.countyid || countyid,
                templateId: template?.id,
                templateName: template?.name,
                template: templateTabs,
            },
            document,
        };
    }
);

export const selectIsReadyTemplateForExportDocumentPreview = createSelector(
    selectTemplateForExportDocumentPreview,
    ({ document, payload, value }) => !isEmpty(payload.template) || (!!document && typeof value !== 'undefined')
);

function* initExcelReportFlowSaga({ payload }) {
    yield delay(300);
    if (payload.docId) {
        yield put(loadHeadersExcelReportFlow(payload.docId));
        yield put(loadCustomScenariosExcelReportFlow(payload.docId));
    }

    yield put(loadLoansExcelReportFlow({ ...(payload.property ?? {}), userId: payload.userId }));
    const { data: models } = yield select(selectFinancingScenariosState);
    if (!models?.length) {
        yield put(loadFinancingScenarios(payload.property));
    }
    yield put(loadDocumentsExcelReportFlow({ ...payload.property, userId: payload.userId }));
    yield put(loadCustomExcelReportFlow());
    yield put(loadRentRollMetricsExcelReportFlow(payload.property.rentRollLoanId));
    yield put(loadUsersLoanModelDatesExcelReportFlow());

    yield put(
        loadVarianceReports({
            parcelId: payload.property.parcelId,
            countyId: payload.property.countyId,
        } as never)
    );
    yield put(
        showPageDrawer({
            content: EXPORT_EXCEL_REPORT,
            data: { title: 'Excel Export' },
            header: null,
        })
    );
}

function* loadHeadersExcelReportFlowSaga({ type, payload }) {
    yield put(apiRequest(payload, exportReportApi.getSlatHeadersExcel, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setHeadersExcelReportFlow(action.payload.data));
}

function* loadDocumentsExcelReportFlowSaga({ type, payload }) {
    const { parcelid, parcelId = parcelid, countyid, countyId = countyid, userId } = payload;

    yield put(apiRequest({ parcelId, countyId, userId }, exportReportApi.getExcelDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    const combinedOsId = yield select(selectDetailedCombinedOsID);

    if (!combinedOsId) {
        const combinedIdFromDocuments = action.payload.data.find(
            ({ type: documentType }) => documentType === DOCUMENT_TYPES.COMBINED
        )?.id;

        if (combinedIdFromDocuments) {
            yield put(loadHeadersExcelReportFlow(combinedIdFromDocuments));
            yield put(loadCustomScenariosExcelReportFlow(combinedIdFromDocuments));
        }
    }

    yield put(setDocumentsExcelReportFlow(action.payload.data));
}

function* loadLoansExcelReportFlowSaga({ type, payload }) {
    const { parcelid, parcelId = parcelid, countyid, countyId = countyid, userId } = payload;
    yield put(apiRequest({ parcelId, countyId, userId }, loansApi.getLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setLoansExcelReportFlow(action.payload.data.content));
}

function* createNewTabsExcelReportFlowSaga() {
    const { view, tabs, selectedTabs }: ExcelReportStore = yield select(selectExcelReportState);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const createdTabs = Object.values(selectedTabs).map(({ _, ...tab }) => {
        const key = `${uuid()}-${tabs?.length ?? 0}`;

        return { ...tab, key };
    });

    const newTabs = (tabs ?? []).concat(createdTabs as unknown as Tab[]);

    yield put(setTabsExcelReportFlow(newTabs));
    yield put(unSelectNewTabsExcelReportFlow());
    yield put(setViewExcelReportFlow(view === VIEW.create ? VIEW.create : VIEW.main));
}

function* saveCustomExcelReportFlowSaga({ type, payload: saveAsNew }) {
    const { template, customTemplates } = yield select(selectExcelReportState);
    const tabs = yield select(selectToSaveTemplateTabsExcelReport);
    const customTemplateIndex = customTemplates?.findIndex(({ id }) => id === template.id);
    if (customTemplateIndex >= 0 && !saveAsNew) {
        yield put(
            apiRequest(
                { id: template.id, name: template.name, template: tabs },
                exportReportApi.updateExcelTemplate,
                type
            )
        );
    } else {
        yield put(apiRequest({ name: template.name, template: tabs }, exportReportApi.createExcelTemplate, type));
    }

    const action = yield take(`${type} ${API_SUCCESS}`);
    const { id, name } = action.payload.data || {};

    yield put(setTemplateExcelReportFlow({ id, name, group: TYPES.custom, tabs }));
    yield put(loadCustomExcelReportFlow());
}

function* loadCustomExcelReportFlowSaga({ type }) {
    yield put(apiRequest(undefined, exportReportApi.getExcelTemplate, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setCustomExcelReportFlow(action.payload?.data));
}

function* deleteCustomExcelReportFlowSaga({ type, payload }) {
    if (!payload) return;

    yield put(resetTemplateExcelReportFlow());
    yield put(apiRequest(payload, exportReportApi.deleteExcelTemplate, type));
    yield take(`${type} ${API_SUCCESS}`);
}

function* setActivePropertyExcelReportFlowSaga({ payload }) {
    const { parcels } = yield select(selectExcelReportState);
    const parcel = parcels[payload];
    if (parcel) {
        yield put(loadFinancingScenarios({ parcelId: parcel.parcelid, countyId: parcel.countyid }));
        yield put(loadDocumentsExcelReportFlow(parcel));
        yield put(loadLoansExcelReportFlow({ parcelId: parcel.parcelid, countyId: parcel.countyid } as never));
        yield put(loadVarianceReports({ parcelId: parcel.parcelid, countyId: parcel.countyid } as never));
    }
}

function* loadCustomScenariosExcelReportFlowSaga({ type, payload }) {
    yield put(apiRequest(payload, exportReportApi.getCustomScenarios, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setCustomScenariosExcelReportFlow(action.payload?.data));
}

function* loadRentRollMetricsExcelReportFlowSaga({ payload, type }) {
    yield put(apiRequest(payload, exportReportApi.getRentRollMetrics, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    if (action.payload?.data) {
        yield put(setRentRollMetricsExcelReportFlow(action.payload.data));
    }
}

function* loadUsersLoanModelDatesExcelReportFlowSaga({ type }) {
    yield put(apiRequest(undefined, loansApi.getUserLoansModelDates, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    if (action.payload?.data) {
        yield put(setUsersLoanModelDatesExcelReportFlow(action.payload.data));
    }
}

export function* watchExcelReportFlow() {
    yield takeLatest(initExcelReportFlow, initExcelReportFlowSaga);
    yield takeEvery(createNewTabsExcelReportFlow, createNewTabsExcelReportFlowSaga);
    yield takeLatest(loadHeadersExcelReportFlow, loadHeadersExcelReportFlowSaga);
    yield takeLatest(loadDocumentsExcelReportFlow, loadDocumentsExcelReportFlowSaga);
    yield takeLatest(loadLoansExcelReportFlow, loadLoansExcelReportFlowSaga);
    yield takeLatest(loadCustomExcelReportFlow, loadCustomExcelReportFlowSaga);
    yield takeLatest(saveCustomExcelReportFlow, saveCustomExcelReportFlowSaga);
    yield takeLatest(deleteCustomExcelReportFlow, deleteCustomExcelReportFlowSaga);
    yield takeLatest(loadUsersLoanModelDatesExcelReportFlow, loadUsersLoanModelDatesExcelReportFlowSaga);
    yield takeEvery(loadCustomScenariosExcelReportFlow, loadCustomScenariosExcelReportFlowSaga);
    yield takeEvery(setActivePropertyExcelReportFlow, setActivePropertyExcelReportFlowSaga);
    yield takeLatest(loadRentRollMetricsExcelReportFlow, loadRentRollMetricsExcelReportFlowSaga);
}
