import { isValidElement, cloneElement, useRef } from 'react';
import { useSelector } from 'react-redux';

import { reloadable } from '@utils/components';
import { stringOrFunctionChecker } from '@utils/index';

import { selectCommonState } from '@scc/store/ducks/common';

import useDebouncedEffect from '@shared/hooks/useDebouncedEffect';
import useQueryParams from '@shared/hooks/useQueryParams';

const PageLoader = reloadable(() => import('@components/PageLoader'));
const NoContentPlaceholder = reloadable(() => import('@scc/components/NoContentPlaceholder'));

function withNoContentPlaceholder(
    { placeholder: Placeholder, showLoaderOnce, searchProps, ...options } = {},
    { placeholderProps = {} } = {}
) {
    return ({
        skipPlaceholder,
        showContentIfEmpty = false,
        hasData,
        loaded,
        loading = false,
        onClick,
        customOptions = {},
        disableLoader = options.disableLoader ?? false,
        children = null,
    }) => {
        const hideLoader = useRef(showLoaderOnce ? !loading && loaded : disableLoader);
        const [, search] = useQueryParams();
        const { menuOpen } = useSelector(selectCommonState);
        const offset = menuOpen ? '200px' : '70px';

        const props = {
            placeholder: !hideLoader.current && (
                <PageLoader {...placeholderProps} offsetLeft={placeholderProps.offsetLeft ?? offset} />
            ),
            noContent: !hasData && (loaded ?? !loading),
        };

        if (props.noContent) {
            const searchPlaceholderProps = search ? searchProps : {};
            props.placeholder = Placeholder || (
                <NoContentPlaceholder
                    {...options}
                    {...customOptions}
                    {...searchPlaceholderProps}
                    {...placeholderProps}
                    onClick={onClick}
                />
            );
        } else if (!loading && loaded) {
            props.placeholder = null;
        }

        const childrenToRender = isValidElement(children)
            ? cloneElement(children, props)
            : stringOrFunctionChecker(children, props);

        useDebouncedEffect(
            () => {
                if (showLoaderOnce && !hideLoader.current) {
                    hideLoader.current = true;
                }
                if (!showLoaderOnce && props.noContent) {
                    hideLoader.current = false;
                }
            },
            [showLoaderOnce, hideLoader.current, props.noContent],
            600
        );

        return (
            <>
                {(!props.placeholder || showContentIfEmpty) && childrenToRender}
                {!!props.placeholder && !skipPlaceholder ? props.placeholder : null}
            </>
        );
    };
}

export default withNoContentPlaceholder;
