/* eslint-disable import/no-extraneous-dependencies */
import cx from 'classnames';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { heroData } from '@scc/pages/Home/compnents/Hero/helpers';
import links from '@scc/router/links';

import Button from '@ui/Button';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import useIsomorphicLayoutEffect from '@shared/hooks/useLayoutEffect';

import styles from './styles.module.scss';
import videoSrc from './video/Buildings.mp4';

const { features, actions } = heroData;

if (typeof window !== 'undefined') {
    gsap.registerPlugin(CustomEase);
}

const sloganPath =
    // eslint-disable-next-line max-len
    'M0,0 C0.25,0 0.313,-0.112 0.376,0.058 0.426,0.194 0.486,0.276 0.536,0.486 0.602,0.71 0.758,0.906 0.808,0.96 0.901,1.06 0.89,1.054 1,1 ';

function Hero({ mobile }) {
    const location = useLocation();
    const ref = useRef();
    const sloganRef = useRef();
    const buttonRef = useRef();
    const servicesRef = useRef();
    const videoRef = useRef();

    useIsomorphicLayoutEffect(() => {
        const tl = gsap.timeline({ delay: 1.5 });

        const ctx = gsap.context(() => {
            // label slogan
            gsap.set(sloganRef.current, {
                y: (t, { offsetParent, clientHeight, offsetTop }) =>
                    (offsetParent.clientHeight - clientHeight) / 2 - offsetTop,
                x: (t, { clientWidth, offsetParent, offsetLeft }) =>
                    (offsetParent.clientWidth - clientWidth) / 2 - offsetLeft,
            });
            tl.to(
                sloganRef.current,

                {
                    keyframes: [
                        {
                            x: 0,
                            textAlign: 'left',
                            duration: 1,
                            ease: CustomEase.create('custom', sloganPath),
                        },
                        { y: 0, duration: 1.2, ease: 'elastic.out(1, 1)', delay: 0.05 },
                    ],
                }
            );
            // label video
            tl.fromTo(
                videoRef.current,
                {
                    x: '100%',
                    scale: 1,
                },
                {
                    duration: 1.1,
                    x: 0,
                    ease: 'elastic.out(1.1, 0.75)',
                },
                '<0.5'
            );
            // label services
            tl.fromTo(
                servicesRef.current,
                {
                    x: (index, target) => -(target.clientWidth + 80),
                },
                { duration: 0.5, x: 0, ease: 'elastic.out(1, 1.1)' },
                '<50%'
            );
            // label button
            tl.fromTo(buttonRef.current, { opacity: 0 }, { duration: 0.35, opacity: 1 }, '<');
        }, ref);

        return () => ctx.revert();
    }, [videoRef]);

    return (
        <div className={styles.hero} ref={ref}>
            <div className={styles.wrapper}>
                <div className={styles.infoWrapper}>
                    <Typography
                        classes={{ root: styles.sliderTextWrapper }}
                        color={colors['deepBlue-500']}
                        component='div'
                        ref={sloganRef}
                    >
                        <Typography classes={{ root: styles.sliderText }}>Dream Bigger</Typography>
                        <div className={styles.aWrapper}>
                            <div className={cx(styles.aWords, styles.aWords1)}>
                                <Typography classes={{ root: styles.sliderText }} component='span'>
                                    Invest
                                </Typography>
                                <Typography classes={{ root: styles.sliderText }} component='span'>
                                    Finance
                                </Typography>
                            </div>
                            <Typography classes={{ root: styles.sliderText }} component='span'>
                                Smarter
                            </Typography>
                        </div>
                    </Typography>

                    <div className={styles.servicesWrapper}>
                        <ul className={styles.featuresList} ref={servicesRef}>
                            {features.map(({ title }) => (
                                <li key={title}>
                                    <Typography color={colors['darkNeutral-500']} variant='h3r'>
                                        {title}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className={styles.actionsWrapper}>
                        <Button
                            aria-label={actions.signUp}
                            component={Link}
                            fullWidth={mobile}
                            ref={buttonRef}
                            rel='nofollow'
                            size={mobile ? 'extra-large' : 'large'}
                            to={{
                                state: { from: location },
                                pathname: links.register.path,
                            }}
                        >
                            {actions.signUp}
                        </Button>
                    </div>
                </div>
                <div className={styles.imgWrapper}>
                    <video className={styles.bgVideo} ref={videoRef} autoPlay loop muted playsInline>
                        <source src={videoSrc} type='video/mp4' />
                    </video>
                </div>
            </div>
        </div>
    );
}

export default Hero;
