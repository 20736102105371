import { forwardRef } from 'react';

import FormControl from '@ui/FormControl';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputAdornment from '@ui/InputAdornment';

import colors from '@themes/palette/export.module.scss';

import DateSvg from '@icons/outlined/calendar.svg';

import styles from './styles.module.scss';

const DatePickerInput = forwardRef(
    ({ variant = 'outlined', label, labelProps, placeholder, InputProps, name, fullWidth, ...props }, ref) => (
        <FormControl variant={variant} fullWidth>
            <Input
                InputProps={{
                    className: styles.input,
                    endAdornment: (
                        <InputAdornment className={styles.inputAdornment} position='end'>
                            <Icon color={colors['grey-400']} component={DateSvg} />
                        </InputAdornment>
                    ),
                    ...InputProps,
                }}
                fullWidth={fullWidth}
                inputRef={ref}
                label={label}
                name={name}
                placeholder={placeholder}
                type='text'
                variant={variant}
                {...props}
            />
        </FormControl>
    )
);

export default DatePickerInput;
