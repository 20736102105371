import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { dashboardApi } from '@api';

const entity = '[dashboardProperties]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const propertiesSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadProperties, setData: setProperties } = propertiesSlice.actions;

export default propertiesSlice.reducer;

export const selectPropertiesState = (store) => store.dashboard.properties;

function* loadPropertiesSaga({ type, payload }) {
    yield put(apiRequest(payload, dashboardApi.getProperties, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const data = action.payload.data.map(({ address, propertyName, ...props }) => {
        const [firstAddressPart] = address?.split(', ');
        return {
            propertyName: propertyName ?? firstAddressPart,
            address,
            ...props,
        };
    });
    yield put(setProperties(data));
}

export function* watchProperties() {
    yield takeLatest(loadProperties, loadPropertiesSaga);
}
