import * as yup from 'yup';

export const patterns = {
    words: /\w+$/,
    textField: /^[A-Za-z-_ ]*$/,
    minLength: /^.{8,}/,
    notEmpty: /^.{0,}/,
    maxLength: /^.{8,32}$/,
    upper: /[A-Z]/,
    lower: /[a-z]/,
    space: /[^\s]/,
    number: /^\d+$/,
    decimalNumber: /^\d+(\.\d+)?$/,
    atLeastOneNumber: /(?=.*\d)/,
    special: /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
    phone: /^$|^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    // eslint-disable-next-line max-len
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const email = yup.string().matches(patterns.email, 'Email is incorrect').required('Email is required');

const phone = yup.string().nullable().matches(patterns.phone, 'Phone number is not valid');

const password = yup.string().required('Password is required');

const dueDate = yup
    .date()
    .nullable()
    .transform((curr, orgn) => (orgn === '' ? null : curr))
    .required('Date is required');
const radioBtn = yup.string().required('A radio option is required');

const select = yup
    .string()
    .transform((curr, orgn) => {
        const selected = Array.isArray(curr) ? curr.join(',') : curr;
        return orgn === '' ? null : selected;
    })
    .nullable()
    .matches(patterns.words, 'Field is required');

const firstName = yup
    .string()
    .matches(patterns.textField, 'Please enter valid First Name')
    .min(2, 'Must be at least 2 characters')
    .max(40, 'Must be no more than 40 characters')
    .required('First Name is required');

const string = yup
    .string()
    .matches(patterns.textField, 'Please enter letters of the alphabet')
    .nullable()
    .min(2, 'Must be at least 2 characters')
    .max(30, 'Must be no more than 40 characters');

const baseNumber = yup.string().nullable().matches(patterns.number, 'Please enter numbers');

const decimalNumber = yup.string().nullable().matches(patterns.decimalNumber, 'Please enter numbers');

const number = baseNumber.min(1, 'Must be at least 1 number').max(15, 'Must be no more than 15 numbers');

const lastName = yup
    .string()
    .matches(patterns.textField, 'Please enter valid Last Name')
    .min(2, 'Must be at least 2 characters')
    .max(40, 'Must be no more than 40 characters')
    .required('Last Name is required');

const newPassword = yup
    .string()
    .required('Password is required')
    .test('minLength', 'Password must be minimum 8 characters long', (value) => patterns.minLength.test(value))
    .test('maxLength', 'Password must be maximum 32 characters long', (value) => patterns.maxLength.test(value))
    .test('upper', 'Password must contain uppercase letters', (value) => patterns.upper.test(value))
    .test('lower', 'Password must contain lowercase letters', (value) => patterns.lower.test(value))
    .test('space', 'Password must not contain spaces', (value) => patterns.space.test(value))
    .test('atLeastOneNumber', 'Password must contain numbers', (value) => patterns.atLeastOneNumber.test(value))
    .test('special', 'Password must contain special characters', (value) => patterns.special.test(value));

const confirmPassword = (ref) =>
    yup
        .string()
        .oneOf([yup.ref(ref), null], 'Passwords must match')
        .required('Confirm Password is required');

const textField = yup
    .string()
    .test('empty-check', 'Text field cannot be empty', (value) => patterns.minLength.test(value));

const notEmpty = yup
    .string()
    .nullable()
    .test('empty-check', 'Text field cannot be empty', (value) => patterns.notEmpty.test(value));

export const validation = {
    email,
    dueDate,
    phone,
    password,
    newPassword,
    confirmPassword,
    string,
    baseNumber,
    decimalNumber,
    number,
    firstName,
    lastName,
    radioBtn,
    select,
    textField,
    notEmpty,
};
