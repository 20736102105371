import { getMapValue } from '@utils/components';
import SharePropertyForm from '@shared/templates/forms/components/SharePropertyForm';
import SuccessPropertyForm from '@shared/templates/forms/components/SharePropertyFormSuccess';

export const TEMPLATES = {
    MAIN: 'main',
    SUCCESS: 'success',
};

const DEFAULT_TEMPLATE = TEMPLATES.MAIN;

const authFlowMap = new Map([
    [TEMPLATES.MAIN, SharePropertyForm],
    [
        TEMPLATES.SUCCESS,
        ({ ...props }) => <SuccessPropertyForm description='You’ve successfully shared link' {...props} />,
    ],
]);

const ShareProperty = function ({ onSuccess, loading, template, address, link }) {
    const Form = getMapValue(authFlowMap, template, DEFAULT_TEMPLATE);

    return <Form address={address} link={link} onSuccess={onSuccess} loading={loading} />;
};

export default ShareProperty;
