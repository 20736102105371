import cx from 'classnames';

import styles from '@scc/pages/Property/property.module.scss';

import Skeleton from '@ui/Skeleton';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

function PropertyAddress({ isLoaded, address, city }) {
    return (
        <div className={styles.searchContainer} id='searchPropertyContainer'>
            <div className={styles.addressWrapper}>
                <div className={styles.addressContainer}>
                    <Typography color={colors['darkNeutral-500']} component='span' variant='h3b'>
                        {isLoaded ? address : <Skeleton width={150} />}
                    </Typography>
                </div>
                <Typography className={cx(styles.city, styles.visible)} color={colors['grey-400']} variant='h6r'>
                    {isLoaded ? city : <Skeleton width={200} />}
                </Typography>
            </div>
        </div>
    );
}

export default PropertyAddress;
