import * as yup from 'yup';
import { validation } from '@utils/forms';

const schema = yup.object({
    email: validation.email,
    firstName: validation.firstName,
    lastName: validation.lastName,
    companyName: yup.string(),
    phone: validation.phone.required('Phone number is required'),
});

export default schema;
