import { isBrowser, openExternalWindow } from '@utils/browser';
import { newWindowSvgString } from '@utils/charts/tooltip/templates/helpers';

import styles from '../styles.module.scss';

function listItems(items, units, format, map) {
    let list = '';

    items?.forEach((item) => {
        list +=
            `<div class=${styles.dropdownTemplateOptionListItem}>` +
            `<div>${item[map.listItem.left]}</div>` +
            `<div>${format(item[map.listItem.right])}` +
            `${units && `<span>${units}</span>`}` +
            `${
                item.link
                    ? `<a href=${item.link} target="_blank" rel="noopener noreferrer">${newWindowSvgString}</a>`
                    : ''
            }` +
            `</div>` +
            `</div>`;
    });
    return list;
}

const dropdown = ({ showValue, showMarker, format, tooltipName, value, marker, ...rest }) => {
    const { link, items, propertyName } = rest?.data?.group || {};

    return `<div ${link ? `data-link=${link}` : ''} class=${styles.dropdownTemplateOption}>
        <div>
            <div class=${styles.dropdownTemplateOptionRow}>
                <div class=${styles.dropdownTemplateOptionRowClick}></div>
                <div class=${styles.dropdownTemplateOptionRowTitle}>
                    <span class=${styles.dropdownTemplateOptionRowTitleTop}>${propertyName || ''}</span>
                    <span class=${styles.dropdownTemplateOptionRowTitleBottom}>${showMarker ? marker : ''}
                        ${tooltipName}
                    </span>
                </div>
                <div style="display: flex; align-items: center; column-gap: 10px;">
                    <span style='display: flex; align-items: center; column-gap: 8px;' >${
                        showValue ? `${format(value)} ${rest?.units}` : ''
                    }</span>
                    <span class=${styles.dropdownTemplateOptionRowIcon}></span>
                </div>
            </div>
            <div class=${styles.dropdownTemplateOptionList}>
                ${listItems(items, rest?.units, format, rest?.map)}
            </div>
        </div>
    </div>`;
};

if (isBrowser) {
    let clickCount = 0;
    let clickTimer = null;

    document?.addEventListener('click', (e) => {
        clickCount += 1;
        if (clickCount === 1) {
            clickTimer = setTimeout(() => {
                const { target } = e;
                if (target.classList.contains(styles.dropdownTemplateOptionRowClick)) {
                    const parent = target.closest(`.${styles.dropdownTemplateOption}`);
                    parent.classList.toggle(styles.dropdownTemplateOptionOpened);
                }
                clickCount = 0;
            }, 150); // Adjust the timeout duration as needed
        } else {
            clearTimeout(clickTimer);
            clickCount = 0;
        }
    });

    document?.addEventListener('dblclick', (e) => {
        const { target } = e;
        const parent = target.closest(`.${styles.dropdownTemplateOption}`);

        clearTimeout(clickTimer);
        clickCount = 0;

        if (parent) {
            const dataLink = parent.getAttribute('data-link');
            const url = window.location.origin + dataLink;

            if (dataLink) {
                openExternalWindow({ url });
            }
        }
    });
}

export default dropdown;
