import { useSelector } from 'react-redux';

import { reloadable } from '@utils/components';

import { SCC_LIMITED_TABS_LIST } from '@scc/components/MenuItemsList';
import { selectIsAdmin, selectIsUserLender, selectIsUserSCCLimited } from '@scc/store/ducks/auth';
import BaseTemplate from '@scc/templates/Base/index';

import RouteList from '@components/RouteList';

import config from '@config';

const AbsoluteComponents = reloadable(() => import('@scc/templates/AbsoluteComponents'));
const Chats = reloadable(() => import('@scc/templates/Chats'));
const hasChat = config.get('hasChat');

type AppProps = {
    routes: [];
    redirects: {
        allow: string;
        forbidden: string;
    };
};

type authState = {
    isAdmin: boolean;
    isLoggedIn: boolean;
    isPreviousAdmin: boolean;
    isLender: boolean;
};

function App({ routes, redirects }: AppProps) {
    const auth = useSelector(selectIsAdmin) as authState;
    const isUserLender = useSelector(selectIsUserLender);
    const isUserSCCLimited = useSelector(selectIsUserSCCLimited);

    return (
        <>
            <AbsoluteComponents isLoggedIn={auth.isLoggedIn} />
            <BaseTemplate>
                {hasChat && <Chats isLender={isUserLender} isLoggedIn={auth.isLoggedIn} />}
                <RouteList
                    auth={auth}
                    isLimited={isUserSCCLimited}
                    limitedRoutes={SCC_LIMITED_TABS_LIST}
                    redirects={redirects}
                    routes={routes}
                />
            </BaseTemplate>
        </>
    );
}

export default App;
