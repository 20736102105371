import colors from '../palette/colors.module.scss';

const link = () => ({
    MuiLink: {
        defaultProps: {
            color: colors['blue-main'],
            underline: 'none',
        },
        styleOverrides: {
            root: {
                fontFamily: 'Open Sans, sans-serif',
                fontWeight: 600,
                lineHeight: '16px',
                fontSize: 13,
                position: 'relative',
                cursor: 'pointer',
                '&:hover': {
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        height: 1,
                        backgroundColor: colors['blue-400'],
                        opacity: 0.2,
                        left: 0,
                        right: 0,
                        bottom: -3,
                    },
                },
                '&:focus': {
                    outline: '1px solid',
                    outlineOffset: 2,
                    borderRadius: 4,
                },
                '&:visited': {
                    color: colors['purple'],
                    borderColor: colors['purple'],
                },
            },
        },
    },
});
export default link;
