import * as yup from 'yup';
import { validation } from '@shared/utils/forms';

const schema = yup.object({
    type: yup.string().required(),
    pages: validation.number.required(),
    date: validation.dueDate,
});

export default schema;
