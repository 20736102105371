import { DOCUMENT_TYPES } from '@constants/documents';

export const COLUMN_TYPES_VALUES = {
    ALL: 'all',
    BUDGET: 'budget',
    ACTUAL: 'actual',
    PRO_FORMA: 'pro-forma',
    APPRAISAL: 'appraisal',
    UNDERWRITING: 'underwriting',
    BASE: 'base',
    YTD: 'ytd',
    ANNUALIZED: 'annualize',
    OTHER: 'other',
} as const;

export const USER_LOAN_DEFAULT_MODEL_DATE = {
    value: 'Latest',
    label: 'Latest',
};

export const COLUMN_TYPE_OPTIONS = [
    { label: 'All', value: COLUMN_TYPES_VALUES.ALL },
    { label: 'Budget', value: COLUMN_TYPES_VALUES.BUDGET },
    { label: 'Actual', value: COLUMN_TYPES_VALUES.ACTUAL },
    { label: 'Pro-Forma', value: COLUMN_TYPES_VALUES.PRO_FORMA },
    { label: 'Appraisal', value: COLUMN_TYPES_VALUES.APPRAISAL },
    { label: 'Underwriting', value: COLUMN_TYPES_VALUES.UNDERWRITING },
    { label: 'Base', value: COLUMN_TYPES_VALUES.BASE },
    { label: 'YTD', value: COLUMN_TYPES_VALUES.YTD },
    { label: 'Annualized', value: COLUMN_TYPES_VALUES.ANNUALIZED },
    { label: 'Other', value: COLUMN_TYPES_VALUES.OTHER },
] as const;

export const COUNT_VALUES = {
    ALL: '0',
    MONTHLY: '1',
    QUARTERLY: '3',
    ANNUALLY: '12',
} as const;

export const COUNT_OPTIONS = [
    { label: 'All', value: COUNT_VALUES.ALL },
    { label: 'Monthly', value: COUNT_VALUES.MONTHLY },
    { label: 'Quarterly', value: COUNT_VALUES.QUARTERLY },
    { label: 'Annually', value: COUNT_VALUES.ANNUALLY },
];

const DOCUMENT_TYPE_VALUES = {
    STANDARDIZED: 'standardized',
    ORIGINAL: 'original',
} as const;

export const DOCUMENT_TYPE_OPTIONS = [
    { label: 'Standardized', value: DOCUMENT_TYPE_VALUES.STANDARDIZED },
    { label: 'Original', value: DOCUMENT_TYPE_VALUES.ORIGINAL },
];

export const STATIC_SCENARIO_VALUES = {
    CONTRACT: 'contract',
    LESSER: 'lesser',
    MARKET: 'market',
    ROLLOVER: 'rollover',
    DOWN_MARKET: 'downMarket',
};

export const SCENARIO_OPTIONS = {
    [STATIC_SCENARIO_VALUES.CONTRACT]: {
        label: 'At Contract',
        value: STATIC_SCENARIO_VALUES.CONTRACT,
    },
    [STATIC_SCENARIO_VALUES.LESSER]: {
        label: 'Lesser of Contract and Market',
        value: STATIC_SCENARIO_VALUES.LESSER,
    },
    [STATIC_SCENARIO_VALUES.MARKET]: {
        label: 'Market Rate',
        value: STATIC_SCENARIO_VALUES.MARKET,
    },
    [STATIC_SCENARIO_VALUES.ROLLOVER]: {
        label: 'Rollover to Market at Year 5',
        value: STATIC_SCENARIO_VALUES.ROLLOVER,
    },
    [STATIC_SCENARIO_VALUES.DOWN_MARKET]: {
        label: 'Down Market Rate',
        value: STATIC_SCENARIO_VALUES.DOWN_MARKET,
    },
};

export const STATIC_SCENARIO_OPTIONS = [
    { label: 'At Contract', value: STATIC_SCENARIO_VALUES.CONTRACT },
    { label: 'Lesser of Contract and Market', value: STATIC_SCENARIO_VALUES.LESSER },
    { label: 'Market Rate', value: STATIC_SCENARIO_VALUES.MARKET },
    { label: 'Rollover to Market at Year 5', value: STATIC_SCENARIO_VALUES.ROLLOVER },
    { label: 'Down Market Rate', value: STATIC_SCENARIO_VALUES.DOWN_MARKET },
];

export const UNIT_MIX_VALUES = {
    BY_BEDROOM: 'byBedrooms',
    BY_UNIT_TYPE: 'byUnits',
};

export const UNIT_MIX_OPTIONS = [
    { label: 'By number of bedrooms', value: UNIT_MIX_VALUES.BY_BEDROOM },
    { label: 'By unit type', value: UNIT_MIX_VALUES.BY_UNIT_TYPE },
];

export const OPTIONS_TYPES = {
    COLUMN: 'column',
    COUNT: 'count',
    COLUMNS_TYPE: 'columnsType',
    TYPE: 'type',
    DOCUMENTS: 'documents',
    RR_ID: 'rrId',
    LOAN_ID: 'loanId',
    COMBINED_ID: 'combinedId',
    YEAR: 'year',
    PERIODS: 'periods',
    UNIT_MIX_TYPE: 'unitMixType',
    MODEL_ID: 'modelId',
    SCENARIOS: 'scenarios',
    VARIANCE_REPORT_ID: 'varianceReportId',
    RENT_ROLL_COMPARISON: 'rentRollComparison',
    RENT_ROLL_SECOND_COMPARISON: 'rentRollSecondComparison',
    LOAN_TABLE_MODEL_DATE: 'asOfDate',
} as const;

export const ASIDE_WIDTH = 260;

export const TYPES = {
    common: 'COMMON_REPORT',
    custom: 'CUSTOM_REPORT',
} as const;

export const VIEW = {
    main: 'MAIN',
    edit: 'EDIT',
    create: 'CREATE',
} as const;

export const DOCUMENTS_TYPES = {
    [DOCUMENT_TYPES.RR]: 'RR',
    [DOCUMENT_TYPES.OS]: 'OS',
    default: 'RR and OS',
} as const;

export const TEMPLATES_ID = {
    financials: 'financials',
    rentRoll: 'rentroll',
    proforma: 'proforma',
    underwriting: 'underwriting',
    asr: 'asr',
    osarCREFC: 'osar-crefc',
    propertyOverview: 'property-overview',
    rgaRentRoll: 'rga-rent-roll',
    rgaStatement: 'rga-statement',
    symetraFinancials: 'symetraFinancials',
    unitMix: 'unit-mix',
    varianceReport: 'varianceReport',
    adjustments: 'adjustments-report',
    fmRentRollComparison: 'dms',
    loanTable: 'loans-table',
    fmFinancials: 'lst-fm',
    fmAnnualizedFinancials: 'lst-annual-fm',
    fmDMS: 'lst-fmDms',
};

export const OPTIONS_LABELS = {
    [OPTIONS_TYPES.COLUMN]: 'Financials',
    [OPTIONS_TYPES.COUNT]: 'Periods',
    [OPTIONS_TYPES.COLUMNS_TYPE]: 'Label',
    [OPTIONS_TYPES.TYPE]: 'Type',
    [OPTIONS_TYPES.DOCUMENTS]: 'Document',
    [OPTIONS_TYPES.RR_ID]: 'Rent Roll',
    [OPTIONS_TYPES.LOAN_ID]: 'Loan',
    [OPTIONS_TYPES.COMBINED_ID]: 'Combined',
    [OPTIONS_TYPES.YEAR]: 'Year',
    [OPTIONS_TYPES.PERIODS]: 'Periods',
    [OPTIONS_TYPES.UNIT_MIX_TYPE]: 'Unit mix type',
    [OPTIONS_TYPES.MODEL_ID]: 'Models',
    [OPTIONS_TYPES.SCENARIOS]: 'Scenarios',
    [OPTIONS_TYPES.VARIANCE_REPORT_ID]: 'Variance Report',
    [OPTIONS_TYPES.RENT_ROLL_COMPARISON]: 'Rent Roll',
    [OPTIONS_TYPES.RENT_ROLL_SECOND_COMPARISON]: 'Rent Roll',
    [OPTIONS_TYPES.LOAN_TABLE_MODEL_DATE]: 'As of Date',
};
