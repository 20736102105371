import colors from '@themes/palette/export.module.scss';

const outlinedInput = () => ({
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: '8px',
                fontFamily: 'Open Sans, Arial, sans-serif',
            },
        },
        variants: [
            {
                props: { variant: 'noOutlined' },
                style: {
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                },
            },
            {
                props: { variant: 'ghost' },
                style: {
                    '.MuiOutlinedInput-notchedOutline': {
                        border: '1px solid transparent',
                        borderRadius: '8px',
                    },
                    '&:hover:not(.Mui-focused)': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: `${colors['grey-300']}!important`,
                        },
                    },
                    '& .Mui-active': {
                        borderColor: `${colors['grey-300']}!important`,
                    },
                },
            },
            {
                props: { variant: 'ghost', size: 'small' },
                style: {
                    '.MuiOutlinedInput-notchedOutline': {
                        padding: '0',
                        fontSize: '12px',
                    },
                },
            },
            {
                props: { variant: 'ghost', size: 'medium' },
                style: {
                    '.MuiOutlinedInput-notchedOutline': {
                        padding: '8px 6px',
                        fontSize: '14px',
                    },
                },
            },
            {
                props: { variant: 'ghost', size: 'large' },
                style: {
                    '.MuiOutlinedInput-notchedOutline': {
                        padding: '12px 16px',
                        fontSize: '16px',
                    },
                },
            },
        ],
    },
});

export default outlinedInput;
