import Icon from '@ui/Icon';
import SentSuccessLayout from '@scc/components/PartialSuccessLayout';
import CheckCircleOutlineIcon from '@scc/assets/icons/success.svg';

import styles from './styles.module.scss';

const SharePropertyFormSuccess = function ({ title = 'Success!', description }) {
    return (
        <SentSuccessLayout
            className={styles.success}
            icon={<Icon component={CheckCircleOutlineIcon} fontSize='128px' />}
            title={<span>{title}</span>}
            description={<span>{description}</span>}
        />
    );
};
export default SharePropertyFormSuccess;
