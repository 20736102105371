import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Score from '@scc/pages/Property/components/Score';
import styles from '@scc/pages/Property/property.module.scss';

import Grid from '@ui/Grid';

export default function Scores({ locationInfo, loaded, locationScore, riskInfo, riskScore, growthInfo, growthScore }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid className={styles.scoreContainer} spacing={isMobile && 16} container>
            <Grid md={4} xs={12} item>
                <Score
                    className={styles.scoreBlock}
                    description={locationInfo.description}
                    score={loaded && locationScore}
                    size={isMobile ? '120px' : null}
                    tooltip={locationInfo.tooltip}
                />
            </Grid>
            <Grid md={4} xs={12} item>
                <Score
                    className={styles.scoreBlock}
                    description={riskInfo.description}
                    score={loaded && riskScore}
                    size={isMobile ? '120px' : null}
                    tooltip={riskInfo.tooltip}
                />
            </Grid>
            <Grid md={4} xs={12} item>
                <Score
                    className={styles.scoreBlock}
                    description={growthInfo.description}
                    score={loaded && growthScore}
                    size={isMobile ? '120px' : null}
                    tooltip={growthInfo.tooltip}
                />
            </Grid>
        </Grid>
    );
}
