import {
    CreateRentRollMetricApiArg,
    generatedApi,
    UpdateRentRollMetricApiArg,
} from '@scc/services/generated/rentRollMetric';

const enhanceOptions = {
    endpoints: {
        createRentRollMetric: {
            async onQueryStarted(arg: CreateRentRollMetricApiArg, { dispatch, queryFulfilled }) {
                const { saveUpdateRentRollMetricDto } = arg;

                const patchResult = dispatch(
                    generatedApi.util.updateQueryData(
                        'getRentRollMetricById',
                        { propertyId: String(saveUpdateRentRollMetricDto.propertyId!) },
                        (draft) => {
                            Object.assign(draft, { data: saveUpdateRentRollMetricDto });
                        }
                    )
                );

                const { data: responseData } = await queryFulfilled;

                dispatch(
                    generatedApi.util.updateQueryData(
                        'getRentRollMetricById',
                        { propertyId: String(saveUpdateRentRollMetricDto.propertyId!) },
                        (draft) => {
                            Object.assign(draft, responseData);
                        }
                    )
                );

                queryFulfilled.catch(patchResult.undo);
            },
        },
        updateRentRollMetric: {
            async onQueryStarted(arg: UpdateRentRollMetricApiArg, { dispatch, queryFulfilled }) {
                const { saveUpdateRentRollMetricDto } = arg;

                const patchResult = dispatch(
                    generatedApi.util.updateQueryData(
                        'getRentRollMetricById',
                        { propertyId: String(saveUpdateRentRollMetricDto.propertyId!) },
                        (draft) => {
                            Object.assign(draft, { data: saveUpdateRentRollMetricDto });
                        }
                    )
                );

                queryFulfilled.catch(patchResult.undo);
            },
        },
    },
} as const;

export const rentRollMetricApi = generatedApi.enhanceEndpoints(enhanceOptions);
