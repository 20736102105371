// eslint-disable-next-line import/prefer-default-export
export const createRoutes = (a, o) =>
    Object.entries(a).map(([k, v]) => {
        if (v.routes) {
            const routes = createRoutes(v.routes, o[k].routes);
            return { ...v, ...o[k], routes };
        }
        return { ...v, ...o[k] };
    });

export const isActiveRoute = (target, dist) =>
    target === dist || (dist !== '/' && target.match(/\/\w+/g)?.[0] === dist);

export const isDynamicRouteMatchesLink = (route, link) => {
    const l = `${route.split(':')[0]}${link.split('/').pop()}`;
    return link === l;
};
