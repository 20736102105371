import { format } from 'date-fns';

const isRgaRentRoll = (tab) => tab?.id === 'rga-rent-roll';
const isRgaStatement = (tab) => tab?.id === 'rga-statement';
const rgaTemplateNames = new Set(['RGA Rent Roll', 'RGA PLM Rent Roll', 'RGA PLM Financials', 'RGA Financials']);

const getMaxDocumentDate = ({
    tabs,
    documents,
    columns,
}: {
    tabs: Record<string, unknown>[];
    documents: Record<string, unknown>[];
    columns: Record<string, unknown>[];
}): string => {
    const rentRollDocumentDates = tabs
        .filter(isRgaRentRoll)
        .map(
            ({ options }) => (options as Record<string, unknown>[]).find((option) => option.name === 'documents')?.value
        )
        .map((documentId) => documents.find(({ id }) => id === documentId))
        .map((document) => document?.documentDate && new Date(document?.documentDate as string))
        .filter((documentDate) => documentDate);

    const statementDates = tabs
        .filter(isRgaStatement)
        .flatMap(
            ({ options }) => (options as Record<string, unknown>[]).find((option) => option.name === 'column')?.value
        )
        .map((columnId) => columns.find(({ value }) => value === columnId))
        .map((column) => column?.columnDate && new Date(column?.columnDate as string))
        .filter((columnDate) => columnDate);

    const allDates = [...rentRollDocumentDates, ...statementDates];

    return format(new Date(Math.max(...allDates.map((d) => d.valueOf())) || Date.now()), 'MMddyyyy');
};

const rgaTemplateNameTransformer = ({
    template,
    properties,
    tabs,
    documents,
    columns,
}: {
    template: Record<string, unknown>;
    properties: Record<string, unknown>;
    tabs: Record<string, unknown>[];
    documents: Record<string, unknown>[];
    columns: Record<string, unknown>[];
}): string => {
    const templateName = template.name;
    const propertyName = `${properties?.propertyId || properties?.propertyName}` || '';
    const maxDocumentDate = getMaxDocumentDate({ tabs, documents, columns });

    return `${templateName}_${propertyName}_${maxDocumentDate}`;
};

export const getFileName = ({
    template,
    properties,
    tabs,
    documents,
    columns,
}: {
    template: Record<string, unknown>;
    properties: Record<string, unknown>;
    tabs: Record<string, unknown>[];
    documents: Record<string, unknown>[];
    columns: Record<string, unknown>[];
}) => {
    if (rgaTemplateNames.has(template.name as string)) {
        return rgaTemplateNameTransformer({ template, properties, tabs, documents, columns });
    }

    return '';
};
