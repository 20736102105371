import { useState } from 'react';

import EditablePercent from './components/EditablePercent/EditablePercent';
import styles from './styles.module.scss';

function CapRateGapsCell({
    value: initialValue,
    row,
    onUpdateData,
    column: { headerProps: { cellData: { isLimited = false } = {} } = {} },
}) {
    const [visibleArrows, setVisibleArrows] = useState(false);

    const onChange = ({ name, value }) => {
        if (initialValue[name] !== value) {
            onUpdateData({ cell: { name: 'capRate', value, attribute: name }, row });
        }
    };

    if (!initialValue) {
        return null;
    }

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
            className={styles.wrapper}
            onMouseLeave={() => setVisibleArrows(false)}
            onMouseOver={() => setVisibleArrows(true)}
        >
            {initialValue.map((item, index) => (
                <>
                    <EditablePercent
                        gradation={0.25}
                        inputClassName={styles.editableNumber}
                        isLimited={isLimited}
                        min={0}
                        name={!index ? 'from' : 'to'}
                        value={item}
                        visibleArrows={visibleArrows}
                        withStaticWidth
                        onSuccess={onChange}
                    />
                    {!index ? <div className={styles.line} /> : null}
                </>
            ))}
        </div>
    );
}

export default CapRateGapsCell;
