import { baseApi as api } from '../configs/property';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        deleteProperty: build.mutation<DeletePropertyApiResponse, DeletePropertyApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property/${queryArg.loanDetailsId}/${queryArg.propertyId}`,
                method: 'DELETE',
            }),
        }),
        addPropertyBuilding: build.mutation<AddPropertyBuildingApiResponse, AddPropertyBuildingApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property/add-building`,
                method: 'POST',
                body: queryArg.addPropertyBuildingRequest,
            }),
        }),
        deletePropertyBuilding: build.mutation<DeletePropertyBuildingApiResponse, DeletePropertyBuildingApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property/delete-building`,
                method: 'DELETE',
                body: queryArg.deletePropertyBuildingRequest,
            }),
        }),
        getPropertyBuildings: build.query<GetPropertyBuildingsApiResponse, GetPropertyBuildingsApiArg>({
            query: (queryArg) => ({ url: `/api/v2/property/${queryArg.id}/buildings` }),
        }),
        updateBuildingDocuments: build.mutation<UpdateBuildingDocumentsApiResponse, UpdateBuildingDocumentsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property/update-buildings`,
                method: 'PUT',
                body: queryArg.body,
                params: { propertyTab: queryArg.propertyTab },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type DeletePropertyApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type DeletePropertyApiArg = {
    /** Loan's loanDetailsId */
    loanDetailsId: number;
    /** Property id from loanPropertyList */
    propertyId: number;
};
export type AddPropertyBuildingApiResponse = /** status 200 OK */ GenericJsonResponseRentRollLoanDto;
export type AddPropertyBuildingApiArg = {
    addPropertyBuildingRequest: AddPropertyBuildingRequest;
};
export type DeletePropertyBuildingApiResponse = /** status 200 OK */ GenericJsonResponseRentRollLoanDto;
export type DeletePropertyBuildingApiArg = {
    deletePropertyBuildingRequest: DeletePropertyBuildingRequest;
};
export type GetPropertyBuildingsApiResponse = /** status 200 OK */ GenericJsonResponseListPropertyBuildingDto;
export type GetPropertyBuildingsApiArg = {
    id: number;
};
export type UpdateBuildingDocumentsApiResponse = /** status 200 OK */ GenericJsonResponseRentRollLoanDto;
export type UpdateBuildingDocumentsApiArg = {
    propertyTab: 'FINANCIALS' | 'RENTROLL';
    body: PropertyBuildingRequest[];
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    userId?: number;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type UnderwritingMetricsDto = {
    lesser?: number;
    contract?: number;
    market?: number;
    rollover?: number;
    downMarket?: number;
    customScenarios?: {
        [key: string]: number;
    };
};
export type LoanPropertyDto = {
    id?: number;
    propertyId?: string;
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
    userId?: number;
    groupId?: number;
    maturityDate?: string;
    address?: string;
    state?: string;
    propertyType?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    isDetailAnalyze?: boolean;
    size?: number;
    units?: number;
    acres?: number;
    underwritingRevenue?: UnderwritingMetricsDto;
    underwritingNoi?: UnderwritingMetricsDto;
    underwritingValuation?: UnderwritingMetricsDto;
    principal?: number;
    balance?: number;
};
export type CompanyOwnerMemberDto = {
    name?: string;
    companyType?: CompanyType;
    logoPath?: string;
    ownership?: number;
};
export type MemberResponseDto = {
    id?: string;
    userId?: number;
    companyId?: string;
    type?: string;
    notes?: string;
    ssn?: string;
    outstanding?: number;
    liquidity?: number;
    netWorth?: number;
    asOf?: string;
    ownedCompanies?: CompanyOwnerMemberDto[];
};
export type UserCompanyDto = {
    userId?: number;
    companyId?: string;
    position?: string;
    companyName?: string;
    updatedAt?: string;
};
export type TeamMemberDto = {
    id?: number;
    loanRole?: LoanRole;
    position?: string;
    lead?: boolean;
};
export type UserMemberResponseDto = {
    member?: MemberResponseDto;
    userInfo?: UserInfoDto;
    userCompanyDTO?: UserCompanyDto;
    teamMemberDTO?: TeamMemberDto;
};
export type CompanyDto = {
    id?: string;
    name?: string;
    companyType?: CompanyType;
    companyUrl?: string;
    email?: string;
    taxId?: string;
    contactAddress?: string;
    phone?: string;
    logoPath?: string;
    createdAt?: string;
    createdBy?: number;
};
export type CompanyMemberResponseDto = {
    member?: MemberResponseDto;
    company?: CompanyDto;
};
export type MemberDto = {
    userMember?: UserMemberResponseDto;
    companyMember?: CompanyMemberResponseDto;
    isNew?: boolean;
    createdAt?: string;
    id?: string;
    user?: boolean;
};
export type LoanDetailsDto = {
    id?: number;
    loanId?: string;
    loanName?: string;
    jsonValues?: string;
    status?: Status2;
    balance?: number;
    unfundedBalance?: number;
    principal?: number;
    loanProgram?: LoanProgram;
    loanType?: LoanType;
    propertyScore?: number;
    termEnd?: string;
    funded?: string;
    permission?: Permission;
    loanData?: object;
    loanPropertyList?: LoanPropertyDto[];
    members?: {
        [key: string]: MemberDto;
    };
    readOnly?: boolean;
};
export type DocumentDto = {
    id?: number;
    isNew?: boolean;
    userId?: number;
    groupId?: number;
    type?: Type;
    name?: string;
    fileName?: string;
    pages?: string;
    sheetNumber?: number;
    generated?: boolean;
    uploadedTimestamp?: string;
    uploadedDate?: string;
    documentTimestamp?: string;
    documentDate?: string;
    documentStatus?: DocumentStatus;
    documentStatusByCurrentUser?: DocumentStatusByCurrentUser;
    status?: Status;
    statusLabel?: string;
    documentUsers?: UserInfoDto[];
    documentLoans?: LoanDetailsDto[];
    documentMembers?: MemberDto[];
    documentProperties?: LoanPropertyDto[];
    documentPropertyBuildings?: PropertyBuildingDto[];
    initialPdfParseType?: InitialPdfParseType;
    rrId?: number;
    osId?: number;
    template?: string;
    statusNote?: string;
    updatedUser?: UserInfoDto;
    isProcessMySelf?: boolean;
};
export type PropertyBuildingDto = {
    id?: number;
    propertyId?: number;
    name?: string;
    documents?: DocumentDto[];
};
export type RentRollLoanDto = {
    id?: number;
    propertyid?: string;
    propertyname?: string;
    loanid?: string;
    amount?: number;
    principal?: number;
    interest?: number;
    maturitydate?: string;
    countyid?: string;
    parcelid?: string;
    addressedvaluetype?: Addressedvaluetype;
    propertytype?: string;
    propertyBuildings?: PropertyBuildingDto[];
};
export type GenericJsonResponseRentRollLoanDto = {
    status?: string;
    data?: RentRollLoanDto;
    message?: string;
    code?: string;
};
export type AddPropertyBuildingRequest = {
    propertyId: number;
    name: string;
};
export type DeletePropertyBuildingRequest = {
    propertyId: number;
    buildingId: number;
};
export type GenericJsonResponseListPropertyBuildingDto = {
    status?: string;
    data?: PropertyBuildingDto[];
    message?: string;
    code?: string;
};
export type PropertyBuildingRequest = {
    id?: number;
    propertyId: number;
    name: string;
    documentIds: number[];
};
export enum Addressedvaluetype {
    Building = 'building',
    BuildingRuntime = 'buildingRuntime',
}
export enum Type {
    RentRoll = 'RENT_ROLL',
    OperatingStatement = 'OPERATING_STATEMENT',
    Budget = 'BUDGET',
    TaxReturn = 'TAX_RETURN',
    BankStatement = 'BANK_STATEMENT',
    DevelopmentBudget = 'DEVELOPMENT_BUDGET',
    Invoice = 'INVOICE',
    PropertyPurchaseAgreement = 'PROPERTY_PURCHASE_AGREEMENT',
    BalanceSheet = 'BALANCE_SHEET',
    GuarantorFinancialStatement = 'GUARANTOR_FINANCIAL_STATEMENT',
    MortgageStatement = 'MORTGAGE_STATEMENT',
    InsurancePolicy = 'INSURANCE_POLICY',
    InspectionReport = 'INSPECTION_REPORT',
    AppraisalReport = 'APPRAISAL_REPORT',
    InfoMemo = 'INFO_MEMO',
    LoanInfoMemo = 'LOAN_INFO_MEMO',
    LeaseAgreement = 'LEASE_AGREEMENT',
    LoanAgreement = 'LOAN_AGREEMENT',
    InsuranceForm = 'INSURANCE_FORM',
    InsuranceAgreement = 'INSURANCE_AGREEMENT',
    Other = 'OTHER',
}
export enum DocumentStatus {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum DocumentStatusByCurrentUser {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum Status {
    Error = 'ERROR',
    InProcessAuto = 'IN_PROCESS_AUTO',
    InProcessManual = 'IN_PROCESS_MANUAL',
    Processed = 'PROCESSED',
    Confirmed = 'CONFIRMED',
}
export enum Status2 {
    SccUnderwriting = 'SCC_UNDERWRITING',
    Evaluation = 'EVALUATION',
    QuoteIssued = 'QUOTE_ISSUED',
    Underwriting = 'UNDERWRITING',
    AppraisalNTitleWork = 'APPRAISAL_N_TITLE_WORK',
    InClosing = 'IN_CLOSING',
    Funded = 'FUNDED',
    PaidOff = 'PAID_OFF',
    Passed = 'PASSED',
    TransferredOut = 'TRANSFERRED_OUT',
    DueDiligence = 'DUE_DILIGENCE',
    ApplicationIssued = 'APPLICATION_ISSUED',
    TermSheetIssued = 'TERM_SHEET_ISSUED',
    Securitized = 'SECURITIZED',
    Dead = 'DEAD',
    UnderContract = 'UNDER_CONTRACT',
}
export enum LoanProgram {
    Acquisition = 'ACQUISITION',
    Construction = 'CONSTRUCTION',
    Refinance = 'REFINANCE',
    NewDevelopment = 'NEW_DEVELOPMENT',
    Redevelopment = 'REDEVELOPMENT',
}
export enum LoanType {
    Loan = 'LOAN',
    LoanRequest = 'LOAN_REQUEST',
    Investment = 'INVESTMENT',
}
export enum Permission {
    Admin = 'ADMIN',
    Creator = 'CREATOR',
    Owner = 'OWNER',
    Collaborator = 'COLLABORATOR',
    Viewer = 'VIEWER',
    None = 'NONE',
}
export enum CompanyType {
    Llc = 'LLC',
    Corporation = 'CORPORATION',
}
export enum LoanRole {
    Borrower = 'BORROWER',
    Lender = 'LENDER',
    Guarantor = 'GUARANTOR',
    Broker = 'BROKER',
    Other = 'OTHER',
}
export enum InitialPdfParseType {
    Pdfbox = 'PDFBOX',
    Ocr = 'OCR',
    Ocr2 = 'OCR2',
}
export const {
    useDeletePropertyMutation,
    useAddPropertyBuildingMutation,
    useDeletePropertyBuildingMutation,
    useGetPropertyBuildingsQuery,
    useUpdateBuildingDocumentsMutation,
} = injectedRtkApi;
