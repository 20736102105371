import Base from './templates/Base';
import Dropdown from './templates/Dropdown';

export const BASE = 'largestTenantsBase';

export const DROPDOWN = 'largestTenantsDropdown';

export const TEMPLATES_MAP = {
    [BASE]: Base,
    [DROPDOWN]: Dropdown,
};
