import cx from 'classnames';
import ReactHighlighter from 'react-highlight-words';

import styles from './styles.module.scss';

function Highlighter({ highlightClassName, searchWords, textToHighlight, autoEscape }) {
    return (
        <ReactHighlighter
            autoEscape={autoEscape}
            highlightClassName={cx(highlightClassName, styles.highlight)}
            searchWords={searchWords}
            textToHighlight={textToHighlight}
        />
    );
}

export default Highlighter;
