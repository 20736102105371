import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

import { createBaseProxy } from '@utils/components';

export default createBaseProxy<
    CheckboxProps & {
        variant?: 'rounded';
        children?: never;
    }
>(Checkbox, 'Checkbox');
