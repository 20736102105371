import { useCallback, useEffect, useState } from 'react';

import { checkFileType } from '@utils/documents';

import config from '@config';
import { CALCULATE_EXCEL_DATA, VIEW_FILE_LOAD } from '@constants/connectorMC';
import { useCustomEventListener } from '@shared/hooks/useCustomEvents';

const APP_PREFIX = config.get('app');

export const usePrepareDocument = ({ document, onDelete, onChange, onPreview, isActive, index, setIsLoading }) => {
    const { id: documentId, end, pages, sheetNumber } = document;
    const [fromPage, toPage] = pages?.split('-') || [0, 0];
    const [xlsTabs, setXlsTabs] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const isDuplicate = !!document.parentId;
    const { isDocument: isDocumentFileType, isExcel: isExcelFileType } = checkFileType(document.displayName);

    const onChangeCallback = useCallback((nextData, _id) => onChange(nextData, _id), [onChange]);

    const handleDelete = useCallback(() => {
        onDelete(documentId);

        if (isActive) {
            onPreview(0);
        }
    }, [documentId, isActive, onDelete, onPreview]);

    const handlePreview = useCallback(() => {
        onPreview(index);

        if (!isActive && (isDocumentFileType || isExcelFileType)) {
            setIsLoading(true);
        }
    }, [index, isActive, isDocumentFileType, isExcelFileType, onPreview, setIsLoading]);

    const onStartPageChange = useCallback(
        (e) => {
            const nextValue = Number(e.target.value);
            const updateData = [{ name: 'start', value: nextValue }];

            if (nextValue >= end || !end) {
                updateData.push({ name: 'end', value: nextValue });
            }

            onChangeCallback(updateData, documentId);
        },
        [documentId, onChangeCallback, end]
    );

    const onCalculateExcelData = useCallback(
        (event, payload = []) => {
            if (isActive && isExcelFileType) {
                setIsLoading(false);
                const activeValue = payload.find((item) => item.isActive)?.orderNo;

                setXlsTabs(payload);

                if (sheetNumber === undefined) {
                    onChangeCallback({ name: 'sheetNumber', value: activeValue }, documentId);
                }
            }
        },
        [documentId, isActive, isExcelFileType, onChangeCallback, setIsLoading, sheetNumber]
    );

    const onGetTotalPages = useCallback(
        (event, payload) => {
            if (isActive && isDocumentFileType) {
                setIsLoading(false);
                setTotalPages(payload);
            }
        },
        [isActive, isDocumentFileType, setIsLoading]
    );

    const onSetTotalPages = useCallback(() => {
        onChangeCallback(
            [
                { name: 'start', value: 1 },
                { name: 'end', value: totalPages },
            ],
            documentId
        );
    }, [documentId, onChangeCallback, totalPages]);

    useCustomEventListener(`${APP_PREFIX}-${CALCULATE_EXCEL_DATA}`, onCalculateExcelData, [onCalculateExcelData]);
    useCustomEventListener(`${APP_PREFIX}-${VIEW_FILE_LOAD}`, onGetTotalPages, [onGetTotalPages]);

    useEffect(() => {
        onChangeCallback(
            [
                { name: 'start', value: fromPage },
                { name: 'end', value: toPage },
            ],
            documentId
        );
    }, [fromPage, documentId, onChangeCallback, toPage]);

    return [
        {
            xlsTabs,
            isDocumentFileType,
            isExcelFileType,
            fromPage,
            toPage,
            isDuplicate,
        },
        {
            handleDelete,
            handlePreview,
            onStartPageChange,
            onSetTotalPages,
        },
    ];
};
