import { delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { loansApi } from '@api';
import { showBottomPageDrawer, showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';
import { SMALL_MODAL_HEADER, TABS_WIDGET, SHARE_LOAN_APPLICATION } from '@constants/modals';

const entity = '[loanLenders]';
const size = 20;

const initialState = {
    totalCount: 0,
    data: [],
    search: {},
    lenderApplication: null,
    loaded: false,
    loading: false,
};

const loanLendersSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            state.search = action.payload.search;
            state.data = [];
            state.loaded = false;
            state.loading = true;
        },
        loadMore() {},
        addMore(state, action) {
            state.data = action.payload;
        },
        setData(state, action) {
            const { data, totalCount } = action.payload;
            state.data = data;
            state.totalCount = totalCount;
            state.loaded = true;
            state.loading = false;
        },
        delete(state) {
            state.loaded = false;
            state.loading = true;
        },
        filter(state, action) {
            state.search = action.payload;
            state.data = [];
            state.loaded = false;
            state.loading = true;
        },
        viewApplication(state, action) {
            state.loaded = true;
            state.loading = false;
            state.lenderApplication = action.payload?.modelData?.id || null;
        },
        shareApplication(state) {
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const {
    load: loadLoanLenders,
    loadMore: loadMoreLoanLenders,
    setData: setLoanLenders,
    delete: deleteLoanLender,
    addMore: addMoreLoanLenders,
    viewApplication: viewLenderApplication,
    shareApplication: shareLenderApplication,
} = loanLendersSlice.actions;

export default loanLendersSlice.reducer;

export const selectLoanLendersState = (store) => store.loans.lender;

function* loadLoanLendersSaga({ payload, type }) {
    yield delay(400);
    const { loanId } = payload;
    yield put(apiRequest(loanId, loansApi.getLoanRequestLenders, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setLoanLenders({ data: content, totalCount: totalElements, search: payload }));
}

function* loadMoreLoanLendersSaga({ type }) {
    const { data: prevData, search = {} } = yield select(selectLoanLendersState);
    yield put(apiRequest({ ...search, page: prevData.length / size }, loansApi.getLoanRequestLenders, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreLoanLenders([...prevData, ...content]));
}

function* deleteLoanLenderSaga({ payload }) {
    const { data } = yield select(selectLoanLendersState);
    yield put(apiRequest(payload, loansApi.deleteLoanRequestLender));
    const loanLenders = data.filter((loan) => loan.id !== payload);
    yield put(setLoanLenders({ data: loanLenders }));
}

function* viewLenderApplicationSaga({ payload, type }) {
    const {
        lenderData: { id },
    } = payload;
    yield put(apiRequest(id, loansApi.getLoanRequestLenderData, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const {
        data: { loanApplication, properties },
    } = action.payload;
    yield put(
        showBottomPageDrawer({
            header: SMALL_MODAL_HEADER,
            content: TABS_WIDGET,
            data: { ...payload, loanLenderData: loanApplication, properties },
        })
    );
}

function* shareLenderApplicationSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: SHARE_LOAN_APPLICATION,
            data: { title: 'Share Application', ...payload },
        })
    );
}

export function* watchLoanLenders() {
    yield takeLatest(loadLoanLenders, loadLoanLendersSaga);
    yield takeLatest(loadMoreLoanLenders, loadMoreLoanLendersSaga);
    yield takeLatest(deleteLoanLender, deleteLoanLenderSaga);
    yield takeLatest(viewLenderApplication, viewLenderApplicationSaga);
    yield takeLatest(shareLenderApplication, shareLenderApplicationSaga);
}
