import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { portfolioApi } from '@api';

const entity = '[financing]';

const initialState = {
    data: {},
    loaded: false,
    loading: false,
};

const financingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadFinancing, setData: setFinancing } = financingSlice.actions;

export default financingSlice.reducer;

export const selectFinancialsState = (store) => store.portfolio.financing;

function* loadFinancingSaga({ type, payload }) {
    yield put(apiRequest(payload, portfolioApi.getOnePortfolio, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(setFinancing(action.payload.data));
}

export function* watchFinancing() {
    yield takeLatest(loadFinancing, loadFinancingSaga);
}
