import { reloadable } from '@utils/components';

import QuotesIcon from '@scc/assets/icons/quotes.svg';
import SliderComment from '@scc/components/SliderComment';
import { comments, frequentlyAskedQuestions } from '@scc/statics/content';

import Container from '@ui/Container';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Paper from '@ui/Paper';

import styles from './pricing.module.scss';
import PricingFaq from './PricingFaq';

const Slider = reloadable(() => import('@scc/components/Slider'), { ssr: false });

function PricingTemplates({ children }) {
    return (
        <Container>
            <Grid as='main' className={styles.main} spacing={15} container>
                {children}
            </Grid>
            <Grid as='section' justifyContent='center' container>
                <Grid md={7} xs={11} item>
                    <PricingFaq questions={frequentlyAskedQuestions} />
                </Grid>
                <Grid md={8} xs={11} item>
                    <Icon
                        className={styles.quotes}
                        component={QuotesIcon}
                        sx={{ stroke: '#C4C7D4' }}
                        viewBox='0 0 60 43'
                    />
                    <Slider className={styles.comments} slides={comments}>
                        {({ position, name, message, image }) => (
                            <>
                                <Paper className={styles.comment} variant='none'>
                                    <SliderComment.Square comment={message} image={image} job={position} name={name} />
                                </Paper>
                                <span slot='wrapper-end'>&nbsp;</span>
                            </>
                        )}
                    </Slider>
                </Grid>
            </Grid>
        </Container>
    );
}

export default PricingTemplates;
