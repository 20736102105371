import { createSlice } from '@reduxjs/toolkit';
import { all, call, put, take, delay, select, takeLatest } from 'redux-saga/effects';

import { USER_LOANS_TAGS, userLoans } from '@scc/services/apis';
import { selectAuthState, selectIsUserBorrower } from '@scc/store/ducks/auth';
import { addUploadDocuments, loadLoanDocuments } from '@scc/store/ducks/documents';
import { loadActivityLoan, loadRequestsLoan } from '@scc/store/ducks/loans/loan';
import { loadOneLoans, selectLoansState } from '@scc/store/ducks/loans/loans';

import { detailedProperty, documentsApi, propertiesApi, buildingApi } from '@api';
import { UPLOAD_LOAN_DOCUMENTS_STEPS, UPLOAD_LOAN_DOCUMENTS_REQUEST_STEPS } from '@constants/modals';
import { uploadDocumentsForm } from '@shared/templates/forms/UploadDocuments';
import { apiRequest, API_SUCCESS, API_ERROR } from '@store/ducks/api';
import { hideDialog } from '@store/ducks/ui/dialog';
import { hideDrawer, showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

const { MAIN, PREPARE, LOADING, SUCCESS, ERROR } = uploadDocumentsForm.TEMPLATES;

const entity = '[sh:flowLoan]';

const initialState = {
    error: null,
    event: {},
    template: '',
    data: {},
    property: {},
};

const loanFLowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        upload(state, action) {
            state.event = action.payload;
        },
        uploadSteps(state) {
            state.template = MAIN;
        },
        uploadStepsRequest(state) {
            state.template = MAIN;
        },
        addPropertyToLoan(state, action) {
            state.error = null;
            state.data = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
        addLoanDocuments() {},
        addLoanDocumentsRequest() {},
        uploadLoanDocuments() {},
        uploadLoanDocumentsRequest() {},
        loadProperty() {},
        addProperty(state, action) {
            state.property = action.payload;
        },
    },
});

export const {
    upload: uploadLoanDocuments,
    uploadSteps: uploadLoanDocumentsSteps,
    uploadStepsRequest: uploadLoanDocumentsRequestSteps,
    addPropertyToLoan: addPropertyToLoanLoanFlow,
    setError: setErrorLoanFlow,
    setTemplate: setTemplateLoanFlow,
    addLoanDocuments: addLoanDocumentsFlow,
    addLoanDocumentsRequest: addLoanDocumentsRequestFlow,
    uploadLoanDocuments: uploadLoanDocumentsFlow,
    uploadLoanDocumentsRequest: uploadLoanDocumentsRequestFlow,
    loadProperty: loadPropertyLoanFlow,
    addProperty: addPropertyLoanFlow,
} = loanFLowSlice.actions;

export default loanFLowSlice.reducer;

export const selectLoanFlowState = (store) => store.flow.loan;

function* uploadLoanDocumentsStepsSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: UPLOAD_LOAN_DOCUMENTS_STEPS,
            data: payload,
        })
    );
}

function* uploadLoanDocumentsRequestStepsSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: UPLOAD_LOAN_DOCUMENTS_REQUEST_STEPS,
            data: payload,
        })
    );
}
function* uploadLoanDocumentsSaga({ payload }) {
    const { loan } = yield select(selectLoansState);
    yield put(
        showPrimaryAsideDrawer({
            content: UPLOAD_LOAN_DOCUMENTS_STEPS,
            data: {
                title: 'Upload Documents',
                maxFiles: 1,
                ...payload,
            },
        })
    );
    yield take(addUploadDocuments);
    yield put(loadLoanDocuments({ loanId: loan.id }));
    yield put(loadRequestsLoan(loan.id));
    yield put(loadActivityLoan(loan.id));
}

function* addPropertyToLoanLoanFlowSaga({ type, payload }) {
    if (payload.loanDetailsId) {
        yield put(apiRequest(payload, propertiesApi.addPropertyToLoan, type));
        const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
        if (action.type.endsWith(API_ERROR)) {
            yield put(setErrorLoanFlow(action.payload.message));
            yield delay(8000);
            yield put(setErrorLoanFlow(null));
        } else {
            yield put(loadOneLoans(payload.loanDetailsId));
            yield put(userLoans.util.invalidateTags([USER_LOANS_TAGS.LOAN]));
        }
    } else {
        const { currentUser } = yield select(selectAuthState);
        const isBorrower = yield select(selectIsUserBorrower);
        const { countyId, parcelId, userId = currentUser.userId } = payload;
        const data = { countyId, parcelId, userId };
        const requestMethod = isBorrower ? buildingApi.getPropertyById : detailedProperty.getOverview;
        yield put(apiRequest(data, requestMethod, loadPropertyLoanFlow));
        const action = yield take(`${loadPropertyLoanFlow} ${API_SUCCESS}`);
        yield put(addPropertyLoanFlow(action.payload?.data ?? action.payload));
    }
    yield put(hideDialog());
}

function* addLoanDocumentsFlowSaga({ payload }) {
    const { selectDocs, loanId } = payload;
    yield put(setTemplateLoanFlow(LOADING));
    yield all(
        selectDocs.map((documentId) => call(documentsApi.addToLoanDocument, { documentId, loanDetailsId: loanId }))
    );
    yield put(loadLoanDocuments({ loanId }));
    yield put(setTemplateLoanFlow(SUCCESS));
    yield delay(3000);
    yield put(hideDrawer());
}

function* addLoanDocumentsRequestFlowSaga({ payload }) {
    const { selectDocs, loanRequestId } = payload;
    yield put(setTemplateLoanFlow(LOADING));
    yield all(
        selectDocs.map((documentId) => call(documentsApi.addToLoanDocumentRequest, { documentId, loanRequestId }))
    );
    yield put(setTemplateLoanFlow(SUCCESS));
    yield delay(3000);
    yield put(hideDrawer());
}

function* uploadLoanDocumentsFlowSaga({ payload }) {
    const { prepareDocs, loanId, editMode } = payload;
    yield put(setTemplateLoanFlow(LOADING));

    const method = editMode ? documentsApi.updateDocument : documentsApi.uploadToLoanDocument;

    const responses = yield all(
        Object.values(prepareDocs).map(({ id, name, start, end, type, date, file }) =>
            call(method, {
                id,
                loanId,
                name,
                type,
                date,
                pages: `${start}-${end}`,
                file,
            })
        )
    );

    if (responses.some((res) => res.error)) {
        yield put(setTemplateLoanFlow(ERROR));
        yield delay(2300);
        yield put(setTemplateLoanFlow(PREPARE));
    } else {
        yield put(loadLoanDocuments({ loanId }));
        yield put(setTemplateLoanFlow(SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
    }
}

function* uploadLoanDocumentsRequestSaga({ payload }) {
    const { prepareDocs, loanRequestId, editMode, loanId } = payload;
    yield put(setTemplateLoanFlow(LOADING));

    const method = editMode ? documentsApi.updateDocument : documentsApi.uploadDocumentsToRequest;

    const responses = yield all(
        Object.values(prepareDocs).map(({ id, name, start, end, type, date, file }) =>
            call(method, {
                id,
                loanRequestId,
                name,
                type,
                date,
                pages: `${start}-${end}`,
                file,
            })
        )
    );

    if (responses.some((res) => res.error)) {
        yield put(setTemplateLoanFlow(ERROR));
        yield delay(2300);
        yield put(setTemplateLoanFlow(PREPARE));
    } else {
        yield put(loadLoanDocuments({ loanId }));
        yield put(setTemplateLoanFlow(SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
    }
}

export function* watchLoanFLow() {
    yield takeLatest(uploadLoanDocuments, uploadLoanDocumentsSaga);
    yield takeLatest(uploadLoanDocumentsSteps, uploadLoanDocumentsStepsSaga);
    yield takeLatest(uploadLoanDocumentsRequestSteps, uploadLoanDocumentsRequestStepsSaga);
    yield takeLatest(addPropertyToLoanLoanFlow, addPropertyToLoanLoanFlowSaga);
    yield takeLatest(addLoanDocumentsFlow, addLoanDocumentsFlowSaga);
    yield takeLatest(addLoanDocumentsRequestFlow, addLoanDocumentsRequestFlowSaga);
    yield takeLatest(uploadLoanDocumentsFlow, uploadLoanDocumentsFlowSaga);
    yield takeLatest(uploadLoanDocumentsRequestFlow, uploadLoanDocumentsRequestSaga);
}
