import { Skeleton } from '@mui/material';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

type EmptyListProps = {
    isLoading: boolean;
};

export function EmptyList({ isLoading }: EmptyListProps) {
    return isLoading ? (
        <Skeleton height={30} variant='rounded' />
    ) : (
        <Typography color={colors['grey-300']}>No data found</Typography>
    );
}
