import { put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { listingApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { selectListingsState, loadListings } from '@store/ducks/listings';
import { hideDrawer } from '@store/ducks/ui/drawer';

const entity = '[listing]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const listingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        delete() {},
    },
});

export const { load: loadListing, setData: setListing, delete: deleteListing } = listingSlice.actions;

export default listingSlice.reducer;

export const selectListingState = (store) => store.listing;

function* loadListingSaga({ payload, type }) {
    yield put(apiRequest(payload, listingApi.getListing, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setListing(action.payload.data));
}

function* deleteListingSaga({ payload, type }) {
    const { search } = yield select(selectListingsState);
    yield put(apiRequest(payload, listingApi.deleteListing, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadListings(search));
    yield put(hideDrawer());
}

export function* watchListing() {
    yield takeLatest(loadListing, loadListingSaga);
    yield takeLatest(deleteListing, deleteListingSaga);
}
