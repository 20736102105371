import { getVariantMappingTypography, typographyList } from '../helpers';

const variantMapping = getVariantMappingTypography(typographyList);

const typography = () => ({
    MuiTypography: {
        defaultProps: {
            variantMapping,
        },
    },
});
export default typography;
