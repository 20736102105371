export type TCovenant = {
    id: number;
    type: string;
    threshold: number;
    latestValue?: number;
    action: string;
};

export type TCovenantState = {
    loading: boolean;
    loaded: boolean;
    data: TCovenant[];
    latestValues: { dsr?: number; debtYield?: number };
};

export type TCovenantLatestValue = {
    dscr: number;
    debtYield: number;
};

export const COVENANT_TYPE_VALUES = {
    MIN_DSCR: 'MIN_DSCR',
    MIN_DEBT_YIELD: 'MIN_DEBT_YIELD',
} as const;

export const LATEST_TYPE_VALUES = {
    MIN_DSCR: 'dscr',
    MIN_DEBT_YIELD: 'debtYield',
};
