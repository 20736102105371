export const sortObjectByDateKeysCb = (a, b) => {
    if (new Date(a) > new Date(b)) return 1;
    if (new Date(a) < new Date(b)) return -1;
    if (new Date(a) === new Date(b)) return 0;
};

const sortingDefaultCb = (a, b) => {
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
};

export const sortObjectByKeys = (o, sortingCb = sortingDefaultCb) =>
    Object.keys(o)
        .sort(sortingCb)
        .reduce((r, k) => ((r[k] = o[k]), r), {});
