import { useEffect, useState } from 'react';

import { addSelectMutliOptions } from '@utils/components';

import Icon from '@ui/Icon';
import MenuItem from '@ui/MenuItem';
import Select from '@ui/Select';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

const defaultProps = {
    unregister: () => {},
    selectProps: { options: [], placeholder: '', renderOption: () => {} },
    onChange: () => {},
};

function SelectBaseForm({
    unregister,
    selectProps: { options, placeholder, renderOption, renderValue, ...selectProps } = {},
    onChange,
    ...props
}) {
    const [state, setState] = useState(addSelectMutliOptions(props.defaultValue));

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setState(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        if (state.includes(props.defaultValue)) return;
        onChange({ target: { value: state } });
    }, [props.defaultValue, state]);

    useEffect(
        () => () => {
            unregister(props.name);
        },
        []
    );

    return (
        <Select
            {...props}
            {...selectProps}
            renderValue={
                renderValue ||
                ((val) =>
                    val === '' || (Array.isArray(val) && (!val.length || val[0] === '')) ? (
                        <Typography color={colors['grey-300']} variant='mdr'>
                            {placeholder}
                        </Typography>
                    ) : (
                        <Typography variant='mdr'>
                            {Array.isArray(val)
                                ? val
                                      .map(
                                          (item) =>
                                              options.find(
                                                  (option) => option.value === item || option?.typeValue === item
                                              )?.label || ''
                                      )
                                      .join(', ')
                                : options.find((option) => option.value === val)?.label || ''}
                        </Typography>
                    ))
            }
            value={state}
            onChange={handleChange}
        >
            {options?.map(({ value, label, icon }) => (
                <MenuItem key={value} value={value}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {renderOption ? (
                        renderOption({ value, label })
                    ) : selectProps?.multiple ? (
                        label
                    ) : (
                        <Stack direction='row' gap={4}>
                            {icon?.icon && <Icon color={icon.color} component={icon.icon} fontSize={icon.size} />}
                            {label}
                        </Stack>
                    )}
                </MenuItem>
            ))}
        </Select>
    );
}

SelectBaseForm.defaultProps = defaultProps;

export default SelectBaseForm;
