import { takeEvery, take, put } from 'redux-saga/effects';

import links from '@scc/router/links';

import { API_ERROR_UNAUTHORIZED } from '@store/ducks/api';

import { setUserInfoAuth, logoutAuth } from './auth';

function* apiErrorUnauthorizedSaga({ payload }) {
    if (typeof window === 'undefined' || !window.auth.hasUser) return;
    if (typeof payload.redirect === 'string' && !!payload.redirect) {
        window.location.href = payload.redirect;
    } else if (typeof payload.redirect === 'boolean' && !!payload.redirect) {
        yield put(logoutAuth());
        yield take([setUserInfoAuth]);
        const { pathname } = window.location;
        if (links.login.path !== pathname) {
            if (window.location.pathname !== links.sssTimeout.path) {
                window.router?.history.push(links.login.path, {
                    from: { pathname },
                });
            }
        }
    }
}

export function* watchApiError() {
    yield takeEvery(API_ERROR_UNAUTHORIZED, apiErrorUnauthorizedSaga);
}
