import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { filterPaths } from '@utils/index';

const useRouterPageOnLocationChange = (pages, action) => {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();

    useEffect(() => {
        const payload = filterPaths(pages, pathname);
        const searchParams = Object.fromEntries(new URLSearchParams(search));
        if (!isEmpty(searchParams)) {
            payload.search = search;
            payload.params = searchParams;
        }
        dispatch(action(payload));
    }, [pathname, action, search]);
};

export default useRouterPageOnLocationChange;
