import { put, take, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { loansApi } from '@api';

const entity = '[analytics]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const analyticsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadAnalytics, setData: setAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const selectAnalyticsState = (store) => store.loans.analytics;

function* loadAnalyticsSaga({ type }) {
    yield put(apiRequest(null, loansApi.getLoanAnalytics, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setAnalytics(action.payload.data));
}

export function* watchAnalytics() {
    yield takeLatest(loadAnalytics, loadAnalyticsSaga);
}
