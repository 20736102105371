/* eslint-disable no-template-curly-in-string */
import cx from 'classnames';
import { useRef } from 'react';

import { getElementHtmlById } from '@utils/browser';

import Breadcrumbs from '@ui/Breadcrumbs';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Link from '@ui/Link';
import Typography from '@ui/Typography';

import Meta from '@components/Meta';

import colors from '@themes/palette/export.module.scss';

import LocationSvg from '@assets/icons/outlined/location.svg';
import config from '@config';
import usePreventReactRender from '@shared/hooks/usePreventReactRender';

import styles from './sitemap.module.scss';

const SITEMAP_CONTAINER_ID = 'sitemapContainer';
const sitemapContainer = getElementHtmlById(SITEMAP_CONTAINER_ID);

function SiteMap() {
    const node = useRef();
    usePreventReactRender(sitemapContainer, node);

    return (
        <div className={cx(styles.sitemap, 'sitemap-level-country')} id={SITEMAP_CONTAINER_ID} ref={node}>
            {config.get('isServer') ? <Meta description='${metaDescription}' title='${metaTitle}' /> : ''}
            {/* label TITLE / DESCRIPTION */}
            <Grid spacig={12} container>
                <Grid className={styles.headerWrapper} xs={7} item>
                    <Typography color={colors['deepBlue-500']} variant='head2b' gutterBottom>
                        {'${title}'}
                    </Typography>
                    <Typography variant='lgr' gutterBottom>
                        {'${description}'}
                    </Typography>
                </Grid>
            </Grid>

            {/* label BREADCRUMBS */}
            <Grid spacig={12} container>
                <div className='sitemap-breadcrumbs-wrapper'>
                    <Breadcrumbs separator='|'>
                        <Link color='inherit' href='#'>
                            <Typography color={colors['grey-400']} variant='smr'>
                                USA
                            </Typography>
                        </Link>
                        {'<c:forEach var="link" items="${breadcrumbList}">'}
                        {'<c:choose>'}
                        {'<c:when test="${not empty link.link}">'}
                        <Link color='inherit' href='${link.link}'>
                            <Typography color={colors['grey-400']} variant='smr'>
                                {'${link.label}'}
                            </Typography>
                        </Link>
                        {'</c:when>'}
                        {'<c:otherwise>'}
                        {'${link.label}'}
                        {'</c:otherwise>'}
                        {'</c:choose>'}
                        {'</c:forEach>'}
                    </Breadcrumbs>
                </div>
            </Grid>

            {/* label SITEMAP */}
            <Grid className={styles.sitemapContainer} spacig={12} container>
                <Grid xs={12} item>
                    {/* label OFFICE PROPERTIES */}
                    {'<c:if test="${not empty officeitems}">'}
                    <Typography className={styles.sitemapTitle} color={colors['deepBlue-500']} variant='h1b'>
                        Office properties
                    </Typography>
                    <ul className={styles.sitemapList}>
                        {'<c:forEach var="item" items="${officeitems}">'}
                        <li className={styles.sitemapItem}>
                            <Icon component={LocationSvg} fontSize={12} />
                            <Link.Secondary href='${pageContext.request.contextPath}/${item.url}'>
                                {'${item.name}'}
                            </Link.Secondary>
                        </li>
                        {'</c:forEach>'}
                    </ul>
                    {'</c:if>'}

                    {/* label RETAIL PROPERTIES */}
                    {'<c:if test="${not empty retailitems}">'}
                    <Typography className={styles.sitemapTitle} color={colors['deepBlue-500']} variant='h1b'>
                        Retail properties
                    </Typography>
                    <ul className={styles.sitemapList}>
                        {'<c:forEach var="item" items="${retailitems}">'}
                        <li className={styles.sitemapItem}>
                            <Icon component={LocationSvg} fontSize={12} />
                            <Link.Secondary href='${pageContext.request.contextPath}/${item.url}'>
                                {'${item.name}'}
                            </Link.Secondary>
                        </li>
                        {'</c:forEach>'}
                    </ul>
                    {'</c:if>'}

                    {/* label MULTI-FAMILY PROPERTIES */}
                    {'<c:if test="${not empty mfitems}">'}
                    <Typography className={styles.sitemapTitle} color={colors['deepBlue-500']} variant='h1b'>
                        Multi-Family properties
                    </Typography>
                    <ul className={styles.sitemapList}>
                        {'<c:forEach var="item" items="${mfitems}">'}
                        <li className={styles.sitemapItem}>
                            <Icon component={LocationSvg} fontSize={12} />
                            <Link.Secondary href='${pageContext.request.contextPath}/${item.url}'>
                                {'${item.name}'}
                            </Link.Secondary>
                        </li>
                        {'</c:forEach>'}
                    </ul>
                    {'</c:if>'}

                    {/* label ZIP CODES */}
                    {'<c:if test="${not empty items}">'}
                    <ul className={styles.sitemapList}>
                        {'<c:forEach var="item" items="${items}">'}
                        <li className={styles.sitemapItem}>
                            <Icon component={LocationSvg} fontSize={12} />
                            <Link.Secondary href='${pageContext.request.contextPath}/${item.url}'>
                                {'${item.name}'}
                            </Link.Secondary>
                        </li>
                        {'</c:forEach>'}
                    </ul>
                    {'</c:if>'}
                </Grid>
            </Grid>
        </div>
    );
}

export default SiteMap;
