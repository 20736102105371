import colors from '@themes/palette/export.module.scss';

const toggleButtonGroup = () => ({
    MuiToggleButtonGroup: {
        defaultProps: {},
        styleOverrides: {},
        variants: [
            {
                props: { variant: 'separated' },
                style: {
                    gap: '10px',
                    '& .MuiToggleButtonGroup-grouped.MuiToggleButton-root': {
                        borderRadius: '4px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        color: colors['darkNeutral-500'],
                        '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                            backgroundColor: colors['darkNeutral-500'],
                            color: colors.white,
                        },
                    },
                },
            },
            {
                props: { variant: 'card' },
                style: {
                    '&.MuiToggleButtonGroup-root': {
                        textTransform: 'initial',
                    },
                },
            },
        ],
    },
});

export default toggleButtonGroup;
