import base from '../base';
import styles from '../styles.module.scss';

const baseWithHeader = ({ header, ...rest }) => {
    return `
        <div>
          <span class="${styles.tooltipHeader}">${header}</span>
          <hr class="${styles.line}" />
          ${base(rest)}
        </div>`;
};

export default baseWithHeader;
