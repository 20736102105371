export const INPUTS = [
    {
        name: 'email',
        caption: 'Your work email address',
    },
    {
        name: 'firstName',
        caption: 'First name',
    },
    {
        name: 'lastName',
        caption: 'Last name',
    },
    {
        name: 'company',
        caption: 'Company name',
    },
    {
        name: 'phone',
        caption: 'Phone',
    },
];
