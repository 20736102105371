import {
    AddLoanStatusEventApiArg,
    DeleteLoanStatusEventApiArg,
    generatedApi,
    UpdateLoanStatusEventApiArg,
    UpdatePreDevelopmentDrawApiArg,
    UpdatePreDevelopmentDrawFundingApiArg,
} from '../generated/userLoans';

export const USER_LOANS_TAGS = {
    DEBT_SERVICE_TABLE: 'debtServiceTable',
    DRAW_LIST: 'drawList',
    DRAW: 'draw',
    SUMMARY_LIST: 'summaryList',
    DRAW_DOCUMENTS: 'drawDocuments',
    LOAN: 'loan',
} as const;

const enhanceOptions = {
    addTagTypes: [
        USER_LOANS_TAGS.DEBT_SERVICE_TABLE,
        USER_LOANS_TAGS.DRAW_LIST,
        USER_LOANS_TAGS.DRAW_DOCUMENTS,
        USER_LOANS_TAGS.SUMMARY_LIST,
        USER_LOANS_TAGS.DRAW,
        USER_LOANS_TAGS.LOAN,
    ],
    endpoints: {
        getDebtService: {
            providesTags: [USER_LOANS_TAGS.DEBT_SERVICE_TABLE],
            keepUnusedDataFor: 20,
        },
        updateHistoricalLoanData: {
            invalidatesTags: [USER_LOANS_TAGS.DEBT_SERVICE_TABLE],
        },
        getLoanDraw: {
            providesTags: [USER_LOANS_TAGS.DRAW_LIST],
            keepUnusedDataFor: 20,
        },
        createLoanDraw: {
            invalidatesTags: [USER_LOANS_TAGS.DRAW_LIST],
        },
        getLoanById: {
            providesTags: [USER_LOANS_TAGS.LOAN],
        },
        updatePreDevelopmentDrawFunding: {
            async onQueryStarted(arg: UpdatePreDevelopmentDrawFundingApiArg, { dispatch, queryFulfilled }) {
                const { preDrawId } = arg;

                const { data } = await queryFulfilled;

                if (data) {
                    const patchResult = dispatch(
                        generatedApi.util.updateQueryData('getPreDevelopmentDrawFunding', { preDrawId }, (draft) => {
                            Object.assign(draft, data);
                        })
                    );

                    queryFulfilled.catch(patchResult.undo);
                }
            },
        },
        updatePreDevelopmentDraw: {
            onQueryStarted(arg: UpdatePreDevelopmentDrawApiArg, { dispatch }) {
                dispatch(
                    generatedApi.util.updateQueryData(
                        'getPreDevelopmentDraw',
                        { preDrawId: arg.preDrawId },
                        (draft) => {
                            const transformedData = Object.entries(arg.updatePreDevelopmentRequest).reduce(
                                (acc, [key, value]) => {
                                    if (key === 'name') {
                                        return {
                                            ...acc,
                                            drawName: value,
                                        };
                                    }
                                    acc[key] = value;

                                    return acc;
                                },
                                {}
                            );

                            Object.assign(draft, {
                                data: {
                                    ...draft.data,
                                    ...transformedData,
                                },
                            });
                        }
                    )
                );
            },
        },
        getPreDevelopmentDraw: {
            providesTags: [USER_LOANS_TAGS.DRAW],
        },
        getPreDevelopmentDrawDocuments: {
            providesTags: [USER_LOANS_TAGS.DRAW_DOCUMENTS],
            keepUnusedDataFor: 20,
        },
        updateDocumentDraw: {
            invalidatesTags: [USER_LOANS_TAGS.DRAW_DOCUMENTS],
        },
        addLoanStatusEvent: {
            onQueryStarted(arg: AddLoanStatusEventApiArg, { dispatch }) {
                const { loanDetailsId, loanStatusEvent } = arg;

                dispatch(
                    generatedApi.util.updateQueryData('getLoanStatusEvents', { loanDetailsId }, (draft) => {
                        const pathResult = draft.data?.concat(loanStatusEvent).sort((a, b) => a.date - b.date);

                        Object.assign(draft, {
                            data: pathResult,
                        });
                    })
                );
            },
        },
        deleteLoanStatusEvent: {
            onQueryStarted(arg: DeleteLoanStatusEventApiArg, { dispatch }) {
                const { loanDetailsId, statusUuid } = arg;

                dispatch(
                    generatedApi.util.updateQueryData('getLoanStatusEvents', { loanDetailsId }, (draft) => {
                        const pathResult = draft.data
                            ?.filter((item) => item.uuid !== statusUuid)
                            .sort((a, b) => a.date - b.date);

                        Object.assign(draft, {
                            data: pathResult,
                        });
                    })
                );
            },
        },
        updateLoanStatusEvent: {
            onQueryStarted(arg: UpdateLoanStatusEventApiArg, { dispatch }) {
                dispatch(
                    generatedApi.util.updateQueryData(
                        'getLoanStatusEvents',
                        { loanDetailsId: arg.loanDetailsId },
                        (draft) => {
                            const pathResult = draft.data
                                ?.map((item) => {
                                    if (item.uuid === arg.loanStatusEvent.uuid) {
                                        return arg.loanStatusEvent;
                                    }

                                    return item;
                                })
                                .sort((a, b) => a.date - b.date);

                            Object.assign(draft, {
                                data: pathResult,
                            });
                        }
                    )
                );
            },
        },
    },
} as const;

export const userLoans = generatedApi.enhanceEndpoints(enhanceOptions);
