import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    target: {},
};

const popoverSlice = createSlice({
    name: '[ui] popover',
    initialState,
    reducers: {
        show(state, action) {
            state.isOpen = true;
            state.target = action.payload;
        },
        hide: () => initialState,
    },
});

export const { show: showPopover, hide: hidePopover } = popoverSlice.actions;

export const selectUiPopoverState = (state) => state.ui.popover;

export default popoverSlice.reducer;
