import { createSlice, createSelector } from '@reduxjs/toolkit';
import { take, put, takeLatest, takeEvery, delay, select } from 'redux-saga/effects';

import { userApi } from '@api';
import { loadToggle } from '@utils/store';
import { apiRequest, API_SUCCESS, API_ERROR, API_ERROR_CLEAN } from '@store/ducks/api';
import { getCurrentUserAuth, getUserInfoAuth, loginAuth, registerAuth, selectUserInfo, logoutAuth } from './auth';

const initialState = {
    data: null,
    error: null,
    loading: false,
    loaded: false,
    template: 'login',
    recoveryEmail: null,
    updatePasswordStatus: false,
    emailIsValid: false,
};

const entity = '[authMng]';
const updatePassword = 'updatePassword';

const authSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        updatePassword(state) {
            loadToggle(state);
        },
        [`${updatePassword} ${API_SUCCESS}`](state) {
            state.updatePasswordStatus = true;
        },
        setTemplate(state, action) {
            state.template = action.payload.name;

            if (action.payload.data) {
                state.data = action.payload.data
            }
        },
        resetPassword(state, action) {
            state.recoveryEmail = action.payload?.email;
            loadToggle(state);
        },
        cleanPasswordStatus(state) {
            state.updatePasswordStatus = false;
            state.loaded = false;
        },
        confirmPassword(state, action) {
            state.recoveryEmail = action.payload?.email;
            loadToggle(state);
        },
        confirmInvite(state) {
            loadToggle(state);
        },
        loaded(state) {
            state.loaded = true;
            state.loading = false;
        },
        startCheckEmail(state) {
            state.loaded = false;
            state.loading = true;
        },
        setValidEmail(state, action) {
            state.loaded = true;
            state.loading = false;
            state.emailIsValid = action.payload;
        },
        registrationSecondStep() {},
    },
});

export const {
    setError: setErrorAuthMng,
    updatePassword: loadUpdatePasswordAuthMng,
    setTemplate: setTemplateAuthMng,
    resetPassword: loadResetPasswordAuthMng,
    confirmPassword: loadConfirmPasswordAuthMng,
    confirmInvite: loadConfirmInviteAuthMng,
    cleanPasswordStatus: cleanPasswordStatusAuthMng,
    loaded: loadedAuthMng,
    startCheckEmail: startCheckEmailAuthMng,
    setValidEmail: setValidEmailAuthMng,
    registrationSecondStep: registrationSecondStepAuthMng,
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthMngState = (state) => state.authMng;
export const selectStatusAuthMngState = createSelector(
    selectAuthMngState,
    ({ error, updatePasswordStatus, loading, loaded, template, data }) => ({
        loading,
        error,
        isSuccess: updatePasswordStatus && loaded,
        template,
        data: error || data
    })
);

function* resetPasswordAuthMngSaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.resetPassword, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setTemplateAuthMng({ name: 'forgotPasswordSuccess' }));
    } else {
        yield put(setErrorAuthMng(action.payload));
    }
    yield put(loadedAuthMng());
}

function* confirmPasswordAuthMngSaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.confirmPassword, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setTemplateAuthMng({ name: 'confirmPasswordSuccess', password: payload.pwd }));
    } else {
        yield put(setErrorAuthMng(action.payload));
    }
    yield put(loadedAuthMng());
}

function* confirmInviteAuthMngSaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.inviteUser, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setTemplateAuthMng({ name: 'confirmInviteSuccess' }));
        const { email, password } = payload;
        yield put(loginAuth({ email, password }));
    } else {
        yield put(setErrorAuthMng(action.payload));
    }
    yield put(loadedAuthMng());
}

function* updatePasswordAuthMngSaga({ type, payload }) {
    const userInfo = yield select(selectUserInfo);
    yield put(
        apiRequest(
            { email: userInfo.email, pwd: payload.password, oldPwd: payload.currentPassword },
            userApi.updateUser,
            type
        )
    );

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    yield put(loadedAuthMng());

    if (action.type.endsWith(API_SUCCESS)) {
        yield delay(5000);
        yield put(cleanPasswordStatusAuthMng());
    } else {
        yield put(setErrorAuthMng(action.payload));
    }
}

function* setTemplateAuthMngSaga(action) {
    // eslint-disable-next-line default-case
    switch (action.payload.name) {
        case 'forgotPasswordSuccess':
            yield delay(4000);
            yield put(setTemplateAuthMng({ name: 'login' }));
            break;
        case 'confirmPasswordSuccess': {
            yield delay(1500);

            const { recoveryEmail } = yield select(selectAuthMngState);

            yield put(loginAuth({ password: action.payload?.password, email: recoveryEmail }));
            break;
        }
    }
}

function* startCheckEmailAuthMngSaga({ payload, type }) {
    yield put(apiRequest(payload, userApi.checkEmail, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith('SUCCESS')) {
        yield put(setValidEmailAuthMng(true));
        yield put(setErrorAuthMng(null));
    } else {
        yield put(setValidEmailAuthMng(false));
        yield put(setErrorAuthMng(action.payload));
    }
}

function* cleanAuthMngErrorSaga() {
    yield put(setErrorAuthMng(null));
}
function* authMngSaga({ meta }) {
    yield put(getUserInfoAuth());
    if (meta?.callback) {
        yield delay(meta.delay || 0);
        meta.callback();
    } else {
        yield put(getCurrentUserAuth());
    }
}

function* registrationSecondStepAuthMngSaga() {
    yield put(setTemplateAuthMng({ name: 'registered' }));
    yield put(getCurrentUserAuth());
}

function* logoutCleanAuthMngSaga() {
    yield put(setValidEmailAuthMng(false));
}

export function* watchAuthMng() {
    yield takeLatest(
        [
            `${loadResetPasswordAuthMng} ${API_ERROR_CLEAN}`,
            `${loadConfirmPasswordAuthMng} ${API_ERROR_CLEAN}`,
            `${loadConfirmInviteAuthMng} ${API_ERROR_CLEAN}`,
            `${loadUpdatePasswordAuthMng} ${API_ERROR_CLEAN}`,
        ],
        cleanAuthMngErrorSaga
    );
    yield takeLatest([`${loginAuth} ${API_SUCCESS}`, `${registerAuth} ${API_SUCCESS}`], authMngSaga);
    yield takeLatest([`${logoutAuth}`], logoutCleanAuthMngSaga);
    yield takeLatest(loadUpdatePasswordAuthMng, updatePasswordAuthMngSaga);
    yield takeEvery(setTemplateAuthMng, setTemplateAuthMngSaga);
    yield takeEvery(loadResetPasswordAuthMng, resetPasswordAuthMngSaga);
    yield takeEvery(loadConfirmPasswordAuthMng, confirmPasswordAuthMngSaga);
    yield takeEvery(loadConfirmInviteAuthMng, confirmInviteAuthMngSaga);
    yield takeLatest(startCheckEmailAuthMng, startCheckEmailAuthMngSaga);
    yield takeLatest(registrationSecondStepAuthMng, registrationSecondStepAuthMngSaga);
}
