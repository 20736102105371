import { getMapValue } from '@utils/components';
import OrderReportForm from '@shared/templates/forms/components/OrderForm';
import Success from '@shared/templates/forms/components/Success';

export const TEMPLATES = {
    MAIN: 'main',
    SUCCESS: 'success',
};

const DEFAULT_TEMPLATE = TEMPLATES.MAIN;

const reportFlowMap = new Map([
    [TEMPLATES.MAIN, OrderReportForm],
    [TEMPLATES.SUCCESS, ({ ...props }) => <Success {...props} />],
]);

const OrderReport = function ({ onSuccess, onUpdate, loading, template, address, link, formId }) {
    const Form = getMapValue(reportFlowMap, template, DEFAULT_TEMPLATE);

    return (
        <Form
            address={address}
            link={link}
            onSuccess={onSuccess}
            onUpdate={onUpdate}
            loading={loading}
            formId={formId}
        />
    );
};

export default OrderReport;
