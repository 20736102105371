import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useRouterPageOnLocationChange from '@shared/hooks/useRouterPageOnLocationChange';
import useScrollToTop from '@shared/hooks/useScrollToTop';
import useCustomEventListeners from '@scc/hooks/useCustomEventListeners';
import { selectIsAdmin } from '@scc/store/ducks/auth';
import { loadPageCommon, selectCurrentPageCommon } from '@scc/store/ducks/common';
import { ROLES } from '@store/helpers';
import links from '@scc/router/links';
import { addScript } from '@utils/browser';
import { GOOGLE_MAPS_API_SCRIPT_ID, GOOGLE_API_JS_PATH, GOOGLE_API_ORIGIN } from '@constants/maps';

const GOOGLE_MAPS_API_KEY = typeof process !== 'undefined' && process.env.GOOGLE_MAPS_API_KEY;

const config = {
    redirects: {
        [ROLES.LENDER]: {
            [links.home.path]: links.dashboard.path,
            [links.enterprise.path]: links.dashboard.path,
        },
        common: {
            [links.enterprise.path]: links.home.path,
        },
    },
};

const withGlobalBrowserFunctionality = function (App, { routes, redirects } = {}) {
    return function GlobalBrowserComponent(props) {
        const auth = useSelector(selectIsAdmin);
        const { path, status } = useSelector(selectCurrentPageCommon);
        const history = useHistory();

        useCustomEventListeners();
        useScrollToTop();
        useRouterPageOnLocationChange(routes, loadPageCommon);

        useEffect(() => {
            addScript({
                // eslint-disable-next-line max-len
                src: `${GOOGLE_API_ORIGIN}${GOOGLE_API_JS_PATH}?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=en&callback=Function.prototype`,
                node: 'head',
                id: GOOGLE_MAPS_API_SCRIPT_ID,
                async: true,
                onLoad: console.log,
            });
        }, []);

        useEffect(() => {
            if (typeof window !== 'undefined') {
                window.router = {
                    history,
                };
                window.auth = {
                    hasUser: !!auth.isLoggedIn,
                };
            }
            if (auth.isLender && !auth.isAdmin && path in config.redirects[ROLES.LENDER]) {
                history.replace(config.redirects[ROLES.LENDER][path]);
                return;
            }

            if (status === 'public' && auth.isLoggedIn && path in config.redirects.common) {
                history.replace(config.redirects.common[path]);
            }
        }, [history, auth.isAdmin, auth.isLoggedIn, auth.isLender, path]);

        return <App routes={routes} redirects={redirects} {...props} />;
    };
};

export default withGlobalBrowserFunctionality;
