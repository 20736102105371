import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { baseChartConfig } from './index';

const rentsDualed = merge(cloneDeep(baseChartConfig()), {
    series: [
        {
            name: 'In-place',
            data: [],
            type: 'bar',
            barWidth: 16,
            barGap: '20%',
        },
        {
            name: 'Market',
            data: [],
            type: 'bar',
            barWidth: 16,
        },
    ],
});

export default rentsDualed;
