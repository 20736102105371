import colors from '@themes/palette/export.module.scss';

const inputBase = () => ({
    MuiInputBase: {
        styleOverrides: {
            root: {
                width: 125,
                '&:hover': {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors['blue-A100']}!important`,
                    },
                },
                '&.Mui-focused': {
                    '&:before': {
                        borderBottom: `2px solid #4870FF !important`,
                    },
                    '&:after': {
                        borderBottom: 'none !important',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors['blue-main']}!important`,
                        boxShadow: `0px 1px 5px -1px ${colors['blue-300 ']}`,
                    },
                },
                '&.Mui-disabled': {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors['grey-A200']}!important`,
                        backgroundColor: colors['grey-A100'],
                        color: colors['grey-300'],
                        zIndex: '-1',
                    },
                    input: {
                        '-webkit-text-fill-color': colors['grey-300'],
                    },
                },
            },
            input: {
                color: colors['darkNeutral-500'],
                fontSize: 12,
                lineHeight: 1.3,
                height: 'auto',
                '&::placeholder': {
                    color: colors['grey-300'],
                    opacity: 1,
                },
            },
            fullWidth: {
                width: '100% !important',
            },
            adornedStart: {
                paddingLeft: 0,
            },
            inputAdornedStart: {
                paddingLeft: '0 !important',
            },
            adornedEnd: {
                paddingRight: 8,
            },
            inputAdornedEnd: {
                paddingRight: '0 !important',
            },
            inputSizeSmall: {
                padding: '5px 12px !important',
            },
        },
        variants: [
            {
                props: { size: 'medium' },
                style: {
                    width: 165,
                    '.MuiOutlinedInput-input': {
                        fontSize: 14,
                        lineHeight: '18px',
                        padding: '7px 12px !important',
                    },
                },
            },
            {
                props: { size: 'large' },
                style: {
                    width: 198,
                    '.MuiOutlinedInput-input': {
                        fontSize: 16,
                        lineHeight: '22px',
                        padding: '9px 16px',
                    },
                },
            },
            {
                props: { size: 'extra-large' },
                style: {
                    width: 238,
                    '.MuiOutlinedInput-input': {
                        fontSize: 16,
                        lineHeight: '22px',
                        padding: '13px 16px',
                    },
                },
            },
        ],
    },
});

export default inputBase;
