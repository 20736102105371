import throttle from 'lodash/throttle';

import { loadData, saveData } from '@utils/browser';
import { filterObjectByKeys } from '@utils/index';

import config from '@config';

const APP_VERSION = config.get('VERSION');

export const memoStore =
    (configureStore) =>
    ({ initialState = {}, reducers, persist = [] }) => {
        const domainPrefix = config.get('app');
        const isServer = config.get('isServer') || typeof window === 'undefined';
        const appVersion = loadData('APP_VER');
        let cacheState = {};

        if (APP_VERSION === appVersion) {
            cacheState = loadData(domainPrefix);
        } else {
            saveData('APP_VER', APP_VERSION);
        }

        const store = configureStore({ ...initialState, ...cacheState }, reducers);
        if (!isServer) {
            store.store.subscribe(
                throttle(
                    () =>
                        saveData(
                            domainPrefix,
                            filterObjectByKeys(store.store.getState(), (state) => persist.includes(state))
                        ),
                    '600'
                )
            );
        }
        return store;
    };

export const loadToggle = (state) => {
    const defaultLoad = state.loading;

    state.loaded = defaultLoad;
    state.loading = !defaultLoad;
};

export const rrid = (rentRollList) => {
    const confirmedRentRollList = rentRollList.filter((rr) => rr.confirmed && !rr.deleted);
    const activeRentRoll = confirmedRentRollList.find((rr) => rr.isActive);
    const prevRentRoll = confirmedRentRollList.at(1);

    return {
        rrid: activeRentRoll?.rrid || '',
        prevRrid: prevRentRoll?.rrid || '',
    };
};

export const osid = (rentRollStatementList) => {
    const confirmed = rentRollStatementList.find((rrs) => rrs.confirmed && !rrs.deleted);
    return {
        osid: confirmed?.osid || '',
    };
};
