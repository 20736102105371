import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, put, select, take, takeLatest } from 'redux-saga/effects';

import { RootState } from '@scc/index';

import { futureFundingApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

import { TFutureFundingState } from './types';

const entity = '[futureFunding]';

const initialState: TFutureFundingState = {
    data: {
        fundings: {
            content: [],
            totalElements: 0,
        },
        labels: [],
    },
    search: '',
    loaded: false,
    loading: false,
    sort: '',
    distribution: 'MONTHLY',
    page: 0,
};

const futureFundingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action: PayloadAction<{ search?: string; distribution?: string; page: number; sort?: string }>) {
            state.search = action.payload.search ?? '';
            state.distribution = action.payload.distribution ?? 'MONTHLY';
            state.page = action.payload.page ?? 0;
            state.sort = action.payload.sort ?? '';
            state.loaded = false;
            state.loading = true;
        },
        loadMore(
            state,
            action: PayloadAction<{ search?: string; distribution?: string; page: number; sort?: string }>
        ) {
            state.search = action.payload.search ?? '';
            state.distribution = action.payload.distribution ?? 'MONTHLY';
            state.page = action.payload.page ?? 0;
            state.sort = action.payload.sort ?? '';

            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;

            state.loaded = true;
            state.loading = false;
        },
        update(state, action: PayloadAction<Record<string, number | string>>) {
            state.loaded = !action?.payload?.loanDetailsId;
            state.loading = !!action?.payload?.loanDetailsId;
        },
        updateFinished(state) {
            state.loaded = true;
            state.loading = false;
        },
        setMoreData(state, action) {
            state.data.fundings.content = [...state.data.fundings.content, ...action.payload.fundings.content];

            state.loaded = true;
            state.loading = false;
        },
        resetState() {
            return initialState;
        },
    },
});

export const {
    load: loadFutureFunding,
    update: updateLoanFutureFunding,
    updateFinished: setUpdateSuccess,
    loadMore: loadMoreFutureFunding,
    setData: setFutureFunding,
    setMoreData: setFutureFundingMore,
    resetState: resetStateFutureFunding,
} = futureFundingSlice.actions;

export const selectFutureFundingState = (store: RootState) => store.loans.futureFunding;

export const selectFutureFundingParams = createSelector(selectFutureFundingState, ({ page, search, distribution }) => ({
    page,
    search,
    distribution,
}));

function* loadFutureFundingSaga({ payload, type }) {
    yield delay(200);

    yield put(apiRequest({ ...payload }, futureFundingApi.getFutureFunding, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;

    yield put(setFutureFunding(data));
}

function* loadMoreFutureFundingSaga({ payload, type }) {
    yield delay(200);

    yield put(apiRequest({ ...payload }, futureFundingApi.getFutureFunding, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;

    yield put(setFutureFundingMore(data));
}

function* updateLoanFutureFundingSaga({ payload, type }) {
    yield delay(200);

    yield put(apiRequest({ ...payload }, futureFundingApi.updateFutureFunding, type));

    yield take(`${type} ${API_SUCCESS}`);

    yield put(setUpdateSuccess());

    const params = yield select(selectFutureFundingParams);

    yield put(loadFutureFunding({ ...params, page: 0 }));
}

export default futureFundingSlice.reducer;

export function* watchFutureFunding() {
    yield takeLatest(updateLoanFutureFunding, updateLoanFutureFundingSaga);
    yield takeLatest(loadFutureFunding, loadFutureFundingSaga);
    yield takeLatest(loadMoreFutureFunding, loadMoreFutureFundingSaga);
}
