import CloseIcon from '@scc/assets/icons/close.svg';
import Logo from '@scc/components/Logo';

import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Stack from '@ui/Stack';

const defaultProps = {
    onClose: null,
};

function TitleContentDialog({ onClose }) {
    return (
        <>
            <Stack alignItems='center' direction='row' sx={{ flexGrow: 1 }}>
                <Logo type='link' />
            </Stack>
            <IconButton onClick={onClose}>
                <Icon aria-label='close' component={CloseIcon} fontSize='16px' viewBox='0 0 16 16' />
            </IconButton>
        </>
    );
}

TitleContentDialog.defaultProps = defaultProps;

export default TitleContentDialog;
