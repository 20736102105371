import { DateTimeFormatOptions } from '@utils/dates';

import colors from '@themes/palette/export.module.scss';

import DocIcon from '@icons/filled/document-1.svg';
import ProcessingIcon from '@icons/outlined/arrow-process.svg';
import AwaitingIcon from '@icons/outlined/clock-1.svg';
import InfoCircleIcon from '@icons/outlined/info-circle.svg';
import MessageQuestionIcon from '@icons/outlined/message-question.svg';
import NotConfirmedIcon from '@icons/outlined/minus-cirlce.svg';
import NotAcceptedIcon from '@icons/outlined/slash.svg';
import ConfirmedIcon from '@icons/outlined/tick-circle.svg';

export const REPORT_TYPES = {
    inspection: {
        value: 'INSPECTION',
        label: 'Inspection Report',
        color: colors['chart-14'],
        size: '13px',
        icon: DocIcon,
    },
    appraisal: {
        value: 'APPRAISAL',
        label: 'Appraisal Report',
        color: colors['chart-11'],
        size: '13px',
        icon: DocIcon,
    },
    evaluation: {
        value: 'EVALUATION',
        label: 'Evaluation Report',
        color: colors['chart-18'],
        size: '13px',
        icon: DocIcon,
    },
    property_photos: {
        value: 'PROPERTY_PHOTOS',
        label: 'Property Photos',
        color: colors['chart-19'],
        size: '13px',
        icon: DocIcon,
    },
    flood: {
        value: 'FLOOD',
        label: 'Flood Report',
        color: colors['chart-06'],
        size: '13px',
        icon: DocIcon,
    },
    environmental: {
        value: 'ENVIRONMENTAL',
        label: 'Environmental Report',
        color: colors['chart-20'],
        size: '13px',
        icon: DocIcon,
    },
    appraisal_review: {
        value: 'APPRAISAL_REVIEW',
        label: 'Appraisal Review Report',
        color: colors['chart-17'],
        size: '13px',
        icon: DocIcon,
    },
};

export const scenarioLabels = {
    lesser: 'Lesser of in-place and market',
    contract: 'In-place using contract',
    market: 'Mark-to-market',
    rollover: 'Rollover to market (5 Years)',
    downMarket: 'Down market',
    salescomp: 'Based on Sales Comps',
};

export const THIRD_PARTY_REPORT_STATUSES = {
    completed: {
        name: 'Completed',
        color: colors['grey-400'],
        icon: ConfirmedIcon,
        iconColor: colors['green-500'],
        iconSize: '14px',
    },
    ordered: {
        name: 'Ordered',
        color: colors['darkNeutral-500'],
        icon: AwaitingIcon,
        iconColor: colors.info,
        iconSize: '14px',
    },
    'not-ordered': {
        name: 'Not ordered',
        color: colors['darkNeutral-500'],
        icon: ProcessingIcon,
        iconColor: colors['yellow-500'],
        iconSize: '14px',
    },
};

export const DOCUMENT_TYPES = {
    RENT_ROLL: {
        value: 'RENT_ROLL',
        name: 'rent_roll',
        requestType: 'RENTROLL',
        type: 'rentRoll',
        label: 'Rent Roll',
    },
    OPERATING_STATEMENT: {
        value: 'OPERATING_STATEMENT',
        requestType: 'STATEMENT',
        name: 'operating_statement',
        type: 'operatingStatement',
        label: 'Operating Statement',
    },
    BUDGET: {
        value: 'BUDGET',
        name: 'budget',
        requestType: 'BUDGET_STATEMENT',
        type: 'budget',
        label: 'Budget',
    },
    DEVELOPMENT_BUDGET: {
        value: 'DEVELOPMENT_BUDGET',
        name: 'developmentBudget',
        type: 'developmentBudget',
        label: 'Development Budget',
    },
    TAX_RETURN: {
        value: 'TAX_RETURN',
        name: 'taxReturn',
        type: 'taxReturn',
        label: 'Tax Return',
    },
    BALANCE_SHEET: {
        value: 'BALANCE_SHEET',
        name: 'balanceSheet',
        type: 'balanceSheet',
        label: 'Balance Sheet',
    },
    APPRAISAL_REPORT: {
        value: 'APPRAISAL_REPORT',
        name: 'apprisal_report',
        requestType: 'APPRAISAL_REPORT',
        type: 'appraisalReport',
        label: 'Appraisal Report',
    },
    INFO_MEMO: {
        value: 'INFO_MEMO',
        name: 'infoMemo',
        type: 'infoMemo',
        label: 'Offering Memorandum',
    },
    LOAN_INFO_MEMO: {
        value: 'LOAN_INFO_MEMO',
        name: 'loanInfoMemo',
        type: 'loanInfoMemo',
        label: 'Loan Offering Memorandum',
    },
    LOAN_AGREEMENT: {
        value: 'LOAN_AGREEMENT',
        name: 'loanAgreement',
        type: 'loanAgreement',
        label: 'Loan Agreement',
    },
    LEASE_AGREEMENT: {
        value: 'LEASE_AGREEMENT',
        name: 'leaseAgreement',
        type: 'leaseAgreement',
        label: 'Lease Agreement',
    },
    GUARANTOR_FINANCIAL_STATEMENT: {
        value: 'GUARANTOR_FINANCIAL_STATEMENT',
        name: 'guarantorFinancialStatement',
        type: 'guarantorFinancialStatement',
        label: 'ACCORD Form',
    },
    INSURANCE_POLICY: {
        value: 'INSURANCE_POLICY',
        name: 'insurancePolicy',
        type: 'insurancePolicy',
        label: 'Insurance Policy',
    },
    INSPECTION_REPORT: {
        value: 'INSPECTION_REPORT',
        name: 'inspectionReport',
        type: 'inspectionReport',
        label: 'Inspection Report',
    },
    PROPERTY_PURCHASE_AGREEMENT: {
        value: 'PROPERTY_PURCHASE_AGREEMENT',
        name: 'propertyPurchaseAgreement',
        type: 'propertyPurchaseAgreement',
        label: 'Property Purchase Agreement',
    },
    INVOICE: {
        value: 'INVOICE',
        name: 'invoice',
        type: 'invoice',
        label: 'Invoice',
    },
    BANK_STATEMENT: {
        value: 'BANK_STATEMENT',
        name: 'bankStatement',
        type: 'bankStatement',
        label: 'Bank Statement',
    },
    OTHER: {
        value: 'OTHER',
        name: 'other',
        type: 'other',
        label: 'Other',
    },
};

export const UNDERWRITING_TYPES = {
    CAPITALIZED_NOI: {
        value: 'CAPITALIZED_NOI',
        label: 'Capitalized NOI',
        disabled: false,
    },
    DISCOUNTED_CASH_FLOW: {
        value: 'DISCOUNTED_CASH_FLOW',
        label: 'Discounted Cash Flow',
        displayLabel: 'DCF',
        disabled: true,
    },
} as const;

export const PORTFOLIO_STATUSES = [
    {
        label: 'Current Portfolio',
        value: 'CURRENT_PORTFOLIO',
        color: colors['green-500'],
    },
    {
        label: 'In Underwriting',
        value: 'IN_UNDERWRITING',
        color: '#92CAFF',
    },
    {
        label: 'LOI Issued',
        value: 'LOI_ISSUED',
        color: colors['yellow-500'],
    },
    {
        label: 'In Closing',
        value: 'IN_CLOSING',
        color: colors['blue-400'],
    },
    {
        label: 'Rejected',
        value: 'REJECTED',
        color: colors['red-500'],
    },
    {
        label: 'Sold',
        value: 'SOLD',
        color: colors['grey-A200'],
    },
];

export const USER_STATUSES = [
    {
        color: colors['green-500'],
        label: 'Invited',
        value: 'INVITED',
    },
    {
        color: colors['yellow-150'],
        label: 'Pending',
        value: 'PENDING',
    },
    {
        color: colors['red-500'],
        label: 'rejected',
        value: 'REJECTED',
    },
];

export const MEMBER_TYPES = {
    LENDER: 'Lender',
    INVESTOR: 'Investor',
    OTHER: 'Other',
    INDIVIDUAL: 'Individual',
    LLC: 'LLC',
    CORPORATION: 'Corporation',
};

export const SOURCES_TYPES = [
    {
        label: 'Equity',
        value: 'equity',
    },
    {
        label: 'Loan',
        value: 'loan',
    },
    {
        label: 'Other',
        value: 'other',
    },
];

export const USES_TYPES = [
    {
        label: 'Purchase Price',
        value: 'purchase',
    },
    {
        label: 'Transaction Fees',
        value: 'fees',
    },
    {
        label: 'Third Party Reports',
        value: 'reports',
    },
    {
        label: 'Other',
        value: 'other',
    },
];

export const STATUSES = {
    confirmed: 'confirmed',
    processing: 'processed',
    processed: 'processed',
    awaiting: 'awaiting',
    completed: 'completed',
    ordered: 'ordered',
    'not-ordered': 'not-ordered',
};

export const DOCUMENTS_STATUSES = {
    UNDER_REVIEW: {
        label: 'Under Review',
        value: 'UNDER_REVIEW',
        status: 'UNDER_REVIEW',
        icon: AwaitingIcon,
        color: colors['grey-300'],
    },
    ACCEPTED: {
        label: 'Accepted',
        value: 'ACCEPTED',
        color: colors['green-500'],
        icon: ConfirmedIcon,
    },
    NOT_ACCEPTED: {
        label: 'Not Accepted',
        value: 'NOT_ACCEPTED',
        icon: NotAcceptedIcon,
        color: colors['red-500'],
    },
    REQUEST_INFO: {
        label: 'Request info',
        value: 'REQUEST_INFO',
        icon: MessageQuestionIcon,
        color: colors['blue-main'],
    },
    STANDARDIZED_NOT_REVIEWED: {
        label: 'Not Reviewed',
        value: 'STANDARDIZED_NOT_REVIEWED',
        icon: NotConfirmedIcon,
        color: colors['blue-main'],
    },
    REVIEWED_BY: {
        label: 'Reviewed',
        value: 'REVIEWED_BY',
        icon: ConfirmedIcon,
        color: colors['blue-main'],
    },
    APPROVED_BY: {
        label: 'Approved',
        value: 'APPROVED_BY',
        icon: ConfirmedIcon,
        color: colors['blue-main'],
    },
    QUESTIONS: {
        label: 'Questions',
        value: 'QUESTIONS',
        icon: MessageQuestionIcon,
        color: colors['yellow-500'],
    },
    PROCESSING: {
        label: 'Processing',
        value: 'PROCESSING',
        icon: AwaitingIcon,
        color: colors['lightBlue-500'],
    },
    WAITING_STANDARDIZATION: {
        label: 'Waiting Standardization',
        value: 'WAITING_STANDARDIZATION',
        icon: InfoCircleIcon,
        color: colors['lightBlue-500'],
    },
    WAITING_PAGES: {
        label: 'Waiting page selection',
        value: 'WAITING_PAGES',
        icon: InfoCircleIcon,
        color: colors['yellow-500'],
    },
    ERROR: {
        label: 'Error',
        value: 'ERROR',
        icon: NotAcceptedIcon,
        color: colors['red-500'],
    },
};

export const FINANCIALS = {
    REVENUE: 'Revenue',
    EXPENSES: 'Expenses',
    TOTAL_EXPENSES: 'Total Expenses',
    REVENUE_BASE_RENTAL: 'Gross Potential Rent',
    REVENUE_CONCESSION: 'Concessions',
    REVENUE_EXPENSE_REIMBURSTMENT: 'Expense Reimbursement',
    REVENUE_OTHER: 'Other Income',
    REVENUE_ELIMINATED: 'Eliminated Income',
    REVENUE_VACANCY: 'Vacancy',
    REVENUE_PERCENTAGE_RENT: 'Percentage Rent',
    REVENUE_PARKING: 'Parking Income',
    REVENUE_SENIOR_OTHER: 'Other Senior Housing Revenue',
    REVENUE_DEP_OTHER: 'Other Departmental Revenue',
    REVENUE_LAUNDRY_VENDING: 'Laundry/Vending Income',
    EXPENSES_TAXES: 'Real Estate Taxes',
    EXPENSES_UTILITIES: 'Utilities',
    EXPENSES_INSURANCE: 'Property Insurance',
    EXPENSES_CLEANING: 'Janitorial',
    EXPENSES_MAINTENANCE: 'Repairs and Maintenance',
    EXPENSES_MANAGEMENT: 'General and Administrative',
    EXPENSES_OTHER: 'Other Expenses',
    EXPENSES_ELIMINATED: 'Other Eliminated Expenses',
    EXPENSES_ELIMINATED_SUB: 'Eliminated Expenses',
    OTHER_EXPENSES_ELIMINATED_SUB: 'Eliminated Expenses',
    ELIMINATED_1: 'Eliminated Items',
    EXPENSES_PAYROLL: 'Payroll & Benefits',
    EXPENSES_PROFESSIONAL_FEES: 'Professional Fees',
    EXPENSES_ADVERTISING: 'Advertising & Marketing',
    EXPENSES_LEASING: 'Leasing Commissions',
    EXPENSES_GROUND_RENT: 'Ground Rent',
    EXPENSES_MANAGEMENT_FEE: 'Management Fees',
    EXPENSES_TENANT_IMPROVEMENTS: 'Tenant Improvements',
    EXPENSES_CAPITAL: 'Capital Expenditures',
    EXPENSES_EXTRA_CAPITAL: 'Extraordinary Capital Expenditures',
    EXPENSES_RESERVES: 'Reserves',
    EXPENSES_DEBT: 'Debt Service',
    NET_OPERATING_INCOME: 'Net Operating Income',
    EXPENSES_NCF: 'Capital Expenses',
    OTHER_LEASING_NCF: 'Leasing Commissions',
    OTHER_TENANT_IMPROVEMENTS_NCF: 'Tenant Improvements',
    OTHER_CAPITAL_NCF: 'Capital Expenditures',
    OTHER_RESERVES_NCF: 'Reserves',
    OTHER_EXTRA_CAPITAL_NCF: 'Extraordinary Capital Expenditures',
    OTHER_NCF: 'Other',
    OTHER_DEBT: 'Debt Service',
    OTHER: 'Removed Items',
    TOTALS: 'Totals',
    ADDED_REVENUE: 'Revenue',
    ADDED_EXPENSES: 'Expenses',
    OTHER_ADDED_REVENUE: 'Added Revenues',
    OTHER_ADDED_EXPENSES: 'Added Expenses',
    ADDED_ITEMS: 'Added Items',
    ADDED_ELIMINATED: 'Added Items',
    TOTAL_BASE_RENT: 'Base Rent',
    TOTAL_REVENUE: 'Total Revenue',
    TOTAL_NOI: 'Net Operating Income',
    TOTAL_NET_CASHFLOW: 'Net Cash FLow',
    TOTAL_NET_CASHFLOW_AFTER_DEBT: 'Net Cash Flow after Debt Service',
    TOTAL_CAPITAL_EXPENSES: 'Total Capital Expenses',
    AR_OCCUPIED_SQFT: 'Occupied SF',
    AR_TOTAL_SQFT: 'Total SF',
    AR_DSCR_FORWARD: 'DSCR at Forward SOFR',
    AR_DSCR_SOFR: 'DSCR at SOFR Cap',
    AR_DSCR_REPO: 'DSCR at Repo',
    AR_PROPERTY_VALUE: 'Property Value',
    AR_CAP_RATE: 'Cap Rate',
    AR_LOAN_AMOUNT: 'Loan Amount',
    AR_LTV: 'LTV',
    AR_DEBT_YIELD: 'Debt Yield',
    AR_CURRENT_BALANCE_DY: 'Current Balance DY',
    AR_REPO_FINANCING: 'Repo Financing',
    AR_LTV2: 'LTV',
    AR_DEBT_YIELD2: 'Debt Yield',
    AR_CURRENT_BALANCE_DY2: 'Current Balance DY',
    AR_INITIAL_FUNDING: 'Initial Funding',
    AR_TI_LC_NEW_LEASING: 'TI/LCs New Leasing',
    AR_TI_LC_RENEWAL_LEASING: 'TI/LCs Renewal Leasing',
    AR_LOAN_BALANCE: 'Loan Balance',
    AR_LOAN_PRINCIPAL: 'Loan Principal',
    AR_REPO_BALANCE: 'Repo Balance',
    AR_REPO_PRINCIPAL: 'Repo Principal',
    DSCR_NOI: 'DSCR NOI',
    DSCR_NCF: 'DSCR NCF',
    AR_OCCUPIED_UNITS: 'Occupied Units',
    AR_TOTAL_UNITS: 'Total Units',
};

export const DATE_FORMAT: Record<string, DateTimeFormatOptions> = {
    default: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    },
    noDay: {
        day: undefined,
        month: 'short',
        year: 'numeric',
    },
    noYear: {
        day: 'numeric',
        month: 'short',
        year: undefined,
    },
};

export const SCORE_PARAMETER_LABELS = {
    ltv: 'LTV',
    dscr: 'DSCR',
    coupon: 'Coupon',
    debtYield: 'Debt Yield',
    occupancy: 'Occupancy',
    walt: 'WALT',
    other: 'Other',
    qualityOfTenants: 'Quality of Tenant',
    rentVsMarket: 'Rents vs Market',
    propertyValueVsMarket: 'Property Value vs Market',
    qualityOfSponsor: 'Quality of Sponsor',
    location: 'Location Score',
    markerFutureGrowth: 'Growth Score',
    riskOfDecline: 'Risk of Value Decline',
    newConstructionRisk: 'New Construction Risk',
};

export const EDITABLE_SCORE_PARAMETER_LABELS = {
    newConstructionRisk: 'New Construction Risk',
    markerFutureGrowth: 'Growth Score',
    qualityOfTenants: 'Quality of Tenant',
    other: 'Other',
    qualityOfSponsor: 'Quality of Sponsor',
};

export const PERCENT_SCORE_PARAMETER_LABELS = {
    propertyValueVsMarket: 'Property Value vs Market',
    rentVsMarket: 'Rents vs Market',
    occupancy: 'Occupancy',
    debtYield: 'Debt Yield',
    ltv: 'LTV',
    coupon: 'Coupon',
};

export const LETTER_SCORE_PARAMETER_LABELS = {
    location: 'Location Score',
};

export const SCORING_KEYS_MAP = {
    loan: ['coupon', 'ltv', 'dscr', 'debtYield'],
    market: ['location', 'riskOfDecline', 'newConstructionRisk', 'markerFutureGrowth'],
    property: ['occupancy', 'rentVsMarket', 'qualityOfTenants', 'walt', 'propertyValueVsMarket', 'other'],
    sponsor: ['qualityOfSponsor'],
};

export const TEAM_TYPES_VALUES = {
    BORROWER: 'BORROWER',
    LENDER: 'LENDER',
};

export const LOAN_TEAM_ROLE_MAP = {
    ANALYST: {
        label: 'Analyst',
        value: 'ANALYST',
    },
    MANAGER: {
        label: 'Manager',
        value: 'MANAGER',
    },
    SUPERVISOR: {
        label: 'Supervisor',
        value: 'SUPERVISOR',
    },
    UNDERWRITER: {
        label: 'Underwriter',
        value: 'UNDERWRITER',
    },
    ASSET_MANAGER: {
        label: 'Asset Manager',
        value: 'ASSET_MANAGER',
    },
    ORIGINATOR: {
        label: 'Originator',
        value: 'ORIGINATOR',
    },
};

export const LOAN_TEAM_USER_ROLE_MAP = {
    [TEAM_TYPES_VALUES.BORROWER]: {
        ...LOAN_TEAM_ROLE_MAP,
        FINANCIALS_CONTACT: {
            label: 'Financials Contact',
            value: 'FINANCIALS_CONTACT',
        },
        INSPECTION_CONTACT: {
            label: 'Inspection Contact',
            value: 'INSPECTION_CONTACT',
        },
        INSURANCE_CONTACT: {
            label: 'Insurance Contact',
            value: 'INSURANCE_CONTACT',
        },
        NOTICES_CONTACT: {
            label: 'Notices Contact',
            value: 'NOTICES_CONTACT',
        },
    },
    [TEAM_TYPES_VALUES.LENDER]: {
        ...LOAN_TEAM_ROLE_MAP,
    },
};
