import cx from 'classnames';
import { useState, useEffect, useRef } from 'react';

import { stringOrFunctionChecker } from '@utils/index';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Input from '@ui/Input';
import InputAdornment from '@ui/InputAdornment';
import Popover from '@ui/Popover';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import ArrowDownSvg from '@icons/outlined/arrow-down.svg';
import CloseSvg from '@icons/outlined/close.svg';
import SearchSvg from '@icons/outlined/search-normal-1.svg';
import UseDebouncedEffect from '@shared/hooks/useDebouncedEffect';

import entitiesMap from './helpers';
import styles from './styles.module.scss';

const POPOVER_WIDTH = 300;

function getTrigger(handleOpenPopover, cellId, value, renderItem, interactive, anchorEl, singleLabel) {
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div aria-describedby={cellId} onClick={handleOpenPopover}>
            {value.length ? (
                renderItem({ item: value[0], value, showMore: true, interactiveClass: styles.interactiveCell })
            ) : interactive ? (
                <Button
                    className={cx(styles.triggerButton, {
                        [styles.triggerButtonActive]: Boolean(anchorEl),
                    })}
                    endIcon={<Icon component={ArrowDownSvg} />}
                    size='small'
                    variant='contained'
                >
                    Select {singleLabel}
                </Button>
            ) : (
                <Typography color={colors['darkNeutral-500']} variant='hr13'>
                    {`No Linked ${singleLabel}`}
                </Typography>
            )}
        </div>
    );
}

function EntityCell({
    value: initialValue = [],
    row: { id: rowId, original },
    onUpdateData,
    column: { headerProps },
    cell: { column: { id: columnId } = {} },
}) {
    const innerContainerRef = useRef(null);
    const [popoverWidth, setPopoverWidth] = useState(POPOVER_WIDTH);
    const [isShowSearchInput, setIsShowSearchInput] = useState(false);

    const [value, setValue] = useState(initialValue);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const { interactive, cellData: { entity } = {} } = stringOrFunctionChecker(headerProps, original);

    const cellId = rowId + columnId;
    const { key, singleLabel, renderItem, fetchItems } = entitiesMap[entity];

    const handleOpenPopover = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!interactive) return;
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
        setIsShowSearchInput(false);
    };

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleClickCheckbox = (event, listItem) => {
        if (value.map((item) => item[key]).includes(listItem[key])) {
            setValue((prev) => prev.filter((item) => item[key] !== listItem[key]));
        } else {
            setValue((prev) => [...prev, listItem]);
        }
    };

    const handleSubmitProperties = (event) => {
        const toAdd = value
            .filter((valueItem) => !initialValue.map((item) => item[key]).includes(valueItem[key]))
            .map((item) => ({
                [key]: item[key],
            }));

        const toRemove = initialValue
            .filter((valueItem) => !value.includes(valueItem))
            .map((item) => ({ [key]: item[key] }));
        onUpdateData({ rowIndex: rowId, value: { toRemove, toAdd } });
        handleClosePopover(event);
    };

    const handleShowInput = () => setIsShowSearchInput(true);

    const handleCloseSearch = () => {
        setIsShowSearchInput(false);
        setSearch('');
    };

    const inputProps = {
        endAdornment: (
            <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' edge='end' size='small' onClick={handleCloseSearch}>
                    <Icon color={colors['grey-400']} component={CloseSvg} />
                </IconButton>
            </InputAdornment>
        ),
    };

    const renderOptions = options.filter((option) => !initialValue.map((item) => item[key]).includes(option[key]));

    const showLinkedProperties = !!initialValue.length;

    const isDisableButton =
        !value.filter((valueItem) => !initialValue.includes(valueItem)).length &&
        !initialValue.filter((valueItem) => !value.includes(valueItem)).length;
    const buttonText = showLinkedProperties ? 'Update' : 'Select';

    useEffect(() => {
        if (!innerContainerRef.current) {
            return;
        }

        const { offsetWidth } = innerContainerRef.current;

        setPopoverWidth(offsetWidth > POPOVER_WIDTH ? offsetWidth + 20 : POPOVER_WIDTH);
    }, [options]);

    UseDebouncedEffect(
        () => {
            const loadOptions = async () => {
                const optionsResult = await fetchItems({ value: initialValue, searchProp: search });
                setOptions(optionsResult);
            };

            if (anchorEl) {
                loadOptions();
            }
        },
        [search, anchorEl],
        500,
        () => {
            setOptions([]);
        }
    );

    return (
        <>
            {getTrigger(handleOpenPopover, cellId, value, renderItem, interactive, anchorEl, singleLabel)}
            <Popover
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    sx: {
                        boxShadow: styles.paper,
                    },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                id={cellId}
                open={Boolean(anchorEl)}
                onClose={handleClosePopover}
            >
                <div style={{ width: popoverWidth }}>
                    <div className={styles.body} ref={innerContainerRef}>
                        {/* label HEADER */}
                        {showLinkedProperties && (
                            <>
                                <div className={styles.header}>
                                    <div className={styles.initialBlockWrapper}>
                                        <div className={styles.initialTitleWrapper}>
                                            <Typography.Uppercase color={colors['grey-300']} variant='xsb'>
                                                Linked {singleLabel}
                                            </Typography.Uppercase>
                                        </div>

                                        <div className={styles.listWrapper}>
                                            {initialValue.map((option) =>
                                                renderItem(
                                                    { item: option, value, interactive: true },
                                                    handleClickCheckbox,
                                                    styles.listItemPadding
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.divider} />
                            </>
                        )}

                        <div className={styles.contentWrapper}>
                            {/* label SEARCH */}
                            <div className={styles.searchWrapper}>
                                {!isShowSearchInput && (
                                    <>
                                        <Typography.Uppercase color={colors['grey-300']} variant='xsb'>
                                            Link {singleLabel} to this document
                                        </Typography.Uppercase>
                                        <IconButton onClick={handleShowInput}>
                                            <Icon color={colors['grey-400']} component={SearchSvg} fontSize='20px' />
                                        </IconButton>
                                    </>
                                )}
                                {isShowSearchInput && (
                                    <Input
                                        InputProps={inputProps}
                                        placeholder={`Search ${singleLabel}...`}
                                        value={search}
                                        autoFocus
                                        fullWidth
                                        onChange={(event) => handleChangeSearch(event)}
                                    />
                                )}
                            </div>

                            {/* label CONTENT */}
                            {!!options.length && (
                                <div className={styles.listWrapper}>
                                    {renderOptions.map((option) =>
                                        renderItem(
                                            { item: option, value, interactive: true },
                                            handleClickCheckbox,
                                            styles.listItemPadding
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <Button
                            disabled={isDisableButton}
                            size='small'
                            fullWidth
                            onClick={(event) => handleSubmitProperties(event)}
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </Popover>
        </>
    );
}

export default EntityCell;
