import cx from 'classnames';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import LoginBase from '@scc/components/LoginBase';
import Logo from '@scc/components/Logo';
import PartialModal from '@scc/components/PartialModal';
import AuthComments from '@scc/pages/Auth/AuthComments';
import { getStepTitles } from '@scc/pages/Auth/helpers';
import styles from '@scc/pages/Auth/styles.module.scss';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import Copyright from '@components/Copyright';
import Meta from '@components/Meta';

import colors from '@themes/palette/export.module.scss';

import TimeoutPng from '@assets/icons/components/timeout.png';

function DesktopLayout({ template, dialogProps, onSuccess, loading, errorStatus }) {
    const { meta, welcomeSection, partialModal } = getStepTitles(template, { errorStatus });
    const isShowIcon = welcomeSection?.icon;

    const { search } = useLocation();

    const isTimeout = useMemo(() => {
        return new URLSearchParams(search).get('isTimeout') ?? false;
    }, [search]);

    return (
        <Box className={styles.modal}>
            <Meta description={meta?.description} title={meta?.title} />
            <Grid direction='row' container>
                <Grid className={styles.welcomeSection} item xs>
                    <Stack direction='column' justifyContent='space-between' sx={{ height: 1, width: '100%' }}>
                        {template === 'login' ||
                        template === 'inviteUser' ||
                        template === 'requestDemo' ||
                        template === 'requestDemoSuccess' ||
                        template === 'requestDemoAlertError' ? (
                            <>
                                <div>
                                    <Logo type='link' />
                                </div>
                                <div className={cx({ [styles.alignCenter]: isShowIcon })}>
                                    {isShowIcon && (
                                        <Icon
                                            color={colors['grey-200']}
                                            component={welcomeSection?.icon}
                                            fontSize='86px'
                                        />
                                    )}
                                    {isTimeout ? (
                                        <Box alignItems='center' display='flex' flexDirection='column'>
                                            <Box height={200} width={240}>
                                                <img alt='timeout' height='100%' src={TimeoutPng} width='100%' />
                                            </Box>
                                            <Typography fontWeight='bold' pt={16} variant='hsb14'>
                                                Your session has expired
                                            </Typography>
                                            <Typography pt={2} variant='hr13'>
                                                Please login. We kept all of your progress in place
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography className={styles.message} component='span' variant='h1b'>
                                            {welcomeSection?.title}
                                        </Typography>
                                    )}
                                </div>
                                <Typography
                                    className={styles.subMessage}
                                    color={colors['darkNeutral-500']}
                                    sx={{ fontWeight: 'bold' }}
                                    variant='head1'
                                >
                                    {welcomeSection?.subTitle}
                                </Typography>
                            </>
                        ) : (
                            <AuthComments />
                        )}
                        <Copyright />
                    </Stack>
                </Grid>
                <Grid xs='auto' item>
                    <PartialModal
                        className={styles.partialModal}
                        contentClassName={styles.content}
                        title={partialModal?.title}
                        titleClassName={styles.title}
                        onClose={dialogProps?.onClose}
                    >
                        <LoginBase loading={loading} onClose={dialogProps?.onClose} onSuccess={onSuccess} />
                    </PartialModal>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DesktopLayout;
