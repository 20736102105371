import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    noBack: true,
    fullScreen: false,
    state: {
        empty: true,
        noHeader: true,
    },
    data: {},
};

const dialogSlice = createSlice({
    name: '[ui] dialog',
    initialState,
    reducers: {
        show(state, action) {
            state.isOpen = true;
            state.noBack = true;
            state.content = action.payload.content || '';
            state.data = action.payload.data;
            state.className = action.payload.className;
            state.paperProps = action.payload.paperProps;
            state.fullScreen = action.payload.fullScreen ?? state.fullScreen;
        },
        showPage(state, action) {
            state.isOpen = true;
            state.noBack = true;
            state.fullScreen = true;
            state.content = action.payload.content;
            state.data = action.payload.data;
            state.className = action.payload.className;
            state.contentProps = action.payload.contentProps;
            state.state = {
                noHeader: false,
                ...action.payload.state,
            };
        },
        showEmpty(state, action) {
            state.isOpen = true;
            state.noBack = true;
            state.content = action.payload.content || '';
            state.data = action.payload.data;
            state.className = action.payload.className;
            state.state = {
                noHeader: true,
                ...action.payload.state,
            };
        },
        hide() {
            return initialState;
        },
    },
});

export const {
    show: showDialog,
    showPage: showPageDialog,
    showEmpty: showEmptyDialog,
    hide: hideDialog,
} = dialogSlice.actions;

export const selectUiDialogState = (state) => state.ui.dialog;

export default dialogSlice.reducer;
