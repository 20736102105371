import { createSlice } from '@reduxjs/toolkit';
import { put, select, take, takeLatest } from 'redux-saga/effects';

import { detailedProperty } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[detailedPropertyMarket]';
const size = 20;

const initialState = {
    loaded: false,
    loading: false,
    data: {
        topEmployers: {},
    },
};

const marketSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadTopEmployers(state) {
            state.loading = true;
        },
        loadMoreTopEmployers() {},
        addMoreTopEmployers(state, { payload }) {
            state.data.topEmployers.data = payload;
        },
        setTopEmployers(state, { payload }) {
            state.data.topEmployers = payload;
            state.loading = false;
        },
    },
});

export const {
    loadTopEmployers: loadTopEmployersData,
    loadMoreTopEmployers: loadMoreTopEmployersData,
    addMoreTopEmployers: addMoreTopEmployersData,
    setTopEmployers: setTopEmployersData,
} = marketSlice.actions;

export default marketSlice.reducer;

export const selectDetailedMarketState = (store) => store.detailedProperty.market;

function* loadTopEmployersDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getMarketTopEmployers, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload;
    yield put(setTopEmployersData({ data: content, totalCount: totalElements }));
}

function* loadMoreTopEmployersDataSaga({ type, payload }) {
    const {
        data: {
            topEmployers: { data: prevData },
        },
    } = yield select(selectDetailedMarketState);
    yield put(apiRequest({ ...payload, page: prevData.length / size }, detailedProperty.getMarketTopEmployers, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload;
    yield put(addMoreTopEmployersData([...prevData, ...content]));
}

export function* watchMarket() {
    yield takeLatest(loadTopEmployersData, loadTopEmployersDataSaga);
    yield takeLatest(loadMoreTopEmployersData, loadMoreTopEmployersDataSaga);
}
