/* eslint-disable react/jsx-no-duplicate-props */
import { formatter } from '@utils/index';

import Input from '@ui/Input';

import { BlockedControl } from '@components/BlockedBox';

import styles from './styles.module.scss';

function InputCell({
    value: initialValue,
    column: {
        headerProps: {
            cellData: { isLimited = false, onBlurCallback, inputProps: { startAdornment, ...restInputProps } } = {},
        } = {},
    },
    onUpdateData,
    cell: {
        column: { id: accessorName },
    },
}) {
    const handleBlur = (event) => {
        if (onBlurCallback) {
            const { name, value } = event.target;
            onBlurCallback(name, value);
        }
    };

    return (
        <div className={styles.wrapper}>
            <BlockedControl isLimited={isLimited}>
                <Input.Outlined
                    InputProps={{ startAdornment }}
                    className={styles.input}
                    disabled={isLimited}
                    inputProps={{ ...restInputProps }}
                    name={accessorName}
                    value={initialValue === 0 ? '' : formatter.number(initialValue)}
                    onBlur={handleBlur}
                    onChange={(event) =>
                        onUpdateData({ cell: { name: event.target.name, value: event.target.value }, event })
                    }
                />
            </BlockedControl>
        </div>
    );
}

export default InputCell;
