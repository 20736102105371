import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { parseDate } from '@utils/dates';

import colors from '@themes/palette/export.module.scss';

import { baseChartConfig, baseYAxisConfig } from './index';

const rentsForNewLeases = merge(cloneDeep(baseChartConfig()), {
    grid: {
        right: '40px',
        left: '40px',
    },
    legend: {
        bottom: '0',
        left: 'center',
        textStyle: {
            color: colors['darkNeutral-500'],
            fontFamily: 'Open Sans',
            fontSize: 12,
            lineHeight: 18,
        },
    },
    xAxis: {
        axisLabel: {
            formatter: (value) => {
                const label = parseDate(value);
                return `{a|${label[0]}} \n {b|${label[2]}}`;
            },
            rich: {
                a: {
                    align: 'center',
                    color: colors['darkNeutral-500'],
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    fontSize: 11,
                    lineHeight: 14,
                    padding: [0, 0, 0, 5],
                },
                b: {
                    fontFamily: 'Open Sans',
                    color: colors['grey-400'],
                    fontWeight: 300,
                    fontSize: 11,
                    lineHeight: 14,
                },
            },
        },
    },
    yAxis: [
        merge(cloneDeep(baseYAxisConfig), {
            boundaryGap: ['25%', '25%'],
        }),
        merge(cloneDeep(baseYAxisConfig), {
            name: '$',
        }),
    ],
});

export default rentsForNewLeases;
