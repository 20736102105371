import styles from '../DrawerList/styles.module.scss';

export const DRAWER_TYPE = {
    DEFAULT_DRAWER: 'defaultDrawer',
    PRIMARY_ASIDE_DRAWER: 'primaryAsideDrawer',
    PRIMARY_PAGE_DRAWER: 'primaryPageDrawer',
    PRIMARY_PAGE_BOTTOM_DRAWER: 'primaryPageBottomDrawer',
};

export const DRAWER_STYLES_BY_TYPE = {
    [DRAWER_TYPE.DEFAULT_DRAWER]: styles.defaultDrawer,
    [DRAWER_TYPE.PRIMARY_ASIDE_DRAWER]: styles.primaryAsideDrawer,
    [DRAWER_TYPE.PRIMARY_PAGE_BOTTOM_DRAWER]: styles.primaryPageBottomDrawer,
    [DRAWER_TYPE.PRIMARY_PAGE_DRAWER]: styles.primaryPageDrawer,
};

export const ANCHOR = {
    RIGHT: 'right',
    BOTTOM: 'bottom',
};
