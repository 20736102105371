/* eslint-disable max-len */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceInYears } from 'date-fns';
import { delay, put, select, take, takeLatest } from 'redux-saga/effects';

import { RootState } from '@scc/index';
import {
    TKeyDate,
    TKeyDateKeys,
} from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/KeyDates/KeyDates.types';
import {
    TLoanDataReservesBalanceCustom,
    TLoanDataReservesCommitmentValue,
} from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Reserves/Reserves.types';
import { getIoAmortization } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Terms/helpers';
import links from '@scc/router/links';
import { USER_LOANS_TAGS, userLoans, DENORMALIZED_LOANS_TAGS, denormalizedLoansApi } from '@scc/services/apis';
import { selectCurrentPageCommon } from '@scc/store/ducks/common';
import { loadDetailsLoan, saveDetailsLoan, setDetailsLoan } from '@scc/store/ducks/loans/loan';
import { TMember } from '@scc/store/ducks/loans/types/members.types';
import { loadMemberLoans } from '@scc/store/ducks/members/member';

import { loanRatesApi, loansApi, propertiesApi, propertyNotesApi } from '@api';
import { LOAN, LOANS_VIEW_TYPE } from '@constants/loans';
import { EDIT_LOAN_FORM, SEARCH_PROPERTY } from '@constants/modals';
import { transformRates } from '@modules/FinancialModel/helpers/helpers';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { GET_EVENT } from '@store/ducks/event';
import { showDialog } from '@store/ducks/ui/dialog';
import { hideDrawer, showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

import { TLoanDetails, TLoanFunding, TLoansState, TLoanTerms, UpdateLoanDataReservesPayload } from './types';

const VIEW_TYPE_MAPS = {
    DEFAULT: LOANS_VIEW_TYPE.ALL,
    [links.financing.name]: LOANS_VIEW_TYPE.FINANCING,
    [links.financings.name]: LOANS_VIEW_TYPE.FINANCING,
};

const getParams = (page, countyId, parcelId) => {
    switch (page) {
        case links.financing.name:
        case links.financings.name:
            return { countyId, parcelId };
        default:
            return {};
    }
};

const transformTerms = (terms) => {
    const { coupon, ...rest } = terms;

    return {
        ...rest,
        coupon: coupon?.value,
        coupon365: coupon?.coupon365,
        ioAmortizing: getIoAmortization(terms.ioAmortizing),
    };
};

const entity = '[loans]';
const size = 20;

const initialState: TLoansState = {
    totalCount: 0,
    data: [],
    loan: {
        loanId: '',
        id: '',
        jsonValues: {},
        loanData: {
            terms: {} as TLoanTerms,
            keyDates: {},
            funding: {},
            reserves: {
                balances: {},
                commitments: {},
            },
            loanDetails: {} as TLoanDetails,
        },
        loanName: '',
        loanPropertyList: [],
        notes: [],
        isDetailAnalyze: false,
        loanProgram: '',
        lenderDefinition: {} as TMember,
        lastLoanImportDate: 0,
        borrowerDefinition: {} as TMember,
        guarantorDefinition: {} as TMember,
        readOnly: false,
    },
    rates: [],
    search: {},
    loaded: false,
    loading: false,
    totalLoaded: false,
    totalLoading: false,
    total: {},
    current: null,
    isRatesLoading: false,
    isRatesLoaded: false,
};

const loanSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action: PayloadAction<Record<string, unknown> | void>) {
            state.search = action.payload ?? {};
            state.loaded = false;
            state.loading = true;
        },
        loadOne(state) {
            state.loan = initialState.loan;
            state.loaded = false;
            state.loading = true;
        },
        loadMore(state) {
            state.loaded = false;
            state.loading = true;
        },
        delete(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            const { data } = action.payload;

            state.data = data.map(({ jsonValues: originalValues, ...item }) => {
                let jsonValues = originalValues;
                if (typeof jsonValues === 'string') {
                    jsonValues = JSON.parse(jsonValues);
                } else if (!jsonValues) {
                    jsonValues = {};
                }

                return {
                    ...item,
                    jsonValues,
                };
            });

            if (Number.isInteger(action.payload.totalCount)) {
                state.totalCount = action.payload.totalCount;
            }
            state.loaded = true;
            state.loading = false;
        },
        updateOneLoan(state, action) {
            const { id, ...values } = action.payload;

            state.data = state.data.map((loan) => {
                if (loan.id === id) {
                    return {
                        ...loan,
                        ...values,
                    };
                }
                return loan;
            });
        },
        setOne(state, action) {
            const { jsonValues, loanData, ...loan } = action.payload;
            const values = jsonValues ? JSON.parse(jsonValues) : {};

            const transformedTerms = transformTerms(loanData?.terms ?? {});

            state.loan = {
                ...loan,
                jsonValues: { ...state.loan.jsonValues, ...values },
                loanData: { ...state.loan.loanData, ...loanData, terms: transformedTerms },
            };
            state.loaded = true;
            state.loading = false;
        },
        addProperty() {},
        editProperty() {},
        loanDeleteProperty() {},
        deleteProperty(state, action) {
            state.loan.loanPropertyList = [...state.loan.loanPropertyList].filter(
                (property) => property.id !== action.payload
            );
            state.loan.propertyCount = state.loan.loanPropertyList.length;
        },
        editCurrent() {},
        setCurrent(state, action: PayloadAction<number | void>) {
            state.current = action.payload || null;
        },
        addMore(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        loadTotal(state) {
            state.totalLoading = true;
            state.totalLoaded = false;
        },
        loadTotalFiltered(state) {
            state.totalLoading = true;
            state.totalLoaded = false;
        },
        setTotal(state, action) {
            state.total = action.payload;
            state.totalLoading = false;
            state.totalLoaded = true;
        },
        filter(state, action) {
            state.search = action.payload;
            state.data = [];
            state.loaded = false;
            state.loading = true;
            state.totalLoaded = false;
        },
        filterMore() {},
        initEditOne(state, action) {
            state.current = action.payload;
        },
        getNotes() {},
        saveNotes() {},
        setNotes(state, { payload }) {
            state.loan.notes = payload;
        },
        loadRates(state) {
            state.isRatesLoading = true;
        },
        setRates(state, { payload }) {
            state.rates = payload;
            state.isRatesLoading = false;
            state.isRatesLoaded = true;
        },
        resetState() {
            return initialState;
        },
        setLoanData(state, { payload }) {
            state.loan.loanData = { ...state.loan.loanData, ...payload, terms: transformTerms(payload?.terms ?? {}) };
        },
        updateLoanDataTerms(state, action: PayloadAction<TLoanTerms>) {
            state.loan.loanData.terms = {
                ...state.loan.loanData.terms,
                ...action.payload,
            };
        },
        updateLoanDataReserves(state, action: PayloadAction<UpdateLoanDataReservesPayload>) {
            const { fieldName, table, value } = action.payload;

            if (!state.loan.loanData.reserves?.[table]) {
                state.loan.loanData.reserves[table] = {};
            }

            if (table === 'commitments') {
                state.loan.loanData.reserves.commitments = {
                    ...state.loan.loanData.reserves.commitments,
                    [fieldName]: value as TLoanDataReservesCommitmentValue,
                };
            } else {
                state.loan.loanData.reserves.balances = {
                    ...state.loan.loanData.reserves.balances,
                    [fieldName]: value as number,
                };
            }
        },
        deleteLoanDataReserves(state, action: PayloadAction<Omit<UpdateLoanDataReservesPayload, 'value'>>) {
            const { fieldName, table } = action.payload;
            if (table === 'commitments') {
                delete state.loan.loanData.reserves.commitments[fieldName];
            } else {
                delete state.loan.loanData.reserves.balances[fieldName];
            }
        },
        updateLoanDataFunding(state, action: PayloadAction<TLoanFunding>) {
            state.loan.loanData.funding = {
                ...state.loan.loanData.funding,
                ...action.payload,
            };
        },
        updateLoanDataKeyDates(state, action: PayloadAction<TKeyDate>) {
            state.loan.loanData.keyDates = {
                ...state.loan.loanData.keyDates,
                ...action.payload,
            };
        },
        deleteLoanDataKeyDate(state, action: PayloadAction<TKeyDateKeys>) {
            delete state.loan.loanData.keyDates[action.payload];
        },
        updateLoanLender(state, action: PayloadAction<TMember>) {
            state.loan.lenderDefinition = action.payload;
        },
        updateLoan(state, action: PayloadAction<Record<string, unknown>>) {
            state.loan = {
                ...state.loan,
                ...action.payload,
            };
        },
    },
});

export const {
    load: loadLoans,
    addMore: addMoreLoans,
    loadMore: loadMoreLoans,
    loadOne: loadOneLoans,
    setData: setLoans,
    setOne: setOneLoans,
    editCurrent: editCurrentLoans,
    editProperty: editPropertyLoans,
    addProperty: addPropertyLoans,
    loanDeleteProperty: loadDeletePropertyLoans,
    deleteProperty: deletePropertyLoans,
    setCurrent: setCurrentLoans,
    delete: deleteLoans,
    loadTotal: loadTotalLoans,
    loadTotalFiltered: loadTotalFilteredLoans,
    setTotal: setTotalLoans,
    filter: filterLoans,
    filterMore: filterMoreLoans,
    initEditOne: initEditOneLoans,
    getNotes: getNotesOneLoans,
    saveNotes: saveNotesOneLoans,
    setNotes: setNotesOneLoans,
    loadRates: loadRatesLoan,
    setRates: setRatesLoan,
    resetState: resetStateLoans,
    updateLoanDataTerms: updateLoanDataTermsLoans,
    updateLoanDataReserves: updateLoanDataReservesLoans,
    deleteLoanDataReserves: deleteLoanDataReservesLoans,
    updateLoanDataFunding: updateLoanDataFundingLoans,
    updateLoanDataKeyDates: updateLoanDataKeyDatesLoans,
    deleteLoanDataKeyDate: deleteLoanDataKeyDateLoans,
    setLoanData: setLoanDataLoans,
    updateLoanLender: updateLoanLenderLoans,
    updateOneLoan: updateOneLoanLoans,
    updateLoan: updateLoanLoans,
} = loanSlice.actions;

export default loanSlice.reducer;

export const selectLoansState = (store: RootState) => store.loans.index;

export const selectLoan = (store: RootState) => store.loans.index.loan;

export const selectRatesLoan = (store: RootState) => store.loans.index.rates;

export const selectOneLoansCollaterals = (store: RootState) => store.loans.index.loan.loanPropertyList;

const selectLoanDataReservesBalances = (store: RootState) => store.loans.index.loan.loanData.reserves?.balances;

export const selectTermsWithKeyDates = createSelector(selectLoan, ({ loanData, lenderDefinition }) => {
    const { terms, keyDates } = loanData;

    return {
        ...terms,
        originationDate: keyDates?.originationDate?.date ?? '',
        maturityDate: keyDates?.currentMaturity?.date ?? '',
        lender: lenderDefinition,
    };
});

export const selectLoanDataReservesBalancesTotal = createSelector([selectLoanDataReservesBalances], (balances = {}) =>
    Object.values(balances).reduce((acc, cur) => {
        if (typeof cur === 'object') {
            const typedCur = cur as TLoanDataReservesBalanceCustom;

            const customAmount = Object.values(typedCur).reduce(
                (customAcc, customCur) => customAcc + customCur.amount ?? 0,
                0
            );

            return acc + customAmount;
        }

        return acc + cur ?? 0;
    }, 0)
);

export const selectLoanDetailsForFinancialScenario = createSelector(selectLoan, ({ loanData }) => {
    const { terms, loanDetails, keyDates } = loanData;

    return {
        ...(terms ?? {}),
        ...(loanDetails ?? {}),
        interest: terms?.ioPeriod ?? 0,
        amount: loanDetails?.loanAmount ?? 0,
        name: terms?.loanName,
        cashInterestRate: terms?.coupon ?? 0,
        terms: Math.abs(
            differenceInYears(
                keyDates?.originationDate?.date ?? Date.now(),
                keyDates?.currentMaturity?.date ?? Date.now()
            )
        ),
        amortization: terms?.amortizationSchedule ?? 0,
        index: terms?.indexValue ?? 0,
        indexLabel: terms?.index ?? '',
        interestAmortization: terms?.ioPeriod ?? 0,
        sofrAdjustment: terms?.termSoftAdjustment ?? 0,
    };
});

export const selectCombinedPortfolio = createSelector(
    [selectOneLoansCollaterals, selectLoansState],
    (state, { loan }) => {
        const initialCombinedState = {
            properties: 0,
            size: 0,
            units: 0,
            amount: state?.length || 0,
            loanId: loan?.id,
            url: '',
        };
        return state?.reduce((acc, cur) => {
            if (cur.size) acc.size += cur.size;
            if (cur.units) acc.units += cur.units;
            acc.url = `/portfolio.html?loanId=${cur.loanDetailsId}`;
            return acc;
        }, initialCombinedState);
    }
);

export const selectLoanCollaterals = createSelector(
    selectLoansState,
    ({ loan: { isDetailAnalyze, loanPropertyList = [] } }) =>
        loanPropertyList?.map((property) => {
            const { isDetailAnalyze: isAnalyzed = isDetailAnalyze, ...loan } = property ?? {};

            return {
                isAnalyzed,
                ...loan,
            };
        }) || []
);

export const selectCurrentLoan = createSelector(
    selectLoansState,
    ({ data, current }) => data.find(({ id } = {} as { id: string }) => id === current) || {}
);

function* loadLoansSaga({ payload, type }) {
    yield delay(200);
    const { name, params } = yield select(selectCurrentPageCommon);

    const { countyId, parcelId, userId } = params ?? {};

    const viewType = VIEW_TYPE_MAPS[name] ?? VIEW_TYPE_MAPS.DEFAULT;
    const paramsRequest = getParams(name, countyId, parcelId);

    yield put(apiRequest({ ...payload, ...paramsRequest, viewType, userId }, loansApi.getLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setLoans({ data: content, totalCount: totalElements }));
}

function* loadMoreLoansSaga({ type }) {
    const { data: prevData, search = {} } = yield select(selectLoansState);

    yield put(apiRequest({ ...search, page: prevData.length / size }, loansApi.getLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;

    const resContent = content.map(({ jsonValues, ...item }) => ({
        ...item,
        jsonValues: jsonValues ? JSON.parse(jsonValues) : {},
    }));
    yield put(addMoreLoans([...prevData, ...resContent]));
}

function* loadOneLoansSaga({ type, payload }) {
    yield put(apiRequest(payload, loansApi.getLoan, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(loadDetailsLoan(action.payload.data));
    yield put(setOneLoans(action.payload.data));
}

function* loadTotalLoansSaga({ type }) {
    yield delay(200);
    yield put(apiRequest(null, loansApi.getTotal, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    const total = action.payload?.data || {
        principal: 12345,
        balance: 9876,
    };
    yield put(setTotalLoans(total));
}

function* loadTotalFilteredLoansSaga({ type, payload }) {
    yield delay(200);
    yield put(apiRequest(payload, loansApi.getTotalFiltered, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    const total = action.payload?.data || {
        principal: 12345,
        balance: 9876,
    };

    yield put(setTotalLoans(total));
}

function* deleteLoansSaga({ type, payload }) {
    const { data, totalCount } = yield select(selectLoansState);

    const loans = data.filter((loan) => loan.id !== payload);

    yield put(apiRequest(payload, loansApi.deleteLoan, type));
    yield put(setLoans({ data: loans, totalCount: totalCount - 1 }));

    yield put(denormalizedLoansApi.util.resetApiState());

    yield take(`${type} ${API_ERROR}`);
    yield put(setLoans({ data, totalCount }));
}

function* filterLoansSaga({ type, payload }) {
    yield delay(400);
    yield put(apiRequest(payload, loansApi.filter, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setLoans({ data: content, totalCount: totalElements }));
}

function* filterMoreLoansSaga({ type }) {
    const { data: prevData, search } = yield select(selectLoansState);
    yield put(apiRequest({ ...search, page: prevData.length / size }, loansApi.filter, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;

    const resContent = content.map(({ jsonValues, ...item }) => ({
        ...item,
        jsonValues: jsonValues ? JSON.parse(jsonValues) : {},
    }));
    yield put(addMoreLoans([...prevData, ...resContent]));
}

function* initEditOneLoansSaga({ payload }) {
    yield put(loadOneLoans(payload));
    const currentLoan = yield select(selectCurrentLoan);
    yield put(
        showPrimaryAsideDrawer({
            content: EDIT_LOAN_FORM,
            data: {
                title: 'Edit Loan',
                label: currentLoan?.loanName || '',
                disabledFields: {
                    balance: true,
                    principal: true,
                },
            },
        })
    );
    yield take(hideDrawer);
    yield put(setCurrentLoans());
}

function* addPropertyLoansSaga({ payload }) {
    const {
        loan: { id },
        ...rest
    } = payload;
    yield put(
        showDialog({
            content: SEARCH_PROPERTY,
            ...rest,
            data: { id },
        })
    );
}

function* loadDeletePropertyLoansSaga({ type, payload }) {
    const { current } = yield select(selectLoansState);
    yield put(
        apiRequest(
            {
                ...payload,
                loanDetailsId: payload.loanDetailsId || String(current),
            },
            propertiesApi.deletePropertyLoan,
            type
        )
    );
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadOneLoans(payload.loanDetailsId || String(current)));
    yield put(userLoans.util.invalidateTags([USER_LOANS_TAGS.LOAN]));
}

function* editPropertyLoansSaga({ payload }) {
    const { loan, className } = payload;

    const { address, latitude, longitude, propertyType } = loan ?? {};

    yield put(
        showDialog({
            content: SEARCH_PROPERTY,
            className,
            data: {
                address,
                coords: {
                    lat: latitude,
                    lon: longitude,
                },
                actionText: 'Update Property',
                classdesc: propertyType,
            },
        })
    );
}

function* editCurrentLoansSaga({ payload }) {
    const {
        id,
        loanId,
        loanName,
        loanProgram,
        borrowerId,
        principal,
        balance,
        approvedDate,
        maturityDate,
        detailsStatus,
        loanType,
        memberId,
    } = payload;
    yield put(
        saveDetailsLoan({
            id,
            name: loanName,
            loanId,
            values: {
                id,
                loanId,
                loanName,
                loanProgram,
                borrowerId,
                principal,
                balance,
                approvedDate,
                maturityDate,
                status: detailsStatus,
            },
        })
    );
    yield take(setDetailsLoan);

    const method = loanType === 'member' ? loadMemberLoans : loadLoans;
    yield put(method({ memberId }));
}

function* getNotesOneLoansSaga({ type, payload }) {
    yield put(apiRequest(payload, propertyNotesApi.getNotes, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { notes } = action.payload.data;
    yield put(setNotesOneLoans(notes));
}

function* saveNotesOneLoansSaga({ type, payload }) {
    yield put(apiRequest(payload, propertyNotesApi.saveNote, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(setNotesOneLoans(payload.notes));
}

function* loadRatesLoanSaga({ type, payload }) {
    yield put(apiRequest(payload, loanRatesApi.getRates, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;

    const resRates = transformRates(data);

    yield put(setRatesLoan(resRates.rates));
}

function* addLoanSaga({ payload: { data = {} }, type }) {
    yield put(apiRequest(data, loansApi.addLoan, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(denormalizedLoansApi.util.resetApiState());

    yield put(denormalizedLoansApi.util.invalidateTags([DENORMALIZED_LOANS_TAGS.GET_USER_LOANS_BY_STATUS]));

    yield put(loadLoans());

    yield put(hideDrawer());
}

export function* watchLoans() {
    yield takeLatest(loadLoans, loadLoansSaga);
    yield takeLatest(loadOneLoans, loadOneLoansSaga);
    yield takeLatest(loadMoreLoans, loadMoreLoansSaga);
    yield takeLatest(loadTotalLoans, loadTotalLoansSaga);
    yield takeLatest(loadTotalFilteredLoans, loadTotalFilteredLoansSaga);
    yield takeLatest(deleteLoans, deleteLoansSaga);
    yield takeLatest(filterLoans, filterLoansSaga);
    yield takeLatest(filterMoreLoans, filterMoreLoansSaga);
    yield takeLatest(initEditOneLoans, initEditOneLoansSaga);
    yield takeLatest(editPropertyLoans, editPropertyLoansSaga);
    yield takeLatest(editCurrentLoans, editCurrentLoansSaga);
    yield takeLatest(addPropertyLoans, addPropertyLoansSaga);
    yield takeLatest(loadDeletePropertyLoans, loadDeletePropertyLoansSaga);
    yield takeLatest(getNotesOneLoans, getNotesOneLoansSaga);
    yield takeLatest(saveNotesOneLoans, saveNotesOneLoansSaga);
    yield takeLatest(loadRatesLoan, loadRatesLoanSaga);
    yield takeLatest(`${LOAN} ${GET_EVENT}`, addLoanSaga);
}
