import { createSlice } from '@reduxjs/toolkit';
import { delay, put, select, take, takeLatest } from 'redux-saga/effects';

import { TAB_TYPES, VIEWS } from '@scc/modules/DocumentsWithGrid/helpers/constants';
import { prepareGridColumnsOptions } from '@scc/modules/DocumentsWithGrid/helpers/helpers';
import { selectDetailedOverviewPropertyIdState } from '@scc/store/ducks/detailedProperty/overview';

import { detailedProperty, documentsApi } from '@api';
import { MESSAGES } from '@constants/isolatedGrid';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[grid/common]';

const getDocumentServiceMap = {
    [TAB_TYPES.FINANCIALS]: detailedProperty.getDocumentsStatement,
    [TAB_TYPES.RENT_ROLL]: detailedProperty.getDocumentsRentRoll,
    [TAB_TYPES.DEVELOPMENT_BUDGET]: detailedProperty.getDocumentsDevelopmentBudget,
};

const getCombinedDocumentServiceMap = {
    [TAB_TYPES.FINANCIALS]: detailedProperty.getCombinedDocumentStatement,
    [TAB_TYPES.RENT_ROLL]: detailedProperty.getCombinedDocumentRentRoll,
};

const getOsWithCombinedDocuments = (result) => {
    const combinedDocument = result.find((doc) => doc.combined);

    if (combinedDocument) {
        combinedDocument.fileName = 'All Financials';
        return [combinedDocument, ...result.filter((doc) => !doc.combined)];
    }

    return result;
};

const getDevelopmentWithCombinedDocuments = (result) => {
    const combinedDocument = result.find((doc) => doc.currentBudget);

    if (combinedDocument) {
        combinedDocument.fileName = 'Current Budget';
        return [combinedDocument, ...result.filter((doc) => !doc.currentBudget)];
    }

    return result;
};

const getDocumentsWithCombinedMap = {
    [TAB_TYPES.FINANCIALS]: getOsWithCombinedDocuments,
    [TAB_TYPES.RENT_ROLL]: getOsWithCombinedDocuments,
    [TAB_TYPES.DEVELOPMENT_BUDGET]: getDevelopmentWithCombinedDocuments,
};

const initialState = {
    message: null,
    selectedDocumentId: null,
    selectedDocumentEntityId: null,
    documents: [],
    building: {},
    activeViews: {},
    previewCoordinates: '',
    isActive: false,
    isEditingCombined: false,
    loadingDocumentList: false,
    loadingDocument: false,
    isLoadedExtractColumns: false,
    templates: [],
    currentTemplate: null,
    columnsOptions: [],
    allColumnsOptions: [],
    scenarios: [],
    tenantNames: [],
    changedTenantNames: [],
    documentType: null,
    previewPage: null,
    resolutions: [],
    filters: {
        count: 12,
    },
    key: 1,
    multiplier: null,
};

const gridSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setLoadingDocumentList(state, action) {
            state.loadingDocumentList = action.payload;
        },
        setLoadingDocument(state, action) {
            state.loadingDocument = action.payload;
        },
        setLoadedExtraction(state, action) {
            state.isLoadedExtractColumns = !!action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setDocument(state, action) {
            state.isLoadedExtractColumns = false;
            state.selectedDocumentId = action.payload?.id;
            state.selectedDocumentEntityId = action.payload?.entityId;
            state.isCurrentBudget = action.payload?.currentBudget ?? false;
            state.previewCoordinates = '';
            state.filters = {
                count: 12,
            };
        },
        setDocuments(state, action) {
            state.documents = action.payload;
        },
        loadDocuments(state, action) {
            if (!action.payload?.skipLoading) {
                state.loadingDocumentList = true;
            }
        },
        [`loadDocuments ${API_SUCCESS}`](state) {
            state.loadingDocumentList = false;
        },
        [`loadDocuments ${API_ERROR}`](state) {
            state.loadingDocumentList = false;
        },
        setIsActive(state, action) {
            state.isActive = action.payload;
            state.editingDocument = null;
        },
        setView(state, action) {
            state.activeViews = {
                ...state.activeViews,
                ...action.payload,
            };
        },
        toggleView(state, action) {
            if (Array.isArray(action.payload)) {
                const nextActiveViews = { ...state.activeViews };
                action.payload.forEach((view) => {
                    nextActiveViews[view] = !nextActiveViews[view];
                });
                state.activeViews = nextActiveViews;
            } else {
                state.activeViews = {
                    ...state.activeViews,
                    [action.payload]: !state.activeViews[action.payload],
                };
            }
        },
        loadTemplates() {},
        setTemplates(state, action) {
            state.templates = action.payload;
        },
        setCurrentTemplate(state, action) {
            state.currentTemplate = action.payload;
        },
        setColumnsOptions(state, action) {
            state.columnsOptions = prepareGridColumnsOptions(action.payload) || [];
        },
        setAllColumnsOptions(state, action) {
            state.allColumnsOptions = prepareGridColumnsOptions(action.payload) || [];
        },
        setScenarios(state, action) {
            state.scenarios = action.payload;
        },
        setPreviewCoordinates(state, action) {
            state.previewCoordinates = action.payload;
        },
        setPreviewPage(state, action) {
            state.previewPage = action.payload;
        },
        setPageResolutions(state, action) {
            state.resolutions = action.payload;
        },
        resetState() {
            return initialState;
        },
        loadCombinedDocument(state) {
            state.loadingDocumentList = true;
        },
        [`loadCombinedDocument ${API_SUCCESS}`](state) {
            state.loadingDocumentList = false;
        },
        [`loadCombinedDocument ${API_ERROR}`](state) {
            state.loadingDocumentList = false;
        },
        setBuilding(state, action) {
            state.documents = [];
            state.selectedDocumentId = null;
            state.previewCoordinates = '';
            state.building = action.payload;
        },
        setDocumentType(state, action) {
            state.documentType = action.payload;
        },
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        addDocumentToDocumentList(state, action) {
            state.documents.push(action.payload);
        },
        setIsEditingCombined(state, action) {
            state.isEditingCombined = action.payload;
        },
        setMultiplier(state, action) {
            state.multiplier = action.payload;
        },
    },
});

export const {
    setLoadingDocumentList: setLoadingDocumentListGrid,
    setLoadingDocument: setLoadingDocumentGrid,
    setMessage: setMessageGrid,
    setView: setViewGrid,
    setColumnsOptions: setColumnsOptionsGrid,
    setAllColumnsOptions: setAllColumnsOptionsGrid,
    setScenarios: setScenariosGrid,
    setPreviewCoordinates: setPreviewCoordinatesGrid,
    setPreviewPage: setPreviewPageGrid,
    setPageResolutions: setPageResolutionsGrid,
    setLoadedExtraction: setLoadedExtractionGrid,
    toggleView: toggleViewGrid,
    setIsActive: setIsActiveGrid,
    setDocument: setDocumentGrid,
    setDocuments: setDocumentsGrid,
    loadDocuments: loadDocumentsGrid,
    loadCombinedDocument: loadCombinedDocumentGrid,
    loadTemplates: loadTemplatesGrid,
    setTemplates: setTemplatesGrid,
    setCurrentTemplate: setCurrentTemplateGrid,
    resetState: resetStateGrid,
    setBuilding: setBuildingGrid,
    setDocumentType: setDocumentTypeGrid,
    setFilters: setFiltersGrid,
    addDocumentToDocumentList: addDocumentToDocumentListGrid,
    setIsEditingCombined: setIsEditingCombinedGrid,
    setMultiplier: setMultiplierGrid,
} = gridSlice.actions;

export default gridSlice.reducer;

export const selectGridState = (store) => ({
    ...store.grid.common,
    document:
        store.grid.common.documents.find(({ id }) => id !== null && id === store.grid.common.selectedDocumentId) ||
        store.grid.common.documents.find(
            ({ entityId }) => entityId !== null && entityId === store.grid.common.selectedDocumentEntityId
        ) ||
        store.grid.common.documents.find(({ currentBudget }) => currentBudget && store.grid.common.isCurrentBudget) ||
        {},
});

export const selectGridActiveViewsState = (store) => store.grid.common.activeViews;

export const selectChosenEntityId = (store) => {
    const document =
        store.portfolio.grid.documents.find(({ id }) => id === store.portfolio.grid.selectedDocumentId) || {};

    return document.entityId;
};

function* loadTemplatesGridSaga({ type, payload }) {
    yield put(apiRequest(payload, documentsApi.getOsTemplates, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setTemplatesGrid(data));
}

function* loadDocumentsGridSaga({ type, payload = {} }) {
    const { skipSendMessage, skipLoading } = payload;
    const propertyId = yield select(selectDetailedOverviewPropertyIdState);
    const { building, documentType } = yield select(selectGridState);

    if (!propertyId || !building.id) {
        yield put(setLoadingDocumentListGrid(false));
        return;
    }

    const getDocumentService = getDocumentServiceMap[documentType];

    yield put(
        apiRequest(
            {
                propertyId,
                propertyBuildingId: building.id,
            },
            getDocumentService,
            `${type}/${documentType}`,
            { skipSendMessage }
        )
    );
    const action = yield take([`${type}/${documentType} ${API_SUCCESS}`, `${type}/${documentType} ${API_ERROR}`]);
    yield put(setLoadingDocumentListGrid(false));

    if (action.type.endsWith(API_ERROR)) {
        return;
    }

    const { data = [] } = action.payload;

    const getDocumentsWithCombined = getDocumentsWithCombinedMap[documentType];

    const documentsGrid = getDocumentsWithCombined(data);

    yield put(setDocumentsGrid(documentsGrid));

    if (!skipLoading && documentsGrid[0]) {
        yield put(setDocumentGrid(documentsGrid[0]));
    }
}

function* loadCombinedDocumentGridSaga({ type }) {
    const propertyId = yield select(selectDetailedOverviewPropertyIdState);
    const { building, documentType } = yield select(selectGridState);

    if (!propertyId) {
        yield put(setLoadingDocumentListGrid(false));
        return;
    }

    const getDocumentService = getCombinedDocumentServiceMap[documentType];

    yield put(
        apiRequest(
            {
                propertyId,
                propertyBuildingId: building.id,
            },
            getDocumentService,
            type
        )
    );
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data = {} } = action.payload;

    if (documentType === TAB_TYPES.FINANCIALS) {
        data.fileName = 'All Financials';
    }

    yield put(setDocumentsGrid(documentType === TAB_TYPES.FINANCIALS ? [data] : data));
}

function* successDocumentsUploadSaga({ payload, meta }) {
    const { data: documents } = payload;
    const { documents: prevDocuments } = yield select(selectGridState);
    let nextDocuments = [...documents];

    if (nextDocuments.length <= prevDocuments.length || prevDocuments.length === 0) {
        return;
    }

    const combinedIndex = nextDocuments.findIndex((item) => item.combined);

    if (combinedIndex !== -1) {
        const [combinedDocument] = nextDocuments.splice(combinedIndex, 1);

        nextDocuments = [combinedDocument, ...nextDocuments];
    }

    const selectedAddedDocument = nextDocuments.find(
        (nextDocument) => !nextDocument.combined && !prevDocuments.some((document) => document.id === nextDocument.id)
    );

    if (selectedAddedDocument) {
        yield put(setDocumentGrid(selectedAddedDocument));

        if (meta?.skipSendMessage) {
            return;
        }

        yield put(
            setMessageGrid({
                message: MESSAGES.OPEN_FILE,
                data: {
                    documentId: selectedAddedDocument.entityId,
                    status: selectedAddedDocument.status,
                    documentStatus: selectedAddedDocument.documentStatus,
                },
            })
        );
    }
}

function* setViewGridSaga({ payload }) {
    yield delay(300);

    if (!payload[VIEWS.PREVIEW_DOCUMENTS] && payload[VIEWS.SIDE_LIST]) {
        yield put(
            setMessageGrid({
                message: MESSAGES.CHANGE_VIEWPORT,
            })
        );

        yield delay(100);
    }

    yield put(
        setMessageGrid({
            message: MESSAGES.CHANGE_VIEWPORT,
        })
    );
}

function* setBuildingGridSaga({ payload }) {
    yield put(loadDocumentsGrid({ skipSendMessage: payload?.meta?.skipSendMessage }));
}

function* setMessageGridSaga({ payload }) {
    if (payload?.message === MESSAGES.FILTER_COLUMNS) {
        yield put(setFiltersGrid(payload.data));
    }
}

export function* watchGrid() {
    yield takeLatest(loadTemplatesGrid, loadTemplatesGridSaga);
    yield takeLatest(loadDocumentsGrid, loadDocumentsGridSaga);
    yield takeLatest(loadCombinedDocumentGrid, loadCombinedDocumentGridSaga);
    yield takeLatest(setBuildingGrid, setBuildingGridSaga);
    yield takeLatest(setViewGrid, setViewGridSaga);
    yield takeLatest(setMessageGrid, setMessageGridSaga);
    yield takeLatest(`${loadDocumentsGrid} ${API_SUCCESS}`, successDocumentsUploadSaga);
}
