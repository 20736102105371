import cx from 'classnames';
import { useEffect, forwardRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { getMapValue } from '@utils/components';
import { formatter, parseLocaleNumber } from '@utils/index';

import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@ui/Box';
import FormControl from '@ui/FormControl';
import FormHelperText from '@ui/FormHelperText';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputAdornment from '@ui/InputAdornment';
import InputLabel from '@ui/InputLabel';
import Typography from '@ui/Typography';

import BaseDatePicker from '@components/DatePicker';
import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import InputBaseForm from './InputBaseForm';
import RadioBaseForm from './RadioBaseForm';
import SelectBaseForm from './SelectBaseForm';
import styles from './styles.module.scss';

const defaultProps = {
    initialFormData: {},
    errorMessage: '',
    onSubmit() {},
    getFormState: () => {},
    schema: {},
    inputs: [],
    hideFields: '',
    labelProps: {
        variant: 'standard',
    },
};

export const FORMATTERS = {
    number: 'FORMAT_NUMBER',
    date: 'FORMAT_DATE',
};

export const formattersMap = {
    [FORMATTERS.number]: {
        format: (value) => (!!value && value !== '0' ? formatter.number(value) : ''),
        unformat: (value) => (!!value && value !== '0' ? parseLocaleNumber(value) : ''),
    },
    [FORMATTERS.date]: {
        unformat: (date) => (date ? new Date(date).toISOString() : null),
    },
};

const fieldsMap = new Map([
    ['select', SelectBaseForm],
    ['input', InputBaseForm],
    ['textArea', Input.Outlined],
    ['radio', RadioBaseForm],
    ['date', BaseDatePicker],
]);

const BaseForm = forwardRef(
    (
        {
            id,
            className,
            initialFormData,
            inputs,
            onSubmit,
            onChange: onChangeProp,
            schema,
            validationMode = 'onBlur',
            notification,
            innerContent,
            labelProps,
            submitBtn,
            validateOnMount,
            getFormState,
            clearAfterSuccess,
            hideFields,
            isMultipleStep = true,
            stopPropagation = false,
        },
        ref
    ) => {
        const { getValues, register, handleSubmit, trigger, unregister, formState, setValue, reset } = useForm({
            mode: validationMode,
            resolver: yupResolver(schema),
            defaultValues: initialFormData,
            reValidateMode: 'onChange',
        });

        const onSubmitHandler = (payload, event) => onSubmit(payload, event, reset);

        const hiddenFields = useMemo(
            () => hideFields && typeof hideFields === 'string' && hideFields.split(','),
            [hideFields]
        );

        useEffect(() => {
            getFormState(formState);
        }, [formState, getFormState]);

        useEffect(() => {
            if (validateOnMount) {
                trigger();
            }
        }, [validateOnMount, trigger]);

        useEffect(() => {
            reset({});
            ref?.current?.reset();
        }, [clearAfterSuccess, ref, reset]);

        return (
            <Box
                className={cx(styles.form, className)}
                component='form'
                id={id}
                ref={ref}
                onSubmit={(e) => {
                    if (stopPropagation) {
                        e.stopPropagation();
                    }

                    return handleSubmit(onSubmitHandler)(e);
                }}
            >
                <ItemList items={inputs}>
                    {({
                        name,
                        label,
                        caption,
                        component,
                        placeholder = '',
                        icon,
                        endIcon,
                        endAdornment,
                        defaultValue,
                        type = 'text',
                        className: localClassName,
                        onChange = onChangeProp,
                        fieldProps = {},
                        selectProps = {},
                        radioProps = {},
                        formatter: formatterName = '',
                        fieldType = 'input',
                        disabled,
                        required,
                    } = {}) => {
                        const hideInput = hiddenFields && hiddenFields.some((fieldName) => fieldName === name);
                        if (hideInput) return;

                        const inputError = !formState.isValid ? formState.errors[name] : false;
                        const formatterFn = formattersMap[formatterName] || {};

                        const BaseFieldComponent = component || getMapValue(fieldsMap, fieldType, 'input');

                        const baseFieldProps = {
                            format: formatterFn.format,
                            defaultValue: initialFormData[name] || defaultValue || null,
                            placeholder: placeholder || null,
                            ...register(name),
                            ...fieldProps,
                        };

                        // todo remove defaultValue installation as value
                        // defaultValue must be passed only as props defaultValue
                        if (fieldType.match(/(input|select)/g) && baseFieldProps.defaultValue && isMultipleStep) {
                            const value = getValues(name);

                            setValue(name, value !== undefined && value !== null ? value : baseFieldProps.defaultValue);
                        }

                        if (fieldType === 'select') {
                            baseFieldProps.selectProps = selectProps;
                        }

                        if (fieldType === 'radio') {
                            baseFieldProps.radioProps = radioProps;
                        }

                        if (fieldType === 'date') {
                            const handleSuccess = (date) => {
                                setValue(name, formatterFn.unformat?.(date) || date);
                            };
                            baseFieldProps.onSuccess = handleSuccess;
                            baseFieldProps.setDefaultValue = handleSuccess;
                        }
                        // todo code refactor
                        if (fieldType === 'textArea') {
                            baseFieldProps.className = styles.textArea;
                            baseFieldProps.multiline = true;
                            baseFieldProps.rows = baseFieldProps.rows ?? 6;
                        }

                        const inputProps = {
                            startAdornment: Boolean(icon) && (
                                <InputAdornment position='start'>
                                    {typeof icon === 'string' ? (
                                        <Typography color={colors['grey-600']} varinat='h5'>
                                            {icon}
                                        </Typography>
                                    ) : (
                                        <Icon color={colors['grey-600']} component={icon} fontSize='24px' />
                                    )}
                                </InputAdornment>
                            ),
                            endAdornment: endIcon ? (
                                <InputAdornment position='end'>
                                    {typeof endIcon === 'string' ? (
                                        <Typography color={colors['grey-600']} varinat='h5'>
                                            {endIcon}
                                        </Typography>
                                    ) : (
                                        <Icon color={colors['grey-600']} component={endIcon} fontSize='24px' />
                                    )}
                                </InputAdornment>
                            ) : (
                                endAdornment || null
                            ),
                        };

                        // eslint-disable-next-line consistent-return
                        return (
                            <FormControl
                                key={name}
                                className={localClassName}
                                error={!!inputError}
                                variant={labelProps.variant}
                                fullWidth
                            >
                                {label && (
                                    <InputLabel
                                        htmlFor={name}
                                        sx={labelProps.sx || fieldProps.labelProps?.sx}
                                        variant={labelProps.variant}
                                    >
                                        {label}
                                    </InputLabel>
                                )}
                                <BaseFieldComponent
                                    {...baseFieldProps}
                                    // todo InputAdornment refactoring
                                    InputProps={inputProps}
                                    data-qa={name}
                                    disabled={disabled}
                                    error={!!inputError}
                                    label={caption}
                                    placeholder={placeholder}
                                    required={required}
                                    trigger={trigger}
                                    type={type}
                                    unregister={unregister}
                                    variant={labelProps.variant}
                                    fullWidth
                                    onChange={({ target }, customName = name) => {
                                        onChange?.(customName, formatterFn.unformat?.(target.value) || target.value);
                                        setValue(customName, formatterFn.unformat?.(target.value) || target.value);
                                    }}
                                />
                                {inputError?.message && (
                                    <FormHelperText error={!!inputError}>{inputError?.message}</FormHelperText>
                                )}
                            </FormControl>
                        );
                    }}
                </ItemList>
                {notification}
                {innerContent}
                {submitBtn && (
                    <FormControl className={styles.submit} variant={labelProps.variant}>
                        {submitBtn}
                    </FormControl>
                )}
            </Box>
        );
    }
);

BaseForm.defaultProps = defaultProps;

export default BaseForm;
