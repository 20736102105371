import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import links from '@scc/router/links';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { PROPERTY_SEARCH } from '@constants/modals';
import SearchIcon from '@icons/outlined/search-normal-1.svg';
import { showPageDialog } from '@store/ducks/ui/dialog';

import styles from './base.module.scss';

function BaseHeaderMenu() {
    const dispatch = useDispatch();
    const location = useLocation();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const handleSearch = () => {
        dispatch(
            showPageDialog({
                content: PROPERTY_SEARCH,
                contentProps: { className: styles.dialogContent },
                className: styles.dialogPaper,
            })
        );
    };
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {isDesktop && (
                <>
                    <Button
                        aria-label='Search Property address'
                        className={styles.link}
                        variant='text'
                        onClick={handleSearch}
                    >
                        <Icon color={colors['grey-400']} component={SearchIcon} fontSize={18} />
                        <Typography color={colors['darkNeutral-500']} component='span' variant='hr13'>
                            Search Address for Instant Property Insight
                        </Typography>
                    </Button>
                    <Button
                        aria-label='Request Demo'
                        className={styles.link}
                        component={Link}
                        rel='nofollow'
                        to={{
                            state: { from: location },
                            pathname: links.requestDemo.path,
                        }}
                        variant='text'
                    >
                        <Typography color={colors['darkNeutral-500']} component='span' variant='hr13'>
                            Request Demo
                        </Typography>
                    </Button>
                    <Button
                        aria-label='Login'
                        className={styles.link}
                        component={Link}
                        rel='nofollow'
                        to={{
                            state: { from: location },
                            pathname: links.login.path,
                        }}
                        variant='text'
                    >
                        <Typography color={colors['darkNeutral-500']} component='span' variant='hr13'>
                            Login
                        </Typography>
                    </Button>
                    <Button
                        aria-label='Sign up'
                        component={Link}
                        rel='nofollow'
                        to={{
                            state: { from: location },
                            pathname: links.register.path,
                        }}
                    >
                        Sign up
                    </Button>
                </>
            )}
        </>
    );
}

export default BaseHeaderMenu;
