/* eslint-disable max-len */

// actual list can be found on
// https://github.com/spacequant/spacequantui/blob/develop/src/main/java/com/spacequant/webapp/model/db/permissions/PermissionType.java

export const PROPERTY = {
    ENTRY: 'properties',
    INSIGHTS: 'properties.insights',
    FINANCIALS: 'properties.financials',
    FINANCIALS_EDIT_MAPPING: 'properties.financials.edit-mapping',
    FINANCIALS_VARIANCE_REPORT: 'properties.financials.variance-report',
    FINANCIALS_NORMALIZATIONS: 'properties.financials.normalizations',
    RENTROLL: 'properties.rentroll',
    RENTROLL_STANDARDIZE: 'properties.rentroll.standardize',
    RENTROLL_EDIT_MAPPING: 'properties.rentroll.edit-mapping',
    PRO_FORMA: 'properties.pro-forma',
    EXP_PRO_FORMA: 'properties.exp-pro-forma',
    UNDERWRITING: 'properties.underwriting',
    UNDERWRITING_SALES_COMPS: 'properties.underwriting.sales-comps',
    UNDERWRITING_FINANCING_SCENARIOS: 'properties.underwriting.financing-scenarios',
    MARKET: 'properties.market',
    FINANCING: 'properties.financing',
    HISTORY: 'properties.history',
    NOTES: 'properties.notes',
    ANALYTICS: 'properties.analytics',
    EXP_FINANCIALS: 'properties.exp-financials',
};

export const LOAN = {
    ENTRY: 'loans',
    INSIGHTS: 'loans.insights',
    UNDERWRITING: 'loans.underwriting',
    MESSAGES: 'loans.messages',
    TODO: 'loans.todo',
    TEAM: 'loans.team',
    THIRD_PARTY_REPORT: 'loans.third-party-report',
    COVENANTS: 'loans.covenants',
    STATEMENTS: 'loans.statements',
    DEBT_SERVICE_EXPORT: 'loans.statements.debt-service.xls',
    FUTURE_FUNDING_EXPORT: 'portfolios.loan-future-funding.xls',
    NOTES: 'loans.notes',
    DRAW: 'loans.draw-management',
    PIPELINE: 'loans.pipeline',
};

export const PORTFOLIO = {
    ENTRY: 'portfolios',
    PROPERTY_PORTFOLIOS: 'portfolios.property-portfolios',
    LOAN_PORTFOLIOS: 'portfolios.loan-portfolios',
    LOAN_STRESS_TESTING: 'portfolios.loan-stress-testing',
    LOANS_FUTURE_FUNDING: 'portfolios.loan-future-funding',
    TENANTS: 'portfolios.tenants',
    LOANS_BETA: 'portfolios.loans-beta',
};

export const REPORT = {
    ENTRY: 'reports',
    PDF: 'reports.pdf',
    EXCEL: 'reports.excel',
    OWN_LOGO: 'reports.own_logo', // TODO
    CUSTOMIZE: 'reports.customize',
};

export const PROPERTY_BORROWER = {
    ENTRY: 'properties_borrower', // TODO
};

export const FINANCING = {
    ENTRY: 'financing',
    COLLATERAL: 'financing.collateral',
    UNDERWRITING: 'financing.underwriting',
    MESSAGES: 'financing.messages',
    TODO: 'financing.todo',
    TEAM: 'financing.team',
    THIRD_PARTY_REPORT: 'financing.third-party-report',
    COVENANTS: 'financing.covenants',
    STATEMENTS: 'financing.statements',
    NOTES: 'financing.notes',
};

export const TODOS = {
    ENTRY: 'todos',
};

export const WISHLIST = {
    ENTRY: 'wishlist',
};

export const LISTING = {
    ENTRY: 'listing',
};

export const CONTACTS = {
    ENTRY: 'contacts',
};

export const REFER = {
    ENTRY: 'refer',
};

export const DOCUMENTS = {
    ENTRY: 'documents',
};

export const BENCHMARKING = {
    ENTRY: 'benchmarking',
};

export const GPT_AI = {
    ENTRY: 'gpt-ai',
};

export const VIEW = {
    ENTRY: 'view', // TODO
};

export const EDIT = {
    ENTRY: 'edit', // TODO
};

export const UPLOAD = {
    ENTRY: 'upload', // TODO
};

export const CUSTOMER_SUPPORT_SLA = {
    ENTRY: 'customer-support-sla', // TODO
};

export const ARGUS = {
    ENTRY: 'argus',
};

export const AUTO_NORMALIZATION_OS = {
    ENTRY: 'auto-normalization.os',
};
