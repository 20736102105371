import Icon from '@ui/Icon';
import Typography from '@ui/Typography';
import CloseIcon from '@scc/assets/icons/close.svg';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

const defaultProps = {
    children: null,
    title: null,
    onClose: () => {},
    className: null,
    titleClassName: null,
    contentClassName: null,
    hideButtonClose: false,
};

const PartialModal = function ({
    children,
    title,
    onClose,
    className,
    titleClassName,
    contentClassName,
    hideButtonClose,
}) {
    return (
        <div className={cx(styles.partialModal, className)}>
            {!hideButtonClose && (
                <span className={styles.partialModalClose} onClick={onClose}>
                    <Icon component={CloseIcon} viewBox='0 0 16 16' />
                </span>
            )}

            <Typography variant='h1b' className={cx(styles.partialModalTitle, titleClassName)}>
                {title}
            </Typography>
            <div className={cx(styles.partialModalContent, contentClassName)}>{children}</div>
        </div>
    );
};

PartialModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    title: PropTypes.string,
    onClose: PropTypes.func,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    hideButtonClose: PropTypes.bool,
};
PartialModal.defaultProps = defaultProps;

export default PartialModal;
