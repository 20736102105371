import { createSlice } from '@reduxjs/toolkit';
import { put, select, take, takeLatest } from 'redux-saga/effects';

import { getMemberRequestDataAdapter, parseResponseContent } from '@utils/store/middleware';

// eslint-disable-next-line import/no-cycle
import { ENTITY_TYPES } from '@scc/pages/Members/helpers';
import { loadMember } from '@scc/store/ducks/members/member';

import { companyApi, membersApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';
import { hideDrawer } from '@store/ducks/ui/drawer';

const CREATE = 'create';
const UPDATE = 'update';

const memberApiMaps = {
    member: {
        [CREATE]: membersApi.createUserMember,
        [UPDATE]: membersApi.updateUserMember,
    },
    company: {
        [CREATE]: companyApi.createUserCompany,
        [UPDATE]: membersApi.updateUserMember,
    },
};

const entity = '[members]';
const size = 20;

const initialState = {
    totalCount: 0,
    data: [],
    team: [],
    search: {},
    loaded: false,
    loading: false,
    error: null,
};

const memberSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            state.search = action.payload;
            state.data = [];
            state.loaded = false;
            state.loading = true;
        },
        loaded(state) {
            state.loaded = true;
            state.loading = false;
        },
        loadMore() {},
        loadTeam(state) {
            state.loaded = false;
            state.loading = true;
        },
        addMore(state, action) {
            state.data = action.payload;
        },
        addExistedMembers() {},
        addNewMember() {},
        setData(state, action) {
            const { data, totalCount } = action.payload;
            state.data = data;
            state.totalCount = totalCount;
            state.loaded = true;
            state.loading = false;
        },
        setTeamData(state, { payload }) {
            state.team = payload;
            state.loaded = true;
            state.loading = false;
        },
        filter(state, action) {
            state.search = action.payload;
            state.data = [];
            state.loaded = false;
            state.loading = true;
        },
        create(state) {
            state.loaded = false;
            state.loading = true;
        },
        update(state) {
            state.loaded = false;
            state.loading = true;
        },
        delete() {},
        deleteTeamMember() {},
        setError(state, action) {
            state.error = action.payload;
        },
        [`create ${API_ERROR}`](state, action) {
            const { message } = action.payload;

            state.loaded = true;
            state.loading = false;
            state.error = message;
        },
    },
});

export const {
    load: loadMembers,
    loaded: loadedMembers,
    loadMore: loadMoreMembers,
    loadTeam: loadTeamByMember,
    setData: setMembers,
    setTeamData: setTeam,
    addMore: addMoreMembers,
    addExistedMembers: addExistedMembersToTeam,
    addNewMember: addNewMemberToTeam,
    create: createMember,
    update: updateMember,
    delete: deleteMember,
    deleteTeamMember: deleteMemberFromTeam,
    setError: setErrorMember,
} = memberSlice.actions;

export default memberSlice.reducer;

export const selectMembersState = (store) => store.members.index;

function* loadMembersSaga({ payload, type }) {
    yield put(apiRequest(payload, membersApi.getMembers, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setMembers({ data: parseResponseContent(content), totalCount: totalElements, search: payload }));
}

function* loadMoreMembersSaga({ type }) {
    const { data: prevData, search = {} } = yield select(selectMembersState);
    yield put(apiRequest({ ...search, page: prevData.length / size }, membersApi.getMembers, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreMembers([...prevData, ...parseResponseContent(content)]));
}

function* changeMemberSaga({ payload, type }) {
    const { entityType, body, memberId, closeModal = true, meta = { callback: null } } = payload;
    const { callback = null, type: metaType } = meta;
    const actionType = type === createMember.type ? CREATE : UPDATE;
    const method = entityType === 'INDIVIDUAL' ? memberApiMaps.member[actionType] : memberApiMaps.company[actionType];

    yield put(apiRequest({ memberId, body }, method, type));
    yield take(`${type} ${API_SUCCESS}`);

    if (entityType !== 'INDIVIDUAL') {
        if (callback) {
            yield put(callback(payload));
        }
    }
    const memberEntityType = entityType === 'INDIVIDUAL' ? ENTITY_TYPES.INDIVIDUAL : ENTITY_TYPES.COMPANY;

    yield put(metaType === 'memberDetails' ? loadMember({ memberId }) : loadMembers({ entityType: memberEntityType }));
    yield put(loadedMembers());
    if (closeModal) {
        yield put(hideDrawer());
    }
}

function* loadTeamByMemberSaga({ payload, type }) {
    yield put(apiRequest(payload, membersApi.getTeamByMember, type));
    const {
        payload: {
            data: { content },
        },
    } = yield take(`${type} ${API_SUCCESS}`);

    const team = content.map(({ user, userMember, companyMember }) =>
        getMemberRequestDataAdapter({ user, userMember, companyMember })
    );

    yield put(setTeam(team));
}

function* deleteMemberSaga({ payload, type }) {
    yield put(apiRequest(payload, membersApi.deleteMember, type));
    yield take(`${type} ${API_SUCCESS}`);
    const { data } = yield select(selectMembersState);
    const members = data.filter((member) => member.id !== payload);
    yield put(setMembers({ data: members }));
}

const methods = {
    [addExistedMembersToTeam]: membersApi.addExistedTeamMembers,
    [addNewMemberToTeam]: membersApi.addNewTeamMember,
    [deleteMemberFromTeam]: membersApi.deleteMemberFromTeam,
};

function* teamMemberSaga({ payload, type }) {
    yield put(apiRequest(payload, methods[type], type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;

    const team = content.map(({ user, userMember, companyMember }) =>
        getMemberRequestDataAdapter({ user, userMember, companyMember })
    );

    yield put(setTeam(team));
    if (type !== deleteMemberFromTeam) {
        yield put(hideDrawer());
    }
}

export function* watchMembers() {
    yield takeLatest(loadMembers, loadMembersSaga);
    yield takeLatest(loadMoreMembers, loadMoreMembersSaga);
    yield takeLatest(loadTeamByMember, loadTeamByMemberSaga);
    yield takeLatest([createMember, updateMember], changeMemberSaga);
    yield takeLatest(deleteMember, deleteMemberSaga);
    yield takeLatest([addExistedMembersToTeam, addNewMemberToTeam, deleteMemberFromTeam], teamMemberSaga);
}
