import { format } from 'date-fns';
import download from 'downloadjs';

import request from '@utils/request';

const BASE_URL = '/api/v2/underwriting';
const ASR_URL = '/api/v2/rmr/asr';
const BASE_V2_URL = '/api/v2';

export async function getModels({ loanId }) {
    return request(`${BASE_URL}/model/${loanId}`);
}

export async function getPropertyModels({ countyId, parcelId }) {
    return request(`${BASE_URL}/property/models/${countyId}/${parcelId?.replace('/', '_SLASH_')}`);
}

export async function getRates({ rateDate }) {
    return request(`${BASE_V2_URL}/rates`, {
        params: { rateDate: rateDate ? format(rateDate, 'yyyy-MM-dd') : null },
    });
}

export async function getFinancials({ loanId = '' } = {}) {
    return request(`${BASE_URL}/financials/${loanId}`);
}

export async function getPropertyFinancials({ countyId = '', parcelId = '', userId = '' } = {}) {
    return request(`${BASE_URL}/property/financials/${countyId}/${parcelId?.replace('/', '_SLASH_')}?userId=${userId}`);
}

export async function createModel(data) {
    return request(`${BASE_URL}/model/add`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function createPropertyModel({ countyId = '', parcelId = '', ...data }) {
    return request(`${BASE_URL}/property/model/add`, {
        method: 'POST',
        body: JSON.stringify({ county: countyId, parcel: parcelId, ...data }),
    });
}

export async function updateModel(data) {
    return request(`${BASE_URL}/model/update`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function updatePropertyModel(data) {
    return request(`${BASE_URL}/property/model/update`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function copyModel({ id: modelId, ...body }) {
    return request(`${BASE_URL}/model/${modelId}/copy`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function cloneModel({ id: modelId }) {
    return request(`${BASE_URL}/model/${modelId}/clone`, {
        method: 'POST',
    });
}

export async function deleteModel({ id: modelId }) {
    return request(`${BASE_URL}/model/delete`, {
        method: 'DELETE',
        body: JSON.stringify({ modelId }),
    });
}

export async function deletePropertyModel({ id: modelId }) {
    return request(`${BASE_URL}/property/model/delete`, {
        method: 'DELETE',
        body: JSON.stringify({ modelId }),
    });
}

export async function toggleDefaultModel({ modelId, loanDetailsId }) {
    return request(`${BASE_URL}/model/toggle-default`, {
        method: 'POST',
        body: JSON.stringify({ modelId, loanDetailsId }),
    });
}

export async function togglePropertyDefaultModel({ modelId, countyId, parcelId }) {
    return request(`${BASE_URL}/property/model/toggle-default`, {
        method: 'POST',
        body: JSON.stringify({ modelId, county: countyId, parcel: parcelId }),
    });
}

export async function downloadAsr(modelId) {
    return fetch(`${ASR_URL}/${modelId}`);
}

export async function exportAsr({ modelId }) {
    const res = await downloadAsr(modelId);
    const [, fileName] = res.headers.get('content-disposition')?.match(/filename="(.*)"/) || [];
    const blob = await res.blob();

    download(blob, fileName ?? 'ASR.xlsx');
}
