import Spinner from '@ui/Spinner';

import BuildingSvg from '@icons/outlined/building.svg';
import RowVerticalSvg from '@icons/outlined/row-vertical.svg';
import UnderConstrSvg from '@icons/outlined/under-constr.svg';

export const TEMPLATE_MESSAGES = {
    noProperty: {
        icon: BuildingSvg,
        lead: 'Add Property to Start',
    },
    noCreateSection: {
        icon: RowVerticalSvg,
        description: 'Add Sections to Start →',
    },
    noTemplate: {
        icon: BuildingSvg,
        lead: 'Select Template to Start',
    },
    underConstruction: {
        icon: UnderConstrSvg,
        lead: 'Under Construction',
        description: 'This template will be available soon',
    },
    notFoundDocs: {
        icon: UnderConstrSvg,
        lead: 'Documents Not Found',
    },
    temp: {
        icon: UnderConstrSvg,
    },
    inProgress: {
        component: <Spinner size={50} />,
    },
};

export const getTemplateStatus = ({ hasTemplate, hasDocuments, isNoCreateSections, hasProperties }) => {
    if (!hasProperties) {
        return TEMPLATE_MESSAGES.noProperty;
    }
    if (!hasTemplate) {
        return TEMPLATE_MESSAGES.noTemplate;
    }
    if (!hasDocuments) {
        return TEMPLATE_MESSAGES.notFoundDocs;
    }
    if (isNoCreateSections) {
        return TEMPLATE_MESSAGES.noCreateSection;
    }
    return { isVisible: false };
};
