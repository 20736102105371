import { combineReducers } from 'redux';

import analyticsReducer, { watchAnalytics } from './analytics';
import covenantReducer, { watchCovenants } from './covenants';
import lenderDocumentsReducer, { watchLenderDocuments } from './lenderDocuments';
import loanReducer, { watchLoan } from './loan';
import loanDocumentsReducer, { watchLoanDocuments } from './loanDocuments';
import lendersReducer, { watchLoanLenders } from './loanLenders';
import loansReducer, { watchLoans } from './loans';
import thirdPartyReportReducer, { watchLoanThirdPartyReport } from './loanThirdPartyReport';
import portfolioReducer, { watchPortfolio } from './portfolio';
import portfoliosReducer, { watchPortfolios } from './portfolios';
import teamReducer, { watchTeam } from './team';
import todoReducer, { watchToDo } from './todos';
import todosCheckListReducer, { watchTodosCheckList } from './todosCheckList';
import todosCheckListsReducer, { watchTodosCheckLists } from './todosCheckLists';
import underwritingReducer, { watchUnderwriting } from './underwriting';
import futureFundingReducer, { watchFutureFunding } from './futureFunding';
import debtServiceTableReducer from './debtService';

export const loansWatchers = [
    watchLoans,
    watchLoan,
    watchLoanLenders,
    watchUnderwriting,
    watchToDo,
    watchTodosCheckLists,
    watchTodosCheckList,
    watchLoanThirdPartyReport,
    watchAnalytics,
    watchTeam,
    watchLoanDocuments,
    watchLenderDocuments,
    watchPortfolio,
    watchPortfolios,
    watchCovenants,
    watchFutureFunding,
];

export default combineReducers({
    index: loansReducer,
    loan: loanReducer,
    lender: lendersReducer,
    underwriting: underwritingReducer,
    todo: todoReducer,
    todosCheckLists: todosCheckListsReducer,
    todosCheckList: todosCheckListReducer,
    thirdPartyReport: thirdPartyReportReducer,
    analytics: analyticsReducer,
    team: teamReducer,
    documents: loanDocumentsReducer,
    lenderDocuments: lenderDocumentsReducer,
    portfolios: portfoliosReducer,
    portfolio: portfolioReducer,
    covenants: covenantReducer,
    futureFunding: futureFundingReducer,
    debtServiceTable: debtServiceTableReducer,
});
