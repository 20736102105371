import download from 'downloadjs';

import request from '@utils/request';

import { FutureFundingSchema } from '@shared/services/api/types';

const BASE_URL = '/api/v2/loan/future-fundings';

export async function getFutureFunding({
    page = 0,
    size = 20,
    search = '',
    distribution = 'MONTHLY',
    sort = '',
}): Promise<FutureFundingSchema> {
    return request(BASE_URL, { params: { page, size, search, distribution, sort } });
}

export async function updateFutureFunding({ loanDetailsId, period, value }): Promise<void> {
    return request(`${BASE_URL}/upsert/${loanDetailsId}/${period}`, {
        method: 'POST',
        body: value,
    });
}

export async function exportExcel({ search = '', distribution = 'MONTHLY', sort = '' }): Promise<void> {
    const url = `${BASE_URL}/export`;

    const response = await fetch(`${url}/${`?search=${search}&distribution=${distribution}&sort=${sort}`}`);

    const [, fileName] = response.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await response.blob();
    download(blob, fileName);
}
