import Container from '@ui/Container';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { SECOND_DATA } from './helpers';
import styles from './styles.module.scss';

function SecondBlock({ mobile }) {
    const { title, description } = SECOND_DATA;
    return (
        <div className={styles.second}>
            <Container className={styles.container} maxWidth='xl'>
                <div className={styles.wrapper}>
                    <Typography color={colors.black} component='span' variant='h1b'>
                        {title}
                    </Typography>
                    <Typography
                        className={styles.description}
                        color={colors['grey-400']}
                        component='span'
                        variant={mobile ? 'pr16' : 'h4sb'}
                    >
                        {description}
                    </Typography>
                </div>
            </Container>
        </div>
    );
}

export default SecondBlock;
