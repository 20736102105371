import { baseApi as api } from '../configs/userLoans';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getNewLoanProgramList: build.query<GetNewLoanProgramListApiResponse, GetNewLoanProgramListApiArg>({
            query: () => ({ url: `/api/v2/user-loans/new-loan-program-data` }),
        }),
        getLoanById: build.query<GetLoanByIdApiResponse, GetLoanByIdApiArg>({
            query: (queryArg) => ({ url: `/api/v2/user-loans/${queryArg.loanId}` }),
        }),
        updateLoanData: build.mutation<UpdateLoanDataApiResponse, UpdateLoanDataApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/data`,
                method: 'PUT',
                body: queryArg.updateDataElementRequest,
            }),
        }),
        updateLoanCovenants: build.mutation<UpdateLoanCovenantsApiResponse, UpdateLoanCovenantsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/covenants`,
                method: 'PUT',
                body: queryArg.updateCovenantRequest,
            }),
        }),
        saveLoanCovenants: build.mutation<SaveLoanCovenantsApiResponse, SaveLoanCovenantsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/covenants`,
                method: 'POST',
                body: queryArg.createCovenantRequest,
            }),
        }),
        deleteLoanCovenants: build.mutation<DeleteLoanCovenantsApiResponse, DeleteLoanCovenantsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/covenants/${queryArg.covenantId}`,
                method: 'DELETE',
            }),
        }),
        getLoanDraw: build.query<GetLoanDrawApiResponse, GetLoanDrawApiArg>({
            query: (queryArg) => ({ url: `/api/v2/user-loans/${queryArg.loanDetailsId}/draw` }),
        }),
        createLoanDraw: build.mutation<CreateLoanDrawApiResponse, CreateLoanDrawApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/pre-draw`,
                method: 'POST',
                body: queryArg.preDevelopmentRequest,
            }),
        }),
        getPreDevelopmentDrawDocuments: build.query<
            GetPreDevelopmentDrawDocumentsApiResponse,
            GetPreDevelopmentDrawDocumentsApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}/documents` }),
        }),
        getPreDevelopmentDrawFunding: build.query<
            GetPreDevelopmentDrawFundingApiResponse,
            GetPreDevelopmentDrawFundingApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}/funding` }),
        }),
        updatePreDevelopmentDrawFunding: build.mutation<
            UpdatePreDevelopmentDrawFundingApiResponse,
            UpdatePreDevelopmentDrawFundingApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}/funding`,
                method: 'PUT',
                body: queryArg.addDrawFundingRequest,
            }),
        }),
        getDrawSummary: build.query<GetDrawSummaryApiResponse, GetDrawSummaryApiArg>({
            query: (queryArg) => ({ url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}/summary` }),
        }),
        getDrawSummaryDetails: build.query<GetDrawSummaryDetailsApiResponse, GetDrawSummaryDetailsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}/summary/${queryArg.category}`,
            }),
        }),
        updateDocumentDraw: build.mutation<UpdateDocumentDrawApiResponse, UpdateDocumentDrawApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/pre-draw/document-update`,
                method: 'PUT',
                body: queryArg.updateDocumentDataRequest,
            }),
        }),
        addEntryToList: build.mutation<AddEntryToListApiResponse, AddEntryToListApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/loan-data/list-entry`,
                method: 'PUT',
                body: queryArg.addListDefinitionEntryRequest,
            }),
        }),
        addNewLoanProgram: build.mutation<AddNewLoanProgramApiResponse, AddNewLoanProgramApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/loan-data/add-new-loan-program`,
                method: 'POST',
                body: queryArg.addListDefinitionEntryRequest,
            }),
        }),
        deleteLoanStatusEvent: build.mutation<DeleteLoanStatusEventApiResponse, DeleteLoanStatusEventApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/status-event/${queryArg.statusUuid}`,
                method: 'DELETE',
            }),
        }),
        getPreDevelopmentDraw: build.query<GetPreDevelopmentDrawApiResponse, GetPreDevelopmentDrawApiArg>({
            query: (queryArg) => ({ url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}` }),
        }),
        updatePreDevelopmentDraw: build.mutation<UpdatePreDevelopmentDrawApiResponse, UpdatePreDevelopmentDrawApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}`,
                method: 'PUT',
                body: queryArg.updatePreDevelopmentRequest,
            }),
        }),
        deletePreDevelopmentDraw: build.mutation<DeletePreDevelopmentDrawApiResponse, DeletePreDevelopmentDrawApiArg>({
            query: (queryArg) => ({ url: `/api/v2/user-loans/pre-draw/${queryArg.preDrawId}`, method: 'DELETE' }),
        }),
        getDebtService: build.query<GetDebtServiceApiResponse, GetDebtServiceApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/debt-service/${queryArg.loanDetailsId}`,
                params: { period: queryArg.period, year: queryArg.year },
            }),
        }),
        getLoanStatusEvents: build.query<GetLoanStatusEventsApiResponse, GetLoanStatusEventsApiArg>({
            query: (queryArg) => ({ url: `/api/v2/user-loans/${queryArg.loanDetailsId}/status-event` }),
        }),
        updateLoanStatusEvent: build.mutation<UpdateLoanStatusEventApiResponse, UpdateLoanStatusEventApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/status-event`,
                method: 'PUT',
                body: queryArg.loanStatusEventRequest,
            }),
        }),
        addLoanStatusEvent: build.mutation<AddLoanStatusEventApiResponse, AddLoanStatusEventApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/status-event`,
                method: 'POST',
                body: queryArg.loanStatusEventRequest,
            }),
        }),
        updateHistoricalLoanData: build.mutation<UpdateHistoricalLoanDataApiResponse, UpdateHistoricalLoanDataApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/user-loans/${queryArg.loanDetailsId}/${queryArg.historicalDataId}/historical-data`,
                method: 'PUT',
                body: queryArg.updateDataElementRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetNewLoanProgramListApiResponse = /** status 200 OK */ GenericJsonResponseListListEntry;
export type GetNewLoanProgramListApiArg = void;
export type GetLoanByIdApiResponse = /** status 200 OK */ GenericJsonResponseUserLoanDto;
export type GetLoanByIdApiArg = {
    loanId: number;
};
export type UpdateLoanDataApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type UpdateLoanDataApiArg = {
    loanDetailsId: number;
    updateDataElementRequest: UpdateDataElementRequest;
};
export type UpdateLoanCovenantsApiResponse = /** status 200 OK */ GenericJsonResponseCovenantDto;
export type UpdateLoanCovenantsApiArg = {
    loanDetailsId: number;
    updateCovenantRequest: UpdateCovenantRequest;
};
export type SaveLoanCovenantsApiResponse = /** status 200 OK */ GenericJsonResponseCovenantDto;
export type SaveLoanCovenantsApiArg = {
    loanDetailsId: number;
    createCovenantRequest: CreateCovenantRequest;
};
export type DeleteLoanCovenantsApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type DeleteLoanCovenantsApiArg = {
    loanDetailsId: number;
    covenantId: number;
};
export type GetLoanDrawApiResponse = /** status 200 OK */ GenericJsonResponseLoanDrawsDto;
export type GetLoanDrawApiArg = {
    /** ID of the loan details */
    loanDetailsId: any;
};
export type CreateLoanDrawApiResponse = /** status 200 OK */ GenericJsonResponsePreDevelopmentDrawCard;
export type CreateLoanDrawApiArg = {
    /** ID of the loan details */
    loanDetailsId: any;
    preDevelopmentRequest: PreDevelopmentRequest;
};
export type GetPreDevelopmentDrawDocumentsApiResponse = /** status 200 OK */ GenericJsonResponseListDocumentDrawDto;
export type GetPreDevelopmentDrawDocumentsApiArg = {
    /** ID of the pre-development draw */
    preDrawId: any;
};
export type GetPreDevelopmentDrawFundingApiResponse = /** status 200 OK */ GenericJsonResponseDrawFundingDto;
export type GetPreDevelopmentDrawFundingApiArg = {
    /** ID of the pre-development draw */
    preDrawId: any;
};
export type UpdatePreDevelopmentDrawFundingApiResponse = /** status 200 OK */ GenericJsonResponseDrawFundingDto;
export type UpdatePreDevelopmentDrawFundingApiArg = {
    /** ID of the pre-development draw */
    preDrawId: any;
    /** Request contains funding name and value */
    request: any;
    addDrawFundingRequest: AddDrawFundingRequest;
};
export type GetDrawSummaryApiResponse = /** status 200 OK */ GenericJsonResponseDrawSummaryDto;
export type GetDrawSummaryApiArg = {
    preDrawId: number;
};
export type GetDrawSummaryDetailsApiResponse = /** status 200 OK */ GenericJsonResponseSummaryDetails;
export type GetDrawSummaryDetailsApiArg = {
    preDrawId: number;
    category:
        | 'REVENUE_BASE_RENTAL'
        | 'REVENUE_EXPENSE_REIMBURSTMENT'
        | 'REVENUE_VACANCY'
        | 'REVENUE_EMPLOYEE_UNITS'
        | 'REVENUE_MODEL_NONREV'
        | 'REVENUE_LAUNDRY_VENDING'
        | 'REVENUE_PERCENTAGE_RENT'
        | 'REVENUE_PARKING'
        | 'REVENUE_ELIMINATED'
        | 'REVENUE_OTHER'
        | 'REVENUE_BAD_DEBT'
        | 'REVENUE_CONCESSION'
        | 'REVENUE_COMMERCIAL'
        | 'REVENUE_SECOND_RESIDENT'
        | 'EXPENSES_TAXES'
        | 'EXPENSES_UTILITIES'
        | 'EXPENSES_INSURANCE'
        | 'EXPENSES_CLEANING'
        | 'EXPENSES_MAINTENANCE'
        | 'EXPENSES_MANAGEMENT'
        | 'EXPENSES_MANAGEMENT_FEE'
        | 'EXPENSES_CAPITAL'
        | 'EXPENSES_EXTRA_CAPITAL'
        | 'EXPENSES_PAYROLL'
        | 'EXPENSES_PROFESSIONAL_FEES'
        | 'EXPENSES_ADVERTISING'
        | 'EXPENSES_LEASING'
        | 'EXPENSES_GROUND_RENT'
        | 'EXPENSES_TENANT_IMPROVEMENTS'
        | 'EXPENSES_ELIMINATED'
        | 'EXPENSES_RESERVES'
        | 'EXPENSES_OTHER'
        | 'REVENUE_GROSS_POTENTIAL_RES'
        | 'REVENUE_RESIDENTIAL_CONCESSIONS_RES'
        | 'REVENUE_RESIDENTIAL_VACANCY_RES'
        | 'REVENUE_RESIDENTIAL_BAD_DEBT_RES'
        | 'REVENUE_SHORT_TERM_PREMIUMS_RES'
        | 'REVENUE_COMMERCIAL_INCOME_COM'
        | 'REVENUE_COMMERCIAL_VACANCY_COM'
        | 'REVENUE_COOP_MAINT_FEE_EGI'
        | 'REVENUE_UTILITY_REIMBURSEMENT_EGI'
        | 'REVENUE_LAUNDRY_EGI'
        | 'REVENUE_PARKING_EGI'
        | 'REVENUE_CABLE_EGI'
        | 'REVENUE_MISCELLANEOUS_EGI'
        | 'REVENUE_SHORT_TERM_PREMIUMS_EGI'
        | 'REVENUE_CORPORATE_PREMIUMS_EGI'
        | 'REVENUE_APPLICATION_FEE_EGI'
        | 'REVENUE_VENDING_EGI'
        | 'EXPENSES_REAL_ESTATE_TAXES_FIXED'
        | 'EXPENSES_INSURANCE_FIXED'
        | 'EXPENSES_GROUND_RENT_FIXED'
        | 'EXPENSES_FUEL_UTILITIES_FIXED'
        | 'EXPENSES_GAS_UTILITIES_FIXED'
        | 'EXPENSES_ELECTRICITY_UTILITIES_FIXED'
        | 'EXPENSES_WATER_SEWER_UTILITIES_FIXED'
        | 'EXPENSES_TRASH_REMOVAL_RM'
        | 'EXPENSES_PEST_CONTROL_RM'
        | 'EXPENSES_BUILDING_MAINTENANCE_RM'
        | 'EXPENSES_DECORATING_RM'
        | 'EXPENSES_CLEANING_RM'
        | 'EXPENSES_ELEVATOR_RM'
        | 'EXPENSES_RECREATION_AMENITIES_RM'
        | 'EXPENSES_PARKING_SNOW_RM'
        | 'EXPENSES_GROUNDS_MAINTENANCE_RM'
        | 'EXPENSES_NONRESIDENT_MGT'
        | 'EXPENSES_NONRESIDENT_MGT_TP'
        | 'EXPENSES_ONSITE_MANAGER_SALARY_TP'
        | 'EXPENSES_SECURITY_TP'
        | 'EXPENSES_OTHER_SALARIES_TP'
        | 'EXPENSES_PAYROLL_TAXES_BENEFITS_TP'
        | 'EXPENSES_APT_ALLOWANCE_TP'
        | 'EXPENSES_ADVERTISING_ADMIN'
        | 'EXPENSES_MODEL_OFFICE_DOWN_UNITS_ADMIN'
        | 'EXPENSES_OFFICE_EXPENSES_ADMIN'
        | 'EXPENSES_LEGAL_AUDIT_ADMIN'
        | 'EXPENSES_LEASED_FURNITURE_ADMIN'
        | 'EXPENSES_CABLE_INTERNET_ADMIN'
        | 'EXPENSES_LICENSE_PERMITS_ADMIN'
        | 'EXPENSES_OTHER_TAXES_ADMIN'
        | 'EXPENSES_CORPORATE_UNITS_ADMIN'
        | 'EXPENSES_SECURITY_ADMIN'
        | 'EXPENSES_COMMERCIAL_OTHER'
        | 'EXPENSES_MISCELLANEOUS_OTHER'
        | 'EXPENSES_MISCELLANEOUS'
        | 'EXPENSES_GROUND_RENT_RESERVES'
        | 'EXPENSES_REPLACEMENT_RESERVES'
        | 'EXPENSES_TENANT_IMPROVEMENTS_LEASING_RESERVES'
        | 'REVENUE_COMM_RENTAL_INC'
        | 'REVENUE_COMM_CONCESSIONS_INC'
        | 'REVENUE_COMM_VACANCY_INC'
        | 'REVENUE_COMM_RETAX_REIMB'
        | 'REVENUE_COMM_UTIL_REIMB'
        | 'REVENUE_COMM_CAM_REIMB'
        | 'REVENUE_COMM_OTHER_REIMB'
        | 'REVENUE_COMM_CONCESSIONS_REIMB'
        | 'REVENUE_COMM_OTHER_EGI'
        | 'REVENUE_MARKET_GROSS_POTENTIAL_GPR'
        | 'REVENUE_LOSS_TO_LEASE_GPR'
        | 'REVENUE_COOP_MAINT_FEE_OTHER'
        | 'REVENUE_UTILITY_REIMBURSEMENT_OTHER'
        | 'REVENUE_LAUNDRY_OTHER'
        | 'REVENUE_PARKING_OTHER'
        | 'REVENUE_CABLE_OTHER'
        | 'REVENUE_SHORT_TERM_PREMIUMS_OTHER'
        | 'REVENUE_CORPORATE_PREMIUMS_OTHER'
        | 'REVENUE_MISCELLANEOUS_OTHER'
        | 'EXPENSES_TRASH_REMOVAL_UTILITIES_FIXED'
        | 'EXPENSES_CONTRACT_SERVICES_RM'
        | 'EXPENSES_LEASING_AGENT_SALARY_TP'
        | 'EXPENSES_MAINT_JANITOR_SALARY_TP'
        | 'EXPENSES_EMP_ALLOWANCE_TP'
        | 'EXPENSES_PAYROLL_PROCESSING_TP'
        | 'EXPENSES_TELEPHONE_ADMIN'
        | 'EXPENSES_PROFESSIONAL_FEES_ADMIN'
        | 'EXPENSES_GROUND_LEASE_OTHER'
        | 'EXPENSES_GENERAL_UTILITIES_FIXED'
        | 'EXPENSES_CAM_MAINTENANCE'
        | 'EXPENSES_WATER_SEWER'
        | 'REVENUE_PRIVATE_PAY'
        | 'REVENUE_MEDICARE'
        | 'REVENUE_NURSING'
        | 'REVENUE_MEALS'
        | 'REVENUE_SENIOR_OTHER'
        | 'REVENUE_DEP_MEALS'
        | 'REVENUE_DEP_TELEPHONE'
        | 'REVENUE_DEP_OTHER'
        | 'EXPENSES_MEALS'
        | 'EXPENSES_DEP_ROOM'
        | 'EXPENSES_DEP_MEALS'
        | 'EXPENSES_DEP_TELEPHONE'
        | 'EXPENSES_DEP_OTHER'
        | 'EXPENSES_FRANCHISE_FEE'
        | 'REVENUE_MEDICAID'
        | 'REVENUE_ENTRANCE_FEES'
        | 'REVENUE_ASSISTED_LIVING'
        | 'REVENUE_ALZ_CARE'
        | 'REVENUE_BEAUTY_SALON'
        | 'REVENUE_PHYSICAL_THERAPY'
        | 'REVENUE_RESPITE'
        | 'EXPENSES_ASSISTED_LIVING'
        | 'EXPENSES_TRANSPORTATION'
        | 'EXPENSES_THERAPY'
        | 'EXPENSES_HVAC'
        | 'EXPENSES_TURNOVER'
        | 'EXPENSES_LANDSCAPING'
        | 'EXPENSES_SECURITY'
        | 'EXPENSES_FFE_RESERVE'
        | 'EXPENSES_MAKE_READY'
        | 'REVENUE_NON_RENTAL1'
        | 'REVENUE_NON_RENTAL2'
        | 'REVENUE_NON_RENTAL3'
        | 'REVENUE_COMM_INS_REIMB'
        | 'REVENUE_COMM_CLEAN_REIMB'
        | 'REVENUE_COMM_ADMIN_REIMB'
        | 'EXPENSES_ELECTRICITY_UTIL'
        | 'EXPENSES_FUEL_UTIL'
        | 'EXPENSES_GAS_UTIL'
        | 'EXPENSES_OTHER_UTIL'
        | 'EXPENSES_WATER_SEWER_UTIL'
        | 'EXPENSES_TRASH_REMOVAL_UTIL'
        | 'EXPENSES_OTHER_MAINTENANCE_RM'
        | 'EXPENSES_PAYROLL_OTHER_TP'
        | 'EXPENSES_MANAGEMENT_ADMIN'
        | 'EXPENSES_MANAGEMENT1_ADMIN'
        | 'EXPENSES_MANAGEMENT2_ADMIN'
        | 'EXPENSES_EMPLOYEE_UNIT_TP'
        | 'EXPENSES_OTHER_TAXES'
        | 'REVENUE_DEP_OTHER2'
        | 'REVENUE_DEP_OTHER3'
        | 'EXPENSES_DEP_OTHER2'
        | 'EXPENSES_DEP_OTHER3'
        | 'EXPENSES_INCENTIVE_MGMT_FEE'
        | 'EXPENSES_ELIMINATED_SUB'
        | 'EXPENSES_DEBT'
        | 'REVENUE_PARKING_OPEN_EGI'
        | 'REVENUE_PARKING_COVERED_EGI'
        | 'EXPENSES_WATER_UTILITIES_FIXED'
        | 'EXPENSES_SEWER_UTILITIES_FIXED'
        | 'EXPENSES_EXTRIMINATING_UTILITIES_FIXED'
        | 'EXPENSES_PERSONAL_PROPERTY_TAXES_FIXED'
        | 'EXPENSES_MISCELLANEOUS_TAXES_FIXED'
        | 'REVENUE_RENT_ADJUSTMENTS'
        | 'REVENUE_RENT_ADJUSTMENTS_COMM'
        | 'EXPENSES_SALARIES'
        | 'EXPENSES_MAINTENANCE_GROUND'
        | 'OTHER_CAPITAL_NCF'
        | 'OTHER_REPLACEMENT_RESERVES_NCF'
        | 'OTHER_DEBT'
        | 'OTHER_DEBT2'
        | 'OTHER_DEBT_SUB'
        | 'OTHER_DEBT_AMORT'
        | 'OTHER_DEBT_AMORT2'
        | 'OTHER_DEBT_AMORT_SUB'
        | 'OTHER_LEASING_NCF'
        | 'OTHER_TENANT_IMPROVEMENTS_NCF'
        | 'OTHER_RESERVES_NCF'
        | 'OTHER_EXTRA_CAPITAL_NCF'
        | 'OTHER_ELIMINATED_NCF'
        | 'OTHER_NCF'
        | 'OTHER_EXPENSES_ELIMINATED_BELOW_NOI'
        | 'ADDED_EXPENSES'
        | 'ADDED_REVENUE'
        | 'EXPENSES_TOTAL'
        | 'REVENUE_TOTAL'
        | 'NOI'
        | 'NOI_MARGIN'
        | 'EXPENSES_AQUISITION'
        | 'EXPENSES_AQUISITION_OTHER'
        | 'EXPENSES_HARD_CONSTRUCTION_MATERIALS'
        | 'EXPENSES_HARD_CONSTRUCTION_LABOR'
        | 'EXPENSES_HARD_CONSTRUCTION_OVERHEADS'
        | 'EXPENSES_HARD_LANDSCAPING_COSTS'
        | 'EXPENSES_HARD_SITE_IMPROVEMENTS'
        | 'EXPENSES_HARD_UTILITY_INSTALLATION'
        | 'EXPENSES_HARD_INTERIOR_FINISHES'
        | 'EXPENSES_HARD_HVAC'
        | 'EXPENSES_HARD_LIFE_SAFETY_SYSTEMS'
        | 'EXPENSES_HARD_FURNITURE'
        | 'EXPENSES_HARD_BUILDING_EQUIPMENT'
        | 'EXPENSES_HARD_OTHER'
        | 'EXPENSES_SOFT_ARCHITECTURAL_PLANNING'
        | 'EXPENSES_SOFT_ENINEERING_COSTS'
        | 'EXPENSES_SOFT_LEGAL_FEES'
        | 'EXPENSES_SOFT_PERMITS'
        | 'EXPENSES_SOFT_TAXES'
        | 'EXPENSES_SOFT_INSURANCE'
        | 'EXPENSES_SOFT_MARKETING'
        | 'EXPENSES_SOFT_REAL_ESTATE_FEES'
        | 'EXPENSES_SOFT_POST_CONSTRUCTION'
        | 'EXPENSES_SOFT_OTHER'
        | 'EXPENSES_FINANCING_LOAN_FEES'
        | 'EXPENSES_FINANCING_OTHER'
        | 'EXPENSES_CONTINGENCY_DESIGN'
        | 'EXPENSES_CONTINGENCY_CONTRACTOR'
        | 'EXPENSES_CONTINGENCY_OWNER'
        | 'EXPENSES_CONTINGENCY_OTHER'
        | 'SOURCES_BORROWER_EQUITY'
        | 'SOURCES_LOAN'
        | 'SOURCES_RESERVES'
        | 'REVENUE_START'
        | 'EXPENSES_START'
        | 'OTHER_START'
        | 'OTHER'
        | 'TOTALS'
        | 'IGNORE';
};
export type UpdateDocumentDrawApiResponse = /** status 200 OK */ GenericJsonResponseDocumentDrawDto;
export type UpdateDocumentDrawApiArg = {
    /** Request object for updating document draw data */
    updateDocumentDataRequest: UpdateDocumentDataRequest;
};
export type AddEntryToListApiResponse = /** status 200 OK */ GenericJsonResponseListDefinition;
export type AddEntryToListApiArg = {
    addListDefinitionEntryRequest: AddListDefinitionEntryRequest;
};
export type AddNewLoanProgramApiResponse = /** status 200 OK */ GenericJsonResponseNewLoanProgramDto;
export type AddNewLoanProgramApiArg = {
    loanDetailsId: number;
    addListDefinitionEntryRequest: AddListDefinitionEntryRequest;
};
export type DeleteLoanStatusEventApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type DeleteLoanStatusEventApiArg = {
    loanDetailsId: number;
    statusUuid: string;
};
export type GetPreDevelopmentDrawApiResponse = /** status 200 OK */ GenericJsonResponsePreDevelopmentDrawDto;
export type GetPreDevelopmentDrawApiArg = {
    /** ID of the draw */
    preDrawId: any;
};
export type UpdatePreDevelopmentDrawApiResponse = /** status 200 OK */ GenericJsonResponsePreDevelopmentDrawCard;
export type UpdatePreDevelopmentDrawApiArg = {
    /** ID of the pre-development draw */
    preDrawId: any;
    /** Request contains draw data */
    request: UpdatePreDevelopmentRequest;
    updatePreDevelopmentRequest: UpdatePreDevelopmentRequest;
};
export type DeletePreDevelopmentDrawApiResponse = /** status 200 Successful operation */ GenericJsonResponseVoid;
export type DeletePreDevelopmentDrawApiArg = {
    /** ID of the pre-development draw */
    preDrawId: any;
};
export type GetDebtServiceApiResponse = /** status 200 OK */ GenericJsonResponseLoanDebtDto;
export type GetDebtServiceApiArg = {
    loanDetailsId: number;
    period: 'ANNUALLY' | 'MONTHLY' | 'QUARTERLY';
    year?: number;
};
export type GetLoanStatusEventsApiResponse = /** status 200 OK */ GenericJsonResponseListLoanStatusEventDto;
export type GetLoanStatusEventsApiArg = {
    loanDetailsId: number;
};
export type UpdateLoanStatusEventApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type UpdateLoanStatusEventApiArg = {
    loanDetailsId: number;
    loanStatusEventRequest: LoanStatusEventRequest;
};
export type AddLoanStatusEventApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type AddLoanStatusEventApiArg = {
    loanDetailsId: number;
    loanStatusEventRequest: LoanStatusEventRequest;
};
export type UpdateHistoricalLoanDataApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type UpdateHistoricalLoanDataApiArg = {
    loanDetailsId: number;
    historicalDataId: number;
    updateDataElementRequest: UpdateDataElementRequest;
};
export type ListEntry = {
    id?: number;
    uuid?: string;
    displayName?: string;
    legacyId?: string;
};
export type GenericJsonResponseListListEntry = {
    status?: string;
    data?: ListEntry[];
    message?: string;
    code?: string;
};
export type UnderwritingMetricsDto = {
    lesser?: number;
    contract?: number;
    market?: number;
    rollover?: number;
    downMarket?: number;
    customScenarios?: {
        [key: string]: number;
    };
};
export type LoanPropertyDto = {
    id?: number;
    propertyId?: string;
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
    userId?: number;
    groupId?: number;
    maturityDate?: string;
    address?: string;
    state?: string;
    propertyType?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    isDetailAnalyze?: boolean;
    size?: number;
    units?: number;
    acres?: number;
    underwritingRevenue?: UnderwritingMetricsDto;
    underwritingNoi?: UnderwritingMetricsDto;
    underwritingValuation?: UnderwritingMetricsDto;
    principal?: number;
    balance?: number;
};
export type TeamMemberDto = {
    id?: number;
    loanRole?: LoanRole;
    position?: string;
    lead?: boolean;
};
export type UnderwritingFinancialsDto = {
    proformaTemplate?: string;
    loanId?: number;
    loanName?: string;
    loanPropertyList?: LoanPropertyDto[];
    financials?: {
        [key: string]: object;
    };
};
export type LoanModelDto = {
    id?: number;
    name?: string;
    valuationType?: ValuationType;
    notes?: string;
    modelDetails?: {
        [key: string]: object;
    };
    financials?: UnderwritingFinancialsDto;
    createdAt?: string;
    updatedAt?: string;
    asOfDate?: number;
    assetValue?: number;
    ltv?: number;
    dscr?: number;
    debtYield?: number;
    default?: boolean;
};
export type CompanyOwnerMemberDto = {
    name?: string;
    companyType?: CompanyType;
    logoPath?: string;
    ownership?: number;
};
export type MemberResponseDto = {
    id?: string;
    userId?: number;
    companyId?: string;
    type?: string;
    notes?: string;
    ssn?: string;
    outstanding?: number;
    liquidity?: number;
    netWorth?: number;
    asOf?: string;
    ownedCompanies?: CompanyOwnerMemberDto[];
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    userId?: number;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type UserCompanyDto = {
    userId?: number;
    companyId?: string;
    position?: string;
    companyName?: string;
    updatedAt?: string;
};
export type UserMemberResponseDto = {
    member?: MemberResponseDto;
    userInfo?: UserInfoDto;
    userCompanyDTO?: UserCompanyDto;
    teamMemberDTO?: TeamMemberDto;
};
export type CompanyDto = {
    id?: string;
    name?: string;
    companyType?: CompanyType;
    companyUrl?: string;
    email?: string;
    taxId?: string;
    contactAddress?: string;
    phone?: string;
    logoPath?: string;
    createdAt?: string;
    createdBy?: number;
};
export type CompanyMemberResponseDto = {
    member?: MemberResponseDto;
    company?: CompanyDto;
};
export type MemberDto = {
    userMember?: UserMemberResponseDto;
    companyMember?: CompanyMemberResponseDto;
    isNew?: boolean;
    createdAt?: string;
    id?: string;
    user?: boolean;
};
export type UserLoanDto = {
    id?: number;
    countyId?: string;
    parcelId?: string;
    propertyId?: string;
    propertyName?: string;
    loanId?: string;
    principal?: number;
    balance?: number;
    unfundedBalance?: number;
    repoPrincipal?: number;
    repoBalance?: number;
    cashSweep?: string;
    loanRating?: string;
    maturityDate?: string;
    approvedDate?: string;
    permission?: Permission;
    address?: string;
    propertyType?: string;
    jsonValues?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    propertyCount?: number;
    propertyUnit?: number;
    propertySize?: number;
    isDetailAnalyze?: boolean;
    loanPropertyList?: LoanPropertyDto[];
    detailsStatus?: string;
    units?: number;
    size?: number;
    acres?: number;
    vacancy?: number;
    occupancy?: number;
    assetValue?: number;
    ltv?: number;
    dscrNoi?: number;
    dscrNcf?: number;
    debtYield?: number;
    currentBalanceDY?: number;
    debtYield2?: number;
    currentBalanceDY2?: number;
    coupon?: number;
    spread?: number;
    interestRateCap?: number;
    dscrSofr?: number;
    propertyScore?: number;
    borrower?: string;
    lender?: string;
    loanProgram?: string;
    loanType?: string;
    loanName?: string;
    isNew?: boolean;
    lenderTeamLead?: TeamMemberDto;
    loanContactRoles?: string[];
    defaultModel?: LoanModelDto;
    documentStatuses?: string[];
    borrowerDefinition?: MemberDto;
    lenderDefinition?: MemberDto;
    guarantorDefinition?: MemberDto;
    loanData?: object;
    createdAt?: string;
    lastLoanImportDate?: string;
    cashReserve?: number;
    totalReserves?: number;
    repoLender?: string;
    totalFutureFunding?: number;
    rent?: number;
    noi?: number;
    ncf?: number;
    repoDSCR?: number;
    modelName?: string;
    modelNotes?: string;
    interestCapRateExpiration?: number;
    indexFloor?: number;
    indexValue?: number;
    adjustment?: number;
    indexType?: string;
    userRequestStatus?: string;
    loanApplicationReferId?: string;
    loanRequestStatus?: string;
    newLoanProgram?: string;
    loanProgramNumber?: number;
    readOnly?: boolean;
};
export type GenericJsonResponseUserLoanDto = {
    status?: string;
    data?: UserLoanDto;
    message?: string;
    code?: string;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type JsonNode = object;
export type UpdateDataElementRequest = {
    path?: string;
    value?: JsonNode;
    elementType?: ElementType;
    isCustomPath?: boolean;
};
export type CovenantDto = {
    id?: number;
    type?: Type;
    threshold?: number;
    action?: Action;
};
export type GenericJsonResponseCovenantDto = {
    status?: string;
    data?: CovenantDto;
    message?: string;
    code?: string;
};
export type UpdateCovenantRequest = {
    id: number;
    type: Type;
    threshold: number;
    action: Action;
};
export type CreateCovenantRequest = {
    type: Type;
    threshold: number;
    action: Action;
};
export type FundingInfo = {
    name?: string;
    currentAmount?: number;
    previousAmount?: number;
    total?: number;
    remaining?: number;
    percentage?: number;
    subFundingList?: FundingInfo[];
};
export type PreDevelopmentDrawCard = {
    id?: number;
    title?: string;
    amount?: number;
    status?: Status;
    date?: string;
};
export type PropertyCredentials = {
    countyId?: string;
    parcelId?: string;
    userId?: number;
};
export type LoanDrawsDto = {
    budget?: FundingInfo;
    loan?: FundingInfo;
    equity?: FundingInfo;
    other?: FundingInfo;
    preDevelopmentDraws?: PreDevelopmentDrawCard[];
    lastDrawDate?: string;
    inspectionDate?: string;
    propertyCredentials?: PropertyCredentials;
};
export type GenericJsonResponseLoanDrawsDto = {
    status?: string;
    data?: LoanDrawsDto;
    message?: string;
    code?: string;
};
export type GenericJsonResponsePreDevelopmentDrawCard = {
    status?: string;
    data?: PreDevelopmentDrawCard;
    message?: string;
    code?: string;
};
export type PreDevelopmentRequest = {
    name: string;
    amount: number;
    date: string;
    status?: Status;
};
export type DrawExpenseData = {
    category?: Category;
    amount?: number;
    description?: string;
};
export type DocumentDrawDto = {
    id?: number;
    name?: string;
    type?: Type2;
    vendor?: MemberDto;
    invoiceNumber?: string;
    amount?: number;
    documentDate?: string;
    documentStatus?: DocumentStatus;
    reviewer?: UserInfoDto;
    fileName?: string;
    expenses?: {
        [key: string]: DrawExpenseData;
    };
};
export type GenericJsonResponseListDocumentDrawDto = {
    status?: string;
    data?: DocumentDrawDto[];
    message?: string;
    code?: string;
};
export type DrawHeader = {
    name?: string;
};
export type DrawRowCell = {
    value?: number;
    isCurrentDraw?: boolean;
};
export type DrawRow = {
    id?: number;
    name?: string;
    values?: DrawRowCell[];
    subtotalId?: number;
    subTotal?: boolean;
};
export type DrawFundingDto = {
    headers?: DrawHeader[];
    rows?: DrawRow[];
    bars?: FundingInfo[];
};
export type GenericJsonResponseDrawFundingDto = {
    status?: string;
    data?: DrawFundingDto;
    message?: string;
    code?: string;
};
export type AddDrawFundingRequest = {
    name: string;
    amount: number;
};
export type DrawSummaryDto = {
    headers?: DrawHeader[];
    rows?: DrawRow[];
};
export type GenericJsonResponseDrawSummaryDto = {
    status?: string;
    data?: DrawSummaryDto;
    message?: string;
    code?: string;
};
export type ExpensesDetails = {
    name?: string;
    thisDraw?: number;
    toDate?: number;
};
export type ReconciliationDetails = {
    name?: string;
    amount?: number;
};
export type SummaryDetails = {
    documents?: string[];
    expenses?: ExpensesDetails[];
    reconciliations?: ReconciliationDetails[];
};
export type GenericJsonResponseSummaryDetails = {
    status?: string;
    data?: SummaryDetails;
    message?: string;
    code?: string;
};
export type GenericJsonResponseDocumentDrawDto = {
    status?: string;
    data?: DocumentDrawDto;
    message?: string;
    code?: string;
};
export type UpdateDocumentDataRequest = {
    documentId: number;
    drawId: number;
    vendor?: string;
    invoiceDate?: string;
    invoiceNumber?: string;
    invoiceData?: {
        [key: string]: DrawExpenseData;
    };
    reviewerEmail?: string;
};
export type ListDefinition = {
    listName?: string;
    values?: ListEntry[];
};
export type GenericJsonResponseListDefinition = {
    status?: string;
    data?: ListDefinition;
    message?: string;
    code?: string;
};
export type AddListDefinitionEntryRequest = {
    listName: string;
    displayName: string;
    value: string;
};
export type NewLoanProgramDto = {
    id?: number;
    uuid?: string;
    loanDetailsId?: number;
    groupId?: number;
    userId?: number;
    name?: string;
    createdAt?: number;
};
export type GenericJsonResponseNewLoanProgramDto = {
    status?: string;
    data?: NewLoanProgramDto;
    message?: string;
    code?: string;
};
export type PreDevelopmentDrawDto = {
    drawId?: number;
    drawName?: string;
    loanDetailsId?: number;
    loanId?: string;
    loanName?: string;
    date?: string;
    status?: Status;
};
export type GenericJsonResponsePreDevelopmentDrawDto = {
    status?: string;
    data?: PreDevelopmentDrawDto;
    message?: string;
    code?: string;
};
export type UpdatePreDevelopmentRequest = {
    name?: string;
    amount?: number;
    date?: string;
    status?: Status;
};
export type LoanDebtValue = {
    get_index?: number;
    path?: string;
    name?: string;
    label?: string;
    value?: number;
};
export type LoanDebtColumn = {
    historicalLoanDataId?: number;
    periodHeader?: string;
    timestamp?: string;
    loanDebtValues?: LoanDebtValue[];
};
export type LoanDebtDto = {
    columns?: LoanDebtColumn[];
};
export type GenericJsonResponseLoanDebtDto = {
    status?: string;
    data?: LoanDebtDto;
    message?: string;
    code?: string;
};
export type LoanStatusEventDto = {
    uuid?: string;
    status?: string;
    date?: number;
    isCurrent?: boolean;
};
export type GenericJsonResponseListLoanStatusEventDto = {
    status?: string;
    data?: LoanStatusEventDto[];
    message?: string;
    code?: string;
};
export type LoanStatusEventRequest = {
    uuid: string;
    status?: string;
    date?: number;
};
export enum Permission {
    Admin = 'ADMIN',
    Creator = 'CREATOR',
    Owner = 'OWNER',
    Collaborator = 'COLLABORATOR',
    Viewer = 'VIEWER',
    None = 'NONE',
}
export enum LoanRole {
    Borrower = 'BORROWER',
    Lender = 'LENDER',
    Guarantor = 'GUARANTOR',
    Broker = 'BROKER',
    Other = 'OTHER',
}
export enum ValuationType {
    CapitalizedNoi = 'CAPITALIZED_NOI',
    DiscountedCashFlow = 'DISCOUNTED_CASH_FLOW',
}
export enum CompanyType {
    Llc = 'LLC',
    Corporation = 'CORPORATION',
}
export enum ElementType {
    List = 'LIST',
    String = 'STRING',
    Number = 'NUMBER',
    Date = 'DATE',
    Rate = 'RATE',
    YesNo = 'YES_NO',
    Sequence = 'SEQUENCE',
    Dictionary = 'DICTIONARY',
    KeyDate = 'KEY_DATE',
    FundingEntry = 'FUNDING_ENTRY',
    FundingBlock = 'FUNDING_BLOCK',
    PrepaymentProtection = 'PREPAYMENT_PROTECTION',
    MultiList = 'MULTI_LIST',
    ReserveCommitmentEntry = 'RESERVE_COMMITMENT_ENTRY',
    ReserveBalanceEntry = 'RESERVE_BALANCE_ENTRY',
    LoanIdCustom = 'LOAN_ID_CUSTOM',
}
export enum Type {
    MinDscr = 'MIN_DSCR',
    MinDebtYield = 'MIN_DEBT_YIELD',
}
export enum Action {
    CashSweep = 'CASH_SWEEP',
    Funding = 'FUNDING',
    FirstExtension = 'FIRST_EXTENSION',
    SecondExtension = 'SECOND_EXTENSION',
    DebtServiceReserveRebalancing = 'DEBT_SERVICE_RESERVE_REBALANCING',
    DebtServiceReserveRelease = 'DEBT_SERVICE_RESERVE_RELEASE',
    InterestRateCapExtension = 'INTEREST_RATE_CAP_EXTENSION',
}
export enum Status {
    InReview = 'IN_REVIEW',
    InProgress = 'IN_PROGRESS',
    Approved = 'APPROVED',
    Paid = 'PAID',
}
export enum Type2 {
    RentRoll = 'RENT_ROLL',
    OperatingStatement = 'OPERATING_STATEMENT',
    Budget = 'BUDGET',
    TaxReturn = 'TAX_RETURN',
    BankStatement = 'BANK_STATEMENT',
    DevelopmentBudget = 'DEVELOPMENT_BUDGET',
    Invoice = 'INVOICE',
    PropertyPurchaseAgreement = 'PROPERTY_PURCHASE_AGREEMENT',
    BalanceSheet = 'BALANCE_SHEET',
    GuarantorFinancialStatement = 'GUARANTOR_FINANCIAL_STATEMENT',
    MortgageStatement = 'MORTGAGE_STATEMENT',
    InsurancePolicy = 'INSURANCE_POLICY',
    InspectionReport = 'INSPECTION_REPORT',
    AppraisalReport = 'APPRAISAL_REPORT',
    InfoMemo = 'INFO_MEMO',
    LoanInfoMemo = 'LOAN_INFO_MEMO',
    LeaseAgreement = 'LEASE_AGREEMENT',
    LoanAgreement = 'LOAN_AGREEMENT',
    InsuranceForm = 'INSURANCE_FORM',
    InsuranceAgreement = 'INSURANCE_AGREEMENT',
    Other = 'OTHER',
}
export enum DocumentStatus {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum Category {
    RevenueBaseRental = 'REVENUE_BASE_RENTAL',
    RevenueExpenseReimburstment = 'REVENUE_EXPENSE_REIMBURSTMENT',
    RevenueVacancy = 'REVENUE_VACANCY',
    RevenueEmployeeUnits = 'REVENUE_EMPLOYEE_UNITS',
    RevenueModelNonrev = 'REVENUE_MODEL_NONREV',
    RevenueLaundryVending = 'REVENUE_LAUNDRY_VENDING',
    RevenuePercentageRent = 'REVENUE_PERCENTAGE_RENT',
    RevenueParking = 'REVENUE_PARKING',
    RevenueEliminated = 'REVENUE_ELIMINATED',
    RevenueOther = 'REVENUE_OTHER',
    RevenueBadDebt = 'REVENUE_BAD_DEBT',
    RevenueConcession = 'REVENUE_CONCESSION',
    RevenueCommercial = 'REVENUE_COMMERCIAL',
    RevenueSecondResident = 'REVENUE_SECOND_RESIDENT',
    ExpensesTaxes = 'EXPENSES_TAXES',
    ExpensesUtilities = 'EXPENSES_UTILITIES',
    ExpensesInsurance = 'EXPENSES_INSURANCE',
    ExpensesCleaning = 'EXPENSES_CLEANING',
    ExpensesMaintenance = 'EXPENSES_MAINTENANCE',
    ExpensesManagement = 'EXPENSES_MANAGEMENT',
    ExpensesManagementFee = 'EXPENSES_MANAGEMENT_FEE',
    ExpensesCapital = 'EXPENSES_CAPITAL',
    ExpensesExtraCapital = 'EXPENSES_EXTRA_CAPITAL',
    ExpensesPayroll = 'EXPENSES_PAYROLL',
    ExpensesProfessionalFees = 'EXPENSES_PROFESSIONAL_FEES',
    ExpensesAdvertising = 'EXPENSES_ADVERTISING',
    ExpensesLeasing = 'EXPENSES_LEASING',
    ExpensesGroundRent = 'EXPENSES_GROUND_RENT',
    ExpensesTenantImprovements = 'EXPENSES_TENANT_IMPROVEMENTS',
    ExpensesEliminated = 'EXPENSES_ELIMINATED',
    ExpensesReserves = 'EXPENSES_RESERVES',
    ExpensesOther = 'EXPENSES_OTHER',
    RevenueGrossPotentialRes = 'REVENUE_GROSS_POTENTIAL_RES',
    RevenueResidentialConcessionsRes = 'REVENUE_RESIDENTIAL_CONCESSIONS_RES',
    RevenueResidentialVacancyRes = 'REVENUE_RESIDENTIAL_VACANCY_RES',
    RevenueResidentialBadDebtRes = 'REVENUE_RESIDENTIAL_BAD_DEBT_RES',
    RevenueShortTermPremiumsRes = 'REVENUE_SHORT_TERM_PREMIUMS_RES',
    RevenueCommercialIncomeCom = 'REVENUE_COMMERCIAL_INCOME_COM',
    RevenueCommercialVacancyCom = 'REVENUE_COMMERCIAL_VACANCY_COM',
    RevenueCoopMaintFeeEgi = 'REVENUE_COOP_MAINT_FEE_EGI',
    RevenueUtilityReimbursementEgi = 'REVENUE_UTILITY_REIMBURSEMENT_EGI',
    RevenueLaundryEgi = 'REVENUE_LAUNDRY_EGI',
    RevenueParkingEgi = 'REVENUE_PARKING_EGI',
    RevenueCableEgi = 'REVENUE_CABLE_EGI',
    RevenueMiscellaneousEgi = 'REVENUE_MISCELLANEOUS_EGI',
    RevenueShortTermPremiumsEgi = 'REVENUE_SHORT_TERM_PREMIUMS_EGI',
    RevenueCorporatePremiumsEgi = 'REVENUE_CORPORATE_PREMIUMS_EGI',
    RevenueApplicationFeeEgi = 'REVENUE_APPLICATION_FEE_EGI',
    RevenueVendingEgi = 'REVENUE_VENDING_EGI',
    ExpensesRealEstateTaxesFixed = 'EXPENSES_REAL_ESTATE_TAXES_FIXED',
    ExpensesInsuranceFixed = 'EXPENSES_INSURANCE_FIXED',
    ExpensesGroundRentFixed = 'EXPENSES_GROUND_RENT_FIXED',
    ExpensesFuelUtilitiesFixed = 'EXPENSES_FUEL_UTILITIES_FIXED',
    ExpensesGasUtilitiesFixed = 'EXPENSES_GAS_UTILITIES_FIXED',
    ExpensesElectricityUtilitiesFixed = 'EXPENSES_ELECTRICITY_UTILITIES_FIXED',
    ExpensesWaterSewerUtilitiesFixed = 'EXPENSES_WATER_SEWER_UTILITIES_FIXED',
    ExpensesTrashRemovalRm = 'EXPENSES_TRASH_REMOVAL_RM',
    ExpensesPestControlRm = 'EXPENSES_PEST_CONTROL_RM',
    ExpensesBuildingMaintenanceRm = 'EXPENSES_BUILDING_MAINTENANCE_RM',
    ExpensesDecoratingRm = 'EXPENSES_DECORATING_RM',
    ExpensesCleaningRm = 'EXPENSES_CLEANING_RM',
    ExpensesElevatorRm = 'EXPENSES_ELEVATOR_RM',
    ExpensesRecreationAmenitiesRm = 'EXPENSES_RECREATION_AMENITIES_RM',
    ExpensesParkingSnowRm = 'EXPENSES_PARKING_SNOW_RM',
    ExpensesGroundsMaintenanceRm = 'EXPENSES_GROUNDS_MAINTENANCE_RM',
    ExpensesNonresidentMgt = 'EXPENSES_NONRESIDENT_MGT',
    ExpensesNonresidentMgtTp = 'EXPENSES_NONRESIDENT_MGT_TP',
    ExpensesOnsiteManagerSalaryTp = 'EXPENSES_ONSITE_MANAGER_SALARY_TP',
    ExpensesSecurityTp = 'EXPENSES_SECURITY_TP',
    ExpensesOtherSalariesTp = 'EXPENSES_OTHER_SALARIES_TP',
    ExpensesPayrollTaxesBenefitsTp = 'EXPENSES_PAYROLL_TAXES_BENEFITS_TP',
    ExpensesAptAllowanceTp = 'EXPENSES_APT_ALLOWANCE_TP',
    ExpensesAdvertisingAdmin = 'EXPENSES_ADVERTISING_ADMIN',
    ExpensesModelOfficeDownUnitsAdmin = 'EXPENSES_MODEL_OFFICE_DOWN_UNITS_ADMIN',
    ExpensesOfficeExpensesAdmin = 'EXPENSES_OFFICE_EXPENSES_ADMIN',
    ExpensesLegalAuditAdmin = 'EXPENSES_LEGAL_AUDIT_ADMIN',
    ExpensesLeasedFurnitureAdmin = 'EXPENSES_LEASED_FURNITURE_ADMIN',
    ExpensesCableInternetAdmin = 'EXPENSES_CABLE_INTERNET_ADMIN',
    ExpensesLicensePermitsAdmin = 'EXPENSES_LICENSE_PERMITS_ADMIN',
    ExpensesOtherTaxesAdmin = 'EXPENSES_OTHER_TAXES_ADMIN',
    ExpensesCorporateUnitsAdmin = 'EXPENSES_CORPORATE_UNITS_ADMIN',
    ExpensesSecurityAdmin = 'EXPENSES_SECURITY_ADMIN',
    ExpensesCommercialOther = 'EXPENSES_COMMERCIAL_OTHER',
    ExpensesMiscellaneousOther = 'EXPENSES_MISCELLANEOUS_OTHER',
    ExpensesMiscellaneous = 'EXPENSES_MISCELLANEOUS',
    ExpensesGroundRentReserves = 'EXPENSES_GROUND_RENT_RESERVES',
    ExpensesReplacementReserves = 'EXPENSES_REPLACEMENT_RESERVES',
    ExpensesTenantImprovementsLeasingReserves = 'EXPENSES_TENANT_IMPROVEMENTS_LEASING_RESERVES',
    RevenueCommRentalInc = 'REVENUE_COMM_RENTAL_INC',
    RevenueCommConcessionsInc = 'REVENUE_COMM_CONCESSIONS_INC',
    RevenueCommVacancyInc = 'REVENUE_COMM_VACANCY_INC',
    RevenueCommRetaxReimb = 'REVENUE_COMM_RETAX_REIMB',
    RevenueCommUtilReimb = 'REVENUE_COMM_UTIL_REIMB',
    RevenueCommCamReimb = 'REVENUE_COMM_CAM_REIMB',
    RevenueCommOtherReimb = 'REVENUE_COMM_OTHER_REIMB',
    RevenueCommConcessionsReimb = 'REVENUE_COMM_CONCESSIONS_REIMB',
    RevenueCommOtherEgi = 'REVENUE_COMM_OTHER_EGI',
    RevenueMarketGrossPotentialGpr = 'REVENUE_MARKET_GROSS_POTENTIAL_GPR',
    RevenueLossToLeaseGpr = 'REVENUE_LOSS_TO_LEASE_GPR',
    RevenueCoopMaintFeeOther = 'REVENUE_COOP_MAINT_FEE_OTHER',
    RevenueUtilityReimbursementOther = 'REVENUE_UTILITY_REIMBURSEMENT_OTHER',
    RevenueLaundryOther = 'REVENUE_LAUNDRY_OTHER',
    RevenueParkingOther = 'REVENUE_PARKING_OTHER',
    RevenueCableOther = 'REVENUE_CABLE_OTHER',
    RevenueShortTermPremiumsOther = 'REVENUE_SHORT_TERM_PREMIUMS_OTHER',
    RevenueCorporatePremiumsOther = 'REVENUE_CORPORATE_PREMIUMS_OTHER',
    RevenueMiscellaneousOther = 'REVENUE_MISCELLANEOUS_OTHER',
    ExpensesTrashRemovalUtilitiesFixed = 'EXPENSES_TRASH_REMOVAL_UTILITIES_FIXED',
    ExpensesContractServicesRm = 'EXPENSES_CONTRACT_SERVICES_RM',
    ExpensesLeasingAgentSalaryTp = 'EXPENSES_LEASING_AGENT_SALARY_TP',
    ExpensesMaintJanitorSalaryTp = 'EXPENSES_MAINT_JANITOR_SALARY_TP',
    ExpensesEmpAllowanceTp = 'EXPENSES_EMP_ALLOWANCE_TP',
    ExpensesPayrollProcessingTp = 'EXPENSES_PAYROLL_PROCESSING_TP',
    ExpensesTelephoneAdmin = 'EXPENSES_TELEPHONE_ADMIN',
    ExpensesProfessionalFeesAdmin = 'EXPENSES_PROFESSIONAL_FEES_ADMIN',
    ExpensesGroundLeaseOther = 'EXPENSES_GROUND_LEASE_OTHER',
    ExpensesGeneralUtilitiesFixed = 'EXPENSES_GENERAL_UTILITIES_FIXED',
    ExpensesCamMaintenance = 'EXPENSES_CAM_MAINTENANCE',
    ExpensesWaterSewer = 'EXPENSES_WATER_SEWER',
    RevenuePrivatePay = 'REVENUE_PRIVATE_PAY',
    RevenueMedicare = 'REVENUE_MEDICARE',
    RevenueNursing = 'REVENUE_NURSING',
    RevenueMeals = 'REVENUE_MEALS',
    RevenueSeniorOther = 'REVENUE_SENIOR_OTHER',
    RevenueDepMeals = 'REVENUE_DEP_MEALS',
    RevenueDepTelephone = 'REVENUE_DEP_TELEPHONE',
    RevenueDepOther = 'REVENUE_DEP_OTHER',
    ExpensesMeals = 'EXPENSES_MEALS',
    ExpensesDepRoom = 'EXPENSES_DEP_ROOM',
    ExpensesDepMeals = 'EXPENSES_DEP_MEALS',
    ExpensesDepTelephone = 'EXPENSES_DEP_TELEPHONE',
    ExpensesDepOther = 'EXPENSES_DEP_OTHER',
    ExpensesFranchiseFee = 'EXPENSES_FRANCHISE_FEE',
    RevenueMedicaid = 'REVENUE_MEDICAID',
    RevenueEntranceFees = 'REVENUE_ENTRANCE_FEES',
    RevenueAssistedLiving = 'REVENUE_ASSISTED_LIVING',
    RevenueAlzCare = 'REVENUE_ALZ_CARE',
    RevenueBeautySalon = 'REVENUE_BEAUTY_SALON',
    RevenuePhysicalTherapy = 'REVENUE_PHYSICAL_THERAPY',
    RevenueRespite = 'REVENUE_RESPITE',
    ExpensesAssistedLiving = 'EXPENSES_ASSISTED_LIVING',
    ExpensesTransportation = 'EXPENSES_TRANSPORTATION',
    ExpensesTherapy = 'EXPENSES_THERAPY',
    ExpensesHvac = 'EXPENSES_HVAC',
    ExpensesTurnover = 'EXPENSES_TURNOVER',
    ExpensesLandscaping = 'EXPENSES_LANDSCAPING',
    ExpensesSecurity = 'EXPENSES_SECURITY',
    ExpensesFfeReserve = 'EXPENSES_FFE_RESERVE',
    ExpensesMakeReady = 'EXPENSES_MAKE_READY',
    RevenueNonRental1 = 'REVENUE_NON_RENTAL1',
    RevenueNonRental2 = 'REVENUE_NON_RENTAL2',
    RevenueNonRental3 = 'REVENUE_NON_RENTAL3',
    RevenueCommInsReimb = 'REVENUE_COMM_INS_REIMB',
    RevenueCommCleanReimb = 'REVENUE_COMM_CLEAN_REIMB',
    RevenueCommAdminReimb = 'REVENUE_COMM_ADMIN_REIMB',
    ExpensesElectricityUtil = 'EXPENSES_ELECTRICITY_UTIL',
    ExpensesFuelUtil = 'EXPENSES_FUEL_UTIL',
    ExpensesGasUtil = 'EXPENSES_GAS_UTIL',
    ExpensesOtherUtil = 'EXPENSES_OTHER_UTIL',
    ExpensesWaterSewerUtil = 'EXPENSES_WATER_SEWER_UTIL',
    ExpensesTrashRemovalUtil = 'EXPENSES_TRASH_REMOVAL_UTIL',
    ExpensesOtherMaintenanceRm = 'EXPENSES_OTHER_MAINTENANCE_RM',
    ExpensesPayrollOtherTp = 'EXPENSES_PAYROLL_OTHER_TP',
    ExpensesManagementAdmin = 'EXPENSES_MANAGEMENT_ADMIN',
    ExpensesManagement1Admin = 'EXPENSES_MANAGEMENT1_ADMIN',
    ExpensesManagement2Admin = 'EXPENSES_MANAGEMENT2_ADMIN',
    ExpensesEmployeeUnitTp = 'EXPENSES_EMPLOYEE_UNIT_TP',
    ExpensesOtherTaxes = 'EXPENSES_OTHER_TAXES',
    RevenueDepOther2 = 'REVENUE_DEP_OTHER2',
    RevenueDepOther3 = 'REVENUE_DEP_OTHER3',
    ExpensesDepOther2 = 'EXPENSES_DEP_OTHER2',
    ExpensesDepOther3 = 'EXPENSES_DEP_OTHER3',
    ExpensesIncentiveMgmtFee = 'EXPENSES_INCENTIVE_MGMT_FEE',
    ExpensesEliminatedSub = 'EXPENSES_ELIMINATED_SUB',
    ExpensesDebt = 'EXPENSES_DEBT',
    RevenueParkingOpenEgi = 'REVENUE_PARKING_OPEN_EGI',
    RevenueParkingCoveredEgi = 'REVENUE_PARKING_COVERED_EGI',
    ExpensesWaterUtilitiesFixed = 'EXPENSES_WATER_UTILITIES_FIXED',
    ExpensesSewerUtilitiesFixed = 'EXPENSES_SEWER_UTILITIES_FIXED',
    ExpensesExtriminatingUtilitiesFixed = 'EXPENSES_EXTRIMINATING_UTILITIES_FIXED',
    ExpensesPersonalPropertyTaxesFixed = 'EXPENSES_PERSONAL_PROPERTY_TAXES_FIXED',
    ExpensesMiscellaneousTaxesFixed = 'EXPENSES_MISCELLANEOUS_TAXES_FIXED',
    RevenueRentAdjustments = 'REVENUE_RENT_ADJUSTMENTS',
    RevenueRentAdjustmentsComm = 'REVENUE_RENT_ADJUSTMENTS_COMM',
    ExpensesSalaries = 'EXPENSES_SALARIES',
    ExpensesMaintenanceGround = 'EXPENSES_MAINTENANCE_GROUND',
    OtherCapitalNcf = 'OTHER_CAPITAL_NCF',
    OtherReplacementReservesNcf = 'OTHER_REPLACEMENT_RESERVES_NCF',
    OtherDebt = 'OTHER_DEBT',
    OtherDebt2 = 'OTHER_DEBT2',
    OtherDebtSub = 'OTHER_DEBT_SUB',
    OtherDebtAmort = 'OTHER_DEBT_AMORT',
    OtherDebtAmort2 = 'OTHER_DEBT_AMORT2',
    OtherDebtAmortSub = 'OTHER_DEBT_AMORT_SUB',
    OtherLeasingNcf = 'OTHER_LEASING_NCF',
    OtherTenantImprovementsNcf = 'OTHER_TENANT_IMPROVEMENTS_NCF',
    OtherReservesNcf = 'OTHER_RESERVES_NCF',
    OtherExtraCapitalNcf = 'OTHER_EXTRA_CAPITAL_NCF',
    OtherEliminatedNcf = 'OTHER_ELIMINATED_NCF',
    OtherNcf = 'OTHER_NCF',
    OtherExpensesEliminatedBelowNoi = 'OTHER_EXPENSES_ELIMINATED_BELOW_NOI',
    AddedExpenses = 'ADDED_EXPENSES',
    AddedRevenue = 'ADDED_REVENUE',
    ExpensesTotal = 'EXPENSES_TOTAL',
    RevenueTotal = 'REVENUE_TOTAL',
    Noi = 'NOI',
    NoiMargin = 'NOI_MARGIN',
    ExpensesAquisition = 'EXPENSES_AQUISITION',
    ExpensesAquisitionOther = 'EXPENSES_AQUISITION_OTHER',
    ExpensesHardConstructionMaterials = 'EXPENSES_HARD_CONSTRUCTION_MATERIALS',
    ExpensesHardConstructionLabor = 'EXPENSES_HARD_CONSTRUCTION_LABOR',
    ExpensesHardConstructionOverheads = 'EXPENSES_HARD_CONSTRUCTION_OVERHEADS',
    ExpensesHardLandscapingCosts = 'EXPENSES_HARD_LANDSCAPING_COSTS',
    ExpensesHardSiteImprovements = 'EXPENSES_HARD_SITE_IMPROVEMENTS',
    ExpensesHardUtilityInstallation = 'EXPENSES_HARD_UTILITY_INSTALLATION',
    ExpensesHardInteriorFinishes = 'EXPENSES_HARD_INTERIOR_FINISHES',
    ExpensesHardHvac = 'EXPENSES_HARD_HVAC',
    ExpensesHardLifeSafetySystems = 'EXPENSES_HARD_LIFE_SAFETY_SYSTEMS',
    ExpensesHardFurniture = 'EXPENSES_HARD_FURNITURE',
    ExpensesHardBuildingEquipment = 'EXPENSES_HARD_BUILDING_EQUIPMENT',
    ExpensesHardOther = 'EXPENSES_HARD_OTHER',
    ExpensesSoftArchitecturalPlanning = 'EXPENSES_SOFT_ARCHITECTURAL_PLANNING',
    ExpensesSoftEnineeringCosts = 'EXPENSES_SOFT_ENINEERING_COSTS',
    ExpensesSoftLegalFees = 'EXPENSES_SOFT_LEGAL_FEES',
    ExpensesSoftPermits = 'EXPENSES_SOFT_PERMITS',
    ExpensesSoftTaxes = 'EXPENSES_SOFT_TAXES',
    ExpensesSoftInsurance = 'EXPENSES_SOFT_INSURANCE',
    ExpensesSoftMarketing = 'EXPENSES_SOFT_MARKETING',
    ExpensesSoftRealEstateFees = 'EXPENSES_SOFT_REAL_ESTATE_FEES',
    ExpensesSoftPostConstruction = 'EXPENSES_SOFT_POST_CONSTRUCTION',
    ExpensesSoftOther = 'EXPENSES_SOFT_OTHER',
    ExpensesFinancingLoanFees = 'EXPENSES_FINANCING_LOAN_FEES',
    ExpensesFinancingOther = 'EXPENSES_FINANCING_OTHER',
    ExpensesContingencyDesign = 'EXPENSES_CONTINGENCY_DESIGN',
    ExpensesContingencyContractor = 'EXPENSES_CONTINGENCY_CONTRACTOR',
    ExpensesContingencyOwner = 'EXPENSES_CONTINGENCY_OWNER',
    ExpensesContingencyOther = 'EXPENSES_CONTINGENCY_OTHER',
    SourcesBorrowerEquity = 'SOURCES_BORROWER_EQUITY',
    SourcesLoan = 'SOURCES_LOAN',
    SourcesReserves = 'SOURCES_RESERVES',
    RevenueStart = 'REVENUE_START',
    ExpensesStart = 'EXPENSES_START',
    OtherStart = 'OTHER_START',
    Other = 'OTHER',
    Totals = 'TOTALS',
    Ignore = 'IGNORE',
}
export const {
    useGetNewLoanProgramListQuery,
    useGetLoanByIdQuery,
    useUpdateLoanDataMutation,
    useUpdateLoanCovenantsMutation,
    useSaveLoanCovenantsMutation,
    useDeleteLoanCovenantsMutation,
    useGetLoanDrawQuery,
    useCreateLoanDrawMutation,
    useGetPreDevelopmentDrawDocumentsQuery,
    useGetPreDevelopmentDrawFundingQuery,
    useUpdatePreDevelopmentDrawFundingMutation,
    useGetDrawSummaryQuery,
    useGetDrawSummaryDetailsQuery,
    useUpdateDocumentDrawMutation,
    useAddEntryToListMutation,
    useAddNewLoanProgramMutation,
    useDeleteLoanStatusEventMutation,
    useGetPreDevelopmentDrawQuery,
    useUpdatePreDevelopmentDrawMutation,
    useDeletePreDevelopmentDrawMutation,
    useGetDebtServiceQuery,
    useGetLoanStatusEventsQuery,
    useUpdateLoanStatusEventMutation,
    useAddLoanStatusEventMutation,
    useUpdateHistoricalLoanDataMutation,
} = injectedRtkApi;
