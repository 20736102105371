import AerealIcon from './images/Aereal.svg';
import JLLIcon from './images/JLL.svg';
import KeyBankIcon from './images/KeyBank.svg';
import PacificIcon from './images/Pacific.svg';
import SlattIcon from './images/Slatt.svg';
import videoSrc from './video/Buildings.mp4';

export const HERO_DATA = {
    title: 'Digitize, Automate and Bring Insight to any Real Estate Transaction',
    description:
        'Modern property underwriting, origination and asset management platform that eliminates data entry and leverages data insight at scale',
    buttonLabel: 'Request Demo',
    videoSrc,
    partners: [
        {
            viewBox: '0 0 150 27',
            component: PacificIcon,
        },
        {
            viewBox: '0 0 66 30',
            component: JLLIcon,
        },
        {
            viewBox: '0 0 97 44',
            component: SlattIcon,
        },
        {
            viewBox: '0 0 90 20',
            component: AerealIcon,
        },
        {
            viewBox: '0 0 102 16',
            component: KeyBankIcon,
        },
    ],
};
