import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery, createConfig } from '@scc/services/helpers';

const ENTITY = 'document';

export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});

export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: [
        'downloadDocument',
        'findDocumentsForCurrentUser',
        'getStatementTemplates',
        'updateDocument',
        'updateDocumentProperty',
        'removeDocumentFromDraw',
        'getDocumentHistory',
        'uploadAsNewDocument',
    ],
});
