import * as yup from 'yup';

const schema = yup
    .object({
        email: yup.string('1').required('Email is required'),
        password: yup.string('1').required('Password is required'),
    })
    .required();

export default schema;
