import { createSlice, createSelector } from '@reduxjs/toolkit';
import { put, select, take, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { selectTodoCheckLists, setModeTodoCheckLists } from '@scc/store/ducks/loans/todosCheckLists';

import { checklistApi } from '@api';
import { MODES } from '@modules/TodoChecklists/helpers/constants';
import { API_ERROR, API_REQUEST, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[loans/todosCheckList]';

const initialState = {
    data: {},
    template: {
        name: 'New Checklist',
        description: null,
        sections: [
            {
                id: uuid(),
                name: 'New Section',
                todoTemplates: [],
            },
        ],
    },
    loading: false,
};

const todosCheckListSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load() {},
        loadTemplate() {},
        set(state, action) {
            state.data = action.payload;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
        create() {},
        update() {},
        delete() {},
        resetState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith(API_REQUEST),
                (state) => {
                    state.loading = true;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith(API_SUCCESS) || action.type.endsWith(API_ERROR),
                (state) => {
                    state.loading = false;
                }
            );
    },
});

export const {
    load: loadTodosCheckList,
    set: setTodoCheckList,
    loadTemplate: loadTemplateTodoCheckList,
    setTemplate: setTemplateTodoCheckList,
    create: createTodoCheckList,
    update: updateTodoCheckList,
    delete: deleteTodoCheckList,
    resetState: resetTodosCheckList,
} = todosCheckListSlice.actions;

export const selectTodoCheckList = (state) => state.loans.todosCheckList;

export const selectCheckListConnectedTemplates = createSelector(selectTodoCheckList, ({ template }) => {
    return template?.sections?.reduce((acc, section) => {
        acc.push(...(section.todoTemplates || []));

        return acc;
    }, []);
});

export default todosCheckListSlice.reducer;

function* loadTodosCheckListSaga({ type, payload }) {
    const { teamType } = payload;
    const { loanId, selectedListId } = yield select(selectTodoCheckLists);
    yield put(apiRequest({ id: selectedListId, loanId, teamType }, checklistApi.populateCheckList, type));
    const { payload: { data } = {} } = yield take(`${type} ${API_SUCCESS}`);
    yield put(setTodoCheckList(data));
}

function* loadTemplateTodosCheckListSaga({ type }) {
    const { selectedListId } = yield select(selectTodoCheckLists);
    yield put(apiRequest(selectedListId, checklistApi.getCheckList, type));
    const { payload: { data } = {} } = yield take(`${type} ${API_SUCCESS}`);
    yield put(setTemplateTodoCheckList(data));
}

function* createTodoCheckListSaga({ type }) {
    const { template } = yield select(selectTodoCheckList);

    yield put(apiRequest(template, checklistApi.createCheckList, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(setModeTodoCheckLists(MODES.CHECKLISTS));
}

function* updateTodoCheckListSaga({ type }) {
    const { template } = yield select(selectTodoCheckList);

    yield put(apiRequest(template, checklistApi.updateCheckList, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(setModeTodoCheckLists(MODES.CHECKLISTS));
}

function* deleteTodoCheckListSaga({ type }) {
    const { selectedListId } = yield select(selectTodoCheckLists);
    yield put(apiRequest(selectedListId, checklistApi.deleteCheckList, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(setModeTodoCheckLists(MODES.CHECKLISTS));
}

export function* watchTodosCheckList() {
    yield takeLatest(loadTodosCheckList, loadTodosCheckListSaga);
    yield takeLatest(loadTemplateTodoCheckList, loadTemplateTodosCheckListSaga);
    yield takeLatest(createTodoCheckList, createTodoCheckListSaga);
    yield takeLatest(updateTodoCheckList, updateTodoCheckListSaga);
    yield takeLatest(deleteTodoCheckList, deleteTodoCheckListSaga);
}
