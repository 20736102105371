import { formatter } from '@utils/index';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function Base({ data, title }) {
    const summarySqft = data.reduce((acc, { sqft }) => acc + sqft, 0);
    return (
        <div className={styles.tooltipTemplate}>
            <div className={styles.title}>
                <Typography className={styles.tenantName} color={colors['grey-200']} variant='smr'>
                    {title}
                </Typography>
                <div className={styles.summary}>
                    <Typography color={colors['grey-200']} variant='smsb'>
                        {formatter.number(summarySqft)}
                    </Typography>
                    <Typography color={colors['grey-200']} variant='smr'>
                        SF
                    </Typography>
                </div>
            </div>
            {data.map(({ propertyName, propertyAddress, sqft }) => {
                const shortcut = `${propertyAddress.split(', ')[0]}`;
                return (
                    <div key={propertyAddress} className={styles.row}>
                        <div className={styles.left}>
                            <Typography color={colors.white} variant='smsb'>
                                {propertyName || shortcut}
                            </Typography>
                            <Typography className={styles.address} color={colors['grey-300']} variant='smr'>
                                {propertyAddress}
                            </Typography>
                        </div>
                        <div className={styles.right}>
                            <Typography color={colors.white} component='span' variant='smr'>
                                {formatter.number(sqft)}
                            </Typography>
                            <Typography color={colors['grey-300']} component='span' variant='smr'>
                                SF
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Base;
