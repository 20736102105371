import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { selectAuthMngState, setTemplateAuthMng } from '@scc/store/ducks/authManager';
import { selectAuthState, registerAuth } from '@scc/store/ducks/auth';

import DesktopLayout from './components/DesktopLayout';
import MobileLayout from './components/MobileLayout';

const AuthPage = function ({ name, dialogProps }) {
    const dispatch = useDispatch();
    const { template } = useSelector(selectAuthMngState);
    const { loading, error } = useSelector(selectAuthState);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(setTemplateAuthMng({ name }));
    }, [name]);

    const handleAuth = (data) => {
        dispatch(registerAuth(data));
    };

    return isMobile ? (
        <MobileLayout onSuccess={handleAuth} loading={loading} />
    ) : (
        <DesktopLayout
            template={template}
            dialogProps={dialogProps}
            onSuccess={handleAuth}
            loading={loading}
            errorStatus={error?.status}
        />
    );
};

export default AuthPage;
