import { createSelector, createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest, select, delay } from 'redux-saga/effects';

import AnalyticsCharts from '@utils/charts/classes/AnalyticsCharts';
import { isMFLike } from '@utils/properties';
import { calcTransactionsAverages } from '@utils/valuation';

import {
    maxAfterComma,
    prepareTransactions,
} from '@scc/pages/Portfolio/components/Underwriting/components/Valuation/helpers/helpers';
import { setMarketData, selectMarketOverview } from '@scc/store/ducks/detailedProperty/overview';
import { selectSimilarTransactionsState } from '@scc/store/ducks/similarTransactions';

import { underwritingApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[underwritingAnalytics]';
const charts = new AnalyticsCharts();

const initialState = {
    data: charts,
    loaded: false,
    loading: false,
};

const analyticsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        [`load ${API_ERROR}`](state) {
            state.loaded = false;
            state.loading = false;
        },
    },
});

export const { load: loadAnalytics, setData: setAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const selectAnalyticsState = (store) => store.underwriting.analytics;

export const selectCustomScenariosFinancialsState = createSelector(
    selectAnalyticsState,
    ({ data: { analytics: { financials: { customScenarios } = {} } = {} } = {} }) =>
        customScenarios ? Object.keys(customScenarios).map((value) => ({ label: value, value })) : []
);

function* loadAnalyticsSaga({ type, payload: { countyId, parcelId, userId } }) {
    if (!countyId || !parcelId) return;
    yield delay(100);

    const { loaded } = yield select(selectMarketOverview);
    if (!loaded) {
        yield take(setMarketData);
    }
    const {
        data: {
            similarTransactionsResponse = {},
            size,
            sqft,
            units = sqft,
            overview: { propertyType },
        },
    } = yield select(selectMarketOverview);

    yield put(apiRequest({ countyId, parcelId, userId }, underwritingApi.getPropertyFinancials, type));
    const {
        payload: { data },
    } = yield take(`${type} ${API_SUCCESS}`);

    const { excludedTransactions } = yield select(selectSimilarTransactionsState);

    const isMf = isMFLike(propertyType);

    const filteredSimilarTransactions = prepareTransactions(similarTransactionsResponse.zoomedValues, isMf)?.filter(
        (val) => !excludedTransactions[val.id]
    );

    const averageSalesComps = similarTransactionsResponse.zoomedValues
        ? calcTransactionsAverages(filteredSimilarTransactions)
        : 0;
    const basedOnSqft = Math.floor(averageSalesComps?.sfPrice) * size || 0;
    const basedOnUnits = isMf ? Math.floor(averageSalesComps?.unitPrice) * units || 0 : 0;

    let [lower, higher] = [basedOnSqft, basedOnUnits].sort((a, b) => a - b);
    if (lower === 0) {
        lower = higher * 0.9;
        higher *= 1.1;
    }
    const value = {
        from: maxAfterComma(lower, 0),
        to: maxAfterComma(higher, 0),
    };

    const dataWithSalesComps = {
        ...data,
        underwritingValuation: {
            ...data?.underwritingValuation,
            salesComps: maxAfterComma((value.from + value.to) / 2, 0),
        },
    };
    charts.addData(dataWithSalesComps);
    yield put(setAnalytics(charts));
}

export function* watchAnalytics() {
    yield takeLatest(loadAnalytics, loadAnalyticsSaga);
}
