import { createSlice } from '@reduxjs/toolkit';
import { all, call, put, delay, takeLatest } from 'redux-saga/effects';

import { loadAllDocuments } from '@scc/store/ducks/documents';

import { documentsApi } from '@api';
import { UPLOAD_DOCUMENTS_STEPS } from '@constants/modals';
import { uploadDocumentsForm } from '@shared/templates/forms/UploadDocuments';
import { hideDrawer, showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

const { MAIN, PREPARE, LOADING, SUCCESS, ERROR } = uploadDocumentsForm.TEMPLATES;

const entity = '[sh:flowDocuments]';

const initialState = {
    error: null,
    event: {},
    template: MAIN,
};

const documentsFLowSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        uploadSteps(state, { payload }) {
            state.template = payload.template || MAIN;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
        uploadDocuments() {},
    },
});

export const {
    uploadSteps: uploadDocumentsSteps,
    setTemplate: setTemplateLoanFlow,
    uploadDocuments: updateDocumentsFlow,
} = documentsFLowSlice.actions;

export default documentsFLowSlice.reducer;

export const selectDocumentsFlowState = (store) => store.flow.documents;

function* uploadDocumentsStepsSaga({ payload }) {
    yield put(
        showPrimaryAsideDrawer({
            content: UPLOAD_DOCUMENTS_STEPS,
            data: payload,
        })
    );
}

function* uploadDocumentsFlowSaga({ payload }) {
    const { prepareDocs, search, editMode } = payload;
    yield put(setTemplateLoanFlow(LOADING));

    const method = editMode ? documentsApi.updateDocument : documentsApi.uploadDocument;

    const responses = yield all(
        Object.values(prepareDocs).map(({ id, name, start, end, type, date, file }) =>
            call(method, {
                id,
                name,
                type,
                date,
                pages: `${start}-${end}`,
                file,
            })
        )
    );

    if (responses.some((res) => res.error)) {
        yield put(setTemplateLoanFlow(ERROR));
        yield delay(2300);
        yield put(setTemplateLoanFlow(PREPARE));
    } else {
        yield put(loadAllDocuments({ search }));
        yield put(setTemplateLoanFlow(SUCCESS));
        yield delay(3000);
        yield put(hideDrawer());
    }
}

export function* watchDocumentsFLow() {
    yield takeLatest(uploadDocumentsSteps, uploadDocumentsStepsSaga);
    yield takeLatest(updateDocumentsFlow, uploadDocumentsFlowSaga);
}
