import { parseMemberResponseObject } from '@utils/store/middleware';

import { PopulatedTodoDto, Repeater, TimeLineEvents, TodoTemplate } from '@scc/services/generated/checklist';
import { TodoTaskDto } from '@scc/services/generated/todo';

const initialState = {
    documents: [],
    properties: [],
    entities: [],
    lead: {},
    labels: [],
    reminder: [],
    connectedTasks: [],
};

export const todoItemTodoListAdapter = (todoItem: TodoTaskDto) => {
    const {
        document,
        properties,
        propertyId,
        contact,
        assigneeFirstName,
        assigneeLastName,
        assigneeEmail,
        dueBy,
        docSyncTask,
        timelineEvents,
    } = todoItem || {};
    const { repeater, reminder = [] } = timelineEvents || {};

    return {
        ...initialState,
        ...todoItem,
        repeater,
        reminder,
        documents: document ? [document] : [],
        properties: [properties?.find((item) => item.id === propertyId)].filter(Boolean),
        entities: contact ? [contact && parseMemberResponseObject(contact)] : [],
        lead: { userInfo: { firstname: assigneeFirstName, lastname: assigneeLastName, email: assigneeEmail } },
        dueDate: dueBy,
        documentSyncTask: docSyncTask,
    };
};

export const todoItemCreateAdapter = (formData) => {
    const { lead, properties, documents, entities, reminder, repeater, connectedTasks, dueDate, documentSyncTask } =
        formData || {};

    return {
        ...formData,
        assigneeEmail: lead?.userInfo?.email,
        propertyReferences: properties?.map(({ id }) => id),
        documentIds: documents?.map(({ id }) => id),
        contacts: entities?.map(({ id }) => id),
        connectedTasks: connectedTasks?.map(({ id }) => id),
        timelineEvents: {
            reminder,
            repeater,
        },
        dueBy: dueDate,
        documentSyncTask: documentSyncTask?.id,
    };
};

export const todoItemUpdateAdapter = (formData) => {
    const { lead, properties, documents, entities, reminder, repeater, connectedTasks, dueDate, documentSyncTask } =
        formData || {};

    return {
        ...formData,
        assigneeEmail: lead?.userInfo?.email,
        propertyReference: properties?.[0]?.id,
        documentId: documents?.[0]?.id,
        contact: entities?.[0]?.id,
        timelineEvents: {
            reminder,
            repeater,
        },
        connectedTasks: connectedTasks?.map(({ id }) => id),
        dueBy: dueDate,
        documentSyncTask: documentSyncTask?.id,
    };
};

export const todoItemChecklistViewAdapter = (todoItem: PopulatedTodoDto): Record<string, unknown> => {
    const { taskType, leadUserInfo, ...rest } = todoItem;

    return {
        ...initialState,
        ...rest,
        lead: { userInfo: leadUserInfo },
        type: taskType,
        repeater: todoItem.timelineEvents?.repeater,
        reminder: todoItem.timelineEvents?.reminder,
    };
};

export const todoItemChecklistViewUpdateAdapter = (formData) => {
    const {
        lead: { userInfo: leadUserInfo },
        type: taskType,
    } = formData;

    return {
        ...formData,
        leadUserInfo,
        taskType,
    };
};

export const todoItemChecklistCreateAdapter = (formData: Record<string, unknown>): TodoTemplate => {
    return {
        ...formData,
        timelineEvents: {
            repeater: formData.repeater as Repeater,
            reminder: formData.reminder as TimeLineEvents['reminder'],
        },
    };
};

export const todoItemResponseChecklistCreateAdapter = (todoItem: TodoTemplate): Record<string, unknown> => {
    return {
        ...todoItem,
        repeater: todoItem.timelineEvents?.repeater,
        reminder: todoItem.timelineEvents?.reminder,
    };
};
