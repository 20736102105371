import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import links from '@scc/router/links';
import { logoutAuth, selectIsUserAuth, selectUserInfo, selectUserSso } from '@scc/store/ducks/auth';

import { getUserPing } from '@shared/services/api/user';

import { useAppSelector, useAppDispatch } from '../../scc/hooks/useTypeScript';

const SESSION_TIMEOUT = 5 * 60 * 1000;

export const useSessionKeepAlive = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsUserAuth);
    const currentUserInfo = useAppSelector(selectUserInfo);
    const { ssoLogin, ssoLink } = useAppSelector(selectUserSso) as { ssoLogin: boolean; ssoLink?: string | null };

    const sessionKeepAlive = async () => {
        const res = await getUserPing();
        if (isLoggedIn && res?.error && currentUserInfo && Object.keys(currentUserInfo).length > 0) {
            if (ssoLogin && ssoLink) {
                window.localStorage.setItem('ssoLink', ssoLink);

                history.push(`${links.ssoTimeout.path}`);
            } else {
                dispatch(logoutAuth());

                const search = new URLSearchParams({ isTimeout: 'true' }).toString();
                history.push(`${links.login.path}?${search}`, {
                    from: {
                        pathname: location.pathname,
                        search: location.search,
                        hash: location.hash,
                    },
                });
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttled = useCallback(throttle(sessionKeepAlive, SESSION_TIMEOUT, { trailing: false, leading: true }), []);

    useEffect(() => {
        document.addEventListener('mousemove', throttled);
        document.addEventListener('keydown', throttled);

        return () => {
            document.removeEventListener('mousemove', throttled);
            document.removeEventListener('keydown', throttled);
        };
    }, []);
};
