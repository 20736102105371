/* eslint-disable no-template-curly-in-string */
import InfoIcon from '@scc/assets/icons/info.svg';

import Button from '@ui/Button';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';
import Stack from '@ui/Stack';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import TreeIcon from '@icons/outlined/tree.svg';

import styles from './styles.module.scss';

export default function Valuation({
    isServer,
    isShowValuations,
    isValuationLoaded,
    valuation,
    valueInfo,
    handleChangeTab,
    getProperty,
    step,
}) {
    return (
        <div className={styles.wrapper}>
            {isServer ? (
                <div className={styles.valueContainer}>
                    <div className={styles.valueWrapper}>
                        <Typography className={styles.value} component='span' variant='head2b'>
                            {'<c:choose><c:when test="${not empty valueFrom}">'}
                            {'${valueFrom}'}
                            {'</c:when><c:otherwise>'}
                            <Skeleton width={50} />
                            {'</c:otherwise></c:choose>'}
                        </Typography>
                    </div>
                    <div className={styles.valueWrapper}>
                        <Typography className={styles.value} component='span' variant='head2b'>
                            {'<c:choose><c:when test="${not empty valueTo}">'}
                            {'${valueTo}'}
                            {'</c:when><c:otherwise>'}
                            <Skeleton width={50} />
                            {'</c:otherwise></c:choose>'}
                        </Typography>
                    </div>
                </div>
            ) : (
                <div className={styles.valueContainer}>
                    {isShowValuations ? (
                        <>
                            <div className={styles.valueWrapper}>
                                <Typography className={styles.value} component='span' variant='head2b'>
                                    {isValuationLoaded ? valuation?.[0] || 0 : <Skeleton width={50} />}
                                </Typography>
                            </div>
                            <div className={styles.valueWrapper}>
                                <Typography className={styles.value} component='span' variant='head2b'>
                                    {isValuationLoaded ? valuation?.[1] || 0 : <Skeleton width={50} />}
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <Stack alignItems='center' direction='row' spacing={4}>
                            <Icon color={colors['grey-400']} component={TreeIcon} fontSize={24} />
                            <Stack alignItems='center' direction='column' spacing={4}>
                                <Typography color={colors['grey-400']} variant='h5r'>
                                    There are no sales comps in this area. <br />
                                </Typography>
                                <Button.Link
                                    className={styles.requestButton}
                                    onClick={() => getProperty('valuation', step)}
                                >
                                    Please Request Income-Based Valuation
                                </Button.Link>
                            </Stack>
                        </Stack>
                    )}
                </div>
            )}
            {isShowValuations ? (
                <Grid className={styles.description} xs={12} item>
                    <Typography color={colors['grey-400']} variant='mdr'>
                        {valueInfo?.description}
                        <Button.Link
                            className={styles.valuationLink}
                            color='inherit'
                            onClick={(e) => handleChangeTab(e, valueInfo?.map)}
                        >
                            {valueInfo?.linkText}
                        </Button.Link>
                    </Typography>
                    <Tooltip title={valueInfo?.tooltip} arrow>
                        <span>
                            <InfoIcon />
                        </span>
                    </Tooltip>
                </Grid>
            ) : null}
        </div>
    );
}
