import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@scc/index';

const entity = '[grid/iframe]';

const initialState = {
    isInitiated: {},
    reconciliation: {},
};

const iframeSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setInitiatedGridIframe(state, action) {
            state.isInitiated[action.payload] = true;
        },
        setReconciliationGridIframe(state, action) {
            if (action.payload === null) {
                state.reconciliation = {};
                return;
            }

            state.reconciliation = {
                ...state.reconciliation,
                ...action.payload,
            };
        },
        unsetStateGridIframe(state, action) {
            state.isInitiated[action.payload] = false;
        },
    }
});

export const { setInitiatedGridIframe, setReconciliationGridIframe, unsetStateGridIframe } = iframeSlice.actions;

export const selectGridIframeState = (store: RootState) => store.grid.iframe;

export default iframeSlice.reducer;
