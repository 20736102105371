import request from '@utils/request';

const BASE_URL_V2 = '/api/v2/charge-code';

export async function saveCategorization({ rrId, chargeCodes }) {
    return request(`${BASE_URL_V2}/categorization`, {
        method: 'POST',
        body: JSON.stringify(chargeCodes),
        params: {
            rrId,
        },
    });
}
