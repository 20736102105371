import { generatedApi } from '@scc/services/generated/notes2';

export const NOTES2_TAGS = {
    NOTE: 'note',
};

const enhanceOptions = {
    addTagTypes: [NOTES2_TAGS.NOTE],
    endpoints: {
        getNotes: {
            providesTags: [NOTES2_TAGS.NOTE],
        },
        saveNotes: {
            invalidatesTags: [NOTES2_TAGS.NOTE],
        }
    }
} as const;

export const notes2Api = generatedApi.enhanceEndpoints(enhanceOptions);