// eslint-disable-next-line import/named
import { createExternalLink } from '@utils/browser';
import request, { getFullFilledParams } from '@utils/request';

const BASE_URL = '/geoGekko/pdf/print';
const BASE_PDF_PAGE_URL = '/pdf.html';

export async function getPdfExport({ path = BASE_PDF_PAGE_URL, ...params }) {
    const searchParams = getFullFilledParams(params);
    const url = createExternalLink(path, searchParams);

    const payload = {
        url: encodeURI(url),
        waitForSelector: '.loaded',
        waitTimeoutSeconds: 120,
        pdfOptions: {
            printBackground: true,
        },
    };

    return request(BASE_URL, {
        method: 'POST',
        responseType: 'blob',
        csrfSafe: params.view === 'puppeteer',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf,application/json',
        },
        body: JSON.stringify(payload),
    });
}

export async function getXlsExport(url, params = {}) {
    return request(url, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf,application/json',
        },
        ...params,
    });
}
