import MuiLinearProgress from '@mui/material/LinearProgress';

import { createBaseProxy } from '@utils/components';

const LinearProgress = createBaseProxy(MuiLinearProgress, 'LinearProgress');

LinearProgress.Determinate = createBaseProxy(MuiLinearProgress, 'LinearProgress', {
    variant: 'determinate',
});

export default LinearProgress;
