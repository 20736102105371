import { baseApi as api } from '../configs/denormalizedLoans';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        memberFacetsFor: build.query<MemberFacetsForApiResponse, MemberFacetsForApiArg>({
            query: (queryArg) => ({ url: `/api/v2/denormalized-loans/member/${queryArg.property}` }),
        }),
        portfolioFacets: build.query<PortfolioFacetsApiResponse, PortfolioFacetsApiArg>({
            query: () => ({ url: `/api/v2/denormalized-loans/portfolios` }),
        }),
        getUserLoansByStatus: build.query<GetUserLoansByStatusApiResponse, GetUserLoansByStatusApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/denormalized-loans/filter`,
                params: {
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                    detailsStatus: queryArg.detailsStatus,
                    loanProgram: queryArg.loanProgram,
                    documentStatus: queryArg.documentStatus,
                    search: queryArg.search,
                    loanModelAsOf: queryArg.loanModelAsOf,
                    lenderTeamLeadUserId: queryArg.lenderTeamLeadUserId,
                    loanRating: queryArg.loanRating,
                    propertyType: queryArg.propertyType,
                    lenderIds: queryArg.lenderIds,
                    portfolioIds: queryArg.portfolioIds,
                },
            }),
        }),
        getTotalsForCurrentUser: build.query<GetTotalsForCurrentUserApiResponse, GetTotalsForCurrentUserApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/denormalized-loans/totals`,
                params: {
                    detailsStatus: queryArg.detailsStatus,
                    loanProgram: queryArg.loanProgram,
                    documentStatus: queryArg.documentStatus,
                    search: queryArg.search,
                    loanModelAsOf: queryArg.loanModelAsOf,
                    lenderTeamLeadUserId: queryArg.lenderTeamLeadUserId,
                    loanRating: queryArg.loanRating,
                    propertyType: queryArg.propertyType,
                    lenderIds: queryArg.lenderIds,
                    portfolioIds: queryArg.portfolioIds,
                },
            }),
        }),
        teamLeadFacet: build.query<TeamLeadFacetApiResponse, TeamLeadFacetApiArg>({
            query: () => ({ url: `/api/v2/denormalized-loans/team-leads` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type MemberFacetsForApiResponse = /** status 200 OK */ GenericJsonResponseMapStringString;
export type MemberFacetsForApiArg = {
    property: string;
};
export type PortfolioFacetsApiResponse = /** status 200 OK */ GenericJsonResponseMapNumberString;
export type PortfolioFacetsApiArg = void;
export type GetUserLoansByStatusApiResponse = /** status 200 OK */ GenericJsonResponsePageDenormalizedLoan;
export type GetUserLoansByStatusApiArg = {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
    detailsStatus?: string[];
    loanProgram?: string[];
    documentStatus?: string[];
    search?: string;
    loanModelAsOf?: string;
    lenderTeamLeadUserId?: number[];
    loanRating?: string[];
    propertyType?: string[];
    lenderIds?: string[];
    portfolioIds?: number[];
};
export type GetTotalsForCurrentUserApiResponse = /** status 200 OK */ GenericJsonResponseMapStringDouble;
export type GetTotalsForCurrentUserApiArg = {
    detailsStatus?: string[];
    loanProgram?: string[];
    documentStatus?: string[];
    search?: string;
    loanModelAsOf?: string;
    lenderTeamLeadUserId?: number[];
    loanRating?: string[];
    propertyType?: string[];
    lenderIds?: string[];
    portfolioIds?: number[];
};
export type TeamLeadFacetApiResponse = /** status 200 OK */ GenericJsonResponseCollectionUserInfoDto;
export type TeamLeadFacetApiArg = void;
export type GenericJsonResponseMapStringString = {
    status?: string;
    data?: {
        [key: string]: string;
    };
    message?: string;
    code?: string;
};
export type GenericJsonResponseMapNumberString = {
    status?: string;
    data?: {
        [key: string]: string;
    };
    message?: string;
    code?: string;
};
export type Sort = {
    unsorted?: boolean;
    sorted?: boolean;
    empty?: boolean;
};
export type Pageable = {
    page?: number;
    size?: number;
    sort?: string[];
};
export type DenormalizedProperty = {
    countyId?: string;
    parcelId?: string;
    propertyId?: string;
    propertyName?: string;
    address?: string;
    propertyType?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    units?: number;
    size?: number;
    acres?: number;
};
export type DenormalizedLoanModel = {
    modelId?: number;
    modelName?: string;
    modelNotes?: string;
    asOfDate?: string;
    principal?: number;
    balance?: number;
    unfundedBalance?: number;
    repoPrincipal?: number;
    repoBalance?: number;
    vacancyUnits?: number;
    occupancyUnits?: number;
    vacancySqft?: number;
    occupancySqft?: number;
    assetValue?: number;
    ltv?: number;
    dscrNoi?: number;
    dscrNcf?: number;
    debtYield?: number;
    currentBalanceDY?: number;
    debtYield2?: number;
    currentBalanceDY2?: number;
    dscrSofr?: number;
    rent?: number;
    noi?: number;
    ncf?: number;
    repoDSCR?: number;
    vacancy?: number;
    occupancy?: number;
    coupon?: number;
    spread?: number;
    interestRateCap?: number;
    cashSweep?: string;
    loanRating?: string;
    cashReserve?: number;
    totalReserve?: number;
    interestCapRateExpiration?: number;
    indexValue?: number;
    indexFloor?: number;
    indexType?: string;
    adjustment?: number;
    default?: boolean;
};
export type DenormalizedLoanData = {
    repoLender?: string;
    indexType?: string;
    cashSweep?: string;
    loanRating?: string;
    newLoanProgram?: string;
    interestCapRateExpiration?: number;
    cashReserve?: number;
    totalReserves?: number;
    coupon?: number;
    spread?: number;
    interestRateCap?: number;
    indexFloor?: number;
    indexValue?: number;
    adjustment?: number;
    totalPrincipal?: number;
    totalBalance?: number;
    totalRepoPrincipal?: number;
    totalRepoBalance?: number;
};
export type DenormalizedTeamMemberDto = {
    teamId?: string;
    userId?: number;
    isLead?: boolean;
    teamMemberRole?: TeamMemberRole;
    email?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
};
export type DenormalizedMemberDto = {
    isUser?: boolean;
    createdAt?: string;
    memberId?: string;
    borrowerDefinitionString?: string;
};
export type DenormalizedLoanPortfolio = {
    id?: number;
    name?: string;
};
export type DenormalizedLoanDefaults = {
    loanPrincipal?: number;
    loanBalance?: number;
    repoPrincipal?: number;
    repoBalance?: number;
    unfundedBalance?: number;
    assetValue?: number;
    rent?: number;
    noi?: number;
    ncf?: number;
    occupancy?: number;
    vacancy?: number;
    ltv?: number;
    dscrNoi?: number;
    dscrNcf?: number;
    repoDSCR?: number;
    debtYield?: number;
    currentBalanceDY?: number;
    debtYield2?: number;
    currentBalanceDY2?: number;
    dscrSofr?: number;
    coupon?: number;
    spread?: number;
    interestRateCap?: number;
    cashSweep?: string;
    loanRating?: string;
    cashReserve?: number;
    totalReserve?: number;
    interestCapRateExpiration?: number;
    indexValue?: number;
    indexFloor?: number;
    indexType?: string;
    adjustment?: number;
};
export type DenormalizedLoan = {
    loanDetailsId?: number;
    loanId?: string;
    loanProgram?: string;
    loanType?: string;
    loanName?: string;
    propertyScore?: number;
    detailsStatus?: string;
    maturityDate?: string;
    approvedDate?: string;
    createdAt?: string;
    propertiesList?: DenormalizedProperty[];
    totalPropertyUnits?: number;
    totalPropertySize?: number;
    totalPropertyCount?: number;
    loanModels?: DenormalizedLoanModel[];
    loanData?: DenormalizedLoanData;
    totalFutureFunding?: number;
    lenderTeamLead?: DenormalizedTeamMemberDto;
    borrower?: DenormalizedMemberDto;
    lender?: DenormalizedMemberDto;
    guarantorDefinition?: DenormalizedMemberDto;
    documentStatuses?: (
        | 'UNDER_REVIEW'
        | 'ACCEPTED'
        | 'NOT_ACCEPTED'
        | 'REQUEST_INFO'
        | 'STANDARDIZED_NOT_REVIEWED'
        | 'REVIEWED_BY'
        | 'NOT_REVIEWED_BY'
        | 'QUESTIONS'
        | 'PROCESSING'
        | 'WAITING_STANDARDIZATION'
        | 'WAITING_PAGES'
        | 'APPROVED_BY'
        | 'PAID'
        | 'NOT_REVIEWED'
        | 'IN_REVIEW'
        | 'REVIEWED'
        | 'NON_APPROVED'
        | 'APPROVED'
        | 'REJECTED'
        | 'ERROR'
    )[];
    loanPortfolios?: DenormalizedLoanPortfolio[];
    defaults?: DenormalizedLoanDefaults;
};
export type PageDenormalizedLoan = {
    totalPages?: number;
    totalElements?: number;
    sort?: Sort;
    first?: boolean;
    pageable?: Pageable;
    number?: number;
    last?: boolean;
    numberOfElements?: number;
    size?: number;
    content?: DenormalizedLoan[];
    empty?: boolean;
};
export type GenericJsonResponsePageDenormalizedLoan = {
    status?: string;
    data?: PageDenormalizedLoan;
    message?: string;
    code?: string;
};
export type GenericJsonResponseMapStringDouble = {
    status?: string;
    data?: {
        [key: string]: number;
    };
    message?: string;
    code?: string;
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    userId?: number;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type GenericJsonResponseCollectionUserInfoDto = {
    status?: string;
    data?: UserInfoDto[];
    message?: string;
    code?: string;
};
export enum TeamMemberRole {
    Analyst = 'ANALYST',
    Manager = 'MANAGER',
    Supervisor = 'SUPERVISOR',
    Underwriter = 'UNDERWRITER',
    AssetManager = 'ASSET_MANAGER',
    Originator = 'ORIGINATOR',
    TeamLead = 'TEAM_LEAD',
    FinancialsContact = 'FINANCIALS_CONTACT',
    InspectionContact = 'INSPECTION_CONTACT',
    InsuranceContact = 'INSURANCE_CONTACT',
    NoticesContact = 'NOTICES_CONTACT',
    Borrower = 'BORROWER',
}
export const {
    useMemberFacetsForQuery,
    usePortfolioFacetsQuery,
    useGetUserLoansByStatusQuery,
    useGetTotalsForCurrentUserQuery,
    useTeamLeadFacetQuery,
} = injectedRtkApi;
