import Popover from '@ui/Popover';

const defaultProps = {
    anchorV: 'bottom',
    anchorH: 'left',
    options: {},
    target: {},
};

const BasePopover = function ({ anchorV, anchorH, isOpen, target, options, onClose, backdrop, children }) {
    return (
        <Popover
            {...options}
            id={target?.id}
            open={isOpen}
            anchorEl={target}
            onClose={onClose}
            anchorOrigin={{
                vertical: anchorV,
                horizontal: anchorH,
            }}
            BackdropProps={{ invisible: !backdrop }}
        >
            {children}
        </Popover>
    );
};

BasePopover.defaultProps = defaultProps;

export default BasePopover;
