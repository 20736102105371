import { FORM_CALL_CONTEXT, FORM_MODE } from './constants';

export const isFieldValid = (value) => {
    switch (typeof value) {
        case 'string':
            return value.length > 0;
        case 'number':
            return value > 0;
        case 'object':
            return !!value && Object.keys(value).length > 0;
        default:
            return false;
    }
};

export const getFormModeBooleans = (formMode) => ({
    isEditMode: formMode === FORM_MODE.EDIT,
    isViewMode: formMode === FORM_MODE.VIEW,
});

export const getFormCallContextBooleans = (callContext: string) => {
    return {
        isChecklistViewContext: callContext === FORM_CALL_CONTEXT.CHECKLIST_VIEW,
        isChecklistEditContext: callContext === FORM_CALL_CONTEXT.CHECKLIST_CREATE,
        isTodoListCreateContext: callContext === FORM_CALL_CONTEXT.TODO_LIST_CREATE,
        isTodoListEditContext: callContext === FORM_CALL_CONTEXT.TODO_LIST_VIEW,
        isUserTodoListViewContext: callContext === FORM_CALL_CONTEXT.USER_TODO_LIST_VIEW,
    };
};
