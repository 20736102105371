import cx from 'classnames';

import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import TickIcon from '@icons/filled/tick.svg';

import styles from './styles.module.scss';

const FEATURES = {
    description: 'We need your phone number to unlock additional critical features of our application, including:',
    features: [
        {
            id: 'premiumData',
            name: 'Expanded Access to Premium Data',
        },
        {
            id: 'propertyValuation',
            name: 'Property Valuation',
        },
        {
            id: 'loanServices',
            name: 'Loan Services',
        },
    ],
};

function VerificationFeatures({ className }) {
    return (
        <div className={cx(styles.featuresWrapper, className)}>
            <Typography color={colors['grey-400']} component='p' variant='mdr'>
                {FEATURES.description}
            </Typography>
            <ul className={styles.features}>
                <ItemList items={FEATURES.features}>
                    {({ name, id }) => (
                        <li key={id} className={styles.feature}>
                            <Icon className={styles.icon} color={colors.success} component={TickIcon} fontSize={9} />
                            <Typography color={colors['grey-400']} component='span' variant='mdb'>
                                {name}
                            </Typography>
                        </li>
                    )}
                </ItemList>
            </ul>
        </div>
    );
}

export default VerificationFeatures;
