import { useState, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import useDebouncedEffect from '@shared/hooks/useDebouncedEffect';
import { withoutCountryCode } from '@utils/index';
import { loansApi } from '@api';
import Autocomplete from '@ui/Autocomplete';
import Input from '@ui/Input';
import Popper from '@ui/Popper';
import PropertyCard from '@shared/templates/forms/components/OrderForm/PropertyCard';
import styles from './styles.module.scss';

const AddPropertyAutocomplete = function ({ property, setProperty, ...props }) {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    //
    useDebouncedEffect(
        () => {
            loansApi.searchAddress({ address: inputValue }).then((response) => {
                setOptions(response.data);
            });
        },
        [inputValue],
        400
    );

    const ListboxComponent = forwardRef((listProps, ref) => (
        <ul {...listProps} ref={ref} className={styles.resultsList} />
    ));

    const renderOptions = (optionProps, { address, ...property }) => {
        const addressValue = withoutCountryCode(address);
        const matches = match(addressValue, inputValue);
        const parts = parse(addressValue, matches);

        return (
            <li {...optionProps} className={styles.itemWrapper}>
                <PropertyCard property={{ address, ...property }} parts={parts} />
            </li>
        );
    };

    return (
        <div className={styles.searchPropertyWrapper}>
            <Autocomplete
                {...props}
                id='search-benchmarking'
                noOptionsText='No properties were found'
                getOptionLabel={(option) => (typeof option === 'string' ? option : option?.address || '')}
                filterOptions={(x) => x}
                options={options}
                loading={loading}
                includeInputInList
                filterSelectedOptions
                sx={{
                    paper: {
                        width: '100%',
                        height: '100%',
                    },
                }}
                PopperComponent={styled(Popper)({
                    width: '340px !important',
                    boxShadow: 'none',
                })}
                value={property}
                onChange={(event, newValue) => {
                    setLoading(true);
                    setProperty(newValue);
                    setLoading(false);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <Input
                        {...params}
                        className={styles.searchInput}
                        InputProps={{
                            ...params.InputProps,
                            sx: { padding: '0 8px' },
                        }}
                        placeholder='Property Address'
                        fullWidth
                    />
                )}
                renderOption={renderOptions}
                ListboxComponent={ListboxComponent}
            />
        </div>
    );
};

export default AddPropertyAutocomplete;
