import { useState } from 'react';
import EditablePercent from '@components/DataTable/Cells/CapRateGapsCell/components/EditablePercent';
import styles from './styles.module.scss';

function PercentCell({
    value: initialValue,
    onUpdateData,
    row: { original = {} },
    cell: {
        column: { id: accessorName, headerProps = {} },
    },
}) {
    const [isVisibleArrows, setIsVisibleArrows] = useState(false);
    const { cellData: { withLabel = true, withoutDecimals } = {} } = headerProps;

    const onChange = ({ name, value }) => {
        onUpdateData({ cell: { name, value }, original });
    };

    return (
        <div
            className={styles.flexWrapper}
            onMouseOver={() => setIsVisibleArrows(true)}
            onMouseLeave={() => setIsVisibleArrows(false)}
        >
            <EditablePercent
                withLabel={withLabel}
                withoutDecimals={withoutDecimals}
                name={accessorName}
                value={initialValue}
                onSuccess={onChange}
                visibleArrows={isVisibleArrows}
                {...headerProps}
            />
        </div>
    );
}

export default PercentCell;
