import cx from 'classnames';
import { Link } from 'react-router-dom';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { getLogoProps, SIZES } from './helpers';
import styles from './logo.module.scss';

type TLogoProps = {
    color?: string;
    nameColor?: string;
    short?: boolean;
    size?: keyof typeof SIZES;
    className?: string;
};

type TLogoLinkProps = {
    color?: string;
    nameColor?: string;
    short?: boolean;
    size?: keyof typeof SIZES;
    className?: string;
    href?: string;
};

function Logo({
    color: colorFromProps = 'inherit',
    nameColor = colors['grey-300'],
    short = false,
    size = 'default',
    className,
}: TLogoProps) {
    const { component, viewBox, color, width, height, nameSize, gap, subtitle } = getLogoProps(
        short,
        colorFromProps,
        size
    );

    return (
        <div className={cx(styles.logo, className)} style={{ maxWidth: width, gap }}>
            <Icon color={color} component={component} height={height} viewBox={viewBox} width={width} />
            {subtitle && (
                <Typography
                    className={styles.name}
                    color={nameColor}
                    sx={{
                        fontWeight: 400,
                        fontSize: nameSize,
                    }}
                    variant='span'
                >
                    by SpaceQuant
                </Typography>
            )}
        </div>
    );
}

// eslint-disable-next-line func-names
Logo.Link = ({ href = '/', className, color, nameColor, size, short }: TLogoLinkProps) => {
    return (
        <Button.Link aria-label='logo' className={cx(styles.button, className)} component={Link} to={href}>
            <Logo color={color} nameColor={nameColor} short={short} size={size} />
        </Button.Link>
    );
};

export default Logo;
