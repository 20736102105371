export const BASE_HEADER = 'BaseHeader';

export const PRIMARY_MODAL_HEADER = 'PrimaryModalHeader';

export const SECONDARY_MODAL_HEADER = 'SecondaryModalHeader';

export const SMALL_MODAL_HEADER = 'SmallModalHeader';

export const UPLOAD_DOCUMENTS = 'uploadDocuments';

export const UPLOAD_DOCUMENTS_STEPS = 'uploadDocumentsSteps';

export const UPLOAD_LOAN_DOCUMENTS_STEPS = 'uploadLoanDocumentsSteps';

export const UPLOAD_LOAN_DOCUMENTS_REQUEST_STEPS = 'uploadLoanDocumentsRequestSteps';

export const UPLOAD_MEMBER_DOCUMENTS_STEPS = 'uploadMemberDocumentsSteps';

export const UPLOAD_PORTFOLIO_DOCUMENTS_STEPS = 'uploadPortfolioDocumentsSteps';

export const UPLOAD_LENDER_DOCUMENTS_STEPS = 'uploadLenderDocumentsSteps';

export const UPLOAD_MEMBER_DOCUMENTS = 'uploadMemberDocuments';

export const UPLOAD_DOCUMENTS_FORM = 'uploadDocumentsForm';

export const PHONE_VERIFICATION = 'phoneVerification';

export const TODO_FORM = 'todoForm';

export const TODO_CHECKLISTS = 'todoChecklist';

export const LISTING_SEARCH_PROPERTY = 'listingSearchProperty';

export const PORTFOLIO_SEARCH_PROPERTY = 'PortfolioSearchProperty';

export const SHARE_PROPERTY = 'shareProperty';

export const LISTING_PROPERTY = 'listingProperty';

export const REFER_FORM = 'referForm';

export const LOAN_REQUEST = 'loan-request';

export const TABS_WIDGET = 'tabsWidget';

export const SHARE_LOAN_APPLICATION = 'shareModalApplication';

export const NO_CONTENT = 'empty';

export const SUCCESS = 'success';

export const UPLOAD_REPORT = 'uploadReport';

export const ORDER_REPORT = 'orderReport';

export const FINANCIAL_MODEL = 'financialModel';

export const CREATE_LOAN = 'createLoan';

export const EDIT_LOAN_FORM = 'editLoanForm';

export const EDIT_PROPERTY_FORM = 'editPropertyForm';

export const SEARCH_PROPERTY = 'searchProperty';

// test nested drawer
export const MEMBER_FORM = 'memberForm';

export const MEMBER_PROPERTY_FORM = 'memberPropertyForm';

export const MEMBER_COMPANY_TEAM_FORM = 'memberCompanyTeamForm';

export const PREVIEW_DOCUMENT = 'previewDocument';

export const UNLOCK_DOCUMENT = 'unlockDocument';

export const ADD_USER = 'addUser';

export const CREATE_COMPANY = 'createCompany';

export const USER_REQUEST = 'portfolioUserRequest';

export const ADD_PROPERTY_ENTERPRISE = 'addPropertyEnterprise';

export const ENTERPRISE_UPLOAD_DOCUMENT = 'enterpriseUploadDocument';

export const SCAN_QUALITY = 'scanQuality';

export const MERGE_MULTILINE_TITLES = 'mergeMultilineTitles';

export const REQUEST_HELP = 'requestHelp';

export const ADD_EDIT_PORTFOLIO = 'addEditPortfolio';

export const ADD_EDIT_PROPERTIES_PORTFOLIO = 'addEditPropertyPortfolio';

export const ADD_EDIT_LOANS_PORTFOLIO = 'addEditLoanPortfolio';

export const ADD_TEAM_USERS = 'addTeamUsers';

export const RENT_ROLL_EXTRACT_TABLE = 'rentRollExtractTable';

export const RENT_ROLL_RECONCILE = 'rentRollReconcile';

export const RENT_ROLL_STANDARDIZE_TENANT_NAMES = 'rentRollStandardizeTenantNames';

export const EXPORT_EXCEL_REPORT = 'exportExcelReport';

export const EXPORT_PDF_REPORT = 'exportPdfReport';

export const EXPORT_TO_PRECISION = 'exportToPrecision';

export const EXPORT_TO_PRECISION_NEW = 'exportToPrecisionNew';

export const REVIEW_DOCUMENT = 'reviewDocument';

export const PROPERTY_SEARCH = 'propertySearch';

export const SIGN_DOCUMENT = 'signDocument';

export const EDIT_USER_PERMISSIONS = 'editUserPermissions';

export const ADD_EDIT_USER = 'addEditUser';

export const ADD_SIMILAR_TRANSACTIONS = 'addSimilarTransaction';

export const GENERATE_CHART_AI = 'generateChartAI';

export const ANALYTICS_SETTINGS = 'analyticsSettings';

export const ADD_COMPANY_CONFIGURATION_TEAMS = 'addCompanyConfigurationTeams';

export const CONFIRM_MODAL = 'confirmModal';

export const SHOW_TABLE_COLUMNS_EDITOR = 'showTableColumnsEditor';

export const COMMENTS_GRID_GROUPS = 'commentsGridGroups';

export const FINANCING_DRAW_CREATE = 'createDraw';

export const LOGIN_DIALOG = 'loginDialog';

export const T12_DIALOG = 't12Dialog';

export const NORMALIZE_NOI_DIALOG = 'normalizeNoiDialog';

export const DRAW_SUMMARY_DETAILS = 'drawSummaryDetails';

export const DRAW_DOCUMENT_DETAILS = 'drawDocumentDetails';

export const IMAGE_PREVIEW = 'imagePreview';

export const GRID_MULTIPLY_VALUES = 'gridMultiplyValues';

export const EDIT_TERM_SHEET = 'editTermSheet';

export const PREVIEW_TERM_SHEET = 'previewTermSheet';

export const PROPERTY_OVERVIEW_CHAT_AI = 'propertyOverviewChatAI';

export const RENT_ROLL_UNDERWRITING_METRICS = 'rentRollUnderwritingMetrics';
