import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { put, take, takeLatest, select, delay } from 'redux-saga/effects';

import { RootState } from '@scc/index';
import { selectDetailedOverviewState } from '@scc/store/ducks/detailedProperty/overview';

import { scenarioLabels } from '@components/DataTable/constants';

import { detailedProperty } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

import { TUnderwritingState, TValuationItem } from './underwriting.types';

const entity = '[detailedPropertyUnderwriting]';

const initialState: TUnderwritingState = {
    loaded: false,
    loading: false,
    valuation: [],
};

const underwritingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
            state.loaded = false;
        },
        setData(state, action: PayloadAction<TValuationItem[]>) {
            state.valuation = [...action.payload];
            state.loading = false;
            state.loaded = true;
        },
        [`load ${API_ERROR}`](state) {
            state.loading = false;
            state.loaded = false;
        },
    },
});

export const { load: loadPropertyValuation, setData: setValuation } = underwritingSlice.actions;

export default underwritingSlice.reducer;

export const selectDetailedUnderwritingState = (store: RootState) => store.detailedProperty.underwriting;

function* loadPropertyValuationSaga({ payload, type }) {
    yield delay(600);
    yield put(apiRequest(payload, detailedProperty.getValuation, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;

    const {
        data: { overview },
    } = yield select(selectDetailedOverviewState);

    let scenarioKeys = Object.keys(scenarioLabels).filter((key) => key !== 'salescomp');

    if (data?.underwritingNoi && data?.underwritingNoi.customScenarios) {
        scenarioKeys = [...scenarioKeys, ...Object.keys(data?.underwritingNoi.customScenarios)];
    }

    let resData: TValuationItem[] = [];
    if (data?.underwritingNoi) {
        resData = scenarioKeys.map((scenario) => {
            const { underwritingNoi, underwritingCapRate } = data;
            const noi = get(underwritingNoi, ['customScenarios', scenario], underwritingNoi[scenario]);

            const numStrings = get(
                underwritingCapRate,
                ['customScenarios', scenario],
                underwritingCapRate[scenario]
            ).split('-');

            const capRate = {
                from: (numStrings[0] * 100).toFixed(2),
                to: (numStrings[1] * 100).toFixed(2),
            };

            return {
                scenario,
                noi,
                capRate,
                sqft: overview.sqft,
                units: overview.units,
            };
        });
    }
    yield put(setValuation(resData));
}

export function* watchUnderwriting() {
    yield takeLatest(loadPropertyValuation, loadPropertyValuationSaga);
}
