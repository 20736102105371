import Avatar from '@ui/Avatar';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import UserIcon from '@icons/filled/user-2.svg';

import styles from './styles.module.scss';

export default function UserInfo({ fullName, position, firstName, lastName, avatarLink }) {
    return (
        <div className={styles.container}>
            <Avatar
                alt='user'
                classes={{ root: styles.avatar }}
                src={avatarLink}
                srcSet={avatarLink}
                sx={{ height: '32px', width: '32px', bgcolor: '#F0F2F7' }}
            >
                <Icon color={colors['grey-200']} component={UserIcon} fontSize={16} />
            </Avatar>
            <div>
                <div className={styles.fullUserInfoTop}>
                    <Typography variant='pr13'>{fullName || `${firstName} ${lastName}`}</Typography>
                </div>
                <Typography color={colors['grey-300']} variant='pr12'>
                    {position}
                </Typography>
            </div>
        </div>
    );
}
