import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { baseChartConfig } from './index';

const underwritingRevenue = merge(cloneDeep(baseChartConfig()), {
    xAxis: {
        axisLabel: {
            rotate: 30,
            margin: 20,
        },
    },
    yAxis: {
        type: 'value',
        name: '$',
    },
    series: [
        {
            data: [
                {
                    value: 0,
                },
                {
                    value: 0,
                },
                {
                    value: 0,
                },
                {
                    value: 0,
                },
                {
                    value: 0,
                },
            ],
            type: 'bar',
            barWidth: '40%',
        },
    ],
});

export default underwritingRevenue;
