const dialog = () => ({
    MuiDialog: {
        styleOverrides: {
            paper: ({ theme }) => ({
                borderRadius: 4,
                [theme.breakpoints.down('md')]: {
                    borderRadius: 0,
                },
            }),
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: '1rem 2rem',
            },
        },
    },
});
export default dialog;
