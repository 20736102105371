import { baseApi as api } from '../configs/document';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        updateDocument: build.mutation<UpdateDocumentApiResponse, UpdateDocumentApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/document/${queryArg.id}`,
                method: 'PUT',
                body: queryArg.updateDocumentRequest,
            }),
        }),
        uploadAsNewDocument: build.mutation<UploadAsNewDocumentApiResponse, UploadAsNewDocumentApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/document/upload-as-new`,
                method: 'POST',
                body: queryArg.uploadAsNewDocumentRequest,
            }),
        }),
        findDocumentsForCurrentUser: build.query<
            FindDocumentsForCurrentUserApiResponse,
            FindDocumentsForCurrentUserApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/document/find`,
                params: {
                    types: queryArg.types,
                    access: queryArg.access,
                    search: queryArg.search,
                    page: queryArg.page,
                    size: queryArg.size,
                    sort: queryArg.sort,
                },
            }),
        }),
        removeDocumentFromDraw: build.mutation<RemoveDocumentFromDrawApiResponse, RemoveDocumentFromDrawApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/document/remove-from-draw`,
                method: 'POST',
                body: queryArg.removeDocumentFromDraw,
            }),
        }),
        getStatementTemplates: build.query<GetStatementTemplatesApiResponse, GetStatementTemplatesApiArg>({
            query: (queryArg) => ({ url: `/api/v2/document/os-template/${queryArg.propertyType}` }),
        }),
        downloadDocument: build.query<DownloadDocumentApiResponse, DownloadDocumentApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/document/download/${queryArg.id}`,
                params: { fixed: queryArg.fixed },
            }),
        }),
        updateDocumentProperty: build.mutation<UpdateDocumentPropertyApiResponse, UpdateDocumentPropertyApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/document/property/status`,
                method: 'POST',
                body: queryArg.updateDocumentProcessingStatusRequest,
            }),
        }),
        getDocumentHistory: build.query<GetDocumentHistoryApiResponse, GetDocumentHistoryApiArg>({
            query: (queryArg) => ({ url: `/api/v2/document/history/${queryArg.id}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type UpdateDocumentApiResponse = /** status 200 OK */ GenericJsonResponseDocumentDto;
export type UpdateDocumentApiArg = {
    id: number;
    updateDocumentRequest: UpdateDocumentRequest;
};
export type UploadAsNewDocumentApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type UploadAsNewDocumentApiArg = {
    uploadAsNewDocumentRequest: UploadAsNewDocumentRequest;
};
export type FindDocumentsForCurrentUserApiResponse = /** status 200 OK */ GenericJsonResponsePageResponseDocumentDto;
export type FindDocumentsForCurrentUserApiArg = {
    /** Document types to filter */
    types?: (
        | 'RENT_ROLL'
        | 'OPERATING_STATEMENT'
        | 'BUDGET'
        | 'TAX_RETURN'
        | 'BANK_STATEMENT'
        | 'DEVELOPMENT_BUDGET'
        | 'INVOICE'
        | 'PROPERTY_PURCHASE_AGREEMENT'
        | 'BALANCE_SHEET'
        | 'GUARANTOR_FINANCIAL_STATEMENT'
        | 'MORTGAGE_STATEMENT'
        | 'INSURANCE_POLICY'
        | 'INSPECTION_REPORT'
        | 'APPRAISAL_REPORT'
        | 'INFO_MEMO'
        | 'LOAN_INFO_MEMO'
        | 'LEASE_AGREEMENT'
        | 'LOAN_AGREEMENT'
        | 'INSURANCE_FORM'
        | 'INSURANCE_AGREEMENT'
        | 'OTHER'
    )[];
    /** Document access to filter */
    access?: 'SHARED' | 'UNSHARED' | 'ALL';
    /** String to search by document name or file name */
    search?: string;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: string[];
};
export type RemoveDocumentFromDrawApiResponse = /** status 200 OK */ GenericJsonResponseDocumentDto;
export type RemoveDocumentFromDrawApiArg = {
    removeDocumentFromDraw: RemoveDocumentFromDraw;
};
export type GetStatementTemplatesApiResponse = /** status 200 OK */ GenericJsonResponseListStatementTemplateDto;
export type GetStatementTemplatesApiArg = {
    propertyType: string;
};
export type DownloadDocumentApiResponse = unknown;
export type DownloadDocumentApiArg = {
    /** Document internal id */
    id: number;
    fixed?: boolean;
};
export type UpdateDocumentPropertyApiResponse = /** status 200 OK */ GenericJsonResponseDocumentLightDto;
export type UpdateDocumentPropertyApiArg = {
    updateDocumentProcessingStatusRequest: UpdateDocumentProcessingStatusRequest;
};
export type GetDocumentHistoryApiResponse = /** status 200 OK */ GenericJsonResponseListDocumentHistoryDto;
export type GetDocumentHistoryApiArg = {
    id: number;
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type UnderwritingMetricsDto = {
    lesser?: number;
    contract?: number;
    market?: number;
    rollover?: number;
    downMarket?: number;
    customScenarios?: {
        [key: string]: number;
    };
};
export type LoanPropertyDto = {
    id?: number;
    propertyId?: string;
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
    userId?: number;
    groupId?: number;
    maturityDate?: string;
    address?: string;
    state?: string;
    propertyType?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    isDetailAnalyze?: boolean;
    size?: number;
    units?: number;
    acres?: number;
    underwritingRevenue?: UnderwritingMetricsDto;
    underwritingNoi?: UnderwritingMetricsDto;
    underwritingValuation?: UnderwritingMetricsDto;
    principal?: number;
    balance?: number;
};
export type CompanyOwnerMemberDto = {
    name?: string;
    companyType?: CompanyType;
    logoPath?: string;
    ownership?: number;
};
export type MemberResponseDto = {
    id?: string;
    userId?: number;
    companyId?: string;
    type?: string;
    notes?: string;
    ssn?: string;
    outstanding?: number;
    liquidity?: number;
    netWorth?: number;
    asOf?: string;
    ownedCompanies?: CompanyOwnerMemberDto[];
};
export type UserCompanyDto = {
    userId?: number;
    companyId?: string;
    position?: string;
    companyName?: string;
    updatedAt?: string;
};
export type TeamMemberDto = {
    id?: number;
    loanRole?: LoanRole;
    position?: string;
    lead?: boolean;
};
export type UserMemberResponseDto = {
    member?: MemberResponseDto;
    userInfo?: UserInfoDto;
    userCompanyDTO?: UserCompanyDto;
    teamMemberDTO?: TeamMemberDto;
};
export type CompanyDto = {
    id?: string;
    name?: string;
    companyType?: CompanyType;
    companyUrl?: string;
    email?: string;
    taxId?: string;
    contactAddress?: string;
    phone?: string;
    logoPath?: string;
    createdAt?: string;
    createdBy?: number;
};
export type CompanyMemberResponseDto = {
    member?: MemberResponseDto;
    company?: CompanyDto;
};
export type MemberDto = {
    userMember?: UserMemberResponseDto;
    companyMember?: CompanyMemberResponseDto;
    isNew?: boolean;
    createdAt?: string;
    id?: string;
    user?: boolean;
};
export type LoanDetailsDto = {
    id?: number;
    loanId?: string;
    loanName?: string;
    jsonValues?: string;
    status?: Status2;
    balance?: number;
    unfundedBalance?: number;
    principal?: number;
    loanProgram?: LoanProgram;
    loanType?: LoanType;
    propertyScore?: number;
    termEnd?: string;
    funded?: string;
    permission?: Permission;
    loanData?: object;
    loanPropertyList?: LoanPropertyDto[];
    members?: {
        [key: string]: MemberDto;
    };
};
export type PropertyBuildingDto = {
    id?: number;
    propertyId?: number;
    name?: string;
    documents?: DocumentDto[];
};
export type DocumentDto = {
    id?: number;
    isNew?: boolean;
    userId?: number;
    groupId?: number;
    type?: Type;
    name?: string;
    fileName?: string;
    pages?: string;
    sheetNumber?: number;
    generated?: boolean;
    uploadedTimestamp?: string;
    uploadedDate?: string;
    documentTimestamp?: string;
    documentDate?: string;
    documentStatus?: DocumentStatus;
    documentStatusByCurrentUser?: DocumentStatusByCurrentUser;
    status?: Status;
    statusLabel?: string;
    documentUsers?: UserInfoDto[];
    documentLoans?: LoanDetailsDto[];
    documentMembers?: MemberDto[];
    documentProperties?: LoanPropertyDto[];
    documentPropertyBuildings?: PropertyBuildingDto[];
    initialPdfParseType?: InitialPdfParseType;
    rrId?: number;
    osId?: number;
    template?: string;
    statusNote?: string;
    updatedUser?: UserInfoDto;
    isProcessMySelf?: boolean;
};
export type GenericJsonResponseDocumentDto = {
    status?: string;
    data?: DocumentDto;
    message?: string;
    code?: string;
};
export type UpdateDocumentRequest = {
    name?: string;
    pages?: string;
    date?: string;
    isProcessedMySelf?: boolean;
    type?: Type;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type UploadAsNewDocumentRequest = {
    documentId: number;
    propertyId: number;
    pages: string;
    type: Type;
};
export type Order = {
    property?: string;
    direction?: string;
    ignoreCase?: boolean;
    nullHandling?: string;
};
export type PageResponseDocumentDto = {
    content?: DocumentDto[];
    first?: boolean;
    last?: boolean;
    totalPages?: number;
    totalElements?: number;
    numberOfElements?: number;
    size?: number;
    number?: number;
    sort?: Order[];
};
export type GenericJsonResponsePageResponseDocumentDto = {
    status?: string;
    data?: PageResponseDocumentDto;
    message?: string;
    code?: string;
};
export type RemoveDocumentFromDraw = {
    documentId: number;
    drawId: number;
};
export type StatementTemplateDto = {
    name?: string;
    label?: string;
};
export type GenericJsonResponseListStatementTemplateDto = {
    status?: string;
    data?: StatementTemplateDto[];
    message?: string;
    code?: string;
};
export type DocumentLightDto = {
    id?: number;
    userId?: number;
    fileName?: string;
    documentName?: string;
    fileDate?: number;
    documentDate?: number;
    pages?: string;
    sheetNumber?: number;
    updatedAt?: number;
    confirmed?: boolean;
    deleted?: boolean;
    status?: Status3;
    documentStatus?: DocumentStatus;
    documentStatusByCurrentUser?: DocumentStatusByCurrentUser;
    statusLabel?: string;
    documentIds?: number[];
    entityId?: number;
    template?: string;
    generated?: boolean;
    locked?: boolean;
    analystLocked?: boolean;
    userRequestPresent?: boolean;
    updatedUser?: UserInfoDto;
    differentChartOfAccounts?: boolean;
    updatedBy?: string;
    combined?: boolean;
};
export type GenericJsonResponseDocumentLightDto = {
    status?: string;
    data?: DocumentLightDto;
    message?: string;
    code?: string;
};
export type UpdateDocumentProcessingStatusRequest = {
    documentId: number;
    rentRollLoanId?: number;
    userId?: number;
    documentStatus: DocumentStatus;
    notes?: string;
    auto?: boolean;
};
export type DocumentHistoryAction = {
    type?: Type2;
    value?: string;
    reviewer?: UserInfoDto;
};
export type DocumentHistoryDto = {
    id?: number;
    documentId?: number;
    user?: UserInfoDto;
    action?: DocumentHistoryAction;
    date?: string;
};
export type GenericJsonResponseListDocumentHistoryDto = {
    status?: string;
    data?: DocumentHistoryDto[];
    message?: string;
    code?: string;
};
export enum Type {
    RentRoll = 'RENT_ROLL',
    OperatingStatement = 'OPERATING_STATEMENT',
    Budget = 'BUDGET',
    TaxReturn = 'TAX_RETURN',
    BankStatement = 'BANK_STATEMENT',
    DevelopmentBudget = 'DEVELOPMENT_BUDGET',
    Invoice = 'INVOICE',
    PropertyPurchaseAgreement = 'PROPERTY_PURCHASE_AGREEMENT',
    BalanceSheet = 'BALANCE_SHEET',
    GuarantorFinancialStatement = 'GUARANTOR_FINANCIAL_STATEMENT',
    MortgageStatement = 'MORTGAGE_STATEMENT',
    InsurancePolicy = 'INSURANCE_POLICY',
    InspectionReport = 'INSPECTION_REPORT',
    AppraisalReport = 'APPRAISAL_REPORT',
    InfoMemo = 'INFO_MEMO',
    LoanInfoMemo = 'LOAN_INFO_MEMO',
    LeaseAgreement = 'LEASE_AGREEMENT',
    LoanAgreement = 'LOAN_AGREEMENT',
    InsuranceForm = 'INSURANCE_FORM',
    InsuranceAgreement = 'INSURANCE_AGREEMENT',
    Other = 'OTHER',
}
export enum DocumentStatus {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
}
export enum DocumentStatusByCurrentUser {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
}
export enum Status {
    Error = 'ERROR',
    InProcessAuto = 'IN_PROCESS_AUTO',
    InProcessManual = 'IN_PROCESS_MANUAL',
    Processed = 'PROCESSED',
    Confirmed = 'CONFIRMED',
}
export enum Status2 {
    SccUnderwriting = 'SCC_UNDERWRITING',
    Evaluation = 'EVALUATION',
    InitialQuoteSent = 'INITIAL_QUOTE_SENT',
    Underwriting = 'UNDERWRITING',
    AppraisalNTitleWork = 'APPRAISAL_N_TITLE_WORK',
    InClosing = 'IN_CLOSING',
    Funded = 'FUNDED',
    PaidOff = 'PAID_OFF',
    Passed = 'PASSED',
    TransferredOut = 'TRANSFERRED_OUT',
    DueDiligence = 'DUE_DILIGENCE',
    ApplicationIssued = 'APPLICATION_ISSUED',
    Loi = 'LOI',
    Dead = 'DEAD',
    UnderContract = 'UNDER_CONTRACT',
}
export enum LoanProgram {
    Acquisition = 'ACQUISITION',
    Construction = 'CONSTRUCTION',
    Refinance = 'REFINANCE',
    NewDevelopment = 'NEW_DEVELOPMENT',
    Redevelopment = 'REDEVELOPMENT',
}
export enum LoanType {
    Loan = 'LOAN',
    LoanRequest = 'LOAN_REQUEST',
    Investment = 'INVESTMENT',
}
export enum Permission {
    Admin = 'ADMIN',
    Creator = 'CREATOR',
    Owner = 'OWNER',
    Collaborator = 'COLLABORATOR',
    Viewer = 'VIEWER',
    None = 'NONE',
}
export enum CompanyType {
    Llc = 'LLC',
    Corporation = 'CORPORATION',
}
export enum LoanRole {
    Borrower = 'BORROWER',
    Lender = 'LENDER',
    Guarantor = 'GUARANTOR',
    Broker = 'BROKER',
    Other = 'OTHER',
}
export enum InitialPdfParseType {
    Pdfbox = 'PDFBOX',
    Ocr = 'OCR',
    Ocr2 = 'OCR2',
}
export enum Status3 {
    Error = 'ERROR',
    InProcessAuto = 'IN_PROCESS_AUTO',
    InProcessManual = 'IN_PROCESS_MANUAL',
    Processed = 'PROCESSED',
    Confirmed = 'CONFIRMED',
}
export enum Type2 {
    Date = 'DATE',
    Delete = 'DELETE',
    Rename = 'RENAME',
    Status = 'STATUS',
    Upload = 'UPLOAD',
    Type = 'TYPE',
    Review = 'REVIEW',
    AutoProcessing = 'AUTO_PROCESSING',
}
export const {
    useUpdateDocumentMutation,
    useUploadAsNewDocumentMutation,
    useFindDocumentsForCurrentUserQuery,
    useRemoveDocumentFromDrawMutation,
    useGetStatementTemplatesQuery,
    useDownloadDocumentQuery,
    useUpdateDocumentPropertyMutation,
    useGetDocumentHistoryQuery,
} = injectedRtkApi;
