import { combineReducers } from 'redux';

import loansReducer, { watchLoans } from './loans';
import notificationsReducer, { watchNotifications } from './notifications';
import propertiesReducer, { watchProperties } from './properties';
import supportReducer, { watchSupport } from './support';
import todosReducer, { watchTodos } from './todos';

export const dashboardWatchers = [watchProperties, watchLoans, watchNotifications, watchSupport, watchTodos];

export default combineReducers({
    properties: propertiesReducer,
    loans: loansReducer,
    notifications: notificationsReducer,
    support: supportReducer,
    todos: todosReducer,
});
