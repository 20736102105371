import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { DRAWER_TYPE, ANCHOR } from '@scc/components/GlobalModals/helpers/consts';

import { Drawer, DrawerState } from '@store/ducks/ui/types/drawer';

const initialState: DrawerState = {
    drawersStack: [],
};

export const DRAWERS_TYPES = {
    base: 'drawer',
    secondary: 'secondary',
    page: 'page',
    popup: 'popup',
    dialog: 'dialog',
    secondaryBottom: 'secondaryBottom',
};

const drawerSlice = createSlice({
    name: '[ui/drawer]',
    initialState,
    reducers: {
        show(state, action) {
            state.drawersStack.push({
                id: uuid(),
                anchor: ANCHOR.RIGHT,
                type: DRAWER_TYPE.DEFAULT_DRAWER,
                isOpen: true,
                ...(action.payload || {}),
            });
        },
        showPage(state, action) {
            state.drawersStack.push({
                id: uuid(),
                anchor: ANCHOR.RIGHT,
                type: DRAWER_TYPE.PRIMARY_PAGE_DRAWER,
                isOpen: true,
                ...(action.payload || {}),
            });
        },
        showBottomPage(state, action) {
            state.drawersStack.push({
                id: uuid(),
                anchor: ANCHOR.BOTTOM,
                type: DRAWER_TYPE.PRIMARY_PAGE_BOTTOM_DRAWER,
                isOpen: true,
                ...(action.payload || {}),
            });
        },
        showPrimaryAside(state, action: PayloadAction<Pick<Drawer, 'data' | 'content' | 'paperClass' | 'header'>>) {
            state.drawersStack.push({
                id: uuid(),
                anchor: ANCHOR.RIGHT,
                type: DRAWER_TYPE.PRIMARY_ASIDE_DRAWER,
                isOpen: true,
                ...(action.payload || {}),
            });
        },
        hide(state) {
            state.drawersStack.pop();
        },
    },
});

export const {
    show: showDrawer,
    showPage: showPageDrawer,
    showPrimaryAside: showPrimaryAsideDrawer,
    showBottomPage: showBottomPageDrawer,
    hide: hideDrawer,
} = drawerSlice.actions;

export const selectUiDrawerState = (state) => state.ui.drawer;

export default drawerSlice.reducer;
