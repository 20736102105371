import { propertyTypes } from '@utils/properties';

import { FORMATTERS } from '@components/BaseForm';

import { parcelsApi, userApi } from '@api';

const {
    OFFICE,
    RETAIL,
    MULTIFAMILY,
    MOBILE_HOME,
    INDUSTRIAL,
    SELF_STORAGE,
    SINGLE_FAMILY,
    LODGING,
    SENIOR_HOUSING,
    STUDENT_HOUSING,
    VACANT_LAND,
    MIXED_USE,
    APARTMENT,
    WAREHOUSE,
    HEALTHCARE,
    AGRICULTURAL,
    OTHER,
} = propertyTypes;

const availablePropertyTypes = [
    OFFICE,
    RETAIL,
    MULTIFAMILY,
    MOBILE_HOME,
    INDUSTRIAL,
    SELF_STORAGE,
    SINGLE_FAMILY,
    LODGING,
    SENIOR_HOUSING,
    STUDENT_HOUSING,
    STUDENT_HOUSING,
    VACANT_LAND,
    MIXED_USE,
    APARTMENT,
    WAREHOUSE,
    HEALTHCARE,
    AGRICULTURAL,
    OTHER,
];

const emptyParcel = {
    size: '',
    units: '',
    beds: '',
    baths: '',
    lotsize: '',
    yearbuilt: '',
};

const FIELDS = [
    {
        name: 'size',
        label: 'Property Size',
        formatter: FORMATTERS.number,
        prefix: 'SF',
        types: [
            OFFICE,
            MIXED_USE,
            APARTMENT,
            WAREHOUSE,
            HEALTHCARE,
            RETAIL,
            INDUSTRIAL,
            SINGLE_FAMILY,
            MULTIFAMILY,
            MOBILE_HOME,
            SELF_STORAGE,
            SENIOR_HOUSING,
            STUDENT_HOUSING,
            LODGING,
            OTHER,
        ],
    },
    {
        name: 'beds',
        label: 'Bedrooms',
        prefix: 'Beds',
        types: [SINGLE_FAMILY],
        width: 3,
    },
    {
        name: 'baths',
        label: 'Bathrooms',
        prefix: 'Bath',
        types: [SINGLE_FAMILY],
        width: 3,
    },
    {
        name: 'units',
        label: 'Number of Units',
        prefix: 'Units',
        collapsed: [SINGLE_FAMILY],
        types: [SINGLE_FAMILY, MULTIFAMILY, MOBILE_HOME, SELF_STORAGE, SENIOR_HOUSING, STUDENT_HOUSING, LODGING, OTHER],
        defaultFields: [SINGLE_FAMILY],
        defaultValue: 1,
    },
    {
        name: 'lotsize',
        label: 'Lot Size',
        prefix: 'SF',
        types: [
            SINGLE_FAMILY,
            MULTIFAMILY,
            MOBILE_HOME,
            SELF_STORAGE,
            SENIOR_HOUSING,
            STUDENT_HOUSING,
            LODGING,
            VACANT_LAND,
            AGRICULTURAL,
            OTHER,
        ],
        collapsed: [
            SINGLE_FAMILY,
            MULTIFAMILY,
            MOBILE_HOME,
            SELF_STORAGE,
            SENIOR_HOUSING,
            STUDENT_HOUSING,
            LODGING,
            OTHER,
        ],
    },
    {
        name: 'yearbuilt',
        label: 'Build Year',
        prefix: 'Year',
        types: [SINGLE_FAMILY, MULTIFAMILY, MOBILE_HOME, SELF_STORAGE, SENIOR_HOUSING, STUDENT_HOUSING, LODGING, OTHER],
        collapsed: [
            SINGLE_FAMILY,
            MULTIFAMILY,
            MOBILE_HOME,
            SELF_STORAGE,
            SENIOR_HOUSING,
            STUDENT_HOUSING,
            LODGING,
            OTHER,
        ],
    },
];

const getParcel = (parcels = [], [key, value]) => (key ? parcels.find((p) => p[key] === value) : parcels[0]);

const addEmptyParcels = (parcels) => {
    availablePropertyTypes.forEach((type) => {
        if (getParcel(parcels, ['propertytype', type])) return;

        // let newParcel;

        // if (type === OFFICE) {
        //     // if we don't have office parcel try to take size from mixed use parcel
        //     const mixedUseParcel = getParcel(parcels, ['propertytype', MIXED_USE]) || { ...emptyParcel };
        //     newParcel = {
        //         size: mixedUseParcel.size,
        //         units: mixedUseParcel.units,
        //         propertytype: type,
        //     };
        // } else {
        // }
        const newParcel = { ...emptyParcel, propertytype: type };
        parcels.push(newParcel);
    });
    return parcels;
};

const transformParcels = (originalParcels = []) => {
    const parcels = [];
    originalParcels.forEach((parcel) => {
        if (availablePropertyTypes.includes(parcel.propertytype)) {
            parcels.push({ ...parcel });
        }
    });
    return parcels;
};

const prepareParcels = (originalParcels) => {
    let parcels = [...originalParcels];
    parcels = addEmptyParcels(parcels);
    parcels = transformParcels(parcels);
    return parcels;
};

const prepareParcelOverrides = (parcel, originalParcel = {}) => {
    const overrides = {
        propertytype: 'PROPERTY_TYPE',
        size: 'PROPERTY_SIZE',
        units: 'PROPERTY_UNITS',
        beds: 'PROPERTY_BEDS',
        baths: 'PROPERTY_BATHS',
        lotsize: 'PROPERTY_LAND',
        yearbuilt: 'PROPERTY_YEAR',
    };
    const params = [];
    Object.keys(overrides)
        .filter(
            (key) =>
                key === 'propertytype' ||
                FIELDS.some((field) => field.name === key && field.types.includes(parcel.propertytype))
        )
        .forEach((prop) => {
            if (parcel[prop] && parcel[prop] !== originalParcel?.[prop]) {
                params.push({ paramname: overrides[prop], paramvalue: String(parcel[prop]) });
            }
        });
    return params;
};

const submitParcel = async (originalParcels, buildingId, parcel) => {
    const {
        propertytype: proptype,
        size = undefined,
        units = undefined,
        beds = undefined,
        baths = undefined,
        lotsize = undefined,
        yearbuilt = undefined,
    } = parcel;
    const originalParcel = getParcel(originalParcels, ['propertytype', proptype]);

    // creating new parcel
    if (!parcel.parcelid) {
        return parcelsApi.createParcel({
            buildingid: buildingId,
            proptype,
            size,
            units,
            beds,
            baths,
            lotsize,
            yearbuilt,
        });
    }

    // saving parcel overrides
    const params = prepareParcelOverrides(parcel, originalParcel);
    if (params.length) {
        await userApi.saveOverrides(parcel.countyid, parcel.parcelid, params);
    }

    return { ...originalParcel, ...parcel };
};

const submitNewParcel = async (originalParcels, buildingid, parcel = {}, userId) => {
    const {
        propertytype: proptype = 'Office',
        size = undefined,
        units = undefined,
        beds = undefined,
        baths = undefined,
        lotsize = undefined,
        yearbuilt = undefined,
        propertyId = undefined,
        loan: { loanid: loanId = '', propertyid = propertyId, propertyname: propertyName = '' } = {},
    } = parcel || {};
    const originalParcel = getParcel(originalParcels, ['propertytype', proptype]);

    // creating new parcel
    if (!parcel.parcelid) {
        return parcelsApi.createNewParcel({
            buildingid,
            proptype,
            propertyId: propertyid,
            propertyName,
            userId,
            loanId,
            size,
            units,
            beds,
            baths,
            lotsize,
            yearbuilt,
        });
    }

    // saving parcel overrides
    const params = prepareParcelOverrides(parcel, originalParcel);
    if (params.length) {
        await userApi.saveOverrides(parcel.countyid, parcel.parcelid, params);
    }

    return { ...originalParcel, ...parcel };
};

const updateParcelOnAddPropertyWizard = async (parcel) => {
    const params = prepareParcelOverrides(parcel);

    return userApi.saveOverrides(parcel.countyid, parcel.parcelid, params);
};

const updateUsingExistingParcel = async ({ originParcel, userId }) => {
    const {
        loan: { id: originLoanId, propertyname, loanid, propertyid } = {},
        countyid,
        parcelid,
    } = originParcel || {};

    if (originLoanId) {
        return;
    }

    const payload = {
        countyId: countyid,
        parcelId: parcelid,
        loanId: loanid,
        propertyId: propertyid,
        propertyName: propertyname,
        userid: userId,
    };

    await parcelsApi.updateUsingExistingParcel(payload);
};

export {
    FIELDS,
    availablePropertyTypes,
    getParcel,
    prepareParcels,
    submitParcel,
    submitNewParcel,
    updateUsingExistingParcel,
    updateParcelOnAddPropertyWizard,
};
