import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import GetStarted from '@scc/components/GetStarted';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import List from '@ui/List';
import ListItem from '@ui/ListItem';
import ListItemAvatar from '@ui/ListItemAvatar';
import ListItemText from '@ui/ListItemText';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import styles from './service.module.scss';
import data from './serviceData';

function ServicePage({ name, label }) {
    const content = data[name] || {};

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <article className={styles.wrapper}>
            <div>
                <Typography className={styles.header} color={colors['deepBlue-500']} variant='head1b' gutterBottom>
                    {label}
                    <span className={styles[name]} />
                </Typography>
                <br />
                <Typography color={colors['darkNeutral-500']} variant={isMobile ? 'pr16' : 'h3b'}>
                    {content.subTitle}
                </Typography>
                <GetStarted className={styles.marginTopMain} text='Get Started' />
            </div>

            <Box>
                <List className={styles.achievements}>
                    <ItemList items={data.achievements}>
                        {({ icon, title, description }) => (
                            <ListItem key={title} className={styles.achievement}>
                                <ListItemAvatar>{icon}</ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography component='h3' variant={isMobile ? 'h5b' : 'h1b'}>
                                            {title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography component='h3' variant={isMobile ? 'pr14' : 'pr16'}>
                                            {description}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )}
                    </ItemList>
                </List>
            </Box>

            <Grid className={styles.service} columnSpacing={15} justifyContent='space-between' container>
                <ItemList items={content.services}>
                    {({ title, description, image }) => (
                        <React.Fragment key={title}>
                            <Grid sm={7} xs={12} item>
                                <Typography color={colors['deepBlue-500']} variant='head2b' gutterBottom>
                                    {title}
                                </Typography>
                                <Typography color={colors['darkNeutral-500']} variant='lgr'>
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid sm={4} xs={8} item>
                                {image}
                            </Grid>
                        </React.Fragment>
                    )}
                </ItemList>
                <GetStarted className={styles.marginTopServices} text='Get Started' />
            </Grid>

            <Grid className={styles.service} columnSpacing={15} container>
                <Grid xs={12} item>
                    <Typography color={colors['deepBlue-500']} variant='head1b'>
                        {content.process?.title}
                    </Typography>
                </Grid>

                <ItemList items={content.process?.steps}>
                    {({ title, description, preview }, index) => (
                        <React.Fragment key={title}>
                            <Grid sm={2} xs={3} item>
                                <Typography className={styles.stepCount}>{index + 1}</Typography>
                            </Grid>
                            <Grid sm={5} xs={9} item>
                                <Typography component='h4' variant='h1b' gutterBottom>
                                    {title}
                                </Typography>
                                <Typography variant='lgr'>{description}</Typography>
                            </Grid>
                            <Grid sm={4} xs={10} item>
                                {preview}
                            </Grid>
                        </React.Fragment>
                    )}
                </ItemList>
            </Grid>
        </article>
    );
}

export default ServicePage;
