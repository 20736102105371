import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

import { reloadable } from '@utils/components';

import LocationParams from '@scc/pages/Portfolio/components/Overview/components/Bottom/LocationParams';
import Actions from '@scc/pages/Property/components/Actions';
import Footer from '@scc/pages/Property/components/Footer';
import { Highlights } from '@scc/pages/Property/components/Highlights';
import Listing from '@scc/pages/Property/components/Listing';
import ListingValue from '@scc/pages/Property/components/ListingValue';
import { LoginButton } from '@scc/pages/Property/components/LoginButton';
import Notes from '@scc/pages/Property/components/Notes';
import PropertyParcel from '@scc/pages/Property/components/PropertyParcel';
import PropertyTitleSection from '@scc/pages/Property/components/PropertyTitleSection';
import Scores from '@scc/pages/Property/components/Scores';
import Valuation from '@scc/pages/Property/components/Valuation';
import useProperty from '@scc/pages/Property/hooks/useProperty';
import styles from '@scc/pages/Property/property.module.scss';

import Grid from '@ui/Grid';
import Typography from '@ui/Typography';

import Meta from '@components/Meta';

import colors from '@themes/palette/export.module.scss';

import { getLocationParamsData } from './helper';

const Maps = reloadable(() => import('./components/Maps'), { ssr: false });

export default function PropertyPage() {
    const [
        {
            step,
            propertyValueRef,
            isOpenParcelEditor,
            tooltip,
            activeMapTab,
            isShowValuations,
            isValuationLoaded,
            propertyData,
            loaded,
            isServer,
            isLoggedIn,
        },
        {
            handleChangeTab,
            handleShareProperty,
            getProperty,
            setIsOpenParcelEditor,
            onTransactionsUpdate,
            handleAddToListingClick,
            handleContactBroker,
        },
    ] = useProperty();

    const { riskInfo, locationInfo, growthInfo, valueInfo } = tooltip;

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        propertyAdvantageDTOList,
        units,
        size,
        lotSize,
        propertyListing,
        address,
        city,
        propertyType,
        locationScore,
        riskScore,
        growthScore,
        valuation,
        parcel,
        street,
        census: { smallRadius: { 2015: { populationDensity, workDensity } = {} } = {} } = {},
        locationMetrics: { footTraffic, hwyscore, popularity, transportation } = {},
    } = propertyData;

    const indicativeValueComponent = useMemo(
        () =>
            propertyListing ? (
                <ListingValue propertyListing={propertyListing} />
            ) : (
                <Valuation
                    {...{
                        isServer,
                        isShowValuations,
                        isValuationLoaded,
                        valuation,
                        valueInfo,
                        handleChangeTab,
                        getProperty,
                        step,
                    }}
                />
            ),
        [
            isServer,
            isShowValuations,
            isValuationLoaded,
            valuation,
            valueInfo,
            handleChangeTab,
            getProperty,
            step,
            propertyListing,
        ]
    );

    return (
        <div className={styles.propertyPageWrapper}>
            <Meta
                description={`View free market data and cap rates for ${address} ${propertyType} property, 
                get same-day property valuation report and pre-approved loans.`}
                title={`${address} - Valuation report, cap rates, free data`}
            />
            {isMobile ? (
                <PropertyTitleSection
                    address={isServer ? address : street}
                    isLoaded={loaded}
                    {...{ city, parcel, handleShareProperty, handleAddToListingClick }}
                />
            ) : null}
            <Grid className={styles.topBlock} spacing={16} container>
                <Grid md={4} xs={12} item>
                    <div className={styles.detailsWrapper} ref={propertyValueRef}>
                        {!isMobile ? (
                            <PropertyTitleSection
                                address={isServer ? address : street}
                                isLoaded={loaded}
                                {...{ city, parcel, handleShareProperty, handleAddToListingClick }}
                            />
                        ) : null}
                        <div className={styles.editorBlock}>
                            <PropertyParcel
                                anchorEl={propertyValueRef.current}
                                measures={{ units, size, lotSize }}
                                open={isOpenParcelEditor}
                                type={propertyType}
                                onToggleOpen={setIsOpenParcelEditor}
                            />

                            {!isLoggedIn ? <LoginButton /> : indicativeValueComponent}

                            <Actions {...{ getProperty, step, propertyListing, handleContactBroker }} />
                        </div>
                    </div>
                    {propertyData && propertyListing && loaded && isMobile && (
                        <Listing propertyListing={propertyListing} onContactBrokerClick={handleContactBroker} />
                    )}
                </Grid>
                <Grid className={styles.mapWrapper} md={8} xs={12} item>
                    <Maps
                        activeMapTab={activeMapTab}
                        data={loaded && propertyData}
                        onChangeTab={handleChangeTab}
                        onTransactionsUpdate={onTransactionsUpdate}
                    />
                </Grid>
            </Grid>
            {isMobile ? (
                <Typography className={styles.highlightsTitle} color={colors['darkNeutral-500']} variant='h5sb'>
                    Market Highlights
                </Typography>
            ) : null}
            {propertyData && propertyListing && loaded && !isMobile && (
                <Listing propertyListing={propertyListing} onContactBrokerClick={handleContactBroker} />
            )}
            <Grid className={styles.midBlock} container>
                <Grid md={6} xs={12} item>
                    <Scores
                        {...{
                            locationInfo,
                            loaded,
                            locationScore,
                            riskInfo,
                            riskScore,
                            growthInfo,
                            growthScore,
                        }}
                    />
                </Grid>
                <Grid md={6} xs={12} item>
                    <LocationParams
                        data={getLocationParamsData({
                            transportation,
                            hwyscore,
                            footTraffic,
                            popularity,
                            populationDensity,
                            workDensity,
                        })}
                    />
                </Grid>
            </Grid>
            <Highlights isMobile={isMobile} list={propertyAdvantageDTOList} />
            {isLoggedIn && <Notes />}
            <div className={styles.footerWrapper}>
                <Footer />
            </div>
        </div>
    );
}
