import dayjs from 'dayjs';
import { isNaN } from 'lodash';

type DateType = string | number | Date;

export type DateTimeFormatOptions = Intl.DateTimeFormatOptions & { locales?: string };

export const monthsMap = (value) => {
    const month = value.startsWith(0) ? value[1] : value;

    const map = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
    };
    return map[month];
};

export const optionsDatesMonth = [
    {
        value: 3,
        label: 'Last 3 Months',
    },
    {
        value: 6,
        label: 'Last 6 Months',
    },
    {
        value: 12,
        label: 'Last 12 Months',
    },
    {
        value: 24,
        label: 'Last 2 Years',
    },
    {
        value: 36,
        label: 'Last 3 Years',
    },
    {
        value: 60,
        label: 'Last 5 Years',
    },
];

const isoDateFormatPattern =
    // eslint-disable-next-line max-len
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;

export const dateTimeStampToUtcValue = (value) => {
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

    if (typeof value === 'string') {
        return new Date(value).getTime() + timeZoneOffset;
    }

    if (value instanceof Date) {
        return value.getTime() + timeZoneOffset;
    }

    return value + timeZoneOffset;
};

export const isIsoDate = (date) => isoDateFormatPattern.test(date);

export const addMonthToDate = (months = 0, date = new Date()) => date.setMonth(date.getMonth() + months);

export const isValidDate = (target) => {
    if (isIsoDate(target)) {
        return !isNaN(Date.parse(target));
    }

    try {
        return !isNaN(new Date(Number(target)));
    } catch (e) {
        console.warn(e);
        return false;
    }
};

export const formatDate = (
    date: DateType = Date.now(),
    options: DateTimeFormatOptions = { year: '2-digit', month: '2-digit', locales: 'en-US' }
) => {
    const { locales = 'en-US', ...optionProps } = options;

    if (date === '-' || !date) return null;

    if (date instanceof Date) {
        return date.toLocaleDateString(locales, optionProps);
    }

    if (isIsoDate(date)) {
        return new Date(
            (date as string)
                .replace('000Z', '')
                .replace(/(\/|\.|-|T)/gim, ' ')
                .trim()
        ).toLocaleDateString(locales, optionProps);
    }

    return !isValidDate(date) ? date : new Date(Number(date) || date).toLocaleDateString(locales, optionProps);
};

export const shortFormatDate = (date, format) => {
    const formattedData = !isValidDate(date) ? date : new Date(Number(date) || date).toLocaleDateString();
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (formattedData === today.toLocaleDateString()) {
        return 'Today';
    }
    if (formattedData === yesterday.toLocaleDateString()) {
        return 'Yesterday';
    }

    return formatDate(date, format);
};

export const isoDateFormatter = (date: DateType = Date.now()) => {
    const d = new Date(date);
    const yyyy = d.getFullYear();
    let mm: string | number = d.getMonth() + 1;
    let dd: string | number = d.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${mm}/${dd}/${yyyy}`;
};

export const formatTime = (
    date: DateType = Date.now(),
    options: DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', locales: 'en-US' }
) => {
    const { locales = 'en-US', ...optionProps } = options;

    if (date === '-' || !date) return null;

    if (date instanceof Date) {
        return date.toLocaleTimeString(locales, optionProps);
    }

    if (isIsoDate(date)) {
        return new Date(
            (date as string)
                .replace('000Z', '')
                .replace(/(\/|\.|-|T)/gim, ' ')
                .trim()
        ).toLocaleTimeString(locales, optionProps);
    }

    return !isValidDate(date) ? date : new Date(Number(date) || date).toLocaleTimeString(locales, optionProps);
};

export const parseDate = (date) => {
    const parsedDate = date.split('/');
    return [monthsMap(parsedDate[0]), parsedDate[1], parsedDate[2]];
};

export const getMonthYear = (date) => (date ? dayjs(date, 'MM/YY').format('MMM YYYY').split(' ') : '');

export const convertAnyDateFormatToTimestamp = (date: DateType) => {
    if (!date || !isValidDate(date)) {
        return null;
    }

    if (typeof date === 'string') {
        return Date.parse(date);
    }

    if (typeof date === 'number') {
        return date;
    }

    return date.getTime();
};
