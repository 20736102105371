import * as apis from './apis';

export const apiMiddlewares = [
    apis.varianceReportApi.middleware,
    apis.appraisalReportApi.middleware,
    apis.todoApi.middleware,
    apis.userLoans.middleware,
    apis.propertyApi.middleware,
    apis.portfolioAnalyticsApi.middleware,
    apis.documentApi.middleware,
    apis.gridApi.middleware,
    apis.propertyDetailsApi.middleware,
    apis.portfolioAlertsApi.middleware,
    apis.notes2Api.middleware,
    apis.commentsApi.middleware,
    apis.referLoanApi.middleware,
    apis.dealCloudApi.middleware,
    apis.team.middleware,
    apis.denormalizedLoansApi.middleware,
    apis.underwritingApi.middleware,
    apis.rentRollLabelApi.middleware,
    apis.rentRollMetricApi.middleware,
    apis.rentRollAnalyticsApi.middleware,
];

export const apiReducers = {
    [apis.varianceReportApi.reducerPath]: apis.varianceReportApi.reducer,
    [apis.appraisalReportApi.reducerPath]: apis.appraisalReportApi.reducer,
    [apis.todoApi.reducerPath]: apis.todoApi.reducer,
    [apis.propertyApi.reducerPath]: apis.propertyApi.reducer,
    [apis.userLoans.reducerPath]: apis.userLoans.reducer,
    [apis.portfolioAnalyticsApi.reducerPath]: apis.portfolioAnalyticsApi.reducer,
    [apis.documentApi.reducerPath]: apis.documentApi.reducer,
    [apis.gridApi.reducerPath]: apis.gridApi.reducer,
    [apis.propertyDetailsApi.reducerPath]: apis.propertyDetailsApi.reducer,
    [apis.portfolioAlertsApi.reducerPath]: apis.portfolioAlertsApi.reducer,
    [apis.notes2Api.reducerPath]: apis.notes2Api.reducer,
    [apis.commentsApi.reducerPath]: apis.commentsApi.reducer,
    [apis.referLoanApi.reducerPath]: apis.referLoanApi.reducer,
    [apis.team.reducerPath]: apis.team.reducer,
    [apis.dealCloudApi.reducerPath]: apis.dealCloudApi.reducer,
    [apis.denormalizedLoansApi.reducerPath]: apis.denormalizedLoansApi.reducer,
    [apis.underwritingApi.reducerPath]: apis.underwritingApi.reducer,
    [apis.rentRollLabelApi.reducerPath]: apis.rentRollLabelApi.reducer,
    [apis.rentRollMetricApi.reducerPath]: apis.rentRollMetricApi.reducer,
    [apis.rentRollAnalyticsApi.reducerPath]: apis.rentRollAnalyticsApi.reducer,
};
