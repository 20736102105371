import { baseApi as api } from '../configs/propertyDetails';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        deleteCombinedRentRoll: build.mutation<DeleteCombinedRentRollApiResponse, DeleteCombinedRentRollApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property-details/processed-documents/combined-rent-roll/${queryArg.id}`,
                method: 'DELETE',
            }),
        }),
        getAnalyticsSettings: build.query<GetAnalyticsSettingsApiResponse, GetAnalyticsSettingsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property-details/analytics/settings`,
                params: { propertyId: queryArg.propertyId },
            }),
        }),
        saveAnalyticsSettings: build.mutation<SaveAnalyticsSettingsApiResponse, SaveAnalyticsSettingsApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property-details/analytics/settings`,
                method: 'POST',
                body: queryArg.savePropertyAnalyticsSettingsRequest,
            }),
        }),
        getMarketAnalysis: build.query<GetMarketAnalysisApiResponse, GetMarketAnalysisApiArg>({
            query: (queryArg) => ({ url: `/api/v2/property-details/market-analysis/${queryArg.propertyId}` }),
        }),
        updateMarketAnalysis: build.mutation<UpdateMarketAnalysisApiResponse, UpdateMarketAnalysisApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property-details/market-analysis/${queryArg.propertyId}`,
                method: 'PUT',
                body: queryArg.propertyMarketAnalysisDto,
            }),
        }),
        generatePropertyCombinedRentRoll: build.mutation<
            GeneratePropertyCombinedRentRollApiResponse,
            GeneratePropertyCombinedRentRollApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/property-details/processed-documents/combined-rent-roll/generate`,
                method: 'POST',
                params: {
                    propertyId: queryArg.propertyId,
                    documentIds: queryArg.documentIds,
                    name: queryArg.name,
                    date: queryArg.date,
                },
            }),
        }),
        updatePropertyCombinedRentRoll: build.mutation<
            UpdatePropertyCombinedRentRollApiResponse,
            UpdatePropertyCombinedRentRollApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/property-details/processed-documents/combined-rent-roll/update`,
                method: 'POST',
                body: queryArg.updatePropertyCombinedRentRollRequest,
            }),
        }),
        askPropertyOverview: build.mutation<AskPropertyOverviewApiResponse, AskPropertyOverviewApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/property-details/market-analysis/ask`,
                method: 'POST',
                body: queryArg.propertyOverviewAskRequest,
            }),
        }),
        getPropertyProcessedRentRolls: build.query<
            GetPropertyProcessedRentRollsApiResponse,
            GetPropertyProcessedRentRollsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/property-details/processed-documents/rent-roll`,
                params: {
                    propertyId: queryArg.propertyId,
                    propertyBuildingId: queryArg.propertyBuildingId,
                    confirmed: queryArg.confirmed,
                },
            }),
        }),
        getPropertyCombinedStatementDateLabels: build.query<
            GetPropertyCombinedStatementDateLabelsApiResponse,
            GetPropertyCombinedStatementDateLabelsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/property-details/processed-documents/combined-statement/date-labels`,
                params: { propertyId: queryArg.propertyId },
            }),
        }),
        getRentRollMultiFamilyCompareData: build.query<
            GetRentRollMultiFamilyCompareDataApiResponse,
            GetRentRollMultiFamilyCompareDataApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/property-details/rr-compare/multi-family`,
                params: {
                    firstRrId: queryArg.firstRrId,
                    secondRrId: queryArg.secondRrId,
                    propertyId: queryArg.propertyId,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type DeleteCombinedRentRollApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type DeleteCombinedRentRollApiArg = {
    /** Combined Rent Roll id */
    id: number;
};
export type GetAnalyticsSettingsApiResponse = /** status 200 OK */ GenericJsonResponsePropertyAnalyticsSettingsDto;
export type GetAnalyticsSettingsApiArg = {
    /** Property Id */
    propertyId: number;
};
export type SaveAnalyticsSettingsApiResponse = /** status 200 OK */ GenericJsonResponsePropertyAnalyticsSettingsDto;
export type SaveAnalyticsSettingsApiArg = {
    savePropertyAnalyticsSettingsRequest: SavePropertyAnalyticsSettingsRequest;
};
export type GetMarketAnalysisApiResponse = /** status 200 OK */ GenericJsonResponsePropertyMarketAnalysisDto;
export type GetMarketAnalysisApiArg = {
    /** Property Id */
    propertyId: number;
};
export type UpdateMarketAnalysisApiResponse = /** status 200 OK */ GenericJsonResponsePropertyMarketAnalysisDto;
export type UpdateMarketAnalysisApiArg = {
    /** Property Id */
    propertyId: number;
    propertyMarketAnalysisDto: PropertyMarketAnalysisDto;
};
export type GeneratePropertyCombinedRentRollApiResponse = /** status 200 OK */ GenericJsonResponseDocumentLightDto;
export type GeneratePropertyCombinedRentRollApiArg = {
    /** Property id */
    propertyId: number;
    /** List of Rent Roll ids */
    documentIds: number[];
    /** Combined Rent Roll name */
    name: string;
    /** Document date */
    date: number;
};
export type UpdatePropertyCombinedRentRollApiResponse = /** status 200 OK */ GenericJsonResponseDocumentLightDto;
export type UpdatePropertyCombinedRentRollApiArg = {
    updatePropertyCombinedRentRollRequest: UpdatePropertyCombinedRentRollRequest;
};
export type AskPropertyOverviewApiResponse = /** status 200 OK */ GenericJsonResponseJsonNode;
export type AskPropertyOverviewApiArg = {
    propertyOverviewAskRequest: PropertyOverviewAskRequest;
};
export type GetPropertyProcessedRentRollsApiResponse = /** status 200 OK */ GenericJsonResponseListDocumentLightDto;
export type GetPropertyProcessedRentRollsApiArg = {
    /** Property Id */
    propertyId: number;
    /** Property building Id */
    propertyBuildingId?: number;
    /** If true, return only confirmed Rent Rolls */
    confirmed?: boolean;
};
export type GetPropertyCombinedStatementDateLabelsApiResponse = /** status 200 OK */ GenericJsonResponseSetString;
export type GetPropertyCombinedStatementDateLabelsApiArg = {
    /** Property Id */
    propertyId: number;
};
export type GetRentRollMultiFamilyCompareDataApiResponse =
    /** status 200 OK */ GenericJsonResponseListRentRollMultiFamilyCompareDto;
export type GetRentRollMultiFamilyCompareDataApiArg = {
    /** First Rent Roll internal id */
    firstRrId?: number;
    /** Second Rent Roll internal id to compare with */
    secondRrId?: number;
    /** PropertyId in case if Underwriting Rent Roll Metric Input was selected */
    propertyId?: number;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type PropertyAnalyticsSettingsDto = {
    propertyId?: number;
    rentRollId?: number;
    isNormalized?: boolean;
    dateLabel?: string[];
};
export type GenericJsonResponsePropertyAnalyticsSettingsDto = {
    status?: string;
    data?: PropertyAnalyticsSettingsDto;
    message?: string;
    code?: string;
};
export type SavePropertyAnalyticsSettingsRequest = {
    propertyId?: number;
    rentRollId?: number;
    isNormalized?: boolean;
    dateLabel?: string[];
};
export type PropertyMarketAnalysisDto = {
    strength?: string;
    weaknesses?: string;
    opportunity?: string;
    threat?: string;
};
export type GenericJsonResponsePropertyMarketAnalysisDto = {
    status?: string;
    data?: PropertyMarketAnalysisDto;
    message?: string;
    code?: string;
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    userId?: number;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type DocumentLightDto = {
    id?: number;
    userId?: number;
    fileName?: string;
    documentName?: string;
    fileDate?: number;
    documentDate?: number;
    pages?: string;
    sheetNumber?: number;
    updatedAt?: number;
    confirmed?: boolean;
    deleted?: boolean;
    status?: Status;
    type?: Type;
    documentStatus?: DocumentStatus;
    documentStatusByCurrentUser?: DocumentStatusByCurrentUser;
    statusLabel?: string;
    documentIds?: number[];
    entityId?: number;
    template?: string;
    generated?: boolean;
    locked?: boolean;
    analystLocked?: boolean;
    userRequestPresent?: boolean;
    updatedUser?: UserInfoDto;
    differentChartOfAccounts?: boolean;
    updatedBy?: string;
    rentRollLabel?: string;
    combined?: boolean;
    currentBudget?: boolean;
};
export type GenericJsonResponseDocumentLightDto = {
    status?: string;
    data?: DocumentLightDto;
    message?: string;
    code?: string;
};
export type UpdatePropertyCombinedRentRollRequest = {
    rrId: number;
    documentIds?: number[];
    name?: string;
    date?: number;
};
export type JsonNode = object;
export type GenericJsonResponseJsonNode = {
    status?: string;
    data?: JsonNode;
    message?: string;
    code?: string;
};
export type PropertyOverviewAskRequest = {
    propertyId?: number;
    address?: string;
    propertyType?: string;
    section?: string;
    requestString?: string;
};
export type GenericJsonResponseListDocumentLightDto = {
    status?: string;
    data?: DocumentLightDto[];
    message?: string;
    code?: string;
};
export type GenericJsonResponseSetString = {
    status?: string;
    data?: string[];
    message?: string;
    code?: string;
};
export type RentRollMultiFamilyCompareDto = {
    rentRollId?: number;
    rentRollDate?: number;
    occupancy?: number;
    rent?: number;
    gpr?: number;
    concession?: number;
};
export type GenericJsonResponseListRentRollMultiFamilyCompareDto = {
    status?: string;
    data?: RentRollMultiFamilyCompareDto[];
    message?: string;
    code?: string;
};
export enum Status {
    Error = 'ERROR',
    InProcessAuto = 'IN_PROCESS_AUTO',
    InProcessManual = 'IN_PROCESS_MANUAL',
    Processed = 'PROCESSED',
    Confirmed = 'CONFIRMED',
}
export enum Type {
    RentRoll = 'RENT_ROLL',
    OperatingStatement = 'OPERATING_STATEMENT',
    Budget = 'BUDGET',
    TaxReturn = 'TAX_RETURN',
    BankStatement = 'BANK_STATEMENT',
    DevelopmentBudget = 'DEVELOPMENT_BUDGET',
    Invoice = 'INVOICE',
    PropertyPurchaseAgreement = 'PROPERTY_PURCHASE_AGREEMENT',
    BalanceSheet = 'BALANCE_SHEET',
    GuarantorFinancialStatement = 'GUARANTOR_FINANCIAL_STATEMENT',
    MortgageStatement = 'MORTGAGE_STATEMENT',
    InsurancePolicy = 'INSURANCE_POLICY',
    InspectionReport = 'INSPECTION_REPORT',
    AppraisalReport = 'APPRAISAL_REPORT',
    InfoMemo = 'INFO_MEMO',
    LoanInfoMemo = 'LOAN_INFO_MEMO',
    LeaseAgreement = 'LEASE_AGREEMENT',
    LoanAgreement = 'LOAN_AGREEMENT',
    InsuranceForm = 'INSURANCE_FORM',
    InsuranceAgreement = 'INSURANCE_AGREEMENT',
    Other = 'OTHER',
}
export enum DocumentStatus {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export enum DocumentStatusByCurrentUser {
    UnderReview = 'UNDER_REVIEW',
    Accepted = 'ACCEPTED',
    NotAccepted = 'NOT_ACCEPTED',
    RequestInfo = 'REQUEST_INFO',
    StandardizedNotReviewed = 'STANDARDIZED_NOT_REVIEWED',
    ReviewedBy = 'REVIEWED_BY',
    NotReviewedBy = 'NOT_REVIEWED_BY',
    Questions = 'QUESTIONS',
    Processing = 'PROCESSING',
    WaitingStandardization = 'WAITING_STANDARDIZATION',
    WaitingPages = 'WAITING_PAGES',
    ApprovedBy = 'APPROVED_BY',
    Paid = 'PAID',
    NotReviewed = 'NOT_REVIEWED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    NonApproved = 'NON_APPROVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
}
export const {
    useDeleteCombinedRentRollMutation,
    useGetAnalyticsSettingsQuery,
    useSaveAnalyticsSettingsMutation,
    useGetMarketAnalysisQuery,
    useUpdateMarketAnalysisMutation,
    useGeneratePropertyCombinedRentRollMutation,
    useUpdatePropertyCombinedRentRollMutation,
    useAskPropertyOverviewMutation,
    useGetPropertyProcessedRentRollsQuery,
    useGetPropertyCombinedStatementDateLabelsQuery,
    useGetRentRollMultiFamilyCompareDataQuery,
} = injectedRtkApi;
