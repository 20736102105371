import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { all, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import AnalyticsCharts from '@utils/charts/classes/AnalyticsCharts';
import { documentTypesFlags } from '@utils/documents';
import { isMFLike, isAgroProperty } from '@utils/properties';
import { osid, rrid } from '@utils/store';
import { calculateValuationValue } from '@utils/valuation';

import { loadPortfolioDocuments, setDocuments } from '@scc/store/ducks/documents';
import { selectSimilarTransactionsState, setSimilarTransactions } from '@scc/store/ducks/similarTransactions';

import { detailedProperty, parcelsApi, propertyNotesApi } from '@api';
import {
    MARKET_VALUE,
    PROPERTY_BUILDING_CLASS,
    PROPERTY_COUNTY_DESCRIPTION,
    PROPERTY_LAND,
    PROPERTY_LAND_USE_CODE,
    PROPERTY_OCCUPANCY,
    PROPERTY_RENOVATED_YEAR,
    PROPERTY_SIZE,
    PROPERTY_TYPE,
    PROPERTY_UNITS,
    PROPERTY_YEAR,
    PROPERTY_ZONING,
} from '@constants/editableFields';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[detailedProperty/overview]';

const initialState = {
    loaded: false,
    loading: false,
    overviewLoading: false,
    overviewLoaded: false,
    marketLoading: false,
    marketLoaded: false,
    documentsLoading: false,
    documentsLoaded: false,
    analyticsLoading: false,
    analyticsLoaded: false,
    chartsLoading: false,
    chartsLoaded: false,
    historyLoading: false,
    historyLoaded: false,
    data: {
        overview: {},
        market: {},
        documents: {},
        analytics: {},
        charts: {},
        history: {},
    },
};

const overviewSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, { payload }) {
            state.loading = true;
            state.loaded = false;
            state.countyId = payload.countyId;
            state.parcelId = payload.parcelId;
            state.userId = payload.userId;
        },
        loaded(state) {
            state.loading = false;
            state.loaded = true;
        },
        loadOverview(state) {
            state.overviewLoading = true;
            state.overviewLoaded = false;
        },
        loadMarket(state) {
            state.marketLoading = true;
            state.marketLoaded = false;
        },
        loadDocuments(state) {
            state.documentsLoading = true;
            state.documentsLoaded = false;
        },
        loadAnalytics(state) {
            state.analyticsLoading = true;
            state.analyticsLoaded = false;
        },
        loadCharts(state, { payload }) {
            state.data.charts = payload;
            state.chartsLoading = true;
            state.chartsLoaded = false;
        },
        loadHistory(state) {
            state.historyLoading = true;
            state.historyLoaded = false;
        },

        setOverview(state, { payload }) {
            const [mainAddress, ...secondaryAddress] = payload.address.split(', ') || {};

            state.data.overview = {
                ...payload,
                addresses: {
                    mainAddress,
                    secondaryAddress: secondaryAddress.join(', '),
                },
            };
            state.overviewLoading = false;
            state.overviewLoaded = true;
        },
        setMarket(state, { payload }) {
            state.data.market = payload;
            state.marketLoading = false;
            state.marketLoaded = true;
        },
        setDocuments(state, { payload }) {
            state.data.documents = payload;
            state.documentsLoading = false;
            state.documentsLoaded = true;
        },
        setAnalytics(state, { payload }) {
            state.data.analytics = payload;
            state.analyticsLoading = false;
            state.analyticsLoaded = true;
        },
        setCharts(state, { payload }) {
            state.data.charts = payload;
            state.chartsLoading = false;
            state.chartsLoaded = true;
        },
        setHistory(state, { payload }) {
            state.data.history = payload;
            state.historyLoading = false;
            state.historyLoaded = true;
        },
        setPropertyParcels(state, { payload }) {
            state.data.overview.parcels = payload;
        },

        updateOverview(state, { payload }) {
            state.data.overview = { ...state.data.overview, ...payload };
        },

        updateAnalytics(state, { payload }) {
            state.data.analytics = merge(state.data.analytics, payload);
        },

        editBaseFields() {},
        editMainFields(state) {
            state.overviewLoading = false;
            state.overviewLoaded = true;
        },
        setDocumentFlag(state, { payload: { type, value } = {} }) {
            documentTypesFlags[type].forEach((flag) => {
                state.data.documents[flag] = value;
            });
        },
        reloadAnalytics() {},
        getNotes() {},
        saveNotes() {},
        setNotes(state, { payload }) {
            state.data.overview.notes = payload;
        },
        resetState() {
            return initialState;
        },
    },
});

export const {
    load: loadMainData,
    loaded: loadedMainData,
    loadOverview: loadOverviewData,
    loadMarket: loadMarketData,
    loadDocuments: loadDocumentsData,
    loadAnalytics: loadAnalyticsData,
    loadCharts: loadChartsData,
    loadHistory: loadHistoryData,
    setOverview: setOverviewData,
    setMarket: setMarketData,
    setDocuments: setDocumentsData,
    setAnalytics: setAnalyticsData,
    setCharts: setChartsData,
    setHistory: setHistoryData,
    setPropertyParcels: setPropertyParcelsData,
    updateOverview: updateOverviewData,
    updateAnalytics: updateAnalyticsData,
    reloadAnalytics: reloadAnalyticsData,
    editBaseFields,
    editMainFields,
    setDocumentFlag,
    getNotes: getNotesData,
    saveNotes: saveNotesData,
    setNotes: setNotesData,
    resetState: resetStateOverview,
} = overviewSlice.actions;

export default overviewSlice.reducer;

export const selectDetailedOverviewState = (store) => store.detailedProperty.overview;

export const selectDetailedOverviewPropertyIdState = (store) =>
    store.detailedProperty.overview.data.overview?.rentRollLoanId;

export const selectDetailedCombinedOsID = createSelector(
    selectDetailedOverviewState,
    ({ data: { documents } }) => documents?.rentRollStatementCombined?.combinedOsid
);

export const selectDetailedPropertyOverviewDocuments = createSelector(
    selectDetailedOverviewState,
    ({ data }) => data.documents
);

export const selectMarketOverview = createSelector(
    [selectSimilarTransactionsState, selectDetailedOverviewState],
    (
        { data: { zoomedValues, ...data } },
        {
            data: {
                market: { similarTransactionsZoomed, ...market },
                overview,
            },
            marketLoading,
            marketLoaded,
        }
    ) => ({
        data: {
            ...market,
            similarTransactionsResponse: { zoomedValues: zoomedValues || similarTransactionsZoomed, ...data },
            overview,
            propertySize: isAgroProperty(overview?.propertyType)
                ? parseFloat(market.lotSize?.replaceAll(',', '') || 0)
                : market.size,
        },
        loading: marketLoading,
        loaded: marketLoaded,
    })
);

export const selectMarket = (store) => store.detailedProperty.overview.data.market;

export const selectIsReportPDFExportReady = createSelector(
    [selectDetailedOverviewState],
    ({
        data: { overview },
        overviewLoading,
        overviewLoaded,
        analyticsLoading,
        analyticsLoaded,
        marketLoading,
        marketLoaded,
    }) => ({
        loading: overviewLoading || analyticsLoading || marketLoading,
        loaded: overviewLoaded && analyticsLoaded && marketLoaded,
        overview,
    })
);

function* loadOverviewDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getOverview, type));
    const overviewAction = yield take(`${type} ${API_SUCCESS}`);

    const {
        payload: { data },
    } = overviewAction;
    yield put(setOverviewData(data));
    const { address, latitude, longitude } = data;
    yield put(
        apiRequest({ address, latitude, longitude, parcelsData: true }, parcelsApi.getParcelsByAddress, 'parcels')
    );
    const {
        payload: { parcels },
    } = yield take(`parcels ${API_SUCCESS}`);
    yield put(setPropertyParcelsData(parcels));
}

function* loadMarketDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getMarket, type));
    const marketAction = yield take(`${type} ${API_SUCCESS}`);

    const {
        payload: {
            data: { similarTransactionsResponse, outlierTransactions, notOutlierTransactions, ...data },
        },
    } = marketAction;

    if (similarTransactionsResponse) {
        yield put(
            setSimilarTransactions({
                similarTransactionsResponse,
                outlierTransactions: outlierTransactions?.split(',').filter(Boolean) || [],
                notOutlierTransactions: notOutlierTransactions?.split(',').filter(Boolean) || [],
            })
        );
    }
    yield put(setMarketData(data));
}

function* loadDocumentsDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getDocuments, type));
    const documentsAction = yield take(`${type} ${API_SUCCESS}`);

    const {
        payload: {
            data: { processedDocuments: documentsData },
        },
    } = documentsAction;
    yield put(setDocumentsData(documentsData));
}

function* loadAnalyticsDataSaga({ type, payload }) {
    const charts = new AnalyticsCharts();
    yield put(loadChartsData(charts));

    const {
        data: {
            similarTransactionsResponse: { zoomedValues = [] },
        },
    } = yield select(selectMarketOverview);

    yield put(apiRequest(payload, detailedProperty.getAnalytics, type));
    const analyticsAction = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (analyticsAction.type.endsWith(API_ERROR)) {
        yield put(setChartsData({}));
        yield put(setAnalyticsData({}));
        return;
    }

    const {
        payload: {
            data: { analytics: analyticsData },
        },
    } = analyticsAction;

    const {
        data: {
            overview: { sqft, units, propertyType },
        },
    } = yield select(selectDetailedOverviewState);

    const isMf = isMFLike(propertyType);

    const filteredSimilarTransactions = zoomedValues?.filter((val) => !val.excluded);

    const averageSalesComps = zoomedValues
        ? filteredSimilarTransactions.reduce((acc, val) => acc + val.price, 0) / filteredSimilarTransactions.length
        : 0;
    const valuation = calculateValuationValue(averageSalesComps, isMf ? units : sqft);

    const dataWithSalesComps = {
        ...analyticsData,
        underwritingValuation: {
            ...analyticsData?.underwritingValuation,
            salesComps: valuation,
        },
    };

    charts.addData(dataWithSalesComps);
    yield put(setChartsData(charts));
    yield put(setAnalyticsData(analyticsData));
}

function* loadHistoryDataSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.getHistory, type));
    const { payload: { data } = {} } = yield take(`${type} ${API_SUCCESS}`);
    yield put(setHistoryData(data));
}

function* loadMainDataSaga({ payload }) {
    yield put(loadOverviewData(payload));
    yield put(loadHistoryData(payload));
    yield put(loadMarketData(payload));
    yield put(loadDocumentsData(payload));
    const documents = yield take(setDocumentsData);

    const {
        payload: { rentRollList, rentRollStatementCombined: { combinedOsid } = {}, rentRollStatementList },
    } = documents;

    const analyticRequestData = {
        ...rrid(rentRollList),
        ...osid(rentRollStatementList),
        combinedOsid,
    };

    yield put(loadAnalyticsData(analyticRequestData));
    yield put(loadedMainData());
}

function* editBaseFieldsSaga({ type, payload }) {
    yield put(apiRequest(payload, detailedProperty.overwriteBaseFields, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(updateOverviewData(payload.data));
}

function* editMainFieldsSaga({ type, payload }) {
    const { countyId, parcelId, userId } = payload;

    const map = {
        [PROPERTY_YEAR]: 'yearbuilt',
        [PROPERTY_RENOVATED_YEAR]: 'renovateYear',
        [PROPERTY_SIZE]: 'sqft',
        [PROPERTY_UNITS]: 'units',
        [PROPERTY_OCCUPANCY]: 'occupancy',
        [PROPERTY_LAND]: 'lotSqft',
        [PROPERTY_BUILDING_CLASS]: 'buildingQuality',
        [PROPERTY_ZONING]: 'zoning',
        [PROPERTY_LAND_USE_CODE]: 'landUseCode',
        [PROPERTY_COUNTY_DESCRIPTION]: 'countyLandUseDescription',
        [MARKET_VALUE]: 'valuation',
        [PROPERTY_TYPE]: 'propertyType',
    };

    yield put(apiRequest(payload, detailedProperty.overwriteMainFields, type));
    const { payload: { data: { name, value } = {} } = {} } = yield take(`${type} ${API_SUCCESS}`);

    if (name === PROPERTY_TYPE) {
        yield put(loadMainData({ countyId, parcelId, userId }));
    } else if (name !== PROPERTY_OCCUPANCY && name !== MARKET_VALUE) {
        yield put(updateOverviewData({ [map[name]]: value }));
    } else if (name === MARKET_VALUE) {
        yield put(updateAnalyticsData({ marketValue: { [map[name]]: value } }));
    } else {
        yield put(updateAnalyticsData({ [map[name]]: value }));
    }
}

function* loadPortfolioDocumentsSaga() {
    const { payload: { data } = {} } = yield take(setDocuments);
    const absentTypes = Object.keys(documentTypesFlags).filter((type) => !data.some((el) => el.type === type));
    yield all(
        absentTypes
            .map((type) =>
                put(
                    setDocumentFlag({
                        type,
                        value: false,
                    })
                )
            )
            .flat()
    );
}

function* reloadAnalyticsDataSaga({ payload }) {
    yield put(loadDocumentsData(payload));
    const documents = yield take(setDocumentsData);

    const {
        payload: { rentRollList, rentRollStatementCombined: { combinedOsid } = '', rentRollStatementList },
    } = documents;

    const analyticRequestData = {
        ...rrid(rentRollList),
        ...osid(rentRollStatementList),
        combinedOsid,
    };

    yield put(loadAnalyticsData(analyticRequestData));
}

function* getNotesDataSaga({ type, payload }) {
    yield put(apiRequest(payload, propertyNotesApi.getNotes, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { notes } = action.payload.data;
    yield put(setNotesData(notes));
}

function* saveNotesDataSaga({ type, payload }) {
    yield put(apiRequest(payload, propertyNotesApi.saveNote, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(setNotesData(payload.notes));
}

export function* watchOverview() {
    yield takeLatest(loadMainData, loadMainDataSaga);
    yield takeLatest(loadOverviewData, loadOverviewDataSaga);
    yield takeLatest(loadMarketData, loadMarketDataSaga);
    yield takeLatest(loadDocumentsData, loadDocumentsDataSaga);
    yield takeLatest(loadAnalyticsData, loadAnalyticsDataSaga);
    yield takeLatest(loadHistoryData, loadHistoryDataSaga);
    yield takeLatest(editBaseFields, editBaseFieldsSaga);
    yield takeLatest(editMainFields, editMainFieldsSaga);
    yield takeLatest(reloadAnalyticsData, reloadAnalyticsDataSaga);
    yield takeEvery(loadPortfolioDocuments, loadPortfolioDocumentsSaga);
    yield takeEvery(getNotesData, getNotesDataSaga);
    yield takeEvery(saveNotesData, saveNotesDataSaga);
}
