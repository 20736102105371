import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';

import InfoIcon from '@scc/assets/icons/info.svg';

import Skeleton from '@ui/Skeleton';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './score.module.scss';

function Score({ score: value, size, description, thickness, tooltip, className }) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const isNum = typeof value === 'number';
    const isLoaded = isNum || value;
    const score = isNum ? value : 0;
    const mehOrGood = value < 66 ? styles.meh : styles.good;
    const scoreTypeStyle = value < 33 ? styles.bad : mehOrGood;

    return (
        <div className={styles.scoreWrapper}>
            {isLoaded ? (
                <div
                    className={cx(styles.scoreBlock, className, {
                        [styles.category]: !isNum,
                        [scoreTypeStyle]: isNum,
                    })}
                >
                    <CircularProgress
                        className={cx(styles.bar, {
                            [scoreTypeStyle]: isNum,
                        })}
                        size={size}
                        thickness={thickness}
                        value={score}
                        variant='determinate'
                    />
                    <span className={styles.score}>{value}</span>
                </div>
            ) : (
                <Skeleton height={80} variant='circular' width={80} />
            )}

            {description && (
                <div className={styles.description}>
                    <Typography color={colors['grey-400']} variant='mdr'>
                        {description}
                    </Typography>
                    {isDesktop && (
                        <Tooltip title={tooltip} arrow>
                            <span>
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
}

Score.defaultProps = {
    score: 0,
    size: 80,
    thickness: 1,
};

export default Score;
