import { Link as RouterLink } from 'react-router-dom';

import Link from '@ui/Link';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './acceptTerms.module.scss';

const defaultProps = {
    termsLink: '',
    policyLink: '',
};

function AcceptTerms({ termsLink, policyLink }) {
    return (
        <div className={styles.agreement}>
            <Typography color={colors['grey-400']} component='p' variant='pr12'>
                By continuing, I agree to the
                <Link.Secondary
                    aria-label='Terms of Use'
                    color='secondary'
                    component={RouterLink}
                    size='small'
                    to={termsLink}
                >
                    <Typography color={colors['grey-400']} component='span' variant='pr12'>
                        Terms of Use
                    </Typography>
                </Link.Secondary>
                &
                <Link.Secondary
                    aria-label='Privacy Policy'
                    color='secondary'
                    component={RouterLink}
                    size='small'
                    to={policyLink}
                >
                    <Typography color={colors['grey-400']} component='span' variant='pr12'>
                        Privacy Policy
                    </Typography>
                </Link.Secondary>
            </Typography>
        </div>
    );
}

AcceptTerms.defaultProps = defaultProps;

export default AcceptTerms;
