import download from 'downloadjs';

import request from '@utils/request';

const BASE_URL = '/api/v2/property';

export async function shareLink({ email, address, link, message }) {
    return request(`${BASE_URL}/link/share`, {
        method: 'POST',
        body: JSON.stringify({ email, address, link, message }),
    });
}

export async function postScoring(body) {
    return request(`${BASE_URL}/scoring`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function getProperty(propertyId) {
    return request(`${BASE_URL}/${propertyId}`);
}

export async function addPropertyToLoan(data) {
    return request(BASE_URL, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function deletePropertyLoan({ loanDetailsId, propertyId }) {
    return request(`${BASE_URL}/${loanDetailsId}/${propertyId}`, {
        method: 'DELETE',
    });
}

export async function getPropertyLender({ size = 20, page = 0, sort, search, propertyType } = {}) {
    return request(`${BASE_URL}/lender`, {
        params: {
            size,
            page,
            sort,
            search,
            propertyType,
        },
    });
}

export async function updateExistingProperty({ systemId, payload }) {
    return request(`${BASE_URL}/${systemId}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
    });
}

export async function downloadExcel({ id, countyId, parcelId }) {
    return fetch(
        `${BASE_URL}/excel/export?modelId=${id}&countyId=${countyId}&parcelId=${parcelId?.replace('/', '_SLASH_')}`
    );
}

export async function downloadExcelFile({ id, countyId, parcelId }) {
    const res = await downloadExcel({ id, countyId, parcelId });
    const [, fileName] = res?.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await res.blob();
    download(blob, fileName);
}

export async function getPropertyLenderHistory({ size = 20, page = 0, sort } = {}) {
    return request(`${BASE_URL}/lender/history`, {
        params: {
            size,
            page,
            sort,
        },
    });
}

export async function deleteProperty({ id: loanId }) {
    return request(`${BASE_URL}/lender/${loanId}`, {
        method: 'DELETE',
    });
}

export async function restoreProperty({ id: loanId }) {
    return request(`${BASE_URL}/lender/${loanId}`, {
        method: 'POST',
    });
}

export async function createPropertyPortfolio(data) {
    return request(`${BASE_URL}/portfolio`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function updatePropertyPortfolio(data) {
    return request(`${BASE_URL}/portfolio`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function deletePropertyPortfolio(id) {
    return request(`${BASE_URL}/portfolio/${id}`, {
        method: 'DELETE',
    });
}

export async function getVarianceReports({ countyId, parcelId }) {
    return request(`${BASE_URL}/variance-report/${countyId}/${parcelId?.replace('/', '_SLASH_')}`);
}

export async function createVarianceReports(data) {
    return request(`${BASE_URL}/variance-report`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function updateVarianceReports(data) {
    return request(`${BASE_URL}/variance-report`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function deleteVarianceReport(id) {
    return request(`${BASE_URL}/variance-report/${id}`, {
        method: 'DELETE',
    });
}

export async function updateBuildings({ propertyTab, buildings }) {
    return request(`${BASE_URL}/update-buildings`, {
        method: 'PUT',
        params: { propertyTab },
        body: JSON.stringify(buildings),
    });
}
