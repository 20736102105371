import { put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { documentsApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[loanDocs]';
const size = 20;

const initialState = {
    data: [],
    search: {},
    totalCount: null,
    loaded: false,
    loading: false,
};

const documentsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadLoan(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadAll(state, action) {
            state.loaded = false;
            state.loading = true;
            state.data = [];
            state.search = action.payload.search;
        },
        loadMore() {},
        setData(state, action) {
            const { data, totalElements } = action.payload;
            state.data = data.map((item) => ({ ...item, shared: [] }));
            if (totalElements) {
                state.totalCount = totalElements;
            }
            state.loaded = true;
            state.loading = false;
        },
        addLoan(state) {
            state.loaded = false;
            state.loading = true;
        },
    },
});

export const {
    loadLoan: loadLoanDocuments,
    loadAll: loadAllDocuments,
    loadMore: loadMoreDocuments,
    addLoan: addLoanDocuments,
    setData: setDocuments,
} = documentsSlice.actions;

export default documentsSlice.reducer;

export const selectLoanDocumentsState = (store) => store.loans.documents;

function* loadLoanDocumentsSaga({ type, payload }) {
    yield put(apiRequest({ loanId: payload }, documentsApi.getLoanDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments(data));
}

function* loadAllDocumentsSaga({ type, payload }) {
    const { search } = payload;
    yield put(apiRequest({ ...search }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setDocuments({ data: content, totalCount: totalElements }));
}

function* loadMoreDocumentsSaga({ type }) {
    const { search, data } = yield select(selectLoanDocumentsState);
    yield put(apiRequest({ ...search, page: data.length / size, size }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setDocuments({ data: content, totalCount: totalElements }));
}

function* addLoanDocumentsSaga({ type, payload }) {
    yield put(apiRequest(payload, documentsApi.addToLoanDocument, type));
}

export function* watchLoanDocuments() {
    yield takeLatest(loadLoanDocuments, loadLoanDocumentsSaga);
    yield takeLatest(loadAllDocuments, loadAllDocumentsSaga);
    yield takeLatest(loadMoreDocuments, loadMoreDocumentsSaga);
    yield takeLatest(addLoanDocuments, addLoanDocumentsSaga);
}
