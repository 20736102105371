import { CustomContentProps } from 'notistack';
import { forwardRef } from 'react';

import { Snackbar } from '@scc/components/snackbar/Snackbar';

import colors from '@themes/palette/export.module.scss';

import InfoSvg from '@icons/outlined/info-circle.svg';

export const Attention = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    return <Snackbar ref={ref} {...props} color={colors['warning-main']} icon={InfoSvg} />;
});
