import Icon from '@ui/Icon';
import UserTickSvg from '@assets/icons/outlined/user-tick.svg';

import PartialSuccessLayout from '@scc/components/PartialSuccessLayout';

const RegisterFormSuccess = function() {
    return (
        <PartialSuccessLayout
            title='Excellent!'
            icon={<Icon component={UserTickSvg} color='text.disabled' fontSize={50} />}
            description={`Your account is verified`}
        />
    )
}

export default RegisterFormSuccess;