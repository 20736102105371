import request from '@utils/request';

const BASE_URL = '/api/v2';
const BASE_XLS_URL = '/api/v2/xls-export';
const BASE_URL_SLAT_XLS_EXPORT = '/api/v2/slat-xls-export';
const BASE_URL_COMBINED_XLS_EXPORT = '/api/v2/combined-export';

export async function getCombinedExcelExport({ template, parcelId, countyId, ...params }) {
    return request(BASE_URL_COMBINED_XLS_EXPORT, {
        method: 'POST',
        body: JSON.stringify({ ...params, items: template, parcelId, countyId }),
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function getSlatHeadersExcel(id) {
    return request(`${BASE_URL_SLAT_XLS_EXPORT}/headers/${id}`);
}

export async function getExcelDocuments({ countyId, parcelId, userId, loanDetailsId } = {}) {
    return request(`${BASE_XLS_URL}/documents`, {
        params: {
            countyId,
            parcelId,
            userId,
            loanDetailsId,
        },
    });
}

export async function getExcelTemplate({ page = 0, size = 20, sort } = {}) {
    return request(`${BASE_XLS_URL}/template`, {
        params: {
            page,
            size,
            sort,
        },
    });
}

export async function createExcelTemplate({ name, template } = {}) {
    return request(`${BASE_XLS_URL}/template`, {
        method: 'POST',
        body: JSON.stringify({
            name,
            templateJson: JSON.stringify(template),
        }),
    });
}

export async function updateExcelTemplate({ id, name, template } = {}) {
    return request(`${BASE_XLS_URL}/template`, {
        method: 'PUT',
        body: JSON.stringify({
            id,
            name,
            templateJson: JSON.stringify(template),
        }),
    });
}

export async function deleteExcelTemplate(templateId) {
    return request(`${BASE_XLS_URL}/template/${templateId}`, { method: 'DELETE' });
}

export async function getCustomScenarios(id) {
    return request(`${BASE_URL_SLAT_XLS_EXPORT}/scenarios/${id}`);
}

export async function getRentRollMetrics(propertyId) {
    return request(`${BASE_URL}/rentroll-metric/${propertyId}`);
}
