import colors from '@themes/palette/export.module.scss';

export const REQUEST_TYPES = {
    ANALYST: {
        value: 'ANALYST',
        label: 'Analyst',
    },
    VALUATION: {
        value: 'VALUATION',
        label: 'Valuation',
    },
    LOAN: {
        value: 'LOAN',
        label: 'Loan',
    },
    SPREADING: {
        value: 'SPREADING',
        label: 'Spreading',
    },
    ORDER: {
        value: 'ORDER',
        label: 'Order',
    },
};

export const REQUEST_STATUSES = {
    REQUEST_SUBMITTED: {
        value: 'REQUEST_SUBMITTED',
        label: 'Request Submitted',
        referLoanLabel: 'Under Review',
        color: colors['yellow-100'],
    },

    WAITING_STANDARDIZATION: {
        value: 'WAITING_STANDARDIZATION',
        label: 'Waiting Standardization',
        color: colors['lightBlue-500'],
    },
    STANDARDIZED_NOT_REVIEWED: {
        value: 'STANDARDIZED_NOT_REVIEWED',
        label: 'Standardized Not Reviewed',
        color: colors['lightBlue-500'],
    },
    IN_PROGRESS_BY_SC: {
        value: 'IN_PROGRESS_BY_SC',
        label: 'In Progress By SC',
        referLoanLabel: 'Smart Capital Center Underwriting',
        color: colors['blue-main'],
    },
    IN_PROGRESS_BY_USER: {
        value: 'IN_PROGRESS_BY_USER',
        label: 'In Progress By User',
        color: colors['blue-main'],
    },
    REQUEST_INFO: {
        value: 'REQUEST_INFO',
        label: 'Information Required',
        referLoanLabel: 'Smart Capital Center Underwriting',
        color: colors['yellow-500'],
    },
    COMPLETED: {
        value: 'COMPLETED',
        label: 'Completed',
        color: colors['green-500'],
    },
    REJECTED: {
        value: 'REJECTED',
        label: 'Rejected',
        color: colors['grey-150'],
    },
};

export const EXTENDED_REQUEST_STATUSES = {
    REQUEST_STARTED: {
        value: 'REQUEST_STARTED',
        label: 'Request Started',
        referLoanLabel: 'Under Review',
        color: colors['green-500'],
    },
    REQUEST_APPROVED: {
        value: 'REQUEST_APPROVED',
        label: 'Request Approved',
        referLoanLabel: 'Smart Capital Center Underwriting',
        color: colors['green-500'],
    },
};
