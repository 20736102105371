import { useDispatch } from 'react-redux';
import { Link as NavLink } from 'react-router-dom';

import links from '@scc/router/links';
import { cancelProperty } from '@scc/store/ducks/property';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Link from '@ui/Link';
import Typography from '@ui/Typography';

import CallSvg from '@assets/icons/outlined/call.svg';
import SmsSvg from '@assets/icons/outlined/sms.svg';
import TickCircleSvg from '@assets/icons/outlined/tick-circle.svg';

const TELEPHONE = typeof process !== 'undefined' && process.env.TELEPHONE;
const EMAIL = typeof process !== 'undefined' && process.env.EMAIL;

function OrderCreateSuccess() {
    const dispatch = useDispatch();
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Icon color='success' component={TickCircleSvg} fontSize={100} />
            <Typography variant='head2b' gutterBottm>
                You&apos;re all set!
            </Typography>
            <Typography variant='pr16' gutterBottom>
                We&apos;ve successfully received your property valuation order.
            </Typography>
            <Typography variant='pr16'>We will send you a confirmation email with the details shortly.</Typography>
            <Typography>Feel free to contact us</Typography>
            <Icon component={SmsSvg} fontSize='14px' />
            <Link.Secondary href={`mailto:${EMAIL}`}>
                <Typography variant='mdr'>{EMAIL}</Typography>
            </Link.Secondary>
            <p>
                <Icon component={CallSvg} fontSize='14px' />
                <Link.Secondary href={`tel:${TELEPHONE}`.replace(/ /g, '')}>
                    <Typography variant='mdr'>{TELEPHONE}</Typography>
                </Link.Secondary>
            </p>
            <Button component={NavLink} to={links.home.path} onClick={() => dispatch(cancelProperty())}>
                Done
            </Button>
        </Box>
    );
}

export default OrderCreateSuccess;
