import clsx from 'clsx';
import { ReactNode } from 'react';

import OutlinedInput from '@ui/OutlinedInput';

import styles from './styles.module.scss';

type TEditableTextFieldProps = {
    startAdornment?: ReactNode;
    [key: string]: unknown;
};

export function EditableTextField({ startAdornment, ...props }: TEditableTextFieldProps) {
    return (
        <OutlinedInput
            {...props}
            className={clsx(styles.editableTextInput, {
                [styles.editableTextInputWithAdornment]: startAdornment,
            })}
            startAdornment={startAdornment}
            autoFocus
        />
    );
}
