import { put, take, delay, takeLatest, select } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { referApi } from '@api';
import { hideDrawer, showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';
import { apiRequest, API_SUCCESS, API_ERROR } from '@store/ducks/api';
import { TEMPLATES } from '@shared/templates/forms/ReferBorrower';

import { selectUserInfo } from '@scc/store/ducks/auth';
import { REFER_FORM } from '@constants/modals';

const entity = '[refer]';

const initialState = {
    error: null,
    data: {},
    loading: false,
    template: TEMPLATES.MAIN,
    loaded: false,
    amount: 0,
};

const referSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        start(state, action) {
            state.amount = action.payload?.discount || 0;
            state.template = TEMPLATES.MAIN;
        },
        add(state) {
            state.loading = true;
            state.loaded = false;
        },
        update(state, action) {
            state.loading = false;
            state.loaded = true;
            state.data = action.payload;
        },
        setTemplate(state, action) {
            state.template = action.payload;
        },
    },
});

export const {
    start: startRefer,
    add: addRefer,
    update: updateRefer,
    setTemplate: setTemplateRefer,
} = referSlice.actions;

export default referSlice.reducer;

export const selectReferState = (state) => state.refer;

function* startReferSaga({ payload }) {
    yield put(showPrimaryAsideDrawer({ content: REFER_FORM, data: payload }));

    const action = yield take([`${addRefer} ${API_SUCCESS}`, `${addRefer} ${API_ERROR}`]);
    if (action.type.endsWith(API_SUCCESS)) {
        yield put(setTemplateRefer(TEMPLATES.SUCCESS));
        yield delay(4000);
        yield put(hideDrawer());
    }
}

function* addReferSaga({ payload, type }) {
    const { email } = yield select(selectUserInfo);
    yield put(apiRequest({ referrerEmail: email, ...payload }, referApi.addReferer, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(updateRefer(action.payload));
}

export function* watchRefer() {
    yield takeLatest(startRefer, startReferSaga);
    yield takeLatest(addRefer, addReferSaga);
}
