import cx from 'classnames';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function Badge({
    children,
    text,
    status,
    textColor = colors['grey-500'],
    textVariant = 'pr12',
    variant = 'primary',
    isCapitalize = false,
    className,
}) {
    const Component = isCapitalize ? Typography.Uppercase : Typography;
    return (
        <div
            className={cx(styles.wrapper, className, {
                [styles.status]: !!status,
                [styles.primary]: variant === 'primary',
                [styles.secondary]: variant === 'secondary',
            })}
        >
            <Component color={textColor} variant={textVariant}>
                {children || text}
            </Component>
        </div>
    );
}

export default Badge;
