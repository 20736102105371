import { forwardRef, useRef, useCallback, useEffect, useImperativeHandle } from 'react';

import { ON_MESSAGE_MC, INITIAL_CONNECTION_MC } from '@constants/connectorMC';
import MessageChannelConnector from '@utils/messageChannel';
import { emitCustomEvent } from '@shared/hooks/useCustomEvents';

const withMessageChannelIframe = (IframeComponent, options) => {
    const connector = new MessageChannelConnector(options);

    return forwardRef(({ onInit, onMessage, onClose, ...props }, ref) => {
        const iframe = useRef(null);

        const handlePostMessage = useCallback((event, data) => {
            emitCustomEvent(ON_MESSAGE_MC, data);
            onMessage?.(event, data);
        }, []);

        const handleInit = () => {
            if (iframe?.current) {
                connector.sendToWindow(INITIAL_CONNECTION_MC, iframe.current.contentWindow);
            }
        };
        useImperativeHandle(ref, () => iframe.current);

        useEffect(() => {
            iframe?.current?.addEventListener?.('load', handleInit);

            return () => {
                iframe?.current?.removeEventListener?.('load', handleInit);
            };
        }, [handleInit, iframe?.current]);

        useEffect(() => {
            connector.init({
                onInit,
                onMessage: handlePostMessage,
                onClose,
            });
            return () => {
                connector.close();
            };
        }, [onInit, handlePostMessage, onClose]);

        return <IframeComponent ref={iframe} {...options} {...props} />;
    });
};

export default withMessageChannelIframe;
