import { Box } from '@mui/material';
import clsx from 'clsx';

import ChevronIcon from '@scc/assets/icons/chevron.svg';
import { TMember } from '@scc/store/ducks/loans/types/members.types';

import Icon from '@ui/Icon';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

type LenderTriggerProps = {
    value: TMember;
    onClick: () => void;
    open: boolean;
};

function LenderTrigger({ value, onClick, open }: LenderTriggerProps) {
    const lenderName = value?.companyMember?.company?.name ?? '';

    return (
        <Box
            className={clsx(styles.trigger, {
                [styles.triggerOpen]: open,
            })}
            onClick={onClick}
        >
            <span>{lenderName}</span>
            <Icon className={clsx(styles.icon)} color={colors['grey-400']} component={ChevronIcon} fontSize={20} />
        </Box>
    );
}

export default LenderTrigger;
