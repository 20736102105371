/* eslint-disable */
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';

import Charts from '@utils/charts';
import { baseChartConfig } from '@utils/charts/configs';
import { fullSeriesInjection } from '@utils/charts/helpers';

export default class LoanPortfolioCharts extends Charts {
    addData(data) {
        this.data = data || null;
        this.portfolio = this.data?.portfolio || null;
        this.loanStates = this.data?.loanStates || null;
        this.osData = this.data?.portfolio?.osData || null;
        this.tenants = this.data?.portfolio?.tenants || null;
    }

    getRevenue(customConfig, grouping) {
        return super.revenue(customConfig, grouping, this.osData);
    }

    getNoi(customConfig, grouping) {
        return super.noi(customConfig, grouping, this.osData);
    }

    getNoiMargin(customConfig, grouping) {
        return super.noiMargin(customConfig, grouping, this.osData);
    }

    getGeography(customConfig = {}) {
        if (!this.loanStates) return null;

        const sortedStates = orderBy(this.loanStates, ['principal'], ['desc']);
        const allStates = sortedStates.reduce(
            (acc, { principal }) => {
                acc.principal += principal;
                return acc;
            },
            {
                state: 'ALL',
                principal: 0,
            }
        );
        const withAll = [allStates, ...sortedStates].reverse();

        const config = merge(cloneDeep(baseChartConfig({ isHorizontal: true })), customConfig);

        const data = withAll.reduce(
            (acc, { state, principal }) => {
                acc.data.data.push(principal);
                acc.yAxis.push(state);
                return acc;
            },
            {
                data: {
                    type: 'bar',
                    barWidth: 16,
                    data: [],
                },
                yAxis: [],
            }
        );

        return fullSeriesInjection(config, data, 'yAxis');
    }

    getLeaseExpirationsSF(customConfig = {}) {
        return super.multipleLeaseExpirationsSF(
            customConfig,
            this.tenants && this.portfolio.properties
                ? { tenants: this.tenants, properties: this.portfolio.properties }
                : null
        );
    }

    getLeaseExpirationsRent(customConfig = {}) {
        return super.multipleLeaseExpirationsRent(
            customConfig,
            this.tenants && this.portfolio.properties
                ? {
                      tenants: this.tenants,
                      properties: this.portfolio.properties,
                  }
                : null
        );
    }
}
