import loadable from '@loadable/component';
import React from 'react';

import { loadData, saveData } from '@utils/browser';

import Noop from '@components/Noop';

import config from '@config';

const PAGE_HAS_BEEN_RELOADED = 'pageHasBeenReloaded';

export const createBaseProxy = <
    T extends {
        children?: React.ReactNode;
    } = Record<string, unknown>,
    R = Record<string, unknown>
>(
    Component,
    name = 'ProxyComponent',
    options = {}
) => {
    const Proxy = React.forwardRef<R, T>(({ children, ...props }, ref) => (
        <Component {...options} {...props} ref={ref}>
            {children}
        </Component>
    ));
    Proxy.displayName = name;
    return Proxy;
};

// eslint-disable-next-line consistent-return
export const findValueByPrefix = (map, prefix) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [prop] of map) {
        if (map.has(prop) && String(prop).startsWith(prefix)) {
            return map.get(prop);
        }
    }
};

export const getMapValue = (map, key, defaultValue, based) =>
    (based && findValueByPrefix(map, based)) || map.get(key) || map.get(defaultValue) || null;

export const createComponentsMap = (object = {}, defaultTemplate) => {
    const originalMap = new Map(Object.entries(object));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ({ exclude, template, based } = {}) => {
        const map = new Map(originalMap);
        if (exclude?.length) {
            exclude.forEach((key) => map.delete(key));
        }
        const Component = getMapValue(map, template, defaultTemplate, based) || Noop;

        return [Component, Array.from(map?.keys()), map];
    };
};

export const reloadable = (componentImport, options = {}) =>
    loadable(async () => {
        const hasReloaded = loadData(PAGE_HAS_BEEN_RELOADED);
        try {
            const component = await componentImport();
            saveData(PAGE_HAS_BEEN_RELOADED, false);
            return component;
        } catch (error) {
            if (!hasReloaded) {
                saveData(PAGE_HAS_BEEN_RELOADED, true);
                if (typeof window !== 'undefined' && !config.get('isDevMode')) {
                    return window.location.reload();
                }
            }
            throw error;
        }
    }, options);

export const addSelectMutliOptions = (value) =>
    value ? [].concat(typeof value === 'string' ? value?.split(',') : value) : [];

export const shouldRenderValue = (value) => value !== null && value !== undefined && value !== false;
