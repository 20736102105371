import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest, select } from 'redux-saga/effects';

import { userPermissionApi } from '@api';
import { API_ERROR, API_REQUEST, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[configuration/teams]';

const initialState = {
    error: null,
    data: [],
    totalElements: 0,
    loaded: false,
    loading: false,
};

const teamsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadTeams() {},
        setTeams(state, action) {
            state.data = action.payload;
        },
        setTotalElementsTeams(state, action) {
            state.totalElements = action.payload;
        },
        resetTeams() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.startsWith(entity) && action.type.endsWith(API_REQUEST),
                (state) => {
                    state.loading = true;
                }
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(entity) &&
                    (action.type.endsWith(API_SUCCESS) || action.type.endsWith(API_ERROR)),
                (state) => {
                    state.loading = false;
                }
            );
    },
});

export const { loadTeams, setTeams, setTotalElementsTeams, resetTeams } = teamsSlice.actions;

export default teamsSlice.reducer;

export const selectTeamsState = (store) => store.configuration.teams;

function* loadTeamsSaga({ type, payload }) {
    yield put(apiRequest(payload, userPermissionApi.getGroups, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    if (payload.page > 0) {
        const { data: prevData } = yield select(selectTeamsState);

        yield put(setTeams([...prevData, ...action.payload.data.content]));
    } else {
        yield put(setTeams(action.payload.data.content));
    }

    yield put(setTotalElementsTeams(action.payload.data.totalElements));
}

export function* watchTeams() {
    yield takeLatest(loadTeams, loadTeamsSaga);
}
