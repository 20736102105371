import request from '@utils/request';

const BASE_USER_REFER_URL = '/api/v2/refer';
const BASE_LOAN_APPLICATION_URL = '/api/v2/refer-loan';

export async function addReferer({
    referrerEmail = '',
    referralEmail = '',
    firstName = '',
    lastName = '',
    phone = '',
    amount = 0,
} = {}) {
    return request(`${BASE_USER_REFER_URL}/new`, {
        method: 'POST',
        body: JSON.stringify({
            referrerEmail,
            referralEmail,
            firstName,
            lastName,
            phone,
            amount,
        }),
    });
}

export async function postRefer({ data, id } = {}) {
    const postReferUrl = `${BASE_LOAN_APPLICATION_URL}/${id ?? ''}`;

    return request(postReferUrl, {
        method: 'POST',
        headers: {},
        body: data,
    });
}
