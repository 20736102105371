const breadcrumbs = () => ({
    MuiBreadcrumbs: {
        styleOverrides: {
            root: {
                color: '#E2E3E9',
            },
            li: {
                a: {
                    fontSize: 12,
                    color: '#8990A9',
                },
            },
        },
    },
});
export default breadcrumbs;
