import DialogContent from '@mui/material/DialogContent';
import Fade from '@mui/material/Fade';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppBar from '@ui/AppBar';
import Dialog from '@ui/Dialog';
import Toolbar from '@ui/Toolbar';

import styles from './contentDialog.module.scss';
import TitleContentDialog from './TitleContentDialog';

const defaultProps = {
    open: true,
    state: {},
    children: null,
    template: {},
};

const Transition = React.forwardRef((props, ref) => <Fade ref={ref} timeout={500} {...props} />);

function ContentDialog({
    open,
    onClose,
    noBack,
    state,
    paperProps,
    label,
    header: Header,
    children,
    template,
    className,
}) {
    const { empty } = state;
    const history = useHistory();
    const [isOpen, setOpen] = useState(open);
    const descriptionElementRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
        if (noBack) return;
        if (history.location.key) {
            history.goBack();
        } else {
            history.push(template.closeLink ?? '/');
        }
    };

    const titleDialog = !empty
        ? (Header && <Header title={label} onClose={handleClose} />) || (
              <AppBar className={styles.header} color='transparent' position='sticky'>
                  <Toolbar>
                      <TitleContentDialog onClose={handleClose} />
                  </Toolbar>
              </AppBar>
          )
        : null;

    const child = React.cloneElement(children, {
        dialogProps: {
            onClose: handleClose,
        },
    });

    React.useEffect(() => {
        if (isOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isOpen]);

    return (
        <Dialog PaperProps={paperProps} TransitionComponent={Transition} open={isOpen} fullScreen onClose={handleClose}>
            <DialogContent className={className} ref={descriptionElementRef} tabIndex={-1}>
                {titleDialog}
                {child}
            </DialogContent>
        </Dialog>
    );
}

ContentDialog.defaultProps = defaultProps;

export default ContentDialog;
