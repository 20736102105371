import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import { useState } from 'react';

import useUploadRentRoll from '@scc/modules/DropZone/hooks/useUploadRentRoll';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import TickCircleSvg from '@icons/filled/tick-circle.svg';
import DeleteSvg from '@icons/filled/trash.svg';
import CloseCircleSvg from '@icons/outlined/close-circle.svg';
import DocumentUploadSvg from '@icons/outlined/document-1.svg';

import styles from './styles.module.scss';

export default function MobileUpload({ initialFiles = [], both, docs = [], type, params = {} }) {
    const [hovered, setHovered] = useState(false);
    const [isSwiped, setIsSwiped] = useState(new Set([]));
    const [touchStart, setTouchStart] = useState(null);
    const minSwipeDistance = 10;

    const toggleHover = (id) => () => setHovered(id);

    const [{ documents, loading }, { getRootProps, getInputProps, removeFile }] = useUploadRentRoll({
        docs,
        type,
        params,
    });

    return (
        <div className={styles.container}>
            <input {...getInputProps()} />

            <div className={styles.filesList}>
                <ItemList items={(both || !documents.length) && initialFiles}>
                    {(file) => (
                        <Typography key={file.id} className={styles.emptyItem} variant='pr14'>
                            <Icon component={DocumentUploadSvg} />
                            {file.name}
                        </Typography>
                    )}
                </ItemList>
                <ItemList className={styles.filesList} items={documents}>
                    {({ osid, rrid, name, lastModified, rentRollFileTimestamp, statementsFileTimestamp }) => {
                        const fileId = osid || rrid;
                        const timestamp = lastModified || rentRollFileTimestamp || statementsFileTimestamp;
                        const isHovered = hovered === fileId;
                        return (
                            <Typography
                                key={fileId}
                                className={styles.item}
                                variant='pr14'
                                onTouchMove={(e) => {
                                    const swipeDistance = touchStart - e.targetTouches[0].clientX;
                                    if (swipeDistance > minSwipeDistance) {
                                        setIsSwiped(new Set(isSwiped.add(fileId)));
                                    } else if (swipeDistance < -minSwipeDistance && isSwiped.delete(fileId)) {
                                        setIsSwiped(new Set(isSwiped));
                                    }
                                }}
                                onTouchStart={(e) => setTouchStart(e.targetTouches[0].clientX)}
                            >
                                <span className={cx(styles.documentName, { [styles.swiped]: isSwiped.has(fileId) })}>
                                    <Icon className={styles.icon} color='inherit' component={DocumentUploadSvg} />
                                    {name}
                                </span>
                                {loading === timestamp ? (
                                    <CircularProgress size={14} />
                                ) : (
                                    <div className={styles.deleteButtonContainer}>
                                        <IconButton
                                            onClick={removeFile(fileId, timestamp)}
                                            onMouseEnter={toggleHover(fileId)}
                                            onMouseLeave={toggleHover(false)}
                                        >
                                            <Icon
                                                color={isHovered ? 'error' : 'success'}
                                                component={isHovered ? CloseCircleSvg : TickCircleSvg}
                                                fontSize='14px'
                                            />
                                        </IconButton>
                                        <IconButton
                                            classes={{
                                                root: cx(styles.deleteButton, {
                                                    [styles.deleteButtonVisible]: isSwiped.has(fileId),
                                                }),
                                            }}
                                            onClick={removeFile(fileId, timestamp)}
                                        >
                                            <Icon color='secondary' component={DeleteSvg} fontSize='14px' />
                                        </IconButton>
                                    </div>
                                )}
                            </Typography>
                        );
                    }}
                </ItemList>
            </div>

            <Button variant='outlined' fullWidth onClick={getRootProps().onClick}>
                Select File
            </Button>
        </div>
    );
}
