import DefaultLogoIcon from '@icons/filled/logo.svg';
import ShortLogoIcon from '@icons/filled/small-logo.svg';
import LogoIcon from '@icons/outlined/logo.svg';

const DEFAULT_SIZES = {
    width: 106,
    height: 43,
};

const DEFAULT_SHORT_SIZES = {
    width: 31,
    height: 31,
};

const FLUID_SHORT_SIZES = {
    width: '100%',
    height: '100%',
};

export const SHORT_SIZES = {
    default: { ...DEFAULT_SIZES },
    small: {
        ...DEFAULT_SHORT_SIZES,
        subtitle: false,
    },
    medium: {
        ...DEFAULT_SHORT_SIZES,
        subtitle: false,
    },
    large: {
        width: 159,
        height: 54,
        subtitle: false,
    },
    fluid: {
        ...FLUID_SHORT_SIZES,
        subtitle: false,
    },
} as const;

export const SIZES = {
    default: { ...DEFAULT_SIZES },
    small: {
        width: 79,
        height: 17,
        subtitle: false,
    },
    medium: {
        ...DEFAULT_SIZES,
        nameSize: 9.5,
        gap: 2,
        subtitle: true,
    },
    large: {
        width: 236,
        height: 49,
        nameSize: 11,
        gap: 3,
        subtitle: true,
    },
} as const;

export const LOGOS = {
    default: {
        component: DefaultLogoIcon,
        viewBox: `0 0 ${DEFAULT_SIZES.width} ${DEFAULT_SIZES.height}`,
    },
    short: {
        component: ShortLogoIcon,
        viewBox: `0 0 ${DEFAULT_SHORT_SIZES.width} ${DEFAULT_SHORT_SIZES.height}`,
    },
    long: {
        component: LogoIcon,
        viewBox: `0 0 ${DEFAULT_SIZES.width} ${DEFAULT_SIZES.height}`,
    },
} as const;
