import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { loadRequestDemo, selectDemoState } from '@scc/store/ducks/demo';

import Button from '@ui/Button';

import BaseForm from '@components/BaseForm';

import { INPUTS } from './helpers';
import styles from './styles.module.scss';
import schema from './validationSchema';

const formId = 'RequestDemoForm';

function RequestDemoForm() {
    const dispatch = useDispatch();
    const { loading } = useSelector(selectDemoState);

    const onSubmit = async (data) => {
        dispatch(loadRequestDemo(data));
    };

    return (
        <Stack className={styles.formWrapper} direction='column' justifyContent='space-between'>
            <div className={styles.container}>
                <BaseForm id={formId} inputs={INPUTS} schema={schema} onSubmit={onSubmit} />
            </div>

            <Button color='primary' form={formId} loading={loading} size='extra-large' type='submit' fullWidth>
                Send
            </Button>
        </Stack>
    );
}

export default RequestDemoForm;
