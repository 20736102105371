import colors from '@themes/palette/export.module.scss';

const switchToggle = () => ({
    MuiSwitch: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                width: 24,
                height: 16,
                padding: 0,
            },
            thumb: {
                boxSizing: 'border-box',
                width: 12,
                height: 12,
            },
            track: {
                borderRadius: 24 / 2,
                backgroundColor: '#C4C4C4',
                opacity: 1,
            },
            switchBase: {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(8px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: colors['blue-main'],
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: colors['blue-main'],
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: colors['blue-main'],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.7,
                },
            },
            sizeSmall: {
                width: 16,
                height: 10,
                '& .MuiSwitch-thumb': {
                    width: 6,
                    height: 6,
                },
                '& .MuiSwitch-switchBase': {
                    padding: 0,
                },
                '& .Mui-checked': {
                    transform: 'translateX(6px) !important',
                },
            },
        },
    },
});

export default switchToggle;
