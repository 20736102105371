import router from '@scc/router/links';

export default {
    services: [
        {
            ...router.services.routes.valuation,
            description: 'Don’t second-guess your investment decisions. Our independent valuations will help you negotiate a fair deal. Get a comprehensive report with property performance and hyper-local market data within a few hours.',
        },
        {
            ...router.services.routes.financing,
            description: 'Don’t lose money on bad loan rates. We negotiate the best and most affordable loan rates for you from our pool of 1,000+ lenders, so you can invest that savings elsewhere.',
        },
        {
            ...router.services.routes.monitoring,
            description: 'Our smart and intuitive software continually monitors your investments so you can preserve your wealth.',
        }
    ]
}