import cx from 'classnames';
import { forwardRef } from 'react';

import { homeData } from '@scc/pages/Home/homeData';

import Container from '@ui/Container';
import Typography from '@ui/Typography';

import Image from '@components/Image';

import colors from '@themes/palette/export.module.scss';

import animationStyles from '../../animation.module.scss';

import styles from './styles.module.scss';

const {
    mission: { title, description, image },
} = homeData;

const Mission = forwardRef(({ mobile }, ref) => {
    return (
        <div className={cx('mission', styles.mission, animationStyles.mission)} ref={ref}>
            <Image
                alt={title}
                className={cx(styles.bgImage, animationStyles.bgImage)}
                loading='lazy'
                sizes={`${image.src?.width}px`}
                src={image.src.src}
                srcSet={image.src.srcSet}
                webp={image.webp.srcSet}
                width={image.src?.width}
            />
            <div className={cx(styles.backdropContainer, animationStyles.backdropContainer)}>
                <Container maxWidth='xl'>
                    <div className={styles.descriptionContainer}>
                        <Typography className={styles.title} color={colors.white} variant={mobile ? 'h1b' : 'head2b'}>
                            {title}
                        </Typography>
                        <Typography color={colors.white} variant={mobile ? 'h4r' : 'h2r'}>
                            {description}
                        </Typography>
                    </div>
                </Container>
            </div>
        </div>
    );
});

export default Mission;
