import styles from '@scc/pages/Auth/styles.module.scss';
import Logo from '@scc/components/Logo';
import { reloadable } from '@utils/components';

const CommentsSlider = reloadable(() => import('@scc/components/CommentsSlider'), { ssr: false });

const AuthComments = function () {
    return (
        <div className={styles.commentsWrapper}>
            <Logo type='link' />
            <CommentsSlider
                align='left'
                className={styles.comments}
                commentOption={{ hideShowMore: true, column: false, hideQuotes: true, size: 'lg' }}
            />
        </div>
    );
};

export default AuthComments;
