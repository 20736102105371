import request from '@utils/request';

const BASE_URL = '/api/v2/notifications';

export const PAGE_SIZE = 10;

export async function markAsRead({ userId, notificationId }) {
    return request(`${BASE_URL}/${userId}/${notificationId}`, {
        method: 'POST',
    });
}

export async function markAllAsRead({ userId }) {
    return request(`${BASE_URL}/mark-all-as-read/${userId}`, {
        method: 'POST',
    });
}

export async function getAll({ userId, page = 0, size = PAGE_SIZE, sort }) {
    return request(`${BASE_URL}/${userId}`, {
        params: {
            page,
            size,
            sort,
        },
    });
}

export async function getUnread({ userId, page = 0, size = PAGE_SIZE, sort }) {
    return request(`${BASE_URL}/unread/${userId}`, {
        params: {
            page,
            size,
            sort,
        },
    });
}
