import MuiInput from '@mui/material/Input';
import MuiSelect from '@mui/material/Select';
import { forwardRef } from 'react';

import { createBaseProxy } from '@utils/components';

import Input from '@ui/Input';

export { OutlinedSelect } from './OutlinedSelect';

const Select = createBaseProxy(MuiSelect, 'Select');
Select.Primary = createBaseProxy(MuiSelect, 'Select', {
    input: (
        <MuiInput
            inputComponent={forwardRef((props, ref) => {
                return <Input ref={ref} {...props} />;
            })}
        />
    ),
});

export default Select;
