import { capitalize } from '@utils/index';

import { DOCUMENT_TYPES } from '@components/DataTable/constants';

import colors from '@themes/palette/export.module.scss';

import AgriculturalSvg from '@icons/filled/agricultural.svg';
import ApartmentSvg from '@icons/filled/apartment.svg';
import HealthcareSvg from '@icons/filled/healthcare.svg';
import IndustrialSvg from '@icons/filled/industrial.svg';
import OtherSvg from '@icons/filled/key.svg';
import LandSvg from '@icons/filled/land.svg';
import LodgingSvg from '@icons/filled/lodging.svg';
import MixeduseSvg from '@icons/filled/mixed.svg';
import MobilehomeSvg from '@icons/filled/mobilehome.svg';
import MultifamilySvg from '@icons/filled/multifamily.svg';
import NoTypeSvg from '@icons/filled/no-type.svg';
import OfficeSvg from '@icons/filled/office.svg';
import RetailSvg from '@icons/filled/retail.svg';
import SelfStorageSvg from '@icons/filled/selfstorage.svg';
import SeniorhousingSvg from '@icons/filled/seniorhousing.svg';
import SinglefamilySvg from '@icons/filled/singlefamily.svg';
import StudenthousingSvg from '@icons/filled/studenthousing.svg';
import WarehouseSvg from '@icons/filled/warehouse.svg';
import AgriculturalContentSvg from '@icons/maps/propertyTypes/agricultural.inline.svg';
import ApartmentContentSvg from '@icons/maps/propertyTypes/apartment.inline.svg';
import HealthcareContentSvg from '@icons/maps/propertyTypes/healthcare.inline.svg';
import IndustrialContentSvg from '@icons/maps/propertyTypes/industrial.inline.svg';
import OtherContentSvg from '@icons/maps/propertyTypes/key.inline.svg';
import LandContentSvg from '@icons/maps/propertyTypes/land.inline.svg';
import LodgingContentSvg from '@icons/maps/propertyTypes/lodging.inline.svg';
import MixeduseContentSvg from '@icons/maps/propertyTypes/mixed.inline.svg';
import MobilehomeContentSvg from '@icons/maps/propertyTypes/mobilehome.inline.svg';
import MultifamilyContentSvg from '@icons/maps/propertyTypes/multifamily.inline.svg';
import NoTypeContentSvg from '@icons/maps/propertyTypes/no-type.inline.svg';
import OfficeContentSvg from '@icons/maps/propertyTypes/office.inline.svg';
import RetailContentSvg from '@icons/maps/propertyTypes/retail.inline.svg';
import SelfStorageContentSvg from '@icons/maps/propertyTypes/selfstorage.inline.svg';
import SeniorhousingContentSvg from '@icons/maps/propertyTypes/seniorhousing.inline.svg';
import SinglefamilyContentSvg from '@icons/maps/propertyTypes/singlefamily.inline.svg';
import StudenthousingContentSvg from '@icons/maps/propertyTypes/studenthousing.inline.svg';
import WarehouseContentSvg from '@icons/maps/propertyTypes/warehouse.inline.svg';
import DocumentType from '@shared/components/DocumentType';

export const propertyTypes = {
    OFFICE: 'Office',
    RETAIL: 'Retail',
    MULTIFAMILY: 'Multi-Family',
    MOBILE_HOME: 'Mobile Home',
    INDUSTRIAL: 'Industrial',
    SELF_STORAGE: 'Self Storage',
    SINGLE_FAMILY: 'Single Family',
    LODGING: 'Lodging',
    SENIOR_HOUSING: 'Senior Housing',
    STUDENT_HOUSING: 'Student Housing',
    MIXED_USE: 'Mixed Use',
    VACANT_LAND: 'Vacant Land',
    APARTMENT: 'Apartment',
    WAREHOUSE: 'Warehouse',
    HEALTHCARE: 'Healthcare',
    AGRICULTURAL: 'Agricultural',
    OTHER: 'Other',
    NO_TYPE: 'No Type',
};

export const mapPropertyTypes = {
    Office: propertyTypes.OFFICE,
    Retail: propertyTypes.RETAIL,
    'Multi-Family': propertyTypes.MULTIFAMILY,
    Multifamily: propertyTypes.MULTIFAMILY,
    'Mobile Home': propertyTypes.MOBILE_HOME,
    Industrial: propertyTypes.INDUSTRIAL,
    'Self Storage': propertyTypes.SELF_STORAGE,
    'Single Family': propertyTypes.SINGLE_FAMILY,
    Lodging: propertyTypes.LODGING,
    Hospitality: propertyTypes.LODGING,
    'Senior Housing': propertyTypes.SENIOR_HOUSING,
    'Student Housing': propertyTypes.STUDENT_HOUSING,
    'Mixed Use': propertyTypes.MIXED_USE,
    'Vacant Land': propertyTypes.VACANT_LAND,
    Apartment: propertyTypes.APARTMENT,
    Warehouse: propertyTypes.WAREHOUSE,
    Healthcare: propertyTypes.HEALTHCARE,
    Agricultural: propertyTypes.AGRICULTURAL,
    Other: propertyTypes.OTHER,
    'No Type': propertyTypes.NO_TYPE,
};

export const PROPERTY_TYPES_PROPS = {
    [propertyTypes.OFFICE]: {
        size: 'size',
        fields: [
            { name: 'size', prefix: 'SF' },
            { name: 'sqft', prefix: 'SF' },
        ],
    },
    [propertyTypes.MIXED_USE]: {
        size: 'size',
        fields: [{ name: 'size' }],
    },
    [propertyTypes.APARTMENT]: {
        size: 'size',
        fields: [{ name: 'size' }],
    },
    [propertyTypes.WAREHOUSE]: {
        size: 'size',
        fields: [{ name: 'size' }],
    },
    [propertyTypes.HEALTHCARE]: {
        size: 'size',
        fields: [{ name: 'size' }],
    },
    [propertyTypes.RETAIL]: {
        size: 'size',
        fields: [{ name: 'size' }, { name: 'sqft', prefix: 'SF' }],
    },
    [propertyTypes.INDUSTRIAL]: {
        size: 'size',
        fields: [
            { name: 'size', prefix: 'SF' },
            { name: 'sqft', prefix: 'SF' },
        ],
    },
    [propertyTypes.MULTIFAMILY]: {
        size: 'units',
        fields: [
            { name: 'size', prefix: 'SF' },
            { name: 'sqft', prefix: 'SF' },
            { name: 'units', prefix: 'Units' },
        ],
    },
    [propertyTypes.LODGING]: {
        size: 'units',
        fields: [
            { name: 'size', prefix: 'SF' },
            { name: 'sqft', prefix: 'SF' },
            { name: 'units', prefix: 'Units' },
        ],
    },
    [propertyTypes.SINGLE_FAMILY]: {
        size: 'size',
        bedrooms: 'bedrooms',
        bathrooms: 'bathrooms',
        units: 'units',
        lotSize: 'lotSize',
        buildYear: 'buildYear',
        fields: [
            { name: 'size', prefix: 'SF' },
            { name: 'units', prefix: 'Units' },
            { name: 'sqft', prefix: 'SF' },
            { name: 'beds', prefix: 'Beds' },
            { name: 'baths', prefix: 'Bath' },
        ],
    },
    [propertyTypes.SELF_STORAGE]: {
        size: 'units',
        units: 'units',
        lotSize: 'lotSize',
        fields: [{ name: 'units', prefix: 'Units' }],
    },
    [propertyTypes.VACANT_LAND]: {
        size: 'lotsize',
        lotSize: 'lotSize',
        fields: [{ name: 'lotsize', prefix: 'SF' }],
    },
    [propertyTypes.AGRICULTURAL]: {
        size: 'lotsize',
        lotSize: 'lotSize',
        fields: [{ name: 'lotsize', prefix: 'SF' }],
    },
};

export const PROPERTY_TYPES = [
    {
        label: 'Office',
        type: 'Office',
        value: 'Office',
    },
    {
        label: 'Retail',
        type: 'Retail',
        value: 'Retail',
    },
    {
        label: 'Multifamily',
        type: 'Multi-Family',
        value: 'Multi-Family',
    },
    {
        label: 'Mobile Home',
        type: 'Mobile Home',
        value: 'Mobile Home',
    },
    {
        label: 'Industrial',
        type: 'Industrial',
        value: 'Industrial',
    },
    {
        label: 'Self Storage',
        type: 'Self Storage',
        value: 'Self Storage',
    },
    {
        label: 'Single Family',
        type: 'Single Family',
        value: 'Single Family',
    },
    {
        label: 'Lodging',
        type: 'Lodging',
        value: 'Lodging',
    },
    {
        label: 'Senior Housing',
        type: 'Senior Housing',
        value: 'Senior Housing',
    },
    {
        label: 'Student Housing',
        type: 'Student Housing',
        value: 'Student Housing',
    },
    {
        label: 'Vacant Land',
        type: 'Vacant Land',
        value: 'Vacant Land',
    },
    {
        label: 'Agricultural',
        type: 'Agricultural',
        value: 'Agricultural',
    },
    {
        label: 'Apartment',
        type: 'Apartment',
        value: 'Apartment',
    },
    {
        label: 'Warehouse',
        type: 'Warehouse',
        value: 'Warehouse',
    },
    {
        label: 'Healthcare',
        type: 'Healthcare',
        value: 'Healthcare',
    },
    {
        label: 'Mixed Use',
        type: 'Mixed Use',
        value: 'Mixed Use',
    },
    {
        label: 'Other',
        type: 'Other',
        value: 'Other',
    },
];

export const simplifyPropertyTypeName = (propertyType) => propertyType?.toLowerCase()?.replace(/\s|-|_/gi, '');

const AGRICULTURAL_PROPERTIES = new Set([propertyTypes.AGRICULTURAL, propertyTypes.VACANT_LAND]);

export const isAgroProperty = (propertyType) => AGRICULTURAL_PROPERTIES.has(propertyType);

export const isMFLike = (
    propertyType = '',
    mfType = ['multifamily', 'mobilehome', 'lodging', 'seniorhousing', 'studenthousing', 'selfstorage']
) => mfType.includes(simplifyPropertyTypeName(propertyType));

export const getDocumentTypesList = (props = {}) =>
    Object.values(DOCUMENT_TYPES).map(({ name, type, value = type, label }) => ({
        id: name,
        value,
        label,
        component: DocumentType,
        componentProps: { type },
        ...props,
    }));

export const PROPERTY_TYPES_CONFIG = {
    [propertyTypes.OFFICE]: {
        wrapperColor: colors['propertyType-office'],
        iconColor: colors.white,
        icon: OfficeSvg,
        displayLabel: 'Office',
        path: 'O',
    },
    [propertyTypes.RETAIL]: {
        wrapperColor: colors['propertyType-retail'],
        iconColor: colors.white,
        icon: RetailSvg,
        displayLabel: 'Retail',
        path: 'R',
    },
    [propertyTypes.MULTIFAMILY]: {
        wrapperColor: colors['propertyType-multifamily'],
        iconColor: colors.white,
        icon: MultifamilySvg,
        displayLabel: 'Multi-Family',
        path: 'MF',
    },
    [propertyTypes.MOBILE_HOME]: {
        wrapperColor: colors['propertyType-mobilehome'],
        iconColor: colors.white,
        icon: MobilehomeSvg,
        displayLabel: 'Mobile Home',
        path: 'MH',
    },
    [propertyTypes.INDUSTRIAL]: {
        wrapperColor: colors['propertyType-industrial'],
        iconColor: colors.white,
        icon: IndustrialSvg,
        displayLabel: 'Industrial',
        path: 'I',
    },
    [propertyTypes.SELF_STORAGE]: {
        wrapperColor: colors['propertyType-selfstorage'],
        iconColor: colors.white,
        icon: SelfStorageSvg,
        displayLabel: 'Self Storage',
        path: 'SS',
    },
    [propertyTypes.SINGLE_FAMILY]: {
        wrapperColor: colors['propertyType-singlefamily'],
        iconColor: colors.white,
        icon: SinglefamilySvg,
        displayLabel: 'Single Family',
        path: 'SF',
    },
    [propertyTypes.LODGING]: {
        wrapperColor: colors['propertyType-lodging'],
        iconColor: colors.white,
        icon: LodgingSvg,
        displayLabel: 'Lodging',
        path: 'L',
    },
    [propertyTypes.SENIOR_HOUSING]: {
        wrapperColor: colors['propertyType-seniorhousing'],
        iconColor: colors.white,
        icon: SeniorhousingSvg,
        displayLabel: 'Senior Housing',
        path: 'SH',
    },
    [propertyTypes.STUDENT_HOUSING]: {
        wrapperColor: colors['propertyType-studenthousing'],
        iconColor: colors.white,
        icon: StudenthousingSvg,
        displayLabel: 'Student Housing',
        path: 'STH',
    },
    [propertyTypes.VACANT_LAND]: {
        wrapperColor: colors['propertyType-land'],
        iconColor: colors.white,
        icon: LandSvg,
        displayLabel: 'Vacant Land',
        path: 'VL',
    },
    [propertyTypes.AGRICULTURAL]: {
        wrapperColor: colors['propertyType-agricultural'],
        iconColor: colors.white,
        icon: AgriculturalSvg,
        displayLabel: 'Agricultural',
        path: 'A',
    },
    [propertyTypes.APARTMENT]: {
        wrapperColor: colors['propertyType-apartment'],
        iconColor: colors.white,
        icon: ApartmentSvg,
        displayLabel: 'Apartment',
        path: 'APT',
    },
    [propertyTypes.WAREHOUSE]: {
        wrapperColor: colors['propertyType-warehouse'],
        iconColor: colors.white,
        icon: WarehouseSvg,
        displayLabel: 'Warehouse',
        path: 'W',
    },
    [propertyTypes.HEALTHCARE]: {
        wrapperColor: colors['propertyType-healthcare'],
        iconColor: colors.white,
        icon: HealthcareSvg,
        displayLabel: 'Healthcare',
        path: 'H',
    },
    [propertyTypes.MIXED_USE]: {
        wrapperColor: colors['propertyType-mixeduse'],
        iconColor: colors.white,
        icon: MixeduseSvg,
        displayLabel: 'Mixed Use',
        path: 'MU',
    },
    [propertyTypes.OTHER]: {
        wrapperColor: colors['grey-150'],
        iconColor: colors.white,
        icon: OtherSvg,
        displayLabel: 'Other',
        path: 'OT',
    },
    [propertyTypes.NO_TYPE]: {
        wrapperColor: colors['grey-A200'],
        iconColor: colors['grey-300'],
        icon: NoTypeSvg,
        displayLabel: 'No Type',
    },
};

const PROPERTY_TYPES_ICONS_CONTENT = {
    [propertyTypes.OFFICE]: {
        icon: OfficeContentSvg,
    },
    [propertyTypes.RETAIL]: {
        icon: RetailContentSvg,
    },
    [propertyTypes.MULTIFAMILY]: {
        icon: MultifamilyContentSvg,
    },
    [propertyTypes.MOBILE_HOME]: {
        icon: MobilehomeContentSvg,
    },
    [propertyTypes.INDUSTRIAL]: {
        icon: IndustrialContentSvg,
    },
    [propertyTypes.SELF_STORAGE]: {
        icon: SelfStorageContentSvg,
    },
    [propertyTypes.SINGLE_FAMILY]: {
        icon: SinglefamilyContentSvg,
    },
    [propertyTypes.LODGING]: {
        icon: LodgingContentSvg,
    },
    [propertyTypes.SENIOR_HOUSING]: {
        icon: SeniorhousingContentSvg,
    },
    [propertyTypes.STUDENT_HOUSING]: {
        icon: StudenthousingContentSvg,
    },
    [propertyTypes.VACANT_LAND]: {
        icon: LandContentSvg,
    },
    [propertyTypes.AGRICULTURAL]: {
        icon: AgriculturalContentSvg,
    },
    [propertyTypes.APARTMENT]: {
        icon: ApartmentContentSvg,
    },
    [propertyTypes.WAREHOUSE]: {
        icon: WarehouseContentSvg,
    },
    [propertyTypes.HEALTHCARE]: {
        icon: HealthcareContentSvg,
    },
    [propertyTypes.MIXED_USE]: {
        icon: MixeduseContentSvg,
    },
    [propertyTypes.OTHER]: {
        icon: OtherContentSvg,
    },
    [propertyTypes.NO_TYPE]: {
        icon: NoTypeContentSvg,
    },
};

export const getPropertyTypeProps = (type) => PROPERTY_TYPES_CONFIG[mapPropertyTypes[type]] || {};

export const getPropertyTypeIconsContent = (type) => PROPERTY_TYPES_ICONS_CONTENT[mapPropertyTypes[type]] || {};

export const getPropertyTypeSize = (type) => PROPERTY_TYPES_PROPS[mapPropertyTypes[type]]?.size;

export const getPropertyType = (propType) => {
    if (propType?.toLowerCase() === 'multi-family') {
        return 'Multi-Family';
    }

    if (propType?.toLowerCase() === 'vacant-land') {
        return propertyTypes.VACANT_LAND;
    }

    return capitalize(propType);
};

export const getDatePriorToToday = (value) => {
    const date = new Date();
    return date.setMonth(date.getMonth() - value);
};

export const formatPropertyAddress = (address) => {
    if (!address) {
        return '-';
    }
    const [mainAddress = '', ...rest] = address.split(', ');
    return [mainAddress, rest.join(', ')];
};

export const propertyCategory = (property) => {
    if (
        property === propertyTypes.OFFICE ||
        property === propertyTypes.RETAIL ||
        property === propertyTypes.INDUSTRIAL ||
        property === propertyTypes.MIXED_USE ||
        property === propertyTypes.APARTMENT ||
        property === propertyTypes.WAREHOUSE ||
        property === propertyTypes.HEALTHCARE
    ) {
        return {
            sizeBasedProp: true,
            unitBasedProp: false,
        };
    }
    return {
        sizeBasedProp: false,
        unitBasedProp: true,
    };
};

export const getPropertyLink = ({ countyId, parcelId, userId }) =>
    `/portfolio/detailed?countyId=${countyId}&parcelId=${parcelId}&userId=${userId}`;

export default propertyTypes;
