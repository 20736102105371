/* eslint-disable */
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { arrayDataInjection, valuesDataInjections } from '@utils/charts/helpers';
import Occupancy from '../configs/occupancy';
import Rents from '../configs/rentsSplitted';
import UnitMix from '../configs/unitMix';
import Charts from '../index';

export default class PortfolioCharts extends Charts {
    addData(data) {
        this.data = data || null;
        this.osData = data.osData || null;
        this.tenants = data.tenants || null;
    }

    getRevenue(customConfig, grouping) {
        return super.revenue(customConfig, grouping, this.osData);
    }

    getNoi(customConfig, grouping) {
        return super.noi(customConfig, grouping, this.osData);
    }

    getNoiMargin(customConfig, grouping) {
        return super.noiMargin(customConfig, grouping, this.osData);
    }

    getOperatingExpenses(customConfig) {
        return super.operatingExpenses(customConfig, this.osData);
    }

    getOperatingExpensesPie(customConfig, grouping) {
        return super.operatingExpensesPie(customConfig, grouping, this.osData);
    }

    getUnitMixBySF(customConfig = {}) {
        if (!this.data?.unitMixSfByType) return null;

        const config = merge(cloneDeep(UnitMix), customConfig);

        const groups = Object.entries(this.data?.unitMixSfByType).map(([key, value]) => ({ name: key, value }));

        const data = {
            data: [Object.values(groups)],
        };

        return arrayDataInjection(config, data);
    }

    getUnitMixByRent(customConfig = {}) {
        if (!this.data?.unitMixRentByType) return null;

        const config = merge(cloneDeep(UnitMix), customConfig);

        const groups = Object.entries(this.data?.unitMixRentByType).map(([key, value]) => ({ name: key, value }));

        const data = {
            data: [Object.values(groups)],
        };

        return arrayDataInjection(config, data);
    }

    getRents(customConfig = {}) {
        if (!this.data) return null;

        const config = merge(cloneDeep(Rents), customConfig);

        const data = {
            data: [this.data?.rent, this.data?.marketRent],
            xAxis: ['In-place', 'Market'],
        };

        return valuesDataInjections(config, data);
    }

    getOccupancy(customConfig = {}) {
        if (!this.data) return null;

        const config = merge(cloneDeep(Occupancy), customConfig);

        const data = {
            data: [this.data?.marketOccupancy, this.data?.occupancy],
            xAxis: ['In-place', 'Market'],
        };

        return valuesDataInjections(config, data);
    }

    getLeaseExpirationsSF(customConfig = {}) {
        return super.multipleLeaseExpirationsSF(
            customConfig,
            this.tenants && this.data.properties ? { tenants: this.tenants, properties: this.data.properties } : null
        );
    }

    getLeaseExpirationsRent(customConfig = {}) {
        return super.multipleLeaseExpirationsRent(
            customConfig,
            this.tenants && this.data.properties
                ? {
                      tenants: this.tenants,
                      properties: this.data.properties,
                  }
                : null
        );
    }
}
