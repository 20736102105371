/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import { useContext } from 'react';

import { UploadDocumentsContext } from '@scc/modules/EnterpriseUploadDocument/components/UploadDocuments/context/UploadDocumentsContext';

import Box from '@ui/Box';
import Button from '@ui/Button';
import FormControl from '@ui/FormControl';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Input from '@ui/Input';
import InputLabel from '@ui/InputLabel';
import ListItemIcon from '@ui/ListItemIcon';
import ListItemText from '@ui/ListItemText';
import MenuItem from '@ui/MenuItem';
import Select from '@ui/Select';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import { DOCUMENT_TYPES } from '@components/DataTable/constants';
import BaseDatePicker from '@components/DatePicker';
import DocumentType from '@components/DocumentType';

import colors from '@themes/palette/export.module.scss';

import { CHANGE_EXCEL_TAB, PREVIEW_FILE_INTERACTIONS } from '@constants/connectorMC';
import DocIcon from '@icons/filled/document-1.svg';
import addSvg from '@icons/outlined/add.svg';
import closeSvg from '@icons/outlined/close.svg';
import DeleteIcon from '@icons/outlined/trash.svg';
import { emitCustomEvent } from '@shared/hooks/useCustomEvents';

import { usePrepareDocument } from './hooks/usePrepareDocument';
import styles from './styles.module.scss';

const documentTypesArray = Object.values(DOCUMENT_TYPES);

function PrepareDocument({
    document,
    onDelete,
    onChange,
    onAddDuplicate,
    onPreview = () => {},
    index,
    isActive,
    totalPages,
    canDelete = true,
    setIsLoading = () => {},
}) {
    const { buildings = [] } = useContext(UploadDocumentsContext);

    const {
        id: documentId,
        name: documentName,
        type,
        start,
        end,
        displayName,
        date,
        sheetNumber,
        isAutoGenerated,
        propertyBuildingId,
    } = document;

    const [
        { xlsTabs, isDocumentFileType, isExcelFileType, fromPage, toPage, isDuplicate },
        { handleDelete, handlePreview, onStartPageChange, onSetTotalPages },
    ] = usePrepareDocument({
        document,
        onDelete,
        onChange,
        onPreview,
        isActive,
        index,
        setIsLoading,
    });

    return (
        <Box
            className={cx(styles.prepareDoc, {
                [styles.prepareDocWithParent]: isDuplicate,
                [styles.active]: isActive,
            })}
            onClick={handlePreview}
        >
            <Grid className={styles.gridContainer} columnSpacing={16} rowSpacing={12} container>
                {!isDuplicate && (
                    <Grid xs={12} item>
                        <div className={styles.docName}>
                            <Icon color={colors['blue-main']} component={DocIcon} fontSize={16} />
                            <Typography color={colors['grey-400']} variant='hb13'>
                                {displayName}
                            </Typography>
                        </div>
                    </Grid>
                )}
                <Grid xs={12} item>
                    <Input
                        label='Document Name'
                        name='name'
                        value={documentName}
                        fullWidth
                        onChange={(e) => onChange(e.target, documentId)}
                    />
                </Grid>
                <Grid xs={6} item>
                    <FormControl variant='standard' fullWidth>
                        <InputLabel id='document-type-label'>Document Type</InputLabel>
                        <Select
                            className={styles.documentTypeSelect}
                            id='document-type'
                            label='Document Type'
                            labelId='document-type-label'
                            value={type || ''}
                            fullWidth
                            onChange={({ target }) => onChange({ name: 'type', value: target.value }, documentId)}
                        >
                            {documentTypesArray.map(({ label, type: _type, value }) => (
                                <MenuItem key={value} value={value}>
                                    <ListItemIcon className={styles.listItemIcon}>
                                        <DocumentType size='small' type={_type} />
                                    </ListItemIcon>
                                    <ListItemText primary={label} primaryTypographyProps={{ variant: 'hr13' }} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid className={styles.fullWidthContentCell} xs={6} item>
                    <BaseDatePicker
                        defaultValue={date}
                        label='Document Date'
                        name='date'
                        fullWidth
                        showYearDropdown
                        onChange={({ target: { value } }) => {
                            onChange(
                                {
                                    name: 'date',
                                    value,
                                },
                                documentId
                            );
                        }}
                    />
                </Grid>
                {!isAutoGenerated && (
                    <Grid xs={6} item>
                        <Stack alignItems='flex-end' direction='row' spacing={4}>
                            {isDocumentFileType && (
                                <div className={styles.prepareDocPageWrapper}>
                                    <div className={styles.pageInputWrapper}>
                                        <Input
                                            defaultValue={fromPage}
                                            inputProps={{ min: 0, max: totalPages }}
                                            name='start'
                                            type='number'
                                            value={start === 0 ? '' : start}
                                            fullWidth
                                            onChange={onStartPageChange}
                                        />
                                    </div>
                                    -
                                    <div className={styles.pageInputWrapper}>
                                        <Input
                                            defaultValue={toPage}
                                            inputProps={{ min: start || fromPage || 0, max: totalPages }}
                                            name='end'
                                            type='number'
                                            value={end === 0 ? '' : end}
                                            fullWidth
                                            onChange={(e) => onChange(e.target, documentId)}
                                        />
                                    </div>
                                </div>
                            )}
                            {isExcelFileType && !!xlsTabs.length && (
                                <FormControl className={styles.tabSelector} size='small' variant='standard'>
                                    <InputLabel id='tab-extract-label'>Selected tab to extract</InputLabel>
                                    <Select
                                        className={styles.documentTypeSelect}
                                        id='tab-extract'
                                        label='Selected tab to extract'
                                        labelId='tab-extract-label'
                                        value={String(sheetNumber) || ''}
                                        fullWidth
                                        onChange={({ target }) => {
                                            emitCustomEvent(PREVIEW_FILE_INTERACTIONS, {
                                                action: CHANGE_EXCEL_TAB,
                                                data: { index: target.value },
                                            });
                                            onChange({ name: 'sheetNumber', value: Number(target.value) }, documentId);
                                        }}
                                    >
                                        {xlsTabs.map(({ orderNo, name: tabName }) => (
                                            <MenuItem key={orderNo} value={String(orderNo)}>
                                                {tabName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            {isDocumentFileType && (
                                <Button variant='outlined' onClick={onSetTotalPages}>
                                    All Pages
                                </Button>
                            )}
                            {!!onAddDuplicate && !isDuplicate && (
                                <Button variant='outlined' onClick={() => onAddDuplicate(document.id)}>
                                    <Icon color={colors['grey-300']} component={addSvg} fontSize={16} />
                                </Button>
                            )}
                            {!!onAddDuplicate && isDuplicate && (
                                <Button variant='outlined' onClick={handleDelete}>
                                    <Icon color={colors['grey-300']} component={closeSvg} fontSize={16} />
                                </Button>
                            )}
                        </Stack>
                    </Grid>
                )}
                {propertyBuildingId && (
                    <Grid xs={6} item>
                        <FormControl className={styles.tabSelector} size='small' variant='standard' fullWidth>
                            <InputLabel id='building-id'>Upload to building</InputLabel>
                            <Select
                                className={styles.documentTypeSelect}
                                label='Upload to building'
                                labelId='building-id'
                                value={propertyBuildingId}
                                fullWidth
                                onChange={({ target }) =>
                                    onChange({ name: 'propertyBuildingId', value: target.value }, documentId)
                                }
                            >
                                {buildings.map(({ id: buildingId, name: buildingName }) => (
                                    <MenuItem key={buildingId} value={buildingId}>
                                        {buildingName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            {!isDuplicate && canDelete && (
                <IconButton className={styles.deleteButton} onClick={handleDelete}>
                    <Icon color={colors['grey-300']} component={DeleteIcon} />
                </IconButton>
            )}
        </Box>
    );
}

export default PrepareDocument;
