import { Link } from 'react-router-dom';

import Button from '@ui/Button';
import Card from '@ui/Card';
import CardActions from '@ui/CardActions';
import CardContent from '@ui/CardContent';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import styles from './services.module.scss';
import data from './servicesData';

function ServicesContent() {
    return (
        <div className={styles.services}>
            <ItemList items={data.services}>
                {({ label, description, name, path }) => (
                    <Card key={name} className={styles[name]} variant='services'>
                        <CardContent>
                            <Typography component='h2' variant='h1b' gutterBottom>
                                {label}
                            </Typography>
                            <Typography color={colors['darkNeutral-500']} variant='lgr'>
                                {description}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button component={Link} size='small' to={path} variant='outlined'>
                                Learn More
                            </Button>
                        </CardActions>
                    </Card>
                )}
            </ItemList>
        </div>
    );
}

export default ServicesContent;
