import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { applyDateValue } from '@components/DatePicker/helpers';

import DatePickerInput from './components/DatePickerInput';

const defaultProps = {
    defaultValue: null,
    input: DatePickerInput,
    showYearDropdown: false,
    dateFormat: 'MMMM d, yyyy',
    onSuccess: () => {},
    variant: 'standard',
};

function DatePicker({
    defaultValue,
    input: Input,
    dateFormat,
    name,
    label,
    showYearDropdown,
    setDefaultValue,
    onChange,
    trigger,
    onSuccess,
    placeholder,
    fullWidth,
    inputProps,
    isClearable = true,
    variant,
    error,
    helperText,
    openToDate = new Date(),
    dateFormatCalendar = 'MMMM',
    onCalendarClose,
    ...props
}) {
    const [startDate, setStartDate] = useState(applyDateValue(defaultValue));

    const handleChange = (data) => {
        if (data) {
            data.setHours(0, 0, 0, 0);
        }

        const dateValue = applyDateValue(data);

        onChange({ target: { value: dateValue } });
        setStartDate(dateValue);
    };

    const handleCalendarClose = () => {
        onSuccess(startDate);
        trigger?.(name);
        onCalendarClose?.();
    };

    useEffect(() => {
        if (setDefaultValue && typeof setDefaultValue === 'function') {
            setDefaultValue(startDate);
        }
    }, []);

    return (
        <ReactDatePicker
            customInput={
                <Input
                    error={error}
                    fullWidth={fullWidth}
                    helperText={helperText}
                    inputProps={{
                        autoComplete: 'off',
                    }}
                    label={label}
                    placeholder={placeholder}
                    variant={variant}
                    {...inputProps}
                />
            }
            dateFormat={dateFormat}
            dateFormatCalendar={dateFormatCalendar}
            isClearable={isClearable}
            name={name}
            openToDate={openToDate}
            placeholderText={placeholder}
            selected={startDate}
            showYearDropdown={showYearDropdown}
            onCalendarClose={handleCalendarClose}
            onChange={handleChange}
            {...props}
        />
    );
}

DatePicker.defaultProps = defaultProps;

export default DatePicker;
