import { getMapValue } from '@utils/components';
import ReferFrom from '@shared/templates/forms/components/ReferFrom';
import SuccessPropertyForm from '@shared/templates/forms/components/SharePropertyFormSuccess';
import schema from './validationSchema';

export const TEMPLATES = {
    MAIN: 'main',
    SUCCESS: 'success',
};

const DEFAULT_TEMPLATE = TEMPLATES.MAIN;

const authFlowMap = new Map([
    [TEMPLATES.MAIN, ReferFrom],
    [TEMPLATES.SUCCESS, SuccessPropertyForm],
]);

const inputs = [
    {
        name: 'firstName',
        caption: 'First Name',
    },
    {
        name: 'lastName',
        caption: 'Last Name',
    },
    {
        name: 'referralEmail',
        caption: 'Email',
    },
    {
        name: 'phone',
        caption: 'Phone Number',
    },
];

const ShareProperty = function ({ onSuccess, loading, template, formProps = {}, children }) {
    const Form = getMapValue(authFlowMap, template, DEFAULT_TEMPLATE);

    return (
        <Form inputs={inputs} schema={schema} onSuccess={onSuccess} loading={loading} formProps={formProps[template]}>
            {children}
        </Form>
    );
};

export default ShareProperty;
