import { generatedApi, ToggleDefaultTermSheetApiArg, DeleteTermSheetApiArg } from '../generated/underwriting';

const UNDERWRITING_TAGS = {
    GET_TERM_SHEETS: 'getTermSheets',
} as const;

const enhanceOptions = {
    addTagTypes: [UNDERWRITING_TAGS.GET_TERM_SHEETS],
    deleteTermSheet: {
        invalidatesTags: [UNDERWRITING_TAGS.GET_TERM_SHEETS],
    },
    generateTermSheet: {
        invalidatesTags: [UNDERWRITING_TAGS.GET_TERM_SHEETS],
    },
    endpoints: {
        getTermSheets: {
            providesTags: [UNDERWRITING_TAGS.GET_TERM_SHEETS],
        },
        getTermSheetsByLoanDetails: {
            providesTags: [UNDERWRITING_TAGS.GET_TERM_SHEETS],
        },
        updateTermSheet: {
            invalidatesTags: [UNDERWRITING_TAGS.GET_TERM_SHEETS],
        },
        toggleDefaultTermSheet: {
            onQueryStarted(
                arg: ToggleDefaultTermSheetApiArg & {
                    loanModelId: number;
                    loanDetailsId: number;
                },
                { dispatch, queryFulfilled }
            ) {
                const { termSheetId, loanModelId } = arg.setDefaultTermSheet;

                const patchResult = dispatch(
                    generatedApi.util.updateQueryData('getTermSheets', { loanModelId }, (draft) => {
                        const patch = draft?.data?.map((termSheet) => {
                            if (termSheet.id === termSheetId) {
                                return {
                                    ...termSheet,
                                    default: true,
                                };
                            }
                            return termSheet;
                        });
                        Object.assign(draft, { data: patch });
                    })
                );

                const patchResultByLoanDetails = dispatch(
                    generatedApi.util.updateQueryData(
                        'getTermSheetsByLoanDetails',
                        { loanDetailsId: arg.loanDetailsId },
                        (draft) => {
                            const patch = draft?.data?.map((termSheet) => {
                                if (termSheet.id === termSheetId) {
                                    return {
                                        ...termSheet,
                                        default: true,
                                    };
                                }
                                return termSheet;
                            });
                            Object.assign(draft, { data: patch });
                        }
                    )
                );

                queryFulfilled.catch(() => {
                    patchResult.undo();
                    patchResultByLoanDetails.undo();
                });
            },
        },
        deleteTermSheet: {
            onQueryStarted(
                arg: DeleteTermSheetApiArg & {
                    loanModelId: number;
                    loanDetailsId: number;
                },
                { dispatch, queryFulfilled }
            ) {
                const { termSheetId, loanModelId, loanDetailsId } = arg;

                const patchResult = dispatch(
                    generatedApi.util.updateQueryData('getTermSheets', { loanModelId }, (draft) => {
                        const patch = draft?.data?.filter((termSheet) => termSheet.id !== termSheetId);

                        Object.assign(draft, { data: patch });
                    })
                );

                const patchResultByLoanDetails = dispatch(
                    generatedApi.util.updateQueryData('getTermSheetsByLoanDetails', { loanDetailsId }, (draft) => {
                        const patch = draft?.data?.filter((termSheet) => termSheet.id !== termSheetId);

                        Object.assign(draft, { data: patch });
                    })
                );

                queryFulfilled.catch(() => {
                    patchResult.undo();
                    patchResultByLoanDetails.undo();
                });
            },
        },
        generateTermSheet: {
            async onQueryStarted(arg: { loanModelId: number }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    const patchResult = dispatch(
                        generatedApi.util.updateQueryData(
                            'getTermSheets',
                            { loanModelId: arg.loanModelId },
                            (draft) => {
                                if (!draft.data) return;

                                Object.assign(draft, { data: [...draft.data, data?.data] });
                            }
                        )
                    );

                    queryFulfilled.catch(patchResult.undo);
                }
            },
        },
    },
} as const;

export const underwritingApi = generatedApi.enhanceEndpoints(enhanceOptions as never);
