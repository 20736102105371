import ValuationProcess from '@scc/modules/ValuationProcess';

const PropertyTemplate = function ({ children }) {
    return (
        <>
            {children}
            <ValuationProcess />
        </>
    );
};

export default PropertyTemplate;
