import cx from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { servicesLinks } from '@scc/router/links';

import List from '@ui/List';
import ListItem from '@ui/ListItem';
import ListItemButton from '@ui/ListItemButton';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import styles from './services.module.scss';

function ServicesMenu() {
    const { pathname } = useLocation();
    const [selectedNavItem, setSelectedNavItem] = React.useState(null);

    React.useEffect(() => {
        setSelectedNavItem(pathname);
    }, [pathname]);

    return (
        <nav className={styles.menu}>
            <List>
                <ItemList items={servicesLinks}>
                    {({ name, path, label }) => {
                        const variant = name === 'services' ? 'h3b' : 'mdr';
                        return (
                            <ListItem key={name}>
                                <ListItemButton
                                    aria-label={label}
                                    className={cx(styles.item, styles[name], {
                                        [styles.active]: selectedNavItem === path,
                                    })}
                                    component={Link}
                                    rel='nofollow'
                                    selected={selectedNavItem === path}
                                    to={path}
                                    onClick={() => setSelectedNavItem(path)}
                                >
                                    <Typography color={colors.black} variant={variant}>
                                        {label}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        );
                    }}
                </ItemList>
            </List>
        </nav>
    );
}

export default ServicesMenu;
