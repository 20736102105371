import type { ConfigFile } from '@rtk-query/codegen-openapi';

export const createConfig = ({ filename, filterEndpoints, tag = false }): ConfigFile => ({
    useEnumType: true,
    apiImport: 'baseApi',
    exportName: 'generatedApi',
    schemaFile: './openapi.json',
    apiFile: `./configs/${filename}.ts`,
    outputFile: `./generated/${filename}.ts`,
    filterEndpoints,
    hooks: true,
    tag,
});
