import BaseForm from '@shared/components/BaseForm';
import { getDocumentTypesList } from '@utils/properties';
import schema from './validationSchema';
import styles from './styles.module.scss';

const radioOptions = getDocumentTypesList();

const UploadDocumentsForm = function ({ formId, initialFormData, onSuccess }) {
    const inputs = [
        {
            name: 'type',
            label: 'Report Type',
            fieldType: 'select',
            className: styles.inline,
            selectProps: {
                options: radioOptions,
            },
        },
        {
            name: 'pages',
            caption: 'Pages',
            type: 'number',
            className: styles.inline,
        },
        {
            name: 'date',
            fieldType: 'date',
            defaultValue: new Date(),
        },
    ];

    return (
        <BaseForm
            id={formId}
            initialFormData={initialFormData}
            className={styles.form}
            inputs={inputs}
            schema={schema}
            onSubmit={onSuccess}
        />
    );
};

export default UploadDocumentsForm;
