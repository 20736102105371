import styles from '@scc/modules/AddPropertyWizard/components/styles.module.scss';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

function ExistingParcelDetails({ propertyId, action }) {
    return (
        <div className={styles.existingAction}>
            <div>
                {propertyId && (
                    <>
                        <Typography color={colors['grey-400']} variant='smr' noWrap>
                            Property ID
                        </Typography>
                        <Typography variant='smr' noWrap>
                            {propertyId}
                        </Typography>
                    </>
                )}
            </div>
            {action}
        </div>
    );
}

export default ExistingParcelDetails;
