import { combineReducers } from 'redux';

import documentViewWithAiReducer from './documentViewWithAi';
import financialsReducer, { watchFinancials } from './financials';
import marketReducer, { watchMarket } from './market';
import overviewReducer, { watchOverview } from './overview';
import underwritingReducer, { watchUnderwriting } from './underwriting';

export const detailedPropertyWatchers = [watchOverview, watchFinancials, watchUnderwriting, watchMarket];

export default combineReducers({
    overview: overviewReducer,
    financials: financialsReducer,
    underwriting: underwritingReducer,
    market: marketReducer,
    documentViewWithAi: documentViewWithAiReducer,
});
