import Icon from '@ui/Icon';
import CheckCircleOutlineIcon from '@scc/assets/icons/success.svg';
import PartialSuccessLayout from '@scc/components/PartialSuccessLayout';
import Button from '@ui/Button';
import styles from './styles.module.scss';

const RequestDemoFormSuccess = function ({ onClose }) {
    return (
        <PartialSuccessLayout
            icon={<Icon component={CheckCircleOutlineIcon} fontSize='128px' />}
            title='Success!'
            description='Thank you for signing up for the Smart Capital Center demo.
Due to high demand, you were added to our waiting list.
We will follow up with you once we add more spots in our demo schedule.'
            action={
                <Button color='primary' size='extra-large' onClick={onClose} fullWidth>
                    Done
                </Button>
            }
            className={styles.successWrapper}
        />
    );
};

export default RequestDemoFormSuccess;
