import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';

import PropertyBookmark from '@scc/pages/Property/components/PropertyBookmark';

import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Menu from '@ui/Menu';
import MenuItem from '@ui/MenuItem';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import ShareButton from '@components/ShareButton';

import colors from '@themes/palette/export.module.scss';

import ArrowIcon from '@icons/outlined/export.svg';
import MoreSvg from '@icons/outlined/more-3.svg';

import styles from './styles.module.scss';

export default function PropertyButtons({ parcel, handleShareProperty, handleAddToListingClick }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Stack direction='row'>
            <PropertyBookmark
                dataQa={{
                    button: 'propertyBookmark',
                    list: 'propertyBookmarkList',
                    listItem: 'propertyBookmarkListItem',
                }}
                parcel={parcel}
            />
            {!isMobile ? <ShareButton onClick={handleShareProperty} /> : null}
            <IconButton.Small onClick={handleClick}>
                <Icon color={colors['grey-400']} component={MoreSvg} />
            </IconButton.Small>

            <Menu
                PaperProps={{
                    elevation: 10,
                }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClick={handleClose}
                onClose={handleClose}
            >
                {isMobile ? (
                    <MenuItem classes={{ root: styles.menuItem }} onClick={handleShareProperty}>
                        <Typography variant='menu'>Share</Typography>
                        <Icon color={colors['darkNeutral-500']} component={ArrowIcon} fontSize='13px' />
                    </MenuItem>
                ) : null}
                <MenuItem classes={{ root: styles.menuItem }} onClick={handleAddToListingClick}>
                    <Typography variant='menu'>List This Property</Typography>
                </MenuItem>
            </Menu>
        </Stack>
    );
}
