import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { memo } from 'react';

import LabeledProgress from '@components/LabeledProgress';

import styles from './styles.module.scss';

function LocationParams({ data }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className={styles.locationParams}>
            {data.map(({ label, tooltip, value }) => (
                <div key={label} className={styles.locationParamsItem}>
                    <LabeledProgress
                        label={label}
                        labelVariant={isMobile ? 'h5s' : undefined}
                        layout={isMobile ? { right: 12 } : undefined}
                        tooltip={!isMobile && tooltip}
                        value={value}
                    />
                </div>
            ))}
        </div>
    );
}

export default memo(LocationParams);
