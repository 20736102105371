import Select, { SelectProps } from '@mui/material/Select';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Icon from '@ui/Icon';
import OutlinedInput from '@ui/OutlinedInput';

import colors from '@themes/palette/export.module.scss';

type TOutlinedSelectProps = SelectProps;

function IconComponent(props) {
    return <Icon {...props} color={colors['grey-400']} component={ChevronIcon} fontSize={20} />;
}

export function OutlinedSelect({ children, ...rest }: TOutlinedSelectProps) {
    return (
        <Select
            IconComponent={IconComponent}
            input={<OutlinedInput variant='outlined' {...(rest.inputProps ?? {})} />}
            {...rest}
        >
            {children}
        </Select>
    );
}
