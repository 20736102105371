import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { checklistApi } from '@api';
import { MODES } from '@modules/TodoChecklists/helpers/constants';
import { API_ERROR, API_REQUEST, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[loans/todosCheckLists]';

const initialState = {
    data: [],
    loading: false,
    dialogMode: MODES.CHECKLISTS,
    selectedListId: null,
    loanId: null,
};

const todosCheckListsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load() {},
        set(state, action) {
            state.data = action.payload;
        },
        setMode(state, action) {
            state.dialogMode = action.payload;
        },
        setSelectedListId(state, action) {
            state.selectedListId = action.payload;
        },
        setLoanId(state, action) {
            state.loanId = action.payload;
        },
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith(API_REQUEST),
                (state) => {
                    state.loading = true;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith(API_SUCCESS) || action.type.endsWith(API_ERROR),
                (state) => {
                    state.loading = false;
                }
            );
    },
});

export const {
    load: loadTodosCheckLists,
    set: setTodoCheckLists,
    setMode: setModeTodoCheckLists,
    reset: resetTodosCheckLists,
    setSelectedListId: setSelectedListIdTodosCheckLists,
    setLoanId: setLoanIdTodosCheckLists,
} = todosCheckListsSlice.actions;

export default todosCheckListsSlice.reducer;

export const selectTodoCheckLists = (state) => state.loans.todosCheckLists;

function* loadTodosCheckListsSaga({ type }) {
    yield put(apiRequest(null, checklistApi.getChecklists, type));

    const { payload: { data } = {} } = yield take(`${type} ${API_SUCCESS}`);

    yield put(setTodoCheckLists(data));
}

export function* watchTodosCheckLists() {
    yield takeLatest(loadTodosCheckLists, loadTodosCheckListsSaga);
}
