import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@scc/index';
import { TAB_ENUM } from '@scc/pages/Portfolio/components/Documents/components/DocumentViewWithAi/helpers';
import { DocumentDto } from '@scc/services/generated/document';

const entity = '[detailedProperty/documentViewWithAi]';

type TDocumentViewWithAi = {
    activeDocumentId: number | null;
    activeTab: string | null;
    activePageIndex: number | null;
    searchString: string | null;
    chatContext: Record<string, unknown> | null;
};

const initialState: TDocumentViewWithAi = {
    activeDocumentId: null,
    activeTab: TAB_ENUM.DOCUMENT,
    activePageIndex: null,
    searchString: null,
    chatContext: {},
};

const documentViewWithAiSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setActiveIdDocumentViewWithAi(state, action) {
            state.activeDocumentId = action.payload;
        },
        setSearchStringDocumentViewWithAi(state, action) {
            state.searchString = action.payload;
        },
        setActivePageIndexDocumentViewWithAi(state, action) {
            state.activePageIndex = action.payload;
        },
        setActiveTabDocumentViewWithAi(state, action) {
            state.activeTab = action.payload;
        },
        setChatContextDocumentViewWithAi(state, action) {
            state.chatContext = action.payload;
        },
        resetStateDocumentViewWithAi() {
            return initialState;
        },
    },
});

export const {
    setActiveIdDocumentViewWithAi,
    setSearchStringDocumentViewWithAi,
    setActivePageIndexDocumentViewWithAi,
    setActiveTabDocumentViewWithAi,
    setChatContextDocumentViewWithAi,
    resetStateDocumentViewWithAi,
} = documentViewWithAiSlice.actions;

export default documentViewWithAiSlice.reducer;

export const selectDocumentViewWithAiDocument = (store: RootState) =>
    store.documents.data.find(
        (document: DocumentDto) => document.id === store.detailedProperty.documentViewWithAi.activeDocumentId
    );

export const selectDocumentViewWithAi = (store: RootState) => store.detailedProperty.documentViewWithAi;

export const selectDocumentViewWithAiChatContext = (store: RootState) =>
    store.detailedProperty.documentViewWithAi.chatContext;
