import { useSelector } from 'react-redux';

import { selectAuthPermissions, selectIsAdmin } from '@scc/store/ducks/auth';

export function useTestPermission(permissionName: string | { ENTRY: string }): boolean {
    const permissions = useSelector(selectAuthPermissions) as Record<string, boolean>;
    const { isAdmin } = useSelector(selectIsAdmin) as { isAdmin: boolean };

    const permission = (permissionName as { ENTRY: string })?.ENTRY || (permissionName as string);

    // TODO: Remove isAdmin when all permissions are added to the backend
    return isAdmin || permissions?.[permission] !== false;
}
