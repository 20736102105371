import { createSlice } from '@reduxjs/toolkit';
import { put, select, take, takeLatest } from 'redux-saga/effects';

import { listingsApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[listings]';

const initialState = {
    totalCount: 0,
    data: [],
    bookmarks: [],
    search: {},
    loaded: false,
    loading: false,
};

const listingsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            if (action.payload) {
                state.search = action.payload;
            }
            state.loaded = false;
            state.loading = true;
        },
        loadMore() {},
        setData(state, action) {
            state.data = action.payload.data;
            state.totalCount = action.payload.totalCount;
            state.loaded = true;
            state.loading = false;
        },
        addMore(state, action) {
            state.data = action.payload;
        },
    },
});

export const {
    load: loadListings,
    loadMore: loadMoreListings,
    setData: setListings,
    addMore: addMoreListings,
} = listingsSlice.actions;

export default listingsSlice.reducer;

export const selectListingsState = (store) => store.listings;

function* loadListingsSaga({ type }) {
    const { search } = yield select(selectListingsState);
    yield put(apiRequest(search, listingsApi.getListings, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setListings({ data: [{ id: 'add-new' }, ...content], totalCount: totalElements }));
}

function* loadMoreListingsSaga({ type }) {
    const { data: prevData, search } = yield select(selectListingsState);
    yield put(
        apiRequest({ ...search, page: Math.round(prevData.length / search.size) }, listingsApi.getListings, type)
    );
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreListings([...prevData, ...content]));
}

export function* watchListings() {
    yield takeLatest(loadListings, loadListingsSaga);
    yield takeLatest(loadMoreListings, loadMoreListingsSaga);
}
