import { ComponentType, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { formatter } from '@utils/index';

import MultiSelectWithApply from '@scc/components/MultiSelectWithApply';
import { SearchableSelect } from '@scc/components/popovers/SearchableSelect';
import { EditableTextField } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/inputs/EditableValue/components/EditableTextField';
import {
    decimalValueValidator,
    decimalWithFiveDigitsValueValidator,
} from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/helpers';
import { TermsKeys, TLoanTerms } from '@scc/store/ducks/loans/types';
import { TMember } from '@scc/store/ducks/loans/types/members.types';

import { NUMBER_FORMAT_MIN_FIVE_ROUND, NUMBER_FORMAT_MIN_TWO_ROUND } from '@constants/numbers';

import { DestinationSelect } from '../components/DestinationSelect';
import LenderTrigger from '../components/LenderTigger';
import LoadDatePicker from '../components/LoanDatePicker';
import { LoanProgramSelect } from '../components/LoanProgramSelect';
import PrepaymentProtectionInput from '../components/PrepaymentProtectionInput';
import TermsSelectInput from '../components/TermsSelectInput';
import { TLoanTermsDetailsWithDivider, TSelectOptions } from '../Terms.types';

import {
    AMORTIZATION_OPTIONS,
    AmortizationOption,
    AMORTIZING_OPTIONS,
    CASH_SWEEP_OPTIONS,
    COUPON_TYPE_OPTIONS,
    LOAN_PROGRAM_OPTIONS,
    LOAN_RATING_OPTIONS,
    STATUS_OPTIONS,
    CouponType,
    AmortizingOption,
    LENDER_TYPE_OPTIONS,
    LENDER_URL,
} from './constants';
import { formatDetailsDateValue, renderLenderOptions } from './formatters';
import { generateTermsPath, getNodesFromLoanCustomIds } from './helpers';

const getDateValue = (value: string | number | Date): number | null => {
    if (!value) {
        return null;
    }

    if (typeof value === 'string') {
        return Date.parse(value);
    }

    if (typeof value === 'number') {
        return value;
    }

    return value.getTime();
};

type TGetTermsNodesProps = {
    details: TLoanTerms;
    rates: Record<string, unknown>[];
    onCustomClick: () => void;
    updateLender: (value: TMember) => void;
    isUserFMACTenant: boolean;
};

export const getTermsNodes = ({
    details,
    rates,
    onCustomClick,
    updateLender,
    isUserFMACTenant,
}: TGetTermsNodesProps): TLoanTermsDetailsWithDivider[] => {
    return [
        {
            id: uuid(),
            kind: 'node',
            title: 'Lender',
            name: TermsKeys.LENDER,
            value: details[TermsKeys.LENDER] ?? {},
            path: generateTermsPath(TermsKeys.LENDER),
            isShowInputOnly: true,
            input: SearchableSelect as ComponentType<unknown>,
            inputProps: {
                url: LENDER_URL,
                renderOption: renderLenderOptions,
                triggerComponent: LenderTrigger,
                onChange: (value) => {
                    updateLender(value as TMember);
                },
                params: {
                    entityType: 'COMPANY',
                },
                value: details[TermsKeys.LENDER] ?? {},
            },
            children: [
                {
                    id: uuid(),
                    kind: 'node',
                    title: 'Lender Type',
                    name: 'lenderType',
                    isShowInputOnly: true,
                    value: details[TermsKeys.LENDER_TYPE] ?? [],
                    path: generateTermsPath(TermsKeys.LENDER_TYPE),
                    formatter: (value) =>
                        LENDER_TYPE_OPTIONS.find((item) => item.value === value)?.label || (value as ReactNode),
                    input: TermsSelectInput as ComponentType<unknown>,
                    selectProps: {
                        options: LENDER_TYPE_OPTIONS,
                        multiple: true,
                    },
                },
            ],
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Loan Name',
            name: TermsKeys.LOAN_NAME,
            value: details[TermsKeys.LOAN_NAME] ?? '',
            path: generateTermsPath(TermsKeys.LOAN_NAME),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Loan Purpose',
            input: TermsSelectInput as ComponentType<unknown>,
            isShowInputOnly: true,
            type: 'select',
            name: TermsKeys.LOAN_PURPOSE,
            value: details[TermsKeys.LOAN_PURPOSE] ?? [],
            path: generateTermsPath(TermsKeys.LOAN_PURPOSE),
            formatter: (value) => LOAN_PROGRAM_OPTIONS.find((item) => item.value === value)?.label || '',
            selectProps: {
                options: LOAN_PROGRAM_OPTIONS,
            },
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Loan Program',
            name: TermsKeys.LOAN_PROGRAM,
            value: details[TermsKeys.LOAN_PROGRAM] ?? '',
            path: generateTermsPath(TermsKeys.LOAN_PROGRAM),
            input: LoanProgramSelect as ComponentType<unknown>,
            isShowInputOnly: true,
            hidden: !details[TermsKeys.LOAN_PROGRAM_OPTIONS]?.find(
                (option: { uuid: string }) => option.uuid !== details[TermsKeys.LOAN_PROGRAM]
            ),
            inputProps: {
                options: details[TermsKeys.LOAN_PROGRAM_OPTIONS] ?? [],
                value: details[TermsKeys.LOAN_PROGRAM] ?? '',
                name: TermsKeys.LOAN_PROGRAM,
                optionsKey: TermsKeys.LOAN_PROGRAM_OPTIONS,
            },
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Loan ID',
            name: TermsKeys.LOAN_ID,
            value: details[TermsKeys.LOAN_ID] ?? '',
            path: generateTermsPath(TermsKeys.LOAN_ID),
            children: [
                ...getNodesFromLoanCustomIds({
                    loanIdsMetadata: details[TermsKeys.LOAN_IDS_METADATA] ?? {},
                    loanIdValues: details[TermsKeys.LOAN_ID_VALUES] ?? {},
                }),
            ],
        },
        {
            kind: 'node',
            id: uuid(),
            title: 'Loan Position',
            name: TermsKeys.LOAN_POSITION,
            value: details[TermsKeys.LOAN_POSITION] ?? '',
            path: generateTermsPath(TermsKeys.LOAN_POSITION),
        },
        {
            id: uuid(),
            kind: 'divider',
            dividerType: 'line',
        },
        {
            id: uuid(),
            kind: 'reference',
            title: 'Funded Date',
            value: getDateValue(details[TermsKeys.ORIGINATION_DATE]) ?? '',
            formatter: (value) => formatDetailsDateValue(value),
            onReferenceClick: onCustomClick,
        },
        {
            id: uuid(),
            kind: 'reference',
            title: 'Maturity Date',
            value: getDateValue(details[TermsKeys.MATURITY_DATE]) ?? '',
            formatter: (value) => formatDetailsDateValue(value),
            onReferenceClick: onCustomClick,
        },
        {
            id: uuid(),
            kind: 'divider',
            dividerType: 'line',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Coupon',
            name: TermsKeys.COUPON,
            value: details[TermsKeys.COUPON] ?? 0,
            type: 'number',
            path: generateTermsPath(TermsKeys.COUPON, 'value'),
            validate: decimalWithFiveDigitsValueValidator,
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            postfix: '%',
            children: [
                {
                    id: uuid(),
                    kind: 'node',
                    title: 'Coupon x365÷360',
                    name: TermsKeys.COUPON_365,
                    path: generateTermsPath(TermsKeys.COUPON, TermsKeys.COUPON_365),
                    value: details[TermsKeys.COUPON_365] ?? '',
                    notEditable: true,
                    postfix: '%',
                    input: EditableTextField as ComponentType<unknown>,
                    formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
                },
            ],
        },
        {
            kind: 'divider',
            dividerType: 'empty',
            id: uuid(),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Fixed/Floating',
            name: TermsKeys.COUPON_TYPE,
            value: details[TermsKeys.COUPON_TYPE] ?? CouponType.FIXED,
            path: generateTermsPath(TermsKeys.COUPON_TYPE),
            input: TermsSelectInput as ComponentType<unknown>,
            isShowInputOnly: true,
            type: 'select',
            selectProps: {
                options: COUPON_TYPE_OPTIONS,
            },
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Index',
            name: TermsKeys.INDEX_VALUE,
            value: details[TermsKeys.INDEX_VALUE] ?? 0,
            path: generateTermsPath(TermsKeys.INDEX_VALUE),
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            notEditable: true,
            postfix: '%',
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
            titleInput: {
                id: uuid(),
                name: TermsKeys.INDEX,
                value: details[TermsKeys.INDEX] ?? '',
                path: generateTermsPath(TermsKeys.INDEX),
                input: TermsSelectInput as ComponentType<unknown>,
                isShowInputOnly: true,
                selectProps: {
                    options: rates as TSelectOptions[],
                },
            },
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Index Date',
            name: TermsKeys.INDEX_DATE,
            value: getDateValue(details[TermsKeys.INDEX_DATE]) ?? '',
            path: generateTermsPath(TermsKeys.INDEX_DATE),
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING || !details[TermsKeys.INDEX],
            input: LoadDatePicker as ComponentType<unknown>,
            forceEditMode: true,
            formatter: (value) => formatDetailsDateValue(value),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Adjustment',
            name: TermsKeys.TERM_SOFT_ADJUSTMENT,
            path: generateTermsPath(TermsKeys.TERM_SOFT_ADJUSTMENT),
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            value: details[TermsKeys.TERM_SOFT_ADJUSTMENT] ?? 0,
            input: EditableTextField as ComponentType<unknown>,
            postfix: '%',
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Index Floor',
            name: TermsKeys.INDEX_FLOOR,
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            path: generateTermsPath(TermsKeys.INDEX_FLOOR),
            value: details[TermsKeys.INDEX_FLOOR] ?? 0,
            postfix: '%',
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Forward SOFR',
            name: TermsKeys.FORWARD_SOFR,
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            path: generateTermsPath(TermsKeys.FORWARD_SOFR),
            value: details[TermsKeys.FORWARD_SOFR] ?? 0,
            postfix: '%',
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Spread',
            name: TermsKeys.SPREAD,
            value: details[TermsKeys.SPREAD] ?? 0,
            path: generateTermsPath(TermsKeys.SPREAD),
            type: 'number',
            input: EditableTextField as ComponentType<unknown>,
            postfix: '%',
            validate: decimalWithFiveDigitsValueValidator,
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'IO/Amortizing',
            name: TermsKeys.IO_AMORTIZING,
            value: details[TermsKeys.IO_AMORTIZING] || [],
            path: generateTermsPath(TermsKeys.IO_AMORTIZING),
            input: MultiSelectWithApply as ComponentType<unknown>,
            isShowInputOnly: true,
            type: 'select',
            selectProps: {
                options: AMORTIZING_OPTIONS,
            },
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'IO Period',
            name: TermsKeys.IO_PERIOD,
            value: details[TermsKeys.IO_PERIOD] ?? 0,
            path: generateTermsPath(TermsKeys.IO_PERIOD),
            validate: decimalValueValidator,
            type: 'number',
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_TWO_ROUND),
            hidden: !details[TermsKeys.IO_AMORTIZING]?.includes(AmortizingOption.IO),
            postfix: 'years',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Amortization type',
            name: 'amortization',
            value: details[TermsKeys.AMORTIZATION] ?? '',
            input: TermsSelectInput as ComponentType<unknown>,
            path: generateTermsPath(TermsKeys.AMORTIZATION),
            isShowInputOnly: true,
            type: 'select',
            selectProps: {
                options: AMORTIZATION_OPTIONS,
            },
            hidden: !details[TermsKeys.IO_AMORTIZING]?.includes(AmortizingOption.AMORTIZING),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Amortization Schedule',
            name: TermsKeys.AMORTIZATION_SCHEDULE,
            value: details[TermsKeys.AMORTIZATION_SCHEDULE] ?? '',
            type: 'number',
            formatter: (value) => formatter.number(value),
            validate: decimalValueValidator,
            path: generateTermsPath(TermsKeys.AMORTIZATION_SCHEDULE),
            hidden:
                details[TermsKeys.AMORTIZATION] === AmortizationOption.AMORTIZATION_SCHEDULE
                    ? !details[TermsKeys.IO_AMORTIZING]?.includes(AmortizingOption.AMORTIZING)
                    : true,
            postfix: 'years',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Fixed Monthly Payment',
            name: TermsKeys.FIXED_MONTHLY_PAYMENT,
            value: details[TermsKeys.FIXED_MONTHLY_PAYMENT] ?? '',
            formatter: (value) => formatter.number(value),
            type: 'number',
            validate: decimalValueValidator,
            path: generateTermsPath(TermsKeys.FIXED_MONTHLY_PAYMENT),
            hidden:
                details[TermsKeys.AMORTIZATION] === AmortizationOption.FIXED_MONTHLY_PAYMENT
                    ? !details[TermsKeys.IO_AMORTIZING]?.includes(AmortizingOption.AMORTIZING)
                    : true,
            postfix: '$',
        },

        {
            id: uuid(),
            kind: 'divider',
            dividerType: 'empty',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Origination Fee',
            name: TermsKeys.ORIGINATION_FEE,
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            value: details[TermsKeys.ORIGINATION_FEE] ?? '',
            path: generateTermsPath(TermsKeys.ORIGINATION_FEE),
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            postfix: '%',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Exit Fee',
            name: TermsKeys.EXIT_FEE,
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            value: details[TermsKeys.EXIT_FEE] ?? 0,
            path: generateTermsPath(TermsKeys.EXIT_FEE),
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            postfix: '%',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Extension Fee',
            name: TermsKeys.EXTENSION_FEE,
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            value: details[TermsKeys.EXTENSION_FEE] ?? 0,
            path: generateTermsPath(TermsKeys.EXTENSION_FEE),
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            postfix: '%',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Prepayment Protection',
            name: TermsKeys.PREPAYMENT_PROTECTION,
            value: details[TermsKeys.PREPAYMENT_PROTECTION] ?? [],
            path: generateTermsPath(TermsKeys.PREPAYMENT_PROTECTION),
            input: PrepaymentProtectionInput as ComponentType<unknown>,
            isShowInputOnly: true,
        },
        {
            kind: 'divider',
            dividerType: 'empty',
            id: uuid(),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Interest Rate Cap',
            name: TermsKeys.INTEREST_RATE_CAP,
            type: 'number',
            validate: decimalWithFiveDigitsValueValidator,
            value: details[TermsKeys.INTEREST_RATE_CAP] ?? 0,
            path: generateTermsPath(TermsKeys.INTEREST_RATE_CAP),
            formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
            postfix: '%',
            hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
            children: [
                {
                    id: uuid(),
                    kind: 'node',
                    title: 'Interest Rate Cap Extension',
                    name: TermsKeys.INTEREST_RATE_CAP_EXTENSION,
                    type: 'number',
                    validate: decimalWithFiveDigitsValueValidator,
                    path: generateTermsPath(TermsKeys.INTEREST_RATE_CAP_EXTENSION),
                    value: details[TermsKeys.INTEREST_RATE_CAP_EXTENSION] ?? 0,
                    formatter: (value) => formatter.number(value, NUMBER_FORMAT_MIN_FIVE_ROUND),
                    postfix: '%',
                    hidden: details[TermsKeys.COUPON_TYPE] !== CouponType.FLOATING,
                },
            ],
        },
        {
            kind: 'divider',
            dividerType: 'line',
            id: uuid(),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Loan Rating',
            name: TermsKeys.LOAN_RATING,
            value: details[TermsKeys.LOAN_RATING] ?? '',
            type: 'select',
            input: TermsSelectInput as ComponentType<unknown>,
            path: generateTermsPath(TermsKeys.LOAN_RATING),
            isShowInputOnly: true,
            selectProps: {
                options: LOAN_RATING_OPTIONS,
            },
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Cash Sweep',
            name: TermsKeys.CASH_SWEEP,
            value: details[TermsKeys.CASH_SWEEP] ?? '',
            path: generateTermsPath(TermsKeys.CASH_SWEEP),
            input: TermsSelectInput as ComponentType<unknown>,
            isShowInputOnly: true,
            type: 'select',
            selectProps: {
                options: CASH_SWEEP_OPTIONS,
            },
        },
        {
            kind: 'divider',
            dividerType: 'line',
            id: uuid(),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Recourse',
            name: 'recourse',
            value: details[TermsKeys.RECOURSE] ?? '',
            path: generateTermsPath(TermsKeys.RECOURSE),
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Collateral',
            name: TermsKeys.COLLATERAL,
            path: generateTermsPath(TermsKeys.COLLATERAL),
            value: details[TermsKeys.COLLATERAL] ?? '',
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Status',
            name: TermsKeys.STATUS,
            path: generateTermsPath(TermsKeys.STATUS),
            value: details[TermsKeys.STATUS] ?? '',
            input: TermsSelectInput as ComponentType<unknown>,
            isShowInputOnly: true,
            type: 'select',
            selectProps: {
                options: STATUS_OPTIONS,
            },
        },
        {
            id: uuid(),
            kind: 'divider',
            dividerType: 'line',
            hidden: !isUserFMACTenant,
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'K-Deal Designation',
            name: TermsKeys.K_DEAL_DESIGNATION,
            value: details[TermsKeys.K_DEAL_DESIGNATION] ?? '',
            path: generateTermsPath(TermsKeys.K_DEAL_DESIGNATION),
            isShowInputOnly: true,
            input: DestinationSelect as ComponentType<unknown>,
            inputProps: {
                options: details[TermsKeys.K_DEAL_DESTINATION_OPTIONS] ?? [],
                value: details[TermsKeys.K_DEAL_DESIGNATION] ?? '',
                optionsKey: TermsKeys.K_DEAL_DESTINATION_OPTIONS,
                name: TermsKeys.K_DEAL_DESIGNATION,
            },
            hidden: !isUserFMACTenant,
        },
        {
            id: uuid(),
            kind: 'node',
            title: 'Accounting Designation',
            name: TermsKeys.ACCOUNTING_DESIGNATION,
            value: details[TermsKeys.ACCOUNTING_DESIGNATION] ?? '',
            path: generateTermsPath(TermsKeys.ACCOUNTING_DESIGNATION),
            input: DestinationSelect as ComponentType<unknown>,
            isShowInputOnly: true,
            inputProps: {
                options: details[TermsKeys.ACCOUNTING_DESTINATION_OPTIONS] ?? [],
                value: details[TermsKeys.ACCOUNTING_DESIGNATION] ?? '',
                optionsKey: TermsKeys.ACCOUNTING_DESTINATION_OPTIONS,
                name: TermsKeys.ACCOUNTING_DESIGNATION,
            },
            hidden: !isUserFMACTenant,
        },
    ];
};
