import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@ui/Dialog';
import PartialModal from '@scc/components/PartialModal';
import ContactUs from '@scc/components/ContactUs';
import SentIcon from '@scc/assets/icons/sent.svg';
import SentSuccessLayout from '@scc/components/PartialSuccessLayout';
import styles from './styles.module.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction='left' ref={ref} {...props} />);

const ContactUsModal = function ({ open, onClose, onSuccess }) {
    const [isSent, setIsSent] = useState(false);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
            keepMounted
            PaperProps={{
                classes: {
                    root: styles.dialog,
                },
            }}
            classes={{
                container: styles.modal,
            }}
        >
            {!isSent ? (
                <PartialModal title='Contact Us' onClose={onClose}>
                    <ContactUs
                        onSuccess={() => {
                            setIsSent(true);
                            setTimeout(() => {
                                onSuccess();
                                setIsSent(false);
                            }, 3000);
                        }}
                    />
                </PartialModal>
            ) : (
                <SentSuccessLayout
                    className={styles.success}
                    icon={SentIcon}
                    title={<span>Thank you!</span>}
                    description={
                        <>
                            <span>Your message has been successfully sent.</span>
                            <span>Have a nice day!</span>
                        </>
                    }
                />
            )}
        </Dialog>
    );
};

export default ContactUsModal;
