/* eslint-disable react/no-unescaped-entities */

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';

import CheckCircleSvg from '@scc/assets/icons/check-circle.svg';
import links from '@scc/router/links';

import Button from '@ui/Button';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Paper from '@ui/Paper';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import TickCircleSvg from '@assets/icons/outlined/tick-circle.svg';

import styles from './pricing.module.scss';
import data from './pricingData';

function PricingPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid className={styles.headerSpacer} lg={8} md={7} xs={11} item>
                <Typography color={colors['deepBlue-500']} variant={isMobile ? 'h1b' : 'head2b'} gutterBottom>
                    {data.content.title}
                </Typography>
                <Typography className={styles.contentDescription} variant='lgr' gutterBottom>
                    {data.content.description}
                </Typography>
            </Grid>

            <Grid className={`${styles.features} ${styles.bg}`} lg={4} md={5} xs={11} item>
                <ItemList items={data.features}>
                    {({ title }) => (
                        <Typography key={title} className={styles.feature} component='span' variant='h2b'>
                            <Icon color='transparent' component={CheckCircleSvg} stroke={colors.success} />
                            {title}
                        </Typography>
                    )}
                </ItemList>
                <span className={styles.bgItem} />
            </Grid>
            <Grid className={styles.button} xs={12} item>
                <Button component={Link} size='large' to={links.register.path}>
                    Let's get started, it's free!
                </Button>
            </Grid>
            <Grid xs={12} item>
                <Grid lg={6} md={7} xs={11} item>
                    <Typography color={colors['deepBlue-500']} variant={isMobile ? 'h1b' : 'head2b'} gutterBottom>
                        {data.additionalProServices.title}
                    </Typography>
                    <Typography className={styles.contentDescription} variant='lgr' gutterBottom>
                        {data.additionalProServices.description}
                    </Typography>
                </Grid>
            </Grid>

            <ItemList items={data.prices}>
                {({ prefix, postfix, value, title, description, features, options = {} }) => (
                    <Grid key={value} className={styles.price} md={6} xs={12} item>
                        <Paper className={styles.priceCard} variant={options.variant}>
                            <Typography className={styles.value}>
                                {prefix && (
                                    <Typography color={colors['darkNeutral-500']} component='span' variant='head2b'>
                                        {prefix}
                                    </Typography>
                                )}
                                <Typography color={colors.black} component='span' variant='head1b'>
                                    {value}
                                </Typography>
                                {postfix && (
                                    <Typography
                                        className={styles.postfix}
                                        color={colors['grey-400']}
                                        component='span'
                                        variant='h1b'
                                    >
                                        {postfix}
                                    </Typography>
                                )}
                            </Typography>
                            <Typography color={colors['blue-500']} component='h2' variant='h1b'>
                                {title}
                            </Typography>
                            <span className={styles.line} />
                            <Typography
                                color={colors['darkNeutral-500']}
                                variant={isMobile ? 'pr16' : 'h3b'}
                                gutterBottom
                            >
                                {description}
                            </Typography>
                            <div className={styles.features}>
                                <ItemList items={features}>
                                    {(feature) => (
                                        <Typography
                                            key={feature.title}
                                            className={styles.feature}
                                            component='span'
                                            variant={isMobile ? 'pr14' : 'h5r'}
                                        >
                                            <Icon color='success' component={TickCircleSvg} />
                                            {feature.title}
                                        </Typography>
                                    )}
                                </ItemList>
                            </div>
                        </Paper>
                    </Grid>
                )}
            </ItemList>
        </>
    );
}

export default PricingPage;
