import PropTypes from 'prop-types';

import AppBar from '@ui/AppBar';
import Container from '@ui/Container';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Toolbar from '@ui/Toolbar';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import CloseSvg from '@assets/icons/outlined/close.svg';

import styles from './header.module.scss';

const defaultProps = {
    title: null,
    onClose: () => {},
    hideButtonClose: false,
};

export function MobileHeader({ onClose, title, hideButtonClose, className }) {
    return (
        <AppBar className={className} color='inherit' position='sticky'>
            <Container maxWidth='xl'>
                <Toolbar className={styles.headerToolbar} variant='dense' disableGutters>
                    {!hideButtonClose && (
                        <div className={styles.button}>
                            <IconButton.Large onClick={onClose}>
                                <Icon color={colors['darkNeutral-500']} component={CloseSvg} />
                            </IconButton.Large>
                        </div>
                    )}

                    <div className={styles.container}>
                        <Typography color={colors.black} variant='h2b'>
                            {title}
                        </Typography>
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

MobileHeader.propTypes = {
    hideButtonClose: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
};
MobileHeader.defaultProps = defaultProps;

export default MobileHeader;
