import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import { useMemo, useState } from 'react';

import Popover from '@ui/Popover';
import Typography from '@ui/Typography';

import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import { loansApi } from '@api';

import { getPopoverMinWidth } from './helpers';
import styles from './styles.module.scss';

function ShowMoreProperties({ propertyCount, loanId }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [properties, setProperties] = useState([]);

    const popoverMinHeight = useMemo(() => getPopoverMinWidth(properties, propertyCount), [properties, propertyCount]);

    const onMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
        loansApi
            .getLoan(loanId)
            .then((response) => {
                if (!response.error) {
                    setProperties(
                        response.data.loanPropertyList.map(({ address, propertyType }) => ({
                            type: propertyType,
                            address,
                        }))
                    );
                }
            })
            .catch(console.warn);
    };

    return propertyCount > 1 ? (
        <div>
            <div className={styles.countIndicator}>
                <Typography
                    aria-haspopup='true'
                    aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
                    color={colors.white}
                    variant='psb10'
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                >
                    +{propertyCount - 1}
                </Typography>
            </div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                id='mouse-over-popover'
                open={!!anchorEl}
                sx={{
                    pointerEvents: 'none',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
                onClose={() => setAnchorEl(null)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: `${popoverMinHeight}px`,
                        minWidth: '350px',
                    }}
                >
                    {properties.length ? (
                        properties.map(({ type, address }, index) => {
                            const [mainText, ...secondaryText] = address.split(', ');
                            return (
                                <div
                                    key={address + type}
                                    className={cx(styles.itemWrapper, {
                                        [styles.borderBottom]: index !== properties.length - 1,
                                    })}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography color={colors['darkNeutral-500']} variant='pr12'>
                                            {mainText}
                                        </Typography>
                                        <Typography color={colors['grey-400']} variant='pr12'>
                                            {secondaryText.join(', ')}
                                        </Typography>
                                    </div>
                                    <div className={styles.itemType}>
                                        <PropertyTypeIcon type={type} />
                                        <Typography variant='pr12'>{type}</Typography>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className={styles.absolute}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    ) : null;
}

export default ShowMoreProperties;
