import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideDrawer, selectIsOpenDrawerState, showDrawer } from '@scc/store/ducks/ui/_drawer';

import AppBar from '@ui/AppBar';
import Box from '@ui/Box';
import Drawer from '@ui/Drawer';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Toolbar from '@ui/Toolbar';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import CloseSvg from '@assets/icons/outlined/close.svg';
import LocationSvg from '@assets/icons/outlined/location.svg';

import styles from './styles.module.scss';

const defaultProps = {
    anchor: 'left',
    header: '',
    paperProps: {
        className: styles.wrapper,
    },
};

function BaseDrawer({ anchor, header, headerClassName, subHeader, info, children, paperProps }) {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsOpenDrawerState);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            dispatch(showDrawer(anchor));
        } else {
            dispatch(hideDrawer());
        }
    };

    useEffect(
        () => () => {
            dispatch(hideDrawer());
        },
        []
    );

    return (
        <Drawer PaperProps={paperProps} anchor={anchor} open={isOpen} headerClassName onClose={toggleDrawer(false)}>
            <div className={styles.inner}>
                <div className={cx(styles.headerInfo, headerClassName)}>
                    <IconButton
                        className={styles.headerButton}
                        size={isMobile ? 'large' : 'medium'}
                        onClick={toggleDrawer(false)}
                    >
                        <Icon
                            aria-label='close'
                            color={colors['grey-600']}
                            component={CloseSvg}
                            fontSize={isMobile ? '16px' : '13px'}
                        />
                    </IconButton>
                    <AppBar className={styles.header} color='inherit' position='sticky'>
                        <Toolbar className={styles.headerContainer} variant='dense' disableGutters>
                            <Box className={styles.title} color='disabled' variant='caption'>
                                <Typography color='black' component='span' variant={isMobile ? 'h4b' : 'pr16'}>
                                    {header}
                                </Typography>
                                <div className={styles.locationWrapper}>
                                    <Icon color={colors['darkNeutral-500']} component={LocationSvg} fontSize='12px' />
                                    <Typography color={colors['grey-600']} variant={isMobile ? 'pr12' : 'pr14'}>
                                        {subHeader}
                                    </Typography>
                                </div>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </div>
                {info && (
                    <Grid justifyContent='center' container>
                        <Grid md={11}>{info}</Grid>
                    </Grid>
                )}
                {children}
            </div>
        </Drawer>
    );
}

BaseDrawer.defaultProps = defaultProps;

export default BaseDrawer;
