import { baseApi as api } from '../configs/portfolioAnalytics';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPortfolioAnalyticsHeaderForLoanPortfolio: build.query<
            GetPortfolioAnalyticsHeaderForLoanPortfolioApiResponse,
            GetPortfolioAnalyticsHeaderForLoanPortfolioApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v2/portfolio-analytics/loan-portfolio/${queryArg.loanPortfolioId}/header`,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetPortfolioAnalyticsHeaderForLoanPortfolioApiResponse =
    /** status 200 OK */ GenericJsonResponsePortfolioAnalyticsLoanHeaderDto;
export type GetPortfolioAnalyticsHeaderForLoanPortfolioApiArg = {
    loanPortfolioId: number;
};
export type UnderwritingMetricsDto = {
    lesser?: number;
    contract?: number;
    market?: number;
    rollover?: number;
    downMarket?: number;
    customScenarios?: {
        [key: string]: number;
    };
};
export type LoanPropertyDto = {
    id?: number;
    propertyId?: string;
    propertyName?: string;
    countyId?: string;
    parcelId?: string;
    userId?: number;
    groupId?: number;
    maturityDate?: string;
    address?: string;
    state?: string;
    propertyType?: string;
    url?: string;
    latitude?: number;
    longitude?: number;
    isDetailAnalyze?: boolean;
    size?: number;
    units?: number;
    underwritingRevenue?: UnderwritingMetricsDto;
    underwritingNoi?: UnderwritingMetricsDto;
    underwritingValuation?: UnderwritingMetricsDto;
    principal?: number;
    balance?: number;
};
export type PortfolioAnalyticsLoanStateDto = {
    state?: string;
    stateLat?: number;
    stateLon?: number;
    principal?: number;
    balance?: number;
    propertyList?: LoanPropertyDto[];
};
export type PortfolioAnalyticsLoanHeaderDto = {
    loanNumber?: number;
    balance?: number;
    unfundedBalance?: number;
    avgLoanSize?: number;
    avgInterestRate?: number;
    avgInterestRate2?: number;
    avgScore?: number;
    avgScore2?: number;
    avgLtv?: number;
    avgLtv2?: number;
    avgDscr?: number;
    avgDscr2?: number;
    avgDebtYield?: number;
    avgDebtYield2?: number;
    loanStates?: PortfolioAnalyticsLoanStateDto[];
    propertiesLtv?: {
        [key: string]: number;
    };
    propertiesDscr?: {
        [key: string]: number;
    };
};
export type GenericJsonResponsePortfolioAnalyticsLoanHeaderDto = {
    status?: string;
    data?: PortfolioAnalyticsLoanHeaderDto;
    message?: string;
    code?: string;
};
export const { useGetPortfolioAnalyticsHeaderForLoanPortfolioQuery } = injectedRtkApi;
