import { useEffect } from 'react';
import { addScript, removeScript } from '@utils/browser';

const useScript = ({
    src,
    id,
    async = false,
    defer = false,
    node = 'body',
    onLoad,
}) => {
    useEffect(() => {
        if (typeof document === 'undefined') return null;
        const script = addScript({
            src,
            id,
            async,
            defer,
            node,
            onLoad,
        });
        return () => removeScript(script?.id);
    }, []);
};

export default useScript;
