import colors from '@themes/palette/export.module.scss';

export const outlined = {
    backgroundColor: colors.white,
    color: colors['darkNeutral-500'],
    outline: `1px solid ${colors['common-border']}`,

    '&:hover': {
        outline: `1px solid ${colors['primary-outline']}`,
        backgroundColor: colors['primary-hover'],
    },
    '&:active': {
        outline: `1px solid ${colors['primary-dark']}`,
        color: '#090B1B',
        backgroundColor: colors['primary-selected'],
    },
    '&:focus-within': {
        outline: `1px solid ${colors['primary-outline']}`,
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
        border: 'none',
    },
};

export const outlinedSecondary = {
    backgroundColor: colors.white,
    color: colors['darkNeutral-500'],
    outline: `1px solid ${colors['common-border']}`,

    '&:hover': {
        outline: `1px solid ${colors['secondary-outline']}`,
        backgroundColor: colors['secondary-hover'],
    },
    '&:active': {
        outline: `1px solid ${colors['secondary-dark']}`,
        color: '#090B1B',
        backgroundColor: colors['secondary-selected'],
    },
    '&:focus-within': {
        outline: `1px solid ${colors['secondary-outline']}`,
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const outlinedInherit = {
    color: 'inherit',
    backgroundColor: colors.white,
    outline: `1px solid ${colors['common-border']}`,

    '&:hover': {
        outline: `1px solid ${colors['common-border']}`,
        backgroundColor: colors['lightGrey-200'],
    },
    '&:active': {
        outline: `1px solid ${colors['common-border']}`,
        color: '#090B1B',
        backgroundColor: colors['lightGrey-500'],
    },
    '&:focus-within': {
        outline: `1px solid ${colors['common-border']}`,
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const outlinedSuccess = {
    backgroundColor: colors.white,
    color: colors['darkNeutral-500'],
    outline: `1px solid ${colors['common-border']}`,

    '&:hover': {
        outline: `1px solid ${colors['success-outline']}`,
        backgroundColor: colors['success-hover'],
    },
    '&:active': {
        outline: `1px solid ${colors['success-dark']}`,
        color: '#090B1B',
        backgroundColor: colors['success-selected'],
    },
    '&:focus': {
        outline: `1px solid ${colors['success-outline']}`,
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const outlinedWarning = {
    backgroundColor: colors.white,
    color: colors['darkNeutral-500'],
    outline: `1px solid ${colors['common-border']}`,

    '&:hover': {
        outline: `1px solid ${colors['warning-outline']}`,
        backgroundColor: colors['warning-hover'],
    },
    '&:active': {
        outline: `1px solid ${colors['warning-dark']}`,
        color: '#090B1B',
        backgroundColor: colors['warning-selected'],
    },
    '&:focus': {
        outline: `1px solid ${colors['warning-outline']}`,
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};

export const outlinedError = {
    backgroundColor: colors.white,
    color: colors['darkNeutral-500'],
    outline: `1px solid ${colors['common-border']}`,

    '&:hover': {
        outline: `1px solid ${colors['error-outline']}`,
        backgroundColor: colors['error-hover'],
    },
    '&:active': {
        outline: `1px solid ${colors['error-dark']}`,
        color: '#090B1B',
        backgroundColor: colors['error-selected'],
    },
    '&:focus': {
        outline: `1px solid ${colors['error-outline']}`,
    },
    '&.Mui-disabled': {
        color: colors['grey-150'],
    },
};
