const prefix = 'grid-';

export const MESSAGES = {
    OPEN_FILE: `${prefix}open-file`,
    OPEN_ORIGINAL_FILE: `${prefix}open-original-file`,
    CHANGE_EXCEL_TAB: `${prefix}change-excel-tab`,
    CHANGE_RENT_ROLL_DATE: `${prefix}change-rent-roll-date`,
    STANDARDIZE: `${prefix}standardize`,
    CONFIRM: `${prefix}confirm`,
    CONFIRM_AUTO: `${prefix}confirm-auto`,
    SAVE: `${prefix}save`,
    REDO: `${prefix}redo`,
    UNDO: `${prefix}undo`,
    EXPORT_XLS: `${prefix}export-xls`,
    MERGE_TITLE: `${prefix}merge-title`,
    APPLY_TEMPLATE: `${prefix}apply-template`,
    FILTER_COLUMNS: `${prefix}filter-columns`,
    PARSE_TYPE: `${prefix}parse-type`,
    T12: `${prefix}t12`,
    SHOW_ADJUSTMENTS: `${prefix}show-adjustments`,
    HIDE_ADJUSTMENTS: `${prefix}hide-adjustments`,
    APPLY_DEFAULT_ADJUSTMENTS: `${prefix}apply-default-adjustments`,
    SHOW_NORMALIZED: `${prefix}show-normalized`,
    DISABLE_EDIT: `${prefix}disable-edit`,
    ENABLE_EDIT: `${prefix}enable-edit`,
    GET_SERIALIZED_TABLE_DATA: `${prefix}get-serialized-table-data`,
    OPEN_MULTI_COMBINED: `${prefix}open-multi-combined`,
    ACTIVE_EXTRACT_COLUMNS_ID: `${prefix}active-extract-columns-id`,
    EXPORT_RENT_ESCALATION_EXCEL: `${prefix}export-rent-escalation-excel`,
    EXPORT_RENT_ESCALATION_WITH_PERIOD_EXCEL: `${prefix}export-rent-escalation-with-periods-excel`,
    EXPORT_TO_PRECISION: `${prefix}export-to-precision`,
    RE_INIT_TABLE: `${prefix}re-init-table`,
    VARIANCE_REPORT: `${prefix}variance-report`,
    VARIANCE_REPORT_NEW: `${prefix}variance-report-new`,
    SERIALIZE_TABLE_DATA: `${prefix}serialize-table-data`,
    GET_STANDARDIZED_TENANT_NAMES: `${prefix}get-standardized-tenant-names`,
    UPDATE_STANDARDIZED_TENANTS_NAMES: `${prefix}update-standardized-tenant-names`,
    GRID_READY: `${prefix}ready`,
    CHANGE_VIEWPORT: `${prefix}change-table-view-port`,
    CHANGE_SCENARIO: `${prefix}change-scenario`,
    SELECT_PROFORMA_COLUMNS: `${prefix}select-proforma-columns`,
    GET_TENANTS_BREAKDOWN_LIST: `${prefix}get-tenants-breakdown-list`,
    SET_CURRENT_TENANTS_BREAKDOWN: `${prefix}set-current-tenants-breakdown`,
    SET_ESCALATION_UNIT: `${prefix}set-escalation-unit`,
    GET_ACTIVE_UNIT_METRIC: `${prefix}get-active-unit-metric`,
    MULTIPLY_VALUES: `${prefix}multiply-values`,
};

export const PARSING_TYPES = {
    PDF: 'PDFBOX',
    SCAN: 'OCR',
    BAD_SCAN: 'OCR2',
};
