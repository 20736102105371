import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery, createConfig } from '@scc/services/helpers';

const ENTITY = 'propertyDetails';

export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});

export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: [
        'generatePropertyCombinedRentRoll',
        'getPropertyProcessedRentRolls',
        'updatePropertyCombinedRentRoll',
        'deleteCombinedRentRoll',
        'getAnalyticsSettings',
        'saveAnalyticsSettings',
        'getPropertyCombinedStatementDateLabels',
        'getMarketAnalysis',
        'updateMarketAnalysis',
        'askPropertyOverview',
        'getRentRollMultiFamilyCompareData',
    ],
});
