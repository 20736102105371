import Image from '@scc/pages/AboutUs/components/Image';
import Label from '@scc/pages/AboutUs/components/Label';

import Container from '@ui/Container';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import OurImageWebP from '@/sc/images/team/our.jpg?format=webp&useResponsiveLoader=true';
import OurImage from '@/sc/images/team/our.jpg?useResponsiveLoader=true';

import styles from './styles.module.scss';

function Vision({ mobile }) {
    return (
        <div className={styles.vision}>
            <Container maxWidth='xl'>
                <div className={styles.wrapper}>
                    <Label>Our Mission & Vision</Label>
                    <Typography color={colors['darkNeutral-500']} component='span' variant={mobile ? 'h1b' : 'head1b'}>
                        Bring Speed. Enhance Insight. Cut Costs
                    </Typography>

                    <div className={styles.userInfo}>
                        <div className={styles.imgContainer}>
                            <Image
                                alt='Our Mission & Vision'
                                loading='lazy'
                                sizes={`${OurImage?.width}px`}
                                src={OurImage.src}
                                srcSet={OurImage.srcSet}
                                webp={OurImageWebP.srcSet}
                                width={OurImage?.width}
                            />
                        </div>
                        <div className={styles.rightBlock}>
                            <div className={styles.section}>
                                <Typography color={colors['darkNeutral-500']} component='span' variant='h5sb'>
                                    Mission
                                </Typography>
                                <Typography color={colors['darkNeutral-500']} variant='pr16'>
                                    To transform the commercial real estate transaction process, bring unparalleled
                                    insight and cut costs across the whole deal lifecycle via a powerful combination of
                                    deep industry expertise and superpowers of technology and AI.
                                </Typography>
                            </div>
                            <div className={styles.section}>
                                <Typography color={colors['darkNeutral-500']} component='span' variant='h5sb'>
                                    Vision
                                </Typography>
                                <Typography color={colors['darkNeutral-500']} variant='pr16'>
                                    To become the go-to platform of real estate investors, brokers, and lenders across
                                    the globe.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Vision;
