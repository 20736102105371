import { Link as RouterLink } from 'react-router-dom';

import { homeData } from '@scc/pages/Home/homeData';

import Link from '@ui/Link';
import Typography from '@ui/Typography';

import Image from '@components/Image';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

const {
    underwriting: { description, images, link, title },
} = homeData;

function Underwriting({ mobile }) {
    return (
        <div className={styles.underwriting}>
            <div className={styles.container}>
                <div className={styles.descriptionContainer}>
                    <div className={styles.titleWrapper}>
                        <Typography variant={mobile ? 'h1b' : 'head2b'}>{title}</Typography>
                    </div>
                    <div className={styles.descriptionWrapper}>
                        <Typography className={styles.description}>{description}</Typography>
                        <Link
                            aria-label={mobile ? link.mobileLabel : link.label}
                            className={styles.link}
                            component={RouterLink}
                            to={link.path}
                            variant='text'
                        >
                            <Typography color={colors.black} component='span' variant='lgsb'>
                                {mobile ? link.mobileLabel : link.label}
                            </Typography>
                        </Link>
                    </div>
                </div>
                <div className={styles.imageContainer}>
                    <div className={styles.imageWrapper}>
                        <Image
                            alt={title}
                            loading='lazy'
                            sizes={`${images.img1.src?.width}px`}
                            src={images.img1.src.src}
                            srcSet={images.img1.src.srcSet}
                            webp={images.img1.webp.srcSet}
                            width={images.img1.src?.width}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Underwriting;
