import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { dashboardApi } from '@api';

const entity = '[dashboardSupport]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const supportSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadSupport, setData: setSupport } = supportSlice.actions;

export default supportSlice.reducer;

export const selectSupportState = (store) => store.dashboard.support;

function* loadSupportSaga({ type, payload }) {
    yield put(apiRequest(payload, dashboardApi.getRequests, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const data = action.payload.data.map(({ address, propertyName, ...props }) => {
        const [firstAddressPart] = address?.split(', ');
        return {
            name: propertyName ?? firstAddressPart,
            address,
            ...props,
        };
    });
    yield put(setSupport(data));
}

export function* watchSupport() {
    yield takeLatest(loadSupport, loadSupportSaga);
}
