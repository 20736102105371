import { put, take, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { apiRequest, API_SUCCESS } from '@store/ducks/api';
import { ordersApi } from '@api';
import { showDrawer, hideDrawer } from '@scc/store/ducks/ui/_drawer';
import { loadAllRentRoll, cleanRentRoll } from '@scc/store/ducks/rentRoll';

const entity = '[order]';

const initialState = {
    error: null,
    data: {},
    status: '',
};

const orderSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        create() {},
        inProgress(state) {
            state.status = 'in progress';
        },
        cancel(state) {
            state.status = '';
        },
        complete(state) {
            state.status = 'complete';
        },
        addDocuments() {},
        infoResponse() {},
        update(state, action) {
            state.data = {...state.data, ...action.payload};
        },
    },
});

export const {
    create: createOrder,
    update: updateOrder,
    cancel: cancelOrder,
    inProgress: inProgressOrder,
    infoResponse: infoResponseOrder,
    addDocuments: addDocumentsOrder,
} = orderSlice.actions;

export default orderSlice.reducer;

export const selectOrderState = (state) => state.order;

function* propertyOrderSaga(action) {
    yield put(updateOrder(action.payload));
}

function* inProgressOrderSaga({ payload }) {
    yield put(showDrawer());
    yield put(loadAllRentRoll(payload));
}

function* cancelOrderSaga() {
    yield put(hideDrawer());
    yield put(cleanRentRoll());
}

function* addDocumentsOrderSaga(action) {
    yield put(apiRequest(action.payload, ordersApi.addDocuments, addDocumentsOrder.type));
    yield take([`${addDocumentsOrder.type} ${API_SUCCESS}`]);
    yield put(infoResponseOrder(action.payload));
    yield put(hideDrawer());
}

function* infoResponseOrderSaga({ payload }) {
    yield put(apiRequest(payload, ordersApi.moreInfoResponse, infoResponseOrder.type));
}

export function* watchOrder() {
    yield takeLatest(createOrder, propertyOrderSaga);
    yield takeLatest(inProgressOrder, inProgressOrderSaga);
    yield takeLatest(cancelOrder, cancelOrderSaga);
    yield takeLatest(addDocumentsOrder, addDocumentsOrderSaga);
    yield takeLatest(infoResponseOrder, infoResponseOrderSaga);
}
