import cx from 'classnames';

import Typography from '@ui/Typography';

import styles from './styles.module.scss';

export const TYPES = {
    appraisalReport: {
        name: 'ar',
        backgroundColor: '#ffb1ff',
    },
    rentRoll: {
        name: 'rr',
        backgroundColor: '#cceae0',
    },
    operatingStatement: {
        name: 'os',
        backgroundColor: '#d4f3fb',
    },
    budget: {
        name: 'b',
        backgroundColor: '#AFF186',
    },
    taxReturn: {
        name: 'tr',
        backgroundColor: '#E4F05D',
    },
    bankStatement: {
        name: 'bs',
        backgroundColor: '#F1D38666',
    },
    invoice: {
        name: 'i',
        backgroundColor: '#81F8C666',
    },
    developmentBudget: {
        name: 'db',
        backgroundColor: '#C5A08666',
    },
    mortgageStatement: {
        name: 'ms',
        backgroundColor: '#F1D38666',
    },
    propertyPurchaseAgreement: {
        name: 'pa',
        backgroundColor: '#CF93FF',
    },
    balanceSheet: {
        name: 'bs',
        backgroundColor: '#F16D8C',
    },
    guarantorFinancialStatement: {
        name: 'af',
        backgroundColor: '#8D57FF',
    },
    loanAgreement: {
        name: 'la',
        backgroundColor: '#6DD06B66',
    },
    leaseAgreement: {
        name: 'la',
        backgroundColor: '#FFE79F66',
    },
    insurancePolicy: {
        name: 'ip',
        backgroundColor: '#E6A8CD',
    },
    inspectionReport: {
        name: 'ir',
        backgroundColor: '#96547C',
    },
    infoMemo: {
        name: 'om',
        backgroundColor: '#F63EF666',
    },
    loanInfoMemo: {
        name: 'lo',
        backgroundColor: '#EA763666',
    },
    other: {
        name: '...',
        backgroundColor: '#B4C0E3',
    },
};

type DocumentTypeProps = {
    type: string;
    options?: Record<string, { name: string; backgroundColor: string }>;
    className?: string;
};

export function DocumentType({ type, options = TYPES, className }: DocumentTypeProps) {
    const { name = '?', backgroundColor = '#DEE3F366' } = options[type] || {};

    return (
        <div className={cx(styles.type, className)} style={{ backgroundColor }}>
            <Typography component='span' sx={{ opacity: '0.45', textTransform: 'uppercase' }} variant='psb10'>
                {name}
            </Typography>
        </div>
    );
}

export default DocumentType;
