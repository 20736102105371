import { put, select, take, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

import { bookmarksApi } from '@api';
import links from '@scc/router/links';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { loadListings } from '@store/ducks/listings';
import { selectIsUserAuth } from './auth';

const entity = '[bookmark]';

const emptyData = Array(5)
    .fill()
    .map(() => ({ id: uniqueId('skeleton-') }));

const initialState = {
    bookmark: null,
    bookmarks: emptyData,
    loaded: false,
    addOnLogin: false,
};

const bookmarkSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        toggle() {},
        fetchOne(state) {
            state.bookmark = null;
            state.bookmarks = [];
            state.loaded = false;
        },
        updateOne(state, { payload }) {
            state.bookmark = payload;
            state.addOnLogin = false;
            state.loaded = true;
        },
        fetchAll(state) {
            state.bookmarks = emptyData;
            state.bookmark = null;
            state.loaded = false;
        },
        updateAll(state, { payload }) {
            state.bookmarks = payload;
            state.loaded = true;
        },
        updateChanged(state, { payload: { id, parcelId, countyId } }) {
            state.bookmarks = state.bookmarks.map((bookmark) => {
                if (bookmark.countyId === countyId && bookmark.parcelId === parcelId) {
                    const bookmarkCopy = { ...bookmark };
                    bookmarkCopy.id = id;
                    return bookmarkCopy;
                }

                return bookmark;
            });
        },
        addOnLogin(state) {
            state.addOnLogin = true;
        },
        add() {},
    },
});

export const {
    toggle: toggleBookmark,
    fetchOne: fetchBookmark,
    updateOne: updateBookmark,
    fetchAll: fetchBookmarks,
    updateAll: updateBookmarks,
    updateChanged: updateChangedList,
    addOnLogin: doAddOnLogin,
    add: addBookmark,
} = bookmarkSlice.actions;

export default bookmarkSlice.reducer;

export const selectBookmarkState = (store) => store.bookmark;
export const selectIsBookmarkChecked = ({ bookmark: { bookmark, loaded } }) => !!bookmark?.id && loaded;

function* fetchBookmarkSaga({ payload, type }) {
    const isLogged = yield select(selectIsUserAuth);

    if (isLogged) {
        yield put(apiRequest(payload, bookmarksApi.getBookmark, type));
        const {
            payload: { data: existingBookmarkData },
        } = yield take(`${type} ${API_SUCCESS}`);

        const { addOnLogin } = yield select(selectBookmarkState);

        if (!addOnLogin) {
            yield put(updateBookmark(existingBookmarkData));
            return;
        }

        yield put(addBookmark(payload));
    }
}

function* addBookmarkSaga({ payload, type }) {
    const { parcelid: parcelId, countyid: countyId } = payload;
    yield put(
        apiRequest(
            {
                parcelId,
                countyId,
            },
            bookmarksApi.createBookmark,
            type
        )
    );
    const {
        payload: { data: createdBookmark },
    } = yield take(`${type} ${API_SUCCESS}`);
    yield put(updateBookmark(createdBookmark));
}

function* fetchAllBookmarksSaga({ type }) {
    const isLogged = yield select(selectIsUserAuth);
    if (isLogged) {
        yield put(apiRequest(null, bookmarksApi.getAllBookmarks, type));
        const response = yield take(`${type} ${API_SUCCESS}`);
        yield put(updateBookmarks(response.payload.data.content));
    }
}

function* toggleBookmarkSaga({ payload, type }) {
    const isLogged = yield select(selectIsUserAuth);
    if (!isLogged) {
        // todo
        // const { parcelId, countyId } = payload;
        // yield put(doAddOnLogin({ parcelId, countyId }));
        yield put(doAddOnLogin());
        window.router?.history.push(links.login.path, {
            from: { pathname: window.location.pathname },
        });
        return;
    }

    const { id: bookmark, reloadListing = false, ...parcel } = payload;

    if (bookmark) {
        yield put(apiRequest(bookmark, bookmarksApi.deleteBookmark, type));
    } else {
        yield put(apiRequest(parcel, bookmarksApi.createBookmark, type));
    }

    const {
        payload: { data },
    } = yield take(`${type} ${API_SUCCESS}`);

    if (reloadListing) {
        yield put(loadListings());
    }

    const { bookmarks } = yield select(selectBookmarkState);
    if (bookmarks.length && bookmarks[0].parcelId) {
        yield put(updateChangedList({ id: data?.id || null, ...parcel }));
    } else {
        yield put(updateBookmark(data));
    }
}

export function* watchBookmark() {
    yield takeLatest(fetchBookmark, fetchBookmarkSaga);
    yield takeLatest(fetchBookmarks, fetchAllBookmarksSaga);
    yield takeLatest(toggleBookmark, toggleBookmarkSaga);
    yield takeLatest(addBookmark, addBookmarkSaga);
}
