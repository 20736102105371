import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { reloadable } from '@utils/components';

import Footer from '@scc/components/Footer';
import MenuSideDrawer from '@scc/modules/MenuSideDrawer';
import pages from '@scc/router/links';
import { selectIsUserAuth, logoutRedirectAuth } from '@scc/store/ducks/auth';

import Box from '@ui/Box';

import useAddBodyClass from '@shared/hooks/useAddBodyClass';

import styles from './base.module.scss';
import Header from './BaseHeader';
import BaseHeaderMenu from './BaseHeaderMenu';
import { services } from './helpers';

const BaseHeaderProfile = reloadable(() => import('./BaseHeaderProfile/BaseHeaderProfile'), { ssr: false });

function BaseTemplate({ children }) {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory();

    const isLoggedIn = useSelector(selectIsUserAuth);
    const [{ containerProps = {} }, setProps] = useState({});

    const logoutAction = () => dispatch(logoutRedirectAuth(pages.home.path));

    useAddBodyClass(styles.overflow, !isLoggedIn);

    const handleGoToLink = (path) => {
        history.push(path);
    };
    const scrollRef = useRef(null);

    useEffect(() => {
        setProps({
            containerProps: {
                maxWidth: 'none',
                className: isLoggedIn ? styles.maxWidthFalse : '',
            },
            contentProps: {
                container: !isLoggedIn,
                className: cx({ [styles.base]: isLoggedIn, [styles.container]: isLoggedIn }),
            },
        });
    }, [isLoggedIn]);

    return (
        <>
            <Header
                barClassName={cx({ [styles.authed]: isLoggedIn })}
                containerProps={containerProps}
                // todo revert class
                onLogout={logoutAction}
            >
                {isLoggedIn ? <BaseHeaderProfile handleLogout={logoutAction} /> : <BaseHeaderMenu />}
            </Header>
            <Box
                className={cx(styles.wrapper, { [styles.unLoggedWrapper]: !isLoggedIn })}
                component='main'
                ref={scrollRef}
            >
                {isLoggedIn && <MenuSideDrawer />}
                <Box className={styles.content}>
                    {children}
                    {!isLoggedIn && (
                        <Footer links={services} mobile={isMobile} scrollRef={scrollRef} onClick={handleGoToLink} />
                    )}
                </Box>
            </Box>
        </>
    );
}

export default BaseTemplate;
