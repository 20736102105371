import request from '@utils/request';

const BASE_URL = '/geoGekko';
const BASE_USER_TRANSACTIONS_URL = '/api/v2/user-transactions';

// eslint-disable-next-line import/prefer-default-export
export async function getSimilarTransactions(data) {
    return request(`${BASE_URL}/get/similarTransactions`, {
        method: 'POST',
        body: JSON.stringify({ ...data }),
    });
}

export async function createSimilarTransactions(data) {
    return request(`${BASE_USER_TRANSACTIONS_URL}/create`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function overrideSimilarTransactions(data) {
    return request(`${BASE_URL}/user/parcel/overrides/save`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}
