const fontPatterns = [
    {
        prefix: 'r',
        fontWeight: 400,
    },
    {
        prefix: 'rb',
        fontWeight: 500,
    },
    {
        prefix: 'sb',
        fontWeight: 600,
    },
    {
        prefix: 'b',
        fontWeight: 700,
    },
];

const typicalTypographySizes = [
    {
        name: 'p',
        size: 9,
        lineHeight: 9,
        component: 'p',
    },
    {
        name: 'p',
        size: 10,
        lineHeight: 14,
        component: 'p',
    },
    {
        name: 'p',
        size: 12,
        lineHeight: 18,
        component: 'p',
    },
    {
        name: 'p',
        size: 13,
        lineHeight: 18,
        component: 'p',
    },
    {
        name: 'p',
        size: 14,
        lineHeight: 22,
        component: 'p',
    },
    {
        name: 'p',
        size: 16,
        lineHeight: 26,
        component: 'p',
    },
];

const typographySizes = [
    {
        ...typicalTypographySizes[0],
        name: 'ns',
    },
    {
        ...typicalTypographySizes[1],
        name: 'xs',
    },
    {
        ...typicalTypographySizes[2],
        name: 'sm',
    },
    {
        ...typicalTypographySizes[3],
        name: 'md',
    },
    {
        ...typicalTypographySizes[4],
        name: 'lg',
    },
];

export const typographyList = [
    // label HEAD
    {
        name: 'head1',
        size: 60,
        lineHeight: 72,
        component: 'h1',
        fontFamily: 'Open Sans Condensed, sans-serif',
    },
    {
        name: 'head2',
        size: 48,
        lineHeight: 56,
        component: 'h2',
        fontFamily: 'Open Sans Condensed, sans-serif',
    },
    {
        name: 'head3',
        size: 40,
        lineHeight: 48,
        component: 'h3',
        fontFamily: 'Open Sans Condensed, sans-serif',
    },
    // label HEADLINES [1-6]
    {
        name: 'h1',
        size: 32,
        lineHeight: 40,
        component: 'h1',
        fontFamily: 'Open Sans Condensed, sans-serif',
    },
    {
        name: 'h2',
        size: 24,
        lineHeight: 32,
        component: 'h2',
        fontFamily: 'Open Sans Condensed, sans-serif',
    },
    {
        name: 'h3',
        size: 20,
        lineHeight: 24,
        component: 'h3',
    },
    {
        name: 'h4',
        size: 18,
        lineHeight: 24,
        component: 'h4',
    },
    {
        name: 'h5',
        size: 16,
        lineHeight: 22,
        component: 'h5',
    },
    {
        name: 'h6',
        size: 14,
        lineHeight: 18,
        component: 'h6',
    },
    // label HEADLINES
    {
        name: 'h',
        size: 10,
        lineHeight: 12,
        component: 'span',
    },
    {
        name: 'h',
        size: 11,
        lineHeight: 14,
        component: 'span',
    },
    {
        name: 'h',
        size: 12,
        lineHeight: 16,
        component: 'span',
    },
    {
        name: 'h',
        size: 13,
        lineHeight: 16,
        component: 'span',
    },
    {
        name: 'h',
        size: 14,
        lineHeight: 16,
        component: 'span',
    },
    {
        name: 'h',
        size: 18,
        lineHeight: 24,
        component: 'span',
    },
    // label PARAGRAPHS
    {
        name: 'p',
        size: 11,
        lineHeight: 16,
        component: 'p',
    },
    ...typicalTypographySizes,
    // label typographySizes
    ...typographySizes,
];

const headersRegExp = /^((h[1-6])|(head[1-3])|(ns|xs|sm|md|lg))$/;

const generateTypography = ({ name, size, lineHeight, fontFamily = 'Open Sans, sans-serif' }) => {
    const typography = {};
    fontPatterns.forEach((val) => {
        const objKey = headersRegExp.test(name) ? `${name}${val.prefix}` : `${name}${val.prefix}${size}`;
        typography[objKey] = {
            fontSize: size,
            lineHeight: lineHeight ? `${lineHeight}px` : 1.2,
            fontWeight: val.fontWeight,
            fontFamily,
        };
    });
    return typography;
};

export const getTypography = (list) =>
    list
        .map((item) => generateTypography(item))
        .reduce(
            (acc, current) => ({
                ...acc,
                ...current,
            }),
            {}
        );

export const getVariantMappingTypography = (list) => {
    const typography = {};
    list.forEach((item) => {
        const { name, size, component } = item;

        fontPatterns.forEach((pattern) => {
            const { prefix } = pattern;
            const objKey = headersRegExp.test(name) ? `${name}${prefix}` : `${name}${prefix}${size}`;

            typography[objKey] = component;
        });
    });
    return typography;
};

export const getMediaPadding = (breakpoints) => {
    const { keys, paddings, values } = breakpoints;
    const padding = {};

    keys.forEach((key) => {
        padding[`@media (min-width:${values[key]}px)`] = {
            paddingLeft: paddings[key],
            paddingRight: paddings[key],
        };
    });

    return padding;
};
