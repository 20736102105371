const toolbar = () => ({
    MuiToolbar: {
        styleOverrides: {
            root: {
                minHeight: 64,
            },
        },
    },
});
export default toolbar;
