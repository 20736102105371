import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';

import { reloadable } from '@utils/components';

import Footer from '@scc/components/Footer';
import { services } from '@scc/templates/Base/helpers';

import Clients from './components/Clients';
import Hero from './components/Hero';
import Story from './components/Story';
import Vision from './components/Vision';

const Events = reloadable(() => import('./components/Events'), { ssr: false });

function AboutUs({ dialogProps }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory();

    const handleGoToLink = (path) => {
        history.push(path);
    };

    return (
        <>
            <Hero containerRef={dialogProps?.dialogContentRef} mobile={isMobile} />
            <Story mobile={isMobile} />
            <Vision mobile={isMobile} />
            <Clients mobile={isMobile} />
            <Events mobile={isMobile} />
            <Footer links={services} mobile={isMobile} onClick={handleGoToLink} />
        </>
    );
}

export default AboutUs;
