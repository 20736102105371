import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { setTemplateAuthMng, setErrorAuthMng } from '@scc/store/ducks/authManager';

import { userApi } from '@api';
import { apiRequest, API_SUCCESS, API_ERROR } from '@store/ducks/api';

const entity = '[demo]';

const initialState = {
    loading: false,
    loaded: false,
};

const demoSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        loaded(state) {
            state.loading = false;
            state.loaded = true;
        },
    },
});

export const { load: loadRequestDemo, loaded: loadedRequestDemo } = demoSlice.actions;

export default demoSlice.reducer;

export const selectDemoState = (state) => state.demo;

function* loadRequestDemoSaga({ payload, type }) {
    yield put(apiRequest(payload, userApi.demoRequest, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_SUCCESS)) {
        if (action.payload.status === 'fail') {
            yield put(setErrorAuthMng(action.payload));
            yield put(setTemplateAuthMng({ name: 'requestDemoAlertError' }));
        } else {
            yield put(setTemplateAuthMng({ name: 'requestDemoSuccess' }));
        }
    } else {
        yield put(setErrorAuthMng(action.payload));
        yield put(setTemplateAuthMng({ name: 'requestDemoAlertError' }));
    }

    yield put(loadedRequestDemo());
}

export function* watchDemo() {
    yield takeLatest(loadRequestDemo, loadRequestDemoSaga);
}
