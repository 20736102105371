import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { teamApi, userApi, loansApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[loans/team]';

const initialState = {
    error: null,
    data: [],
    existing: [],
    loaded: false,
    loading: false,
    existingLoaded: false,
    existingLoading: false,
};

const teamSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadExisting(state) {
            state.existingLoaded = false;
            state.existingLoading = true;
        },
        setExisting(state, action) {
            state.existingLoaded = true;
            state.existingLoading = false;
            state.existing = action.payload;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        update(state, action) {
            const { rowIndex, columnName = '', value } = action.payload;
            // eslint-disable-next-line no-unsafe-optional-chaining
            const key = columnName?.toLowerCase() in state?.data?.[rowIndex]?.userInfo || {};
            if (key) {
                state.data.splice(rowIndex, 1, {
                    ...state.data[rowIndex],
                    userInfo: {
                        ...state.data[rowIndex].userInfo,
                        [columnName?.toLowerCase()]: value,
                    },
                });
            }
        },
        delete() {},
        resend() {},
        setMemberAsLead() {},
    },
});

export const {
    load: loadTeam,
    loadExisting: loadExistingTeam,
    setData: setTeam,
    setExisting: setExistingTeam,
    update: updateTeam,
    delete: deleteMember,
    resend: resendInvite,
    setMemberAsLead: setMemberAsLeadTeam,
} = teamSlice.actions;

export default teamSlice.reducer;

export const selectTeamState = (store) => store.loans.team;

export const selectTeamInfoState = (store) => {
    const team = store?.loans?.team?.data;

    return team?.members?.map?.(({ userInfo, isLead, role, userId, userCompanyDTO, loanAccessLevel, teamId } = {}) => ({
        loanId: team?.loanId,
        teamType: team?.teamType,
        isLead,
        role,
        userId,
        companyName: userCompanyDTO?.companyName,
        loanAccessLevel,
        teamId,
        ...userInfo,
        title: userCompanyDTO?.position || userInfo?.title,
    }));
};

function* loadTeamSaga({ type, payload }) {
    yield put(apiRequest(payload, teamApi.getTeam, type));
    const response = yield take(`${type} ${API_SUCCESS}`);

    const {
        payload: { data },
    } = response;
    yield put(setTeam(data || []));
}

function* deleteMemberTeamSaga({ type, payload }) {
    const { teamId, userId, type: teamType, loanId } = payload;
    yield put(apiRequest({ teamId, userId }, teamApi.deleteMember, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadTeam({ type: teamType, loanId }));
}

function* resendInviteSaga({ type, payload }) {
    const { loanId, teamType } = payload;
    yield put(apiRequest(payload, loansApi.inviteUser, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadTeam({ loanId, type: teamType }));
}

function* loadExistingTeamSaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.getUsers, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setExistingTeam(action.payload.data));
}

function* setMemberAsLeadTeamSaga({ type, payload }) {
    yield put(apiRequest(payload, teamApi.setmemberAsLead, type));
    const { payload: { data } = {} } = yield take(`${type} ${API_SUCCESS}`);
    yield put(setTeam(data || []));
}

export function* watchTeam() {
    yield takeLatest(loadTeam, loadTeamSaga);
    yield takeLatest(loadExistingTeam, loadExistingTeamSaga);
    yield takeLatest(deleteMember, deleteMemberTeamSaga);
    yield takeLatest(resendInvite, resendInviteSaga);
    yield takeLatest(setMemberAsLeadTeam, setMemberAsLeadTeamSaga);
}
