import { createSlice } from '@reduxjs/toolkit';
import { all, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { selectUiDialogState, hideDialog } from '@store/ducks/ui/dialog';

import {
    selectAuthState,
    getCurrentUserAuth,
    setStatusAuth,
    getUserInfoAuth,
    selectIsUserAuth,
    verificationPhoneAuth,
    socialNetworkAuth,
} from './auth';
import { selectAuthMngState, setTemplateAuthMng } from './authManager';

const entity = '[common]';

const initialState = {
    menuOpen: true,
    currentPage: {},
    detailedPageParcel: null,
    appOffset: 0,
    hideTips: [],
};

const commonSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        toggleMenu() {},
        getUser() {},
        openMenu(state) {
            state.menuOpen = true;
        },
        closeMenu(state) {
            state.menuOpen = false;
        },
        loadPage() {},
        setPage(state, action) {
            state.currentPage = action.payload;
        },
        loadParcel(state, action) {
            state.detailedPageParcel = action.payload;
        },
        setOffset(state, action) {
            state.appOffset = action.payload;
        },
        setHideTips(state, action) {
            state.hideTips = [...state.hideTips, action.payload];
        },
    },
});

export const {
    toggleMenu: toggleMenuCommon,
    openMenu: openMenuCommon,
    closeMenu: closeMenuCommon,
    loadPage: loadPageCommon,
    setPage: setPageCommon,
    loadParcel: loadDetailedParcel,
    setOffset: setOffsetCommon,
    setHideTips: setHideTipsCommon,
} = commonSlice.actions;

export default commonSlice.reducer;

export const selectCommonState = (store) => store.common;

export const selectCurrentPageCommon = (store) => store.common.currentPage;

export const selectCurrentPageCommonQueries = (store) => {
    const { search: searchString = '' } = store.common.currentPage;
    const queries = new URLSearchParams(searchString);

    return Object.fromEntries(queries);
};

export const selectHideTipsCommon = createSelector(
    [selectCommonState, selectAuthState],
    ({ hideTips }, { currentUser: { userId } }) => ({
        skipMergeTips: hideTips?.includes(userId),
    })
);

function* toggleMenuCommonSaga() {
    const { menuOpen } = yield select(selectCommonState);
    if (menuOpen) {
        yield put(closeMenuCommon());
    } else {
        yield put(openMenuCommon());
    }
}

function* getCurrentUser() {
    if (typeof window === 'undefined') return;
    yield put(getCurrentUserAuth());
    yield take(setStatusAuth);
    const isLoggedIn = yield select(selectIsUserAuth);
    if (isLoggedIn) {
        yield put(getUserInfoAuth());
    }
    const { socialNetwork } = yield select(selectAuthState);
    const { template } = yield select(selectAuthMngState);
    if (socialNetwork && isLoggedIn) {
        yield put(verificationPhoneAuth());
        yield put(socialNetworkAuth({ payload: false }));
    }

    if (template.endsWith('Success')) {
        yield put(setTemplateAuthMng({ name: 'login' }));
    }
}

function* loadPageCommonSaga({ payload }) {
    const page = yield select(selectCurrentPageCommon);
    if (payload.path !== page.path) {
        const { isOpen } = yield select(selectUiDialogState);
        if (isOpen) {
            yield put(hideDialog());
        }
    }
    yield put(setPageCommon(payload));
}

export function* watchCommon() {
    yield all([fork(getCurrentUser)]);
    yield takeEvery(toggleMenuCommon, toggleMenuCommonSaga);
    yield takeEvery(loadPageCommon, loadPageCommonSaga);
}
