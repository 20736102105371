const formHelperText = () => ({
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                margin: '5px 8px 0',
                fontSize: 11,
                lineHeight: '14px',
                minHeight: 20,
            },
            contained: {
                margin: '1px 0 0',
            },
        },
    },
});
export default formHelperText;
