import cx from 'classnames';

import { getMapValue } from '@utils/components';

import LinearProgress from '@ui/LinearProgress';

import AddExistingDocumentsForm from '@shared/templates/forms/components/AddExistingDocumentsForm';
import PrepareDocumentsForm from '@shared/templates/forms/components/PrepareDocumentsForm';
import Success from '@shared/templates/forms/components/Success';
import UploadDocumentsForm from '@shared/templates/forms/components/UploadDocumentsForm';

import styles from './styles.module.scss';

export const TEMPLATES = {
    MAIN: 'main',
    EXISTING: 'existing',
    PREPARE: 'prepare',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
};

const DEFAULT_TEMPLATE = TEMPLATES.MAIN;

const uploadDocumentsFlowMap = new Map([
    [TEMPLATES.MAIN, UploadDocumentsForm],
    [TEMPLATES.EXISTING, AddExistingDocumentsForm],
    [TEMPLATES.PREPARE, PrepareDocumentsForm],
    [
        TEMPLATES.LOADING,
        () => (
            <div className={styles.progressWrapper}>
                <LinearProgress />
            </div>
        ),
    ],
    [TEMPLATES.SUCCESS, ({ ...props }) => <Success {...props} />],
    [TEMPLATES.ERROR, () => <div>Oops...</div>],
]);

function UploadDocuments({
    className,
    formId,
    initialFormData,
    flowMainTemplate,
    templateProps,
    loading,
    template,
    onUpdate,
    onSuccess,
    onToggleDocs,
    data = {},
    actions,
    canDelete,
}) {
    if (flowMainTemplate) uploadDocumentsFlowMap.set(TEMPLATES.MAIN, flowMainTemplate);

    if (flowMainTemplate) {
        uploadDocumentsFlowMap.set(TEMPLATES.MAIN, flowMainTemplate);
    } else if (uploadDocumentsFlowMap.get(TEMPLATES.MAIN) !== UploadDocumentsForm) {
        uploadDocumentsFlowMap.set(TEMPLATES.MAIN, UploadDocumentsForm);
    }

    const Form = getMapValue(uploadDocumentsFlowMap, template, DEFAULT_TEMPLATE);

    return (
        <Form
            actions={actions}
            canDelete={canDelete}
            className={cx(styles.wrapper, className)}
            data={data[template]}
            formId={formId}
            initialFormData={initialFormData}
            loading={loading}
            onChange={onToggleDocs}
            onSuccess={onSuccess}
            onUpdate={onUpdate}
            {...templateProps}
        />
    );
}

export default UploadDocuments;
