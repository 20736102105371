import isNil from 'lodash/isNil';

import { baseEmphasis, mediumBarPattern } from '@utils/charts/patterns';
import { getPropertyLink } from '@utils/properties';

import colors from '@themes/palette/export.module.scss';

export const groupingByPeriods = (groupingByYears) =>
    Object.entries(groupingByYears).reduce(
        (periods, [year, tenants]) => {
            const result = { ...periods };
            const currentYear = new Date().getFullYear();
            if (year === 'noDate') {
                result.noDate = tenants;
            } else if (year < currentYear) {
                result.old[year] = tenants;
            } else if (year > currentYear + 10) {
                result.future[year] = tenants;
            } else {
                result.current[year] = tenants;
            }
            return result;
        },
        { noDate: [], old: {}, current: {}, future: {} }
    );

export const singleGroup = ({ years, groupingByYears, xAxisLength, position, barColor }) => {
    return years?.reduce((accGroups, year, idx) => {
        const group = groupingByYears[year].map(({ tenantName, value }) => {
            const initArray = Array(xAxisLength).fill(null);
            const offsetPosition = isNil(position) ? idx + 1 : position;
            initArray[offsetPosition] = value;
            return {
                ...mediumBarPattern,
                itemStyle: {
                    color: barColor || colors['chart-01'],
                },
                stack: 'total',
                name: tenantName,
                data: initArray,
            };
        });

        return [...accGroups, ...group];
    }, []);
};

export const multipleGroup = (years, xAxisLength, groupingByYearsByAddresses, position, barColor) =>
    years.reduce((accGroups, year, idx) => {
        const group = Object.values(groupingByYearsByAddresses[year]).map((grouped) => {
            const initArray = Array(xAxisLength).fill(null);
            const offsetPosition = isNil(position) ? idx + 1 : position;
            initArray[offsetPosition] = { group: grouped, value: grouped.summ };
            return {
                ...mediumBarPattern,
                itemStyle: {
                    color: barColor || colors['chart-01'],
                },
                ...baseEmphasis,
                stack: 'total',
                name: grouped.propertyAddress,
                data: initArray,
            };
        });

        return [...accGroups, ...group];
    }, []);

export const groupingByYearsByAddresses = (data, period, field) => {
    return Object.entries(period).reduce((acc, [year, groupedTenantsByYear]) => {
        const summaryByYears = { ...acc };
        summaryByYears[year] = groupedTenantsByYear.reduce(
            (nestedAcc, { propertyAddress, propertyName, tenantName, [field]: selectedField }) => {
                const summaryByAddress = { ...nestedAcc };
                const property = (data.properties || []).find((item) => item.address === propertyAddress);
                if (propertyAddress in summaryByAddress) {
                    summaryByAddress[propertyAddress].items.push({ tenantName, [field]: selectedField });
                    summaryByAddress[propertyAddress].summ += selectedField;
                } else {
                    summaryByAddress[propertyAddress] = {
                        propertyAddress,
                        propertyName,
                        link: getPropertyLink(property),
                        summ: selectedField,
                        items: [{ tenantName, [field]: selectedField }],
                    };
                }

                return summaryByAddress;
            },
            {}
        );

        return summaryByYears;
    }, {});
};
