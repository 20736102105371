const Paper = () => ({
    MuiPaper: {
        styleOverrides: {
            rounded: {
                borderRadius: '4px',
            },
            none: {
                backgroundColor: 'transparent',
                boxShadow: 'unset !important',
            },
            outlined: {
                borderColor: '#f4f6fa',
            },
            services: {
                padding: '1rem',
            },
        },
    },
});
export default Paper;
