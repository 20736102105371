import Button from '@ui/Button';
import Typography from '@ui/Typography';

import BaseForm from '@components/BaseForm';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

const formId = 'refer-borrower-form';

function ReferFrom({ inputs, schema, onSuccess, loading, formProps = {}, children }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
                <div className={styles.titleWrapper}>
                    <Typography className={styles.title} variant='h3b'>
                        Who will be the borrower?
                    </Typography>
                    <Typography className={styles.subtitle} color={colors['grey-400']} variant='pr12'>
                        One of our financing consultant will contact the borrower today
                    </Typography>
                </div>
                <BaseForm
                    className={styles.form}
                    id={formId}
                    inputs={inputs}
                    schema={schema}
                    onSubmit={onSuccess}
                    {...formProps}
                />
                {children}
            </div>
            <div className={styles.footer}>
                <div className={styles.footerInner}>
                    <div className={styles.controls}>
                        <Button form={formId} loading={loading} type='submit'>
                            Send
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReferFrom;
