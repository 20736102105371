export default {
    content: {
        title: 'Free for Everyone, Always',
        description: 'We provide a number of free basic services to help you build your wealth without breaking the bank. Get free property valuation and hyper-local market analysis for over a million properties nationwide. Loan quotes are always free, and we negotiate lower costs for you to offset the cost of our service. Ready for the next step? Order a detailed property valuation report.'
    },
    additionalProServices: {
        title:'Additional Pro Services',
        description:'We also offer detailed Property Valuations and Loan Quotes. Ready for the next step? Order a detailed property valuation report.'
    },
    features: [
        {
            title: 'Free market analysis and valuation'
        },
        {
            title: 'Free tailored loan quotes'
        },
        {
            title: 'Free wealth monitoring'
        },
    ],
    prices: [
        {
            prefix: '$',
            value: '199.00',
            title: 'Detailed Property \n Valuation',
            description: 'Detailed analysis of property financial and operating performance',
            features: [
                {
                    title: 'Detailed report with market analysis and property valuation'
                },
                {
                    title: 'Sharable, web-based property page with interactive analysis'
                },
                {
                    title: 'Fee is waived if you close a loan through us. See Affordable Loan Service 👉'
                },
            ],
            options: {
                variant: 'none'
            }
        },
        {
            postfix: 'fee',
            value: '1%',
            title: 'Affordable Loan Service',
            description: 'You will only be charged for the loan service after you receive the loan',
            features: [
                {
                    title: 'Free detailed property valuation'
                },
                {
                    title: 'We select the best lender and terms for you from our database of 1000+ lenders'
                },
                {
                    title: 'Our clients save thousands of dollars a year by using Smart Capital Center'
                },
            ],
            options: {
                variant: 'outlined'
            }
        }
    ]
}