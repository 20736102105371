import download from 'downloadjs';
import clamp from 'lodash/clamp';
import floor from 'lodash/floor';
import has from 'lodash/has';
import isFinite from 'lodash/isFinite';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import round from 'lodash/round';
import trim from 'lodash/trim';
import uniqueId from 'lodash/uniqueId';

import colors from '@themes/palette/export.module.scss';

const K = 1000;

export const CURRENCY_LABEL_MAP = [
    { label: '', divider: 1 },
    { label: 'K', divider: K },
    { label: 'M', divider: K * K },
    { label: 'B', divider: K ** 3 },
    { label: 'T', divider: K ** 4 },
];

function log(n, base) {
    return Math.log(n) / (base ? Math.log(base) : 1);
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export const withoutCountryCode = (address = '') => address.split(', ').slice(0, -1).join(', ');

export const separateAddress = (fullAddress = '') => {
    const [address = '', ...country] = fullAddress.split(', ');
    return {
        address,
        country: country.join(', '),
    };
};

export const currencyPrefixFormatter = (inputValue, roundVal = 1) => {
    const value = Math.abs(inputValue);
    if (isFinite(parseFloat(Math.abs(value)))) {
        const parsedValue = parseFloat(value);
        const mapIndex = clamp(floor(log(parsedValue, K)), 0, CURRENCY_LABEL_MAP.length - 1);

        const { divider = 1, label = '' } = Number.isNaN(CURRENCY_LABEL_MAP[mapIndex])
            ? 0
            : CURRENCY_LABEL_MAP[mapIndex];
        const resValue = parsedValue / divider;
        return `${inputValue < 0 ? '-' : ''}${round(resValue, roundVal)}${label}`;
    }

    return value;
};

export const shallowCompare = (newObj, prevObj) => Object.keys(newObj).some((key) => newObj[key] !== prevObj[key]);

// eslint-disable-next-line default-param-last
export const filterPaths = (paths, target, initial = {}, initialBased) => {
    const hasDynamicPath = /\d+/gi.test(target);
    let pathname = target;

    if (hasDynamicPath) {
        pathname = target.match(/\/\D+\//i) ? target.match(/\/\D+\//i)[0] : target.match(/\/\D+/i)?.[0];
    }

    return paths.reduce(
        (acc, { routes, path, to = path, label, displayLabel, based = initialBased, name, view, status } = {}) => {
            if ((hasDynamicPath && to?.startsWith(pathname)) || to === target) {
                return { name, title: displayLabel ?? label, path: to, view, based, status };
            }
            if (Array.isArray(routes) && pathname.startsWith(to)) {
                return filterPaths(
                    routes,
                    pathname,
                    { name, title: displayLabel ?? label, path: to, view, based, status },
                    based
                );
            }
            return acc;
        },
        initial
    );
};

export const commaSeparateNumber = (val) => {
    if (isNumber(val) || isString(val)) {
        const parts = val.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
    if (!isNil(val)) {
        throw new Error(`${val} can't be parsed. Should be string or number`);
    }
    return val;
};

export const findAllByKey = (object = {}, targetKey = '') =>
    Object.entries(object).reduce((acc, [key, value]) => {
        if (key === targetKey) {
            return acc.concat(value);
        }
        // eslint-disable-next-line testing-library/await-async-queries
        return typeof value === 'object' ? acc.concat(findAllByKey(value, targetKey)) : acc;
    }, []);

const currencyFormatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
};

export const parseLocaleNumber = (stringNumber = '', locale = 'en-US') => {
    const thousandSeparator = Intl.NumberFormat(locale)
        .format(11111)
        .replace(/\p{Number}/gu, '');

    const decimalSeparator = Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/\p{Number}/gu, '');

    return parseFloat(
        String(stringNumber || 0)
            .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
            .replace(new RegExp(`\\${decimalSeparator}`), '.')
    );
};

export const formatter = {
    number: (value = 0, options = {}) => {
        const parsedValue = parseLocaleNumber(value);

        return isNumber(parsedValue) ? new Intl.NumberFormat('en-US', options).format(parsedValue) : value;
    },
    percent: (value = 0, options = { maximumSignificantDigits: 2 }) =>
        isNumber(value) ? new Intl.NumberFormat('en-US', { style: 'percent', ...options }).format(value) : value,
    currency: (value = 0, options = {}) =>
        isNumber(value)
            ? new Intl.NumberFormat('en-US', { ...currencyFormatOptions, ...options }).format(value)
            : value,
};

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const createEmptyData = ({ quantity = 1, idKey = 'id', prefix = 'data' }) =>
    Array(quantity)
        .fill()
        .map(() => ({ [idKey]: uniqueId(`${prefix}-`) }));

export const filterObjectByKeys = (obj, predicate) =>
    Object.keys(obj)
        .filter(predicate)
        .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

export const extractInitial = (name) => (name?.length > 0 ? name?.slice(0, 1).toUpperCase() : '');

export const fullNameToInitials = (name) => {
    const namesArray = name.split(' ');
    const len = namesArray.length - 1;
    return `${extractInitial(namesArray[0])}${extractInitial(namesArray[len])}`;
};

export const namesToInitials = (firstName, lastName) => `${extractInitial(firstName)}${extractInitial(lastName)}`;

export const randomText = (length) =>
    Array(length)
        .fill(0)
        .map(() => Math.random().toString(10).charAt(2))
        .join('');

export function memoizeCallback(callback) {
    const cache = {};

    if (typeof callback !== 'function') {
        throw new Error('The argument must be a function');
    }

    return (...arg) => {
        const key = JSON.stringify(arg[0]);
        cache[key] = cache[key] || callback.apply(this, arg);
        return cache[key];
    };
}

export const delay = (ms) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

export const memoizeFn = (f, queue, time = 1000) => {
    const cache = {};

    return async (...values) => {
        const args = JSON.stringify(values);
        if (queue && Object.keys(cache)?.length >= queue) {
            await delay(time);
        }
        cache[args] = cache[args] || (await f.apply(this, values));
        return cache[args];
    };
};

export const extendApiResponseData = (fn, { dataKey = 'content' } = {}) => {
    if (typeof fn !== 'function') {
        throw new Error('the argument must be a function');
    }
    let result;

    return async (...values) => {
        const { data = {}, ...res } = await fn.apply(this, values);
        const contentData = data?.[dataKey];
        if (Array.isArray(contentData)) {
            result = result ? result.concat(contentData) : contentData;
        }

        return {
            ...res,
            data: {
                ...data,
                [dataKey]: result,
            },
        };
    };
};

export function promisify(f, manyArgs = false) {
    return (...args) => {
        return new Promise((resolve, reject) => {
            function callback(err, ...result) {
                if (err) {
                    reject(err);
                } else {
                    resolve(manyArgs ? result : result[0]);
                }
            }

            args.push(callback);
            f.call(this, ...args);
        });
    };
}

export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export function plural(word, amount) {
    if (amount !== undefined && amount === 1) {
        return word;
    }
    const pluralRegExps = {
        '(quiz)$': '$1zes',
        '^(ox)$': '$1en',
        '([m|l])ouse$': '$1ice',
        '(matr|vert|ind)ix|ex$': '$1ices',
        '(x|ch|ss|sh)$': '$1es',
        '([^aeiouy]|qu)y$': '$1ies',
        '(hive)$': '$1s',
        '(?:([^f])fe|([lr])f)$': '$1$2ves',
        '(shea|lea|loa|thie)f$': '$1ves',
        sis$: 'ses',
        '([ti])um$': '$1a',
        '(tomat|potat|ech|her|vet)o$': '$1oes',
        '(bu)s$': '$1ses',
        '(alias)$': '$1es',
        '(octop)us$': '$1i',
        '(ax|test)is$': '$1es',
        '(us)$': '$1es',
        '([^s]+)$': '$1s',
    };

    const irregular = {
        move: 'moves',
        foot: 'feet',
        goose: 'geese',
        sex: 'sexes',
        child: 'children',
        man: 'men',
        tooth: 'teeth',
        person: 'people',
    };

    const uncountable = [
        'sheep',
        'fish',
        'deer',
        'moose',
        'series',
        'species',
        'money',
        'rice',
        'information',
        'equipment',
        'bison',
        'cod',
        'offspring',
        'pike',
        'salmon',
        'shrimp',
        'swine',
        'trout',
        'aircraft',
        'hovercraft',
        'spacecraft',
        'sugar',
        'tuna',
        'you',
        'wood',
    ];
    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
        return word;
    }
    // check for irregular forms
    // eslint-disable-next-line no-restricted-syntax
    for (const w in irregular) {
        if (has(pluralRegExps, w)) {
            const pattern = new RegExp(`${w}$`, 'i');
            const replace = irregular[w];
            if (pattern.test(word)) {
                return word.replace(pattern, replace);
            }
        }
    }
    // check for matches using regular expressions
    // eslint-disable-next-line no-restricted-syntax
    for (const reg in pluralRegExps) {
        if (has(pluralRegExps, reg)) {
            const pattern = new RegExp(reg, 'i');
            if (pattern.test(word)) {
                return word.replace(pattern, pluralRegExps[reg]);
            }
        }
    }
    return word;
}

export const getUserInitials = (fullName) =>
    fullName
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();

export const getScoreColor = (score) => {
    if (Number.isFinite(score)) {
        if (score > 66) {
            return colors.success;
        }
        if (score < 33) {
            return colors.error;
        }
        return colors.warning;
    }
    const val = score.toLowerCase();
    switch (val) {
        case 'a':
            return colors.success;
        case 'b':
            return colors.warning;
        case 'c':
            return colors.warning;
        default:
            return colors.error;
    }
};

export const stringOrFunctionChecker = (payload, data) => (typeof payload === 'function' ? payload(data) : payload);

export const shortenAddress = (address, divider, sliceTo) => {
    if (!address || typeof address !== 'string') return '';
    const div = typeof divider === 'string' ? divider : ' ';
    const to = typeof sliceTo === 'number' ? sliceTo : -2;
    return address.split(',').slice(0, to).map(trim).join(div);
};

export const downloadRequestedFile = async (payload, name) => {
    if (payload) {
        const { response = {}, file } = payload;
        const [, fileName = 'Report'] = response.headers?.get('content-disposition')?.match(/filename="(.*)"/) || [];
        download(file, name || fileName);
    }
};

export const scrollToElement = (e) => {
    const id = e.target.hash.replace('#', '');
    document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
    });
};

export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

export const getViewportWidth = () => {
    return window?.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth || 0;
};

export const objectsArrayXor = (first, second, property) => {
    return first.filter((firstItem) => !second.some((secondItem) => firstItem[property] === secondItem[property]));
};

export const getProportionalSizeBlock = ({ width = 100, height = 100, maxWidth = 100, maxHeight = 100 }) => {
    let w = width;
    let h = height;
    let ratio = 0;
    if (width > maxWidth) {
        ratio = maxWidth / width;
        w = maxWidth;
        h = height * ratio;
    }
    if (height > maxHeight) {
        ratio = maxHeight / height;
        h = maxHeight;
        w = width * ratio;
    }

    return {
        width: Math.round(w),
        height: Math.round(h),
    };
};
