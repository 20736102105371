import download from 'downloadjs';

import request from '@utils/request';

import { LOANS_VIEW_TYPE } from '@constants/loans';

const BASE_V2_URL = '/api/v2';
const BASE_URL = '/api/v2/user-loans';
const LOAN_LENDERS_URL = '/api/v2/loan-lenders';
const LOAN_LENDER_DATA_URL = '/api/v2/loan-lenders/application';
const LOAN_PORTFOLIOS_URL = '/api/v2/loan-portfolio';
const XLS_LOAN_PORTFOLIOS_URL = '/api/v2/xls-export/loan-portfolio';
const LOAN_SERVICE_IMPORT = '/api/v2/loan-service-import';
const LOAN_EXPORT_URL = `${BASE_V2_URL}/loans/export`;

export async function getLoans({
    size = 20,
    page = 0,
    countyId,
    parcelId,
    viewType = LOANS_VIEW_TYPE.ALL,
    search,
    userId,
    sort,
} = {}) {
    return request(BASE_URL, {
        params: { page, size, viewType, countyId, parcelId, search, userId, sort },
    });
}

export async function getPortfolios() {
    return request(LOAN_PORTFOLIOS_URL);
}

export async function getLoanPortfolioAnalyticsById(portfolioId) {
    return request(`${LOAN_PORTFOLIOS_URL}/analytics/${portfolioId}`);
}

export async function getLoan(loanId) {
    return request(`${BASE_URL}/${loanId}`);
}

export async function inviteUser(body) {
    return request(`${BASE_URL}/share`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function getActivity(id) {
    return request(`${BASE_URL}/events/${id}`);
}

export async function loginAndCreateUserAndRoom({ loanId } = {}) {
    return request(`/api/v2/rocket-chat/login-create`, {
        params: {
            loanId,
        },
    });
}

export async function loginIntoSCCviaToken(data) {
    return request(data.scc_url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            SCC_TOKEN: data.token,
            chat_token: data.chat_token,
        },
        csrfSafe: true,
        responseType: 'default',
    });
}

export async function saveLoanRequest(data) {
    return request(`${BASE_URL}/request/new`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getLoanRequest(loanId) {
    return request(`${BASE_URL}/request/${loanId}`);
}

export async function getLoanDetails({ id, detailsId } = {}) {
    return request(`${BASE_URL}/details/${id}/${detailsId}`);
}

export async function getLoanAnalytics() {
    return request(`${BASE_URL}/analytics`);
}

export async function getLoanStatementsFunding(id) {
    return request(`${BASE_URL}/funding/${id}`);
}

export async function exportLoanStatementsFundingExcel(id) {
    const res = await fetch(`${BASE_V2_URL}/loan-details-export/funding/${id}`);
    const [, fileName] = res.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await res.blob();
    download(blob, fileName);
}

export async function exportLoanStatementsDebtServiceExcel({ id, period, year }) {
    const urlParams = new URLSearchParams();

    Object.entries({ period, year }).forEach(([key, value]) => {
        if (value) {
            urlParams.append(key, value);
        }
    });

    const isEmptyParams = urlParams.toString().length === 0;

    const res = await fetch(
        `${BASE_V2_URL}/loan-details-export/debt-service/${id}${isEmptyParams ? '' : `?${urlParams.toString()}`}`
    );
    const [, fileName] = res.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await res.blob();
    download(blob, fileName);
}

export async function getLoanStatementsDebtService(id) {
    return request(`${BASE_URL}/debt-service/${id}`);
}

export async function updateLoanStatementsDebtService(loanId, historicalDataId, body) {
    return request(`${BASE_URL}/${loanId}/${historicalDataId}/historical-data`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function updateLoanStatementsFunding(id, body) {
    return request(`${BASE_URL}/funding`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function saveLoanDetails({ id, loanId, status, loanName, values = {} }) {
    return request(`${BASE_URL}/details`, {
        method: 'POST',
        body: JSON.stringify({
            rrLoanId: Number(id),
            loanId,
            loanName,
            status,
            jsonValues: values,
        }),
    });
}

export async function getTotal() {
    return request(`${BASE_URL}/totals`);
}

export async function getTotalFiltered({
    status = '',
    program: loanProgram = '',
    documentStatus = '',
    search = '',
    viewType = LOANS_VIEW_TYPE.FINANCING,
    loanModelAsOf,
} = {}) {
    return request(`${BASE_URL}/totals/filter`, {
        params: {
            detailsStatus: status,
            search,
            loanProgram,
            documentStatus,
            viewType,
            loanModelAsOf,
        },
    });
}

export async function deleteLoan(loanId) {
    return request(`${BASE_URL}/details/${loanId}`, {
        method: 'DELETE',
    });
}

export async function deletePortfolio(id) {
    return request(`${LOAN_PORTFOLIOS_URL}/${id}`, {
        method: 'DELETE',
    });
}

export async function filter({
    page = 0,
    size = 20,
    status = '',
    program: loanProgram = '',
    documentStatus = '',
    search = '',
    viewType = LOANS_VIEW_TYPE.FINANCING,
    sort,
    loanModelAsOf,
} = {}) {
    return request(`${BASE_URL}/filter`, {
        params: {
            detailsStatus: status,
            search,
            loanProgram,
            documentStatus,
            page,
            viewType,
            size,
            sort,
            loanModelAsOf,
        },
    });
}

export async function addLoan(body) {
    return request(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function searchAddress({ address } = {}) {
    return request(`${BASE_URL}/search`, {
        params: {
            parameter: address,
        },
    });
}

export async function getLoanRequestBorrowers(loanDetailsId, { page = 0, size = 20, sort } = {}) {
    return request(`${BASE_URL}/${loanDetailsId}/borrowers`, {
        params: {
            page,
            size,
            sort,
        },
    });
}

export async function getLoanRequestGuarantors(loanDetailsId, { page = 0, size = 20, sort } = {}) {
    return request(`${BASE_URL}/${loanDetailsId}/guarantors`, {
        params: {
            page,
            size,
            sort,
        },
    });
}

export async function addUserBorrowerToLoan(loanDetailsId, body) {
    return request(`${BASE_URL}/${loanDetailsId}/add-user-borrower`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function addCompanyBorrowerToLoan(loanDetailsId, body) {
    return request(`${BASE_URL}/${loanDetailsId}/add-company-borrower`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function getLoanRequestLenders(loanId, { page = 0, size = 20 } = {}) {
    return request(`${LOAN_LENDERS_URL}/${loanId}`, {
        params: {
            page,
            size,
        },
    });
}

export async function getLoanRequestLenderData(id) {
    return request(`${LOAN_LENDER_DATA_URL}/${id}`);
}

export async function deleteLoanRequestLender(id) {
    return request(`${LOAN_LENDERS_URL}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id,
        }),
    });
}

export async function addLoanLenders(body) {
    return request(LOAN_LENDERS_URL, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updateLoanLenders(id, body) {
    return request(`${LOAN_LENDERS_URL}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function getLoanRequestTenants({ size = 20, page = 0, search, propertyType } = {}) {
    return request(`${BASE_URL}/tenants`, {
        params: {
            size,
            page,
            search,
            propertyType,
        },
    });
}

export async function downloadExcel({ id, loanId }) {
    return fetch(`${BASE_URL}/excel/export?modelId=${id}&loanId=${loanId}`);
}

export async function downloadExcelFile({ id, loanId }) {
    const res = await downloadExcel({ id, loanId });
    const [, fileName] = res.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await res.blob();
    download(blob, fileName);
}

export async function getLoansHistory({ page = 0, size = 20 } = {}) {
    return request(`${BASE_URL}/history`, {
        params: {
            page,
            size,
        },
    });
}

export async function exportExcel(params) {
    const urlParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            urlParams.append(key, value);
        }
    });

    const isEmptyParams = urlParams.toString().length === 0;

    const url = `${LOAN_EXPORT_URL}${isEmptyParams ? '' : `?${urlParams.toString()}`}`;

    return fetch(url);
}

export async function exportExcelFile({ size = 20, page = 0, status, ...restParams }) {
    const res = await exportExcel({ size, page, detailsStatus: status, ...restParams });
    const [, fileName] = res.headers.get('content-disposition')?.match(/filename=(.*)/) || [];
    const blob = await res.blob();
    download(blob, fileName);
}

export async function getLoanCovenants({ loanDetailsId }) {
    return request(`${BASE_URL}/${loanDetailsId}/covenants`);
}

export async function getLoanCovenantsLatestValues({ loanDetailsId }) {
    return request(`${BASE_URL}/${loanDetailsId}/covenants/latest-values`);
}

export async function updateLoanCovenants({ loanId, body }) {
    return request(`${BASE_URL}/${loanId}/covenants`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function deleteLoanCovenant({ loanId, covenantId }) {
    return request(`${BASE_URL}/${loanId}/covenants/${covenantId}`, {
        method: 'DELETE',
    });
}

export async function createLoanCovenants({ loanId, body }) {
    return request(`${BASE_URL}/${loanId}/covenants`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updateLoanPortfolio({ id, name }) {
    return request(`${LOAN_PORTFOLIOS_URL}/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ name }),
    });
}

export async function createLoanPortfolio({ name, loanIds }) {
    return request(LOAN_PORTFOLIOS_URL, {
        method: 'POST',
        body: JSON.stringify({ name, loanIds }),
    });
}

export async function addLoanToPortfolio({ portfolioId, loanIds }) {
    return request(`${LOAN_PORTFOLIOS_URL}/add-loan`, {
        method: 'POST',
        body: JSON.stringify({ portfolioId, loanIds }),
    });
}

export async function removeLoanFromPortfolio({ portfolioId, loanIds }) {
    return request(`${LOAN_PORTFOLIOS_URL}/remove-loan`, {
        method: 'POST',
        body: JSON.stringify({ portfolioId, loanIds }),
    });
}

export async function getPortfolioLoans({ portfolioId }) {
    return request(`${LOAN_PORTFOLIOS_URL}/loans/${portfolioId}`);
}

export async function getLoanPortfolioTenants({ id, size = 10, page = 0 }) {
    return request(`${LOAN_PORTFOLIOS_URL}/tenants`, {
        params: {
            id,
            size,
            page,
        },
    });
}

export async function loanPortfolioExportExcel(loanId) {
    return request(`${XLS_LOAN_PORTFOLIOS_URL}/${loanId}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf,application/json',
        },
    });
}

export async function updateLoanDetailData({ id, body, isBulk = false }) {
    return request(`${BASE_URL}/${id}/data${isBulk ? '/bulk' : ''}`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function updateLoanLender({ loanId, lenderId }) {
    return request(`${BASE_URL}/set-lender`, {
        method: 'PUT',
        body: JSON.stringify({ loanDetailsId: loanId, lenderId }),
    });
}

export async function deleteLoanDetailData({ id, body }) {
    return request(`${BASE_URL}/${id}/data/delete`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}

export async function getUserLoansModelDates() {
    return request(`${BASE_URL}/model-dates`);
}

export async function getLoanData(loanId) {
    return request(`${BASE_URL}/${loanId}/data`);
}

export async function pullAdditionalDataFromServices() {
    return request(`${LOAN_SERVICE_IMPORT}`);
}

export async function updateIndexDate({ loanId, rateType, body }) {
    return request(`${BASE_URL}/${loanId}/data/index-date/${rateType}`, {
        method: 'PUT',
        body: JSON.stringify(body),
    });
}
