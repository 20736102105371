const NODE_ENV = typeof process !== 'undefined' && process.env.NODE_ENV;

class AppConfig {
    constructor(config = {}) {
        // keeps the config private.
        Object.assign(this, {
            // eslint-disable-next-line no-prototype-builtins
            has: (key) => config.hasOwnProperty(key),
            // eslint-disable-next-line consistent-return
            get: (key) => {
                if (this.has(key)) {
                    return config[key];
                }
                if (NODE_ENV !== 'test') {
                    throw new Error(`Key "${key}" was not found in clientConfig.`);
                }
            },
        });
    }
}

// eslint-disable-next-line no-undef
const options = typeof ClientConfig !== 'undefined' ? ClientConfig : undefined;

export default new AppConfig(options);
