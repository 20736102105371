const icon = () => ({
    MuiIcon: {
        styleOverrides: {
            root: {
                fill: 'currentColor',
                pointerEvents: 'none',
            },
        },
    },
});
export default icon;
