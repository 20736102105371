import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@ui/IconButton';
import Avatar from '@ui/Avatar';
import Input from '@ui/Input';
import Typography from '@ui/Typography';
import Icon from '@ui/Icon';
import { selectUserProfile } from '@scc/store/ducks/auth';
import { selectBuildingState } from '@scc/store/ducks/building';
import { propertyNotesApi } from '@api';
import NoteTextIcon from '@assets/icons/filled/note-text.svg';

import styles from './notes.module.scss';

const Notes = function () {
    const [notesContent, setNotesContent] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const userProfile = useSelector(selectUserProfile);
    const { parcel } = useSelector(selectBuildingState);

    useEffect(() => {
        if (parcel) {
            propertyNotesApi
                .getNotes({
                    parcelId: parcel?.parcelid,
                    countyId: parcel?.countyid,
                    type: propertyNotesApi.PROPERTY,
                })
                .then(({ status, data }) => {
                    if (status === 'success') {
                        setNotesContent(data.notes);
                    } else {
                        setNotesContent('');
                    }
                });
        }
    }, [parcel]);

    const handleSaveNoteContent = (value) => {
        propertyNotesApi
            .saveNote({
                notes: value,
                parcelId: parcel?.parcelid,
                countyId: parcel?.countyid,
                type: propertyNotesApi.PROPERTY,
            })
            .then(({ status, data }) => {
                if (status === 'success') {
                    setNotesContent(data.notes);
                } else {
                    setNotesContent('');
                }
                setEdit(false);
            });
    };

    return (
        <div className={styles.notesContainer}>
            <Typography variant='h1b' gutterBottom className={styles.heading}>
                <Icon component={NoteTextIcon} fontSize='23px' />
                Notes
            </Typography>

            {!isEdit ? (
                <div className={styles.notesView} onClick={() => setEdit(true)}>
                    <IconButton className={styles.userIcon}>
                        <Avatar src={userProfile.avatar} sx={{ width: 20, height: 20 }} alt='You'>
                            <Typography variant='hb14'>{userProfile.initials}</Typography>
                        </Avatar>
                    </IconButton>
                    <div className={styles.notesContent}>{notesContent || '+ Add Notes for this Property'}</div>
                </div>
            ) : (
                <Input
                    multiline
                    fullWidth
                    placeholder='+ Add Notes for this Property'
                    rows={4}
                    max
                    value={notesContent}
                    onChange={(e) => setNotesContent(e.target.value)}
                    className={styles.noteTextArea}
                    onBlur={(e) => handleSaveNoteContent(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <IconButton className={styles.userIcon}>
                                <Avatar src={userProfile.avatar} sx={{ width: 20, height: 20 }} alt='You'>
                                    <Typography variant='hb14'>{userProfile.initials}</Typography>
                                </Avatar>
                            </IconButton>
                        ),
                        maxLength: 255,
                    }}
                />
            )}
        </div>
    );
};

export default Notes;
