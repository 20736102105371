import LoadingButton from '@mui/lab/LoadingButton';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import PropTypes from 'prop-types';

import { createBaseProxy } from '@utils/components';

type Variant = 'icon' | 'icon-outlined' | 'icon-text' | 'primary' | 'outlined' | 'contained' | 'text';
type Color = 'primary' | 'secondary' | 'inherit' | 'success' | 'warning' | 'error';

export type ButtonProps = Omit<MuiButtonProps, 'variant' | 'color'> & {
    variant?: Variant;
    color?: Color;
    loading?: boolean;
};

const LoaderButton = createBaseProxy<ButtonProps>(LoadingButton, 'Button');
const Button = createBaseProxy<ButtonProps>(MuiButton, 'Button');
LoaderButton.Button = Button;

// todo remove presets
// label BASE BUTTON
LoaderButton.Link = createBaseProxy(MuiButton, 'Link', { variant: 'text', color: 'primary' });
LoaderButton.Primary = createBaseProxy(LoadingButton, 'Button', { size: 'large', variant: 'contained' });
LoaderButton.Outlined = createBaseProxy(LoadingButton, 'ButtonOutlined', {
    size: 'large',
    variant: 'outlined',
});

LoaderButton.Small = createBaseProxy(MuiButton, 'Button', { size: 'small' });
LoaderButton.NoGap = createBaseProxy(MuiButton, 'Button', { sx: { paddingLeft: 0, paddingRight: 0 } });

LoaderButton.Link.displayName = 'Link';

LoaderButton.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
};

LoaderButton.defaultProps = {
    color: 'primary',
    disabled: false,
    size: 'medium',
    variant: 'contained',
};

export default LoaderButton;
