import { put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { documentsApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { parseDocuments } from '@utils/store/middleware';

const entity = '[lenderDocs]';
const size = 20;

const initialState = {
    data: [],
    search: {},
    totalCount: null,
    loaded: false,
    loading: false,
};

const lendersSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadAll(state, action) {
            state.loaded = false;
            state.loading = true;
            state.data = [];
            state.search = action.payload.search;
        },
        loadMore() {},
        setData(state, action) {
            const { data, totalCount } = action.payload;
            state.data = data;
            if (totalCount) {
                state.totalCount = totalCount;
            }
            state.loaded = true;
            state.loading = false;
        },
        add(state) {
            state.loaded = false;
            state.loading = true;
        },
    },
});

export const {
    load: loadLenderDocuments,
    loadAll: loadAllDocuments,
    loadMore: loadMoreDocuments,
    setData: setDocuments,
} = lendersSlice.actions;

export default lendersSlice.reducer;

export const selectLenderDocumentsState = (store) => store.loans.lenderDocuments;

function* loadLenderDocumentsSaga({ type, payload }) {
    yield put(apiRequest({ lenderId: payload }, documentsApi.getLenderDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data, totalElements } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content), totalCount: totalElements }));
}

function* loadAllDocumentsSaga({ type, payload }) {
    const { search } = payload;
    yield put(apiRequest({ ...search }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setDocuments({ data: content, totalCount: totalElements }));
}

function* loadMoreDocumentsSaga({ type }) {
    const { search, data } = yield select(selectLenderDocumentsState);
    yield put(apiRequest({ ...search, page: data.length / size, size }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content, totalElements } = action.payload.data;
    yield put(setDocuments({ data: content, totalCount: totalElements }));
}

export function* watchLenderDocuments() {
    yield takeLatest(loadLenderDocuments, loadLenderDocumentsSaga);
    yield takeLatest(loadAllDocuments, loadAllDocumentsSaga);
    yield takeLatest(loadMoreDocuments, loadMoreDocumentsSaga);
}
