import request from '@utils/request';

const baseUrl = '/geoGekko/user/email';

export async function sendMessage(data) {
    return await request(baseUrl + '/send', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}
