import colors from '@themes/palette/export.module.scss';

const alert = () => ({
    MuiAlert: {
        styleOverrides: {
            outlined: {
                backgroundColor: colors.white,
            },
            outlinedSuccess: {
                '& .MuiAlert-icon': {
                    backgroundColor: colors.success,
                    color: colors.white,
                },
            },
            outlinedWarning: {
                '& .MuiAlert-icon': {
                    backgroundColor: colors.warning,
                    color: colors.white,
                },
            },
        },
    },
});

export default alert;
