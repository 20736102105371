import cx from 'classnames';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuItemsList } from '@scc/components/MenuItemsList';
import pages, { authedDrawerLinks } from '@scc/router/links';
import { selectCommonState, toggleMenuCommon } from '@scc/store/ducks/common';
import { setMessageGrid } from '@scc/store/ducks/grid/common';

import Box from '@ui/Box';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';

import BaseDrawer from '@components/BaseDrawer';

import colors from '@themes/palette/export.module.scss';

import BenchmarkingIcon from '@assets/icons/outlined/additem.svg';
import { MESSAGES } from '@constants/isolatedGrid';
import CartIcon from '@icons/outlined/bag-tick-3.svg';
import BookmarkIcon from '@icons/outlined/bookmark-3.svg';
import BuildingIcon from '@icons/outlined/buliding.svg';
import ClockIcon from '@icons/outlined/clock-2.svg';
import DocumentIcon from '@icons/outlined/document-1.svg';
import DollarIcon from '@icons/outlined/dollar-circle-2.svg';
import LoanIcon from '@icons/outlined/dollar-circle.svg';
import RobotIcon from '@icons/outlined/emoji-happy.svg';
import HomeIcon from '@icons/outlined/home-2.svg';
import MembersIcon from '@icons/outlined/members.svg';
import MenuArrowLeftIcon from '@icons/outlined/menu-arrow-left.svg';
import MenuArrowRightIcon from '@icons/outlined/menu-arrow-right.svg';
import MoneyReciveIcon from '@icons/outlined/money-recive-2.svg';
import PortfolioIcon from '@icons/outlined/portfolio.svg';
import TodosIcon from '@icons/outlined/task-square.svg';
import useDimensions from '@shared/hooks/useDimensions';

import styles from './styles.module.scss';

const drawerIcons = {
    [pages.dashboard.name]: HomeIcon,
    [pages.search.name]: HomeIcon,
    [pages.todos.name]: TodosIcon,
    [pages.myProperties.name]: CartIcon,
    [pages.bookmarks.name]: BookmarkIcon,
    [pages.history.name]: ClockIcon,
    [pages.history2.name]: ClockIcon,
    [pages.properties.name]: PortfolioIcon,
    [pages.portfolios.name]: PortfolioIcon,
    [pages.listings.name]: BuildingIcon,
    [pages.financings.name]: DollarIcon,
    [pages.refer.name]: MoneyReciveIcon,
    [pages.documents.name]: DocumentIcon,
    [pages.benchmarking.name]: BenchmarkingIcon,
    [pages.benchmarking.routes.map.name]: BenchmarkingIcon,
    [pages.members.name]: MembersIcon,
    [pages.loans.name]: LoanIcon,
    [pages.chatAi.name]: RobotIcon,
};

const DRAWER_MAX_WIDTH = 200;
const DRAWER_MIN_WIDTH = 58;
const DRAWER_MIN_WIDTH_WITH_SCROLL = 66;
const ICONS_SIZE = 18;
const ANIMATION_DURATION = 100;

function MenuSideDrawer() {
    const dispatch = useDispatch();
    const { menuOpen } = useSelector(selectCommonState);
    const navRef = useRef();
    const [shortDrawerWidth, setShortDrawerWidth] = useState(DRAWER_MIN_WIDTH);

    const asideWidth = useMemo(() => (menuOpen ? DRAWER_MAX_WIDTH : shortDrawerWidth), [menuOpen, shortDrawerWidth]);

    const triggerGridResize = useCallback(
        () =>
            setTimeout(
                () =>
                    dispatch(
                        setMessageGrid({
                            message: MESSAGES.CHANGE_VIEWPORT,
                        })
                    ),
                ANIMATION_DURATION
            ),
        [dispatch]
    );

    const toggleDrawer = () => {
        triggerGridResize();

        dispatch(toggleMenuCommon());
    };

    const { height } = useDimensions(navRef?.current);

    const checkContainerScroll = (ref) => {
        if (ref) {
            if (ref.current.scrollHeight > ref.current.clientHeight) {
                setShortDrawerWidth(DRAWER_MIN_WIDTH_WITH_SCROLL);
            } else {
                setShortDrawerWidth(DRAWER_MIN_WIDTH);
            }
        }
    };

    useLayoutEffect(() => {
        checkContainerScroll(navRef);
    }, [navRef, height]);

    return (
        <BaseDrawer
            anchor='left'
            as='aside'
            className={styles.aside}
            contentClassName={styles.content}
            paperProps={{
                sx: {
                    width: asideWidth,
                },
                classes: { root: cx(styles.paper, { [styles.width]: !menuOpen }) },
            }}
            variant='permanent'
        >
            <Box
                className={styles.drawerContent}
                // todo take height header with common
                sx={{
                    height: 'calc(100vh - 64px)',
                }}
            >
                <Box
                    className={cx(styles.toggle, {
                        [styles.menuClosed]: !menuOpen,
                    })}
                    onClick={toggleDrawer}
                >
                    <IconButton className={styles.arrowButton} color='inherit'>
                        <Icon
                            color={colors['grey-200']}
                            component={menuOpen ? MenuArrowLeftIcon : MenuArrowRightIcon}
                            fontSize={ICONS_SIZE}
                        />
                    </IconButton>
                </Box>
                <MenuItemsList
                    className={cx({ [styles.collapsed]: !menuOpen })}
                    hideLabel={!menuOpen}
                    iconSize={ICONS_SIZE}
                    iconsMap={drawerIcons}
                    items={authedDrawerLinks}
                    navRef={navRef}
                />
            </Box>
        </BaseDrawer>
    );
}

export default MenuSideDrawer;
