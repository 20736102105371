import * as yup from 'yup';
import propertyTypes, { getPropertyTypeSize, isMFLike } from '@utils/properties';
import { parseLocaleNumber } from '@utils/index';

const {
    OFFICE,
    RETAIL,
    INDUSTRIAL,
    SINGLE_FAMILY,
    VACANT_LAND,
    MIXED_USE,
    APARTMENT,
    WAREHOUSE,
    HEALTHCARE,
    AGRICULTURAL,
} = propertyTypes;

const SIZE_REQUIRED_FIELDS = [OFFICE, RETAIL, INDUSTRIAL, MIXED_USE, APARTMENT, WAREHOUSE, HEALTHCARE, SINGLE_FAMILY];
const LOT_SIZE_REQUIRED_FIELDS = [VACANT_LAND, AGRICULTURAL];
const UNITS_NOT_REQUIRED_FIELDS = [...SIZE_REQUIRED_FIELDS, ...LOT_SIZE_REQUIRED_FIELDS];

const checkRequired =
    (fields = [], required = true) =>
    (propertyType) =>
        required ? fields.includes(propertyType) : !fields.includes(propertyType);

const schema = yup.object({
    propertytype: yup.string().required('Property type is required'),
    units: yup.number().when('propertytype', {
        is: (propertyType) => getPropertyTypeSize(propertyType) === 'units',
        then: yup
            .number()
            .transform((value, originalValue) => (originalValue === '' ? undefined : value))
            .typeError('Size must be a number')
            .positive('Size must be positive')
            .min(1, 'Size must be higher than ${min} units')
            .notRequired()
            .when('propertytype', {
                is: checkRequired(UNITS_NOT_REQUIRED_FIELDS, false),
                then: yup.number().required('Property size is required'),
            }),
    }),
    size: yup
        .number()
        .transform((value, originalValue) =>
            originalValue === '' ? undefined : parseLocaleNumber(value || originalValue) || ''
        )
        .typeError('Size must be a number')
        .positive('Size must be positive')
        .min(101, 'Size must be higher than ${min} SF')
        .notRequired()
        .when('propertytype', {
            is: checkRequired(SIZE_REQUIRED_FIELDS),
            then: yup.number().required('Property size is required'),
        }),
    beds: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .when('propertytype', {
            is: (propertyType) =>
                // console.log('req beds', checkIsNotRequiredFields(propertyType))
                !isMFLike(propertyType) && propertyType !== 'Single Family',
            then: yup
                .number()
                .typeError('Size must be a number')
                .positive('Size must be positive')
                .min(0, 'Size must be higher than ${min} bedrooms')
                .max(9, 'Must be no more than ${max} bedrooms')
                .notRequired(),
        }),
    baths: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .when('propertytype', {
            is: (propertyType) => !isMFLike(propertyType) && propertyType !== 'Single Family',
            then: yup
                .number()
                .typeError('Size must be a number')
                .positive('Size must be positive')
                .min(0, 'Size must be higher than ${min} bathrooms')
                .max(9, 'Must be no more than ${max} bathrooms')
                .notRequired(),
        }),
    lotsize: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .typeError('Size must be a number')
        .positive('Size must be positive')
        .notRequired()
        .when('propertytype', {
            is: checkRequired(LOT_SIZE_REQUIRED_FIELDS),
            then: yup.number().required('Property lot size is required'),
        }),
    yearbuilt: yup
        .date()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .min(1800)
        .max(new Date().getFullYear())
        .notRequired(),
});

export default schema;
