import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';
import { ChangeEvent, memo } from 'react';

import { useDropdown } from '@scc/hooks/useDropdown';
import { useSearchDataQuery } from '@scc/hooks/useFetchDataQuery';

import Icon from '@ui/Icon';
import Popover from '@ui/Popover';

import colors from '@themes/palette/export.module.scss';

import SearchIcon from '@icons/outlined/search-normal.svg';

import { InfinityDropdown } from '../../InfinityDropdown';

import { anchorOrigin } from './helpers';
import { SearchableSelectProps } from './SearchableSelect.types';

function SearchableSelectComponent<T extends Record<string, unknown>>({
    url,
    renderOption,
    triggerComponent: Trigger,
    onChange,
    className,
    value,
    inputProps = {},
    popoverProps = {},
    params = {},
}: SearchableSelectProps<T>) {
    const [{ isOpen, anchorRef }, { handleAnchorClick, handleDropdownClose }] = useDropdown();

    const [{ total, data, loading }, { handleSearchChange, loadMoreHandler }] = useSearchDataQuery<T>({
        url,
        params,
    });

    const onSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
        handleSearchChange(event.target.value);
    }, 500);

    const handleChange = (newValue: T) => {
        handleSearchChange('');
        onChange(newValue);
        handleDropdownClose();
    };

    return (
        <div className={className}>
            <Trigger open={isOpen} value={value} onClick={handleAnchorClick} />
            <Popover
                anchorEl={anchorRef}
                anchorOrigin={anchorOrigin}
                open={isOpen}
                sx={{
                    '&& .MuiPopover-paper': {
                        minWidth: anchorRef?.clientWidth,
                    },
                }}
                onClose={(event: MouseEvent) => {
                    event.stopPropagation();
                    handleDropdownClose();
                }}
                {...popoverProps}
            >
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Icon color={colors['grey-600']} component={SearchIcon} fontSize='16px' />
                            </InputAdornment>
                        ),
                    }}
                    placeholder='Search'
                    variant='standard'
                    fullWidth
                    onChange={onSearchChange}
                    onClick={(event) => event.stopPropagation()}
                    {...inputProps}
                />
                <InfinityDropdown<T>
                    data={data}
                    isLoading={loading}
                    loadMoreHandler={loadMoreHandler}
                    renderOption={renderOption}
                    selected={value}
                    total={total}
                    onChange={handleChange}
                />
            </Popover>
        </div>
    );
}

export const SearchableSelect = memo(SearchableSelectComponent);
