import { reloadable } from '@utils/components';
import links from '@scc/router/links';

import GetStarted from '@scc/components/GetStarted';
import Jll from '@scc/assets/images/partners/jll.svg';
import AAreal from '@scc/assets/images/partners/aareal.svg';
import KeyBank from '@scc/assets/images/partners/keybank.svg';
import Pacific from '@scc/assets/images/partners/pacific.svg';
import Slatt from '@scc/assets/images/partners/slatt.svg';

const PropertyValuation = reloadable(() => import('./images/property-valuation.svg'));
const MortgageCosts = reloadable(() => import('./images/mortgage-costs.svg'));
const FreeMonitoring = reloadable(() => import('./images/free-monitoring.svg'));
const OurMission = reloadable(() => import('./images/our-mission.svg'));

import Image1 from './images/service-5.jpg?useResponsiveLoader=true';
import Image1WebP from './images/service-5.jpg?format=webp&useResponsiveLoader=true';
import Image2 from './images/service-3.jpg?useResponsiveLoader=true';
import Image2WebP from './images/service-3.jpg?format=webp&useResponsiveLoader=true';
import Image3 from './images/service-2.jpg?useResponsiveLoader=true';
import Image3WebP from './images/service-2.jpg?format=webp&useResponsiveLoader=true';
import Image4 from './images/service-1.jpg?useResponsiveLoader=true';
import Image4WebP from './images/service-1.jpg?format=webp&useResponsiveLoader=true';
import Image5 from './images/news-3.jpg?useResponsiveLoader=true';
import Image5WebP from './images/news-3.jpg?format=webp&useResponsiveLoader=true';
import Image6 from './images/news-2.jpg?useResponsiveLoader=true';
import Image6WebP from './images/news-2.jpg?format=webp&useResponsiveLoader=true';
import Image7 from './images/news-1.jpg?useResponsiveLoader=true';
import Image7WebP from './images/news-1.jpg?format=webp&useResponsiveLoader=true';
import Image8 from './images/service-4.jpg?useResponsiveLoader=true';
import Image8WebP from './images/service-4.jpg?format=webp&useResponsiveLoader=true';

import UserImage1 from './images/user-1.jpg?useResponsiveLoader=true';
import UserImage1WebP from './images/user-1.jpg?format=webp&useResponsiveLoader=true';
import UserImage2 from './images/user-2.jpg?useResponsiveLoader=true';
import UserImage2WebP from './images/user-2.jpg?format=webp&useResponsiveLoader=true';
import UserImage3 from './images/user-3.jpg?useResponsiveLoader=true';
import UserImage3WebP from './images/user-3.jpg?format=webp&useResponsiveLoader=true';

import Logo1 from './images/logo1.svg';
import Logo2 from './images/logo2.svg';
import Logo3 from './images/logo3.svg';

import Video1 from './videos/video1.mp4';

export default {
    services: [
        {
            title: 'Independent Property Valuation',
            description:
                'Get independent and comprehensive property valuation within a few hours - faster than anyone in the market. We give you an accurate assessment of property value and risk so you can invest with confidence',
            image: <PropertyValuation />,
        },
        {
            title: 'Dramatically Lower Mortgage Costs',
            description:
                'Don’t overpay for your mortgage! We use technology and data to cut costs and get you the best loan rates in real-time. We negotiate the best and most affordable loan options for you from our pool of 1,000+ lenders',
            image: <MortgageCosts />,
        },
        {
            title: 'Free Property Monitoring',
            description:
                'Monitor your assets without breaking the bank. Track performance for all of your properties. We provide you with all the data and tools you need to effectively track your wealth...for free!',
            image: <FreeMonitoring />,
        },
    ],
    about: [
        {
            component: <GetStarted showIcon={false} showBackgroundImages />,
        },
        {
            title: 'Our Mission',
            description:
                'We want to help you build wealth with real estate. We provide you with free data and tools needed to make smarter investment decisions. We save you money every step of the way by connecting you with the best loan options so lenders don’t eat up your profit. And our job isn’t done after we help you close a deal. Our smart software continually monitors your investments so you can preserve your wealth for generations to come',
            image: <OurMission />,
        },
        {
            title: 'Our Clients',
            description:
                'We created our software for some of the largest banks, insurance companies, and government agencies in the commercial real estate industry. Now we’re leveling the playing field and giving first-time investors and small property owners access to the same technology trusted by the largest lenders…for free!',
            partners: [Pacific, Slatt, Jll, AAreal, KeyBank],
        },
    ],
};

export const homeData = {
    aiSolution: {
        title: 'Smart Capital accelerates execution, cuts costs, and powers insight at every step of a real estate transaction - from deal sourcing, underwriting, and financing to monitoring and risk management',
        videos: {
            video1: { src: Video1 },
        },
    },
    propertyMonitoring: {
        title: 'Effortless Portfolio Monitoring',
        description:
            'Monitor your assets without breaking the bank. Track performance for all of your properties. We provide you with all the data and tools you need to effectively track your portfolio',
        link: {
            label: 'Learn More',
            mobileLabel: 'Learn More',
            path: links.services.routes.monitoring.path,
        },
        images: {
            img1: { src: Image1, webp: Image1WebP },
            img2: { src: Image2, webp: Image2WebP },
        },
    },
    lowCostFinancing: {
        title: 'Optimized Deal Financing',
        description:
            'Extensive network of 1,000+ lenders and bank-quality property underwriting help cut financing costs',
        link: {
            label: 'Learn More',
            mobileLabel: 'Learn More',
            path: links.services.routes.financing.path,
        },
        images: {
            img1: { src: Image3, webp: Image3WebP },
        },
    },
    underwriting: {
        title: 'Ultra-Fast Deal Underwriting',
        description: 'Same-day independent deal analysis powered by deep market insight helps win the best deals',
        link: {
            label: 'Learn More',
            mobileLabel: 'Learn More',
            path: links.services.routes.valuation.path,
        },
        images: {
            img1: { src: Image4, webp: Image4WebP },
        },
    },
    mission: {
        title: 'Bring Speed.\n Enhance Insight.\n Cut Costs.',
        description:
            'Smart Capital’s mission is to bring unparalleled insight and cut costs across the whole deal lifecycle via a powerful combination of deep industry expertise and superpowers of technology',
        image: {
            src: Image8,
            webp: Image8WebP,
        },
    },
    caseStudies: {
        title: 'Latest Updates',
        link: {
            label: 'View All Updates',
            path: 'https://www.smartcapital.center/news',
        },
        cases: [
            {
                id: 0,
                image: {
                    src: Image5,
                    webp: Image5WebP,
                },
                link: {
                    label: 'Powering Fast, Data-Driven Decision- Making at a Leading US Commercial Lender',
                    path: 'https://www.smartcapital.center/news/2023/05/03/accelerating-data-driven-decision-making-for-a-leading-us-commercial-mortgage-lender/',
                },
            },
            {
                id: 1,
                image: {
                    src: Image6,
                    webp: Image6WebP,
                },
                link: {
                    label: 'Faster, Smarter, Cheaper Real Estate Document Analysis with GPT4',
                    path: 'https://www.smartcapital.center/news/2023/06/01/faster-smarter-cheaper-document-analysis-with-smart-capital/',
                },
            },
            {
                id: 2,
                image: {
                    src: Image7,
                    webp: Image7WebP,
                },
                link: {
                    label: 'Smart Capital at Realcomm: Shaping the Future of CRE with AI',
                    path: 'https://www.smartcapital.center/news/2023/06/29/smart-capital-at-realcomm-ibcon-2023-shaping-the-future-of-cre-with-ai/',
                },
            },
        ],
    },
    feedbacks: [
        {
            title: '30x Productivity Gain',
            message: `Smart Capital's capability to extract, standardize, and analyze data from property financials, combined with automated reconciliations and analyses, has already transformed our operations. <br><br>Instead of the 30-40 minutes, it took us previously to process a single financial statement, it now takes 1-3 minutes with Smart Capital.`,
            author: 'Marina Smith',
            position: 'Director of Asset Management',
            location: 'Los Angeles',
            image: UserImage1,
            webp: UserImage1WebP,
            logo: Logo1,
        },
        {
            title: 'Removed Most of the Manual Work',
            message:
                'Smart Capital has removed most of the manual work that was taking a lot of time from our team. The solution allows us to focus on more productive, higher-level work, and we get the results much faster.',
            author: 'Mario Johnson',
            position: 'Credit Risk Manager',
            location: 'Los Angeles',
            image: UserImage2,
            webp: UserImage2WebP,
            logo: Logo2,
        },
        {
            title: '40% Less Time to Prepare Financial Model',
            message:
                'We are midway through our implementation of Smart Capital and we already achieved a 40% reduction in the time required to prepare a financial model for a loan.',
            author: 'Mario Johnson',
            position: 'Senior Vice President',
            location: 'Los Angeles',
            image: UserImage3,
            webp: UserImage3WebP,
            logo: Logo3,
        },
    ],
};
