import { createSlice } from '@reduxjs/toolkit';
import { takeLatest, call, put, delay, take, select } from 'redux-saga/effects';

import { setSimilarTransactions } from '@scc/store/ducks/similarTransactions';

import { buildingApi } from '@api';
import { apiRequest, API_SUCCESS } from '@store/ducks/api';

import { selectIsUserAuth } from './auth';

const entity = '[building]';

const initialState = {
    error: null,
    data: {},
    loading: false,
    loadingSimilar: false,
    loaded: true,
    parcel: false,
    breadcrumbs: [],
    similarBuildings: [],
    parcels: null,
};

const initialBuildingPayload = {
    disableSimilarContext: false,
    mode: 'statements',
};

const buildingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loading = true;
            state.loaded = false;
        },
        loadSimilar(state) {
            state.loadingSimilar = true;
        },
        copyParcel() {},
        setParcel(state, action) {
            state.parcel = action.payload;
        },
        update(state, action) {
            state.data = action.payload;
            state.loading = false;
            state.loaded = true;
            state.loadingSimilar = false;
        },
        getParcels(state) {
            state.loading = true;
            state.loaded = false;
        },
        setParcels(state, action) {
            state.parcels = action.payload.parcels;
            state.buildingId = action.payload.buildingId;
            state.loading = false;
            state.loaded = true;
        },
    },
});

export const {
    load: loadBuilding,
    loadSimilar: loadBuildingSimilar,
    update: updateBuilding,
    copyParcel: copyParcelBuilding,
    setParcel: setParcelBuilding,
    getParcels: getParcelsBuilding,
    setParcels: setParcelsBuilding,
} = buildingSlice.actions;

export default buildingSlice.reducer;

export const selectBuildingState = (state) => state.building;

export const selectBuildingData = (state) => state.building.data;

function* loadBuildingSaga(action) {
    yield delay(100);
    const response = yield call(buildingApi.getPropertyById, action.payload);

    const parcel = {
        countyid: response.countyId,
        parcelid: response.parcelId,
    };

    yield put(setParcelBuilding(parcel));
    yield put(apiRequest({ ...parcel, ...initialBuildingPayload }, buildingApi.getBuildingData, action.type));
    const { payload } = yield take([`${loadBuilding.type} ${API_SUCCESS}`]);

    yield put(
        setSimilarTransactions({
            similarTransactionsResponse: payload.similarTransactionsResponse,
            outlierTransactions: payload.outlierTransactions?.split(',').filter(Boolean) || [],
            notOutlierTransactions: payload.notOutlierTransactions?.split(',').filter(Boolean) || [],
        })
    );

    yield put(updateBuilding(payload));
}

function* loadBuildingWithSimilarSaga(action) {
    yield delay(100);
    yield put(apiRequest({ ...action.payload, ...initialBuildingPayload }, buildingApi.getBuildingData, action.type));
    const { payload } = yield take([`${loadBuildingSimilar.type} ${API_SUCCESS}`]);

    yield put(
        setSimilarTransactions({
            similarTransactionsResponse: payload.similarTransactionsResponse,
            outlierTransactions: payload.outlierTransactions?.split(',').filter(Boolean) || [],
            notOutlierTransactions: payload.notOutlierTransactions?.split(',').filter(Boolean) || [],
        })
    );

    yield put(updateBuilding(payload));
}

function* copyBuildingSaga() {
    const isLoggedIn = yield select(selectIsUserAuth);
    if (!isLoggedIn) return;
    const { parcel } = yield select(selectBuildingState);

    yield put(apiRequest(parcel, buildingApi.copyPublicParcel, copyParcelBuilding.type));

    const { payload } = yield take([`${copyParcelBuilding.type} ${API_SUCCESS}`]);

    const parcelParams = {
        countyid: payload.countyid,
        parcelid: payload.parcelid,
    };
    yield put(setParcelBuilding(parcelParams));
}

function* getParcelsBuildingSaga(action) {
    const { countyid = null, parcelid = null } = action?.payload || {};
    const { parcel } = yield select(selectBuildingState);
    yield put(
        apiRequest(
            { countyid: parcel?.countyid || countyid, parcelid: parcel?.parcelid || parcelid, oneParcelPerType: true },
            buildingApi.getParcelsByParcelId,
            action.type
        )
    );

    const {
        payload: { buildingId, parcels },
    } = yield take([`${action.type} ${API_SUCCESS}`]);
    yield put(
        setParcelsBuilding({
            buildingId,
            parcels,
        })
    );
}

export function* watchBuilding() {
    yield takeLatest(loadBuilding, loadBuildingSaga);
    yield takeLatest(loadBuildingSimilar, loadBuildingWithSimilarSaga);
    yield takeLatest(copyParcelBuilding, copyBuildingSaga);
    yield takeLatest(getParcelsBuilding, getParcelsBuildingSaga);
}
