import colors from '@themes/palette/export.module.scss';

const textField = () => ({
    MuiTextField: {
        defaultProps: {
            variant: 'standard',
        },
        variants: [
            {
                props: { variant: 'filled', color: 'primary' },
                style: {
                    backgroundColor: colors['grey-A100'],
                },
            },
        ],
    },
});

export default textField;
