import cx from 'classnames';

import { homeData } from '@scc/pages/Home/homeData';

import Container from '@ui/Container';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import animationStyles from '../../animation.module.scss';

import styles from './styles.module.scss';

const {
    aiSolution: { title, videos },
} = homeData;

function AiSolution({ mobile }) {
    return (
        <div className={cx('solution', styles.solution, animationStyles.solution)}>
            <video className={cx(styles.video, animationStyles.bgImage)} autoPlay loop muted playsInline>
                <source src={videos.video1.src} type='video/mp4' />
                Your browser does not support video.
            </video>

            <div className={cx(styles.backdropContainer, animationStyles.backdropContainer)}>
                <Container maxWidth='xl'>
                    <div className={styles.descriptionContainer}>
                        <Typography color={colors.white} variant={mobile ? 'h1b' : 'head2b'}>
                            {title}
                        </Typography>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default AiSolution;
