import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { userPermissionApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[configuration/team]';

const initialState = {
    error: null,
    data: {},
    presets: [],
    loaded: false,
    loading: false,
};

const teamSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadPresets() {},
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        setPresets(state, action) {
            state.presets = action.payload;
        },
        resetTeam(state) {
            state.data = initialState.data;
            state.loaded = initialState.loaded;
        },
    },
});

export const { load: loadTeam, setData: setTeam, setPresets, loadPresets, resetTeam } = teamSlice.actions;

export default teamSlice.reducer;

export const selectTeamState = (store) => store.configuration.team;

function* loadTeamSaga({ type, payload }) {
    yield put(apiRequest(payload, userPermissionApi.getGroup, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setTeam(action.payload.data));
}

function* loadPresetsSaga({ type }) {
    yield put(apiRequest(null, userPermissionApi.getPresets, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setPresets(action.payload.data));
}

export function* watchTeam() {
    yield takeLatest(loadTeam, loadTeamSaga);
    yield takeLatest(loadPresets, loadPresetsSaga);
}
