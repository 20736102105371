import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest, select } from 'redux-saga/effects';

import { isActualSelected } from '@components/DataTable/helpers';

import { configurationApi, userApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[allUsers]';
const size = 20;

const initialState = {
    list: [],
    groups: [],
    companies: [],
    loading: false,
    totalCount: null,
};

const allUsersSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadList(state) {
            state.loading = true;
        },
        loadMore() {},
        setList(state, { payload }) {
            state.loading = false;
            state.list = payload.content;
            state.totalCount = payload.totalElements;
        },
        removeFromList(state, ids) {
            state.list = state.list.filter((el) => {
                return !ids.includes(el.id);
            });
            state.totalCount -= ids.length;
        },
        [`loadList ${API_ERROR}`](state) {
            state.loading = false;
        },
        setGroups(state, { payload }) {
            state.loading = false;
            state.groups = payload.content;
        },
        loadGroups(state) {
            state.loading = true;
        },
        setCompanies(state, { payload }) {
            state.loading = false;
            state.companies = payload;
        },
        loadCompanies(state) {
            state.loading = true;
        },
        [`loadCompanies ${API_ERROR}`](state) {
            state.loading = false;
        },
        createCompany(state) {
            state.loading = true;
        },
        [`createCompany ${API_ERROR}`](state) {
            state.loading = false;
        },
        addMore(state, { payload: { content, totalElements } }) {
            state.list = [...state.list, ...content];
            state.totalCount = totalElements;
        },
        setUser(state, { payload }) {
            const userIndex = state.list.findIndex((user) => user.id === payload.id);

            if (userIndex !== -1) {
                state.list[userIndex] = payload;
            }
        },
        setUserGroupsBulk(state, { payload: { selection, securityGroupIds } }) {
            state.list = state.list.map((user) =>
                isActualSelected(user.id, selection) ? { ...user, securityGroupIds } : user
            );
        },
    },
});

export const {
    loadList: loadUsers,
    loadMore: loadMoreUsers,
    setList,
    loadGroups: loadUserGroups,
    setGroups,
    loadCompanies: loadUserCompanies,
    createCompany: createUserCompany,
    setCompanies,
    addMore: addMoreUsers,
    setUser,
    removeFromList,
    setUserGroupsBulk,
} = allUsersSlice.actions;

export default allUsersSlice.reducer;

export const selectAllUsersState = (store) => store.configuration.allUsers;

function* loadUsersSaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.getAllUsers, type));

    const action = yield take(`${type} ${API_SUCCESS}`);

    const { data } = action.payload;

    yield put(setList(data));
}

function* loadMoreUsersSaga({ type, payload }) {
    const { list } = yield select(selectAllUsersState);

    yield put(apiRequest({ page: list.length / size, size, ...payload }, userApi.getAllUsers, type));

    const action = yield take(`${type} ${API_SUCCESS}`);

    const { data } = action.payload;

    yield put(addMoreUsers(data));
}

function* loadGroupsSaga({ type }) {
    yield put(apiRequest({}, configurationApi.loadUserGroups, type));

    const action = yield take(`${type} ${API_SUCCESS}`);

    const { data } = action.payload;

    yield put(setGroups(data));
}

function* loadCompaniesSaga({ type }) {
    yield put(apiRequest({}, userApi.loadUserCompanies, type));

    const action = yield take(`${type} ${API_SUCCESS}`);

    const { data } = action.payload;

    yield put(setCompanies(data));
}

function* createUserCompanySaga({ type, payload }) {
    yield put(apiRequest(payload, userApi.createUserCompany, type));

    yield take(`${type} ${API_SUCCESS}`);

    yield put(loadUserCompanies());
}

export function* watchAllUsers() {
    yield takeLatest(loadUsers, loadUsersSaga);
    yield takeLatest(loadMoreUsers, loadMoreUsersSaga);
    yield takeLatest(loadUserGroups, loadGroupsSaga);
    yield takeLatest(loadUserCompanies, loadCompaniesSaga);
    yield takeLatest(createUserCompany, createUserCompanySaga);
}
