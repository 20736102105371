import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

export const API_REQUEST = 'API_REQUEST';

export const API_SUCCESS = 'API_SUCCESS';

export const API_ERROR = 'API_ERROR';

export const API_ERROR_CLEAN = 'API_ERROR_CLEAN';

export const API_ERROR_UNAUTHORIZED = 'API_ERROR_UNAUTHORIZED';

export const apiRequest = (data, method, entity, actionMeta = {}) => ({
    type: `${entity} ${API_REQUEST}`,
    payload: data,
    meta: {
        method,
        entity,
        ...actionMeta,
    },
});

export const apiSuccess = (res, entity, meta = {}) => ({
    type: `${entity} ${API_SUCCESS}`,
    payload: res,
    meta: { entity, ...meta },
});

export const apiError = (err, entity) => ({
    type: `${entity} ${API_ERROR}`,
    payload: err,
    meta: { entity },
});

export const apiErrorClean = (entity) => ({
    type: `${entity} ${API_ERROR_CLEAN}`,
    meta: { entity },
});

export const apiErrorUnauthorized = (payload, entity) => ({
    type: API_ERROR_UNAUTHORIZED,
    payload,
    meta: { entity },
});

function* apiRequestSaga({ payload, meta = {} }) {
    const { method, entity, ...actionMeta } = meta;

    try {
        const { error, ...data } = yield call(method, payload);
        if (!error) {
            yield put(apiSuccess(data, entity, actionMeta));
        } else {
            yield put(apiError(data, entity));
        }
    } catch (err) {
        yield put(apiError(err, entity));
    }
}

function* apiRequestErrorSaga({ meta = {}, payload = {} }) {
    const { redirect } = payload;
    const { entity } = meta;

    if (redirect) {
        yield put(apiErrorUnauthorized(payload, entity));
    }
    yield delay(5000);
    yield put(apiErrorClean(entity));
}

export function* watchApiRequest() {
    yield takeEvery((action) => /API_REQUEST$/.test(action.type), apiRequestSaga);
    yield takeLatest((action) => /API_ERROR$/.test(action.type), apiRequestErrorSaga);
}
