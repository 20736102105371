const accordion = () => ({
    MuiAccordion: {
        styleOverrides: {
            root: ({ theme }) => ({
                paddingLeft: 12,
                paddingRight: 12,
                [theme.breakpoints.down('md')]: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
            }),
        },
    },
});
export default accordion;
