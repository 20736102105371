// todo remove this component
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Dialog from '@ui/Dialog';

import styles from './contentDialog.module.scss';

const defaultProps = {
    open: true,
    state: {},
    children: null,
    template: {},
};

function ContentDialog({ open, onClose, noBack, paperProps, children, template }) {
    const history = useHistory();
    const [isOpen, setOpen] = React.useState(open);
    const descriptionElementRef = React.useRef(null);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
        if (noBack) return;
        if (history.location.key) {
            history.goBack();
        } else {
            history.push(template.closeLink);
        }
    };

    const child = React.cloneElement(children, {
        dialogProps: {
            onClose: handleClose,
            dialogContentRef: descriptionElementRef,
        },
    });

    React.useEffect(() => {
        if (isOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose} PaperProps={paperProps} fullScreen>
            <DialogContent tabIndex={-1} ref={descriptionElementRef} className={styles.content}>
                {child}
            </DialogContent>
        </Dialog>
    );
}

ContentDialog.defaultProps = defaultProps;

export default ContentDialog;
