const autocomplete = () => ({
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '.MuiOutlinedInput-root': {
                    padding: 0,
                    background: 'transparent',
                    borderRadius: 4,
                    '.MuiAutocomplete-input': {
                        padding: '13px 16px',
                    },
                },
                '.MuiInput-root': {
                    paddingBottom: 0,
                },
            },
            popper: {
                marginTop: '10px !important',
                background: 'transparent',
            },
            listbox: {
                padding: 0,
                maxHeight: 310,
                height: '100%',
            },
            noOptions: {
                maxHeight: 310,
                padding: 0,
                height: '100%',
            },
            inputRoot: {
                padding: '0 30px 0.5rem 8px',
            },
        },
    },
});

export default autocomplete;
