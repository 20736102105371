export const prepareGridColumnsOptions = (columns) =>
    columns?.map(({ year, count, month, dateLabel, id }) => ({
        labelTop: `${count} months ending`,
        labelBottom: `${month} ${year}`,
        value: `${count}-${month}-${year}${dateLabel ? `-${dateLabel}` : ''}`,
        label: `${month} ${year} ${count} months ending`,
        dateLabel,
        year,
        count,
        month,
        id,
    }));

export const POLLING_DELAY = 10000;

export const removeKeyFromSearch = (search, key) => {
    const params = new URLSearchParams(search);

    if (!params.has(key)) return null;

    params.delete(key);
    return params.toString();
};
