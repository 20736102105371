import { commaSeparateNumber } from '@utils/index';

import styles from '../styles.module.scss';

type StackProps = {
    series: {
        axisValue: string;
        value: number;
        seriesName: string;
    }[];
    formatName?: (value: string) => string;
};

const stack = ({ series, formatName }: StackProps) => {
    const title = formatName ? formatName(series[0].axisValue) : series[0].axisValue;

    const total = series.reduce((acc, cur) => {
        return acc + cur.value ?? 0;
    }, 0);

    return `
      <div>
        <div class="${styles.header}">
           <span>${title}</span>
           <span>${commaSeparateNumber(total)}</span>
        </div>
        <hr class="${styles.line}" />
        <div>
          ${series
              .filter((item) => item.value)
              .map(
                  (item) => `
                    <div class="${styles.item}">
                      <span>${item.seriesName}</span>
                      <span>${commaSeparateNumber(item.value)}</span>
                    </div>
                  `
              )
              .join('')}
      </div>
    `;
};

export default stack;
