import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AcceptTerms from '@scc/components/AcceptTerms';
import VerificationForm from '@scc/components/RegisterForm/VerificationForm';
import links from '@scc/router/links';
import { registerAuth } from '@scc/store/ducks/auth';
import {
    registrationSecondStepAuthMng,
    selectAuthMngState,
    setValidEmailAuthMng,
    startCheckEmailAuthMng,
} from '@scc/store/ducks/authManager';

import Button from '@ui/Button';
import Divider from '@ui/Divider';
import Link from '@ui/Link';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import LoginSocialNetworkButton from '@components/SocialNetworkButton';

import colors from '@themes/palette/export.module.scss';

import EmailForm from './EmailForm';
import styles from './styles.module.scss';

function RegisterForm({ user, onStepChange = () => {}, loading }) {
    const dispatch = useDispatch();

    const { template, emailIsValid, loading: checkEmailLoading, error } = useSelector(selectAuthMngState);
    const [continueWithEmail, setContinueWithEmail] = useState(false);

    useEffect(() => {
        if (emailIsValid) {
            onStepChange('verification');
        }
        return () => dispatch(setValidEmailAuthMng(false));
    }, [emailIsValid, checkEmailLoading]);

    const handleFirstStepSuccess = ({ password, email }) => {
        dispatch(startCheckEmailAuthMng({ email }));
        user.pwd = password;
        user.email = email;
    };

    const handleVerificationSuccess = (data) => {
        dispatch(
            registerAuth({
                meta: {
                    callback: () => dispatch(registrationSecondStepAuthMng()),
                },
                ...data,
                ...user,
            })
        );
    };

    function Form() {
        return (
            <div className={styles.formWrapper}>
                <Stack className={styles.socialButtonsFull} spacing={6}>
                    <LoginSocialNetworkButton margin='dense' size='extra-large' type='google' fullWidth />
                    <LoginSocialNetworkButton margin='dense' size='extra-large' type='facebook' fullWidth />
                    <LoginSocialNetworkButton margin='dense' size='extra-large' type='linkedin' fullWidth />
                </Stack>
                <Divider sx={{ margin: '34px 0' }}>or</Divider>
                <div className={styles.continueWithEmail}>
                    <Button size='extra-large' variant='outlined' fullWidth onClick={() => setContinueWithEmail(true)}>
                        <Typography variant='lgr'>
                            Continue with{' '}
                            <Typography component='span' variant='lgb'>
                                Email
                            </Typography>
                        </Typography>
                    </Button>
                </div>
            </div>
        );
    }

    function FormContent() {
        return (
            <>
                <div className={styles.formContainer}>
                    {template === 'verification' ? (
                        <VerificationForm
                            loading={loading}
                            onStepChange={onStepChange}
                            onSuccess={handleVerificationSuccess}
                        />
                    ) : continueWithEmail ? (
                        <EmailForm
                            error={error}
                            user={user}
                            onStepChange={onStepChange}
                            onSuccess={handleFirstStepSuccess}
                        />
                    ) : (
                        <Form />
                    )}
                </div>

                <Typography
                    className={styles.footerText}
                    color={colors['grey-400']}
                    sx={{ fontSize: '12px', marginTop: '60px' }}
                    variant='smr'
                >
                    Already have account?{' '}
                    <Link.Secondary
                        size='small'
                        onClick={() => {
                            onStepChange('login');
                        }}
                    >
                        <Typography color={colors['grey-400']} component='span' variant='smr'>
                            Login
                        </Typography>
                    </Link.Secondary>
                </Typography>
                <div className={styles.footerText} style={{ marginTop: '35px' }}>
                    <AcceptTerms policyLink={links.policy.path} termsLink={links.terms.path} />
                </div>
                <Typography className={styles.copyright} color='#dbdbdb' variant='smr'>
                    © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                </Typography>
            </>
        );
    }

    return <FormContent />;
}

export default RegisterForm;
