import Typography from '@ui/Typography';

const LOGO_SITE_NAME = typeof process !== 'undefined' && process.env.LOGO_SITE_NAME;

const Copyright = function () {
    return (
        <Typography component='span' variant='note' sx={{ color: '#d3d5df' }}>
            © {new Date().getFullYear()} {LOGO_SITE_NAME}. All rights reserved.
        </Typography>
    );
};

export default Copyright;