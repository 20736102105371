import { generatedApi, RegenerateOriginalStatementApiArg, NormalizeNoiApiArg } from '@scc/services/generated/grid';

export const GRID_TAGS = {
    PREPROCESS_STATEMENT: 'preprocessStatement',
    CHECK_PROFORMA_STATUS: 'checkProformaStatus',
} as const;

const enhanceOptions = {
    addTagTypes: [GRID_TAGS.PREPROCESS_STATEMENT, GRID_TAGS.CHECK_PROFORMA_STATUS],
    endpoints: {
        checkProformaData: {
            providesTags: [GRID_TAGS.CHECK_PROFORMA_STATUS],
        },
        preprocessStatement: {
            providesTags: [GRID_TAGS.PREPROCESS_STATEMENT],
        },
        addCombinedYearColumn: {
            invalidatesTags: [GRID_TAGS.PREPROCESS_STATEMENT],
        },
        saveOriginalStatement: {
            invalidatesTags: [GRID_TAGS.PREPROCESS_STATEMENT],
        },
        saveStatement: {
            invalidatesTags: [GRID_TAGS.PREPROCESS_STATEMENT],
        },
        regenerateOriginalStatement: {
            invalidatesTags: [GRID_TAGS.PREPROCESS_STATEMENT],
            async onQueryStarted(arg: RegenerateOriginalStatementApiArg, { dispatch, queryFulfilled }) {
                const { osid } = arg.originalStatement;
                const { data } = await queryFulfilled;

                if (data) {
                    const patchResult = dispatch(
                        generatedApi.util.updateQueryData(
                            'preprocessOriginalStatement',
                            { statementId: Number(osid) },
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );

                    queryFulfilled.catch(patchResult.undo);
                }
            },
        },
        normalizeNoi: {
            async onQueryStarted(arg: NormalizeNoiApiArg, { dispatch, queryFulfilled }) {
                const { osid } = arg.standardizedStatement;
                const { data } = await queryFulfilled;

                if (data) {
                    const patchResult = dispatch(
                        generatedApi.util.updateQueryData(
                            'preprocessStatement',
                            { statementId: Number(osid) },
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );

                    queryFulfilled.catch(patchResult.undo);
                }
            },
        },
    },
} as const;

export const gridApi = generatedApi.enhanceEndpoints(enhanceOptions);
