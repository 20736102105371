import MuiIconButton from '@mui/material/IconButton';

import { createBaseProxy } from '@utils/components';

function BtnIcon({ sx, children, fontSize, ...props }) {
    return (
        <MuiIconButton
            {...props}
            sx={{
                ...sx,
                fontSize,
            }}
        >
            {children}
        </MuiIconButton>
    );
}

const IconButton = createBaseProxy(MuiIconButton, 'IconButton');

IconButton.XSmall = createBaseProxy(BtnIcon, 'IconButton', {
    size: 'extra-small',
    fontSize: '12px',
});

IconButton.Small = createBaseProxy(BtnIcon, 'IconButton', {
    size: 'small',
    fontSize: '14px',
});
IconButton.Medium = createBaseProxy(BtnIcon, 'IconButton', {
    size: 'medium',
    fontSize: '14px',
});
IconButton.Large = createBaseProxy(BtnIcon, 'IconButton', {
    size: 'large',
    fontSize: '21px',
});
IconButton.XLarge = createBaseProxy(BtnIcon, 'IconButton', {
    size: 'extra-large',
    fontSize: '21px',
});

export default IconButton;
