import { useEffect, useState } from 'react';

import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';

import colors from '@themes/palette/export.module.scss';

import TrashIcon from '@icons/outlined/trash.svg';
import BaseForm from '@shared/components/BaseForm';
import PropertyCard from '@shared/templates/forms/components/OrderForm/PropertyCard';

import AddPropertyAutocomplete from './AddPropertyAutocomplete';
import styles from './styles.module.scss';
import schema from './validationSchema';

function ReportForm({ formId, onUpdate, onSuccess }) {
    const [property, setProperty] = useState(null);

    const handleDeleteCard = () => {
        setProperty(null);
    };

    const handleSetProperty = (value) => {
        onUpdate(value);
        setProperty(value);
    };

    useEffect(() => handleDeleteCard(), []);

    const inputs = [
        {
            component: () =>
                property ? (
                    <div className={styles.card}>
                        <PropertyCard property={property} />
                        <IconButton className={styles.button} size='medium' onClick={handleDeleteCard}>
                            <Icon aria-label='close' color={colors['grey-600']} component={TrashIcon} fontSize='14px' />
                        </IconButton>
                    </div>
                ) : (
                    <AddPropertyAutocomplete property={property} setProperty={handleSetProperty} />
                ),
        },
        {
            name: 'comment',
            caption: 'Comment',
            placeholder: '+ Add Comment',
            fieldProps: {
                multiline: true,
            },
        },
    ];

    return <BaseForm id={formId} inputs={inputs} schema={schema} onSubmit={onSuccess} />;
}

export default ReportForm;
