import { blue, grey } from '@themes/colors';

const slider = () => ({
    MuiSlider: {
        styleOverrides: {
            root: {
                padding: '6px 0',
            },
            thumb: {
                boxShadow: `0 0 1px 0 ${blue['400']}`,
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                width: '16px',
                height: '16px',
                '&:hover': {
                    boxShadow: `0 0 0 2px ${blue['400']}`,
                },
                '&:after': {
                    width: '30%',
                    height: '30%',
                    backgroundColor: 'currentColor',
                },
            },
            track: {
                height: 4,
                border: 'none',
            },
            rail: {
                height: 4,
                color: grey['400'],
            },
        },
    },
});

export default slider;
