import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '@components/DataTable/constants';

import { todoApi } from '@api';
import { API_ERROR, API_REQUEST, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[todos]';

const initialState = {
    data: [],
    loading: false,
};

const todosSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load() {},
        set(state, action) {
            state.data = action.payload;
        },
        updateItem() {},
        setItem(state, action) {
            const { payload } = action;
            const indexToUpdate = state.data.findIndex(({ id }) => id === payload.id);

            state.data[indexToUpdate] = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.startsWith(entity) && action.type.endsWith(API_REQUEST),
                (state) => {
                    state.loading = true;
                }
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(entity) &&
                    (action.type.endsWith(API_SUCCESS) || action.type.endsWith(API_ERROR)),
                (state) => {
                    state.loading = false;
                }
            );
    },
});

export const {
    load: loadTodos,
    set: setTodos,
    updateItem: updateItemTodos,
    setItem: setItemTodos,
} = todosSlice.actions;

export default todosSlice.reducer;

export const selectTodosState = (store) => {
    return {
        ...store.todos,
        data: store.todos.data.map((todo) => ({
            ...todo,
            documentType: Object.keys(DOCUMENT_TYPES).find((item) => DOCUMENT_TYPES[item].label === todo.documentType),
        })),
    };
};

function* loadTodosSaga({ type, payload = {} }) {
    yield put(apiRequest(payload, todoApi.loadUserTodos, type));

    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;

    yield put(setTodos(data));
}

function* updateItemTodosSaga({ type, payload }) {
    yield put(apiRequest(payload, todoApi.updateTodo, type));

    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;

    yield put(setItemTodos(data));
}

export function* watchTodos() {
    yield takeLatest(loadTodos, loadTodosSaga);
    yield takeLatest(updateItemTodos, updateItemTodosSaga);
}
