import difference from 'lodash/difference';

import { DOCUMENT_TYPES } from '@components/DataTable/constants';

export const documentTypesFlags = {
    RENT_ROLL: ['showRentRollGrid'],
    OPERATING_STATEMENT: ['showFinancialsGrid', 'showProformaGrid'],
};

export const isAllDocumentsTypeSelected = (data = {}) => {
    const allPreparedDocTypes = Object.values(data)?.map((item) => item?.type);
    const allValidDocTypes = Object.keys(DOCUMENT_TYPES);

    return !difference(allPreparedDocTypes, allValidDocTypes)?.length;
};

export const getDocumentTypeByLabel = (label) => {
    return Object.values(DOCUMENT_TYPES).find((type) => type.label === label);
};

export const checkFileType = (name) => {
    const nameStr = typeof name === 'string' ? name : '';
    const acceptFileTypes = /(\.|\/)(xls|xlsx|xlsm|docx?|jpe?g|csv|png|gif|pdf|sheet)$/i;
    const excelTypes = /(\.|\/)(xlsx|xls|xlsm|xlsb|csv)$/i;
    const imageTypes = /(\.|\/)(jpe?g|png|gif)$/i;
    const docxTypes = /(\.|\/)(docx?)$/i;
    const pdfTypes = /(\.|\/)(pdf)$/i;

    return {
        isExcel: excelTypes.test(nameStr),
        isImage: imageTypes.test(nameStr),
        isDocx: docxTypes.test(nameStr),
        isPdf: pdfTypes.test(nameStr),
        isDocument: docxTypes.test(nameStr) || pdfTypes.test(nameStr),
        isAcceptable: acceptFileTypes.test(nameStr),
    };
};
