import DialogActions from '@mui/material/DialogActions';
import cx from 'classnames';

import Drawer from '@ui/Drawer';
import Grid from '@ui/Grid';

import styles from './styles.module.scss';

const defaultProps = {
    isOpen: false,
    anchor: 'right',
    headerProps: {},
    className: '',
    onClose: () => {},
};

function BaseDrawer({
    isOpen,
    as,
    variant,
    onClose,
    className,
    paperProps,
    anchor,
    header: Header,
    headerProps,
    title,
    label,
    info,
    footer,
    contentClassName,
    drawerClassName,
    children,
}) {
    return (
        <Drawer
            PaperProps={paperProps}
            anchor={anchor}
            as={as}
            className={className}
            open={isOpen}
            variant={variant}
            onClose={onClose}
        >
            <div className={cx(styles.drawer, drawerClassName)}>
                {Header && (
                    <div className={styles.gridHeader}>
                        <Header label={label} title={title} onClose={onClose} {...headerProps} />
                    </div>
                )}
                <div className={styles.gridContent}>
                    {info && (
                        <Grid justifyContent='center' container>
                            <Grid md={12}>{info}</Grid>
                        </Grid>
                    )}
                    <div className={cx(styles.content, contentClassName)}>{children}</div>
                </div>
                {Boolean(footer) && (
                    <div className={styles.gridFooter}>
                        <DialogActions className={styles.footer}>{footer}</DialogActions>
                    </div>
                )}
            </div>
        </Drawer>
    );
}

BaseDrawer.defaultProps = defaultProps;

export default BaseDrawer;
