import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest, select, delay } from 'redux-saga/effects';

import links from '@scc/router/links';
import { selectIsUserAuth } from '@scc/store/ducks/auth';

import { bookmarksApi, portfolioApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[portfolios]';
const size = 20;

const initialState = {
    userId: '',
    searchParam: null,
    data: [],
    loanId: null,
    operatingStatementExpensesOptions: [],
    period: null,
    portfolioBookmark: null,
    loaded: false,
    loading: false,
    addOnLogin: false,
    total: {},
};

const portfolioSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state, action) {
            const { userId, searchParam } = action.payload;
            state.data = [];
            state.loaded = false;
            state.loading = true;
            state.userId = userId;
            state.searchParam = searchParam || null;
        },
        loadMore() {},
        setData(state, action) {
            const { data, totalCount } = action.payload;
            state.data = data;
            state.totalCount = totalCount;
            state.loaded = true;
            state.loading = false;
        },
        loadExpensesOptions(state, action) {
            const { period = null, options = [] } = action.payload;
            state.operatingStatementExpensesOptions = options;
            state.period = period;
        },
        loadBookmark(state) {
            state.portfolioBookmark = null;
            state.loaded = false;
        },
        toggleBookmark(state) {
            state.loading = true;
            state.loaded = false;
        },
        setMore(state, action) {
            state.data = action.payload;
        },
        save(state, action) {
            state.data = action.payload;
        },
        delete() {},
        initUpdate() {},
        updateBookmark(state, { payload }) {
            state.portfolioBookmark = payload;
            state.addOnLogin = false;
            state.loading = false;
            state.loaded = true;
        },
        addBookmark() {},
        addProperty() {},
        loadTotal() {},
        setTotal(state, action) {
            const { total: totalValue, ...rest } = action.payload;
            state.total = { totalValue, ...rest };
        },
    },
});

export const {
    load: loadPortfolio,
    setData: setPortfolio,
    loadBookmark: loadBookmarkPortfolio,
    toggleBookmark: toggleBookmarkPortfolio,
    loadMore: loadMorePortfolio,
    save: saveData,
    delete: deletePortfolio,
    initUpdate: updatePortfolio,
    updateBookmark: updateBookmarkPortfolio,
    addBookmark: addBookmarkPortfolio,
    addProperty: addPropertyPortfolio,
    loadTotal: loadTotalPortfolio,
    setTotal: setTotalPortfolio,
    loadExpensesOptions: loadExpensesOptionsPortfolio,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;

export const selectPortfolioFromState = (store) => store.portfolios;

export const selectPortfolioBookmarkChecked = ({ portfolios: { portfolioBookmark, loaded } }) =>
    !!portfolioBookmark?.id && loaded;

function* loadPortfolioSaga({ payload, type }) {
    const { userId, status } = payload;
    yield put(apiRequest({ page: 0, size, userId, status }, portfolioApi.getPortfolio, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { totalElements: totalCount, content: data } = action.payload.data;
    yield put(setPortfolio({ data, totalCount }));
}

function* loadMorePortfolioSaga({ type }) {
    const { data: prevData, userId, searchParam: status } = yield select(selectPortfolioFromState);
    yield put(apiRequest({ size, page: prevData.length / size, userId, status }, portfolioApi.getPortfolio, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content: data } = action.payload.data;
    yield put(saveData(prevData.concat(data)));
}

function* updatePortfolioSaga({ payload, type }) {
    const { data } = yield select(selectPortfolioFromState);
    const { newRow, index } = payload;
    yield put(apiRequest(newRow, portfolioApi.updatePortfolio, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const prevDataCopy = [...data];
    prevDataCopy[index] = action.payload.data;
    yield put(saveData(prevDataCopy));
    yield put(loadTotalPortfolio());
}

function* deletePortfolioSaga({ payload, type }) {
    const { id, ...rest } = payload;
    yield put(apiRequest({ id }, portfolioApi.deletePortfolio, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(loadPortfolio(rest));
    yield put(loadTotalPortfolio());
}

function* loadBookmarkPortfolioSaga({ payload, type }) {
    const isLogged = yield select(selectIsUserAuth);

    if (isLogged) {
        yield put(apiRequest(payload, portfolioApi.getPortfolioBookmark, type));
        const {
            payload: { data: existingBookmarkData },
        } = yield take(`${type} ${API_SUCCESS}`);

        const { addOnLogin } = yield select(selectPortfolioFromState);

        if (!addOnLogin) {
            yield put(updateBookmarkPortfolio(existingBookmarkData));
            return;
        }

        yield put(addBookmarkPortfolio(payload));
    }
}

function* toggleBookmarkPortfolioSaga({ payload, type }) {
    const isLogged = yield select(selectIsUserAuth);

    if (!isLogged) {
        window.router?.history.push(links.login.path, {
            from: { pathname: window.location.pathname },
        });
        return;
    }

    const { portfolioBookmark, parcelId, countyId, userId } = payload;
    const { id } = portfolioBookmark || {};

    if (id) {
        yield put(apiRequest({ id }, portfolioApi.deletePortfolio, type));
    } else {
        yield put(apiRequest({ parcelId, countyId, userId }, portfolioApi.createPortfolio, type));
    }

    const {
        payload: { data },
    } = yield take(`${type} ${API_SUCCESS}`);

    yield put(updateBookmarkPortfolio(data));
}

function* addBookmarkPortfolioSaga({ payload, type }) {
    yield put(apiRequest(payload, bookmarksApi.createBookmark, type));
    const {
        payload: { data: createdBookmark },
    } = yield take(`${type} ${API_SUCCESS}`);
    yield put(updateBookmarkPortfolio(createdBookmark));
}

function* addPropertyPortfolioSaga({ payload, type }) {
    yield put(apiRequest(payload, portfolioApi.createPortfolio, type));
}

function* loadTotalPortfolioSaga({ type }) {
    yield delay(200);
    yield put(apiRequest(null, portfolioApi.getTotal, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    const total = action.payload?.data || {};
    yield put(setTotalPortfolio(total));
}

export function* watchPortfolio() {
    yield takeLatest(loadPortfolio, loadPortfolioSaga);
    yield takeLatest(loadBookmarkPortfolio, loadBookmarkPortfolioSaga);
    yield takeLatest(toggleBookmarkPortfolio, toggleBookmarkPortfolioSaga);
    yield takeLatest(loadMorePortfolio, loadMorePortfolioSaga);
    yield takeLatest(updatePortfolio, updatePortfolioSaga);
    yield takeLatest(addBookmarkPortfolio, addBookmarkPortfolioSaga);
    yield takeLatest(addPropertyPortfolio, addPropertyPortfolioSaga);
    yield takeLatest(loadTotalPortfolio, loadTotalPortfolioSaga);
    yield takeLatest(deletePortfolio, deletePortfolioSaga);
}
