import cx from 'classnames';
import { cloneElement, useEffect, useState, useRef } from 'react';

import TextField from '@ui/Input';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { EditableFieldWrapper } from './components/EditBlockWrapper';
import styles from './styles.module.scss';

const getDefaultValue = (value, type) => {
    switch (type) {
        case 'date':
            return value;
        case 'select':
        default:
            return value;
    }
};

const getReturnValue = (value, type, children) => {
    switch (type) {
        case 'select':
            return value.target.innerText;
        default:
            return value || children?.props?.children || '';
    }
};

const isSuccess = (value, initialValue, type, options) => {
    switch (type) {
        case 'select':
            return options?.find((item) => item?.label === value)?.value !== initialValue;
        case 'date':
            // eslint-disable-next-line no-case-declarations
            const d1 = new Date(+initialValue);
            // eslint-disable-next-line no-case-declarations
            const d2 = new Date(Date.parse(value));
            return (
                `${d1.getDate()} ${d1.getMonth()} ${d1.getFullYear()}` !==
                `${d2.getDate()} ${d2.getMonth()} ${d2.getFullYear()}`
            );
        default:
            return initialValue !== value;
    }
};

const defaultProps = {
    tabIndex: 1,
    autoFocus: true,
    multilinePlaceholder: false,
    formatter: (v) => v,
    type: 'text',
    input: TextField,
    inputProps: {},
    validate: () => true,
    onSuccess: () => {},
    onChange: () => {},
    underlined: null,
    onIsEditModeChange: () => {},
    withStaticWidth: false,
};

function EditBlock({
    name,
    type,
    tabIndex,
    validate,
    input: Input,
    initialValue,
    render,
    children = render,
    forceEditMode,
    formatter,
    autoFocus,
    notEditable,
    placeholder,
    multilinePlaceholder,
    inputProps: { classes, ...inputProps },
    selectProps,
    className,
    underlined,
    value: originalValue,
    dataQa,
    onChange,
    onSuccess,
    onBlur,
    onDoubleClick,
    onIsEditModeChange,
    inputClassName,
    withStaticWidth,
}) {
    const [value, setValue] = useState(() => getDefaultValue(initialValue, type, children));
    const [isEditMode, setIsEditMode] = useState(false);
    const [width, setWidth] = useState(null);
    const isEditableModeForced = typeof forceEditMode === 'boolean';
    const staticFieldRef = useRef();

    useEffect(() => {
        onIsEditModeChange(isEditMode);
    }, [onIsEditModeChange, isEditMode]);

    useEffect(() => {
        setValue(getDefaultValue(initialValue, type, children));
    }, [initialValue]);

    useEffect(() => {
        if (withStaticWidth && !isEditMode && staticFieldRef?.current?.clientWidth) {
            setWidth(staticFieldRef.current.clientWidth);
        }
    }, [withStaticWidth, staticFieldRef, isEditMode]);

    const handleChangeEditMode = (event) => {
        event?.stopPropagation?.();
        event?.nativeEvent?.stopImmediatePropagation?.();
        if (isEditableModeForced || (onDoubleClick && event.detail === 1)) return;
        if (onBlur && isEditMode) {
            onBlur();
        }

        setIsEditMode(!isEditMode);
    };

    const handleKeyPress = (event) => {
        if (!isEditableModeForced && event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            onSuccess({ name, value: event.target?.value, prevValue: initialValue, originalValue });
            setIsEditMode(!isEditMode);
        }
    };

    const handleClose = (data) => {
        const returnValue = getReturnValue(data, type);
        const shouldUpdate = isSuccess(returnValue, initialValue, type, selectProps?.options || []);
        if (shouldUpdate) {
            onSuccess({ name, value: returnValue, prevValue: initialValue, originalValue });
        }
        setIsEditMode(!isEditMode);
    };

    const handleChange = (e) => {
        if (validate(e)) {
            if (e.target !== undefined) {
                setValue(e.target.value);
            } else {
                setValue(e.value);
            }

            if (onChange) {
                onChange(e);
            }
        }
    };

    const textFiledProps = {
        name,
        tabIndex,
        value,
        onChange: handleChange,
        placeholder,
        showYearDropdown: true,
        ...inputProps,
        selectProps,
        InputProps: { ...(inputProps.InputProps ?? {}), autoFocus, classes: { input: classes } },
        inputProps,
        onKeyPress: handleKeyPress,
        onSuccess: handleClose,
        onBlur: handleChangeEditMode,
        onClose: type === 'select' && handleClose,
        onClick: (e) => e.stopPropagation(),
    };

    useEffect(() => {
        if (isEditableModeForced) setIsEditMode(forceEditMode);
    }, [isEditableModeForced, forceEditMode]);

    if (notEditable) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }

    if (forceEditMode || isEditMode) {
        const editableField = (
            <div
                className={cx(styles.input, {
                    [inputClassName]: inputClassName,
                })}
                style={width ? { width } : width}
                tabIndex={tabIndex}
            >
                <Input fullWidth {...textFiledProps} data-qa={dataQa} />
            </div>
        );

        return underlined ? (
            <EditableFieldWrapper
                component={editableField}
                isEditMode={forceEditMode || isEditMode}
                size={underlined}
                type={type}
            />
        ) : (
            editableField
        );
    }

    if (children?.props?.children || children?.props?.src) {
        const { value: _value, children: subChildren = _value } = children.props;

        const elem = cloneElement(
            children,
            { onClick: handleChangeEditMode, onKeyPress: handleChangeEditMode, tabIndex, placeholder },
            formatter(subChildren)
        );

        const staticField = (
            <div
                className={cx(styles.static, className)}
                data-qa={dataQa || name}
                ref={staticFieldRef}
                style={{
                    width: subChildren === '-' ? '60px' : 'auto',
                }}
            >
                {elem}
            </div>
        );

        return underlined ? (
            <EditableFieldWrapper component={staticField} size={underlined} type={type} value={subChildren} />
        ) : (
            staticField
        );
    }

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className={cx(styles.static, className)} data-qa={dataQa || name} onClick={handleChangeEditMode}>
            {value ||
                (placeholder ? (
                    <div className={cx({ [styles.multilinePlaceholder]: multilinePlaceholder })}>
                        <Typography color={colors['grey-400']} variant='mdr'>
                            {placeholder}
                        </Typography>
                    </div>
                ) : null)}
        </div>
    );
}

EditBlock.defaultProps = defaultProps;

export default EditBlock;
