import { generatedApi } from '@scc/services/generated/propertyDetails';

export const PROPERTY_DETAILS_TAGS = {
    PROCESSED_RENT_ROLL_LIST: 'processedRentRollList',
} as const;

const enhanceOptions = {
    addTagTypes: [PROPERTY_DETAILS_TAGS.PROCESSED_RENT_ROLL_LIST],
    getPropertyProcessedRentRolls: {
        providesTags: [PROPERTY_DETAILS_TAGS.PROCESSED_RENT_ROLL_LIST],
        keepUnusedDataFor: 20,
    },
    generatePropertyCombinedRentRoll: {
        invalidatesTags: [PROPERTY_DETAILS_TAGS.PROCESSED_RENT_ROLL_LIST],
    },
    updatePropertyProcessedRentRolls: {
        invalidatesTags: [PROPERTY_DETAILS_TAGS.PROCESSED_RENT_ROLL_LIST],
    },
    deleteCombinedRentRoll: {
        invalidatesTags: [PROPERTY_DETAILS_TAGS.PROCESSED_RENT_ROLL_LIST],
    },
} as const;

export const propertyDetailsApi = generatedApi.enhanceEndpoints(enhanceOptions);
