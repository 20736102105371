import breakpoints from '@themes/breakpoints/export.module.scss';
import { getMediaPadding } from '@themes/helpers';

const container = () => ({
    MuiContainer: {
        styleOverrides: {
            root: ({ theme }) => ({
                ...getMediaPadding(theme.breakpoints),
                '&.MuiContainer-maxWidthXs': {
                    maxWidth: 444,
                },
                '&.MuiContainer-maxWidthSm': {
                    maxWidth: `${breakpoints.sm}px`,
                },
                '&.MuiContainer-maxWidthMd': {
                    maxWidth: `${breakpoints.md}px`,
                },
                '&.MuiContainer-maxWidthLg': {
                    maxWidth: `${breakpoints.lg}px`,
                },
                '&.MuiContainer-maxWidthXl': {
                    maxWidth: `${breakpoints.xl}px`,
                },
            }),
        },
    },
});

export default container;
