import { ButtonProps } from '@mui/material/Button';

import { ConfirmPopover } from '@scc/components/ConfirmPopover';

type TWithConfirmPopoverProps = ButtonProps & {
    onClick: () => void;
    confirmText?: string;
};

export function withConfirmPopover(Component: React.FC) {
    return ({ onClick, confirmText, ...restProps }: TWithConfirmPopoverProps) => {
        if (!confirmText) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <Component onClick={onClick} {...restProps} />;
        }

        return (
            <ConfirmPopover confirmText={confirmText} onSubmit={onClick}>
                <Component {...restProps} />
            </ConfirmPopover>
        );
    };
}
