import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatter } from '@utils/index';
import { isAgroProperty, isMFLike, PROPERTY_TYPES_CONFIG } from '@utils/properties';
import { publicURLParse } from '@utils/url';

import { selectBuildingState, getParcelsBuilding } from '@scc/store/ducks/building';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import ParcelEditor from '@components/ParcelEditor';
import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import EditIcon from '@assets/icons/filled/edit-new.svg';
import SettingIcon from '@assets/icons/outlined/setting-4.svg';

import styles from './styles.module.scss';

const getDialogActions =
    ({ onClose }) =>
    ({ onAction, loading, disabled }) => {
        return (
            <Stack direction='row' justifyContent='space-between' spacing={12}>
                <Button size='small' type='button' variant='outlined' fullWidth onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={disabled}
                    loading={loading}
                    size='small'
                    type='button'
                    variant='contained'
                    fullWidth
                    onClick={onAction}
                >
                    Update Property
                </Button>
            </Stack>
        );
    };

function PropertyParcel({ open, onToggleOpen, type, measures }) {
    const dispatch = useDispatch();
    const { parcel, parcels, buildingId } = useSelector(selectBuildingState);
    const { units, size, lotSize } = measures;
    const [isHovered, setIsHovered] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // todo MFlike
    const unitsOrSize = isMFLike(type) ? { value: units, label: 'Units' } : { value: size, label: 'SF' };
    const propertySize = isAgroProperty(type) ? lotSize : unitsOrSize.value;
    const propertyUnitType = isAgroProperty(type) ? 'Acres' : unitsOrSize.label;

    const handleOpenParcel = () => {
        dispatch(getParcelsBuilding());
        onToggleOpen(true);
    };

    const handleParcelSelect = (parcelArg) => {
        window.location.href = publicURLParse(parcelArg.publicurl);
    };

    return (
        <>
            <div
                className={cx(styles.propertiesWrapper, {
                    [styles.loading]: open && !parcels,
                })}
                role='presentation'
                onClick={handleOpenParcel}
                onKeyDown={handleOpenParcel}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={styles.propertyContainer}>
                    <div className={styles.iconWrapper}>
                        <PropertyTypeIcon
                            background={isHovered ? colors['blue-main'] : null}
                            iconComponent={
                                isHovered ? <Icon color={colors.white} component={EditIcon} fontSize={13} /> : null
                            }
                            size='medium'
                            type={type}
                        />
                    </div>
                    <Typography className={styles.propTitle} color={colors['darkNeutral-500']} variant='mdr'>
                        {PROPERTY_TYPES_CONFIG[type]?.displayLabel}
                    </Typography>
                </div>
                <div className={styles.propertyContainer}>
                    <Typography className={styles.propTitle} color={colors['darkNeutral-500']} variant='mdr'>
                        {formatter.number(propertySize)}
                    </Typography>
                    <Typography className={styles.propTitle} color={colors['grey-300']} variant='hr12'>
                        {propertyUnitType}
                    </Typography>
                </div>
                {isMobile ? <Icon color={colors['grey-200']} component={SettingIcon} fontSize={16} /> : null}
            </div>

            {open && (
                <div className={styles.parcelEditorWrapper}>
                    <div className={styles.parcelEditorContainer}>
                        {parcels && (
                            <ParcelEditor
                                actionComponent={getDialogActions({ onClose: () => onToggleOpen(false) })}
                                defaultParcelId={parcel?.parcelid}
                                isMobile={isMobile}
                                parcelsData={{ parcels, buildingId }}
                                selectorColumnWidth={6}
                                fullHeight
                                onSuccess={handleParcelSelect}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default PropertyParcel;
