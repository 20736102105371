import request from '@utils/request';

const BASE_URL = '/api/v2/user-permission';

// TODO: fix in terms of SQS-15357
export async function loadUserGroups({ size = 500, page = 0, sort } = {}) {
    return request(`${BASE_URL}/group`, {
        params: {
            size,
            page,
            sort,
        },
    });
}

export async function saveUserGroups({ userId, groupIds }) {
    return request(`${BASE_URL}/grand/groups`, {
        method: 'PUT',
        body: JSON.stringify({ userId, securityGroupIds: groupIds }),
    });
}

export async function saveUserPermissions(user) {
    return request(`${BASE_URL}/user`, {
        method: 'PUT',
        body: JSON.stringify(user),
    });
}

export async function saveUserGroupsBulk(data) {
    return request(`${BASE_URL}/grand/bulk-users-groups`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}
