import createCache from '@emotion/cache';
import ReactDOM from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import { BrowserRouter, StaticRouter } from 'react-router-dom';

import Root from '@components/Root';

import config from '@config';

const rootElement = typeof document !== 'undefined' && document.getElementById('app');
const initialStateContainer = typeof document !== 'undefined' && document.getElementById('redux-store-state');
const buildingData = typeof window !== 'undefined' && window.buildingData;
const parcelData = typeof window !== 'undefined' && window.parcelData;
const breadcrumbList = typeof window !== 'undefined' && window.breadcrumbList;
const similarBuildingsList = typeof window !== 'undefined' && window.similarBuildingsList;

const head = typeof document !== 'undefined' && document.querySelector('head');

// <meta name="emotion-insertion-point" content="">
let emotionInsertionPoint =
    typeof document !== 'undefined' && document.querySelector('meta[name="emotion-insertion-point"]');

if (head && !emotionInsertionPoint) {
    emotionInsertionPoint = document.createElement('meta');
    emotionInsertionPoint.setAttribute('name', 'emotion-insertion-point');
    emotionInsertionPoint.setAttribute('content', '');
    head.appendChild(emotionInsertionPoint);
}

const createApp = (createStore, { theme, sagas, reducers, persist, noMount }) => {
    if (config.get('enableStrictMode')) {
        console.info('StrictMode is enabled');
    }

    let initialState;

    if (initialStateContainer) {
        try {
            initialState = JSON.parse(initialStateContainer.textContent);
            if (buildingData) {
                initialState.building = {
                    error: null,
                    data: buildingData,
                    parcel: parcelData,
                    breadcrumbs: breadcrumbList || [],
                    similarBuildings: similarBuildingsList || [],
                    loaded: true,
                    loading: false,
                    parcels: null,
                };
                initialState.similarTransactions = {
                    data: buildingData.similarTransactionsResponse || {
                        precision: 50,
                        filterRequest: {
                            radius: 40,
                        },
                    },
                    loading: false,
                    loaded: true,
                    excludedTransactions: {},
                };
            }
        } catch (error) {
            console.warn('Could not load redux initial state');
        }
    }
    const { store, sagaMiddleware } = createStore({ initialState, reducers, persist });

    if (sagas && sagaMiddleware) {
        sagaMiddleware.run(sagas);
    } else {
        console.info('No Sagas');
    }

    // eslint-disable-next-line consistent-return
    const renderApp = (Component) => {
        const cache = createCache({
            key: 'scc',
            insertionPoint: emotionInsertionPoint,
        });

        const rootApp = (
            <Root emotionCache={cache} store={store} theme={theme}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <BrowserRouter>
                    <Component />
                </BrowserRouter>
            </Root>
        );

        if (noMount) {
            return rootApp;
        }

        if (!rootElement) {
            return null;
        }

        if (rootElement?.childElementCount > 0) {
            ReactDOM.hydrateRoot(rootElement, rootApp);
        } else {
            ReactDOM.createRoot(rootElement).render(rootApp);
        }
    };

    const staticRenderApp = ({ component: Component, createEmotionServer, props = {}, context = {} }) => {
        const cache = createCache({
            key: 'scc',
        });
        const { extractCriticalToChunks, constructStyleTagsFromChunks } = createEmotionServer(cache);

        const rootApp = (
            <Root emotionCache={cache} store={store} theme={theme}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <StaticRouter context={context} location={{ pathname: context.pathname || '/' }}>
                    <Component {...props} />
                </StaticRouter>
            </Root>
        );
        const html = ReactDOMServer.renderToString(rootApp);

        const chunks = extractCriticalToChunks(html);
        const styles = constructStyleTagsFromChunks(chunks);

        const renderingResult = {
            app: html,
            styles,
        };

        if (context.escapingReplace) {
            renderingResult.app = html
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&quot;/g, '"');
        }
        return renderingResult;
    };

    return { store, renderApp, staticRenderApp };
};

export default createApp;
