import { baseApi as api } from '../configs/varianceReport';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        generateVarianceReport: build.query<GenerateVarianceReportApiResponse, GenerateVarianceReportApiArg>({
            query: (queryArg) => ({ url: `/api/v2/llm/perplexity/variance-report/${queryArg.varianceId}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GenerateVarianceReportApiResponse = /** status 200 OK */ GenericJsonResponseJsonNode;
export type GenerateVarianceReportApiArg = {
    varianceId: number;
};
export type JsonNode = object;
export type GenericJsonResponseJsonNode = {
    status?: string;
    data?: JsonNode;
    message?: string;
    code?: string;
};
export const { useGenerateVarianceReportQuery } = injectedRtkApi;
