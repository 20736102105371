import {
    generatedApi,
    RemoveDocumentFromDrawApiArg,
    UpdateDocumentApiArg,
    UpdateDocumentPropertyApiArg,
} from '@scc/services/generated/document';

import { generatedApi as userLoanApi } from '../generated/userLoans';

const DOCUMENT_TAGS = {
    DOCUMENT_HISTORY: 'documentHistory',
};

const enhanceOptions = {
    addTagTypes: [DOCUMENT_TAGS.DOCUMENT_HISTORY],
    endpoints: {
        downloadDocument(endpoint) {
            const { query } = endpoint;

            // eslint-disable-next-line no-param-reassign
            endpoint.query = (arg) => {
                const result = query(arg);

                return {
                    ...result,
                    responseType: 'arrayBuffer',
                };
            };

            // eslint-disable-next-line no-param-reassign
            endpoint.transformResponse = (response) => {
                return response.file;
            };

            return endpoint;
        },
        updateDocument: {
            invalidatesTags: [DOCUMENT_TAGS.DOCUMENT_HISTORY],
            async onQueryStarted(
                arg: UpdateDocumentApiArg & {
                    drawId: number;
                },
                { dispatch, queryFulfilled }
            ) {
                const patchResult = dispatch(
                    userLoanApi.util.updateQueryData(
                        'getPreDevelopmentDrawDocuments',
                        {
                            preDrawId: arg.drawId,
                        },
                        (draft) => {
                            const patch = draft?.data?.map((document) => {
                                if (document.id === arg.id) {
                                    return {
                                        ...document,
                                        ...arg.updateDocumentRequest,
                                    };
                                }

                                return document;
                            });

                            Object.assign(draft, { data: patch });
                        }
                    )
                );

                queryFulfilled.catch(patchResult.undo);
            },
        },
        updateDocumentProperty: {
            invalidatesTags: [DOCUMENT_TAGS.DOCUMENT_HISTORY],
            onQueryStarted(
                arg: UpdateDocumentPropertyApiArg & {
                    drawId: number;
                },
                { dispatch }
            ) {
                dispatch(
                    userLoanApi.util.updateQueryData(
                        'getPreDevelopmentDrawDocuments',
                        {
                            preDrawId: arg.drawId,
                        },
                        (draft) => {
                            const patch = draft?.data?.map((document) => {
                                if (document.id === arg.updateDocumentProcessingStatusRequest.documentId) {
                                    return {
                                        ...document,
                                        ...arg.updateDocumentProcessingStatusRequest,
                                    };
                                }

                                return document;
                            });

                            Object.assign(draft, { data: patch });
                        }
                    )
                );
            },
        },
        removeDocumentFromDraw: {
            onQueryStarted(arg: RemoveDocumentFromDrawApiArg, { dispatch }) {
                dispatch(
                    userLoanApi.util.updateQueryData(
                        'getPreDevelopmentDrawDocuments',
                        {
                            preDrawId: arg.removeDocumentFromDraw.drawId,
                        },
                        (draft) => {
                            const patch = draft?.data?.filter(
                                (document) => document.id !== arg.removeDocumentFromDraw.documentId
                            );

                            Object.assign(draft, { data: patch });
                        }
                    )
                );
            },
        },
        getDocumentHistory: {
            providesTags: [DOCUMENT_TAGS.DOCUMENT_HISTORY],
        },
    },
} as const;

export const documentApi = generatedApi.enhanceEndpoints(enhanceOptions);
