import { generatedApi } from '@scc/services/generated/appraisalReport';

const enhanceOptions = {
    endpoints: {
        updateTextField: {
            async onQueryStarted(
                { updateAppraisalReportTextFieldRequest: { field, value } },
                { dispatch, queryFulfilled }
            ) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    dispatch(
                        generatedApi.util.updateQueryData(
                            'getAppraisalReportByDocumentId',
                            { documentId: data.data.documentId },
                            (draft) => {
                                if (draft.data?.promptValues?.[field]) {
                                    Object.assign(draft.data.promptValues[field], { value });
                                }
                            }
                        )
                    );
                }
            },
        },
        updateImage: {
            async onQueryStarted(
                { updateAppraisalReportImageRequest: { url, category } },
                { dispatch, queryFulfilled }
            ) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    dispatch(
                        generatedApi.util.updateQueryData(
                            'getAppraisalReportByDocumentId',
                            { documentId: data.data.documentId },
                            (draft) => {
                                const itemToUpdate = draft.data?.siteImages?.find((image) => image.url === url);

                                if (itemToUpdate) {
                                    Object.assign(itemToUpdate, { category });
                                }
                            }
                        )
                    );
                }
            },
        },
        deleteImage: {
            async onQueryStarted({ deleteAppraisalReportImageRequest: { url } }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    dispatch(
                        generatedApi.util.updateQueryData(
                            'getAppraisalReportByDocumentId',
                            { documentId: data.data.documentId },
                            (draft) => {
                                if (!draft.data?.siteImages?.length) return;

                                const nextSiteImages = [...(draft.data?.siteImages ?? [])];
                                const indexToRemove = nextSiteImages.findIndex((image) => image.url === url);

                                if (indexToRemove !== -1) {
                                    nextSiteImages.splice(indexToRemove, 1);
                                    Object.assign(draft.data?.siteImages, nextSiteImages);
                                }
                            }
                        )
                    );
                }
            },
        },
        updateCustomFields: {
            async onQueryStarted({ body: customFields }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    dispatch(
                        generatedApi.util.updateQueryData(
                            'getAppraisalReportByDocumentId',
                            { documentId: data.data.documentId },
                            (draft) => {
                                if (draft.data) {
                                    Object.assign(draft.data, { customFields });
                                }
                            }
                        )
                    );
                }
            },
        },
        postAppraisalReportChatMessages: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data.status === 'success') {
                    dispatch(
                        generatedApi.util.updateQueryData('getAppraisalReportChatMessages', { id }, (draft) => {
                            Object.assign(draft, data);
                        })
                    );
                }
            },
        },
    },
} as const;

export const appraisalReportApi = generatedApi.enhanceEndpoints(enhanceOptions);
