import AAreal from '@scc/assets/images/partners/aareal.svg';
import Jll from '@scc/assets/images/partners/jll.svg';
import KeyBank from '@scc/assets/images/partners/keybank.svg';
import Pacific from '@scc/assets/images/partners/pacific.svg';
import Slatt from '@scc/assets/images/partners/slatt.svg';
import Label from '@scc/pages/AboutUs/components/Label';

import Container from '@ui/Container';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

const PARTNERS = [Pacific, Slatt, Jll, AAreal, KeyBank];

function Clients({ mobile }) {
    return (
        <div className={styles.clients}>
            <Container maxWidth='xl'>
                <div className={styles.wrapper}>
                    <Label>Our Clients</Label>
                    <Typography color={colors['darkNeutral-500']} component='span' variant={mobile ? 'h1b' : 'head2b'}>
                        Our platform powers the largest banks, insurance companies and real estate investment firms
                    </Typography>
                    <Typography color={colors['darkNeutral-500']} component='span' variant={mobile ? 'pr16' : 'h4sb'}>
                        Having transformed commercial real estate origination and asset management for the largest
                        industry players, we&apos;re now leveraging the same technology to level the playing field for
                        the everyday real estate investor.
                    </Typography>
                </div>

                <div className={styles.partners}>
                    <ItemList items={PARTNERS}>
                        {(Component, i) => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a key={i} href='#'>
                                <Component />
                            </a>
                        )}
                    </ItemList>
                </div>
            </Container>
        </div>
    );
}

export default Clients;
