import cx from 'classnames';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function PartialSuccessLayout({ icon, className, title, description, action, titleVariant }) {
    return (
        <div className={cx(styles.root, className)}>
            <div className={styles.wrapper}>
                <div className={styles.iconWrapper}>{icon}</div>
                <Typography className={styles.title} variant={titleVariant || 'h1b'}>
                    {title}
                </Typography>
                <Typography className={styles.description} color={colors['grey-400']} variant='smr'>
                    {description}
                </Typography>
            </div>

            {action}
        </div>
    );
}

export default PartialSuccessLayout;
