import { Box, SxProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode } from 'react';

import { DateTimeFormatOptions, formatDate, isValidDate } from '@utils/dates';

import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from '../styles.module.scss';
import { TLoanTermsValue } from '../Terms.types';

const dateFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

export function formatDetailsStringValue({
    value,
    prefix = '',
    postfix = '',
    sx,
}: {
    value: number | string;
    prefix?: string;
    postfix?: string;
    sx?: SxProps;
}): ReactNode {
    if (!value) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '2px',
            }}
        >
            {value && (
                <Typography color={colors['grey-400']} variant='hr13'>
                    {prefix}
                </Typography>
            )}
            <Typography sx={sx} variant='hr13'>
                {value}
            </Typography>
            {value && (
                <Typography color={colors['grey-400']} variant='hr13'>
                    {postfix}
                </Typography>
            )}
        </Box>
    );
}

export function formatDetailsDateValue(value: TLoanTermsValue): ReactNode {
    if (!value || value === '-' || !isValidDate(value)) {
        return null;
    }

    const formattedDate = formatDate(value as string, dateFormatOptions as DateTimeFormatOptions) ?? '';

    const [date, month, year] = String(formattedDate).split(' ');

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '4px',
            }}
        >
            <Typography color={colors['grey-600']} variant='hr16'>{`${date} ${month}`}</Typography>
            <Typography color={colors['grey-600']} variant='hr16'>
                {year}
            </Typography>
        </Box>
    );
}

export function renderLenderOptions({ companyMember }) {
    return (
        <Typography className={styles.item} color={colors['text-primary']} component='div' variant='hr14'>
            {companyMember?.company?.name}
        </Typography>
    );
}

export function customLoanIdFormatter(value: string): ReactNode {
    return (
        <Stack
            alignItems='center'
            direction='row'
            gap={4}
            sx={{
                flex: 1,
                '&, & *': {
                    minWidth: 0,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                },
            }}
        >
            <Typography color={colors['text-primary']} component='div' variant='lgr'>
                Loan ID
            </Typography>
            <Tooltip title={value}>
                <Typography
                    color={colors['text-secondary']}
                    component='span'
                    sx={{
                        maxWidth: '160px',
                    }}
                    variant='hr14'
                >
                    {value}
                </Typography>
            </Tooltip>
        </Stack>
    );
}
