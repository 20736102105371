import request from '@utils/request';

const BASE_URL = '/api/v2/home';
const NEWS_WP_URL = 'https://news.spacequant.com/wp-json/wp/v2/posts?_embed&per_page=5&page=1';

export async function getProperties() {
    return request(`${BASE_URL}/property/recent`);
}

export async function getLoans() {
    return request(`${BASE_URL}/loan/recent`);
}

export async function getNotifications() {
    return request(`${BASE_URL}/notification/recent`);
}

export async function getRequests() {
    return request(`${BASE_URL}/request/recent`);
}

export async function getTodos(params) {
    return request(`${BASE_URL}/todo/recent`, {
        params,
    });
}

export async function getNews() {
    return request(`${NEWS_WP_URL}`);
}
