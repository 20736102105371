import { useMemo } from 'react';

import { createComponentsMap, reloadable } from '@utils/components';

import links from '@scc/router/links';

import Typography from '@ui/Typography';

const FinancingBreadcrumb = reloadable(() => import('@scc/pages/Financings/components/Breadcrumb'));
const LoanBreadcrumb = reloadable(() => import('@scc/pages/Loans/components/Breadcrumb'));
const MemberBreadcrumb = reloadable(() => import('@scc/pages/Members/components/Breadcrumb'));
const PortfolioBreadcrumb = reloadable(() => import('@scc/pages/Portfolio/components/Breadcrumb'));

const PropertiesPortfolioBreadcrumb = reloadable(() =>
    import('@scc/pages/Properties/components/Portfolio/components/Breadcrumb')
);

const LoanPortfolioBreadcrumb = reloadable(() => import('@scc/pages/Loans/components/Portfolio/components/Breadcrumb'));

const breadcrumbsMap = {
    main: ({ title }) => <Typography variant='h5b'>{title}</Typography>,
    [links.financing.based]: FinancingBreadcrumb,
    [links.loan.based]: LoanBreadcrumb,
    [links.member.based]: MemberBreadcrumb,
    [links.portfolio.based]: PortfolioBreadcrumb,
    [links.loansPortfolio.path]: LoanPortfolioBreadcrumb,
    [links.properties.routes.portfolios.path]: PropertiesPortfolioBreadcrumb,
};

const getBreadcrumbHeader = createComponentsMap(breadcrumbsMap, 'main');

function BaseBreadcrumbs({ title, template, based }) {
    const [BreadcrumbHeader] = useMemo(
        () =>
            getBreadcrumbHeader({
                template,
                based,
            }),
        [template, based]
    );
    return <BreadcrumbHeader template={template} title={title} />;
}

export default BaseBreadcrumbs;
