import { isNumber } from 'lodash';
import { isMFLike } from '@utils/properties';

export const rowsTemplate = [
    { label: '', type: 'card', accessKeys: ['propertyName', 'propertyType', 'address', 'lon', 'lat', 'url'] },
    { label: 'Similarity', accessKey: 'score', type: 'similarity' },
    { label: 'SF', type: 'square', accessKey: 'sqft' },
    { label: 'Units', type: 'units', accessKey: 'units' },
    { label: 'Occupancy', type: 'percent', accessKey: 'occupancy' },
    { label: 'Lot Size', type: 'square', accessKey: 'lotSqft' },
    { label: 'Built year', type: 'string', accessKey: 'yearbuilt' },
    { label: 'Renovated Year', type: 'string', accessKey: 'renovateYear' },
];

export const additionalData = {
    unitMix: { label: 'Unit mix', type: 'unitMix', row: ['Unit mix'] },
    rents: { label: 'Rent', type: 'rent', labels: [], row: ['Rent'] },
    revenues: { label: 'Revenue', type: 'revenue', labels: [], row: ['Revenue'] },
    expenses: { label: 'Expenses', type: 'expenses', labels: [], row: ['Expenses'] },
};

export const validateResult = (result) =>
    Number.isNaN(result) || result === Infinity || result === -Infinity ? 0 : result.toFixed(0);

export const calculateMainData = (properties) =>
    rowsTemplate.map(({ label, type, accessKey, accessKeys }) => {
        let sum = 0;
        let count = 0;
        const resultRow = [label];
        properties.forEach((item, index) => {
            if (accessKey) {
                let value = item[accessKey];
                if (!value) {
                    value = '';
                } else {
                    value = Number(value).toFixed(0);
                }
                resultRow.push(value);

                if (index !== 0 && isNumber(+value) && value) {
                    sum += Number(value);
                    count += 1;
                }
            } else if (accessKeys && accessKeys.length) {
                const resObject = {};
                accessKeys.forEach((key) => {
                    resObject[key] = item[key];
                });
                resultRow.push(resObject);
            } else {
                resultRow.push(label);
            }
        });

        // add an empty column value to the row for AVERAGE if there is 2 or more properties
        if (properties.length > 1) {
            let average = sum / count;
            average = validateResult(average);
            resultRow.push(average ? Math.floor(average) : '');
        }

        // add a blank column value to the row for the column "Add Property to Compare"
        resultRow.push(' ');

        return { label, type, row: resultRow };
    });

export const calculateAdditionalData = (properties, isMf) =>
    properties.map(({ propertyType, analytics, statementData }) => {
        const expenses = statementData
            ? statementData.expenses.map((item) => ({ ...item, value: Math.ceil(item.value) }))
            : [];
        const revenues = statementData
            ? statementData.revenues.map((item) => ({ ...item, value: Math.ceil(item.value) }))
            : [];

        if (isMf) {
            let unitMix = [];
            const rents = [];
            const dataArray = isMFLike(propertyType) && analytics?.data?.length ? analytics.data : [];
            dataArray.forEach((dataItem) => {
                const unitMixIndex = unitMix.findIndex((unit) => unit.name === dataItem.bedroomsNumber);
                if (unitMixIndex >= 0) {
                    unitMix[unitMixIndex] = {
                        ...unitMix[unitMixIndex],
                        value: unitMix[unitMixIndex].value + 1,
                    };
                } else {
                    unitMix = [
                        ...unitMix,
                        {
                            name: dataItem.bedroomsNumber,
                            value: 1,
                        },
                    ];
                }
            });
            unitMix.forEach(({ name }) => {
                const curArray = dataArray
                    .filter((dataItem) => dataItem.bedroomsNumber === name)
                    .map(({ rent, rentPeriod }) => ({ rent: rent || 0, rentPeriod }));
                const curRent = curArray.reduce(
                    (acc, cur) => acc + (cur?.rent || 0) * (cur.rentPeriod === 'YEAR_PRICE' ? 12 : 1),
                    0
                );
                const value = curRent / curArray.length;
                rents.push({ name, value });
            });

            return {
                unitMix: unitMix
                    .map((item) => ({
                        ...item,
                        name: item.name === '0' || item.name === '' ? 'Studio' : `${item.name}BD`,
                    }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .filter((item) => !item?.name.includes('undefined')),
                rents: rents
                    .map(({ name, value }) => ({
                        name: name === '0' ? 'Studio' : `${name}BD`,
                        value: Number(value.toFixed(0)),
                    }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .filter((item) => !item?.name.includes('undefined')),
                revenues,
                expenses,
            };
        }

        let totalRent = 0;
        let totalSqft = 0;

        (analytics?.data || []).forEach(({ rent, rentPeriod, sqft }) => {
            totalRent += (rent || 0) * (rentPeriod === 'YEAR_PRICE' ? 1 : 12);
            totalSqft += sqft || 0;
        });

        return {
            unitMix: [],
            rents: validateResult(totalRent / totalSqft),
            revenues,
            expenses,
        };
    });

export const getUnitMixLabels = (additionalRows) => {
    const labels = {};
    additionalRows.forEach((row) => {
        const resObject = {};
        Object.values(row).forEach((item) => (resObject[item.name] = item.value));
        const keys = Object.keys(resObject);
        keys.forEach((key) => {
            labels[key] = key;
        });
    });
    let sortedLabels = Object.keys(labels).sort((a, b) => (a > b ? 1 : -1));
    if (sortedLabels.includes('Studio')) {
        const last = sortedLabels.pop();
        sortedLabels = [last, ...sortedLabels];
    }
    return sortedLabels;
};

export const getUnitMixRow = (additionalRows) => {
    const resRow = additionalData.unitMix;
    const labels = getUnitMixLabels(additionalRows);
    if (additionalRows.length < 2) return [{ ...resRow, row: [...resRow.row, ...additionalRows, []] }, labels];
    const sumCell = {};
    const countForSumCell = {};
    additionalRows.forEach((item, index) => {
        if (index === 0) return;
        const resObject = {};
        Object.values(item).forEach((item) => (resObject[item.name] = item.value));
        labels.forEach((key) => {
            if (!sumCell[key]) {
                sumCell[key] = resObject[key] || 0;
            } else {
                sumCell[key] += resObject[key] || 0;
            }
            if (!countForSumCell[key] && resObject[key]) {
                countForSumCell[key] = 1;
            } else if (countForSumCell[key] && resObject[key]) {
                countForSumCell[key] += 1;
            }
        });
    });
    const averageCell = {};
    labels.forEach((key) => {
        const avg = sumCell[key] / (additionalRows.length - 1);
        averageCell[key] = avg > 0 ? avg.toFixed(0) : 0;
    });
    return [
        {
            ...resRow,
            row: [
                ...resRow.row,
                ...additionalRows,
                Object.keys(averageCell).map((key) => ({ name: key, value: averageCell[key] })),
                [],
            ],
        },
        labels,
    ];
};

export const getRentsRowMf = (additionalRows, labels) => {
    const resRow = additionalData.rents;
    let sortedLabels = labels.sort((a, b) => (a > b ? 1 : -1));
    if (sortedLabels.includes('Studio')) {
        const last = sortedLabels.pop();
        sortedLabels = [last, ...sortedLabels];
    }
    let resData = additionalRows.map((item) => {
        const resObject = {};
        Object.values(item).forEach((item) => (resObject[item.name] = item.value));
        sortedLabels.forEach((label) => {
            if (!resObject[label]) resObject[label] = 0;
        });
        const resArray = Object.keys(resObject).map((key) => ({ name: key, value: resObject[key] }));
        return resArray;
    });
    if (sortedLabels.includes('Studio')) {
        resData = resData.map((item) => {
            const last = item.pop();
            return [last, ...item];
        });
    }
    const sumCell = {};
    const countForSumCell = {};
    resData.forEach((item, index) => {
        if (index === 0) return;
        const resObject = {};
        Object.values(item).forEach((item) => (resObject[item.name] = item.value));
        sortedLabels.forEach((key) => {
            if (!sumCell[key]) {
                sumCell[key] = resObject[key] || 0;
            } else {
                sumCell[key] += resObject[key] || 0;
            }
            if (!countForSumCell[key] && resObject[key]) {
                countForSumCell[key] = 1;
            } else if (countForSumCell[key] && resObject[key]) {
                countForSumCell[key] += 1;
            }
        });
    });
    const averageCell = {};
    sortedLabels.forEach((key) => {
        const avg = sumCell[key] / (countForSumCell[key] || 1);
        averageCell[key] = avg !== 0 ? Math.floor(avg) : 0;
    });

    return additionalRows.length < 2
        ? {
              ...resRow,
              labels: sortedLabels,
              row: [...resRow.row, ...resData.map((item) => Object.values(item).map((item) => item.value)), []],
          }
        : {
              ...resRow,
              labels: sortedLabels,
              row: [
                  ...resRow.row,
                  ...resData.map((item) => Object.values(item).map((item) => item.value)),
                  Object.values(averageCell),
                  [],
              ],
          };
};

export const getRentsRow = (additionalRows) => {
    const resRow = additionalData.rents;
    let sum = 0;
    let counter = 0;
    additionalRows.forEach((item, index) => {
        if (index === 0) return;
        if (+item) {
            sum += +item;
            counter++;
        }
    });
    const average = validateResult(sum / counter);
    return {
        ...resRow,
        row:
            additionalRows.length < 2
                ? [...resRow.row, ...additionalRows, '']
                : [...resRow.row, ...additionalRows, average, ''],
        labelProp: 'rentSize',
        isSingleLine: true,
    };
};

export const getRevenuesExpensesRow = (additionalRows, key) => {
    const resRow = additionalData[key];
    const labelsObj = {};
    additionalRows.forEach((item) => {
        const keys = Object.values(item).map((item) => item.name);
        keys.forEach((key) => {
            labelsObj[key] = key;
        });
    });
    const labels = Object.keys(labelsObj);
    const resData = additionalRows.map((item) => {
        const resObject = {};
        Object.values(item).forEach((item) => (resObject[item.name] = item.value));
        labels.forEach((label) => {
            if (!resObject[label]) resObject[label] = 0;
        });
        return resObject;
    });
    const sumCell = {};
    const countForSumCell = {};
    resData.forEach((item, index) => {
        if (index === 0) return;
        labels.forEach((key) => {
            if (!sumCell[key]) {
                sumCell[key] = item[key] || 0;
            } else {
                sumCell[key] += item[key] || 0;
            }
            if (!countForSumCell[key] && item[key]) {
                countForSumCell[key] = 1;
            } else if (countForSumCell[key] && item[key]) {
                countForSumCell[key] += 1;
            }
        });
    });
    const averageCell = {};
    labels.forEach((key) => {
        const avg = sumCell[key] / (countForSumCell[key] || 1);
        averageCell[key] = avg !== 0 ? avg.toFixed(0) : 0;
    });
    const resRowData = (additionalRows.length < 2 ? resData : [...resData, averageCell]).map((item) => {
        const resArray = [];
        labels.forEach((label) => {
            resArray.push(item[label]);
        });
        return resArray;
    });
    return {
        ...resRow,
        labels,
        row: [...resRow.row, ...resRowData, []],
    };
};

export const formatData = (properties) => {
    const { propertytype = false, propertyType = false } = properties[0] || {};
    const isMf = propertyType || propertytype ? isMFLike(propertyType || propertytype) : false;
    const mainData = calculateMainData(properties, isMf);
    const additionalRows = calculateAdditionalData(properties, isMf);
    const [unitMixRow, labels] = getUnitMixRow(additionalRows.map((item) => item.unitMix));
    let rentsRow;
    if (isMf) {
        rentsRow = getRentsRowMf(
            additionalRows.map((item) => item.rents),
            labels
        );
    } else {
        rentsRow = getRentsRow(additionalRows.map((item) => item.rents));
    }
    const revenuesRow = getRevenuesExpensesRow(
        additionalRows.map((item) => item.revenues),
        'revenues'
    );
    const expensesRow = getRevenuesExpensesRow(
        additionalRows.map((item) => item.expenses),
        'expenses'
    );

    const resData = [...mainData, unitMixRow, rentsRow, revenuesRow, expensesRow];

    resData.push({
        label: '',
        type: 'additional',
        row: new Array(properties.length + 2).fill(' '),
    });
    return resData;
};
