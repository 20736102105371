import React from 'react';

import ArrowRightIcon from '@scc/assets/icons/arrow-right.svg';

import Button from '@ui/Button';
import Container from '@ui/Container';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import Meta from '@components/Meta';

import colors from '@themes/palette/export.module.scss';

import styles from './privacyTerms.module.scss';
import data from './privacyTermsData';

const defaultProps = {
    name: 'policy',
};

function PrivacyTermsPage({ name }) {
    const [content = {}, setContent] = React.useState(data[name]);
    const handlePageChange = () => setContent(data[content.paging?.name]);

    return (
        <Container>
            <Meta description={`${content.label} | Smart Capital Center`} title={`${content.label}`} />
            <Grid as='article' justifyContent='center' spacing={50} container>
                <Grid md={9} item>
                    <Typography component='h1' variant='head2b' gutterBottom>
                        {content.label}
                    </Typography>
                    {content.subTitle && (
                        <Typography className={styles.title} variant='lgr'>
                            {content.subTitle}{' '}
                            <Typography color={colors['grey-400']} component='span' variant='lgr'>
                                {content.version}
                            </Typography>
                        </Typography>
                    )}
                </Grid>

                {content.rules?.map(({ title, description, list }, index) => (
                    <Grid key={title} md={9} item>
                        <Typography variant='h2b' gutterBottom>
                            {index + 1}. {title}
                        </Typography>
                        <br />
                        <br />
                        <Typography component='p' sx={{ whiteSpace: 'pre-line' }} variant='lgr'>
                            {description}
                        </Typography>
                        <Typography component='ul' variant='lgr'>
                            {list?.map((item, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Typography key={idx} component='li' variant='lgr'>
                                    {item}
                                </Typography>
                            ))}
                        </Typography>
                    </Grid>
                ))}

                <Grid md={9} item>
                    <Button size='small' variant='outlined' fullWidth onClick={handlePageChange}>
                        <Typography variant='hsb13'>{content.paging?.label} </Typography>
                        <Icon component={ArrowRightIcon} sx={{ marginLeft: '5px' }} viewBox='0 0 11 8' />
                    </Button>
                </Grid>

                <Grid md={9} item>
                    <Typography
                        color={colors['grey-150']}
                        fontWeight='normal'
                        sx={{ textAlign: 'center' }}
                        variant='smr'
                    >
                        © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

PrivacyTermsPage.defaultProps = defaultProps;

export default PrivacyTermsPage;
