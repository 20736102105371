import colors from '@themes/palette/export.module.scss';

import {
    containedInherit,
    containedSuccess,
    containedSecondary,
    containedPrimary,
    containedWarning,
    containedError,
    outlined,
    outlinedSecondary,
    outlinedInherit,
    outlinedSuccess,
    outlinedWarning,
    outlinedError,
    iconLarge,
    iconMedium,
    iconSmall,
    text,
    textSuccess,
    textWarning,
    textSecondary,
    textError,
    textInherit,
} from './common';

export const button = () => ({
    MuiButton: {
        defaultProps: {
            disableRipple: true,
            color: 'primary',
            variant: 'contained',
        },
        styleOverrides: {
            root: {
                borderRadius: 8,
                fontWeight: 600,
                textTransform: 'Capitalize',
                fontFamily: 'Open Sans',
                letterSpacing: '-0.5px',
                color: colors.white,
                border: 'none',
                boxShadow: 'none',
                gap: '8px',
                whiteSpace: 'nowrap',

                '&:hover': {
                    boxShadow: 'none',
                    border: 'none',
                },
            },
            containedPrimary,
            containedSecondary,
            containedInherit,
            containedSuccess,
            containedWarning,
            containedError,
            outlined,
            outlinedSecondary,
            outlinedSuccess,
            outlinedWarning,
            outlinedError,
            outlinedInherit,
            text,
            textSecondary,
            textSuccess,
            textWarning,
            textError,
            startIcon: {
                marginRight: 0,
                '&>*:nth-of-type(1)': {
                    fontSize: '16px',
                    color: 'inherit',
                },
            },
            endIcon: {
                marginLeft: 0,
                '&>*:nth-of-type(1)': {
                    fontSize: '16px',
                    color: 'inherit',
                },
            },
            sizeSmall: {
                fontSize: '12px',
                padding: '4px 12px',
                minWidth: '1rem',
                height: '28px',
            },
            sizeMedium: {
                fontSize: '13px',
                lineHeight: '16px',
                height: '32px',
                padding: '8px 16px',
            },
            sizeLarge: {
                fontSize: 18,
                padding: '16px',
                height: '44px',

                '.MuiButton-startIcon, .MuiButton-endIcon': {
                    '&>*:nth-of-type(1)': {
                        fontSize: '24px',
                    },
                },
            },
        },
        // todo remove this variant
        variants: [
            {
                props: {
                    size: 'extra-large',
                },
                style: {
                    fontSize: 18,
                    lineHeight: '24px',
                    padding: '12px 32px',
                    '.MuiButton-startIcon, .MuiButton-endIcon': {
                        '&>*:nth-of-type(1)': {
                            fontSize: '24px',
                        },
                    },
                },
            },
            {
                // todo remove this variant
                props: {
                    color: 'primary',
                    variant: 'toggle',
                },
                style: {
                    color: colors.white,
                    backgroundColor: colors['darkNeutral-500'],
                    '&:hover': {
                        backgroundColor: colors['darkNeutral-500'],
                    },
                },
            },
            {
                // todo remove this variant
                props: {
                    color: 'secondary',
                    variant: 'toggle',
                },
                style: {
                    color: colors.black,
                    backgroundColor: colors.white,
                    '&:hover': {
                        backgroundColor: colors.white,
                    },
                },
            },
            {
                props: {
                    variant: 'icon',
                },
                style: {
                    ...iconMedium,

                    ...containedPrimary,
                },
            },
            {
                props: {
                    variant: 'icon',
                    size: 'large',
                },

                style: iconLarge,
            },
            {
                props: {
                    variant: 'icon',
                    size: 'small',
                },
                style: iconSmall,
            },
            {
                props: {
                    variant: 'icon',
                    color: 'secondary',
                },
                style: {
                    ...containedSecondary,
                },
            },
            {
                props: {
                    variant: 'icon',
                    color: 'inherit',
                },
                style: {
                    ...containedInherit,
                },
            },
            {
                props: {
                    variant: 'icon',
                    color: 'success',
                },
                style: {
                    ...containedSuccess,
                },
            },
            {
                props: {
                    variant: 'icon',
                    color: 'warning',
                },
                style: {
                    ...containedWarning,
                },
            },
            {
                props: {
                    variant: 'icon',
                    color: 'error',
                },
                style: {
                    ...containedError,
                },
            },
            {
                props: {
                    variant: 'icon-outlined',
                    size: 'small',
                },
                style: iconSmall,
            },
            {
                props: {
                    variant: 'icon-outlined',
                    size: 'large',
                },
                style: iconLarge,
            },
            {
                props: {
                    variant: 'icon-outlined',
                    size: 'medium',
                },
                style: iconMedium,
            },
            {
                props: {
                    variant: 'icon-text',
                    size: 'small',
                },
                style: iconSmall,
            },
            {
                props: {
                    variant: 'icon-text',
                    size: 'large',
                },
                style: iconLarge,
            },
            {
                props: {
                    variant: 'icon-text',
                    size: 'medium',
                },
                style: iconMedium,
            },
            {
                props: {
                    variant: 'icon-outlined',
                    color: 'primary',
                },
                style: {
                    ...outlined,
                },
            },
            {
                props: {
                    variant: 'icon-outlined',
                    color: 'secondary',
                },
                style: {
                    ...outlinedSecondary,
                },
            },
            {
                props: {
                    variant: 'icon-outlined',
                    color: 'inherit',
                },
                style: {
                    ...outlinedInherit,
                },
            },
            {
                props: {
                    variant: 'icon-outlined',
                    color: 'success',
                },
                style: {
                    ...outlinedSuccess,
                },
            },
            {
                props: {
                    variant: 'icon-outlined',
                    color: 'warning',
                },
                style: {
                    ...outlinedWarning,
                },
            },
            {
                props: {
                    variant: 'icon-outlined',
                    color: 'error',
                },
                style: {
                    ...outlinedError,
                },
            },
            {
                props: {
                    variant: 'icon-text',
                    color: 'primary',
                },

                style: {
                    ...text,
                },
            },
            {
                props: {
                    variant: 'icon-text',
                    color: 'secondary',
                },
                style: {
                    ...textSecondary,
                },
            },
            {
                props: {
                    variant: 'icon-text',
                    color: 'inherit',
                },
                style: {
                    ...textInherit,
                },
            },
            {
                props: {
                    variant: 'icon-text',
                    color: 'success',
                },
                style: {
                    ...textSuccess,
                },
            },
            {
                props: {
                    variant: 'icon-text',
                    color: 'warning',
                },
                style: {
                    ...textWarning,
                },
            },
            {
                props: {
                    variant: 'icon-text',
                    color: 'error',
                },
                style: {
                    ...textError,
                },
            },
            {
                props: {
                    variant: 'text',
                },
                style: {
                    ...text,
                },
            },
            {
                props: {
                    variant: 'text',
                    color: 'secondary',
                },
                style: {
                    ...textSecondary,
                },
            },
            {
                props: {
                    variant: 'text',
                    color: 'inherit',
                },
                style: {
                    ...textInherit,
                },
            },
            {
                props: {
                    variant: 'text',
                    color: 'success',
                },
                style: {
                    ...textSuccess,
                },
            },
            {
                props: {
                    variant: 'text',
                    color: 'warning',
                },
                style: {
                    ...textWarning,
                },
            },
            {
                props: {
                    variant: 'text',
                    color: 'error',
                },
                style: {
                    ...textError,
                },
            },
        ],
    },
});
