import Icon from '@ui/Icon';
import CheckCircleOutlineIcon from '@scc/assets/icons/success.svg';
import PartialSuccessLayout from '@scc/components/PartialSuccessLayout';

const RegisterFormSuccess = function ({ user }) {
    return (
        <PartialSuccessLayout
            icon={<Icon component={CheckCircleOutlineIcon} fontSize='128px' />}
            title='Welcome!'
            description={`We have sent an email with a confirmation link to ${user.email}`}
        />
    );
};

export default RegisterFormSuccess;
