/* eslint-disable no-template-curly-in-string */
/* eslint-disable prefer-template */
import { useSelector } from 'react-redux';

import { isServer } from '@utils/browser';
import { reloadable } from '@utils/components';

import { selectBuildingState } from '@scc/store/ducks/building';

import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import SimilarBuildingsLink from './SimilarBuildingLink';
import styles from './similarBuildings.module.scss';

const Slider = reloadable(() => import('@scc/components/Slider'), { ssr: false });

const serverSimilarBuildings = Array(5)
    .fill()
    .map((item, i) => ({
        cif: `<c:if test="\${not empty similarBuildings[${i}]}">`,
        address: `<c:out value="\${similarBuildings[${i}].address}"/>`,
        publicUrl: `<c:out value="\${similarBuildings[${i}].publicUrl}"/>`,
    }));

const serverSimilarTransactions = Array(5)
    .fill()
    .map((item, i) => ({
        cif: `<c:if test="\${not empty response.similarTransactionsZoomed[${i}]}">`,
        address: `<c:out value="\${response.similarTransactionsZoomed[${i}].address}"/>`,
        publicUrl: `<c:out value="\${response.similarTransactionsZoomed[${i}].publicLink}"/>`,
    }));

const maxLinks = 10;

function SimilarBuildings() {
    const buildingState = useSelector(selectBuildingState);
    const { parcel } = buildingState;
    const similarBuildings = isServer ? serverSimilarBuildings : buildingState.similarBuildings;

    const similarTransactions = isServer
        ? serverSimilarTransactions
        : buildingState.data?.similarTransactionsResponse?.zoomedValues || [];
    const links = [...similarBuildings, ...similarTransactions].slice(0, maxLinks);

    return (
        !!links?.length && (
            <>
                {isServer
                    ? '<c:if test="${(not empty similarBuildings) || (not empty response.similarTransactionsZoomed)}">'
                    : ''}
                <div className={styles.similarPropertiesContainer}>
                    <Typography color={colors['grey-150']} variant='lgr'>
                        Similar properties in this area
                    </Typography>
                    {isServer ? (
                        <div className={styles.addressWrapper}>
                            <ItemList items={links}>
                                {(item) => (
                                    <>
                                        {item.cif}
                                        <SimilarBuildingsLink item={item} propertype={parcel?.propertytype} />
                                        {'</c:if>'}
                                    </>
                                )}
                            </ItemList>
                        </div>
                    ) : (
                        <Slider
                            className={styles.similarBuildingsSwiper}
                            options={{
                                spaceBetween: 20,
                            }}
                            slideClassName={styles.slide}
                            slides={links}
                            slidesPerView='auto'
                        >
                            {(item) => <SimilarBuildingsLink item={item} propertype={parcel?.propertytype} />}
                        </Slider>
                    )}
                </div>
                {isServer ? '</c:if>' : ''}
            </>
        )
    );
}

export default SimilarBuildings;
