import cx from 'classnames';
import { useState } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Icon from '@ui/Icon';
import MenuItem from '@ui/MenuItem';
import OutlinedInput from '@ui/OutlinedInput';
import Select from '@ui/Select';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

type TSelectInputProps = {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    selectProps: {
        options: Record<string, unknown>[];
        [key: string]: unknown;
    };
    [key: string]: unknown;
};

function IconComponent(props) {
    return <Icon {...props} color={colors['grey-400']} component={ChevronIcon} fontSize={20} />;
}

export function TermsSelectInput({
    value,
    onChange,
    selectProps: { options, ...selectProps },
    ...props
}: TSelectInputProps) {
    const [open, setOpen] = useState(false);

    if (!options?.length) {
        return null;
    }

    return (
        <Select
            {...props}
            {...selectProps}
            IconComponent={IconComponent}
            input={<OutlinedInput className={cx(styles.input, { [styles.open]: open })} variant='noOutlined' />}
            open={open}
            renderValue={(selected: string) => {
                if (selectProps?.multiple) {
                    const selectedOptions = options?.filter(({ value: optionValue }) =>
                        selected.includes(optionValue as string)
                    );
                    const selectedLabels = selectedOptions?.map(({ label }) => label).join(', ');

                    return selectedLabels || '-';
                }

                const option = options?.find(({ value: optionValue }) => optionValue === selected);

                return option?.label || '-';
            }}
            value={value}
            onChange={onChange}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {options?.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                </MenuItem>
            ))}
        </Select>
    );
}

export default TermsSelectInput;
