import request from '@utils/request';

const BASE_URL = '/api/v2/checklist';

export async function getChecklists() {
    return request(BASE_URL);
}

export async function populateCheckList(data) {
    return request(`${BASE_URL}/populate`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function applyCheckList(data) {
    return request(`${BASE_URL}/apply`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getCheckList(id) {
    return request(`${BASE_URL}/${id}`);
}

export async function createCheckList(data) {
    return request(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function updateCheckList(data) {
    return request(`${BASE_URL}/${data.id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function deleteCheckList(id) {
    return request(`${BASE_URL}/${id}`, {
        method: 'DELETE',
    });
}
