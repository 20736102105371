import { reloadable } from '@utils/components';

const PropertyValuation = reloadable(() => import('@scc/pages/Home/images/property-valuation.svg'));
const MortgageCosts = reloadable(() => import('@scc/pages/Home/images/mortgage-costs.svg'));
const FreeMonitoring = reloadable(() => import('@scc/pages/Home/images/free-monitoring.svg'));

const CustomersIcon = reloadable(() => import('@scc/assets/icons/customers.svg'));
const PropertyIcon = reloadable(() => import('@scc/assets/icons/property.svg'));
const CoinsIcon = reloadable(() => import('@scc/assets/icons/coins.svg'));

const CreateAccount = reloadable(() => import('../images/create-account.svg'));
const UploadDocuments = reloadable(() => import('../images/upload-documents.svg'));
const AnalyzeData = reloadable(() => import('../images/analyze-uploaded-data.svg'));
const ValuationReport = reloadable(() => import('../images/valuation-report.svg'));

export default {
    achievements: [
        {
            icon: <PropertyIcon />,
            title: '50+ million',
            description: 'Properties analyzed',
        },
        {
            icon: <CustomersIcon />,
            title: '1,000 +',
            description: 'Happy clients',
        },
        {
            icon: <CoinsIcon />,
            title: '$1+ billion',
            description: 'In loans processed',
        },
    ],
    valuation: {
        subTitle: 'Same-day, independent deal analysis with interactive financial model and market insight',
        services: [
            {
                title: 'Independent',
                description:
                    'Don’t second-guess your investment decisions. Our independent valuations will help you negotiate a fair deal. Buy and sell at the right price in an over-inflated market.',
            },
            {
                title: 'Affordable',
                description:
                    'Get a detailed property valuation at a fraction of the cost, or completely free if we help you close a loan.',
                image: <PropertyValuation />,
            },
            {
                title: 'Same-Day Delivery',
                description:
                    'Our state-of-the-art technology enables us to deliver a comprehensive property valuation within a few hours, instead of the many days of manual work required previously.',
            },
        ],
        process: {
            title: 'How it Works',
            steps: [
                {
                    title: 'Create your account',
                    preview: <CreateAccount />,
                },
                {
                    title: 'Upload property financial documents',
                    preview: <UploadDocuments />,
                },
                {
                    title: 'We analyze all uploaded data',
                    description: 'and hundreds of other metrics about the property and the local market',
                    preview: <AnalyzeData />,
                },
                {
                    title: 'We deliver the property valuation report',
                    description: 'on the same business day 🙌',
                    preview: <ValuationReport />,
                },
            ],
        },
    },
    financing: {
        subTitle: 'Save money and put that extra cash in your pocket for future investments',
        services: [
            {
                title: 'Same-Day Response',
                description:
                    'Don’t wait weeks for lenders to get back to you. Fill out our streamlined, simple application and get a same-day response with free real-time loan quotes.',
            },
            {
                title: 'Best Terms',
                description:
                    'Save money on mortgage costs.  Our state-of-the-art technology will match you with the best loan options from our pool of 1,000+ lenders. No more wondering if you’re getting the best deal.',
                image: <MortgageCosts />,
            },
            {
                title: 'Mortgages For All',
                description:
                    'Get a mortgage for any situation. We work with all borrowers for all property types nationwide. We find the best terms and lenders for YOU.',
            },
        ],
    },
    monitoring: {
        subTitle: 'Effortlessly track and monitor all of your investments',
        services: [
            {
                title: 'Monitor Investments',
                description:
                    'Staying up-to-date on the performance of your investments is crucial. Our extensive and easy-to-use dashboard has all the metrics you need to track and preserve your wealth for generations to come.',
            },
            {
                title: 'Grow Your Wealth',
                description:
                    'A system that grows with you. Get real-time operating performance for your existing investments and new investments as you build your portfolio. Get the important information you need to preserve and grow your wealth.',
                image: <FreeMonitoring />,
            },
            {
                title: 'Free, forever',
                description:
                    'Monitor your assets without breaking the bank. Track performance for all of your properties. We provide you with all the data and tools you need to effectively manage your wealth...for free!',
            },
        ],
    },
};
