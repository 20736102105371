import ClickAwayListener from '@mui/material/ClickAwayListener';
import Collapse from '@mui/material/Collapse';
import Portal from '@mui/material/Portal';
import cx from 'classnames';
import React, { useEffect, useState, useCallback, forwardRef, useId } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { PROPERTY_TYPES_CONFIG, PROPERTY_TYPES, propertyTypes } from '@utils/properties';

import { yupResolver } from '@hookform/resolvers/yup';

import { PARCEL_ID } from '@scc/modules/AddPropertyWizard/components/ExistingParcels';
import { selectFullUserState } from '@scc/store/ducks/auth';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import InputUI from '@ui/Input';
import InputAdornment from '@ui/InputAdornment';
import Typography from '@ui/Typography';

import { formattersMap } from '@components/BaseForm';
import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import ArrowCircleDownIcon from '@icons/outlined/arrow-circle-down.svg';
import ArrowIcon from '@icons/outlined/arrow-select.svg';

import { getParcel, prepareParcels, submitParcel, updateUsingExistingParcel, FIELDS } from './helpers';
import styles from './parcel-editor.module.scss';
import schema from './validationSchema';

const {
    OFFICE,
    RETAIL,
    MULTIFAMILY,
    MOBILE_HOME,
    INDUSTRIAL,
    SELF_STORAGE,
    SINGLE_FAMILY,
    LODGING,
    SENIOR_HOUSING,
    STUDENT_HOUSING,
    VACANT_LAND,
    MIXED_USE,
    APARTMENT,
    WAREHOUSE,
    HEALTHCARE,
    AGRICULTURAL,
    OTHER,
} = propertyTypes;

function Field({
    component: Component,
    defaultValue,
    formatter,
    width,
    name,
    label,
    prefix,
    errors,
    register,
    parcel,
    updateParcel,
}) {
    return (
        <Grid xs={width} item>
            <Component
                key={name}
                error={!!errors[name]}
                label={label}
                fullWidth
                {...register(name)}
                InputProps={{
                    endAdornment: <InputAdornment position='start'>{prefix}</InputAdornment>,
                }}
                value={
                    formatter?.format?.(parcel[name]) ||
                    parcel[name] ||
                    formatter?.format?.(defaultValue) ||
                    defaultValue ||
                    ''
                }
                focused
                onChange={(e) => {
                    updateParcel({ ...parcel, [name]: formatter?.unformat?.(e.target.value) || e.target.value });
                }}
            />
        </Grid>
    );
}

const getActionsComponent = ({
    loading,
    fullWidth,
    onAction,
    actionText,
    disableButton,
    actionComponent: ActionComponent,
}) => {
    const defaultAction = (
        <Button
            className={styles.button}
            disabled={disableButton}
            fullWidth={fullWidth}
            loading={loading}
            size='large'
            type='submit'
            variant='contained'
        >
            {actionText}
        </Button>
    );

    return ActionComponent ? <ActionComponent {...{ onAction, loading, disabled: disableButton }} /> : defaultAction;
};

function PropertyTypesContainer({ children, isMobile, onClose }) {
    return isMobile ? (
        <Dialog PaperProps={{ className: styles.propertyTypesContainer }} open onClose={onClose}>
            {children}
        </Dialog>
    ) : (
        <div className={styles.popover}>{children}</div>
    );
}

const defaultProps = {
    input: InputUI,
    fullWidth: true,
    actionText: 'Next',
    showButton: true,
    initialParcel: {},
    isOpen: false,
};

const ParcelEditor = forwardRef(
    (
        {
            input: Input,
            fullWidth,
            fullHeight,
            onSuccess,
            actionText,
            parcelsData,
            initialListing,
            pullParcelState,
            defaultParcelId,
            isOpen,
            showButton,
            loading,
            onUpdateParcel,
            initialParcel,
            formId,
            onChange,
            selectorColumnWidth = 6,
            actionComponent: customActionComponent,
            mode = 'onSubmit',
            isMobile,
        },
        ref
    ) => {
        const systemID = useId();
        const container = React.useRef(null);
        const [anchorEl, setAnchorEl] = useState(null);
        const [isCollapse, setIsCollapse] = useState(isOpen);
        const [inputValue, setInputValue] = useState(initialListing?.propertyType || null);

        const { userId } = useSelector(selectFullUserState);
        const { buildingId, parcels: originalParcels = [], parcel: originParcel } = parcelsData || {};
        const [parcels, setParcels] = useState([]);
        const [parcel, setParcel] = useState(initialParcel);

        const isShowMoreButton =
            [
                SINGLE_FAMILY,
                MULTIFAMILY,
                MOBILE_HOME,
                SELF_STORAGE,
                SENIOR_HOUSING,
                STUDENT_HOUSING,
                LODGING,
                OTHER,
            ].includes(inputValue) && !isOpen;

        const {
            register,
            handleSubmit,
            reset,
            setValue,
            formState: { errors, isValid },
        } = useForm({
            mode,
            resolver: yupResolver(schema),
        });
        const open = Boolean(anchorEl);
        const id = open ? 'popover' : undefined;

        const propertyTypeFullWidth =
            ![
                OFFICE,
                RETAIL,
                INDUSTRIAL,
                VACANT_LAND,
                MIXED_USE,
                APARTMENT,
                WAREHOUSE,
                HEALTHCARE,
                AGRICULTURAL,
            ].includes(inputValue) ||
            !inputValue ||
            open;
        const disableButton = inputValue !== parcel.propertytype || !isValid;

        const handleClick = (e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const onSubmit = async (data) => {
            const newParcel = { systemid: systemID, ...data, ...parcel };

            if (originParcel?.parcelid && originParcel?.parcelid !== PARCEL_ID.EMPTY) {
                await updateUsingExistingParcel({ originParcel, userId });
            }

            if (typeof onUpdateParcel === 'function') {
                onUpdateParcel(newParcel);
            } else {
                const parcelRes = await submitParcel(originalParcels, buildingId, newParcel);
                if (onSuccess) {
                    onSuccess(parcelRes);
                }
            }
        };

        const changeParcel = useCallback(
            (parcelsArr, searchObj = []) => {
                reset();
                const newParcel = getParcel(parcelsArr, searchObj) || {};
                setParcel(newParcel);
                setValue('propertytype', newParcel.propertytype);
            },
            [reset, setValue]
        );

        const updateParcel = (updatedParcel) => {
            const searchObj = ['propertytype', updatedParcel.propertytype];
            const parcelsCopy = [...parcels];
            // eslint-disable-next-line no-shadow
            const parcel = getParcel(parcelsCopy, searchObj) || {};
            onChange?.(updatedParcel);
            Object.assign(parcel, updatedParcel);
            setParcels(parcelsCopy);
            changeParcel(parcelsCopy, searchObj);
        };

        const handleSelectPropertyType = (value) => {
            setInputValue(value);
            changeParcel(parcels, ['propertytype', value]);
            setAnchorEl(null);
            setIsCollapse(isOpen);
        };

        useEffect(() => {
            if (pullParcelState) {
                pullParcelState(parcel);
            }
        }, [parcel, pullParcelState]);

        useEffect(() => {
            setInputValue(parcel.propertytype);
        }, [parcel]);

        useEffect(() => {
            const parcelsArr = prepareParcels(originalParcels);
            setParcels(parcelsArr);
            changeParcel(parcelsArr, defaultParcelId ? ['parcelid', defaultParcelId] : []);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [defaultParcelId]);

        // todo
        useEffect(() => {
            FIELDS.forEach(({ name, formatter, defaultFields = [], defaultValue = '' }) => {
                if (defaultFields.includes(inputValue)) {
                    updateParcel({ ...parcel, [name]: formatter?.unformat?.(defaultValue) || defaultValue });
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [inputValue, parcel]);

        const ActionComponent = getActionsComponent({
            showButton,
            loading,
            fullWidth,
            onAction: handleSubmit(onSubmit),
            actionText,
            disableButton,
            actionComponent: customActionComponent,
        });

        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Box
                    className={cx({ [styles.fullHeight]: fullHeight })}
                    component='form'
                    id={formId}
                    ref={ref}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid
                        className={cx(styles.wrapper, {
                            [styles.active]: open,
                        })}
                    >
                        <div className={cx({ [styles.fullHeight]: fullHeight })}>
                            <Grid columnSpacing={16} rowSpacing={12} container>
                                <Grid xs={propertyTypeFullWidth ? 12 : 6} item>
                                    {/* label SELECT */}
                                    <Input
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <PropertyTypeIcon size='small' type={inputValue} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <div className={styles.arrowIcon}>
                                                        <Icon
                                                            aria-label='arrow'
                                                            color={
                                                                open ? colors['blue-400'] : colors['darkNeutral-500']
                                                            }
                                                            component={ArrowIcon}
                                                            fontSize='10px'
                                                        />
                                                    </div>
                                                </InputAdornment>
                                            ),
                                        }}
                                        aria-describedby={id}
                                        data-qa='propertyInput'
                                        fullWidth={fullWidth}
                                        label='Property Type'
                                        open={open}
                                        value={PROPERTY_TYPES_CONFIG[inputValue]?.displayLabel ?? inputValue}
                                        onClick={handleClick}
                                    />
                                </Grid>

                                {/* label PROPERTY TYPES / POPOVER */}
                                {open && (
                                    <PropertyTypesContainer isMobile={isMobile} onClose={handleClose}>
                                        <Grid container>
                                            {PROPERTY_TYPES.map((propertyType) => {
                                                const { label, type, value } = propertyType;
                                                const isActive = inputValue === value;

                                                return (
                                                    <Grid key={type} xs={selectorColumnWidth} item>
                                                        <Button.Link
                                                            className={cx(styles.propertyTypeItem, {
                                                                active: isActive,
                                                            })}
                                                            color='secondary'
                                                            value={value}
                                                            variant='text'
                                                            onClick={() => handleSelectPropertyType(value)}
                                                        >
                                                            <PropertyTypeIcon
                                                                size={isMobile ? 'medium' : 'small'}
                                                                type={type}
                                                            />
                                                            <Typography
                                                                component='span'
                                                                variant={isActive ? 'hsb13' : 'hr13'}
                                                                noWrap
                                                            >
                                                                {label}
                                                            </Typography>
                                                        </Button.Link>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </PropertyTypesContainer>
                                )}

                                {/* label FIELDS */}
                                {!open &&
                                    FIELDS.map(
                                        ({
                                            name,
                                            label,
                                            prefix,
                                            types = [],
                                            collapsed = [],
                                            formatter: formatterName = '',
                                            width = 6,
                                        }) => {
                                            const show = types.includes(inputValue);
                                            const isCollapsed = collapsed.includes(inputValue);
                                            const formatterFn = formattersMap[formatterName] || {};

                                            // Render uncollapsed fields
                                            if (show && !isCollapsed) {
                                                return (
                                                    <Field
                                                        key={name}
                                                        component={Input}
                                                        errors={errors}
                                                        formatter={formatterFn}
                                                        label={label}
                                                        name={name}
                                                        parcel={parcel}
                                                        prefix={prefix}
                                                        register={register}
                                                        updateParcel={updateParcel}
                                                        width={width}
                                                    />
                                                );
                                            }

                                            // Render collapsed fields into portal
                                            if (show && isCollapsed) {
                                                return (
                                                    <Portal key={name} container={container.current}>
                                                        <Field
                                                            key={name}
                                                            component={Input}
                                                            errors={errors}
                                                            label={label}
                                                            name={name}
                                                            parcel={parcel}
                                                            prefix={prefix}
                                                            register={register}
                                                            updateParcel={updateParcel}
                                                            width={width}
                                                        />
                                                    </Portal>
                                                );
                                            }
                                            return null;
                                        }
                                    )}
                                <Grid xs={12} item>
                                    {/* label COLLAPSE CONTAINER */}
                                    <Collapse in={isCollapse}>
                                        <Grid
                                            className={styles.collapseContainer}
                                            columnSpacing={16}
                                            ref={container}
                                            rowSpacing={12}
                                            container
                                        />
                                    </Collapse>
                                </Grid>
                            </Grid>

                            {/* label MORE BUTTON */}
                            {isShowMoreButton && !open && (
                                <div className={styles.showMoreBtnWrapper}>
                                    <Button.Link
                                        className={styles.showMoreBtn}
                                        onClick={() => {
                                            setIsCollapse(!isCollapse);
                                        }}
                                    >
                                        <Typography color={colors['grey-400']} component='span' variant='hsb13'>
                                            {isCollapse ? 'Less' : 'More'}
                                        </Typography>
                                        <Icon
                                            className={cx(styles.showMoreBtnIcon, {
                                                [styles.showMoreBtnIconUp]: isCollapse,
                                            })}
                                            color={colors['grey-400']}
                                            component={ArrowCircleDownIcon}
                                            fontSize='15px'
                                        />
                                    </Button.Link>
                                </div>
                            )}
                        </div>

                        {/* label ACTION BUTTON */}
                        {!open && showButton ? ActionComponent : null}
                    </Grid>
                </Box>
            </ClickAwayListener>
        );
    }
);

ParcelEditor.defaultProps = defaultProps;

export default ParcelEditor;
