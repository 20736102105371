import { baseWithHeader } from '@utils/charts/tooltip/templates/baseWithHeader';

import base from './base';
import dropdown from './dropdown';
import stack from './stack';

export const BASE = 'base';

export const DROPDOWN = 'dropdown';

export const BASE_WITH_HEADER = 'baseWithHeader';

const templatesMap = {
    [BASE]: base,
    [DROPDOWN]: dropdown,
    [BASE_WITH_HEADER]: baseWithHeader,
};

export const stackTemplate = stack;

export default templatesMap;
