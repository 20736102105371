import cx from 'classnames';

import { commaSeparateNumber } from '@utils/index';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

const defaultProps = {
    labelColor: colors['grey-500'],
    labelFontVariant: 'mdr',
    amountColor: colors.black,
    amountFontVariant: 'mdr',
    children: '0',
};

function Percent({
    children,
    labelFontVariant,
    labelColor,
    onClick,
    amountFontVariant,
    amountColor,
    className,
    withLabel = true,
}) {
    return (
        <div className={cx(styles.blockWrapper, className)} onClick={onClick}>
            <Typography color={amountColor} component='span' variant={amountFontVariant}>
                {commaSeparateNumber(children)}
            </Typography>
            {withLabel ? (
                <Typography color={labelColor} component='span' variant={labelFontVariant}>
                    %
                </Typography>
            ) : null}
        </div>
    );
}
Percent.defaultProps = defaultProps;

export default Percent;
