/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';

import { openExternalWindow } from '@utils/browser';
import { formatDate, formatTime, shortFormatDate } from '@utils/dates';
import { commaSeparateNumber, currencyPrefixFormatter, formatter, hex2rgba } from '@utils/index';
import { formatPropertyAddress } from '@utils/properties';
import { ROLES } from '@utils/roles';
import { userAvatarUrl } from '@utils/user';

import InfoIcon from '@scc/assets/icons/info.svg';
import ContactsInfo from '@scc/components/ContactsInfo';
import UserInfoUI from '@scc/components/UserInfo';
import { useTestPermission } from '@scc/hooks/useTestPermission';

import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import Chip from '@ui/Chip';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import LinearProgress from '@ui/LinearProgress';
import Link from '@ui/Link';
import Stack from '@ui/Stack';
import Switch from '@ui/Switch';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import Badge from '@components/Badge';
import BasePopover from '@components/BasePopover';
import CustomTooltip from '@components/CustomTooltip';
import { BaseCell } from '@components/DataTable/DataTable';
import Highlighter from '@components/Highlighter';
import PropertyTypeIcon from '@components/PropertyTypeIcon';
import ShowMoreProperties from '@components/ShowMoreProperties';

import colors from '@themes/palette/export.module.scss';

import { documentsApi } from '@api';
import UserSvg from '@assets/icons/filled/user.svg';
import LocationOutlinedIcon from '@assets/icons/outlined/location.svg';
import MoreSvg from '@assets/icons/outlined/more-3.svg';
import NotificationIcon from '@assets/icons/outlined/notification-bing.svg';
import TimerSvg from '@assets/icons/outlined/timer-1.svg';
import * as PERMISSIONS from '@constants/permissions';
import CorporationIcon from '@icons/filled/buliding.svg';
import CalendarSvg from '@icons/filled/calendar.svg';
import LocationIcon from '@icons/filled/location.svg';
import ProfileSvg from '@icons/filled/profile.svg';
import ArrowDownSvg from '@icons/outlined/arrow-down-1.svg';
import ArrowUpSvg from '@icons/outlined/arrow-up-3.svg';
import CompanyIcon from '@icons/outlined/building.svg';
import BuildingIcon from '@icons/outlined/buliding.svg';
import CallSvg from '@icons/outlined/call.svg';
import DocumentIcon from '@icons/outlined/document-1.svg';
import EyeSvg from '@icons/outlined/eye.svg';
import ImportSvg from '@icons/outlined/import-2.svg';
import SmsSvg from '@icons/outlined/sms.svg';
import { TASK_TYPE, TASK_TYPE_ENUM } from '@modules/TodoForm/helpers';
import DocumentStatus from '@shared/components/DocumentStatus';
import DocumentType from '@shared/components/DocumentType';
import { LOAN_PROGRAMMS, LOAN_STATUSES_LENDER } from '@shared/constants/loans';
import useMenuList from '@shared/hooks/useMenuList';

import {
    DATE_FORMAT,
    DOCUMENT_TYPES,
    MEMBER_TYPES,
    PORTFOLIO_STATUSES,
    REPORT_TYPES,
    scenarioLabels,
    SCORE_PARAMETER_LABELS,
    STATUSES,
    THIRD_PARTY_REPORT_STATUSES,
    UNDERWRITING_TYPES,
    USER_STATUSES,
} from './constants';
import styles from './styles.module.scss';

export const formatToUsPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d|)?(\d{3})(\d{3})(\d{4})$/);
    let formatted = phoneNumberString;
    if (match) {
        const intlCode = match[1] ? `+${match[1]} ` : '';
        formatted = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return formatted;
};

export function CurrencyColumnFormat({
    value = '0',
    size,
    highlightNegativeValue = true,
    formatterOptions = { maximumFractionDigits: 0 },
}: {
    value: unknown;
    size?: string;
    highlightNegativeValue?: boolean;
    formatterOptions?: { maximumFractionDigits: number };
} = {}) {
    const { minimumFractionDigits = 0 } = formatterOptions;
    const defaultValuePlaceholder = parseFloat('0').toFixed(minimumFractionDigits);

    const valueColor = highlightNegativeValue && value < 0 ? colors['red-500'] : 'inherit';

    return (
        <div style={{ display: 'inline-flex', flexDirection: 'row', gap: '.25rem' }}>
            <Typography color={colors['grey-200']} variant={size}>
                $
            </Typography>
            <Typography color={valueColor} variant={size}>
                {!Number(+value) ? defaultValuePlaceholder : formatter.number(value, formatterOptions)}
            </Typography>
        </div>
    );
}

export function ValueGapsFormat({ value, original = {} }) {
    const keys = Object.keys(original);
    const values = Object.values(original);
    const index = values.findIndex((item) => item === value);

    if (index < 0) {
        return null;
    }
    const key = keys[index];
    let resValue;
    if (typeof value === 'object') {
        resValue = [value.from, value.to];
    } else {
        let resKey;
        if (key === 'sqft') {
            resKey = 'valuePerSqft';
        } else if (key === 'units') {
            resKey = 'valuePerUnit';
        } else {
            resKey = 'value';
        }

        if (!resKey || !original[resKey]) {
            return null;
        }

        resValue = Object.values(original[resKey]) || null;
    }

    return (
        <div className={styles.wrapper}>
            {resValue.map((item, idx) => (
                <>
                    <CurrencyColumnFormat size='hr12' value={item} />
                    {!idx ? <div className={styles.line} /> : null}
                </>
            ))}
        </div>
    );
}

export function ValueGapsTotalRow({ footerCols, column: { formatting = ({ value }) => value, id } }) {
    return <>{formatting({ value: footerCols[id], original: footerCols })}</>;
}

export function BarFormat({ value, original: { percent } = {} }) {
    if (!value) {
        return null;
    }

    return (
        <div className={styles.bar} style={{ width: `${percent}%` }}>
            <Typography color={colors.white} component='p' variant='hsb13'>
                {currencyPrefixFormatter(value, 1)}
            </Typography>
        </div>
    );
}

export function NumericColumnFormat({ value = '0', variant = 'smr' } = {}) {
    return (
        <Typography className={styles.nativeSize} variant={variant}>
            {typeof +value === 'number' ? formatter.number(value, { maximumFractionDigits: 0 }) : '0'}
        </Typography>
    );
}

export function ContactNameFormat({ value, original: { type } } = {}) {
    const icon = (
        <Icon
            color={colors['grey-400']}
            component={['CORPORATION', 'LLC'].includes(type) ? CorporationIcon : UserSvg}
        />
    );
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            {icon}
            <Typography color={colors['grey-400']} varinat='hsb13'>
                {value}
            </Typography>
        </div>
    );
}

export function ScoreFormat({ value, row: { original } } = {}) {
    const { score, threshold } = original;
    const color = score < threshold ? colors.error : colors['grey-400'];

    return (
        <div className={cx(styles.scoreBlock)} style={{ width: `2rem`, height: `2rem`, color: colors['grey-400'] }}>
            <CircularProgress
                className={styles.score}
                size={36}
                sx={{
                    color,
                }}
                thickness={2}
                value={value === 'NaN' ? 0 : value}
                variant='determinate'
            />
            <Typography color={color} variant='mdr'>
                {value === 'NaN' ? 'n/a' : value}
            </Typography>
        </div>
    );
}

const getScoreColor = (value) => {
    if (value > 66) {
        return colors.success;
    }

    if (value > 33) {
        return colors.warning;
    }

    return colors.error;
};

export function ScoreLoanFormat({ value } = {}) {
    const color = getScoreColor(value);

    return (
        <div className={styles.scoreBlock} style={{ width: `2rem`, height: `2rem`, color: colors['grey-400'] }}>
            <Typography color={color} varinat='mdr'>
                {value?.toFixed(0)}
            </Typography>
        </div>
    );
}

export function AlertFormat({ value } = {}) {
    if (value === 100) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '.25rem' }}>
                <Typography color={colors['red-500']} variant='hsb12'>
                    {Number(value).toFixed(0)}
                </Typography>
                <Typography color={colors['red-500']} component='span' variant='hsb12'>
                    %
                </Typography>
            </div>
            <div className={styles.description}>
                <Tooltip title='Sum of all weight should be 100%' arrow>
                    <span className={styles.tooltipIcon}>
                        <InfoIcon />
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}

export function ScoreParameterLabelFormat({ value }) {
    return (
        <Typography color={colors['darkNeutral-400']} style={{ whiteSpace: 'nowrap' }} variant='h6sb'>
            {SCORE_PARAMETER_LABELS[value]}
        </Typography>
    );
}

export function ContactCompanyNameFormat({ value } = {}) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <Typography color={colors['grey-400']} varinat='hr13'>
                {value}
            </Typography>
        </div>
    );
}

export function MemberTypeFormat({ value = 'OTHER' } = {}) {
    return ['CORPORATION', 'LLC'].includes(value) ? null : MEMBER_TYPES[value];
}

export function PropertyAddressFormat({
    value = '-',
    size,
    showIcon = false,
    showFullAddress = false,
    original: { propertyCount = 1, id },
} = {}) {
    const [mainText, ...secondaryText] = value?.split(', ') || [];

    const secondaryShowedAddress = showFullAddress ? secondaryText : secondaryText.slice(0, -1);

    return value !== '-' ? (
        <div className={styles.propertyAddress}>
            {showIcon && <Icon color={colors['grey-300']} component={LocationOutlinedIcon} fontSize='14px' />}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography color={colors['darkNeutral-500']} variant={size}>
                    {mainText}
                </Typography>
                <Typography color={colors['grey-400']} variant={size}>
                    {secondaryShowedAddress.join(', ')}
                </Typography>
            </div>
            <ShowMoreProperties loanId={id} propertyCount={propertyCount} />
        </div>
    ) : (
        { value }
    );
}

export function TwoRowsAddress({ address }) {
    const firstLine = address?.split(',', 1) || '';
    const secondLine = address?.replace(', ', ',')?.substring(address.indexOf(',') + 1) || '';

    return firstLine || secondLine ? (
        <Stack alignItems='center' direction='row' spacing={4}>
            <Icon color={colors['grey-400']} component={LocationIcon} fontSize={16} />
            <Stack direction='column'>
                {firstLine && (
                    <Typography color={colors['darkNeutral-500']} variant='smr'>
                        {`${firstLine},`}
                    </Typography>
                )}
                {secondLine && (
                    <Typography color={colors['grey-400']} variant='smr'>
                        {secondLine}
                    </Typography>
                )}
            </Stack>
        </Stack>
    ) : (
        '-'
    );
}

export function TitleCompany({ value: { title, companyName } }) {
    return title || companyName ? (
        <Stack alignItems='center' direction='row' spacing={4}>
            <Icon color={colors['grey-400']} component={CompanyIcon} fontSize={16} />
            <Stack direction='column'>
                {title && (
                    <Typography color={colors['darkNeutral-500']} variant='smr'>
                        {title}
                    </Typography>
                )}
                {companyName && (
                    <Typography color={colors['grey-400']} variant='smr'>
                        {companyName}
                    </Typography>
                )}
            </Stack>
        </Stack>
    ) : (
        '-'
    );
}

export function Contacts({ value: { email, phone } = {} }) {
    return email || phone ? (
        <Stack direction='column' spacing={2}>
            {email && (
                <Stack direction='row' spacing={4}>
                    <Icon color={colors['grey-400']} component={SmsSvg} fontSize={16} />
                    <Typography color={colors['darkNeutral-500']} variant='smr'>
                        {email}
                    </Typography>
                </Stack>
            )}
            {phone && (
                <Stack direction='row' spacing={4}>
                    <Icon color={colors['grey-400']} component={CallSvg} fontSize={16} />
                    <Typography color={colors['darkNeutral-500']} variant='smr'>
                        {phone}
                    </Typography>
                </Stack>
            )}
        </Stack>
    ) : (
        '-'
    );
}

export function HeadingGroupFormat({ value = '', size } = {}) {
    const [mainText = '', secondaryText = ''] = value?.split('\n') || [];

    return (
        <div className={styles.headingGroup}>
            <Typography color={colors['darkNeutral-500']} variant={size}>
                {mainText}
            </Typography>
            <Typography color={colors['grey-400']} variant={size}>
                {secondaryText}
            </Typography>
        </div>
    );
}

export function DateFormat({ value, color, size, orderBtn = false, inline = true } = {}) {
    const primaryDateFormat = { day: 'numeric', month: 'short' };
    const secondaryDateFormat = { year: 'numeric' };

    if (orderBtn) {
        return <Button.Outlined size='medium'>Order</Button.Outlined>;
    }

    return (
        <div
            className={styles.date}
            style={{
                flexDirection: inline ? 'row' : 'column',
                gap: '.25rem',
                flexWrap: 'nowrap',
            }}
        >
            <Typography color={color} style={{ whiteSpace: 'nowrap' }} variant={size}>
                {formatDate(value, primaryDateFormat)}
            </Typography>
            <Typography color={color} style={{ whiteSpace: 'nowrap' }} variant={size}>
                {formatDate(value, secondaryDateFormat)}
            </Typography>
        </div>
    );
}

export function FullDateFormat({ value, color, size } = {}) {
    const primaryDateFormat = { day: 'numeric', month: 'short', year: 'numeric' };

    return (
        <Typography color={color} variant={size}>
            {shortFormatDate(value, primaryDateFormat)}
        </Typography>
    );
}

export function ShortDateFormat({ value } = {}) {
    return shortFormatDate(value, DATE_FORMAT.noYear);
}

export function SecondaryShortDateFormat({ value } = {}) {
    return shortFormatDate(value, DATE_FORMAT.noDay);
}

export function DateColumnFormat({ value } = {}) {
    return value ? formatDate(value, DATE_FORMAT.default) : '-';
}

export const getTwoLinesFormat = (firstLine, secondLine) => () => {
    return (
        <Stack>
            <Typography color={colors['darkNeutral-500']} variant='hr12'>
                {firstLine}
            </Typography>
            <Typography color={colors['grey-400']} variant='hr12'>
                {secondLine}
            </Typography>
        </Stack>
    );
};

export function DateTimeColumnFormat({ value } = {}) {
    if (!value) {
        return '-';
    }

    const date = formatDate(value, DATE_FORMAT.default);
    const time = formatTime(value);

    return (
        <div className={styles.dateTimeWrapper}>
            <Typography color={colors['darkNeutral-500']} variant='hr13'>
                {date}
            </Typography>
            <Typography color={colors['grey-400']} variant='pr12'>
                {time}
            </Typography>
        </div>
    );
}

export function DateSecondColumnFormat({ value } = {}) {
    if (!value) {
        return '-';
    }

    const [top, bottom] = formatDate(value, DATE_FORMAT.default).split(', ');

    return (
        <Stack direction='column' spacing={2}>
            <Typography
                color={colors['darkNeutral-500']}
                sx={{
                    whiteSpace: 'nowrap',
                }}
                variant='hr13'
            >
                {top}
            </Typography>
            <Typography className='textWithHeightAnimation' color={colors['grey-400']} variant='smr'>
                {bottom}
            </Typography>
        </Stack>
    );
}

export function DateThirdColumnFormat({ value } = {}) {
    if (!value) {
        return '-';
    }
    const [top, bottom] = formatDate(value, DATE_FORMAT.default).split(', ');
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: '.5rem',
                whiteSpace: 'nowrap',
            }}
        >
            <Typography color={colors['darkNeutral-500']} variant='hr13'>
                {top.split(' ')[0]} {bottom}
            </Typography>
        </div>
    );
}

export function PropertyTypeFormat({ value, size } = {}) {
    return (
        <>
            <PropertyTypeIcon type={value} />
            <Typography variant={size}>{value}</Typography>
        </>
    );
}

export function PropertyNameFormat({ value, size, showHighlight, searchWords } = {}) {
    const val = value || '-';

    return (
        <Typography className={styles.propertyNameInput} variant={size}>
            {showHighlight ? (
                <Highlighter
                    highlightClassName='YourHighlightClass'
                    searchWords={searchWords}
                    textToHighlight={value}
                    autoEscape
                />
            ) : (
                val
            )}
        </Typography>
    );
}

export function RecentPropertyNameFormat({ value, type, size } = {}) {
    return (
        <div className={styles.recentPropertyNameWrapper}>
            <PropertyTypeIcon size={size} type={type} />
            <Typography>{value}</Typography>
        </div>
    );
}

export function PropertyTypeWithAddressFormat({ value = '-', size, original: { propertyType } } = {}) {
    const [mainText, ...secondaryText] = value?.split(', ') || [];

    return (
        <div className={styles.propertyAddress}>
            <PropertyTypeIcon type={propertyType} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography color={colors['darkNeutral-500']} variant={size}>
                    {mainText}
                </Typography>
                <Typography color={colors['grey-400']} variant={size}>
                    {secondaryText.slice(0, -1).join(' ')}
                </Typography>
            </div>
        </div>
    );
}

export function DocumentTypeFormat({ value, color, documentName } = {}) {
    const { type, label } = DOCUMENT_TYPES[value?.toUpperCase().replaceAll(' ', '_')] || {};
    return (
        <div className={styles.documentName}>
            <DocumentType type={type} />
            <Typography color={color} variant='hr13'>
                {documentName || label}
            </Typography>
        </div>
    );
}

export function AmountFormat({ value } = {}) {
    return (
        <div className={styles.amountWrapper}>
            <Typography color={colors['grey-300']} variant='pr13'>
                $
            </Typography>
            <Typography>{commaSeparateNumber(value)}</Typography>
        </div>
    );
}

export function DocumentTypeShortFormat({ value } = {}) {
    if (!value) return null;

    const { type } = DOCUMENT_TYPES[value.toUpperCase().replaceAll(' ', '_')] || {};
    return <DocumentType type={type} />;
}

export function ThirdPartyReportsTypeFormat({ value, variant = 'mdsb', iconSize } = {}) {
    const { label, color, size, icon } = REPORT_TYPES[String(value.toLowerCase())] || {};

    return (
        <div className={styles.reportTypeWrapper}>
            <Icon color={color} component={icon} fontSize={iconSize || size} />
            <Typography color={colors['darkNeutral-500']} variant={variant}>
                {label}
            </Typography>
        </div>
    );
}

export function DocumentStatusFormat({ value = 'processing' } = {}) {
    return <DocumentStatus status={STATUSES[value.toLowerCase()]} />;
}

export function ThirdPartyReportsStatusFormat({ value } = {}) {
    return <DocumentStatus options={THIRD_PARTY_REPORT_STATUSES} status={STATUSES[value.toLowerCase()]} />;
}

export function UnderwritingNameCell({ value, underwriting, row, column, ...cellProps }) {
    const [isChecked, setIsChecked] = useState(false);
    const { id, checked, name } = value;

    const { onChangeDefaultModel, readOnly } = underwriting;

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    if (value.checked) {
        Object.defineProperty(row, 'isHighlighted', {
            value: true,
            configurable: true,
        });
    }
    return (
        <div className={styles.modelCellWrapper}>
            {!readOnly && (
                <Switch
                    checked={isChecked}
                    data-qa='loanUnderwritingSwitcher'
                    onChange={() => {
                        setIsChecked(!checked);
                        onChangeDefaultModel(id);
                    }}
                />
            )}
            <BaseCell
                childrenClassName={styles.underwritingEllipsisInput}
                column={{
                    ...column,
                    editable: !underwriting?.readOnly,
                }}
                row={row}
                value={name}
                {...cellProps}
                dataQa='loanUnderwritingName'
            />

            {checked && (
                <Typography.Uppercase
                    className={styles.modelLabel}
                    color={colors['darkBlue-500']}
                    component='span'
                    variant='xssb'
                >
                    Default
                </Typography.Uppercase>
            )}
        </div>
    );
}

export function UnderwritingNotesCell({ value, column, underwriting, ...cellProps }) {
    return (
        <Tooltip placement='top' title={value} arrow>
            <div>
                <BaseCell
                    childrenClassName={styles.underwritingEllipsisInput}
                    {...cellProps}
                    column={{
                        ...column,
                        editable: !underwriting?.readOnly,
                    }}
                    dataQa='loanUnderwritingSwitcher'
                    value={value}
                />
            </div>
        </Tooltip>
    );
}

export function UnderwritingNameFormat({ value }) {
    if (!value) {
        return (
            <Typography color={colors['grey-300']} variant='smr'>
                Unnamed
            </Typography>
        );
    }

    return value;
}

export function UnderwritingTypeFormat({ value }) {
    return UNDERWRITING_TYPES[value]?.displayLabel ?? UNDERWRITING_TYPES[value]?.label;
}

export function Actions({ entity }) {
    const handlePreview = () => {
        openExternalWindow({
            url: `/viewFile.html?docId=${entity.id}`,
            options: 'height=900,width=700,left=300',
        });
    };

    return (
        <>
            <IconButton.Small color='secondary' onClick={handlePreview}>
                <Icon color={colors['darkNeutral-500']} component={EyeSvg} />
            </IconButton.Small>
            <IconButton.Small
                color='secondary'
                component={Link}
                // todo improve link transfer
                href={`${documentsApi.DOWNLOAD_URL}?entityType=${entity.type}&entityId=${entity.id}&fileName=${entity.fileName}`}
            >
                <Icon color={colors['darkNeutral-500']} component={ImportSvg} />
            </IconButton.Small>
            <IconButton.Small>
                <Icon color={colors['darkNeutral-400']} component={MoreSvg} />
            </IconButton.Small>
        </>
    );
}

export function MoreDetailsAction(
    { menuItems = [], value, row = {}, disabledActionMenu = false, dataQa = 'more-details-action' } = {},
    index,
    rowData = {}
) {
    const nextMenuItems = typeof menuItems === 'function' ? menuItems(value, row) : menuItems;
    const disabled = typeof disabledActionMenu === 'function' ? disabledActionMenu(row) : disabledActionMenu;

    const rowId = row.original?.id || rowData?.id || row.id;

    const [{ menu, anchor }, { setAnchor }] = useMenuList({
        items: nextMenuItems,
        id: rowId,
        row: row.original,
        index: row.id,
    });

    const handleClick = (e) => {
        e.stopPropagation();
        setAnchor(e?.currentTarget);
    };

    return (
        <>
            <IconButton.Small
                className={cx(styles.moreActions, { opened: !!anchor })}
                data-qa={dataQa}
                disabled={disabled}
                onClick={handleClick}
            >
                <Icon color={colors['grey-300']} component={MoreSvg} />
            </IconButton.Small>
            {menu}
        </>
    );
}

function ActionsListDefault({ actions = [], row, cell, dataQa }) {
    if (['equity', 'purchase', 'Balance'].includes(cell.row.original?.type)) return null;
    return (
        <div className={styles.actionListWarapper}>
            {actions.map((action, i) => {
                const {
                    component: Component,
                    actionComponent: ActionComponent = IconButton.Small,
                    actionComponentProps = {},
                    iconProps = {},
                    label = '',
                    icon,
                    onClick = () => {},
                    color = colors['grey-300'],
                } = typeof action === 'function' ? action(row) : action;

                return (
                    <ActionComponent
                        key={i}
                        className='action'
                        component={Component}
                        data-qa={dataQa}
                        onClick={(e) => onClick(e, { row, cell })}
                        {...actionComponentProps}
                    >
                        {icon && <Icon color={color} component={icon} {...iconProps} />}
                        {label}
                    </ActionComponent>
                );
            })}
        </div>
    );
}

export const ActionsList = memo(ActionsListDefault);

export function ContextMenuAction({
    menus: { icon = MoreSvg, content = {} } = {},
    showFor,
    row = {},
    value,
    dataQa,
    className,
} = {}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const userPermission = {
        edit: useTestPermission(PERMISSIONS.EDIT),
    };

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (anchorEl) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const { id: rowId } = row;
    const { data, node } = content[value] || {};

    if (!data && !node) return null;

    let menuContent;
    if (data && Array.isArray(data)) {
        menuContent = typeof node === 'function' ? node({ data: data[rowId], rowId, handleClose }) : node;
    } else {
        const { data: arrData, ...rest } = data;
        menuContent =
            typeof node === 'function'
                ? node({ data: arrData[rowId], userPermission, arrData, ...rest, rowId, handleClose })
                : node;
    }

    return !showFor?.includes(value) ? null : (
        <div className={className}>
            <IconButton.Small data-qa={dataQa} onClick={handleClick}>
                <Icon color={colors['grey-300']} component={icon} />
            </IconButton.Small>
            <BasePopover id={rowId} isOpen={isOpen} target={anchorEl} onClose={handleClose}>
                {menuContent}
            </BasePopover>
        </div>
    );
}

export function NumberDecimalsFormat({
    value,
    size = 'smr',
    decimal = 1,
    color,
    prefix = '%',
    prefixColor,
    showPrefix = false,
    showZero = false,
}: {
    value: unknown;
    size?: string;
    decimal?: number;
    color?: string;
    prefix?: string;
    prefixColor?: string;
    showPrefix?: boolean;
    showZero?: boolean;
} = {}) {
    if (+value === 0 && !showZero) {
        return null;
    }

    return (
        <div style={{ display: 'inline-flex', flexDirection: 'row', gap: '3px' }}>
            <Typography color={color || (Number(value) < 0 ? colors['red-500'] : 'inherit')} variant={size}>
                {Number(value) && !!value ? Number(value).toFixed(decimal) : Number(0).toFixed(decimal)}
            </Typography>
            {showPrefix && (
                <Typography
                    color={prefixColor || (Number(value) < 0 ? colors['red-500'] : colors['grey-300'])}
                    component='span'
                    variant={size}
                >
                    {prefix}
                </Typography>
            )}
        </div>
    );
}

export const PercentColumnFormat = ({
    value,
    size = 'smr',
    decimal = 1,
    color,
    prefixColor,
    showPrefix = true,
    showZero = false,
}: {
    value: unknown;
    size?: string;
    decimal?: number;
    color?: string;
    prefixColor?: string;
    showPrefix?: boolean;
    showZero?: boolean;
} = {}) => NumberDecimalsFormat({ value, size, decimal, color, prefixColor, showPrefix, showZero });

export const ltvFormat = ({ value, size }) => {
    const color = Number(value) > 80 ? colors.error : 'inherit';

    return PercentColumnFormat({ value, size, color });
};

export const dscrFormat = ({ value, size }) => {
    const color = Number(value) < 1.2 ? colors.error : 'inherit';

    return NumberDecimalsFormat({ value, size, color, decimal: 2 });
};

export const debtYieldFormat = ({ value, size }) => {
    const color = Number(value) < 10 ? colors.error : 'inherit';

    return PercentColumnFormat({ value, size, color, decimal: 1 });
};

export const propertyScoreFormat = ({ value = 15, size }) => {
    const color = Number(value) < 50 ? colors.error : 'inherit';

    return (
        <Typography color={color} variant={size}>
            {Number(value)}
        </Typography>
    );
};

export const statusColumnFormatMap = {
    // eslint-disable-next-line no-use-before-define
    Portfolio: ({ value }) => getStatusColumnFormat(PORTFOLIO_STATUSES, value),
    // eslint-disable-next-line no-use-before-define
    Loans: ({ value }) => getStatusColumnFormat(LOAN_STATUSES_LENDER, value),
    // eslint-disable-next-line no-use-before-define
    User: ({ value = 'INVITED' }) => getStatusColumnFormat(USER_STATUSES, value),
};

export function StressTestStatusFormat({ value } = {}) {
    return statusColumnFormatMap.Loans({ value });
}

function getStatusColumnFormat(statuses, value) {
    const { color, label } = statuses.find((item) => item.value === value) || {};
    return (
        <>
            <Typography as='span' className={styles.dot} color={color} />
            <Typography variant='smr'>{label}</Typography>
        </>
    );
}

export function FullUserInfo({ value: { fullName, isLead, profilePictureExists, userId } = {} }) {
    return (
        <Stack direction='row' spacing={4}>
            <Avatar
                src={userAvatarUrl(userId, profilePictureExists)}
                sx={{ height: 24, width: 24, bgcolor: colors['grey-50'] }}
            >
                <Icon color={colors['grey-200']} component={ProfileSvg} fontSize={18} />
            </Avatar>
            <Typography variant={isLead ? 'mdsb' : 'mdr'}>{fullName}</Typography>
            {isLead && (
                <Chip
                    label='Team Lead'
                    size='small'
                    sx={{
                        fontWeight: 700,
                        fontSize: '12px',
                        color: colors['darkBlue-500'],
                        background: hex2rgba(colors['blue-main'], 0.5),
                    }}
                    variant='filled'
                />
            )}
        </Stack>
    );
}

export function UserFullNameWithPositionDepartment({ value }) {
    return <UserInfoUI {...value} />;
}

export function WithEmailInfo({ value }) {
    return (
        <>
            <Icon className={styles.asset} color={colors['grey-400']} component={SmsSvg} fontSize={14} />
            {value}
        </>
    );
}

export function WithCompanyInfo({ value }) {
    return (
        <>
            <Icon className={styles.asset} color={colors['grey-400']} component={BuildingIcon} fontSize={14} />
            {value}
        </>
    );
}

export function WithPhoneInfo({ value }) {
    return (
        <>
            <Icon className={styles.asset} color={colors['grey-400']} component={CallSvg} fontSize={14} />
            <span className={styles.nowrap}>{value}</span>
        </>
    );
}

export function EmailPhoneInfo({ value: { email, phone } }) {
    return <ContactsInfo email={email} phone={formatToUsPhoneNumber(phone)} />;
}

export function CalculateColumnSum({ rows, column: { formatting = ({ value }) => value, id } }) {
    const total = React.useMemo(() => rows.reduce((sum, row) => (Number(row.original[id]) || 0) + sum, 0), [rows, id]);
    return formatting({ value: Math.round(total) });
}

export function ExpanderCell({ row }) {
    return row.canExpand ? (
        <span
            {...row.getToggleRowExpandedProps({
                style: {
                    paddingLeft: `${row.depth * 2}rem`,
                },
            })}
        >
            {row.isExpanded ? '-' : '+'}
        </span>
    ) : null;
}

export const countFilteredTotalSum = (values, targets) =>
    Object.entries(values)
        .filter(([key]) => targets.some(({ name }) => name === key))
        .reduce((acc, [, val]) => (Number(val) || 0) + acc, 0);

export const LoanProgramFormat = ({ value }) =>
    LOAN_PROGRAMMS.find((item) => item.value === value?.toUpperCase())?.label;

export function TaskTypeFormat({ value = 'OTHER', size = 'hr13' } = {}) {
    return (
        <Stack alignItems='center' direction='row' spacing={2}>
            <Icon color={colors['grey-400']} component={TASK_TYPE[value]?.icon || TASK_TYPE.OTHER.icon} />
            <Typography color={colors['grey-500']} variant={size}>
                {TASK_TYPE[value]?.label || 'Other'}
            </Typography>
        </Stack>
    );
}

const DOCUMENT_TYPE_LIST_WITH_PROPERTY_INDICATION = [
    DOCUMENT_TYPES.RENT_ROLL.value,
    DOCUMENT_TYPES.OPERATING_STATEMENT.value,
];

const TASK_TYPE_LIST_WITH_REAL_DOCUMENT_DATA_INDICATION = [
    TASK_TYPE_ENUM.SIGN_DOCUMENT,
    TASK_TYPE_ENUM.REVIEW_DOCUMENT,
];

export function TaskDataCell({ row: { original } = {} } = {}) {
    const {
        documentType = 'Other',
        documentPeriod = null,
        property: { propertyName, address } = { propertyName: null, address: null },
        contact,
        type,
        document,
        docSyncTask,
    } = original;

    const { userMember, companyMember, user } = contact || {};
    const { firstname, lastname } = userMember?.userInfo || {};
    const { name: companyName } = companyMember?.company || {};
    const entity = user ? (!!firstname || !!lastname) && `${firstname} ${lastname}` : !!companyName && `${companyName}`;
    const isShowingProperty = DOCUMENT_TYPE_LIST_WITH_PROPERTY_INDICATION.includes(documentType);
    const isShowingSubLine = isShowingProperty || !!entity;
    const isShowingRealDocumentData = TASK_TYPE_LIST_WITH_REAL_DOCUMENT_DATA_INDICATION.includes(type);

    return (
        <div className={styles.taskDocumentData} style={{ pointerEvents: 'none' }}>
            <div style={{ display: 'flex', gap: '.25rem', alignItems: 'center' }}>
                <DocumentTypeFormat
                    color={colors['darkNeutral-500']}
                    documentName={isShowingRealDocumentData ? document?.name ?? docSyncTask?.title : null}
                    size='hr13'
                    value={isShowingRealDocumentData ? document?.type ?? docSyncTask?.documentType : documentType}
                />
                {!!documentPeriod && (
                    <div style={{ display: 'flex', gap: '.25rem', alignItems: 'center', flexWrap: 'nowrap' }}>
                        <Icon color={colors['grey-400']} component={CalendarSvg} />
                        <DateFormat color={colors['grey-400']} size='hr12' value={documentPeriod} inline />
                    </div>
                )}
            </div>
            {isShowingSubLine && (
                <div className={cx(styles.taskDocumentAdditional, 'textWithHeightAnimation')}>
                    <div style={{ display: 'flex', gap: '.25rem', alignItems: 'center' }}>
                        <Icon color={colors['grey-400']} component={isShowingProperty ? LocationIcon : ProfileSvg} />
                        <Typography color={colors['grey-400']} variant='hr12'>
                            {isShowingProperty ? propertyName || formatPropertyAddress(address)[0] || '-' : entity}
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
}

export function TaskTextDataFormat({ original: { title = '', description = '' } }) {
    const ref = useRef(null);
    const [maxWidth, setMaxWidth] = useState(0);

    useEffect(() => {
        if (ref?.current) {
            const computedStyle = getComputedStyle(ref.current.parentElement);

            setMaxWidth(ref.current.parentElement.clientWidth - parseFloat(computedStyle.paddingLeft) * 2 - 10);
        }
    }, []);

    return (
        <div className={styles.todoDescription} ref={ref} style={{ maxWidth }}>
            <Typography color={colors['darkNeutral-500']} variant='hr13'>
                {title}
            </Typography>

            {!!description && (
                <Tooltip className='textWithHeightAnimation' placement='top' title={description} arrow>
                    <Typography color={colors['grey-500']} variant='pr12'>
                        {description}
                    </Typography>
                </Tooltip>
            )}
        </div>
    );
}

export function UserInfoCell({
    value: { email, userName } = {},
    column: { cellProps: { isShowUserName = false } = {} } = {},
}) {
    if (!userName?.trim()) {
        return null;
    }

    return (
        <Stack alignItems='center' direction='row' spacing={2} style={{ flexWrap: 'nowrap' }}>
            <Tooltip placement='top' title={userName} arrow>
                {email ? (
                    <Avatar
                        alt={`${userName}'s avatar`}
                        src={`/api/v2/user/email/${encodeURIComponent(email)}/avatar`}
                        sx={{ width: 24, height: 24 }}
                    />
                ) : (
                    <Icon className={styles.asset} color={colors['grey-400']} component={UserSvg} fontSize={13} />
                )}
            </Tooltip>
            {isShowUserName && (
                <Typography style={{ whiteSpace: 'nowrap' }} variant='hr13'>
                    {userName}
                </Typography>
            )}
        </Stack>
    );
}

export function TaskTimersFormat({
    value = '',
    original: { isLender = false, timelineEvents = {} } = { timelineEvents: {} },
}) {
    const { repeater, reminder } = timelineEvents || {};
    return (
        <div className={styles.dueDateWrapper}>
            <div className={styles.dueDate}>
                <Icon color={colors['grey-500']} component={CalendarSvg} />
                <Typography color={colors['grey-500']} variant='hr11'>
                    {'Due: '}
                </Typography>
                <Typography color={colors['darkNeutral-500']} variant='hsb11'>
                    {formatDate(value || '', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })}
                </Typography>
            </div>
            {isLender ? (
                <div className={styles.dueDateAdditional}>
                    {reminder ? <Icon color={colors['grey-400']} component={NotificationIcon} fontSize={10} /> : null}
                    {repeater ? <Icon color={colors['grey-400']} component={TimerSvg} fontSize={10} /> : null}
                </div>
            ) : null}
        </div>
    );
}

export function HeaderActionButton({ headerProps: { action = {} } = {} } = {}) {
    return (
        <Button
            size={action.size}
            startIcon={action.icon ? <Icon color={colors['grey-300']} component={action.icon} fontSize={9} /> : null}
            onClick={action.onClick}
        >
            {action.label}
        </Button>
    );
}

export function DocumentNameFormat({ value = '', original: { name = '' } = {} } = {}) {
    function getTextComponent() {
        return (
            <>
                <Typography color={colors['darkNeutral-500']} variant='hr13'>
                    {name.length ? name : '- '}
                </Typography>
                <Typography color={colors['grey-400']} variant='hr12'>
                    {value.length ? value : '-'}
                </Typography>
            </>
        );
    }

    return (
        // TODO Tech Debt: create common wrapper/HOC for similar overflow cases
        <div className={styles.documentNameFormat}>
            {getTextComponent()}
            <div className={styles.documentNameFormatOnHover}>{getTextComponent()}</div>
        </div>
    );
}

export function DocumentShortNameFormat({ value = '' } = {}) {
    return (
        <div className={styles.shortDocumentNameWrapper}>
            <Icon color={colors['grey-150']} component={DocumentIcon} fontSize={18} />
            <Typography color={colors['grey-600']} variant='pr14'>
                {value ?? '-'}
            </Typography>
        </div>
    );
}

export function DocumentTypeSecondFormat({ value = 'Other', original: { pages = null } = {} } = {}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '.25rem' }}>
            <DocumentTypeFormat value={value} />
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', gap: '.25rem' }}>
                <Typography color={colors['grey-400']} variant='hsb12'>
                    Pages:
                </Typography>
                <Typography color={colors['grey-400']} variant='hr12'>
                    {pages || 'All'}
                </Typography>
            </div>
        </div>
    );
}

export function DocumentSelectCell({ value: { id }, documents: { onSelectDocuments } }) {
    return (
        <Checkbox
            defaultChecked={false}
            onClick={(e) => {
                onSelectDocuments(e, id);
            }}
        />
    );
}

export function LoanIdCell({
    value = '-',
    row: { original: { loanDetailsId = null } = {} } = {},
    column: { cellProps = {} },
    handleCellClick,
}) {
    return (
        <div
            className={cx(styles.loanName, { [styles.clickable]: cellProps?.clickable && loanDetailsId })}
            role='presentation'
            onClick={cellProps?.clickable && loanDetailsId ? () => handleCellClick(loanDetailsId) : undefined}
        >
            <Typography color={colors['darkNeutral-500']} variant='sm'>
                {value || '-'}
            </Typography>
        </div>
    );
}

export function LoanNameCell({
    value = '-',
    row: { original: { address = 'LOAN', loanDetailsId = null } = {} } = {},
    column: { cellProps = {} },
    handleCellClick,
}) {
    return (
        <div
            className={cx(styles.loanName, { [styles.clickable]: cellProps?.clickable && loanDetailsId })}
            role='presentation'
            onClick={cellProps?.clickable && loanDetailsId ? () => handleCellClick(loanDetailsId) : undefined}
        >
            <Typography color={colors['darkNeutral-500']} variant='smsb'>
                {value || formatPropertyAddress(address)[0] || '-'}
            </Typography>
        </div>
    );
}

export function DocumentEntityFormat({ size, original = {} } = {}) {
    if (!original?.documentMembers.length) {
        return '-';
    }
    let type;
    let name;
    const {
        documentMembers: { user, companyMember, userMember },
    } = original;
    if (user) {
        name = `${userMember?.userInfo?.firstname} ${userMember?.userInfo?.lastname}`;
        type = userMember?.member?.type ? MEMBER_TYPES[userMember?.member?.type] : 'Other';
    } else {
        name = companyMember?.company?.name;
        type = companyMember?.company?.compantType ? MEMBER_TYPES[companyMember?.company?.compantType] : 'Other';
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '.5rem',
            }}
        >
            <Typography color={colors['darkNeutral-500']} variant={size}>
                {name}
            </Typography>
            <Typography color={colors['grey-400']} variant={size}>
                {type}
            </Typography>
        </div>
    );
}

export function ValuationScenarioFormat({ value } = {}) {
    return value ? scenarioLabels[value] || value : null;
}

export function WithChipFormat({ value = { name: '', badge: '' } }) {
    const { name, badge } = value;

    return (
        <>
            <Typography color={colors['darkNeutral-500']} variant='hsb13'>
                {name}
            </Typography>
            {badge && (
                <Typography className={styles.chip} color={colors['grey-400']} variant='hsb10'>
                    {badge}
                </Typography>
            )}
        </>
    );
}

export function ColoredShiftFormat({ value = { number: 0, units: '' } }) {
    const { number, units } = value;
    const isPositive = number > 0;
    const isEquals = number === 0;
    // eslint-disable-next-line no-nested-ternary
    const textColor = isPositive ? colors['green-500'] : isEquals ? colors['grey-400'] : colors['red-500'];
    const mark = isPositive ? '+' : '';
    // eslint-disable-next-line no-nested-ternary
    const arrowComponent = isPositive ? ArrowUpSvg : isEquals ? null : ArrowDownSvg;

    return (
        <Typography color={textColor} variant='hr13'>
            {`${mark}${number}${units || ''}`} <Icon component={arrowComponent} />
        </Typography>
    );
}

export function ProgressBarFormat({ value, tooltip }) {
    return (
        <div className={styles.progressBarFormat}>
            <CustomTooltip {...tooltip}>
                <LinearProgress className={styles.progressBarFormatBar} value={value} variant='determinate' />
            </CustomTooltip>
        </div>
    );
}

export function HeaderTwoSectioned(first, second) {
    return (
        <>
            <Typography align='right' color={colors['grey-500']} variant='smsb'>
                {first}
            </Typography>
            {second && (
                <Typography align='right' color={colors['grey-400']} variant='smr'>
                    {second}
                </Typography>
            )}
        </>
    );
}

export function TextBolderFormat({ value }) {
    return (
        <Typography fontWeight='600' variant='hr13'>
            {value}
        </Typography>
    );
}

export function RoleLabelFormat({ value }) {
    return (
        <Badge textColor={colors['grey-400']} textVariant='xsb' variant='secondary' isCapitalize>
            {ROLES[value]?.label}
        </Badge>
    );
}

export function getServerFormatSelection(selection) {
    const {
        data: { selected, isInvertedSelection },
    } = selection;

    const selectedField = isInvertedSelection ? 'unchecked' : 'checked';

    return {
        [selectedField]: selected,
        selectAll: isInvertedSelection,
    };
}

export function isActualSelected(itemId, selection) {
    const {
        data: { selected, isInvertedSelection },
    } = selection;
    return selected?.includes(itemId) === !isInvertedSelection;
}
