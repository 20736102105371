export const ROLES = {
    OWNER: {
        value: 'OWNER',
        label: 'Owner',
    },
    TEAM: {
        value: 'TEAM',
        label: 'Team Member',
    },
    BORROWER: {
        value: 'BORROWER',
        label: 'Borrower',
    },
    LENDER: {
        value: 'LENDER',
        label: 'Lender',
    },
    OTHER: {
        value: 'OTHER',
        label: 'Other',
    },
};
