const grey = {
    100: '#f8f9fa',
    200: '#e9ecef',
    300: '#d3d5df',
    400: '#8990A9',
    500: '#474d66',
    600: '#6c757d',
    700: '#abadb2',
    800: '#343a40',
    900: '#212529',
    A200: '#E6E8F0',
};

export default grey;
