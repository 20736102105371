import { newWindowSvgString } from '@utils/charts/tooltip/templates/helpers';

import styles from '../styles.module.scss';

const base = ({ showValue, showMarker, format, tooltipName, value, marker, link, ...rest }) => `<div class=${
    styles.baseTemplate
}>
        <span>
            ${showMarker ? marker : ''}${tooltipName}
        </span>
        <span>
            ${showValue ? `${format(value)} ${rest?.units}` : ''}
            ${link ? `<a href=${link} target="_blank" rel="noopener noreferrer">${newWindowSvgString}</a>` : ''}
        </span>
    </div>`;

export default base;
