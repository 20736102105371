// todo remove current reducer
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    type: null,
};

const drawerSlice = createSlice({
    name: '[ui] _drawer',
    initialState,
    reducers: {
        show(state, action) {
            state.isOpen = true;
            state.type = action.payload;
        },
        hide() {
            return initialState;
        },
    },
});

export const { show: showDrawer, hide: hideDrawer } = drawerSlice.actions;

export const selectUiDrawerState = (state) => state.ui._drawer;
export const selectIsOpenDrawerState = (state) => state.ui._drawer.isOpen;

export default drawerSlice.reducer;
