import TableFooter from '@mui/material/TableFooter';
import cx from 'classnames';

import TableCell from '@ui/TableCell';
import TableRow from '@ui/TableRow';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import styles from '../../styles.module.scss';

let skipCells = 0;

function BaseFooter({ groups = [], cols, className }) {
    return (
        <TableFooter className={className}>
            {groups.map((group, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index} {...group.getFooterGroupProps()}>
                    {group.headers.reduce((acc, column, idx) => {
                        const { footerProps = {}, cellProps } = column;

                        if (footerProps.colSpan) {
                            skipCells = footerProps.colSpan;
                        }
                        if (!footerProps.colSpan && skipCells) {
                            skipCells -= 1;
                        }
                        if (!footerProps.colSpan && skipCells) {
                            return acc;
                        }

                        return acc.concat(
                            <TableCell
                                {...column.getFooterProps([
                                    {
                                        className: cx(footerProps.className, cellProps?.className, {
                                            [styles.alignRight]: footerProps.isRight,
                                        }),
                                        style: {
                                            ...footerProps.sx,
                                            ...(cellProps?.sx || {}),
                                        },
                                    },
                                ])}
                                {...footerProps}
                                sx={{ padding: '6px 20px', ...(idx === 0 && { paddingLeft: '36px' }) }}
                            >
                                <Typography
                                    className={styles.cell}
                                    color={colors['grey-50']}
                                    sx={footerProps?.sx}
                                    variant='hb12'
                                >
                                    {column.render('Footer', { footerCols: cols })}
                                </Typography>
                            </TableCell>
                        );
                    }, [])}
                </TableRow>
            ))}
        </TableFooter>
    );
}

export default BaseFooter;
