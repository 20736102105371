import { combineReducers } from 'redux';

import portfolioReducer, { watchPortfolioFLow } from '@scc/store/ducks/flow/portfolio';
import requestLoanReducer, { watchLoanRequestFLow } from '@scc/store/ducks/flow/requestLoan';

import documentsReducer, { watchDocumentsFLow } from '@store/ducks/flow/documents';
import lenderReducer, { watchLenderFLow } from '@store/ducks/flow/lender';
import loanReducer, { watchLoanFLow } from '@store/ducks/flow/loan';
import memberReducer, { watchMemberFLow } from '@store/ducks/flow/members';

import excelReportReducer, { watchExcelReportFlow } from './excelReport';

export const flowWatchers = [
    watchLoanFLow,
    watchLoanRequestFLow,
    watchPortfolioFLow,
    watchMemberFLow,
    watchLenderFLow,
    watchDocumentsFLow,
    watchExcelReportFlow,
];

export default combineReducers({
    loan: loanReducer,
    requestLoan: requestLoanReducer,
    portfolio: portfolioReducer,
    member: memberReducer,
    documents: documentsReducer,
    lender: lenderReducer,
    excelReport: excelReportReducer,
});
