import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { createExternalLink } from '@utils/browser';

import { socialNetworkAuth } from '@scc/store/ducks/auth';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import FacebookSvg from '@assets/icons/filled/facebook2.svg';
import GoogleSvg from '@assets/icons/filled/google.svg';
import LinkedInSvg from '@assets/icons/filled/linkedin.svg';
import useHistoryPreviousPath from '@shared/hooks/useHistoryPreviousPath';

import styles from './styles.module.scss';

const SOCIAL_NETWORKS = {
    google: {
        name: 'Google',
        color: colors['socialNetworks-google'],
        icon: GoogleSvg,
        path: 'google',
    },
    facebook: {
        name: 'Facebook',
        color: colors['socialNetworks-facebook'],
        icon: FacebookSvg,
        path: 'facebook',
    },
    linkedin: {
        name: 'LinkedIn',
        color: colors['socialNetworks-linkedin'],
        icon: LinkedInSvg,
        path: 'linkedin',
    },
};

const defaultProps = {
    logoOnly: false,
    variant: 'contained',
};

function SocialNetworkButton({ logoOnly, type, variant, ...props }) {
    const dispatch = useDispatch();

    const fromHistoryPath = useHistoryPreviousPath('redirect-url');

    const network = SOCIAL_NETWORKS[type];
    const isContained = variant === 'contained';
    const backgroundColor = isContained ? network.color : 'initial';
    const color = isContained ? colors.white : network.color;

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(socialNetworkAuth({ payload: e.currentTarget.href, meta: { delay: 100 } }));
    };

    return (
        <Button
            variant={variant}
            onClick={handleClick}
            {...props}
            className={cx(styles[`button${isContained ? '-contained' : ''}`], {
                [styles.withText]: !logoOnly,
            })}
            href={createExternalLink(`/oauth2/authorization/${network.path}`, fromHistoryPath)}
            sx={{
                backgroundColor,
                color,
                border: `1px solid ${colors['grey-400']}`,
                ':hover, :active, :focus': {
                    backgroundColor,
                    color,
                    border: `1px solid ${colors['blue-400']}`,
                },
            }}
        >
            <div className={styles.iconWrapper}>
                <Icon component={network.icon} fontSize={22} />
            </div>
            {!logoOnly && (
                <div className={styles.text}>
                    <Typography component='div' sx={{ lineHeight: '24px' }} variant='lgr'>
                        Continue with{' '}
                        <Typography component='span' sx={{ lineHeight: '24px' }} variant='lgb'>
                            {network.name}
                        </Typography>
                    </Typography>
                </div>
            )}
        </Button>
    );
}

SocialNetworkButton.propTypes = {
    type: PropTypes.oneOf(['google', 'facebook', 'linkedin']).isRequired,
    logoOnly: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'outlined']),
};

SocialNetworkButton.defaultProps = defaultProps;

export default SocialNetworkButton;
