import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { formatter } from '@utils/index';
import { isMFLike } from '@utils/properties';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';
import PropertyTypeIcon from '@components/PropertyTypeIcon';

import colors from '@themes/palette/export.module.scss';

import TickCircleSVG from '@assets/icons/outlined/tick-circle.svg';
import DocumentSvg from '@icons/outlined/document-1.svg';

import ExistingParcelDetails from './ExistingParcelDetails';
import styles from './styles.module.scss';

const getSizing = (propertytype, size, units) => {
    const isMFType = isMFLike(propertytype);
    const typeUnits = isMFType ? 'Units' : 'SF';
    const typeSize = isMFType ? units : size;
    return `${formatter.number(typeSize)} ${typeUnits}`;
};

export const PARCEL_ID = {
    EMPTY: 'empty',
};

const createParcel = { parcelid: PARCEL_ID.EMPTY };

function ExistingParcels({ parcels = [], propertyAddress, selected, onSelect }) {
    const [address, stateAddress, countryAddress] = propertyAddress?.split(',') || [];
    const parcelsLength = parcels?.length;
    const hasParcels = Boolean(parcelsLength);
    const isCreateParcelSelected = selected?.parcelid === createParcel.parcelid;

    const subHeader = hasParcels && (
        <Typography color={colors['grey-400']} variant='smr' gutterBottom>
            Select from existing parcel
        </Typography>
    );
    const newParcel = { ...createParcel, loan: { propertyname: address } };

    const handleSelectParcel = (parcel) => () =>
        onSelect(selected?.parcelid === parcel?.parcelid ? undefined : { ...parcel, loan: { propertyname: address } });

    return (
        <List className={styles.existingList} subheader={subHeader}>
            <ItemList items={parcels}>
                {(parcel) => {
                    const {
                        parcelid,
                        propertytype,
                        size,
                        units,
                        hasRentRoll,
                        loan: { propertyname, propertyid } = {},
                    } = parcel;
                    const isSelected = selected?.parcelid === parcel?.parcelid;
                    return (
                        <ListItem
                            key={parcelid}
                            secondaryAction={
                                <ExistingParcelDetails
                                    action={
                                        isSelected ? (
                                            <IconButton
                                                className={styles.iconButton}
                                                onClick={handleSelectParcel(parcel)}
                                            >
                                                <Icon component={TickCircleSVG} />
                                            </IconButton>
                                        ) : (
                                            <Button
                                                variant={isSelected ? 'contained' : 'outlined'}
                                                onClick={handleSelectParcel(parcel)}
                                            >
                                                Select
                                            </Button>
                                        )
                                    }
                                    propertyId={propertyid}
                                />
                            }
                            disablePadding
                        >
                            <ListItemButton
                                selected={isSelected}
                                variant='outlined'
                                onClick={handleSelectParcel(parcel)}
                            >
                                <ListItemText
                                    primary={
                                        <Typography color={colors['darkNeutral-700']} variant='h6b' gutterBottom>
                                            {propertyname || address}
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography as='p' variant='sms' gutterBottom>
                                                {address},{' '}
                                                <Typography as='span' color={colors['grey-400']} variant='sms'>
                                                    {stateAddress}, {countryAddress}
                                                </Typography>
                                            </Typography>
                                            <Stack.Menu justifyContent='start'>
                                                <Stack.Menu justifyContent='start' spacing={5}>
                                                    <PropertyTypeIcon size='small' type={propertytype} />{' '}
                                                    <Typography
                                                        color={colors['darkNeutral-500']}
                                                        size='small'
                                                        variant='hr13'
                                                    >
                                                        {propertytype}
                                                    </Typography>
                                                </Stack.Menu>
                                                <Typography variant='smr'>
                                                    {getSizing(propertytype, size, units)}
                                                </Typography>
                                                {hasRentRoll && <Icon component={DocumentSvg} />}
                                            </Stack.Menu>
                                        </>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                }}
            </ItemList>
            {(hasParcels || address) && (
                <ListSubheader sx={{ bottom: 0 }} disableGutters>
                    <ListItem
                        as='span'
                        secondaryAction={
                            isCreateParcelSelected ? (
                                <IconButton className={styles.iconButton} onClick={handleSelectParcel(createParcel)}>
                                    <Icon component={TickCircleSVG} />
                                </IconButton>
                            ) : (
                                <Button
                                    variant={isCreateParcelSelected ? 'contained' : 'outlined'}
                                    onClick={handleSelectParcel(newParcel)}
                                >
                                    Select
                                </Button>
                            )
                        }
                        disablePadding
                    >
                        <ListItemButton
                            selected={isCreateParcelSelected}
                            sx={{ minHeight: 64 }}
                            onClick={handleSelectParcel(newParcel)}
                        >
                            <ListItemText primary={<Typography variant='h6b'>Create New Parcel</Typography>} />
                        </ListItemButton>
                    </ListItem>
                </ListSubheader>
            )}
        </List>
    );
}

export default ExistingParcels;
