export const iconLarge = {
    minWidth: 'auto',
    padding: '8px',
    width: '44px',
    height: '44px',

    '& > .MuiIcon-root': {
        fontSize: '24px',
    },
};

export const iconMedium = {
    minWidth: 'auto',
    padding: '8px',
    borderRadius: '8px',
    width: '32px',
    height: '32px',

    '& > .MuiIcon-root': {
        fontSize: '20px',
    },
};

export const iconSmall = {
    padding: '6px',
    width: '28px',
    height: '28px',

    '& > .MuiIcon-root': {
        fontSize: '16px',
    },
};
