import { createSlice, createSelector } from '@reduxjs/toolkit';
import { put, take, takeLatest, select, call } from 'redux-saga/effects';

import { formatData } from '@utils/benchmarking';

import links from '@scc/router/links';

import { benchmarkingApi } from '@api';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[benchmarking]';

const initialState = {
    data: [],
    properties: [],
    similarProperties: [],
};

const benchmarkingSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        add(state, action) {
            state.properties = [...state.properties, action.payload];
        },
        getNewProperty() {},
        remove(state, action) {
            const copy = [...state.properties];
            copy.splice(action.payload - 1, 1);
            state.properties = copy;
            state.data = formatData(copy, state.data);
        },
        loadSimilar(state, action) {
            const filteredData = action.payload.filter(({ countyId, parcelId }) => countyId && parcelId);
            state.similarProperties = filteredData;
            state.similarPropertiesWithoutCmbs = filteredData.filter(({ source }) => source !== 'cmbs');
        },
        open(state) {
            state.properties = [];
        },
        update(state, action) {
            state.properties = action.payload;
        },
    },
});

export const {
    add: addProperty,
    remove: removeProperty,
    open: openBenchmarking,
    loadSimilar: loadSimilarProperty,
    getNewProperty: getProperty,
} = benchmarkingSlice.actions;

export default benchmarkingSlice.reducer;

export const selectBenchmarkingState = (store) => store.benchmarking;

const NUMBER_ADDING_PROPERTY = 10;

export const selectSimilarTransaction = createSelector(
    selectBenchmarkingState,
    ({ similarProperties, similarPropertiesWithoutCmbs }) => ({
        similarProperties,
        limitedSimilarProperties: similarProperties.slice(0, NUMBER_ADDING_PROPERTY),
        limitedSimilarPropertiesWithoutCmbs: similarPropertiesWithoutCmbs.slice(0, NUMBER_ADDING_PROPERTY),
    })
);

function* getBenchmarkingSaga(property) {
    try {
        const { countyId, parcelId, userid, userId } = property;
        const { similarProperties } = yield select(selectBenchmarkingState);
        const { countyid: sourceCountyId = null, parcelid: sourceParcelId = null } = similarProperties[0] || {};
        const tempUserId = !userid ? userId : userid;

        const payload =
            sourceParcelId && sourceCountyId && (countyId !== sourceCountyId || parcelId !== sourceParcelId)
                ? { sourceParcelId, sourceCountyId, parcelId, countyId, userid: tempUserId }
                : { parcelId, countyId, userid: tempUserId };

        const { status, data } = yield call(benchmarkingApi.getBenchmark, payload);
        if (status === 'success') {
            yield put(addProperty({ ...property, ...data }));
        } else {
            throw new Error('Oops Something went wrong');
        }
    } catch (error) {
        // do nothing
    }
}

function* openBenchmarkingSaga({ payload }) {
    const { limitedSimilarProperties, limitedSimilarPropertiesWithoutCmbs } = yield select(selectSimilarTransaction);
    const data = payload ? limitedSimilarPropertiesWithoutCmbs : limitedSimilarProperties;

    window.router.history.push(links.benchmarking.routes.benchmarking.path);
    let counter = 0;
    while (counter <= data.length) {
        yield call(getBenchmarkingSaga, data[counter]);
        counter += 1;
    }
}

function* getPropertySaga({ payload, type }) {
    const { countyId, parcelId, newValue } = payload;
    const { similarProperties, properties } = yield select(selectBenchmarkingState);
    let sourceParcelId;
    let sourceCountyId;
    if (similarProperties.length || properties.length) {
        const { countyid, parcelid } = similarProperties[0] || properties[0] || {};
        sourceParcelId = parcelid;
        sourceCountyId = countyid;
    }
    yield put(
        apiRequest(
            sourceParcelId && sourceCountyId
                ? { sourceParcelId, sourceCountyId, parcelId, countyId }
                : { parcelId, countyId },
            benchmarkingApi.getBenchmark,
            type
        )
    );
    const {
        payload: { data },
    } = yield take(`${type} ${API_SUCCESS}`);
    const resObj = { ...newValue, ...data };
    yield put(addProperty(resObj));
}

export function* watchBenchmarking() {
    yield takeLatest(openBenchmarking, openBenchmarkingSaga);
    yield takeLatest(getProperty, getPropertySaga);
}
