import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { baseChartConfig, xAxisDateFormatter } from '@utils/charts/configs/index';

import { FINANCIALS } from '@components/DataTable/constants';

const operatingExpenses = merge(cloneDeep(baseChartConfig()), {
    grid: {
        left: '40px',
        right: '260px',
        bottom: '150px',
    },
    legend: {
        type: 'scroll',
        orient: 'vertical',
        height: '310px',
        top: '40px',
        right: 10,
    },
    xAxis: {
        axisLabel: xAxisDateFormatter,
    },
    yAxis: {
        type: 'value',
        name: '$',
    },
    series: Object.keys(FINANCIALS).map((item) => ({
        name: FINANCIALS[item],
        type: 'bar',
        stack: 'one',
        data: [],
    })),
});

export default operatingExpenses;
