import { green } from '@themes/colors';

const svgIcon = () => ({
    MuiSvgIcon: {
        styleOverrides: {
            colorSuccess: {
                stroke: green[500],
            },
            colorNone: {
                color: 'transparent',
            },
            fontSizeSmall: {
                fontSize: 16,
            },
            fontSizeMedium: {
                fontSize: 21,
            },
            fontSizeLarge: {
                fontSize: 55,
            },
        },
    },
});
export default svgIcon;
