import { useState, createRef, useEffect } from 'react';

import { highlightsMap } from '@scc/pages/Property/staticData';

import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import HighlightItem from '../HighlightItem';

import styles from './highlights.module.scss';

function Highlights({ list }) {
    const [elRefs, setElRefs] = useState({});

    useEffect(() => {
        setElRefs((els) =>
            Object.keys(list).reduce((acc, curr) => {
                acc[curr] = els[curr] || createRef();
                return acc;
            }, {})
        );
    }, [list]);

    return (
        <Grid className={styles.highlightsContainer} container>
            {highlightsMap?.map(({ title, icon, name, map }) => {
                if (!list[name]?.length) return null;

                return (
                    <Grid key={name} className={styles.highlightsGridItem} md={6} xs={12} item>
                        <Typography
                            className={styles.heading}
                            dataId={map}
                            ref={elRefs[name]}
                            variant='h1b'
                            gutterBottom
                        >
                            <Icon component={icon} fontSize='23px' />
                            {title}
                        </Typography>
                        {list[name].map(({ messageInfo, marker, options, outOf }) => (
                            <HighlightItem
                                key={messageInfo}
                                chips={options}
                                outOf={outOf}
                                text={messageInfo}
                                type={marker}
                            />
                        ))}
                    </Grid>
                );
            })}
        </Grid>
    );
}

Highlights.defaultProps = {
    list: {},
};

export default Highlights;
