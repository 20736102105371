export const DOCUMENT_TYPES = {
    RR: 'RENTROLL',
    OS: 'STATEMENT',
    COMBINED: 'STATEMENT_COMB',
    DEVELOPMENT_BUDGET: 'DEVELOPMENT_BUDGET',
};

export const PROCESS_STATUSES = {
    processing: 'IN_PROCESS_AUTO',
    processed: 'PROCESSED',
    confirmed: 'CONFIRMED',
    error: 'ERROR',
};
