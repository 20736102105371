import Logo from '@scc/components/Logo';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import Image from '@components/Image';

import colors from '@themes/palette/export.module.scss';

import TimeoutImage from '@assets/icons/components/timeout.png';
import ArrowRightIcon from '@assets/icons/outlined/arrow-right-3.svg';

export function SsoTimeout() {
    const ssoLink = window.localStorage.getItem('ssoLink');

    return (
        <Box
            bgcolor={colors['grey-A100']}
            display='flex'
            flexDirection='column'
            gap={16}
            height={1}
            justifyContent='space-between'
            width={1}
        >
            <Box display='flex' px={16} py={12} width={1}>
                <Logo type='link' />
            </Box>
            <Box alignItems='center' display='flex' flexDirection='column' p={8}>
                <Box height={200} width={240}>
                    <Image alt='Timeout image' src={TimeoutImage} />
                </Box>
                <Typography fontWeight='bold' pt={16} variant='hsb14'>
                    Your session has expired
                </Typography>
                <Typography pt={2} variant='hr13'>
                    Please login. We kept all of your progress in place
                </Typography>
                <Box pt={16} width={310}>
                    <Button component='a' href={ssoLink} sx={{ height: 44 }} fullWidth>
                        <Typography pr={4} variant='hsb18'>
                            Continue
                        </Typography>
                        <Icon component={ArrowRightIcon} fontSize={24} />
                    </Button>
                </Box>
            </Box>
            <Box display='flex' justifyContent='center' px={54} py={8} width={1}>
                <Typography color={colors['grey-300']} variant='smr'>
                    © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
}
