import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { baseChartConfig } from './index';

const leaseExpirations = (tooltipSidePosition = 'default') => {
    return merge(cloneDeep(baseChartConfig({ tooltipSidePosition })), {});
};

export default leaseExpirations;
