import Logo from './Logo';

function LogoFactory({ type, ...restProps }) {
    if (type === 'link') {
        return <Logo.Link {...restProps} />;
    }

    return <Logo {...restProps} />;
}

export default LogoFactory;
