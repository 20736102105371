import colors from '@themes/palette/export.module.scss';

const borderColor = colors['grey-300'];

const input = () => ({
    MuiInput: {
        styleOverrides: {
            root: {
                width: 200,
                '&.Mui-disabled': {
                    '&:before': {
                        borderBottomStyle: 'solid',
                    },
                },
                ':after': {
                    borderBottom: `1px solid ${borderColor}`,
                },
                '&:before': {
                    borderBottom: `1px solid ${borderColor}`,
                },
                '&:hover': {
                    '&:before': {
                        borderBottom: `2px solid ${borderColor}`,
                    },
                    '&:after': {
                        borderBottom: `2px solid ${borderColor}`,
                    },
                },
            },
            input: {
                fontSize: '16px',
                lineHeight: '22px',
                color: colors.black,
            },
            multiline: {
                fontSize: '16px',
                lineHeight: '22px',
                padding: '9px 8px',
                color: colors.black,
            },
        },
    },
});

export default input;
