import { useRouteMatch } from 'react-router-dom';

import RouteList from '@components/RouteList';
import ServicesContent from './ServicesContent';

const ServicesPage = function ({ routes }) {
    const { isExact } = useRouteMatch();

    return (
        <>
            <RouteList routes={routes} />
            {isExact && <ServicesContent />}
        </>
    );
};

export default ServicesPage;
