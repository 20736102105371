/* eslint-disable no-use-before-define */
import download from 'downloadjs';

import { HTTP_STATUSES } from '@constants/httpStatuses';

/**
 * Module to work with API requests
 * @module utils/request
 */

let CSRF_TOKEN;
const X_CSRF_TOKEN_NAME = 'X-CSRF-TOKEN';

const parsers = {
    response: responseParser,
    json: parseJSON,
    default: parseEmpty,
    blob: parseBlob,
    arrayBuffer: parseArrayBuffer,
    text: parseText,
    // TODO: add support for arrayBuffer, blob
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param   {object} response   A response from a network request
 * @param options
 * @return  {object|undefined}  Returns either the response, or throws an error
 */
async function checkStatus(response, options = {}) {
    if (response.ok) {
        return response;
    }

    let errorMessage;
    let errorResponse;

    // try to get error message from response
    try {
        const res = await response.json();
        errorResponse = res;
        errorMessage = res.message;
    } catch (e) {
        errorMessage = response.statusText;
    }
    const error = new Error(errorMessage);
    error.response = response;
    error.redirect =
        !options.noRedirect &&
        (response.status === HTTP_STATUSES.UNAUTHORIZED ||
            (response.status === HTTP_STATUSES.NOT_ACCEPTABLE && errorResponse?.redirect));

    throw error;
}

export function getFullFilledParams(params) {
    const res = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in params) {
        if (params[key] !== undefined && params[key] !== null) {
            res[key] = params[key];
        }
    }
    return res;
}

async function createRequest(
    path = '',
    {
        params = {},
        headers = { 'Content-Type': 'application/json' },
        csrfClean = false,
        csrfSafe = false,
        ...options
    } = {}
) {
    const fullFilledParams = getFullFilledParams(params);

    const url = prepareRequestURL(path, fullFilledParams);
    let req = new Request(url, {
        headers,
        ...options,
    });
    if (!isSafeMethod(req.method) && !csrfSafe) {
        req = await protectRequestWithCSRF(req);
    }
    if (csrfClean) {
        CSRF_TOKEN = null;
    }
    return req;
}

/**
 * Transforms key-value pairs of query parameters into string
 *
 * @param   {object} paramsObj  Key-value pair of query parameters for the URL
 * @return  {string}
 */
function getParamsString(paramsObj) {
    const queries = new URLSearchParams(paramsObj);
    return queries.toString() !== '' ? `?${queries}` : '';
}

/**
 * Checks if request method doesn't require CSRF protection
 *
 * @param   {string}    method  Request method
 * @return  {boolean}
 */
function isSafeMethod(method) {
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
    if (response.status === HTTP_STATUSES.NO_CONTENT || response.status === HTTP_STATUSES.RESET_CONTENT) {
        return null;
    }

    return response.json();
}

async function parseBlob(response) {
    if (response.status === HTTP_STATUSES.NO_CONTENT || response.status === HTTP_STATUSES.RESET_CONTENT) {
        return { status: response.status, message: response.statusText };
    }
    const blob = await response.blob();
    return { response, file: blob };
}

async function parseArrayBuffer(response) {
    if (response.status === HTTP_STATUSES.NO_CONTENT || response.status === HTTP_STATUSES.RESET_CONTENT) {
        return { error: { status: response.status, message: response.statusText } };
    }
    const file = await response.arrayBuffer();
    return { response, file };
}

function parseText(response) {
    if (response.status === HTTP_STATUSES.NO_CONTENT || response.status === HTTP_STATUSES.RESET_CONTENT) {
        return null;
    }

    return response.text();
}

function responseParser(response) {
    if (response.status < 400) {
        return { response };
    }
    return null;
}

/**
 * Parses the request without response body
 *
 * @return {object}
 */
function parseEmpty() {
    return {};
}

/**
 * Parses the response returned by a network request
 *
 * @param  {object} response        A response from a network request
 * @param  {string} responseType    Defines a parser for the response
 *
 * @return {object}                 The parsed response
 */
function parseResponse(response, responseType = 'json') {
    const parser = parsers[responseType] || parsers.json;
    return parser(response);
}

/**
 * Prepares request URL adding query parameters if specified
 *
 * @param   {string} path     Request path
 * @param   {object} params   Key-value pairs of query parameters
 * @return  {string}          Request URL
 */
export function prepareRequestURL(path = '', params = {}) {
    const queries = getParamsString(params);
    return path + queries;
}

/**
 * Adds CSRF protection for the request if needed
 *
 * @param   {object}    req Prepared request before sending
 * @return  {object}            Modified request with CSRF protection
 */
async function protectRequestWithCSRF(req) {
    if (!(req instanceof Request)) {
        throw new Error('Argument must be an instance of Request');
    }
    if (!CSRF_TOKEN) {
        const optionsRequest = new Request(req.url, { method: 'OPTIONS' });
        const optionsResponse = await fetch(optionsRequest);
        CSRF_TOKEN = optionsResponse.headers.get(X_CSRF_TOKEN_NAME);
        if (!CSRF_TOKEN) {
            throw new Error('Invalid or missing CSRF token');
        }
    }
    req.headers.append(X_CSRF_TOKEN_NAME, CSRF_TOKEN);
    return req;
}

export function createNestedFormData({ formData = new FormData(), data, name }) {
    const formDataClone = new Blob([JSON.stringify(data)], {
        type: 'application/json',
    });
    formData.set(name, formDataClone);
    return formData;
}

/**
 * Requests a URL, returning a promise
 *
 * @param   {string} url       The URL we want to request
 * @param path
 * @param   {object} [options] The options we want to pass to "fetch"
 *
 * @return  {object}           The response data
 */
export default async function request(path, { responseType = 'json', noRedirect = false, ...options } = {}) {
    const initRequest = await createRequest(path, options);
    // eslint-disable-next-line no-return-await
    return await fetch(initRequest)
        .then((res) => checkStatus(res, { noRedirect }))
        .then((response) => parseResponse(response, responseType))
        .catch((e = {}) => {
            // TODO add better handling, show toast message, log to backend, whatever
            console.warn(e);
            return {
                error: true,
                message: e.message || '',
                status: e?.response?.status || '',
                redirect: e.redirect || false,
            };
        });
}

export const sequentialRequest = (items, req) =>
    items.reduce(
        (last, item) => last.then((result) => req(item).then((response) => [...result, response])),
        Promise.resolve([])
    );

export const getContentNameFromResponse = (response = {}) => {
    return (
        response.headers?.get('content-disposition')?.match(/filename=\\?['"]?([^\\'"]+)\\?/)?.[1] ||
        response?.url?.substring((response.url || '').lastIndexOf('/') + 1) ||
        ''
    );
};

export const downloadDocument = async (response, blob, fileNameTransformer = (name) => name) => {
    const fileName = fileNameTransformer(getContentNameFromResponse(response));
    download(blob, fileName);
};
