import Chip from '@ui/Chip';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import LambdaCircleSvg from '@assets/icons/filled/lambdaCircle.svg';
import MinusCircleSvg from '@assets/icons/filled/minus-cirlce.svg';
import PlusCircleSvg from '@assets/icons/filled/plusCircle.svg';
import Star2Svg from '@assets/icons/filled/star2.svg';

import styles from './highlightItem.module.scss';

const renderIcon = (type) => {
    switch (type) {
        case 'GOOD':
            return <Icon color={colors['green-500']} component={PlusCircleSvg} fontSize='9px' />;
        case 'AVERAGE':
            return <Icon color={colors['yellow-500']} component={LambdaCircleSvg} fontSize='9px' />;
        case 'BAD':
            return <Icon color={colors['red-500']} component={MinusCircleSvg} fontSize='9px' />;
        case 'STAR':
            return <Icon color={colors['yellow-500']} component={Star2Svg} fontSize='9px' />;
        default:
            return <Icon color={colors['green-500']} component={PlusCircleSvg} fontSize='9px' />;
    }
};

function HighlightItem({ type, text, chips, outOf }) {
    return (
        <Typography className={styles.item} variant='lgr'>
            <Typography className={styles.text}>
                <div className={styles.icon}>{renderIcon(type)}</div>
                <div>
                    {text}
                    {outOf && (
                        <Typography component='span' sx={{ marginLeft: 2 }}>
                            {`(out of ${outOf})`}
                        </Typography>
                    )}
                </div>
            </Typography>
            {chips && (
                <div className={styles.chipsContainer}>
                    <ItemList items={chips}>
                        {(chip) => <Chip key={chip} className={styles.chip} label={chip} />}
                    </ItemList>
                </div>
            )}
        </Typography>
    );
}

export default HighlightItem;
