import { baseApi as api } from '../configs/team';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllMembersByLoan: build.query<GetAllMembersByLoanApiResponse, GetAllMembersByLoanApiArg>({
            query: (queryArg) => ({ url: `/api/v2/team/${queryArg.loanDetailsId}/members` }),
        }),
        updateMemberAccessLevel: build.mutation<UpdateMemberAccessLevelApiResponse, UpdateMemberAccessLevelApiArg>({
            query: (queryArg) => ({
                url: `/api/v2/team/member/update-access-level`,
                method: 'PUT',
                body: queryArg.updateTeamMemberAccessLevel,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetAllMembersByLoanApiResponse = /** status 200 OK */ GenericJsonResponseListUserInfoDto;
export type GetAllMembersByLoanApiArg = {
    /** ID by particular LoanDetails */
    loanDetailsId: number;
};
export type UpdateMemberAccessLevelApiResponse = /** status 200 OK */ GenericJsonResponseVoid;
export type UpdateMemberAccessLevelApiArg = {
    updateTeamMemberAccessLevel: UpdateTeamMemberAccessLevel;
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    userId?: number;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type GenericJsonResponseListUserInfoDto = {
    status?: string;
    data?: UserInfoDto[];
    message?: string;
    code?: string;
};
export type GenericJsonResponseVoid = {
    status?: string;
    data?: object;
    message?: string;
    code?: string;
};
export type UpdateTeamMemberAccessLevel = {
    loanId: number;
    teamId: string;
    userId: number;
    accessLevel: AccessLevel;
};
export enum AccessLevel {
    View = 'VIEW',
    Edit = 'EDIT',
    Admin = 'ADMIN',
}
export const { useGetAllMembersByLoanQuery, useUpdateMemberAccessLevelMutation } = injectedRtkApi;
