import { useState } from 'react';

import { withConfirmPopover } from '@scc/hocs/withConfirmPopover';

import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuItem from '@ui/MenuItem';
import Typography from '@ui/Typography';

import { BlockedControl } from '@components/BlockedBox';

const MenuItemWithConfirmPopover = withConfirmPopover(MenuItem);

function useMenuList({ items = [], id = '', row, index, minWidth = 0 }) {
    const [anchor, setAnchor] = useState(null);

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchor(null);
    };

    const menu = (
        <Menu
            PaperProps={{
                elevation: 10,
                sx: {
                    minWidth,
                    px: 4,
                },
            }}
            anchorEl={anchor}
            open={!!anchor}
            onClick={handleClose}
            onClose={handleClose}
        >
            {items?.map(
                ({
                    component,
                    value,
                    isLimited,
                    label,
                    icon,
                    iconColor,
                    iconSize,
                    disabled,
                    onClick = () => {},
                    confirmText,
                } = {}) =>
                    component || (
                        <BlockedControl key={value} isLimited={isLimited}>
                            <MenuItemWithConfirmPopover
                                key={value}
                                confirmText={confirmText}
                                data-qa={`tableMenuItems-${label}`}
                                disabled={disabled || isLimited}
                                sx={{ gap: 5, minWidth: 100, borderRadius: 1 }}
                                onClick={(e) => onClick(e, id, row, value, index)}
                            >
                                {icon && <Icon color={iconColor} component={icon} fontSize={iconSize} />}
                                <Typography color='grey.natural' variant='menu'>
                                    {label}
                                </Typography>
                            </MenuItemWithConfirmPopover>
                        </BlockedControl>
                    )
            )}
        </Menu>
    );

    return [{ menu, anchor }, { setAnchor }];
}

export default useMenuList;
