import { useState, MouseEvent } from 'react';

export const useDropdown = <T = HTMLElement>() => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorRef, setAnchorRef] = useState<T | null>(null);

    const handleAnchorClick = (event: MouseEvent<T>) => {
        event.stopPropagation();
        setAnchorRef(event.currentTarget);
        setIsOpen(true);
    };

    const handleDropdownClose = () => {
        setIsOpen(false);
        setAnchorRef(null);
    };

    return [
        { isOpen, anchorRef },
        { handleAnchorClick, handleDropdownClose },
    ] as const;
};
