import { RootState } from "@scc/index";
import { TDebtServiceTableState } from "./types/debtService.types";
import { createSlice } from '@reduxjs/toolkit';

const entity = '[debtServiceTable]';

const initialState: TDebtServiceTableState = {
  year: new Date().getFullYear(),
  period: 'MONTHLY',
};

const debtServiceTableSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    setFilterPeriod(state, action) {
      state.period = action.payload;
    },
    setCurrentYear(state, action) {
      state.year = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setCurrentYear,
  setFilterPeriod,
  resetState,
} = debtServiceTableSlice.actions;

export default debtServiceTableSlice.reducer;

export const selectDebtServiceTableState = (store: RootState) => store.loans.debtServiceTable;