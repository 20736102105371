import { TSelectOptions } from '@scc/pages/Loan/components/Details/components/LoanDetails/components/DetailsTabs/components/tabs/Terms/Terms.types';
import { TermsKeys } from '@scc/store/ducks/loans/types';

export const AmortizingOption = {
    IO: 1,
    AMORTIZING: 2,
} as const;

export const AmortizationOption = {
    AMORTIZATION_SCHEDULE: 1,
    FIXED_MONTHLY_PAYMENT: 2,
    CASH_SWEPT: 3,
} as const;

const LoanRating = {
    LOW: 1,
    MODERATE: 2,
    ACCEPTABLE: 3,
    HIGH: 4,
    LOSS: 5,
} as const;

const LoanPurpose = {
    ACQUISITION_LOAN: 1,
    REFINANCING: 2,
    REDEVELOPMENT: 3,
    DEVELOPMENT: 4,
    CONSTRUCTION: 5,
} as const;

const CashSweep = {
    YES: true,
    NO: false,
} as const;

export const CouponType = {
    FIXED: 1,
    FLOATING: 2,
} as const;

export const Status = {
    SCC_UNDERWRITING: 1,
    EVALUATION: 2,
    QUOTE_ISSUED: 3,
    UNDERWRITING: 4,
    APPRAISAL_N_TITLE_WORK: 5,
    CLOSING: 6,
    FUNDED: 7,
    PAID_IF: 8,
    PASSED: 9,
    TRANSFERRED_OUT: 10,
    DUE_DILIGENCE: 11,
    APPLICATION_ISSUED: 12,
    TERM_SHEET_ISSUED: 13,
    SECURITIZED: 14,
    DEAD: 15,
} as const;

export const LenderType = {
    BANK: 1,
    LIFE_COMPANY: 2,
    CMBS: 3,
    DEBT_FUND: 4,
    AGENCY: 5,
    OTHER: 6,
};

export const AMORTIZING_OPTIONS: TSelectOptions[] = [
    {
        label: 'IO',
        value: AmortizingOption.IO,
    },
    {
        label: 'Amortizing',
        value: AmortizingOption.AMORTIZING,
    },
];

export const AMORTIZATION_OPTIONS: TSelectOptions[] = [
    {
        label: 'Amortization Period',
        value: AmortizationOption.AMORTIZATION_SCHEDULE,
    },
    {
        label: 'Fixed Monthly Payment',
        value: AmortizationOption.FIXED_MONTHLY_PAYMENT,
    },
    {
        label: 'Cash Sweep',
        value: AmortizationOption.CASH_SWEPT,
    },
];

export const LOAN_RATING_OPTIONS: TSelectOptions[] = [
    {
        label: 'Low Risk',
        value: LoanRating.LOW,
    },
    {
        label: 'Moderate Risk',
        value: LoanRating.MODERATE,
    },
    {
        label: 'Acceptable Risk',
        value: LoanRating.ACCEPTABLE,
    },
    {
        label: 'High Risk',
        value: LoanRating.HIGH,
    },
    {
        label: 'Loss Likely',
        value: LoanRating.LOSS,
    },
];

export const LOAN_PROGRAM_OPTIONS: TSelectOptions[] = [
    {
        label: 'Acquisition',
        value: LoanPurpose.ACQUISITION_LOAN,
    },
    {
        label: 'Refinancing',
        value: LoanPurpose.REFINANCING,
    },
    {
        label: 'Development',
        value: LoanPurpose.CONSTRUCTION,
    },
    {
        label: 'Redevelopment',
        value: LoanPurpose.REDEVELOPMENT,
    },
];

export const LENDER_TYPE_OPTIONS: TSelectOptions[] = [
    {
        label: 'CMBS',
        value: LenderType.CMBS,
    },
    {
        label: 'Agency',
        value: LenderType.AGENCY,
    },
    {
        label: 'Bank',
        value: LenderType.BANK,
    },
    {
        label: 'Life Company',
        value: LenderType.LIFE_COMPANY,
    },
    {
        label: 'Debt Fund',
        value: LenderType.DEBT_FUND,
    },
    {
        label: 'Other',
        value: LenderType.OTHER,
    },
];

export const CASH_SWEEP_OPTIONS: TSelectOptions[] = [
    {
        label: 'Yes',
        value: CashSweep.YES,
    },
    {
        label: 'No',
        value: CashSweep.NO,
    },
];

export const COUPON_TYPE_OPTIONS: TSelectOptions[] = [
    {
        label: 'Fixed',
        value: CouponType.FIXED,
    },
    {
        label: 'Floating',
        value: CouponType.FLOATING,
    },
];

export const STATUS_OPTIONS: TSelectOptions[] = [
    {
        label: 'Evaluation',
        value: Status.EVALUATION,
    },
    {
        label: 'Quote Issued',
        value: Status.QUOTE_ISSUED,
    },
    {
        label: 'Term Sheet Issued',
        value: Status.TERM_SHEET_ISSUED,
    },
    {
        label: 'Application Issued',
        value: Status.APPLICATION_ISSUED,
    },
    {
        label: 'Due Diligence',
        value: Status.DUE_DILIGENCE,
    },
    {
        label: 'Appraisal & Title Work',
        value: Status.APPRAISAL_N_TITLE_WORK,
    },
    {
        label: 'Underwriting',
        value: Status.UNDERWRITING,
    },
    {
        label: 'SCC Underwriting',
        value: Status.SCC_UNDERWRITING,
    },
    {
        label: 'Closing',
        value: Status.CLOSING,
    },
    {
        label: 'Funded',
        value: Status.FUNDED,
    },
    {
        label: 'Passed',
        value: Status.PASSED,
    },
    {
        label: 'Dead',
        value: Status.DEAD,
    },
    {
        label: 'Transferred Out',
        value: Status.TRANSFERRED_OUT,
    },
    {
        label: 'Securitized',
        value: Status.SECURITIZED,
    },
    {
        label: 'Paid Off',
        value: Status.PAID_IF,
    },
];

export const TERMS_BASE_PATH = 'terms';

export const fieldToRecalculateCoupon = {
    [TermsKeys.SPREAD]: true,
    [TermsKeys.TERM_SOFT_ADJUSTMENT]: true,
    [TermsKeys.INDEX_FLOOR]: true,
    [TermsKeys.INDEX]: true,
    [TermsKeys.COUPON_TYPE]: true,
};

export const filedToRecalculateIndexValue = {
    [TermsKeys.INDEX]: true,
};

export const fieldToRecalculateCoupon365 = {
    [TermsKeys.COUPON]: true,
};

export const filedToRecalculate = {
    ...fieldToRecalculateCoupon,
    ...filedToRecalculateIndexValue,
    ...fieldToRecalculateCoupon365,
};

export const valuesNeedUpdateLoanDetails = {
    [TermsKeys.LOAN_ID]: true,
    [TermsKeys.LOAN_NAME]: true,
};

export const LENDER_URL = 'members';

export const RATE_TYPES = {
    prime: 'PRIME',
    sofr: 'SOFR',
    monthTermSofr: 'MONTH_TERM_SOFR',
    default: 'TREASURY',
};
