import BulidingSvg from '@assets/icons/filled/buliding.svg';
import Profile2UserSvg from '@assets/icons/filled/profile-2user.svg';
import CarSvg from '@assets/icons/filled/car.svg';
import HomeTrendUpSvg from '@assets/icons/filled/home-trend-up.svg';
import TransactionsSvg from '@assets/icons/outlined/transactions.svg';
import AdditionalSvg from '@assets/icons/outlined/additional.svg';
import OverviewImg from "@scc/assets/images/maps/overview.webp";
import DemographicsImg from "@scc/assets/images/maps/demographics.webp";
import FoottrafficImg from "@scc/assets/images/maps/foottraffic.webp";
import BusinessImg from "@scc/assets/images/maps/business.webp";
import RetailImg from "@scc/assets/images/maps/retail.webp";
import SalescompsImg from "@scc/assets/images/maps/salescomps.webp";
import TransitImg from "@scc/assets/images/maps/transit.webp";

export const mapTabs = [
    {
        label: 'Overview',
        value: 'Overview',
    },
    {
        label: 'Sales Comps',
        value: 'Sales',
    },
    {
        label: 'Retail',
        value: 'Retail',
    },
    {
        label: 'Foot Traffic',
        value: 'FootTraffic',
    },
    {
        label: 'Demographics',
        value: 'Demographics',
    },
    {
        label: 'Business',
        value: 'Business',
    },
    {
        label: 'Transit',
        value: 'Transit',
    }
];

export const highlightsMap = [
    {
        name: 'REAL_ESTATE_MARKET',
        title: 'Real Estate Market',
        color: '',
        icon: BulidingSvg,
        map: mapTabs[1].value,
    },
    {
        name: 'DEMOGRAPHICS',
        title: 'Demographics',
        color: '',
        icon: Profile2UserSvg,
        map: mapTabs[3].value,
    },
    {
        name: 'BUSINESS',
        title: 'Business',
        color: '',
        icon: HomeTrendUpSvg,
        map: mapTabs[4].value,
    },
    {
        name: 'TRANSIT',
        title: 'Transit',
        color: '',
        icon: CarSvg,
        map: mapTabs[5].value,
    },
    {
        name: 'AMENITIES',
        title: 'Popularity and Amenities',
        color: '',
        icon: TransactionsSvg,
        map: mapTabs[2].value,
    },
    {
        name: 'OTHER',
        title: 'Other',
        color: '',
        icon: AdditionalSvg,
    },
];

export const mapPlaceholders = {
    [mapTabs[0].value]: OverviewImg,
    [mapTabs[1].value]: SalescompsImg,
    [mapTabs[2].value]: RetailImg,
    [mapTabs[3].value]: FoottrafficImg,
    [mapTabs[4].value]: DemographicsImg,
    [mapTabs[5].value]: BusinessImg,
    [mapTabs[6].value]: TransitImg,
}

export const tooltip = {
    valueInfo: {
        description: 'Indicative value based on ',
        tooltip: 'Indicative value based on Sales Transactions',
        linkText:'Sales Transactions',
        map: mapTabs[1].value,
    },
    locationInfo: {
        description: 'Overall Location Score',
        tooltip: 'Overall Location Score'
    },
    riskInfo: {
        description: 'Decline Risk Score',
        tooltip: 'How likely is the property value to decline during a period of market instability (100 – unlikely to decline, 0 – likely to decline substantially)'
    },
    growthInfo: {
        description: 'Growth Potential Score',
        tooltip: 'How likely is the property value to increase after a period of market instability (100 – likely to rebound quickly, 0 – unlikely to rebound quickly)'
    },
};
