import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';

import { getStepTitles } from '@scc/pages/Auth/helpers';
import ContentDialogTemplate from '@scc/templates/ContentDialog';

import MobileHeader from './components/MobileHeader';
import styles from './dialog.module.scss';

const defaultProps = {
    state: {},
    children: null,
};

const getHeaderForMobile =
    (hideButtonClose, withMobileLayout, title) =>
    ({ ...props }) =>
        (
            <MobileHeader
                hideButtonClose={hideButtonClose}
                title={title}
                {...props}
                className={cx({ [styles.mobileHeader]: withMobileLayout })}
            />
        );

export function Dialog({ state, label, template, className, open, onClose, children, withMobileLayout }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { hideButtonClose } = state;

    if (isMobile) {
        const { partialModal } = getStepTitles(template?.template);
        const title = template?.template ? partialModal.title : label;

        const Header = getHeaderForMobile(hideButtonClose, withMobileLayout, title);

        return (
            <ContentDialogTemplate
                className={cx({ [styles.mobileDialog]: withMobileLayout })}
                header={Header}
                label={label}
            >
                <div className={cx(styles.mobileContent, { [styles.mobileOverride]: withMobileLayout })}>
                    {children}
                </div>
            </ContentDialogTemplate>
        );
    }
    return (
        <ContentDialogTemplate
            className={cx(styles.desktopContent, className)}
            open={open}
            state={state}
            onClose={onClose}
        >
            {children}
        </ContentDialogTemplate>
    );
}

Dialog.defaultProps = defaultProps;

export default Dialog;
