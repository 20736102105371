export const LENDERS = 'lenders';

export const INVESTORS = 'investors';

export const SERVICERS = 'servicers';

export const solutions = {
    title: 'Solutions',
    links: [
        {
            name: 'lenders',
            label: 'For Lenders',
            value: LENDERS,
            toScroll: true,
        },
        {
            name: 'investors',
            label: 'For Investors',
            value: INVESTORS,
            toScroll: true,
        },
        {
            name: 'servicers',
            label: 'For Servicers',
            value: SERVICERS,
            toScroll: true,
        },
    ],
};
