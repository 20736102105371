import Card from '@mui/material/Card';
import { SnackbarContent, closeSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Icon from '@ui/Icon';

import colors from '@themes/palette/export.module.scss';

import CloseIconSvg from '@icons/outlined/close.svg';

import styles from './Snackbar.module.scss';

export type TSnackbar = {
    message?: string | React.ReactNode;
    icon?: React.ElementType;
    color?: string;
    withCloseButton?: boolean;
    id: string;
};

export const Snackbar = forwardRef<HTMLDivElement, TSnackbar>((props, ref) => {
    const { id, icon, message, color, withCloseButton } = props;

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id]);

    return (
        <SnackbarContent ref={ref}>
            <Card className={styles.card}>
                <Box className={styles.icon} style={{ background: color ?? colors['secondary-main'] }}>
                    {!!icon && <Icon color='#fff' component={icon} fontSize={24} />}
                </Box>
                <Box className={styles.content}>{message}</Box>
                {withCloseButton && (
                    <Box className={styles.actions}>
                        <Button variant='icon-text' onClick={handleDismiss}>
                            <Icon component={CloseIconSvg} />
                        </Button>
                    </Box>
                )}
            </Card>
        </SnackbarContent>
    );
});
