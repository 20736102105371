import { combineReducers } from 'redux';

import commonReducer, { watchGrid } from './common';
import editMappingReducer from './editMapping';
import iframeReducer from './iframe';

export const gridWatchers = [watchGrid];

export default combineReducers({
    common: commonReducer,
    editMapping: editMappingReducer,
    iframe: iframeReducer,
});
