import request from '@utils/request';

const BASE_URL = '/api/v2/maps';

export async function getSimilarProperties(data = {}) {
    return request(`${BASE_URL}/similar`, {
        params: data,
    });
}

export async function getGeocodingStreetViewData(address) {
    return request(`${BASE_URL}/abc/streetView`, {
        responseType: 'text',
        headers: {
            'Content-Type': 'text/plain',
        },
        params: { address },
    });
}

export async function saveGeocodingStreetViewData({ url, address } = {}) {
    return request(`${BASE_URL}/abc/streetView`, {
        responseType: 'default',
        method: 'POST',
        body: JSON.stringify({ address, url }),
    });
};

export async function getUsersProperties(data = {}) {
    return request(`${BASE_URL}/uploads`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}
