import PropTypes from 'prop-types';

import { propertyTypes, mapPropertyTypes, getPropertyTypeProps } from '@utils/properties';

import Icon from '@ui/Icon';

import styles from './PropertyTypeIcon.module.scss';

const sizes = {
    'extra-small': {
        wrapperSize: 14,
        iconSize: 8,
    },
    small: {
        wrapperSize: 16,
        iconSize: 10,
    },
    medium: {
        wrapperSize: 24,
        iconSize: 16,
    },
    large: {
        wrapperSize: 32,
        iconSize: 24,
    },
    'extra-large': {
        wrapperSize: 48,
        iconSize: 28,
    },
};

const relativeSizes = {
    'extra-small': {
        wrapperSize: 1.28, // 14
        iconSize: 0.75, // 8
    },
    small: {
        wrapperSize: 1.47, // 16
        iconSize: 0.94, // 10
    },
    medium: {
        wrapperSize: 2.2, // 24px
        iconSize: 1.5, // 16px
    },
    large: {
        wrapperSize: 2.67, // 32px
        iconSize: 2.25, // 24px
    },
    'extra-large': {
        wrapperSize: 4.4, // 48
        iconSize: 2.63, // 28
    },
};

const defaultProps = {
    size: 'medium',
    type: 'No Type',
    color: null,
    background: null,
    invert: false,
};

function PropertyIcon({ type = propertyTypes.NO_TYPE, size, color, invert, relativeSize }) {
    const iconProps = getPropertyTypeProps(type);
    const iconColor = color || (invert ? iconProps.wrapperColor : iconProps.iconColor);
    const iconSize = relativeSize ? `${relativeSizes[size].iconSize}cqmin` : `${sizes[size].iconSize}px`;

    return <Icon color={iconColor} component={iconProps.icon} fontSize={iconSize} />;
}

function PropertyTypeIcon({
    size,
    type,
    color,
    background,
    invert,
    disabled,
    iconComponent: IconComponent,
    relativeSize = false,
}) {
    const iconProps = getPropertyTypeProps(type);
    const typeSize = relativeSize ? `${relativeSizes[size].wrapperSize}cqmin` : `${sizes[size].wrapperSize}px`;
    const backgroundColor = background || (invert ? iconProps.iconColor : iconProps.wrapperColor);
    return (
        <span
            className={styles.wrapper}
            style={{
                width: typeSize,
                height: typeSize,
                backgroundColor,
                opacity: disabled ? 0.5 : 1,
            }}
        >
            {IconComponent || (
                <PropertyIcon color={color} invert={invert} relativeSize={relativeSize} size={size} type={type} />
            )}
        </span>
    );
}

PropertyTypeIcon.propTypes = {
    size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large', 'extra-large']),
    color: PropTypes.string,
    background: PropTypes.string,
    invert: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(mapPropertyTypes).map((i) => i)),
};

PropertyTypeIcon.defaultProps = defaultProps;

export default PropertyTypeIcon;
