import * as yup from 'yup';
import { validation } from '@shared/utils/forms';

const schema = yup
    .object({
        email: validation.email,
    })
    .required();

export default schema;
