import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery, createConfig } from '@scc/services/helpers';

const ENTITY = 'grid';

export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});

export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: [
        'preprocessProforma',
        'preprocessOriginalStatement',
        'saveProforma',
        'checkProformaData',
        'preprocessStatement',
        'preprocessCombinedStatement',
        'regenerateOriginalStatement',
        'saveCombined',
        'saveStatement',
        'createVarianceReport',
        'addCombinedYearColumn',
        'saveOriginalStatement',
        'preprocessLoanProforma',
        'saveLoanProforma',
        'preprocessCurentDevBudget',
        'normalizeNoi',
        'downloadAdjustments',
    ],
});
