import { all, call, spawn } from 'redux-saga/effects';

export default function createRootSaga(sagas) {
    return function* rootSaga() {
        yield all(
            sagas.map((saga) =>
                spawn(function* rootWatcher() {
                    while (true) {
                        try {
                            yield call(saga);
                            break;
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                })
            )
        );
    };
}
