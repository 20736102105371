import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';

import DropZone from '@scc/modules/DropZone';
import MobileUpload from '@scc/modules/DropZone/components/MobileUpload';
import { selectRRFiles, selectOSFiles, selectRentRollAllState } from '@scc/store/ducks/rentRoll';

import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

const fullYear = new Date().getFullYear();

const initialRRFiles = [{ initial: true, id: 'RR', name: 'Latest rent roll' }];

const initialOSFiles = [
    { initial: true, id: 'yOS', name: `${fullYear - 3}-${fullYear - 1} operating statements` },
    { initial: true, id: 'mOS', name: `${fullYear} monthly or quarterly operating statements` },
];

function UploadDocumentStep({ controls }) {
    const { data, loading } = useSelector(selectRentRollAllState);
    const RRFiles = useSelector(selectRRFiles) || [];
    const OSFiles = useSelector(selectOSFiles) || [];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uploadParams = {
        countyid: data.countyid,
        parcelid: data.parcelid,
        skipParse: true,
    };
    return (
        <>
            {!isMobile ? (
                <Stack direction='row' spacing={10} sx={{ flexGrow: 0.95, flexWrap: 'wrap' }}>
                    <DropZone docs={RRFiles} initialFiles={initialRRFiles} params={uploadParams} process={loading} />
                    <DropZone
                        docs={OSFiles}
                        initialFiles={initialOSFiles}
                        params={uploadParams}
                        process={loading}
                        type='os'
                    />
                    <Typography sx={{ textAlign: 'center', flexBasis: '100%', alignSelf: 'flex-end' }} variant='pr12'>
                        Why are we asking for these
                    </Typography>
                </Stack>
            ) : (
                <MobileUpload
                    docs={[...OSFiles, ...RRFiles]}
                    initialFiles={[...initialOSFiles, ...initialRRFiles]}
                    params={uploadParams}
                    process={loading}
                    type='os'
                />
            )}

            {controls}
        </>
    );
}

export default UploadDocumentStep;
