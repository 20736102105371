import colors from '@themes/palette/export.module.scss';

import { EXTENDED_REQUEST_STATUSES, REQUEST_STATUSES } from '@constants/requestTypes';

export const LOAN = 'Loan';

export const MEMBER_LOAN = 'memberLoan';

export const LOANS_VIEW_TYPE = {
    ALL: 'ALL',
    FINANCING: 'FINANCING',
};

export const CONSTRUCTION = 'CONSTRUCTION';

export const DEVELOPMENT = 'DEVELOPMENT';

export const REDEVELOPMENT = 'REDEVELOPMENT';

export const ACQUISITION = 'ACQUISITION';

export const REFINANCE = 'REFINANCE';

export const LOAN_PROGRAMMS_MAP = {
    [DEVELOPMENT]: DEVELOPMENT,
    [REDEVELOPMENT]: REDEVELOPMENT,
    [ACQUISITION]: ACQUISITION,
    [REFINANCE]: REFINANCE,
};

export const REFER_LOAN_STATUSES = [
    REQUEST_STATUSES.COMPLETED.value,
    EXTENDED_REQUEST_STATUSES.REQUEST_APPROVED.value,
    REQUEST_STATUSES.IN_PROGRESS_BY_SC.value,
    REQUEST_STATUSES.REQUEST_INFO.value,
];

export const LOAN_PROGRAMMS = [
    {
        label: 'Acquisition',
        status: 'acquisition',
        value: ACQUISITION,
    },
    {
        label: 'Refinance',
        status: 'refinance',
        value: REFINANCE,
    },
    {
        label: 'Redevelopment',
        status: 'redevelopment',
        value: REDEVELOPMENT,
    },
    {
        label: 'Development',
        status: 'construction',
        value: CONSTRUCTION,
    },
];

export const LOAN_AMORTIZATIONS = [
    {
        label: '25 years',
        value: '25 years',
    },
    {
        label: '30 years',
        value: '30 years',
    },
    {
        label: 'No amortization - Interest only',
        value: 'No amortization - Interest only',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];

export const FINANCING_AMORTIZATIONS = [
    {
        label: 'Prefer interest only',
        value: 'Prefer interest only',
    },
    {
        label: 'Amortization',
        value: 'Amortization',
    },
];

export const TERMS = [
    {
        label: '1-2 years',
        value: '1-2 years',
    },
    {
        label: '3-5 years',
        value: '3-5 years',
    },
    {
        label: '6-15 years',
        value: '6-15 years',
    },
    {
        label: '16-30 years',
        value: '16-30 years',
    },
];

export const RECOURSE = [
    {
        label: 'Recourse',
        value: 'Recourse',
    },
    {
        label: 'Non-recourse',
        value: 'Non-recourse',
    },
];

export const INTEREST_RATE_VALUES = {
    Fixed: 'Fixed',
    Floating: 'Floating',
};

export const INTEREST_RATE = [
    {
        label: INTEREST_RATE_VALUES.Fixed,
        value: INTEREST_RATE_VALUES.Fixed,
    },
    {
        label: INTEREST_RATE_VALUES.Floating,
        value: INTEREST_RATE_VALUES.Floating,
    },
];

export const LEGACY_LOAN_STATUSES_VALUES = {
    SCC_UNDERWRITING: 'SCC_UNDERWRITING',
    EVALUATION: 'EVALUATION',
    QUOTE_ISSUED: 'QUOTE_ISSUED',
    UNDERWRITING: 'UNDERWRITING',
    APPRAISAL_N_TITLE_WORK: 'APPRAISAL_N_TITLE_WORK',
    IN_CLOSING: 'IN_CLOSING',
    FUNDED: 'FUNDED',
    PAID_OFF: 'PAID_OFF',
    TRANSFERRED_OUT: 'TRANSFERRED_OUT',
    PASSED: 'PASSED',
    DUE_DILIGENCE: 'DUE_DILIGENCE',
    APPLICATION_ISSUED: 'APPLICATION_ISSUED',
    TERM_SHEET_ISSUED: 'TERM_SHEET_ISSUED',
    DEAD: 'DEAD',
    SECURITIZED: 'SECURITIZED',
} as const;

export const LOAN_STATUSES_BORROWER = [
    {
        color: colors['blue-200'],
        label: 'Smart Capital Underwriting',
        status: 'scc_underwriting',
        value: LEGACY_LOAN_STATUSES_VALUES.SCC_UNDERWRITING,
    },
    {
        color: colors['yellow-500'],
        label: 'Quotes in Progress',
        status: 'evaluation',
        value: LEGACY_LOAN_STATUSES_VALUES.EVALUATION,
    },
    {
        color: colors['yellow-500'],
        label: 'Quote Selection by Borrower',
        status: 'quote_issued',
        value: LEGACY_LOAN_STATUSES_VALUES.QUOTE_ISSUED,
    },
    {
        color: colors['blue-200'],
        label: 'Underwriting by Lender',
        status: 'underwriting',
        value: LEGACY_LOAN_STATUSES_VALUES.UNDERWRITING,
    },
    {
        color: colors['yellow-500'],
        label: 'Appraisal & Title Work',
        status: 'appraisal_n_title_work',
        value: LEGACY_LOAN_STATUSES_VALUES.APPRAISAL_N_TITLE_WORK,
    },
    {
        color: colors['green-500'],
        label: 'Closing',
        status: 'in_closing',
        value: LEGACY_LOAN_STATUSES_VALUES.IN_CLOSING,
    },
    {
        color: colors['green-500'],
        label: 'Funded',
        status: 'funded',
        value: LEGACY_LOAN_STATUSES_VALUES.FUNDED,
    },
    {
        color: colors['green-500'],
        label: 'Paid Off',
        status: 'PAID_OFF',
        value: LEGACY_LOAN_STATUSES_VALUES.PAID_OFF,
    },
    {
        color: colors['grey-500'],
        label: 'Transferred Out',
        status: 'transferred_out',
        value: LEGACY_LOAN_STATUSES_VALUES.TRANSFERRED_OUT,
    },
    {
        color: colors['red-500'],
        label: 'Passed',
        status: 'rejected',
        value: LEGACY_LOAN_STATUSES_VALUES.PASSED,
    },
    {
        label: 'Due Diligence',
        status: 'due_diligence',
        value: LEGACY_LOAN_STATUSES_VALUES.DUE_DILIGENCE,
        color: colors['yellow-500'],
    },
    {
        label: 'Application Issued',
        status: 'application_issued',
        value: LEGACY_LOAN_STATUSES_VALUES.APPLICATION_ISSUED,
        color: colors['red-300'],
    },
    {
        label: 'Term Sheet Issued',
        status: 'term_sheet_issued',
        value: LEGACY_LOAN_STATUSES_VALUES.TERM_SHEET_ISSUED,
        color: colors['grey-300'],
    },
    {
        label: 'Dead',
        status: 'dead',
        value: LEGACY_LOAN_STATUSES_VALUES.DEAD,
        color: colors['grey-600'],
    },
    {
        color: colors['grey-500'],
        label: 'Securitized',
        status: 'securitized',
        value: LEGACY_LOAN_STATUSES_VALUES.SECURITIZED,
    },
];

type TLoanStatus = {
    color: string;
    label: string;
    /**
     * @deprecated use value instead
     */
    status: string;
    value: string;
};

export const LOAN_STATUSES_LENDER: TLoanStatus[] = [
    {
        color: colors['green-500'],
        label: 'Evaluation',
        status: 'evaluation',
        value: LEGACY_LOAN_STATUSES_VALUES.EVALUATION,
    },
    {
        color: colors['yellow-200'],
        label: 'Quote Issued',
        status: 'quote_issued',
        value: LEGACY_LOAN_STATUSES_VALUES.QUOTE_ISSUED,
    },
    {
        label: 'Term Sheet Issued',
        status: 'term_sheet_issued',
        value: LEGACY_LOAN_STATUSES_VALUES.TERM_SHEET_ISSUED,
        color: colors['grey-300'],
    },
    {
        label: 'Application Issued',
        status: 'application_issued',
        value: LEGACY_LOAN_STATUSES_VALUES.APPLICATION_ISSUED,
        color: colors['red-300'],
    },
    {
        label: 'Due Diligence',
        status: 'due_diligence',
        value: LEGACY_LOAN_STATUSES_VALUES.DUE_DILIGENCE,
        color: colors['yellow-500'],
    },
    {
        color: colors['yellow-500'],
        label: 'Appraisal & Title Work',
        status: 'appraisal_n_title_work',
        value: LEGACY_LOAN_STATUSES_VALUES.APPRAISAL_N_TITLE_WORK,
    },
    {
        color: colors['blue-200'],
        label: 'Underwriting',
        status: 'underwriting',
        value: LEGACY_LOAN_STATUSES_VALUES.UNDERWRITING,
    },
    {
        color: colors['blue-200'],
        label: 'SCC Underwriting',
        status: 'scc_underwriting',
        value: LEGACY_LOAN_STATUSES_VALUES.SCC_UNDERWRITING,
    },
    {
        color: colors['green-500'],
        label: 'Closing',
        status: 'in_closing',
        value: LEGACY_LOAN_STATUSES_VALUES.IN_CLOSING,
    },
    {
        color: colors['blue-500'],
        label: 'Funded',
        status: 'funded',
        value: LEGACY_LOAN_STATUSES_VALUES.FUNDED,
    },
    {
        color: colors['red-300'],
        label: 'Passed',
        status: 'passed',
        value: LEGACY_LOAN_STATUSES_VALUES.PASSED,
    },
    {
        label: 'Dead',
        status: 'dead',
        value: LEGACY_LOAN_STATUSES_VALUES.DEAD,
        color: colors['grey-600'],
    },
    {
        color: colors['grey-100'],
        label: 'Transferred Out',
        status: 'transferred_out',
        value: LEGACY_LOAN_STATUSES_VALUES.TRANSFERRED_OUT,
    },
    {
        color: colors['lightGreen-900'],
        label: 'Paid Off',
        status: 'PAID_OFF',
        value: LEGACY_LOAN_STATUSES_VALUES.PAID_OFF,
    },
    {
        color: colors['grey-500'],
        label: 'Securitized',
        status: 'securitized',
        value: LEGACY_LOAN_STATUSES_VALUES.SECURITIZED,
    },
];

export const LOAN_REQUEST_STATUSES = {
    [LEGACY_LOAN_STATUSES_VALUES.EVALUATION]: true,
    [LEGACY_LOAN_STATUSES_VALUES.QUOTE_ISSUED]: true,
    [LEGACY_LOAN_STATUSES_VALUES.TERM_SHEET_ISSUED]: true,
    [LEGACY_LOAN_STATUSES_VALUES.APPLICATION_ISSUED]: true,
    [LEGACY_LOAN_STATUSES_VALUES.DUE_DILIGENCE]: true,
    [LEGACY_LOAN_STATUSES_VALUES.UNDERWRITING]: true,
    [LEGACY_LOAN_STATUSES_VALUES.APPRAISAL_N_TITLE_WORK]: true,
    [LEGACY_LOAN_STATUSES_VALUES.SCC_UNDERWRITING]: true,
    [LEGACY_LOAN_STATUSES_VALUES.IN_CLOSING]: true,
    [LEGACY_LOAN_STATUSES_VALUES.DEAD]: true,
};

export const LOAN_CASH_TYPES = [
    {
        label: 'Yes',
        value: 'YES',
    },
    {
        label: 'No',
        value: 'NO',
    },
];

export const LOAN_RATING_TYPES = [
    {
        label: 'Low Risk',
        value: 'Low Risk',
    },
    {
        label: 'Moderate Risk',
        value: 'Moderate Risk',
    },
    {
        label: 'Acceptable Risk',
        value: 'Acceptable Risk',
    },
    {
        label: 'High Risk',
        value: 'High Risk',
    },
    {
        label: 'Loss Likely',
        value: 'Loss Likely',
    },
];

export const IO_AMORTIZATION_VALUES = {
    IO: 'IO',
    AMORTIZATION: 'Amortization',
    NO_PREFERENCE: 'No Preference',
};

export const IO_AMORTIZATION = [
    {
        label: 'IO',
        value: IO_AMORTIZATION_VALUES.IO,
    },
    {
        label: 'Amortization',
        value: IO_AMORTIZATION_VALUES.AMORTIZATION,
    },
    {
        label: 'No Preference',
        value: IO_AMORTIZATION_VALUES.NO_PREFERENCE,
    },
];

export const LOAN_TYPES = {
    LOAN: 'LOAN',
    LOAN_REQUEST: 'LOAN_REQUEST',
} as const;
