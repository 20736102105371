import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useRef } from 'react';

import { reloadable } from '@utils/components';

import Footer from '@scc/components/Footer';
import Hero from '@scc/pages/Enterprise/components/Hero';
import Second from '@scc/pages/Enterprise/components/SecondBlock';

import { LENDERS, solutions } from './helpers';

const Features = reloadable(() => import('@scc/pages/Enterprise/components/Features'), { ssr: false });

function Enterprise(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { dialogProps, name } = props;

    const [activeTab, setActiveTab] = useState(LENDERS);
    const featuresRef = useRef(null);

    return (
        <>
            <Hero containerRef={dialogProps?.dialogContentRef} mobile={isMobile} />
            <Second mobile={isMobile} />
            <Features activeTab={activeTab} mobile={isMobile} scrollRef={featuresRef} onClick={setActiveTab} />
            <Footer
                links={solutions}
                location={name}
                mobile={isMobile}
                scrollRef={featuresRef}
                onClick={setActiveTab}
            />
        </>
    );
}

export default Enterprise;
