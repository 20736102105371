import { createSlice, createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { put, take, takeLatest, select } from 'redux-saga/effects';

import { createEmptyData } from '@utils/';

import { propertiesApi, ordersApi } from '@api';
import { apiRequest, API_SUCCESS } from '@store/ducks/api';

import { inProgressOrder } from './order';

const entity = '[my properties]';

const requiredFiles = [
    { id: 'LATEST_RRS', name: 'Latest Rent Roll', initial: true, type: 'rr' },
    { id: 'ANNUAL_STATEMENTS', name: 'Annual operating statements', initial: true, type: 'os' },
    { id: 'LATEST_STATEMENTS', name: 'Latest monthly or quarterly operating statements', initial: true, type: 'os' },
];

const orderTypes = {
    LOAN_QUOTES: 'Loan Quote',
    FULL: 'Loan Quote & Detailed Valuation',
};

const emptyData = createEmptyData({ quantity: 5, prefix: 'skeleton' });

const initialState = {
    error: null,
    data: emptyData,
    loadedCount: 0,
    loading: false,
    info: null,
};

const myPropertiesSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadInitial(state) {
            state.loading = true;
        },
        loadMore(state) {
            state.loading = true;
        },
        update(state, action) {
            const { data, loadedCount } = action.payload;
            state.loadedCount = loadedCount;
            state.data = data;
            state.loading = false;
        },
        moreInfo(state, action) {
            state.info = action.payload;
        },
        delete() {},
    },
});

export const {
    update: updateMyProperties,
    moreInfo: moreInfoMyProperties,
    loadInitial: loadInitialProperties,
    loadMore: loadMoreProperties,
    delete: deleteProperty,
} = myPropertiesSlice.actions;

export default myPropertiesSlice.reducer;

export const selectLoadedCount = (state) => state.myProperties.loadedCount;

export const selectMyPropertiesState = (state) => state.myProperties;

export const selectHasEmptyProperties = ({ properties }) => !properties?.data?.length;

export const selectMoreInfoPropertyState = ({ properties: { data, info } }) => {
    const [moreInfo] = data.filter(({ id } = {}) => id === info);
    return moreInfo || {};
};

export const selectUploadedDocuments = createSelector(
    selectMoreInfoPropertyState,
    ({ requiredDocumentTypes = [] } = {}) =>
        requiredFiles.reduce(
            (files, file) => {
                if (requiredDocumentTypes.includes(file.id)) {
                    files.required[file.type].push({ required: true, ...file });
                } else {
                    files.documents.push(file);
                }
                return files;
            },
            {
                documents: [],
                required: {
                    rr: [],
                    os: [],
                },
            }
        )
);

const transformProperty = (property) => {
    const date = dayjs(property.dateRequest).format('DD MMM, YYYY h:mm a');
    const orderType = orderTypes[property.type];
    return { ...property, date, type: orderType };
};

function* loadInitialPropertiesSaga({ type, payload }) {
    yield put(apiRequest(payload, ordersApi.getOrders, type));
    const { payload: { data: { content: properties, totalElements } = {} } = {} } = yield take([
        `${type} ${API_SUCCESS}`,
    ]);

    if (properties) {
        const data = properties.map(transformProperty);
        const loadedCount = data.length;
        data.length = totalElements;
        yield put(updateMyProperties({ data, loadedCount }));
    }
}

function* loadMorePropertiesSaga({ type, payload }) {
    const { startIndex, stopIndex, size } = payload;
    const { loadedCount: prevLoadedCount, data: prevData } = yield select(selectMyPropertiesState);
    yield put(apiRequest({ page: prevLoadedCount / size, size }, ordersApi.getOrders, type));
    const { payload: { data: { content } = {} } = {} } = yield take([`${type} ${API_SUCCESS}`]);

    if (content) {
        const data = content.map(transformProperty);
        const loadedCount = prevLoadedCount + data.length;
        const newData = [...prevData];
        for (let i = startIndex; i <= stopIndex; i += 1) {
            newData[i] = data[i - startIndex];
        }
        yield put(updateMyProperties({ data: newData, loadedCount }));
    }
}

function* moreInfoMyPropertiesSaga() {
    const { countyId, parcelId } = yield select(selectMoreInfoPropertyState);
    yield put(
        inProgressOrder({
            countyid: countyId,
            parcelid: parcelId,
        })
    );
}
function* deletePropertySaga({ type, payload }) {
    const action = yield put(apiRequest({ id: payload }, propertiesApi.deleteProperty, type));
    if (action.type.endsWith(API_SUCCESS)) {
        yield put(loadInitialPropertiesSaga());
    }
}

export function* watchMyProperties() {
    yield takeLatest(moreInfoMyProperties, moreInfoMyPropertiesSaga);
    yield takeLatest(loadInitialProperties, loadInitialPropertiesSaga);
    yield takeLatest(loadMoreProperties, loadMorePropertiesSaga);
    yield takeLatest(deleteProperty, deletePropertySaga);
}
