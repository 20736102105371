import { Box, InputAdornment, Divider, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';

import { selectAuthState, selectIsUserAuth } from '@scc/store/ducks/auth';

import Button from '@ui/Button';
import Grid from '@ui/Grid';
import Icon from '@ui/Icon';
import Input from '@ui/Input';

import LoginSocialNetworkButton from '@components/SocialNetworkButton';

import colors from '@themes/palette/export.module.scss';

import EyeSlashSvg from '@assets/icons/outlined/eye-slash.svg';
import EyeSvg from '@assets/icons/outlined/eye.svg';

import styles from './styles.module.scss';
import { registrationSchema } from './validationSchema';

function EmailForm({ user, onSuccess, error }) {
    const {
        register,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(registrationSchema),
    });
    const { currentUser = {}, error: authError } = useSelector(selectAuthState);
    const isLoggedIn = useSelector(selectIsUserAuth);

    const onSubmit = (data) => {
        onSuccess(data);
    };

    useEffect(() => {
        if (authError) {
            setError('email', {
                type: 'manual',
                message: authError.message,
            });
        } else if (isLoggedIn) {
            reset();
            typeof onSuccess === 'function' && onSuccess(currentUser.email);
        } else {
            clearErrors();
        }
    }, [authError, isLoggedIn]);

    useEffect(() => {
        if (error) {
            setError('email', {
                type: 'manual',
                message: error.message,
            });
        }
    }, [error]);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    /* eslint-disable react/jsx-props-no-spreading */

    return (
        <div className={styles.formWrapper}>
            <Box className={styles.form} component='form' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    defaultValue={user?.email}
                    label='Email'
                    margin='normal'
                    variant='standard'
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    onChange={({ target: { value } }) => {
                        setValue('email', value);
                        user.email = value;
                    }}
                />
                <Input
                    defaultValue={user?.pwd}
                    label='Password'
                    margin='normal'
                    variant='standard'
                    onChange={({ target: { value } }) => {
                        setValue('password', value);
                        user.pwd = value;
                    }}
                    {...register('password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    edge='end'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <Icon color={colors['grey-600']} component={EyeSlashSvg} fontSize='17px' />
                                    ) : (
                                        <Icon color={colors['grey-600']} component={EyeSvg} fontSize='17px' />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                />
                <Input
                    defaultValue={user?.pwd}
                    label='Confirm Password'
                    margin='normal'
                    variant='standard'
                    onChange={({ target: { value } }) => {
                        setValue('confirmPassword', value);
                        user.pwd = value;
                    }}
                    {...register('confirmPassword')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    edge='end'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <Icon color={colors['grey-600']} component={EyeSlashSvg} fontSize='17px' />
                                    ) : (
                                        <Icon color={colors['grey-600']} component={EyeSvg} fontSize='17px' />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                />
                <Button className={styles.submit} size='extra-large' type='submit' variant='contained' fullWidth>
                    Continue
                </Button>
            </Box>
            <div>
                <Divider sx={{ margin: '34px 0' }}>or</Divider>

                <Grid
                    alignItems='center'
                    className={styles.socialButtons}
                    direction='row'
                    justifyContent='center'
                    spacing={3}
                    container
                >
                    <Grid xs='auto' item>
                        <LoginSocialNetworkButton
                            size='extra-large'
                            type='google'
                            variant='outlined'
                            fullWidth
                            logoOnly
                        />
                    </Grid>
                    <Grid xs='auto' item>
                        <LoginSocialNetworkButton size='extra-large' type='facebook' variant='outlined' logoOnly />
                    </Grid>
                    <Grid xs='auto' item>
                        <LoginSocialNetworkButton size='extra-large' type='linkedin' variant='outlined' logoOnly />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default EmailForm;
