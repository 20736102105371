import cx from 'classnames';
import { useEffect, useState } from 'react';

import Icon from '@ui/Icon';
import IconButton from '@ui/IconButton';

import { BlockedControl } from '@components/BlockedBox';
import EditableBlock from '@components/EditableBlock';
import Percent from '@components/Percent';

import colors from '@themes/palette/export.module.scss';

import ArrowDownIcon from '@icons/filled/arrow-down.svg';

import styles from './styles.module.scss';

const DEFAULT_GRADATION = 1;
const DEFAULT_MIN = -Infinity;
const DEFAULT_MAX = Infinity;

function EditablePercent({
    name,
    value: initialValue,
    onSuccess = () => {},
    visibleArrows,
    gradation = DEFAULT_GRADATION,
    min = DEFAULT_MIN,
    max = DEFAULT_MAX,
    withLabel = true,
    withoutDecimals = false,
    amountFontVariant = 'hr12',
    labelFontVariant = 'hr12',
    isLimited,
    inputClassName,
    withStaticWidth,
}) {
    const [value, setValue] = useState(initialValue);

    const onChange = ({ value: propValue }) => {
        if (propValue > min && propValue < max) {
            setValue(propValue);
        } else if (propValue < min) {
            setValue(min);
        } else if (propValue > max) setValue(max);
    };

    useEffect(() => {
        if (value !== initialValue) {
            onSuccess({ value, name });
        }
    }, [value]);

    return (
        <div
            className={cx(styles.wrapper, {
                [styles.withoutPadding]: isLimited,
            })}
        >
            <BlockedControl isLimited={isLimited}>
                <EditableBlock
                    className={styles.editableNumber}
                    initialValue={value}
                    inputClassName={inputClassName}
                    name={name}
                    notEditable={isLimited}
                    withStaticWidth={withStaticWidth}
                    onSuccess={onChange}
                >
                    <Percent
                        amountColor={colors['darkNeutral-400']}
                        amountFontVariant={amountFontVariant}
                        className={cx(styles.interactiveCell, {
                            [styles.interactiveCellLimited]: isLimited,
                        })}
                        labelColor={colors['grey-400']}
                        labelFontVariant={labelFontVariant}
                        withLabel={withLabel}
                    >
                        {Number(value)
                            .toFixed(withoutDecimals ? 0 : 2)
                            .toString()}
                    </Percent>
                </EditableBlock>
            </BlockedControl>
            {visibleArrows && !isLimited ? (
                <div className={styles.controls}>
                    <IconButton size='small' onClick={() => onChange({ value: +value + gradation })}>
                        <Icon className={styles.topArrow} color={colors['grey-400']} component={ArrowDownIcon} />
                    </IconButton>
                    <IconButton size='small' onClick={() => onChange({ value: +value - gradation })}>
                        <Icon color={colors['grey-400']} component={ArrowDownIcon} />
                    </IconButton>
                </div>
            ) : null}
        </div>
    );
}

export default EditablePercent;
