import { InputAdornment } from '@mui/material';
import clsx from 'clsx';
import { memo } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Icon from '@ui/Icon';
import OutlinedInput from '@ui/OutlinedInput';

import colors from '@themes/palette/export.module.scss';

import styles from './Trigger.module.scss';

type TriggerProps = {
    isOpen: boolean;
    onClick: () => void;
    value: string;
    [key: string]: unknown;
};

function TriggerComponent({ isOpen, onClick, value, ...rest }: TriggerProps) {
    return (
        <OutlinedInput
            className={clsx(styles.input, {
                [styles.open]: isOpen,
            })}
            endAdornment={
                <InputAdornment position='end'>
                    <Icon className={styles.icon} color={colors['grey-400']} component={ChevronIcon} fontSize={20} />
                </InputAdornment>
            }
            value={value}
            variant='noOutlined'
            readOnly
            onClick={onClick}
            {...rest}
        />
    );
}

export const Trigger = memo(TriggerComponent);
