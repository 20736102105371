import { createSlice } from '@reduxjs/toolkit';
import { take, put, call, takeLatest, takeEvery, delay, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { extractInitial } from '@utils/index';

import links from '@scc/router/links';
// eslint-disable-next-line import/no-cycle
import { setTemplateAuthMng } from '@scc/store/ducks/authManager';

import { userApi } from '@api';
import { PHONE_VERIFICATION } from '@constants/modals';
import { apiRequest, API_SUCCESS, API_ERROR, API_ERROR_CLEAN } from '@store/ducks/api';
import { showPageDialog, hideDialog } from '@store/ducks/ui/dialog';
import { getRole, ROLES } from '@store/helpers';

const initialState = {
    currentUser: null,
    status: 'public',
    error: null,
    info: {},
    loading: false,
    loaded: false,
    updateUserStatus: false,
    socialNetwork: false,
};

const entity = '[auth]';
const updateUserEntity = 'updateUser';

const authSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        login(state) {
            state.loaded = false;
            state.loading = true;
            state.error = null;
        },
        loginAsUser(state) {
            state.loaded = false;
            state.loading = true;
            state.error = null;
        },
        register(state) {
            state.loaded = false;
            state.loading = true;
            state.error = null;
        },
        logout: () => initialState,
        logoutAsUser() {},
        logoutRedirect() {},
        getUser(state) {
            state.error = null;
        },
        updateUser(state) {
            state.loaded = false;
            state.loading = true;
        },
        uploadAvatar() {},
        deleteAvatar() {},
        [`${updateUserEntity} ${API_SUCCESS}`](state) {
            state.updateUserStatus = true;
        },
        getUserInfo(state) {
            state.loaded = true;
            state.loading = false;
        },
        setUserInfo(state, action) {
            state.loaded = true;
            state.loading = false;
            state.info = action.payload;
        },
        setUser(state, action) {
            state.loaded = true;
            state.loading = false;
            state.currentUser = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
            state.loaded = true;
            state.loading = false;
        },
        cleanError(state) {
            state.error = null;
        },
        cleanUserStatus(state) {
            state.updateUserStatus = false;
            state.loaded = true;
            state.loading = false;
        },
        setLoader(state, action) {
            state.loaded = action.payload.loaded ?? state.loaded;
            state.loading = action.payload.loading ?? state.loading;
        },
        loaded(state) {
            state.loaded = true;
            state.loading = false;
        },
        verificationPhone() {},
        socialNetwork(state, action) {
            const { payload } = action.payload;
            state.socialNetwork = !!payload;
        },
    },
});

export const {
    register: registerAuth,
    login: loginAuth,
    logout: logoutAuth,
    loginAsUser: loginAsUserAuth,
    logoutAsUser: logoutAsUserAuth,
    logoutRedirect: logoutRedirectAuth,
    getUser: getCurrentUserAuth,
    setUser: setUserAuth,
    updateUser: updateUserAuth,
    deleteAvatar: deleteUserAvatar,
    uploadAvatar: uploadUserAvatar,
    getUserInfo: getUserInfoAuth,
    setUserInfo: setUserInfoAuth,
    setStatus: setStatusAuth,
    setError: setErrorAuth,
    cleanError: cleanErrorAuth,
    cleanUserStatus: cleanUserStatusAuth,
    loaded: loadedAuth,
    setLoader: setLoaderAuth,
    verificationPhone: verificationPhoneAuth,
    socialNetwork: socialNetworkAuth,
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthState = (state) => state.auth;

export const selectUserInfo = (state) => state.auth.info;

export const selectUserProfile = ({ auth: { info = {}, currentUser } = {} }) => {
    const { profilePictureExists, firstname = '', lastname = '' } = info;

    return {
        initials: [extractInitial(firstname), extractInitial(lastname)].join(''),
        avatar: profilePictureExists ? '/api/v2/user/avatar' : '',
        userId: currentUser?.userId,
    };
};

export const selectUserSso = ({ auth: { currentUser } = {} }) => {
    return {
        ssoLogin: currentUser?.ssoLogin,
        ssoLink: currentUser?.ssoLink,
    };
};

export const selectFullUserState = ({ auth }) => ({ ...auth.info, ...auth.currentUser });

export const selectAuthPermissions = (state) => state.auth.currentUser.permissions;

export const selectIsUserAuth = createSelector(
    selectAuthState,
    ({ currentUser } = {}) => currentUser && !currentUser?.roles?.includes(ROLES.ANONYMOUS)
);

export const selectIsUser = createSelector([selectAuthState, selectIsUserAuth], ({ currentUser } = {}, isLoggedIn) => ({
    isUser:
        isLoggedIn &&
        currentUser?.roles.includes(ROLES.USER) &&
        !currentUser?.roles.includes(ROLES.ADMIN) &&
        !currentUser?.roles.includes(ROLES.GROUP_ADMIN),
}));

export const selectStatusAuthState = createSelector(
    selectAuthState,
    ({ error, loading, loaded, updateUserStatus }) => ({
        loading,
        error,
        loaded,
        isSuccess: updateUserStatus && loaded,
    })
);

export const selectIsUserLender = getRole(selectAuthState, ROLES.LENDER);

export const selectIsUserAdmin = getRole(selectAuthState, ROLES.ADMIN);

export const selectIsUserSCCLimited = getRole(selectAuthState, ROLES.SCC_LIMITED);

export const selectIsUserBorrower = getRole(selectAuthState, ROLES.BORROWER);

export const selectIsUserLenderOrAdmin = selectIsUserLender || selectIsUserAdmin;

export const selectIsUserFullServe = getRole(selectAuthState, ROLES.FULL_SERVE);

export const selectIsUserFullServeExtended = getRole(selectAuthState, ROLES.SCC_FULL_SERVE_EXTENDED);

export const selectIsUserSelfServe = getRole(selectAuthState, ROLES.SCC_SELF_SERVE);

export const selectIsUserServicer = getRole(selectAuthState, ROLES.SCC_SERVICER);

export const selectIsUserPacificLife = getRole(selectAuthState, ROLES.SCC_PL);

export const selectIsUserJLLTenant = getRole(selectAuthState, ROLES.JLL_TENANT);

export const selectIsUserRGATenant = getRole(selectAuthState, ROLES.RGA_TENANT);

export const selectIsUserFMACTenant = getRole(selectAuthState, ROLES.FMAC_TENANT);

export const selectIsUserGantryTenant = getRole(selectAuthState, ROLES.GANTRY_TENANT);

export const selectIsUserPLTenant = getRole(selectAuthState, ROLES.PL_TENANT);

export const selectIsUserRMRTenant = getRole(selectAuthState, ROLES.RMR_TENANT);

export const selectIsUserDemo = getRole(selectAuthState, ROLES.DEMO);

export const selectIsAdmin = createSelector(
    [selectAuthState, selectIsUserAuth, selectIsUserLender],
    ({ currentUser } = {}, isLoggedIn, isLender) => ({
        isAdmin: currentUser?.roles?.includes(ROLES.ADMIN),
        isPreviousAdmin: currentUser?.roles?.includes(ROLES.PREV_ADMIN),
        isGroupAdmin: currentUser?.roles?.includes(ROLES.GROUP_ADMIN),
        isLender,
        isLoggedIn,
        userId: currentUser?.userId,
    })
);

function* requestAuthSaga({ type, payload: { meta, ...payload } }) {
    yield delay(100);
    const method = type.includes(loginAuth.type) ? userApi.login : userApi.register;
    yield put(apiRequest(payload, method, type, meta));

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);

    if (action.type.endsWith(API_ERROR)) {
        yield put(setErrorAuth(action.payload));
    } else {
        const { headers } = action.payload?.response || {};
        const ssoRedirect = headers.get('x-sso-redirect');
        if (ssoRedirect) {
            window.location.replace(ssoRedirect);
        }
    }
}

function* loginAsUserSaga({ type, payload }) {
    yield delay(100);
    yield put(apiRequest(payload, userApi.loginAsUser, type));

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    if (action.type.endsWith('SUCCESS')) {
        // todo
        window.router?.history.push(links.home.path);
        window.location.reload();
    } else {
        yield put(setErrorAuth({ error: action.payload }));
    }
}

function* logoutAsUserSaga({ type }) {
    yield delay(100);
    yield put(apiRequest(null, userApi.logoutAsUser, type));

    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    if (action.type.endsWith('SUCCESS')) {
        // todo
        window.location.reload();
    } else {
        yield put(setErrorAuth({ error: action.payload }));
    }
}

function* requestLogoutAuthSaga() {
    yield delay(300);

    yield call(userApi.logout);
    yield put(getCurrentUserAuth());
    yield put(setUserInfoAuth({}));
}

function* logoutRedirectSaga(action) {
    yield put(logoutAuth());
    window.router?.history.push(action.payload, {
        from: { pathname: window.location.pathname },
    });
}

function* getCurrentUserAuthSaga() {
    yield put(apiRequest(null, userApi.getCurrentUser, getCurrentUserAuth.type));

    const action = yield take([`${getCurrentUserAuth} ${API_SUCCESS}`]);

    yield put(setUserAuth(action.payload));

    let status = 'public';
    if (!action.payload.roles.includes(ROLES.ANONYMOUS)) {
        status = 'private';
    } else {
        yield put(setUserInfoAuth({}));
    }

    yield put(setStatusAuth(status));
}

function* getUserInfoAuthSaga({ type }) {
    yield put(apiRequest(null, userApi.getUserInfo, type));
    const action = yield take([`${getUserInfoAuth} ${API_SUCCESS}`]);
    yield put(setUserInfoAuth(action.payload));
}

function* updateUserAuthSaga({ type, payload = {} }) {
    const { firstname, lastname, email, ...restUserData } = yield select(selectUserInfo);
    const { firstname: firstName = firstname, lastname: lastName = lastname, ...userData } = payload;
    yield put(apiRequest({ email, firstName, lastName, ...restUserData, ...userData }, userApi.updateUser, type));
    const action = yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    yield put(loadedAuth());

    if (action.type.endsWith(API_SUCCESS)) {
        yield put(getUserInfoAuth());
        yield delay(5000);

        yield put(cleanUserStatusAuth());
    } else {
        yield put(setErrorAuth({ error: action.payload }));
    }
}

function* cleanTestAuthErrorSaga({ payload }) {
    const { time } = payload.meta || {};
    if (typeof time !== 'undefined') {
        yield delay(time);
        yield put(cleanErrorAuth(null));
    }
}

function* verificationPhoneSaga() {
    const { payload } = yield take(`${getUserInfoAuth} ${API_SUCCESS}`);

    if (!payload.phone) {
        yield put(
            showPageDialog({ content: PHONE_VERIFICATION, state: { noHeader: true, closeLink: links.home.path } })
        );
        const action = yield take([loadedAuth, hideDialog]);
        if (action.type === loadedAuth.type) {
            yield put(hideDialog());
            yield put(setTemplateAuthMng({ name: 'complete' }));
            yield delay(4000);
        }
    }
    yield put(getCurrentUserAuth());
}

function* socialNetworkAuthSaga({ payload: { meta, payload } }) {
    yield delay(meta?.delay);
    if (payload) {
        window.location.href = payload;
    }
}

function* uploadUserAvatarSaga({ payload: avatar, type }) {
    const formData = new FormData();
    formData.append('avatar', avatar, avatar.name);
    yield put(apiRequest(formData, userApi.uploadAvatar, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield delay(4000);
    yield put(getUserInfoAuth());
}

function* deleteUserAvatarSaga({ type }) {
    yield put(apiRequest(null, userApi.deleteAvatar, type));
    yield take(`${type} ${API_SUCCESS}`);
    yield put(getUserInfoAuth());
}

function* cleanAuthErrorSaga() {
    yield put(setErrorAuth(null));
}

export function* watchAuth() {
    yield takeLatest([loginAuth, registerAuth], requestAuthSaga);
    yield takeLatest(deleteUserAvatar, deleteUserAvatarSaga);
    yield takeLatest(uploadUserAvatar, uploadUserAvatarSaga);
    yield takeLatest(logoutAuth, requestLogoutAuthSaga);
    yield takeLatest(loginAsUserAuth, loginAsUserSaga);
    yield takeLatest(logoutAsUserAuth, logoutAsUserSaga);
    yield takeLatest(logoutRedirectAuth, logoutRedirectSaga);
    yield takeLatest(getCurrentUserAuth, getCurrentUserAuthSaga);
    yield takeLatest(getUserInfoAuth, getUserInfoAuthSaga);
    yield takeLatest(updateUserAuth, updateUserAuthSaga);
    yield takeLatest(
        [
            `${loginAuth} ${API_ERROR_CLEAN}`,
            `${registerAuth} ${API_ERROR_CLEAN}`,
            `${updateUserAuth} ${API_ERROR_CLEAN}`,
        ],
        cleanAuthErrorSaga
    );
    yield takeLatest(setErrorAuth, cleanTestAuthErrorSaga);
    yield takeEvery(verificationPhoneAuth, verificationPhoneSaga);
    yield takeEvery(socialNetworkAuth, socialNetworkAuthSaga);
}
