import request from '@utils/request';

const BASE_URL = '/api/v2/listing';

export async function getListings({ size = 19, page = 0, myListing = false, address, propertyTypes } = {}) {
    const params = { page, size };
    if (page === 0) {
        params.size = 19;
    }
    if (address) {
        params.address = address;
    }
    if (propertyTypes?.length) {
        params.propertyTypes = propertyTypes;
    }
    return request(`${BASE_URL}/find`, {
        params: { ...params, myListing },
    });
}

export async function getListingsBookmark(params) {
    return request(`${BASE_URL}/bookmark`, {
        params,
    });
}
