import { useEffect, useState } from 'react';
import cx from 'classnames';

import Input from '@ui/Input';
import Skeleton from '@ui/Skeleton';

import { DataTable } from '@components/DataTable';
import { existingDocuments } from '@components/DataTable/headers';
import styles from './styles.module.scss';

const AddExistingDocumentsForm = function ({ className, onChange, loading, total, actions, data }) {
    const { onChangeExistingDocumentInput } = actions;
    const [selectDocs, setSelectDocs] = useState([]);

    useEffect(() => {
        onChange(selectDocs);
    }, [selectDocs]);

    const handleSelectDocuments = (e, documentId) => {
        const i = selectDocs.indexOf(documentId);
        if (!~i) {
            setSelectDocs([documentId, ...selectDocs]);
        } else {
            const _selectDocs = [...selectDocs];
            _selectDocs.splice(i, 1);
            setSelectDocs(_selectDocs);
        }
    };

    return (
        <div className={cx(styles.wrapper, className)}>
            <Input fullWidth placeholder='Search document...' onChange={onChangeExistingDocumentInput} />
            {loading && <Skeleton width='100%' height='100%' />}
            {!loading && (
                <DataTable
                    data={data}
                    headers={existingDocuments}
                    totalCount={total}
                    cellProps={{
                        documents: {
                            onSelectDocuments: handleSelectDocuments,
                        },
                    }}
                />
            )}
        </div>
    );
};

export default AddExistingDocumentsForm;
