import request, {getContentNameFromResponse} from '@utils/request';
import download from 'downloadjs';

const BASE_URL = '/api/v2/third-party-report';

export async function getThirdPartyReports({ loanId }) {
    return request(`${BASE_URL}/find/${loanId}`);
}

export async function createThirdPartyReport(body) {
    return request(BASE_URL, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function uploadFileThirdPartyReport(data) {
    return request(`${BASE_URL}/upload`, {
        method: 'POST',
        body: data,
        headers: {},
    });
}

export async function createOrderThirdPartyReport(body) {
    return request(`${BASE_URL}/order`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function downloadReport(id) {
    return request(`${BASE_URL}/download/${id}`, { responseType: 'blob' });
}

export async function downloadSingleReport({ id }) {
    const { response, file } = await downloadReport(id);
    const fileName = getContentNameFromResponse(response);
    download(file, fileName);
}

export async function deleteSingleReport({ id, loanId }) {
    return request(`${BASE_URL}/loan/${loanId}/report/${id}`, {
        method: 'DELETE',
    });
}
