import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest, select } from 'redux-saga/effects';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { propertiesApi } from '@api';

const entity = '[properties]';

const initialState = {
    data: {},
    loaded: false,
    loading: false,
};

const propertiesSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        loadMore() {},
        setData(state, action) {
            const { content, pageable, totalElements } = action.payload;

            state.data = { properties: content, pageable, totalCount: totalElements };
            state.loaded = true;
            state.loading = false;
        },
        setMoreData(state, action) {
            const { content } = action.payload;

            state.data.properties = [...state.data.properties, ...content];
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const {
    load: loadProperties,
    loadMore: loadMoreProperties,
    setData: setProperties,
    setMoreData: setMoreProperties,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;

export const selectPropertiesState = (store) => store.history.properties;

function* loadPropertiesSaga({ type, payload }) {
    yield put(apiRequest(payload, propertiesApi.getPropertyLenderHistory, type));
    const action = yield take(`${type} ${API_SUCCESS}`);

    yield put(setProperties(action.payload.data));
}

function* loadMorePropertiesSaga({ type }) {
    const {
        data: { pageable },
        search = {},
    } = yield select(selectPropertiesState);
    yield put(apiRequest({ ...search, page: pageable.pageNumber + 1 }, propertiesApi.getPropertyLenderHistory, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setMoreProperties(action.payload.data));
}

export function* watchProperties() {
    yield takeLatest(loadProperties, loadPropertiesSaga);
    yield takeLatest(loadMoreProperties, loadMorePropertiesSaga);
}
