import * as yup from 'yup';
import { validation } from '@utils/forms';

const schema = yup.object().shape({
    name: yup.string(),
    email: validation.email,
    message: yup.string(),
    captcha: yup.string(),
});

export default schema;