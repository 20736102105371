import { Link } from 'react-router-dom';

import SmilingFace from '@scc/assets/icons/smiling-face.svg';
import links from '@scc/router/links';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import styles from './getStarted.module.scss';

const defaultProps = {
    className: '',
    text: 'Get Started',
    showIcon: false,
    showBackgroundImages: false,
    padding: false,
};

function GetStarted({ text, className, showIcon, showBackgroundImages, padding }) {
    return (
        <div
            className={`${styles.getStarted} ${className} ${showBackgroundImages && styles.getStartedBackgroudImages}
                ${padding && styles.getStartedPadding}`}
        >
            <Button component={Link} rel='nofollow' size='large' to={links.register.path} fullWidth>
                {showIcon && <Icon component={SmilingFace} fontSize={24} />}
                <span>{text}</span>
            </Button>
        </div>
    );
}

GetStarted.defaultProps = defaultProps;

export default GetStarted;
