import { createSlice } from '@reduxjs/toolkit';
import { put, take, takeLatest } from 'redux-saga/effects';
import { API_SUCCESS, apiRequest } from '@store/ducks/api';
import { dashboardApi } from '@api';

const entity = '[dashboardLoans]';

const initialState = {
    data: [],
    loaded: false,
    loading: false,
};

const loansSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        load(state) {
            state.loaded = false;
            state.loading = true;
        },
        setData(state, action) {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
        },
    },
});

export const { load: loadLoans, setData: setLoans } = loansSlice.actions;

export default loansSlice.reducer;

export const selectLoansState = (store) => store.dashboard.loans;

function* loadLoansSaga({ type, payload }) {
    yield put(apiRequest(payload, dashboardApi.getLoans, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    yield put(setLoans(action.payload.data));
}

export function* watchLoans() {
    yield takeLatest(loadLoans, loadLoansSaga);
}
