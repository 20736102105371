import { combineReducers } from 'redux';

import _drawerReducer from '@scc/store/ducks/ui/_drawer';
import popoverReducer from '@scc/store/ducks/ui/popover';

import dialogReducer from '@store/ducks/ui/dialog';
import drawerReducer from '@store/ducks/ui/drawer';
import { watchMainAlert } from '@store/ducks/ui/mainAlert';
import notificationsReducer from '@store/ducks/ui/notifications';

export default combineReducers({
    _drawer: _drawerReducer,
    drawer: drawerReducer,
    dialog: dialogReducer,
    popover: popoverReducer,
    notifications: notificationsReducer,
});

export const uiWatchers = [watchMainAlert];
