import Icon from '@ui/Icon';

import colors from '@themes/palette/export.module.scss';

import RadiobuttonSvg from '@assets/icons/components/radiobutton/radiobutton.svg';
import RadiobuttonDotSvg from '@assets/icons/components/radiobutton/radiobuttonDot.svg';

const radiobutton = () => ({
    MuiRadio: {
        defaultProps: {
            disableRipple: true,
            icon: <Icon color='inherit' component={RadiobuttonSvg} fontSize='inherit' />,
            checkedIcon: <Icon color='inherit' component={RadiobuttonDotSvg} fontSize='inherit' />,
        },
        styleOverrides: {
            root: {
                padding: 0,
                '&.Mui-disabled': {
                    color: colors['grey-A200'],
                    '&.Mui-checked': {
                        color: colors['blue-main'],
                        opacity: 0.5,
                    },
                },
            },
        },
        variants: [
            {
                props: { size: 'small' },
                style: {
                    fontSize: 14,
                },
            },
            {
                props: { size: 'medium' },
                style: {
                    fontSize: 18,
                },
            },
        ],
    },
});

export default radiobutton;
