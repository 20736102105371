import Icon from '@ui/Icon';

import colors from '@themes/palette/export.module.scss';

import CheckboxSvg from '@assets/icons/components/checkbox/checkbox.svg';
import CheckboxCheckedSvg from '@assets/icons/components/checkbox/checkboxChecked.svg';
import CheckboxIndeterminateSvg from '@assets/icons/components/checkbox/indeterminate.svg';

const checkbox = () => ({
    MuiCheckbox: {
        defaultProps: {
            disableRipple: true,
            icon: <Icon component={CheckboxSvg} />,
            checkedIcon: <Icon component={CheckboxCheckedSvg} />,
            indeterminateIcon: <Icon component={CheckboxIndeterminateSvg} />,
        },
        styleOverrides: {
            root: {
                padding: 0,
                '&.Mui-disabled': {
                    color: colors['grey-A200'],
                    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                        color: colors['blue-main'],
                        opacity: 0.5,
                    },
                },
            },
        },
        variants: [
            {
                props: { size: 'small' },
                style: {
                    fontSize: 12,
                },
            },
            {
                props: { size: 'medium' },
                style: {
                    fontSize: 16,
                },
            },
        ],
    },
});

export default checkbox;
