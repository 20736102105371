import isEmpty from 'lodash/isEmpty';
import { ReactNode } from 'react';

type TItemListProps<T> = {
    items: T[] | T;
    children: (item: T, index: number, rest: Record<string, unknown>) => ReactNode;
    alt?: ReactNode;
    limit?: number;
    adapter?: (data: T) => T;
    render?: (item: T, index: number, rest: Record<string, unknown>) => ReactNode;
    loading?: boolean;
} & Record<string, unknown>;

function ItemList<T extends Record<string, unknown>>({
    items,
    limit,
    children,
    loading,
    adapter = (data: T) => data,
    render = children,
    alt = null,
    ...rest
}: TItemListProps<T>) {
    const isItemsArray = Array.isArray(items);

    const isItemsEmpty = isItemsArray ? !items.length : isEmpty(items);

    if (isItemsEmpty || loading) {
        return alt;
    }

    const renderer = (item: T, index: number) => render(adapter(item), index, rest);

    if (isItemsArray) {
        return (items as T[]).slice(0, limit || items.length).map(renderer);
    }

    return renderer(items as T, 0);
}

export default ItemList;
