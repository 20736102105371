import { baseApi as api } from '../configs/comments';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        addComment: build.mutation<AddCommentApiResponse, AddCommentApiArg>({
            query: (queryArg) => ({ url: `/api/v2/comments`, method: 'POST', body: queryArg.addCommentRequest }),
        }),
        getComments: build.query<GetCommentsApiResponse, GetCommentsApiArg>({
            query: (queryArg) => ({ url: `/api/v2/comments/${queryArg.entityType}/${queryArg.entityId}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type AddCommentApiResponse = /** status 200 OK */ GenericJsonResponseListCommentDto;
export type AddCommentApiArg = {
    addCommentRequest: AddCommentRequest;
};
export type GetCommentsApiResponse = /** status 200 OK */ GenericJsonResponseListCommentDto;
export type GetCommentsApiArg = {
    entityType: 'DOCUMENT' | 'PROPERTY' | 'CONTACT' | 'DRAW';
    entityId: number;
};
export type UserCardResponse = {
    last4?: string;
    brand?: string;
    cardName?: string;
};
export type UserInfoDto = {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    companyname?: string;
    title?: string;
    department?: string;
    restoreid?: string;
    card?: UserCardResponse;
    profilePictureExists?: boolean;
    address?: string;
    avatarUrl?: string;
    invitedAt?: string;
    inviteConfirmed?: boolean;
};
export type CommentDto = {
    id?: number;
    user?: UserInfoDto;
    comment?: string;
    date?: string;
};
export type GenericJsonResponseListCommentDto = {
    status?: string;
    data?: CommentDto[];
    message?: string;
    code?: string;
};
export type AddCommentRequest = {
    entityId: number;
    entityType: EntityType;
    comment: string;
};
export enum EntityType {
    Document = 'DOCUMENT',
    Property = 'PROPERTY',
    Contact = 'CONTACT',
    Draw = 'DRAW',
}
export const { useAddCommentMutation, useGetCommentsQuery } = injectedRtkApi;
