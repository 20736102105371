import breakpoints from '@themes/breakpoints/export.module.scss';

export const getBreakpoints = () => {
    const breakpointList = {};
    Object.keys(breakpoints).forEach((key) => {
        breakpointList[key] = Number(breakpoints[key]);
    });

    return breakpointList;
};

export default getBreakpoints();
