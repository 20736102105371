import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery, createConfig } from '@scc/services/helpers';
const ENTITY = 'notes2';
export const baseApi = createApi({
    reducerPath: `[api/${ENTITY}]`,
    baseQuery: createBaseQuery(),
    endpoints: () => ({}),
});
export const config = createConfig({
    filename: ENTITY,
    filterEndpoints: ['getNotes', 'saveNotes'],
});
















