import Stack from '@mui/material/Stack';
import format from 'date-fns/format';

import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

export const rentRollComparisonLabel = ({ name, date }: { name: string; date: number }) => {
    return (
        <Stack alignItems='center' direction='row' gap={4} justifyContent='space-between' minWidth='380px'>
            <Typography color={colors['text-accent']} variant='pr14'>
                {name}
            </Typography>
            <Typography color={colors['text-secondary']} variant='pr12'>
                {date && format(date, 'MMM dd, yyyy')}
            </Typography>
        </Stack>
    );
};
