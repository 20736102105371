import request from '@utils/request';

const BASE_URL = '/geoGekko/user';
const BASE_URL_V2 = '/api/v2/user';
const REQUEST_URL_V2 = '/api/v2/user-request';

export async function getCurrentUser() {
    return request(`${BASE_URL}/get`);
}

export async function getUserPing() {
    return request(`${BASE_URL}/ping`, {
        noRedirect: true,
    });
}

export async function getUserInfo(userId) {
    return request(`${BASE_URL}/info/get`, {
        params: userId ? { id: userId } : {},
    });
}

export async function uploadAvatar(file) {
    return request(`${BASE_URL_V2}/avatar`, {
        method: 'PUT',
        body: file,
        headers: {},
    });
}

export function updateFreshChatRestoreId({ email, restoreId }) {
    return request(`/geoGekko/freshchatid/update`, {
        method: 'POST',
        body: JSON.stringify({
            email,
            restoreid: restoreId,
        }),
    });
}

export async function deleteAvatar() {
    return request(`${BASE_URL_V2}/avatar`, {
        method: 'DELETE',
    });
}

export async function getAvatarById(userId) {
    return request(`${BASE_URL_V2}/${userId}/avatar`);
}

export async function uploadAvatarById(id, file) {
    return request(`${BASE_URL_V2}/${id}/avatar`, {
        method: 'PUT',
        body: file,
        headers: {},
    });
}

export async function updateUser(data = {}) {
    return request(`${BASE_URL_V2}/me`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function login(data = {}) {
    return request('/api/login', {
        method: 'POST',
        responseType: 'response',
        csrfSafe: true,
        csrfClean: true,
        noRedirect: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data),
    });
}

export async function checkEmail({ email }) {
    return request(`${BASE_URL_V2}/check`, {
        params: { email },
    });
}

export async function logout() {
    return request('/logout');
}

export async function loginAsUser(data = {}) {
    return request('/login/impersonate', {
        method: 'POST',
        responseType: 'default',
        csrfSafe: true,
        csrfClean: true,
        noRedirect: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data),
    });
}

export async function logoutAsUser() {
    return request('/logout/impersonate', {
        method: 'POST',
        responseType: 'default',
        csrfSafe: true,
        csrfClean: true,
        noRedirect: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
}

export async function register(data = {}) {
    return request(`${BASE_URL_V2}/register`, {
        method: 'POST',
        csrfSafe: true,
        csrfClean: true,
        body: JSON.stringify(data),
    });
}

export async function resetPassword({ email }) {
    return request(`${BASE_URL}/password/reset`, {
        method: 'POST',
        csrfSafe: true,
        body: JSON.stringify({ email }),
    });
}

export async function resetUserPassword({ email }) {
    return request(`${BASE_URL_V2}/password/reset`, {
        method: 'POST',
        body: JSON.stringify({ email }),
    });
}

export async function sendInvite({ userId }) {
    return request(`${BASE_URL_V2}/invite/${userId}`, {
        method: 'POST',
    });
}

export async function confirmPassword(data) {
    return request(`${BASE_URL}/password/confirm`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function inviteUser(data) {
    return request(`${BASE_URL_V2}/invite/confirm`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function saveOverrides(countyId, parcelId, params, userId) {
    const data = {
        countyid: countyId,
        parcelid: parcelId,
        params,
    };
    if (userId) {
        data.userid = userId;
    }
    return request(`${BASE_URL}/parcel/overrides/save`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getUsers(teamType) {
    return request(`${BASE_URL_V2}/to_share?type=${teamType}`);
}

export async function getAllUsers({ page, size = 20, sort, search, orderBy, team, company }) {
    return request(`${BASE_URL_V2}/filter`, {
        params: {
            size,
            page,
            sort,
            search,
            orderBy,
            team,
            company,
        },
    });
}

export async function updateUserMember({ id, ...data }) {
    return request(`${BASE_URL_V2}/update-member-user/${id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function getUserById(id) {
    if (!id) return null;
    return request(`${BASE_URL}/info/get?id=${id}`);
}

export async function createEditUser(user) {
    return request(BASE_URL_V2, {
        method: 'PUT',
        body: JSON.stringify(user),
    });
}

export async function demoRequest(data = {}) {
    return request(`${BASE_URL_V2}/scc-demo-request`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getJwtToken() {
    return request(`${BASE_URL_V2}/jwt`);
}

export async function deleteAll(data) {
    return request(`${BASE_URL_V2}/delete-all`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function loadUserCompanies() {
    return request(`${BASE_URL_V2}/user-group`);
}

export async function createUserCompany(data) {
    return request(`${BASE_URL_V2}/user-group-contact`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function getUserRequestsByPropertyId(propertyId) {
    return request(`${REQUEST_URL_V2}/property/${propertyId}`);
}

export async function requestAnalystHelp(data) {
    return request(`${REQUEST_URL_V2}/analyst/ask`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export async function updateUserCompany(data) {
    return request(`${BASE_URL_V2}/user-group`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export async function updateUserRequest(payload) {
    return request(`${REQUEST_URL_V2}/update`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
}

export async function deleteUserCompany(data) {
    return request(`${BASE_URL_V2}/user-group`, {
        method: 'DELETE',
        body: JSON.stringify(data),
    });
}
