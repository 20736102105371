import cx from 'classnames';
import { useEffect, useState } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Popover from '@ui/Popover';
import Typography from '@ui/Typography';

import { BlockedControl } from '@components/BlockedBox';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

function StatusCell({
    mainProps: { hideLabel = () => {} },
    value: initialValue,
    row,
    column: {
        id,
        headerProps: { interactive, cellData: { isLimited, statuses = [], closeOnSelect = false } = {} } = {},
    },
    onUpdateData = (value) => value,
    cell,
}) {
    const [value, setValue] = useState({});
    const [prevValue, setPrevValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const initialVal = statuses.find((item) => item.value === initialValue) || {};
        setValue(initialVal);
        setPrevValue(initialVal.value);
    }, [initialValue, statuses]);

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
        if (prevValue !== value.value) {
            setPrevValue(value.value);
            onUpdateData({ cell: { name: cell.column?.id, ...value }, row });
        }
    };

    const handleClick = (event) => {
        if (anchorEl) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (event, newValue) => {
        if (value.value === newValue) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        const valueRes = statuses.find((item) => item?.value === newValue);
        if (closeOnSelect) {
            onUpdateData({ cell: { name: cell.column?.id, ...valueRes }, row });
            setAnchorEl(null);
        } else {
            setValue(valueRes);
        }
    };

    return prevValue ? (
        <>
            <BlockedControl isLimited={isLimited} placement='right'>
                <Button.Button
                    aria-describedby={id + row.index}
                    className={cx(styles.cellWrapper, { [styles.activeCellWrapper]: anchorEl })}
                    disabled={!interactive || isLimited}
                    size='small'
                    variant='text'
                    onClick={handleClick}
                >
                    {value?.icon ? (
                        <Icon color={value?.color} component={value.icon} fontSize={16} />
                    ) : (
                        <div className={styles.statusCircle} style={{ backgroundColor: value.color }} />
                    )}
                    <Typography
                        className={cx(styles.text, { [styles.textDisabled]: !interactive })}
                        component='div'
                        variant='hr12'
                    >
                        {value.label}
                    </Typography>
                    {interactive ? (
                        <Icon color={colors[anchorEl ? 'blue-700' : 'grey-400']} component={ChevronIcon} />
                    ) : null}
                </Button.Button>
            </BlockedControl>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                id={id + row.index}
                open={open}
                onClose={handleClose}
            >
                <div className={styles.options}>
                    {statuses.map(({ label, value: statusValue, icon, color }) => {
                        const showOption = !hideLabel?.(statusValue);

                        return (
                            showOption && (
                                <div
                                    key={statusValue}
                                    className={cx(styles.option, {
                                        [styles.optionDisabled]: value.value === statusValue,
                                    })}
                                    role='button'
                                    tabIndex={0}
                                    onClick={(event) => handleChange(event, statusValue)}
                                    onKeyDown={() => {}}
                                >
                                    {icon && color ? (
                                        <Icon color={color} component={icon} fontSize={16} />
                                    ) : (
                                        <div className={styles.statusCircle} style={{ backgroundColor: color }} />
                                    )}
                                    <Typography variant='hr13'>{label}</Typography>
                                </div>
                            )
                        );
                    })}
                </div>
            </Popover>
        </>
    ) : (
        <Typography
            className={cx(styles.staticLabel, { [styles.textDisabled]: !interactive })}
            component='div'
            variant='hr12'
        >
            {initialValue}
        </Typography>
    );
}

export default StatusCell;
