import { createSlice } from '@reduxjs/toolkit';
import { put, delay, take, takeLatest, select } from 'redux-saga/effects';

import { parseDocuments } from '@utils/store/middleware';

import { selectCurrentPageCommon } from '@scc/store/ducks/common';

import { documentsApi } from '@api';
import { API_ERROR, API_SUCCESS, apiRequest } from '@store/ducks/api';

const entity = '[docs]';
const size = 20;

const initialState = {
    data: [],
    totalCount: null,
    toBeUpdated: { toAdd: [], toRemove: [] },
    search: {},
    parcel: {},
    loaded: false,
    isSync: false,
    loading: false,
    loanId: null,
    memberId: null,
    lenderId: null,
    event: {},
    added: [],
};

const documentsSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        loadLoan(state, action) {
            const { loanId, search } = action?.payload || {};
            state.loaded = false;
            state.loading = true;
            if (search) {
                state.search = search || {};
            }
            state.loanId = loanId;
        },
        loadMoreLoan() {},
        loadMember(state, action) {
            const { memberId, search } = action?.payload || {};
            state.loaded = false;
            state.loading = true;
            state.memberId = memberId;
            if (search) {
                state.search = search || {};
            }
        },
        loadPortfolio(state, action) {
            const { parcel, search, silent } = action?.payload || {};
            if (!silent) {
                state.loaded = false;
                state.loading = true;
            }
            state.parcel = parcel;
            if (search) {
                state.search = search || {};
            }
        },
        loadMorePortfolio() {},
        loadLender(state, action) {
            const { lenderId, search } = action?.payload || {};
            state.loaded = false;
            state.loading = true;
            state.memberId = lenderId;
            if (search) {
                state.search = search || {};
            }
        },
        loadAll(state, action) {
            state.loaded = false;
            state.loading = true;
            state.data = [];
            state.search = action?.payload?.search || {};
        },
        loadMore() {},
        setData(state, action) {
            const { data, totalCount } = action.payload;
            state.data = data;
            if (totalCount) {
                state.totalCount = totalCount;
            }
            state.loaded = true;
            state.loading = false;
        },
        add(state, action) {
            state.added = [
                ...state.added,
                ...action.payload.map((item) => ({
                    ...item,
                    shared: [],
                })),
            ];
        },
        addMore(state, action) {
            state.data = [
                ...state.data,
                ...action.payload.map((item) => ({
                    ...item,
                    shared: [],
                })),
            ];
        },
        reset(state) {
            state.data = [];
            state.totalCount = 0;
            state.loaded = false;
            state.loading = false;
        },
        change() {},
        updateOne() {},
        updateLinking(state, action) {
            const { task, item, neccessaryKey } = action.payload;
            const { toAdd, toRemove } = { ...state.toBeUpdated };
            if (task === 'add') {
                const resAdd = toAdd.filter((listItem) => listItem[neccessaryKey] !== item[neccessaryKey]);
                state.toBeUpdated = {
                    toRemove,
                    toAdd: resAdd,
                };
            } else {
                const resRemove = toRemove.filter((listItem) => listItem[neccessaryKey] !== item[neccessaryKey]);
                state.toBeUpdated = {
                    toAdd,
                    toRemove: resRemove,
                };
            }
        },
        updateItems(state, action) {
            state.toBeUpdated = action.payload;
        },
        updateDataItem(state, action) {
            const { id } = action.payload;
            const indexToUpdate = state.data.findIndex((item) => item.id === id);

            if (indexToUpdate < 0) {
                return;
            }

            state.data[indexToUpdate] = action.payload;
        },
        changeIsSync(state, action) {
            state.isSync = action.payload;
        },
    },
});

export const {
    loadLoan: loadLoanDocuments,
    loadMoreLoan: loadMoreLoanDocuments,
    loadLender: loadLenderDocuments,
    loadMember: loadMemberDocuments,
    loadPortfolio: loadPortfolioDocuments,
    loadMorePortfolio: loadMorePortfolioDocuments,
    loadAll: loadAllDocuments,
    change: changeStatus,
    loadMore: loadMoreDocuments,
    updateOne: updateLinkedItem,
    updateLinking: updateStateOfLinking,
    updateItems: updateLinkedItems,
    setData: setDocuments,
    add: addUploadDocuments,
    addMore: addMoreDocuments,
    reset: resetDocumentsState,
    updateDataItem: updateDataItemDocuments,
    changeIsSync: setSync,
} = documentsSlice.actions;

export default documentsSlice.reducer;

export const selectDocumentsState = (store) => store.documents;

function* loadLenderDocumentsSaga({ type, payload }) {
    const { lenderId, search } = payload;
    yield put(apiRequest({ lenderId, search }, documentsApi.getLenderDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content) }));
}

function* loadPortfolioDocumentsSaga({ type, payload }) {
    const { params: { userId } = {} } = yield select(selectCurrentPageCommon);
    const { parcel } = payload;
    yield put(apiRequest({ parcel, userId }, documentsApi.getPortfolioDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content), totalCount: data.totalElements }));
}

function* loadMorePortfolioDocumentsSaga({ type }) {
    const { data, parcel } = yield select(selectDocumentsState);

    yield put(
        apiRequest(
            { parcel: { ...parcel, search: { page: data.length / size, size } } },
            documentsApi.getPortfolioDocuments,
            type
        )
    );

    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreDocuments(parseDocuments(content)));
}

function* loadLoanDocumentsSaga({ type, payload }) {
    const { loanId, search } = payload;
    yield put(apiRequest({ loanId, search }, documentsApi.getLoanDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content), totalCount: data.totalElements }));
}

function* loadMoreLoanDocumentsSaga({ type }) {
    const { search, data, loanId } = yield select(selectDocumentsState);
    yield put(
        apiRequest(
            { loanId, search: { ...search, page: data.length / size, size } },
            documentsApi.getLoanDocuments,
            type
        )
    );
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreDocuments(parseDocuments(content)));
}

function* loadMemberDocumentsSaga({ type, payload }) {
    const { memberId, search } = payload;
    yield put(apiRequest({ memberId, search }, documentsApi.getMemberDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content), totalCount: data.totalElements }));
}

function* loadAllDocumentsSaga({ type, payload = { search: {} } }) {
    yield delay(300);
    const { search = {} } = payload;
    yield put(apiRequest({ ...search, size }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { data } = action.payload;
    yield put(setDocuments({ data: parseDocuments(data.content), totalCount: data.totalElements }));
}

function* loadMoreDocumentsSaga({ type }) {
    const { search, data } = yield select(selectDocumentsState);
    yield put(apiRequest({ ...search, page: data.length / size, size }, documentsApi.getAllDocuments, type));
    const action = yield take(`${type} ${API_SUCCESS}`);
    const { content } = action.payload.data;
    yield put(addMoreDocuments(parseDocuments(content)));
}

function* updateLinkedItemSaga({ payload, type }) {
    const { item, task } = payload;
    const neccessaryKey = Object.keys(item).filter((key) => key !== 'documentId')[0];
    const isAddTask = task === 'add';
    let apiMethod = isAddTask ? documentsApi.addToLoan : documentsApi.removeFromLoan;
    if (neccessaryKey === 'memberId') {
        apiMethod = isAddTask ? documentsApi.addToMember : documentsApi.removeFromMember;
    } else if (neccessaryKey === 'propertyId') {
        apiMethod = isAddTask ? documentsApi.addToProperty : documentsApi.removeFromProperty;
    } else if (neccessaryKey === 'email') {
        apiMethod = isAddTask ? documentsApi.addToUser : documentsApi.removeFromUser;
    }
    yield put(apiRequest(item, apiMethod, type));
    yield take([`${type} ${API_SUCCESS}`, `${type} ${API_ERROR}`]);
    yield put(updateStateOfLinking({ ...payload, neccessaryKey }));
}

function* changeStatusSaga({ type, payload }) {
    yield put(apiRequest(payload, documentsApi.changeDocumentStatus, type));
    yield take(`${type} ${API_SUCCESS}`);
    const { search } = yield select(selectDocumentsState);
    yield put(loadAllDocuments({ search }));
}

export function* watchDocuments() {
    yield takeLatest(loadLoanDocuments, loadLoanDocumentsSaga);
    yield takeLatest(loadMoreLoanDocuments, loadMoreLoanDocumentsSaga);
    yield takeLatest(loadPortfolioDocuments, loadPortfolioDocumentsSaga);
    yield takeLatest(loadMorePortfolioDocuments, loadMorePortfolioDocumentsSaga);
    yield takeLatest(loadLenderDocuments, loadLenderDocumentsSaga);
    yield takeLatest(loadMemberDocuments, loadMemberDocumentsSaga);
    yield takeLatest(loadAllDocuments, loadAllDocumentsSaga);
    yield takeLatest(loadMoreDocuments, loadMoreDocumentsSaga);
    yield takeLatest(updateLinkedItem, updateLinkedItemSaga);
    yield takeLatest(changeStatus, changeStatusSaga);
}
