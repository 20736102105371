import cx from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';

import { selectAuthMngState, loadResetPasswordAuthMng, setTemplateAuthMng } from '@scc/store/ducks/authManager';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Divider from '@ui/Divider';
import Grid from '@ui/Grid';
import Input from '@ui/Input';
import Link from '@ui/Link';
import Stack from '@ui/Stack';
import Typography from '@ui/Typography';

import LoginSocialNetworkButton from '@components/SocialNetworkButton';

import colors from '@themes/palette/export.module.scss';

import Emoji from '@shared/components/Emoji';
import useQueryParams from '@shared/hooks/useQueryParams';

import styles from './styles.module.scss';
import schema from './validationSchema';

function ForgotPasswordForm({ onStepChange }) {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const { error: authError, loading } = useSelector(selectAuthMngState);

    const [{ email: emailParam, error: errorParam }] = useQueryParams();

    useEffect(() => {
        if (emailParam) {
            setValue('email', emailParam);
        }
    }, [emailParam]);

    useEffect(() => {
        if (authError) {
            setError('email', {
                type: 'manual',
                message: authError.message,
            });
        }
    }, [authError]);

    const resetButtonTitle = errorParam ? 'Resend Reset Link' : 'Reset Password';

    const handleStepChange = (step) => {
        if (onStepChange) {
            onStepChange(step);
        }
    };

    const onSubmit = (data) => {
        dispatch(loadResetPasswordAuthMng(data));
    };

    const handleCancel = () => {
        dispatch(setTemplateAuthMng({ name: 'login' }));
    };

    return (
        <Stack className={styles.formWrapper} direction='column' justifyContent='space-between' sx={{ height: 1 }}>
            <Box className={styles.form} component='form' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    label='Email'
                    margin='normal'
                    variant='standard'
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />

                <div
                    className={cx(styles.infoWrapper, {
                        [styles.alignRight]: !errorParam,
                    })}
                >
                    {errorParam && (
                        <div className={styles.tokenExpired}>
                            <Typography color={colors['yellow-500']} component='span' variant='h5sb'>
                                Password reset link is expired <Emoji label='Face with Monocle' symbol='🧐' />
                            </Typography>

                            <Typography color={colors['grey-400']} component='span' variant='mdr'>
                                Resend password reset link?
                            </Typography>
                        </div>
                    )}
                    <Link.Secondary size='small' onClick={handleCancel}>
                        <Typography color={colors.black} component='span' variant='smr'>
                            Cancel
                        </Typography>
                    </Link.Secondary>
                </div>

                <Button
                    color='primary'
                    loading={loading}
                    size='extra-large'
                    sx={{ marginTop: '66px' }}
                    type='submit'
                    fullWidth
                >
                    {resetButtonTitle}
                </Button>

                <div>
                    <Divider sx={{ margin: '84px 0 34px 0' }}>or</Divider>
                    <Grid
                        alignItems='center'
                        direction='row'
                        justifyContent='center'
                        spacing={3}
                        sx={{ marginBottom: '65px' }}
                        container
                    >
                        <Grid xs='auto' item>
                            <LoginSocialNetworkButton
                                size='extra-large'
                                type='google'
                                variant='outlined'
                                fullWidth
                                logoOnly
                            />
                        </Grid>
                        <Grid xs='auto' item>
                            <LoginSocialNetworkButton size='extra-large' type='facebook' variant='outlined' logoOnly />
                        </Grid>
                        <Grid xs='auto' item>
                            <LoginSocialNetworkButton size='extra-large' type='linkedin' variant='outlined' logoOnly />
                        </Grid>
                    </Grid>
                </div>
                <Typography color={colors['grey-400']} sx={{ textAlign: 'center' }} variant='smr'>
                    Dont have account?{' '}
                    <Link.Secondary
                        size='small'
                        onClick={() => {
                            handleStepChange('register');
                        }}
                    >
                        <Typography color={colors['grey-400']} component='span' variant='smr'>
                            Sign up
                        </Typography>
                    </Link.Secondary>
                </Typography>
                <Typography className={styles.copyright} color='#dbdbdb' variant='smr'>
                    © {new Date().getFullYear()} Smart Capital Center. All rights reserved.
                </Typography>
            </Box>
        </Stack>
    );
}

export default ForgotPasswordForm;
