import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setTemplateAuthMng } from '@scc/store/ducks/authManager';

import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import colors from '@themes/palette/export.module.scss';

import { LOGIN_DIALOG } from '@constants/modals';
import LockIcon from '@icons/outlined/lock-1.svg';
import { showPrimaryAsideDrawer } from '@store/ducks/ui/drawer';

import styles from './styles.module.scss';

export function LoginButton() {
    const dispatch = useDispatch();

    const handleLoginClick = useCallback(() => {
        dispatch(setTemplateAuthMng({ name: 'register' }));
        dispatch(
            showPrimaryAsideDrawer({
                content: LOGIN_DIALOG,
                header: null,
            })
        );
    }, [dispatch]);

    return (
        <div className={styles.wrapper} role='presentation' onClick={handleLoginClick} onKeyDown={handleLoginClick}>
            <Typography className={styles.text} color={colors['grey-400']} variant='h5r'>
                Please Login to see Indicative Value
            </Typography>
            <Icon color={colors['grey-400']} component={LockIcon} fontSize={24} />
        </div>
    );
}
