import { baseApi as api } from '../configs/rentRollAnalytics';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRentRollReconcileAlerts: build.query<
            GetRentRollReconcileAlertsApiResponse,
            GetRentRollReconcileAlertsApiArg
        >({
            query: (queryArg) => ({ url: `/api/v2/rentroll-analytics/reconcile-alerts/${queryArg.rentRollId}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetRentRollReconcileAlertsApiResponse = /** status 200 OK */ GenericJsonResponseListReconcileAlertDto;
export type GetRentRollReconcileAlertsApiArg = {
    rentRollId: number;
};
export type ReconcileAlertDto = {
    type?: Type;
    message?: string;
};
export type GenericJsonResponseListReconcileAlertDto = {
    status?: string;
    data?: ReconcileAlertDto[];
    message?: string;
    code?: string;
};
export enum Type {
    Yellow = 'YELLOW',
    Red = 'RED',
}
export const { useGetRentRollReconcileAlertsQuery } = injectedRtkApi;
