import request from '@utils/request';

const BASE_URL = '/api/v2/todo';

export async function createTodo(body) {
    return request(`${BASE_URL}/new`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updateTodo(body) {
    return request(`${BASE_URL}/update`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function updateTodoStatus(body) {
    return request(`${BASE_URL}/update-status`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function deleteTodo(body) {
    return request(`${BASE_URL}/delete`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function loadTodos({ team, loanId, sort }) {
    return request(`${BASE_URL}/${team}/${loanId}`, {
        params: {
            sort,
        },
    });
}

export async function loadUserTodos({ search, taskStatus, taskType, sort } = {}) {
    return request(BASE_URL, {
        params: {
            search,
            taskStatus,
            taskType,
            sort,
        },
    });
}

export async function loadAllTodos({ loanId }) {
    return request(`${BASE_URL}/${loanId}`);
}

export async function getPendingUploadTasks({ loanId }) {
    return request(`${BASE_URL}/pending-upload-tasks/${loanId}`);
}
