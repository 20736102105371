import Tooltip from '@ui/Tooltip';

import LargestTenants from './components/LargestTenants';
import { LARGEST_TENANTS } from './constants';
import styles from './styles.module.scss';

const tooltipTemplatesMap = {
    [LARGEST_TENANTS]: LargestTenants,
};

function CustomTooltip({ component, title, children, ...props }) {
    const { title: componentTitle, data, additionalData, name, template } = component || {};
    const TooltipTemplate = tooltipTemplatesMap[name];

    const TooltipComponent = (
        <TooltipTemplate additionalData={additionalData} data={data} template={template} title={componentTitle} />
    );

    if ((component && component.data) || title) {
        return (
            <div className={styles.customTooltip}>
                <Tooltip title={component ? TooltipComponent : title} {...props}>
                    {children}
                </Tooltip>
            </div>
        );
    }

    return children;
}

export default CustomTooltip;
