import { InputAdornment } from '@mui/material';
import cx from 'classnames';
import { forwardRef, useRef, useState } from 'react';

import ChevronIcon from '@scc/assets/icons/chevron.svg';

import Icon from '@ui/Icon';
import OutlinedInput from '@ui/OutlinedInput';

import BaseDatePicker from '@components/DatePicker';

import colors from '@themes/palette/export.module.scss';

import styles from './styles.module.scss';

type TCustomInputProps = {
    isOpen: boolean;
    [key: string]: unknown;
};

export const CustomInput = forwardRef((props: TCustomInputProps, ref) => {
    const { isOpen, ...inputProps } = props;

    return (
        <OutlinedInput
            {...inputProps}
            className={cx(styles.input, { [styles.open]: isOpen })}
            endAdornment={
                <InputAdornment position='end'>
                    <Icon className={styles.icon} color={colors['grey-400']} component={ChevronIcon} fontSize={20} />
                </InputAdornment>
            }
            inputProps={{
                ...(inputProps.inputProps ?? {}),
                autoComplete: 'off',
            }}
            ref={ref}
            variant='noOutlined'
            fullWidth
        />
    );
});

function LoanDatePicker(props) {
    const inputRef = useRef(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleCalendarClose = () => {
        setIsCalendarOpen(false);
    };

    const handleCalendarOpen = () => {
        setIsCalendarOpen(true);
    };

    return (
        <BaseDatePicker
            {...props}
            customInput={<CustomInput isOpen={isCalendarOpen} ref={inputRef} sx={props?.sx ?? {}} />}
            dateFormat='MMM d, yyyy'
            isClearable={false}
            showYearDropdown
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
        />
    );
}

export default LoanDatePicker;
