import { useEffect } from 'react';

const usePreventReactRender = function (content = '', ref = {}) {
    useEffect(() => {
        if (!!content && ref.current) {
            // eslint-disable-next-line no-param-reassign
            ref.current.innerHTML = content;
        }
    }, [ref]);
};

export default usePreventReactRender;
