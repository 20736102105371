import { createSelector } from '@reduxjs/toolkit';

export const ROLES = {
    ADMIN: 'ROLE_ADMIN',
    GROUP_ADMIN: 'ROLE_GROUPADMIN',
    PREV_ADMIN: 'ROLE_PREVIOUS_ADMINISTRATOR',
    USER: 'ROLE_USER',
    ANONYMOUS: 'ROLE_ANONYMOUS',
    LENDER: 'ROLE_SCC_LENDER',
    DEMO: 'ROLE_SCC_DEMO',
    FULL_SERVE: 'ROLE_FULLSERVE',
    EVALUATION: 'ROLE_EVALUATION',
    BORROWER: 'ROLE_SCC_BORROWER',
    SCC_LIMITED: 'ROLE_SCC_LIMITED',
    SCC_FULL_SERVE_EXTENDED: 'ROLE_SCC_FULL_SERVE_EXTENDED',
    SCC_SERVICER: 'ROLE_SERVICER',
    SCC_PL: 'ROLE_PACIFICLIFE',
    JLL_TENANT: 'ROLE_JLL_TENANT',
    PL_TENANT: 'ROLE_PL_TENANT',
    RGA_TENANT: 'ROLE_RGA_TENANT',
    FMAC_TENANT: 'ROLE_FREDDIE',
    GANTRY_TENANT: 'ROLE_GANTRY_TENANT',
    RMR_TENANT: 'ROLE_RMR_TENANT',
    SCC_SELF_SERVE: 'ROLE_SCC_SELF_SERVICE',
};

export const getRole = (selector, role) =>
    createSelector(selector, ({ currentUser } = {}) => currentUser && currentUser?.roles?.includes(role));

export default ROLES;
