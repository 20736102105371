const badge = () => ({
    MuiBadge: {
        styleOverrides: {
            standard: {
                fontSize: '0.6rem',
                minWidth: 16,
                height: 16,
            },
            colorSuccess: {
                color: '#ffffff',
            },
        },
    },
});
export default badge;
