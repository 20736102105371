import { Link, useLocation } from 'react-router-dom';

import links from '@scc/router/links';
import Header from '@scc/templates/Base/BaseHeader';
import BaseHeaderMenu from '@scc/templates/Base/BaseHeaderMenu';

import Button from '@ui/Button';
import Container from '@ui/Container';
import Icon from '@ui/Icon';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import { HERO_DATA } from './helpers';
import styles from './styles.module.scss';

function Hero({ mobile, containerRef }) {
    const location = useLocation();

    const { title, description, buttonLabel, partners, videoSrc } = HERO_DATA;
    return (
        <div className={styles.hero}>
            <Header
                appBarProps={{ position: 'static' }}
                containerProps={{
                    maxWidth: 'xl',
                }}
                containerRef={containerRef?.current}
            >
                <BaseHeaderMenu />
            </Header>
            <Container maxWidth='xl'>
                <div className={styles.wrapper}>
                    <div className={styles.infoWrapper}>
                        <div className={styles.infoContainer}>
                            <Typography color={colors['deepBlue-500']} variant={mobile ? 'h1b' : 'head1b'}>
                                {title}
                            </Typography>
                            <Typography color={colors['darkNeutral-500']} component='span' variant='h5r'>
                                {description}
                            </Typography>
                        </div>

                        <Button
                            aria-label={buttonLabel}
                            className={styles.button}
                            component={Link}
                            fullWidth={mobile}
                            rel='nofollow'
                            size='extra-large'
                            to={{
                                state: { from: location },
                                pathname: links.requestDemo.path,
                            }}
                        >
                            {buttonLabel}
                        </Button>
                    </div>
                    <div className={styles.imgWrapper}>
                        <video id={styles.bgVideo} autoPlay loop muted>
                            <source src={videoSrc} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className={styles.partnersWrapper}>
                    <Typography className={styles.partnersTitle} color={colors['darkNeutral-50']} variant='hr12'>
                        <Typography variant='hb12'>Trusted</Typography>
                        by
                        <Typography variant='hb12'>Partners</Typography>
                    </Typography>
                    <div className={styles.partnersList}>
                        <ItemList items={partners}>
                            {({ component, viewBox }) => (
                                <Icon.AutoSize color='#474D66' component={component} viewBox={viewBox} />
                            )}
                        </ItemList>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Hero;
