import { useEffect, forwardRef, useState, useMemo } from 'react';
import cx from 'classnames';

import { createUrl } from '@utils/browser';
import styles from './styles.module.scss';

const BaseIframe = forwardRef(
    ({ src, origin, hash, search, className, attrs: { title = 'BaseIframe', ...attrs } = {} }, ref) => {
        const [iframeSrc, setSrc] = useState('');
        const iFrameElement = useMemo(
            () => (
                <iframe
                    title={title}
                    ref={ref}
                    src={iframeSrc}
                    className={styles.iframe}
                    sandbox='allow-modals allow-downloads allow-forms allow-popups allow-scripts allow-same-origin'
                    {...attrs}
                />
            ),
            [iframeSrc]
        );

        useEffect(() => {
            const url = src && createUrl({ search, path: src, origin, hash });
            if (url !== iframeSrc) setSrc(url);
        }, [search, src, origin, hash]);

        if (!iframeSrc) return null;

        return <div className={cx(styles.wrapper, className)}>{iFrameElement}</div>;
    }
);

export default BaseIframe;
