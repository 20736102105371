import ImageComponent from '@shared/components/Image';

import styles from './styles.module.scss';

function Image({ src, ...rest }) {
    return (
        <div className={styles.wrapper}>
            <ImageComponent src={src} {...rest} />
            <div className={styles.frame} />
        </div>
    );
}

export default Image;
