import cx from 'classnames';
import { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import List from '@ui/List';
import ListItem from '@ui/ListItem';
import Popover from '@ui/Popover';
import Typography from '@ui/Typography';

import ItemList from '@components/ItemList';

import colors from '@themes/palette/export.module.scss';

import ArrowIcon from '@shared/assets/icons/outlined/arrow-select.svg';

import styles from './headerMenu.module.scss';

export function HeaderMenu({ links, active }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav className={styles.nav}>
            <ItemList items={links}>
                {({ name, path, to = path, displayLabel, label, external, target, routes }) => {
                    if (routes) {
                        return (
                            <Fragment key={path}>
                                <Button
                                    aria-label={displayLabel ?? label}
                                    className={styles.popupButton}
                                    color='text'
                                    size='small'
                                    fullWidth
                                    onClick={handleClick}
                                >
                                    <Typography color={colors['grey-400']} component='span' variant='lgr'>
                                        {displayLabel ?? label}
                                    </Typography>
                                    <Icon
                                        className={cx({ [styles.activeIcon]: open })}
                                        color={colors['grey-400']}
                                        component={ArrowIcon}
                                        fontSize={6}
                                    />
                                </Button>

                                <Popover
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    id={id}
                                    open={open}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleClose}
                                >
                                    <List>
                                        {Object.values(routes).map(
                                            ({
                                                name: itemName,
                                                path: itemPath,
                                                to: itemTo = itemPath,
                                                displayLabel: itemDisplayedLabel,
                                                label: itemLabel,
                                            }) => (
                                                <ListItem key={itemName}>
                                                    <NavLink
                                                        aria-label={itemDisplayedLabel ?? itemLabel}
                                                        className={cx(styles.link, {
                                                            [styles.active]: active === itemPath,
                                                        })}
                                                        to={itemTo}
                                                        onClick={handleClose}
                                                    >
                                                        <Typography component='span' variant='h6s'>
                                                            {itemDisplayedLabel ?? itemLabel}
                                                        </Typography>
                                                    </NavLink>
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                </Popover>
                            </Fragment>
                        );
                    }

                    return external ? (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                            key={name}
                            aria-label={displayLabel ?? label}
                            className={styles.link}
                            href={to}
                            target={target}
                        >
                            <Typography component='span' variant='h6s'>
                                {displayLabel ?? label}
                            </Typography>
                        </a>
                    ) : (
                        <NavLink
                            key={name}
                            aria-label={displayLabel ?? label}
                            className={cx(styles.link, {
                                [styles.active]: active === path,
                            })}
                            {...(label === 'Listings' ? { rel: 'nofollow' } : {})}
                            to={to}
                        >
                            <Typography component='span' variant='h6s'>
                                {displayLabel ?? label}
                            </Typography>
                        </NavLink>
                    );
                }}
            </ItemList>
        </nav>
    );
}
