import InputAdornment from '@ui/InputAdornment';
import Typography from '@ui/Typography';

import CapRateGapsCell from '@components/DataTable/Cells/CapRateGapsCell';
import EntityCell from '@components/DataTable/Cells/EntityCell';
import InputCell from '@components/DataTable/Cells/InputCell';
import MemberCell from '@components/DataTable/Cells/MemberCell';
import PercentCell from '@components/DataTable/Cells/PercentCell';
import ScoringParameterValue from '@components/DataTable/Cells/ScoringParameterValue';

import colors from '@themes/palette/export.module.scss';

import { LOAN_STATUSES_BORROWER } from '@shared/constants/loans';

import StatusCell from './Cells/StatusCell';
import { DOCUMENTS_STATUSES, PORTFOLIO_STATUSES } from './constants';
import {
    ActionsList,
    AlertFormat,
    AmountFormat,
    BarFormat,
    ContactNameFormat,
    CurrencyColumnFormat,
    DateColumnFormat,
    DateFormat,
    DateSecondColumnFormat,
    debtYieldFormat,
    DocumentNameFormat,
    DocumentSelectCell,
    DocumentStatusFormat,
    DocumentTypeSecondFormat,
    DocumentTypeShortFormat,
    dscrFormat,
    FullDateFormat,
    getTwoLinesFormat,
    HeadingGroupFormat,
    ltvFormat,
    MemberTypeFormat,
    MoreDetailsAction,
    NumericColumnFormat,
    PercentColumnFormat,
    PropertyAddressFormat,
    PropertyNameFormat,
    propertyScoreFormat,
    PropertyTypeFormat,
    PropertyTypeWithAddressFormat,
    RecentPropertyNameFormat,
    RoleLabelFormat,
    ScoreFormat,
    ScoreParameterLabelFormat,
    ThirdPartyReportsStatusFormat,
    ThirdPartyReportsTypeFormat,
    UnderwritingNameCell,
    UnderwritingNotesCell,
    UnderwritingTypeFormat,
    ValuationScenarioFormat,
    ValueGapsFormat,
    ValueGapsTotalRow,
} from './helpers';
import TotalRow from './Rows/Total';
import styles from './styles.module.scss';

export const loansBorrower = [
    {
        Header: 'Loan name',
        accessor: (props) => props.loanName || props.jsonValues?.propertyName || props.propertyName,
        headerProps: {
            className: styles.lastFixedColumn,
            size: 'pb14',
            sx: {
                left: 0,
                minWidth: 180,
                maxWidth: 240,
                paddingRight: '1.5rem',
                zIndex: 3,
            },
        },
        cellProps: {
            className: styles.lastFixedColumn,
            sx: {
                left: 0,
                minWidth: 180,
                maxWidth: 240,
                paddingRight: '1.5rem',
            },
        },
    },
    {
        Header: 'Property address',
        accessor: 'address',
        formatting: PropertyAddressFormat,
        headerProps: {
            interactive: true,
            size: 'pr12',
            sx: {
                minWidth: 220,
            },
        },
        cellProps: {
            sx: {
                minWidth: 220,
            },
        },
    },
    {
        Header: 'Property type',
        accessor: 'propertyType',
        formatting: PropertyTypeFormat,
        headerProps: {
            size: 'pr12',
        },
        cellProps: {
            sx: {
                minWidth: 150,
            },
        },
    },
    {
        Header: 'Bank',
        accessor: 'bankName',
    },
    {
        Header: 'Balance',
        Footer: TotalRow,
        accessor: 'balance',
        formatting: CurrencyColumnFormat,
    },
    {
        Header: 'Term end',
        accessor: 'maturityDate',
        formatting: DateColumnFormat,
        cellProps: {
            sx: {
                minWidth: 110,
            },
        },
    },
    {
        Header: 'Loan ID',
        accessor: 'loanId',
    },
    {
        Header: 'Status',
        accessor: 'detailsStatus',
        headerProps: {
            cellData: {
                statuses: LOAN_STATUSES_BORROWER,
            },
        },
        Cell: StatusCell,
    },
];

export const stressTest = [
    {
        Header: 'Loan name',
        accessor: (props) => props.jsonValues?.loanName || props.loanName,
        headerProps: {
            className: styles.lastFixedColumn,
            size: 'pb14',
            sx: {
                left: 0,
                minWidth: 180,
                maxWidth: 240,
                paddingRight: '1.5rem',
                zIndex: 3,
            },
        },
        cellProps: {
            className: styles.lastFixedColumn,
            sx: {
                left: 0,
                minWidth: 180,
                maxWidth: 240,
                paddingRight: '1.5rem',
            },
        },
    },
    {
        Header: 'Loan ID',
        accessor: 'loanId',
    },
    {
        Header: 'Principal',
        accessor: 'principal',
        Footer: TotalRow,
        formatting: CurrencyColumnFormat,
    },
    {
        Header: 'Balance',
        Footer: TotalRow,
        accessor: 'balance',
        formatting: CurrencyColumnFormat,
    },
    {
        Header: 'Asset Value',
        accessor: 'assetValue',
        Footer: TotalRow,
        formatting: CurrencyColumnFormat,
    },
    {
        Header: 'LTV',
        accessor: 'ltv',
        formatting: ltvFormat,
    },
    {
        Header: 'DSCR',
        accessor: 'dscr',
        formatting: dscrFormat,
    },
    {
        Header: 'Debt Yield',
        accessor: 'debtYield',
        formatting: debtYieldFormat,
    },
    {
        Header: 'Loan score',
        accessor: 'propertyScore',
        formatting: propertyScoreFormat,
    },
    {
        Header: 'Term date',
        accessor: 'maturityDate',
        formatting: DateColumnFormat,
        cellProps: {
            sx: {
                minWidth: 110,
            },
        },
    },
];

export const documents = [
    {
        Header: '',
        accessor: 'type',
        formatting: DocumentTypeShortFormat,
        cellProps: {
            sx: {
                paddingLeft: '2rem',
                paddingRight: 0,
                width: 30,
            },
        },
    },
    {
        Header: 'Document Name',
        accessor: 'fileName',
        formatting: DocumentNameFormat,
    },
    {
        Header: 'As Of',
        accessor: 'documentTimestamp',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Property',
        accessor: 'documentProperties',
        headerProps: {
            interactive: true,
            cellData: {
                entity: 'property',
            },
        },
        Cell: EntityCell,
    },
    {
        Header: 'Entity',
        accessor: 'documentMembers',
        headerProps: {
            interactive: true,
            cellData: {
                entity: 'member',
            },
        },
        Cell: EntityCell,
    },
    {
        Header: 'Uploaded',
        accessor: 'uploadedDate',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Status',
        accessor: 'status',
        formatting: DocumentStatusFormat,
    },
    {
        Header: ' ',
        headerProps: {
            interactive: true,
        },
        accessor: 'action',
        Cell: MoreDetailsAction,
        sx: {
            width: 30,
        },
    },
];

export const memberDocuments = [
    {
        Header: '',
        accessor: 'type',
        formatting: DocumentTypeShortFormat,
        cellProps: {
            sx: {
                paddingLeft: '2rem',
                paddingRight: 0,
                width: 30,
            },
        },
    },
    {
        Header: 'Document Name',
        id: 'fileName',
        accessor: (props) => `${props.fileName}\n ${props.name}`,
        formatting: DocumentNameFormat,
        headerProps: {
            sx: {
                width: 320,
            },
        },
    },
    {
        Header: 'As Of',
        accessor: 'documentTimestamp',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Property',
        accessor: 'documentProperties',
        headerProps: {
            interactive: true,
            cellData: {
                entity: 'property',
            },
        },
        Cell: EntityCell,
    },
    {
        Header: 'Entity',
        accessor: 'documentMembers',
        headerProps: {
            interactive: true,
            cellData: {
                entity: 'member',
            },
        },
        Cell: EntityCell,
    },
    {
        Header: 'Uploaded',
        accessor: 'uploadedDate',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Status',
        accessor: 'status',
        formatting: DocumentStatusFormat,
    },
    {
        Header: ' ',
        headerProps: {
            interactive: true,
        },
        accessor: 'action',
        Cell: MoreDetailsAction,
    },
];

export const portfolio = [
    {
        Header: 'Property Name',
        accessor: 'propertyName',
        formatting: PropertyNameFormat,
        headerProps: {
            className: styles.lastFixedColumn,
            size: 'pb14',
            sx: {
                left: 0,
                minWidth: 220,
                maxWidth: 240,
                paddingRight: '1.5rem',
                zIndex: 3,
            },
        },
        cellProps: {
            className: styles.lastFixedColumn,
            sx: {
                left: 0,
                minWidth: 220,
                maxWidth: 240,
                paddingRight: '1.5rem',
            },
        },
    },
    {
        Header: 'Property address',
        accessor: 'address',
        formatting: PropertyAddressFormat,
        headerProps: {
            interactive: true,
            size: 'pr12',
            sx: {
                minWidth: 220,
            },
        },
        cellProps: {
            sx: {
                minWidth: 220,
            },
        },
    },
    {
        Header: 'Property Type',
        accessor: 'propertyType',
        formatting: PropertyTypeFormat,
        headerProps: {
            size: 'pr12',
            sx: {
                minWidth: 150,
            },
        },
        cellProps: {
            sx: {
                minWidth: 150,
            },
        },
    },
    {
        Header: 'SF',
        accessor: 'sf',
        formatting: NumericColumnFormat,
        Footer: TotalRow,
        headerProps: {
            size: 'pr12',
            sx: {
                minWidth: 50,
            },
        },
    },
    {
        Header: 'Units',
        accessor: 'units',
        formatting: NumericColumnFormat,
        Footer: TotalRow,
    },
    {
        Header: 'Total Value',
        accessor: 'totalValue',
        formatting: ({ value }) => CurrencyColumnFormat({ value, size: 'pr12' }),
        editable: true,
        Footer: TotalRow,
        headerProps: {
            size: 'pr12',
            sx: {
                minWidth: 120,
            },
        },
    },
    {
        Header: 'Mortgage',
        accessor: 'mortgage',
        formatting: ({ value }) => CurrencyColumnFormat({ value, size: 'pr12' }),
        editable: true,
        Footer: TotalRow,
        headerProps: {
            size: 'pr12',
            sx: {
                minWidth: 120,
            },
        },
    },
    {
        Header: 'Ownership',
        accessor: 'owned',
        editable: true,
        formatting: PercentColumnFormat,
        headerProps: {
            size: 'pr12',
            sx: {
                minWidth: 120,
            },
        },
    },
    {
        Header: 'My equity',
        accessor: 'equity',
        formatting: ({ value }) => CurrencyColumnFormat({ value, size: 'pr12' }),
        Footer: TotalRow,
        headerProps: {
            size: 'pr12',
            sx: {
                minWidth: 120,
            },
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        headerProps: {
            interactive: true,
            cellData: {
                statuses: PORTFOLIO_STATUSES,
            },
        },
        Cell: StatusCell,
    },
    {
        Header: '',
        headerProps: {
            interactive: true,
        },
        accessor: 'action',
        Cell: ActionsList,
        sx: {
            width: 30,
        },
    },
];

export const thirdPartyReports = [
    {
        Header: 'Type',
        accessor: 'type',
        formatting: ThirdPartyReportsTypeFormat,
    },
    {
        Header: 'File name',
        accessor: 'fileName',
    },
    {
        Header: 'Property Address',
        accessor: 'address',
        formatting: PropertyAddressFormat,
    },
    {
        Header: 'Order Date',
        accessor: 'orderedAt',
        formatting: ({ value, ...props }) => DateFormat({ value, orderBtn: !value, ...props }),
    },
    {
        Header: 'Status',
        accessor: 'status',
        formatting: ThirdPartyReportsStatusFormat,
    },
    {
        Header: 'Completion Date',
        accessor: 'completedAt',
        formatting: DateFormat,
    },
    {
        id: 'actions',
        Header: '',
        headerProps: {
            interactive: true,
        },
        sx: {
            minWidth: 200,
        },
        Cell: MoreDetailsAction,
    },
];

export const underwriting = [
    {
        id: 'name',
        Header: 'Model',
        accessor: ({ id, name, default: defaultChecked }) => ({
            id,
            name,
            checked: defaultChecked,
        }),
        headerProps: {
            size: 'pr13',
        },
        cellProps: {
            sx: {
                minWidth: '200px',
            },
        },
        Cell: UnderwritingNameCell,
        placeholder: 'Unnamed',
        editable: true,
    },
    {
        Header: 'Valuation Type',
        accessor: 'valuationType',
        formatting: UnderwritingTypeFormat,
    },
    {
        Header: 'Date created',
        accessor: 'createdAt',
        formatting: DateFormat,
    },
    {
        Header: 'Last Modified',
        accessor: 'updatedAt',
        formatting: DateFormat,
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        editable: true,
        Cell: UnderwritingNotesCell,
        cellProps: {
            sx: {
                minWidth: '180px',
                maxWidth: '180px',
            },
        },
    },
    {
        id: 'edit',
        Header: '',
        minWidth: 200,
        Cell: (props) => <ActionsList {...props} dataQa='loanUnderwritingOpenButton' />,
        cellProps: {
            sx: {
                textAlign: 'right',
                maxWidth: '150px',
            },
        },
    },
    {
        id: 'actions',
        Header: '',
        minWidth: 200,
        Cell: (props) => <MoreDetailsAction {...props} dataQa='loanUnderwritingMoreDetails' />,
    },
];

export const valuation = [
    {
        Header: 'Purchase Price',
        accessor: 'assetValue',
        formatting: CurrencyColumnFormat,
        headerProps: {
            interactive: true,
            size: 'hr14',
            className: styles.darkCell,
            sx: {
                borderRadius: '8px 0 0 0',
            },
            cellData: {
                saveOnBlur: true,
                inputProps: {
                    type: 'text',
                    startAdornment: (
                        <InputAdornment position='end'>
                            <Typography color={colors['grey-300']} variant='hr12'>
                                $
                            </Typography>
                        </InputAdornment>
                    ),
                },
            },
        },
        cellProps: {
            className: styles.darkCell,
            sx: {
                borderRadius: '0 8px 0 0',
            },
        },
        editable: true,
        Cell: InputCell,
    },
    {
        Header: 'Price/SF',
        accessor: 'valuePerSqft',
        formatting: CurrencyColumnFormat,
        headerProps: {
            size: 'hr14',
        },
    },
    {
        Header: 'Price/Unit',
        accessor: 'valuePerUnit',
        formatting: CurrencyColumnFormat,
        headerProps: {
            size: 'hr14',
        },
    },
    {
        Header: 'Cap Rate',
        accessor: 'capRate',
        formatting: PercentColumnFormat,
        headerProps: {
            size: 'hr14',
            fractions: 2,
        },
    },
    {
        Header: 'Valuation',
        accessor: 'valuation',
        formatting: CurrencyColumnFormat,
        headerProps: {
            size: 'hr14',
            className: styles.lightBlueCell,
            sx: {
                borderRadius: '0 0 0 8px',
            },
        },
        cellProps: {
            className: styles.lightBlueCell,
            sx: {
                borderRadius: '0 0 8px 0',
            },
        },
    },
];

export const loanSizing = [
    {
        Header: 'LTV threshold',
        accessor: 'ltv',
        headerProps: {
            interactive: true,
            size: 'hr14',
            cellData: {
                inputProps: {
                    step: 5,
                    max: 100,
                    min: 0,
                    type: 'number',
                    startAdornment: (
                        <InputAdornment position='end'>
                            <Typography color={colors['grey-300']} variant='hr12'>
                                %
                            </Typography>
                        </InputAdornment>
                    ),
                },
            },
            sx: {
                borderRadius: '8px 0 0 0',
            },
        },
        Cell: InputCell,
        cellProps: {
            className: styles.paddingCell,
        },
        editable: true,
    },
    {
        Header: 'DSCR threshold',
        accessor: 'dscr',
        headerProps: {
            interactive: true,
            size: 'hr14',
            cellData: {
                inputProps: {
                    type: 'number',
                    step: 0.25,
                    min: 0,
                },
            },
        },
        Cell: InputCell,
        editable: true,
    },
    {
        Header: 'Loan Size',
        accessor: 'loanSize',
        formatting: CurrencyColumnFormat,
        headerProps: {
            size: 'hr14',
            className: styles.darkCell,
        },
        cellProps: {
            className: styles.darkCell,
        },
    },
    {
        Header: 'Interest Rate',
        accessor: 'interestRate',
        headerProps: {
            interactive: true,
            size: 'hr14',
            cellData: {
                inputProps: {
                    type: 'number',
                    step: 0.25,
                    min: 0,
                    startAdornment: (
                        <InputAdornment position='end'>
                            <Typography color={colors['grey-300']} variant='hr12'>
                                %
                            </Typography>
                        </InputAdornment>
                    ),
                },
            },
        },
        Cell: InputCell,
        editable: true,
    },
    {
        Header: 'Amortization',
        accessor: 'amortization',
        headerProps: {
            interactive: true,
            size: 'hr14',
            cellData: {
                inputProps: {
                    type: 'number',
                    step: 5,
                    startAdornment: (
                        <InputAdornment position='end'>
                            <Typography color={colors['grey-300']} variant='hr12'>
                                Years
                            </Typography>
                        </InputAdornment>
                    ),
                },
            },
            sx: {
                borderRadius: '0 0 0 8px',
            },
        },
        Cell: InputCell,
        editable: true,
        sx: {
            borderRadius: '0 0 8px 0',
        },
    },
];

export const stressTesting = [
    {
        Header: 'Rents',
        accessor: 'rentsCoefficient',
        headerProps: {
            max: 100,
        },
        Cell: PercentCell,
        cellProps: {
            sx: { padding: '6px 1rem' },
        },
    },
    {
        Header: 'Occupancy',
        accessor: 'occupancyCoefficient',
        headerProps: {
            max: 100,
        },
        Cell: PercentCell,
        cellProps: {
            sx: { padding: '6px 1rem' },
        },
    },
    {
        Header: 'Cap Rates',
        accessor: 'capRateCoefficient',
        headerProps: {
            min: -100,
            max: 100,
        },
        Cell: PercentCell,
        cellProps: {
            sx: { padding: '6px 1rem' },
        },
    },
    {
        Header: 'Interest Rates',
        accessor: 'interestsRateCoefficient',
        headerProps: {
            max: 100,
        },
        Cell: PercentCell,
        cellProps: {
            sx: { padding: '6px 1rem' },
        },
    },
];

export const membersCompany = [
    {
        Header: 'Name',
        accessor: 'name',
        formatting: ContactNameFormat,
    },
    {
        Header: 'Phone',
        accessor: (col) => (['CORPORATION', 'LLC'].includes(col.type) ? col.companyPhone : col.phone),
    },
    {
        Header: 'Address',
        accessor: 'address',
    },
    {
        Header: ' ',
        headerProps: {
            interactive: true,
        },
        accessor: 'action',
        Cell: (props) => <MoreDetailsAction {...props} dataQa='contextMenuCompany' />,
        sx: {
            width: 30,
        },
    },
];

export const membersIndividual = [
    {
        Header: 'Name',
        accessor: 'name',
        formatting: ContactNameFormat,
    },
    {
        Header: 'Company',
        accessor: 'companyName',
    },
    {
        Header: 'Title',
        accessor: 'position',
    },
    {
        Header: 'Email',
        accessor: (col) => (['CORPORATION', 'LLC'].includes(col.type) ? col.companyEmail : col.email),
    },
    {
        Header: 'Phone',
        accessor: (col) => (['CORPORATION', 'LLC'].includes(col.type) ? col.companyPhone : col.phone),
    },
    {
        Header: 'Address',
        accessor: 'address',
    },
    {
        Header: 'Role',
        accessor: 'type',
        formatting: MemberTypeFormat,
    },
    {
        Header: ' ',
        headerProps: {
            interactive: true,
        },
        accessor: 'action',
        Cell: (props) => <MoreDetailsAction {...props} dataQa='contextMenuMember' />,
        sx: {
            width: 30,
        },
    },
];

export const owners = [
    {
        Header: 'Contact',
        id: 'owner',
        accessor: ({ userInfoDTO: { firstname, lastname, role, avatar } }) => ({
            name: `${firstname} ${lastname}`,
            role,
            avatar,
        }),
        Cell: MemberCell,
        cellProps: {
            sx: { width: '600px' },
        },
    },
    {
        Header: 'Owns',
        accessor: 'ownership',
        formatting: PercentColumnFormat,
    },
];

export const memberProperties = [
    {
        Header: 'Property Name',
        accessor: 'propertyName',
        formatting: PropertyNameFormat,
        headerProps: {
            size: 'pb14',
            sx: {
                left: 0,
                minWidth: 180,
                maxWidth: 240,
                paddingRight: '1.5rem',
                zIndex: 3,
            },
        },
        cellProps: {
            sx: {
                left: 0,
                minWidth: 180,
                maxWidth: 240,
                paddingRight: '1.5rem',
            },
        },
    },
    {
        Header: 'Property address',
        accessor: 'address',
        formatting: PropertyAddressFormat,
        headerProps: {
            interactive: true,
            size: 'pr12',
            sx: {
                minWidth: 220,
            },
        },
        cellProps: {
            sx: {
                minWidth: 220,
            },
        },
    },
    {
        Header: 'Property Type',
        accessor: 'propertyType',
        formatting: PropertyTypeFormat,
        headerProps: {
            size: 'pr12',
        },
        cellProps: {
            sx: {
                minWidth: 150,
            },
        },
    },
    {
        Header: 'SF',
        accessor: 'size',
        formatting: NumericColumnFormat,
        Footer: TotalRow,
    },
    {
        Header: 'Units',
        accessor: 'units',
        formatting: NumericColumnFormat,
        Footer: TotalRow,
    },
    {
        Header: 'Total Value',
        accessor: 'value',
        formatting: CurrencyColumnFormat,
        editable: true,
        Footer: TotalRow,
    },
    {
        Header: 'Mortgage',
        accessor: 'mortgage',
        formatting: CurrencyColumnFormat,
        editable: true,
        Footer: TotalRow,
    },
    {
        Header: 'Ownership',
        accessor: 'ownership',
        editable: true,
        formatting: PercentColumnFormat,
    },
    {
        Header: 'Equity',
        accessor: 'equity',
        formatting: CurrencyColumnFormat,
        Footer: TotalRow,
    },
    {
        Header: 'Role',
        accessor: 'role',
        formatting: RoleLabelFormat,
        cellProps: {
            sx: {
                minWidth: 120,
            },
        },
    },
    {
        Header: ' ',
        headerProps: {
            interactive: true,
        },
        accessor: 'action',
        Cell: MoreDetailsAction,
        sx: {
            width: 30,
        },
    },
];

export const existingDocuments = [
    {
        Header: ' ',
        accessor: ({ id, name }) => ({
            id,
            name,
        }),
        Cell: DocumentSelectCell,
    },
    {
        Header: 'Document Name',
        accessor: 'fileName',
        formatting: DocumentNameFormat,
    },
    {
        Header: 'Type',
        accessor: 'type',
        formatting: DocumentTypeSecondFormat,
    },
    {
        Header: 'Doc Date',
        accessor: 'documentTimestamp',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Status',
        accessor: 'status',
        formatting: DocumentStatusFormat,
    },
];

export const loanLenders = [
    {
        Header: 'Lender',
        accessor: 'lenderName',
    },
    {
        Header: 'Loan Officer',
        accessor: 'loanOfficer',
    },
    {
        Header: 'Comment',
        accessor: 'comment',
    },
    {
        Header: '',
        accessor: 'action',
        headerProps: {
            sx: {
                width: 100,
            },
        },
        Cell: ActionsList,
    },
];

export const lenderDocuments = [
    {
        Header: '',
        accessor: 'type',
        formatting: DocumentTypeShortFormat,
        cellProps: {
            sx: {
                paddingLeft: '1rem',
                paddingRight: 0,
                width: 30,
            },
        },
    },
    {
        Header: 'Name',
        id: 'fileName',
        accessor: (props) => `${props.fileName}\n ${props.name}`,
        formatting: HeadingGroupFormat,
        headerProps: {
            sx: {
                width: 320,
            },
        },
    },
    {
        Header: 'As of',
        accessor: 'documentDate',
        formatting: DateFormat,
    },
    {
        Header: 'Property',
        accessor: 'documentProperties',
        headerProps: {
            interactive: false,
            cellData: {
                entity: 'property',
            },
        },
        Cell: EntityCell,
    },
    {
        Header: 'Entity',
        accessor: 'documentMembers',
        headerProps: {
            interactive: false,
            cellData: {
                entity: 'member',
            },
        },
        Cell: EntityCell,
    },
    {
        Header: 'Uploaded',
        accessor: 'uploadedDate',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: StatusCell,
        headerProps: {
            cellData: {
                statuses: Object.values(DOCUMENTS_STATUSES),
            },
        },
    },
    {
        Header: ' ',
        headerProps: {
            interactive: true,
        },
        cellProps: {
            sx: {
                textAlign: 'right',
                minWidth: 140,
            },
        },
        accessor: 'action',
        Cell: MoreDetailsAction,
    },
];

export const propertyValuation = [
    {
        Header: 'Based on cash flow',
        accessor: 'scenario',
        formatting: ValuationScenarioFormat,
        headerProps: {
            sx: {
                width: 350,
            },
            cellData: {
                isLimited: false,
            },
        },
        Footer: 'BASED ON SALES COMPS',
    },
    {
        Header: 'NOI',
        accessor: 'noi',
        formatting: CurrencyColumnFormat,
        headerProps: {
            cellData: {
                isLimited: false,
            },
        },
        editable: true,
    },
    {
        Header: 'Cap Rate',
        accessor: (col) => (col?.capRate ? Object.values(col.capRate) : null),
        Cell: CapRateGapsCell,
        headerProps: {
            cellData: {
                isLimited: false,
            },
        },
        editable: true,
    },
    {
        Header: 'Value',
        accessor: 'value',
        Footer: ValueGapsTotalRow,
        formatting: ValueGapsFormat,
    },
    {
        Header: 'Value/Unit',
        accessor: 'units',
        Footer: ValueGapsTotalRow,
        formatting: ValueGapsFormat,
    },
    {
        Header: 'Value/SF',
        accessor: 'sqft',
        Footer: ValueGapsTotalRow,
        formatting: ValueGapsFormat,
    },
    {
        Header: 'Average Value',
        accessor: 'average',
        formatting: BarFormat,
        Footer: ValueGapsTotalRow,
        headerProps: {
            sx: {
                width: 300,
            },
        },
    },
];

export const recentProperties = [
    {
        Header: 'Name',
        accessor: 'propertyName',
        formatting: ({ original: { propertyType }, value }) => RecentPropertyNameFormat({ value, type: propertyType }),
    },
    {
        Header: 'Address',
        accessor: 'address',
        formatting: (props) => PropertyAddressFormat({ showIcon: true, ...props }),
    },
    {
        Header: 'last update',
        accessor: 'lastUpload',
        formatting: (props) => FullDateFormat({ color: colors['grey-400'], ...props }),
    },
];

export const recentLoans = [
    {
        Header: 'Name',
        accessor: 'loanName',
        formatting: (props) => PropertyNameFormat({ ...props, size: 'psb14' }),
    },
    {
        Header: 'Property',
        accessor: 'address',
        formatting: PropertyTypeWithAddressFormat,
    },
    {
        Header: 'Amount',
        accessor: 'principal',
        formatting: AmountFormat,
    },
];

export const tenants = [
    {
        Header: 'Tenant Name',
        accessor: 'tenantName',
        cellProps: {
            sx: {
                maxWidth: 270,
                minWidth: 150,
            },
        },
    },
    {
        Header: 'Property',
        accessor: 'address',
        formatting: PropertyTypeWithAddressFormat,
    },
    {
        Header: 'SF',
        accessor: 'sqft',
        formatting: NumericColumnFormat,
        headerProps: {
            isRight: true,
        },
        footerProps: {
            isRight: true,
        },
        Footer: TotalRow,
    },
    {
        Header: getTwoLinesFormat('Rent', ' $/SF/Y'),
        accessor: 'yearRentPsf',
        headerProps: {
            isRight: true,
        },
        formatting: CurrencyColumnFormat,
    },
    {
        Header: getTwoLinesFormat('Rent', ' $/Y'),
        accessor: 'yearRent',
        headerProps: {
            isRight: true,
        },
        footerProps: {
            isRight: true,
        },
        formatting: CurrencyColumnFormat,
        Footer: TotalRow,
    },
    {
        Header: getTwoLinesFormat('Lease', ' Start Date'),
        accessor: 'leaseStart',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: getTwoLinesFormat('Lease', ' End Date'),
        accessor: 'leaseEnd',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: getTwoLinesFormat('Rent Roll', ' Date'),
        accessor: 'rrDocumentDate',
        formatting: DateSecondColumnFormat,
    },
];

export const scoring = [
    {
        Header: 'Parameter',
        accessor: 'lineName',
        formatting: ScoreParameterLabelFormat,
        headerProps: {
            sx: {
                width: 300,
            },
        },
        cellProps: {
            sx: {
                width: 300,
            },
        },
    },
    {
        Header: 'Weight',
        accessor: 'weight',
        Cell: PercentCell,
        headerProps: {
            cellData: {
                withoutDecimals: true,
            },
        },
        cellProps: {
            sx: {
                width: 64,
            },
        },
        formatting: AlertFormat,
        Footer: TotalRow,
    },
    {
        Header: 'Parameter value',
        accessor: 'value',
        Cell: ScoringParameterValue,
        cellProps: {
            sx: {
                width: 64,
            },
        },
    },
    {
        Header: 'Score',
        accessor: 'score',
        Cell: ScoreFormat,
        cellProps: {
            sx: {
                width: 64,
            },
        },
    },
    {
        Header: 'Threshold',
        accessor: 'threshold',
        Cell: PercentCell,
        cellProps: {
            sx: {
                width: 64,
            },
        },
        headerProps: {
            interactive: true,
            cellData: {
                withLabel: false,
                withoutDecimals: true,
            },
        },
    },
];

export const historyProperties = [
    {
        Header: 'Property Name',
        accessor: 'propertyName',
        formatting: PropertyNameFormat,
        headerProps: {
            size: 'hsb13',
        },
        cellProps: {
            sx: {
                minWidth: 180,
                maxWidth: 240,
            },
        },
    },
    {
        Header: 'Address',
        accessor: 'address',
        formatting: PropertyAddressFormat,
        cellProps: {
            sx: {
                minWidth: 220,
            },
        },
    },
    {
        Header: 'Type',
        accessor: 'propertyType',
        formatting: PropertyTypeFormat,
        cellProps: {
            sx: {
                minWidth: 150,
            },
        },
    },
    {
        Header: 'Property ID',
        accessor: 'propertyId',
        headerProps: {
            sx: {
                minWidth: 110,
            },
        },
    },
    {
        Header: 'Loan ID',
        accessor: 'loanId',
        headerProps: {
            sx: {
                minWidth: 110,
            },
        },
    },
    {
        Header: 'Last Doc Upload',
        accessor: 'lastUpload',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Last Opened',
        accessor: 'lastOpened',
        formatting: DateSecondColumnFormat,
    },
    {
        Header: 'Last Modified',
        accessor: 'lastModified',
        formatting: DateSecondColumnFormat,
    },
];

export const emptyGrid = [
    {
        Header: '',
        accessor: 'type',
    },
    {
        Header: 'Document Name',
        accessor: 'fileName',
    },
    {
        Header: 'As Of',
        accessor: 'documentTimestamp',
    },
    {
        Header: 'Property',
        accessor: 'documentProperties',
    },
    {
        Header: 'Entity',
        accessor: 'documentMembers',
    },
    {
        Header: 'Uploaded',
        accessor: 'uploadedDate',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
];
